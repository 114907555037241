import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const handleResetPasswordValueChange = (
    value,
    currentContext,
    resetPasswordError,
    setResetPasswordValue,
    validatePasswordValue,
    setPasswordValidationRules,
    setResetPasswordTooltipVisible,
    setResetPassWordError
    ) => {
    setResetPasswordValue(value);
    const key = getStorageKey("resetPasswordValue", currentContext);
    localStorage.setItem(key, value);
    const resetPasswordValidationResults = validatePasswordValue(value);
    setPasswordValidationRules(resetPasswordValidationResults);
    setResetPasswordTooltipVisible(
      !resetPasswordValidationResults.every((rule) => rule.isValid)
    );
    if (resetPasswordError) {
      setResetPassWordError("");
    }
  };
  export const onResetPasswordValueFocused = (
    resetPasswordValue,
    setResetPasswordFocused,
    validatePasswordValue,
    setPasswordValidationRules,
    setResetPasswordTooltipVisible
  ) => {
    setResetPasswordFocused("resetPasswordValue");
    setResetPasswordFocused(true);
    const resetPasswordValidationResults = validatePasswordValue(
      resetPasswordValue
    ).map((rule) => ({
      ...rule,
      isValid: rule.isValid,
    }));
    setPasswordValidationRules(resetPasswordValidationResults);
    setResetPasswordTooltipVisible(
      resetPasswordValidationResults.some((rule) => !rule.isValid)
    );
  };
  export const onResetPasswordValueBlur = (
    setResetPasswordFocused,
    setResetPasswordTooltipVisible
  ) => {
    setResetPasswordFocused(false);
    setResetPasswordTooltipVisible(false);
  };

  export const handleConfirmResetPasswordChange = (
    value,
    confirmResetPasswordError,
    currentContext,
    setConfirmResetPasswordValue,
    setConfirmResetPasswordError
    ) => {
    setConfirmResetPasswordValue(value);
    const confirmResetPasswordKey = getStorageKey(
      "confirmResetPasswordValue",
      currentContext
    );
    localStorage.setItem(confirmResetPasswordKey, value);
    if (confirmResetPasswordError) {
      setConfirmResetPasswordError("");
    }
  };