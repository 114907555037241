import React, { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import { GlassBtnRef } from "../buttons";
import { PlusSign, XIcon, ClipBoardCheck } from "../../components/icons";
import { NavLink } from "react-router-dom";
import timeCalculator from "../../utils/timeCalculator";
import { getCapitalizedLocationDetail } from "../../utils/stringUtils.js";
import "./eventCard.scss";
console.log(document.querySelectorAll(".white-gold-btn"));

const EventCard = ({ event }) => {
  const {
    id,
    eventStartDate,
    eventEndDate,
    eventTotalInterested,
    eventTotalAttending,
    eventImagePath, // Destructure it here for easier access
  } = event;

  const [animations, setAnimations] = useState({});
  const [userAction, setUserAction] = useState(false);

  let locationClassName = "";

  let locationDetails =
    event.eventPlace ||
    event.eventStreet ||
    event.eventCity ||
    event.eventState ||
    event.eventZipCode;

  if (event.isRemote && locationDetails) {
    locationDetails = `${event.eventPlace ? event.eventPlace + ", " : ""}
                      ${event.eventStreet ? event.eventStreet + ", " : ""}
                      ${event.eventCity ? event.eventCity + ", " : ""}
                      ${event.eventState ? event.eventState + ", " : ""}
                      ${event.eventZipCode ? event.eventZipCode + ", " : ""}
                      United States · Virtual`;
    locationClassName = "location-remote-and-physical";
  } else if (event.isRemote) {
    locationDetails = "Virtual";
    locationClassName = "only-remote";
  } else if (locationDetails) {
    locationDetails = `${event.eventPlace ? event.eventPlace + ", " : ""}
                      ${event.eventStreet ? event.eventStreet + ", " : ""}
                      ${event.eventCity ? event.eventCity + ", " : ""}
                      ${event.eventState ? event.eventState + ", " : ""}
                      ${event.eventZipCode ? event.eventZipCode + ", " : ""}
                      United States`;
    locationClassName = "in-person";
  }
  // In EventCard component
  const buttonRef = React.useRef(null);

  // When passing the button component, pass the ref as well
  <GlassBtnRef ref={buttonRef} /* other props */ />;

  const OnAnimate = (buttonType) => {
    setAnimations((prev) => ({
      ...prev,
      [buttonType]: true,
    }));
    setTimeout(() => {
      setAnimations((prev) => ({
        ...prev,
        [buttonType]: false,
      }));
    }, 2000);
  };

  // comment this out if you want to remove the confetti launching on mount
  useEffect(() => {
    showConfetti();
  }, []); // Empty dependency array means it will run once after the initial render

  const showConfetti = () => {
    const button = buttonRef.current;
    if (!button) {
      return;
    }
    const rect = button.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;
    const normalizedX = x / window.innerWidth;
    const normalizedY = y / window.innerHeight;

    confetti({
      particleCount: 150,
      spread: 60,
      origin: {
        x: normalizedX,
        y: normalizedY,
      },
    });
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(date);
  };
  return (
    <NavLink className="event-card" to={`/events/${id}`}>
      {event.eventImagePath && (
        <div className="event-image-container">
          <img
            alt={`Image for event: ${event.eventTitle || "Event Image"}`}
            src={event.eventImagePath}
          />
        </div>
      )}
      <div className="event-info">
        <div className="eventDate-s">
          <p>
            {eventStartDate && (
              <>
                Starts: {formatDate(new Date(eventStartDate))}
                {event.eventStartTime && ` @ ${event.eventStartTime}`}
              </>
            )}
            {/* commented end date and duration out for the events listings page */}
            {/* {eventStartDate && eventEndDate && " - "}
            {eventEndDate && (
              <>
                Ends: {formatDate(new Date(eventEndDate))}
                {event.eventEndTime && ` @ ${event.eventEndTime}`}
              </>
            )}
            <br />
            {eventStartDate &&
              eventEndDate &&
              `Duration: ${timeCalculator(eventStartDate, eventEndDate)} hours`} */}
          </p>
          {event.eventTitle && (
            <h1 className="eventTitle">{event.eventTitle}</h1>
          )}
           {/* no relestate for mobile so commentdd out description for eventslisting page */}
          {/* {event.eventDescription && (
            <p className="eventDescription">{event.eventDescription}</p>
          )} */}
        </div>
        {locationDetails && (
          <div className={`event-location-type ${locationClassName}`}>
            {getCapitalizedLocationDetail(locationDetails)}
          </div>
        )}
        <div>
          <span className="eventTotalInterested">
            {eventTotalInterested} people interested
          </span>
          <span className="spacer"></span>
          <span className="eventTotalAttending">
            {eventTotalAttending} people attending
          </span>
        </div>
      </div>
      <div className="button-container">
        {userAction ? (
          <GlassBtnRef
            glassBtnImage={
              <XIcon fillColor="#fdfd96" height="25px" width="25px" />
            }
            onClick={(e) => {
              e.preventDefault();
              setUserAction(false);
            }}
            glassBtnText="Cancel Interested"
            glassBtnFontColor="#fdfd96"
            glassBtnTextDecorationColor="#fdfd96"
            glassBtnWidth="fit-content"
            glassBtnTextFontSize="1.5rem"
            glassBtnHeight="40px"
            glassBtnFontFamily="Roboto-Slab"
            glassBtnFontWeight="900"
            extraStyles={
              {
                backdropFilter: "blur(40px)", // Corrected from "blur(40x)" to "blur(40px)"
              }
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-30px, 118px)",
              zIndex: 9,
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-30px, -118px)",
              zIndex: 9,
            }}
          />
        ) : (
          <GlassBtnRef
            glassBtnImage={
              <ClipBoardCheck fillColor="#fdfd96" height="25px" width="25px" />
            }
            ref={buttonRef}
            glassBtnText="Interested"
            glassBtnFontColor="#fdfd96"
            glassBtnTextDecorationColor="#fdfd96"
            glassBtnWidth="fit-content"
            glassBtnTextFontSize="1.5rem"
            glassBtnHeight="40px"
            glassBtnFontFamily="Roboto-Slab"
            glassBtnFontWeight="900"
            animationActive={true}
            extraStyles={
              {
                backdropFilter: "blur(40px)", // Corrected from "blur(40x)" to "blur(40px)"
              }
            }
            onClick={(e) => {
              e.preventDefault();
              setUserAction(true);
              showConfetti();
              console.log("Button clicked");
            }}
          />
        )}
      </div>
    </NavLink>
  );
};

export default EventCard;
