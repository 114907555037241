// WhiteBlendButton.jsx
import React from 'react';
import './whiteBlendBtn.scss';

const WhiteBlendButton = ({
    className = 'white-blend-btn',
    onClick,
    disabled = false,
    isSubmit = false,
    whiteBlendBtnImage = null,  // Set to null instead of empty string for JSX elements.
    whiteBlendBtnText = '',
    whiteBlendBtnTextFontSize,
    whiteBlendBtnWidth,
    whiteBlendBtnHeight,
    whiteBlendBtnFontFamily,
    whiteBlendBtnBoxShadow,
    whiteBlendBtnFontWeight,
    whiteBlendBtnFontColor,
    marginLeft = '0px',
    marginRight = '0px',
    animationActive = false,
    backgroundFilter,
    backdropFilter,
    extraStyles = {},
}) => (
    <button
        className={`white-blend-btn position-relative ${className}`}
        onClick={onClick}
        disabled={disabled}
        type={isSubmit ? "submit" : "button"}
        style={{
            width: whiteBlendBtnWidth,
            height: whiteBlendBtnHeight,
            boxShadow: `${whiteBlendBtnBoxShadow} !important`,
            marginLeft: marginLeft,
            marginRight: marginRight,
            animation: animationActive ? "active 0.9s alternate infinite" : "none",
            backgroundFilter:backgroundFilter,
            backdropFilter:backdropFilter,
            ...extraStyles,
        }}
    >
        {/* adjustig the image to be aligned with the font inside the button */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {whiteBlendBtnImage}
            <span
                style={{
                    marginLeft: whiteBlendBtnImage ? '2px' : '0',  // Add a small gap if there's an image.
                    fontSize: whiteBlendBtnTextFontSize,
                    fontFamily: whiteBlendBtnFontFamily,
                    fontWeight: whiteBlendBtnFontWeight,
                    color: whiteBlendBtnFontColor,
                }}
            >
                {whiteBlendBtnText}
            </span>
        </div>
    </button>
);

export default WhiteBlendButton;
