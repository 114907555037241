/* eslint-disable react/button-has-type */
import React from "react";
import "./customButton.scss";
// import CustomSpinner from './customSpinner';

const CustomButton = ({
  className = "",
  onClick, // Removed the default function
  disabled = false,
  isSubmit = false,
  image = "",
  customBtnText = "",
  customBtnFontColor,
  isLoading = false,
  customBtnBorderColor,
  customBtnTextFontSize, // changed it from fontSize for scale purposes
  customBtnWidth, 
  customBtnHeight, 
  customBtnFontFamily,
  customBtnBoxShadow,
  customBtnFontWeight,
  customBtnBorderRadius,
  marginLeft = "0px", // default to 0 if not provided
  marginRight = "0px",
  animationActive = false, // Default to false if not provided
  paddingTop,
  paddingBottom,
  paddingLeft,
  imagePadding, // Add this prop for image padding
  top,
  paddingRight, // passing left and right padding to over ride the default style locally
}) => {
  return (
    <button
      className={`globalized-btn position-relative ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
      style={{
        width: customBtnWidth, // Applied the width here
        height: customBtnHeight, // Applied the height here
        borderRadius: customBtnBorderRadius,
        borderColor: customBtnBorderColor,
        marginLeft: marginLeft,
        marginRight: marginRight,
        display: 'flex', // Aligns children (image and text) in a row
        alignItems: 'center', // Vertically centers the children
        justifyContent: 'center', // Horizontally centers the children
        top: top,
        boxShadow: `${customBtnBoxShadow} !important`, // putting important to override any css conflict
        animation: animationActive ? "active 0.9s alternate infinite" : "none",
      }} // styling it here gives more control and allows the font
      // to be styled seperately for the button without impacting eachother
    >
      {image && (
        // image and word was too close so needed a gap
        // adding the padding so the children will have space between when I apply the margin right
        <div className="image-container" style={{ marginRight: imagePadding }}> 
        {image}
        </div>
      )}
      <span
        // span in parent component is how you can style your props to be passed
        style={{
          fontSize: customBtnTextFontSize, // Applied the font size here
          fontFamily: customBtnFontFamily, //font-family control on custom Button Props
          fontWeight: customBtnFontWeight,
          fontColor: customBtnFontColor,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        }}
      >
        {customBtnText}
      </span>
      {/* {isLoading && <CustomSpinner/>} */}
    </button>
  );
};
export default CustomButton;
