import React from "react";
import "./receiptCard.scss";

const ReceiptCard = ({ receipt }) => {
  // Destructure the receipt properties you want to display
  const {
    orderSubTotalBal,
    grandTotalBal,
    invoiceStartDate,
    receiptConfirmedEndDate,
    totalDueBal,
    GiftCardBalUsed,
    beginningGiftCardBal,
    currentGiftCardBal,
    beginningPointsBalance,
    currentPointsBalance,
    orderPointsEarned,
    beginingRewardLevelName,
    beginingRewardLevel,
    currentRewardLevelName,
    currentRewardLevel,
  } = receipt;

  return (
    <div className="receipt-card">
      <div className="receipt-info">
        <p>Order Subtotal:{(orderSubTotalBal)}</p>
        <p>Grand Total: {(grandTotalBal)}</p>
        <p>Total Due: {(totalDueBal)}</p>
        <p>Gift Card Balance Used: ${(GiftCardBalUsed)}</p>
        <p>
          Beginning Gift Card Balance: {(beginningGiftCardBal)}
        </p>
        <p>Current Gift Card Balance: {(currentGiftCardBal)}</p>
        <p>
          Beginning Points Balance:
          {(beginningPointsBalance)}
        </p>
        <p>
          Current Points Balance:
          {(currentPointsBalance)}
        </p>
        <p>
          Order Points Earned: {(orderPointsEarned)}
        </p>
        <p>
          Beginning Reward Level: ({beginingRewardLevelName}, {(beginingRewardLevel)})
        </p>
        <p>
          Current Reward Level ({currentRewardLevelName}, {(currentRewardLevel)})
        </p>

        <p>Invoice Produced: {invoiceStartDate.toLocaleDateString()}</p>
        <p>Receipt Confirmed: {receiptConfirmedEndDate.toLocaleDateString()}</p>
      </div>
      <div className="receipt-actions">
        {/* Buttons or links for actions like 'View Details', 'Download', etc. */}
      </div>
    </div>
  );
};

export default ReceiptCard;
