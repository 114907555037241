import { INCREASE_GIFT_CARD_BALANCE, DECREASE_GIFT_CARD_BALANCE } from "../actions/actionGiftCardBalance.js";
const initialState = {
  balance: 0,
};
const giftCardBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_GIFT_CARD_BALANCE:
      return {
        ...state,
        // balance: Math.round(state.balance + action.payload / 100),
        // money value data type is rounding up to two decimal places and dropping any numbers after
        balance: +(state.balance + action.payload / 100).toFixed(2),
      };
    case DECREASE_GIFT_CARD_BALANCE:
      return {
        ...state,
        // balance: Math.round(state.balance - action.payload),
        // money value data type is rounding up to two decimal places and dropping any numbers after
        balance: +(state.balance - action.payload).toFixed(2),
      };
    default:
      return state;
  }
};

export default giftCardBalanceReducer;
