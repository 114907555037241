export const Rewardlevels = [
    {
      name: "member",
      pointsRange: [0, 999],
      rate: 1,
      icon: "star",
    },
    {
      name: "Star",
      pointsRange: [1000, 4999],
      rate: 1,
      icon: "star",
    },
    {
      name: "Silver",
      pointsRange: [5000, 9999],
      rate: 1.25,
      icon: "silver",
    },
    {
      name: "Gold",
      pointsRange: [10000, 14999],
      rate: 1.5,
      icon: "gold",
    },
    {
      name: "Platinum",
      pointsRange: [15000, 24999],
      rate: 1.75,
      icon: "platinum",
    },
    {
      name: "Black Diamond",
      pointsRange: [25000, Infinity],
      rate: 2,
      icon: "diamond",
    },
  ];
  export const REDEMPTION_BENEFITS = [
    {
      title: "Star",
      icon: "/starlevel.svg",
      benefit1: "Use a 1,000 points for a $10 Gift Card on Meet on up",
    },
    {
      title: "Silver",
      icon: "/silverlevel.svg",
      benefit1: "Use 5,000 points for a $50 Gift Card on Meet on up",
    },
    {
      title: "Gold",
      icon: "/goldlevel.svg",
      benefit1: "Use 10,000 points for a $100 Gift Card on Meet on up",
    },
    {
      title: "Platinum",
      icon: "/platinumlevel.svg",
      benefit1: "Use 15,000 points for a $150 Gift Card Meet on up",
    },
    {
      title: "Black Diamond",
      icon: "/diamondlevel.svg",
      benefit1: "Use 25,000 points a $250 Gift Card Meet on up",
    },
  ];
  export const POINTS_AND_EARNINGS = [
    {
      title: "Star",
      points: "1,000",
      earnings: "1.0",
      icon: "/starlevel.svg",
    },
    {
      title: "Silver",
      points: "5,000",
      earnings: "1.25",
      icon: "/silverlevel.svg",
    },
    {
      title: "Gold",
      points: "10,000",
      earnings: "1.50",
      icon: "/goldlevel.svg",
    },
    {
      title: "Platinum",
      points: "15,000",
      earnings: "1.75",
      icon: "/platinumlevel.svg",
    },
    {
      title: "Black Diamond",
      points: "25,000",
      earnings: "2.0",
      icon: "/diamondlevel.svg",
    },
  ];
  // frequently asked questions data
  export const FAQS = [
    {
      question: "Do points expire?",
      answer: "No",
    },
    {
      question: "Do Platinum and Diamond members earn points differently?",
      answer:
        "Yes. For every $1 spent, over 15,000 points, Platinum members earn 1.75 points. For every $1 spent, over 25,000 points, Diamond members earn 2 points. That's 75% and 100% faster, respectively. You can keep your status as long as you have points activity (purchasing, selling or redeeming) through Meet on up every 3 months after the first year.",
    },
    {
      question: "Do points expire?",
      answer:
        "You can keep your points as long as you have points activity (purchasing, selling or redeeming) through Meet on up every 3 months after the first year.",
    },
    {
      question: "How do I keep track of my points earned currently?",
      answer:
        "The member status shows you now many points you have earned. In your dashboard, you will find your points earned amount underneath the redeem button.",
    },
    {
      question: "Does Gift Card Balance Expire?",
      answer:
        "You can keep your Gift Card balance as long as you have account activity (purchasing, selling or redeeming) through Meet on up one year from last account activity.",
    },
    {
      question: "How do I keep track of my shared gift card amount?",
      answer:
        "In your dashboard, you will find your gift card amount shared underneath the the balance amount at the bottom.",
    },
  ];
  
  