import {
    SET_PLACE_ORDER,
    CANCEL_PLACE_ORDER
  } from "../actions/actionPlaceOrder.js";
  
  const initialState = {
    grandTotal: 0,
    orderSubTotal: 0,
    totalGiftCardBalance: 0,
    totalDue: 0,
  };
  
  const placeOrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PLACE_ORDER:
        return {
          ...state,
          ...action.payload,
        };
      case CANCEL_PLACE_ORDER:
        return initialState;
      default:
        return state;
    }
  };
  
  export default placeOrderReducer;
  