import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SiteHeader from "./siteHeader/siteHeader";
import { SiteLogo } from "../icons";
// import { setCurrentRewardLevel } from "../../redux/actions/actionCurrentRewardLevel";
// import { getCurrentRewardLevel } from "../constant/getCurrentRewardLevel";
import "./layout.scss";

const Layout = () => {
  // maybe access the points from here globally?
  // const dispatch = useDispatch()
  // const PointsBalance = useSelector(state => state.PointsBalance.balance)

  // useEffect(() => {
  //   dispatch(setCurrentRewardLevel(getCurrentRewardLevel(PointsBalance)));
  // }, []);

  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  const [headerStyle, setHeaderStyle] = useState({
    transform: "translateY(0)",
  });

  return (
    <div id="app-layout">
      {isLandingPage ? (
        <header id="landing-page-header" style={headerStyle}>
          Meet on up
          <SiteLogo />
        </header>
      ) : (
        <header id="global-header" className="sticky-header">
          <SiteHeader />
        </header>
      )}
      <main className={isLandingPage ? "is-landing-page" : ""}>
        <Outlet />
      </main>
    </div>
  );
};
export default Layout;

// why have conditional rendering and location example for this app
// The header that disappears on scroll on the landing page won’t be applied in another for the landing page class.
