import { combineReducers } from 'redux';
import authReducer from './reducerAuth.js';
import userReducer from "./reducerUser.js";
import uploadImageReducer from "./reducerUploadImage.js";
import pointsBalanceReducer from './pointsBalanceReducer.js';
import giftCardBalanceReducer from './giftCardBalanceReducer.js';
import placeOrderReducer from './placeOrderReducer.js';
import receiptReducer from './receiptReducer.js';
import currentRewardLevelReducer from './currentRewardLevelReducer.js';

const reducersGlobal = combineReducers({
  auth: authReducer,  // handle authentication related state
  user: userReducer, 
  uploadImage: uploadImageReducer,
  pointsBalance: pointsBalanceReducer, // redemption
  giftCardBalance: giftCardBalanceReducer, //conversions
  placeOrder: placeOrderReducer, // for crud dependency on frontend till final transactions
  receipt: receiptReducer, // send the receipt to the page and backed
  currentRewardLevel: currentRewardLevelReducer, // to update reward with redemptions
});

export default reducersGlobal;
