import React from 'react';
// import { REDEMPTION_BENEFITS } from './dashBoardWrapper';
import { REDEMPTION_BENEFITS } from '../../constants/rewardPoints';

const RedemptionBenefits = () => {
  return (
    <section className="redemption">
      <h1>Redemption Benefits</h1>
      {REDEMPTION_BENEFITS.map((benefit, index) => (
        <div key={index}>
          <p>
            <img src={benefit.icon} alt="" />
            <span>{benefit.title}</span>
          </p>
          <div>
            <p>
              <img src='/star-shape.svg' alt="" />
              <span>{benefit.benefit1}</span>
            </p>
            {/* <p>
              <img src='/star-shape.svg' alt="" />
              <span>
                <img className="textforI" src='/i-circle.svg' alt="" />{' '}
              </span>
            </p> */}
          </div>
        </div>
      ))}
    </section>
  );
};

export default RedemptionBenefits;
