import React, { useState, useEffect, useRef } from "react";
import { WhiteGoldButton, ToggleSlider, SlideBtn } from "../../buttons";
import { CommunityIcon } from "../../icons";
import DatePicker from "../../datePicker/datePicker";
import TimeDropdown from "../../TimeDropDown/timeDropDown";
import StateDropdown from "../../stateDropdown/stateDropDown";
import PrivateInviteOptions from "../../privateInviteOptions/privateInviteOptions";
import CustomCheckbox from "../../buttons/customCheckbox/customCheckBox";
import CustomRadioButton from "../../buttons/customRadioBtn/customRadioBtn";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import { createEventErrorHandler } from "../../../controller/errorHandlers/eventFormErrorHandler.js";
import {
  eventTitleWatcher,
  descriptionValueWatcher,
  eventCityWatcher,
  clearEventCity,
  clearEventTitle,
  clearEventDescription,
} from "../../../controller/watcherAndClearFunctions/eventClearsWatchersFunctions.js";
import FileUploadButton from "../profileEventGroupPicPageUploads/profileEventGroupPicPageUploads.jsx";
import "./eventForm.scss";

const EventPopup = ({
  onClose,
  eventFormOpen, // state is set on events list page
  updateEvent,
  contextType,
}) => {
  const currentContext = updateEvent ? "updateEvent" : "createEvent";
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [startTimeDropDownOpen, setStartTimeDropDownOpen] = useState(false);
  const [endTimeDropDownOpen, setEndTimeDropDownOpen] = useState(false);
  const [dateOrderError, setDateOrderError] = useState(false);
  const [eventZipCodeError, setEventZipCodeError] = useState(false);
  const [eventCityError, setEventCityError] = useState("");
  const [selectedStateError, setSelectedStateError] = useState("");
  const [eventVisibility, setEventVisibility] = useState("plublic");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showFollowers, setShowFollowers] = useState(true);
  const [showFollowing, setShowFollowing] = useState(true);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({}); // Object to manage which groups are selected
  const [selectedFollowers, setSelectedFollowers] = useState([]); // Object to manage which Followers are selected
  const [selectedFollowing, setSelectedFollowing] = useState([]); // Object to manage which Following are selected
  const [eventIsInPerson, setEventIsInPerson] = useState(false);
  const [eventIsRemote, setEventIsRemote] = useState(false);
  const [excludeChecked, setExcludeChecked] = useState(false);
  const [isStateDropDownOpen, setStateDropDownOpen] = useState(false);
  const [showManageEventOptions, setShowManageEventOptions] = useState(true);
  const [isEventInviteManager, setIsEventInviteManager] = useState(true);
  const [eventTitleFocused, setEventTitleFocused] = useState(false);
  const [cityFocused, setCityFocused] = useState(false);
  const [zipCodeFocused, setZipCodeFocused] = useState(false);
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [eventDescriptionValue, setEventDescriptionValue] = useState("");
  const [eventCityValue, setEventCityValue] = useState("");
  const [eventTitleValue, setEventTitleValue] = useState("");
  const [eventZipCodeValue, setEventZipCodeValue] = useState("");
  const [file, setFile] = useState(null);
  const [selectedState, setSelectedState] = useState(
    localStorage.getItem(
      getStorageKey("eventSelectedStateValue", currentContext)
    ) || ""
  ); // State value with local storage persistence for selected state value
  const [createEventAttempted, setCreateEventAttempted] = useState(false);
  const handleGroupSelect = (group, isSelected) => {
    setSelectedGroups((prev) => ({ ...prev, [group]: isSelected }));
  };
  const topOfEventFormRef = useRef(null);

  const groups = ["Group A", "Group B", "Group C"]; // Dummy list of groups

  const onCreateEvent = async () => {
    console.log("onCreateEvent triggered");
    setCreateEventAttempted(true);
  };
  useEffect(() => {
    if (createEventAttempted) {
      const { errors } = createEventErrorHandler(
        eventZipCodeValue,
        eventCityValue,
        selectedState,
        eventIsInPerson,
        currentContext,
        dateOrderError,
        setEventZipCodeError,
        setEventCityError,
        setSelectedStateError
      );
      console.log("Errors received:", errors);

      if (Object.keys(errors).length > 0) {
        // Log the error and scroll to the top of the form
        console.log("Form submission stopped due to errors:", errors);
        if (topOfEventFormRef && topOfEventFormRef.current) {
          topOfEventFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        resetEventForm();
      }
      setCreateEventAttempted(false); // Reset for next submission attempt
    }
  }, [
    createEventAttempted,
    eventZipCodeValue,
    eventCityValue,
    selectedState,
    dateOrderError,
    eventIsInPerson,
    currentContext,
    topOfEventFormRef,
    setEventZipCodeError,
    setSelectedStateError,
  ]);
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  const onUpdateEvent = async () => {
    // setCreateEventAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  const resetEventForm = () => {
    clearEventIsInPerson(setEventIsInPerson, currentContext);
    clearEventIsRemote(setEventIsRemote, currentContext);
    clearGuestIsEventInviteManager(setIsEventInviteManager, currentContext);
    clearState(setSelectedState, setStateDropDownOpen, currentContext);
    clearEventZipCode(setEventZipCodeValue, currentContext);
    clearEventCity(setEventCityValue, currentContext);
    clearEventTitle(setEventTitleValue, currentContext);
    clearEndDate(setEndDateValue, currentContext);
    clearStartDate(setStartDateValue, currentContext);
    clearEventDescription(setEventDescriptionValue, currentContext);
    clearEventVisibility(setEventVisibility, currentContext);
    resetPrivateState(
      setShowFollowers,
      setShowFollowing,
      setExcludeChecked,
      setSelectedFollowers,
      setSelectedFollowing,
      currentContext
    );
    onClose();
  };

  const followersList = ["Alice", "Bob", "Charlie"];
  const followingList = ["Dave", "Eve", "Frank"];

  const handleRemoveUser = (userListSetter, username) => {
    userListSetter((prevList) => prevList.filter((user) => user !== username));
  };

  useEffect(() => {
    // console.log("showManageEventOptions value:", showManageEventOptions);
  }, [showManageEventOptions]);

  useEffect(() => {
    const visibilityKey = getStorageKey("EventVisibility", currentContext);
    const storedVisibility = localStorage.getItem(visibilityKey);
    if (storedVisibility) {
      setEventVisibility(storedVisibility);
    }
  }, [currentContext]);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission
  };
  const clearEventZipCode = () => {
    setEventZipCodeValue("");
    const EventZipCodeKey = getStorageKey("eventZipCodeValue", currentContext);
    localStorage.removeItem(EventZipCodeKey);
  };
  const clearState = () => {
    // Define the key and hard coded currentContext to target payments
    const state = getStorageKey("State", currentContext);
    // Check if the key exists in localStorage and get the item
    if (localStorage.getItem(state) !== null) {
      localStorage.removeItem(state); // Remove the item
    } else {
      console.log("Key not found in localStorage:", state);
    }
    setSelectedState(""); // Clear the state
    setStateDropDownOpen(false);
  };
  useEffect(() => {
    if (selectedState) {
      const key = getStorageKey("State", currentContext);
      localStorage.setItem(key, selectedState);
      setSelectedState("State", selectedState);
      if (selectedStateError) {
        setSelectedStateError("");
      }
    }
  }, [selectedState, currentContext]);
  const clearStartDate = () => {
    const startDateKey = getStorageKey("startDateValue", currentContext);
    localStorage.removeItem(startDateKey);
    setStartDateValue("");
  };
  const clearEndDate = () => {
    const endDateKey = getStorageKey("endDateValue", currentContext);
    localStorage.removeItem(endDateKey);
    setEndDateValue("");
  };
  const handleStartDateChange = (startDate) => {
    const dateString = startDate ? startDate.toISOString() : "";
    const startDateKey = getStorageKey("startDateValue", currentContext);
    setStartDateValue(startDate);
    localStorage.setItem(startDateKey, dateString);
  };
  const handleEndDateChange = (endDate) => {
    const dateString = endDate ? endDate.toISOString() : "";
    const endDateKey = getStorageKey("endDateValue", currentContext);
    setEndDateValue(endDate); // Update state
    localStorage.setItem(endDateKey, dateString); // Update localStorage
  };
  const eventIsInPersonWatcher = (e) => {
    const check = e.target.checked; // Extract the checked state from the event object
    setEventIsInPerson(check);
    const EventIsInPersonKey = getStorageKey("EventIsInPerson", currentContext);
    localStorage.setItem(EventIsInPersonKey, check.toString());
  };
  const eventRemoteCheckWatcher = (e) => {
    const check = e.target.checked; // Extract the checked state from the event object
    setEventIsRemote(check);
    const EventIsRemoteKey = getStorageKey("EventIsRemote", currentContext);
    localStorage.setItem(EventIsRemoteKey, check.toString());
  };

  const eventZipCodeWatcher = (value) => {
    // Clear the error message when the user starts typing
    if (eventZipCodeError) {
      setEventZipCodeError("");
    }
    // Only allow values that are numbers
    if (/^\d*$/.test(value)) {
      setEventZipCodeValue(value);
      const EventZipCodeKey = getStorageKey(
        "eventZipCodeValue",
        currentContext
      );
      localStorage.setItem(EventZipCodeKey, value);
    }
    if (eventZipCodeError) {
      setEventZipCodeError("");
    }
  };
  const clearGuestIsEventInviteManager = (e) => {
    setIsEventInviteManager(false); // Update the state
    const GuestIsEventInviteManagerKey = getStorageKey(
      "GuestIsEventInviteManagerKey",
      currentContext
    );
    localStorage.removeItem(GuestIsEventInviteManagerKey); // Convert boolean to string for localStorage
  };

  const clearEventIsInPerson = () => {
    setEventIsInPerson(false); // Update the state
    const EventIsInPersonKey = getStorageKey("EventIsInPerson", currentContext);
    localStorage.removeItem(EventIsInPersonKey); // Convert boolean to string for localStorage
  };
  const clearEventIsRemote = () => {
    setEventIsRemote(false); // Update the state
    const EventIsRemoteKey = getStorageKey("EventIsRemote", currentContext);
    localStorage.removeItem(EventIsRemoteKey); // Convert boolean to string for localStorage
  };

  const inviteSelectedFollowerWatcher = (follower) => {
    const newSelectedFollowers = selectedFollowers.includes(follower)
      ? selectedFollowers.filter((f) => f !== follower)
      : [...selectedFollowers, follower];
    setSelectedFollowers(newSelectedFollowers);
    const selectedFollowersKey = getStorageKey(
      "selectedFollowers",
      currentContext
    );
    localStorage.setItem(
      selectedFollowersKey,
      JSON.stringify(newSelectedFollowers)
    );
  };

  const clearEventVisibility = () => {
    setEventVisibility("public");
    const visibilityKey = getStorageKey("EventVisibility", currentContext);
    localStorage.removeItem(visibilityKey);
  };
  const inviteFollowersWatcher = () => {
    const newShowFollowers = !showFollowers;
    setShowFollowers(newShowFollowers);
    const showFollowersKey = getStorageKey("showFollowers", currentContext);
    localStorage.setItem(showFollowersKey, newShowFollowers.toString());
  };

  const inviteFollowingWatcher = () => {
    const newShowFollowing = !showFollowing;
    setShowFollowing(newShowFollowing);
    const showFollowingKey = getStorageKey("showFollowing", currentContext);
    localStorage.setItem(showFollowingKey, newShowFollowing.toString());
  };
  const inviteExcludeCheckedWatcher = () => {
    const newExcludeChecked = !excludeChecked;
    setExcludeChecked(newExcludeChecked);
    const excludeCheckedKey = getStorageKey("excludeChecked", currentContext);
    localStorage.setItem(excludeCheckedKey, newExcludeChecked.toString());
  };

  const inviteSelectedFollowingWatcher = (following) => {
    const newSelectedFollowing = selectedFollowing.includes(following)
      ? selectedFollowing.filter((f) => f !== following)
      : [...selectedFollowing, following];
    setSelectedFollowing(newSelectedFollowing);
    const selectedFollowingKey = getStorageKey(
      "selectedFollowing",
      currentContext
    );
    localStorage.setItem(
      selectedFollowingKey,
      JSON.stringify(newSelectedFollowing)
    );
  };
  const guestIsEventInviteManagerWatcher = (position) => {
    const checked = position === "right"; // `isChecked` is true if position is "right"
    setIsEventInviteManager(checked);
    const GuestIsEventInviteManagerKey = getStorageKey(
      "GuestIsEventInviteManagerKey",
      currentContext
    );
    localStorage.setItem(GuestIsEventInviteManagerKey, checked.toString()); // Store in localStorage
  };
  const eventPermissionsWatcher = (visibility, currentContext) => {
    if (visibility === "public") {
      resetPrivateState(currentContext);
    }
    setEventVisibility(visibility);
    const visibilityKey = getStorageKey("EventVisibility", currentContext);
    localStorage.setItem(visibilityKey, visibility);
  };

  const resetPrivateState = (currentContext) => {
    setShowFollowers(true);
    setShowFollowing(true);
    setExcludeChecked(false);
    setSelectedFollowers([]);
    setSelectedFollowing([]);
    const keys = [
      getStorageKey("showFollowers", currentContext),
      getStorageKey("showFollowing", currentContext),
      getStorageKey("excludeChecked", currentContext),
      getStorageKey("selectedFollowers", currentContext),
      getStorageKey("selectedFollowing", currentContext),
    ];
    keys.forEach((key) => localStorage.removeItem(key));
  };

  return (
    <div
      id="eventForm"
      ref={topOfEventFormRef}
      className={`event-popup ${eventFormOpen ? "open" : "closed"}`}
    >
      {eventFormOpen && (
        <>
          <div className="popup-overlay" />
          <div className="popup-content">
            <WhiteGoldButton
              onClick={resetEventForm}
              className="cancel-button"
              marginLeft="70%"
              whiteGoldBtnText="X Cancel"
              whiteGoldBtnFontColor="#964B00"
              whiteGoldBtnWidth="100px"
              whiteGoldBtnFontWeight="800"
              whiteGoldBtnFontFamily="roboto-slab"
              whiteGoldBtnTextFontSize="1.3rem"
            />
            <div className="header">
              <h2 className="black">Event Details</h2>
            </div>
            <div className="input-container blinker-wrapper">
              <input
                placeholder="Enter Event Title"
                value={eventTitleValue}
                onChange={(e) =>
                  eventTitleWatcher(
                    e.target.value,
                    setEventTitleValue,
                    currentContext
                  )
                }
                onFocus={() => setEventTitleFocused(true)}
                onBlur={() => setEventTitleFocused(false)}
                className={`${
                  eventTitleFocused && eventTitleValue
                    ? "input-event-focused-value"
                    : ""
                } ${eventTitleFocused ? "focused-input" : ""} ${
                  eventTitleValue ? "input-withEvent-value" : ""
                }`}
              />
              {eventTitleValue && (
                <button
                  className="clear-button"
                  onClick={() =>
                    clearEventTitle(setEventTitleValue, currentContext)
                  }
                >
                  Clear
                </button>
              )}
              {!eventTitleFocused && eventTitleValue === "" && (
                <span className="blinking-cursor blinking">|</span>
              )}
            </div>
            <div className="header">
              <h2 className="black weight700">Host Name:</h2>
            </div>
            <p>User Name Value Goes Here</p>
            <label className="black weight700">
              What are the event details?
            </label>
            <div className="input-container blinker-wrapper description">
              <input
                placeholder="Description"
                value={eventDescriptionValue}
                onChange={(e) =>
                  descriptionValueWatcher(
                    e.target.value,
                    setEventDescriptionValue,
                    currentContext
                  )
                }
                onFocus={() => setDescriptionFocused(true)}
                onBlur={() => setDescriptionFocused(false)}
                className={`${
                  descriptionFocused && eventDescriptionValue
                    ? "input-event-focused-value"
                    : ""
                } ${descriptionFocused ? "focused-input" : ""} ${
                  eventDescriptionValue ? "input-withEvent-value" : ""
                }`}
              />
              {eventDescriptionValue && (
                <button
                  className="clear-button"
                  onClick={() =>
                    clearEventDescription(
                      setEventDescriptionValue,
                      currentContext
                    )
                  }
                >
                  Clear
                </button>
              )}
              {!descriptionFocused && eventDescriptionValue === "" && (
                <span className="blinking-cursor blinking margtop5percent">
                  |
                </span>
              )}
            </div>
            <div className="datetime-wrapper">
              <label className="black weight700">Start Date and Time:</label>
              <DatePicker
                currentContext={currentContext}
                onClick={() => setDatePickerOpen(true)}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
                clearStartDate={clearStartDate}
                clearEndDate={clearEndDate}
                setStartDateValue={setStartDateValue}
                setEndDateValue={setEndDateValue}
                startDateValue={startDateValue}
                endDateValue={endDateValue}
                onDateErrorChange={(dateOrderError) => {
                  console.log("Date Order Error Received:", dateOrderError);
                  setDateOrderError(dateOrderError);
                }}
                showAdditionalDate={false}
                isClearable={true}
                showYearDropdown={false}
                showMonthDropdown={true}
                dateFormat="MMMM d, yyyy Date"
                placeholder="Enter/Select  Date"
              />
              <TimeDropdown
                isEndTime={false}
                selectedTime={startTime}
                setSelectedTime={setStartTime}
                isOpen={startTimeDropDownOpen}
                onToggle={(value) => {
                  setStartTimeDropDownOpen(value);
                }}
              />
              <TimeDropdown
                isEndTime={true}
                selectedTime={endTime}
                setSelectedTime={setEndTime}
                isOpen={endTimeDropDownOpen}
                onToggle={(value) => {
                  setEndTimeDropDownOpen(value);
                }}
              />
            </div>
            <div className="header">
              <h2 className="black weight700">Who can see the event</h2>
            </div>
            <div>
              <div className="visibility-container">
                <div className="private-section">
                  <CustomRadioButton
                    label="Private"
                    name="visibility"
                    value="private-event"
                    checked={eventVisibility === "private"}
                    onChange={() => eventPermissionsWatcher("private")}
                  />
                  {/* <strong className="black">Private</strong> */}
                  {eventVisibility === "private" && (
                    <PrivateInviteOptions
                      showFollowers={showFollowers}
                      setShowFollowers={inviteFollowersWatcher} // Updated to use inviteFollowersWatcher
                      showFollowing={showFollowing}
                      setShowFollowing={inviteFollowingWatcher} // Updated to use inviteFollowingWatcher
                      excludeChecked={excludeChecked}
                      setExcludeChecked={inviteExcludeCheckedWatcher} // Updated to use inviteExcludeCheckedWatcher
                      followersList={followersList}
                      followingList={followingList}
                      setSelectedFollowers={inviteSelectedFollowerWatcher} // Updated to use inviteSelectedFollowerWatcher
                      selectedFollowers={selectedFollowers}
                      setSelectedFollowing={inviteSelectedFollowingWatcher} // Updated to use inviteSelectedFollowingWatcher
                    />
                  )}
                </div>
                <div className="group-section">
                  <label>
                    <CustomCheckbox
                      label="Group Members"
                      name="showGroupOptions"
                      checked={showGroupOptions}
                      onChange={() => setShowGroupOptions((prev) => !prev)}
                    />
                    <p>Members of a group that you are in</p>
                  </label>
                  {showGroupOptions &&
                    groups.map((group) => (
                      <label key={group} className="group-column">
                        <CustomCheckbox
                          key={group}
                          label={group}
                          name={group}
                          checked={!!selectedGroups[group]}
                          onChange={(e) =>
                            handleGroupSelect(group, e.target.checked)
                          }
                        />
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div>
              <CustomRadioButton
                label="Public"
                name="visibility"
                value="public-event"
                checked={eventVisibility === "public"}
                onChange={() => eventPermissionsWatcher("public")}
              />
              <p>Anyone can see the event on/off meetonup</p>
            </div>
            <div className="header">
              <h2 className="weight700 mrg-top">
                How will people attend the event?
              </h2>
            </div>
            <label>
              <CustomCheckbox
                label="In person"
                name="inPerson"
                checked={eventIsInPerson}
                onChange={eventIsInPersonWatcher}
              />
            </label>
            {eventIsInPerson && (
              <>
                <div className="input-container blinker-wrapper">
                  <input
                    placeholder={
                      eventCityError ? eventCityError : "Enter City Here"
                    }
                    className={eventCityError ? "required-message" : ""}
                    value={eventCityValue}
                    onChange={(e) =>
                      eventCityWatcher(
                        e.target.value,
                        setEventCityValue,
                        currentContext,
                        eventCityError,
                        setEventCityError
                      )
                    }
                    onFocus={() => setCityFocused(true)}
                    onBlur={() => setCityFocused(false)}
                  />

                  {eventCityValue && (
                    <button
                      className="clear-button"
                      onClick={() =>
                        clearEventCity(setEventCityValue, currentContext)
                      }
                    >
                      Clear
                    </button>
                  )}

                  {!cityFocused && eventCityValue === "" && (
                    <span className="blinking-cursor blinking">|</span>
                  )}
                </div>
                <div className="input-container blinker-wrapper">
                  <input
                    placeholder={
                      eventZipCodeError
                        ? eventZipCodeError
                        : "Enter Zip Code Here"
                    }
                    className={eventZipCodeError ? "required-message" : ""}
                    value={eventZipCodeValue}
                    onChange={(e) =>
                      eventZipCodeWatcher(
                        e.target.value,
                        setEventZipCodeValue,
                        currentContext,
                        eventZipCodeError,
                        setEventZipCodeError
                      )
                    }
                    onFocus={() => setZipCodeFocused(true)}
                    onBlur={() => setZipCodeFocused(false)}
                  />
                  {eventZipCodeError &&
                    eventZipCodeError !== "ZipCode is required" && (
                      <div className="error-message">{eventZipCodeError}</div>
                    )}
                  {eventZipCodeValue && (
                    <button
                      className="clear-button"
                      onClick={() =>
                        clearEventZipCode(setEventZipCodeValue, currentContext)
                      }
                    >
                      Clear
                    </button>
                  )}
                  {!zipCodeFocused && eventZipCodeValue === "" && (
                    <span className="blinking-cursor blinking">|</span>
                  )}
                </div>
                <StateDropdown
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  clearState={clearState}
                  isOpen={isStateDropDownOpen}
                  currentContext={currentContext}
                  onToggle={setStateDropDownOpen}
                />
                {selectedStateError && (
                  <div className="error-message">{selectedStateError}</div>
                )}
              </>
            )}
            <label>
              <CustomCheckbox
                label="Remotely"
                name="RemoteEvent"
                checked={eventIsRemote}
                onChange={eventRemoteCheckWatcher} // Pass only the function reference
              />
            </label>
            <div>
              <WhiteGoldButton
                onClick={() => setShowManageEventOptions((prev) => !prev)}
                // Toggle the visibility on button click
                whiteGoldBtnText="Manage Event Settings"
                whiteGoldBtnFontColor="#964B00"
                whiteGoldBtnWidth="fit-content"
                whiteGoldBtnFontWeight="800"
                whiteGoldBtnFontFamily="roboto-slab"
                whiteGoldBtnTextFontSize="1.3rem"
              />
              {showManageEventOptions && (
                <div>
                  <ToggleSlider
                    defaultPosition={isEventInviteManager ? "right" : "left"}
                    handleChangeSlider={guestIsEventInviteManagerWatcher}
                    activeContent="Yes"
                    inactiveContent="No"
                    checked={isEventInviteManager}
                    showGuestInviteText={true}
                    sliderWrapperStyle={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      left: "43%",
                      gap: "33px",
                    }}
                    sliderTextStyle={{
                      textAlign: "center", // Center the text
                    }}
                  />
                </div>
              )}
              <FileUploadButton
                onFileChange={handleFileChange} // Replace with your file change handler
              />
              {/* Add the SlideBtn component here, at the bottom of your popup content */}
              <SlideBtn
                slideBtnText={
                  updateEvent
                    ? "Slide to Update Event"
                    : "+ Slide to Create Event"
                }
                slideBtnWidth={updateEvent ? "250px" : "fit-content"}
                slidBtnFontSize="1rem"
                marginLeft={updateEvent ? "22%" : "20%"}
                sliderImage={() => (
                  <CommunityIcon
                    fillColor="#964B00"
                    width="40px"
                    height="30px"
                  />
                )}
                style={{
                  width: "200px",
                  border: "1px solid rgba(0, 0, 0, 0.6)",
                }}
                onHandleSlideEnd={(ev) => {
                  if (updateEvent) {
                    onUpdateEvent(ev, contextType); // Assuming you have an onUpdateEvent function
                  } else {
                    onCreateEvent(ev, contextType);
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default EventPopup;
