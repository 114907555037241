// ProfilePicture.jsx
import React from "react";

// profile={userId.Profile}: This prop provides the profile data to the ProfilePicture
// component. Inside the ProfilePicture, it will check if profile (in this case userId.Profile)
// has a valid image path to display. If it does, the image will be shown; otherwise,
// the SVG avatar will be rendered. see example below on how it is used
{
  /* <ProfilePicture profile={userId.Profile} width="120" height="150" fillColor="blue" />? */
}
// for now since we are not live we will use optional chaining and userData to avoid an undefined
// It allows reading the value of Profile within userId without having to explicitly check
// if userId is null or undefined. If userId is null or undefined, the expression short-circuits
// and returns undefined. This helps in preventing runtime errors.
{
  /* <ProfilePicture profile={userData?.Profile} width="120" height="150" fillColor="blue" /> */
}

const ProfilePicture = ({
  profileImgPath = null,
  className,
  borderRadius,
  borderColor = "black", // For both the profile pic and the SVG avatar
  borderWidth = "", // For both the profile pic and the SVG avatar
  imgWidth = "112.701", // In case I want a different width for the user's profile image
  imgHeight = "148.173", // In case I want a different height for the user's profile image
  fillColor = "black",
  avatarWidth, //  SVG avatar width
  avatarHeight, // SVG avatar height
  showBorder = true, // For the profile pic
  showAvatarBorder = false, // For the SVG avatar
}) => {
  console.log("Profile Image Path:", profileImgPath);

  const validAvatarWidth = Number(avatarWidth) || 100;   // default to 100 if invalid or undefined
  const validAvatarHeight = Number(avatarHeight) || 100; // default to 100 if invalid or undefined
  // Style definitions for the SVG avatar
  const avatarStyle = {
    width: avatarWidth,
    height: avatarHeight,
    border: showAvatarBorder ? `${borderWidth} solid ${borderColor}` : "none",
    borderRadius: borderRadius,
  };

  const imgStyle = {
    width: imgWidth,
    height: imgHeight,
    objectFit: "cover",
    border:
      profileImgPath && showBorder
        ? `${borderWidth} solid ${borderColor}`
        : "none",
    borderRadius: borderRadius,
  };

  return profileImgPath ? (
    <img
      src={profileImgPath}
      alt="User Profile Pic"
      className={`circle-profile-picture ${className}`}
      style={imgStyle}
    />
  ) : (
    <div style={avatarStyle} // call back for svg border
    className="avatar-wrapper"> {/* wrapping it to center the svg when a border is applied */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={avatarWidth}
      height={avatarHeight }
      viewBox={`0 -5 ${validAvatarWidth} ${validAvatarHeight}`}
      className={className}
    >
      <g
        id="profile-avatar"
        data-name="uni avtar-02"
        transform="translate(-59.85 -13.778)"
      >
        <path
          fill={fillColor}
          id="Path_25"
          data-name="Path 25"
          d="M176.344,86.244c16.475,0,29.83-16.222,29.83-36.233s-4.385-36.233-29.83-36.233S146.512,30,146.512,50.011,159.868,86.244,176.344,86.244Z"
          transform="translate(-60.143 0)"
        />
        <path
          fill={fillColor}
          id="Path_26"
          data-name="Path 26"
          d="M59.856,429.968c-.005-1.22-.01-.344,0,0Z"
          transform="translate(0 -288.4)"
        />
        <path
          fill={fillColor}
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.99c.016-.334.005-2.317,0,0Z"
          transform="translate(-255.58 -289.469)"
        />
        <path
          fill={fillColor}
          id="Path_28"
          data-name="Path 28"
          d="M172.491,310.76c-.553-34.859-5.106-44.793-39.944-51.08,0,0-4.9,6.249-16.335,6.249s-16.335-6.249-16.335-6.249c-34.459,6.219-39.288,16-39.924,49.948-.052,2.772-.076,2.917-.085,2.6,0,.6,0,1.718,0,3.662,0,0,8.294,16.721,56.34,16.721s56.34-16.721,56.34-16.721c0-1.249,0-2.118,0-2.709A22.4,22.4,0,0,1,172.491,310.76Z"
          transform="translate(-0.012 -170.656)"
        />
      </g>
    </svg>
    </div>
  );
};

export default ProfilePicture;
