import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAddZerosWithCursor } from "../../components/constant/dashboardValidation";
import { renderArrows } from "./dashBoardWrapper"; // A utility for rendering arrows, possibly for UI navigation.
import {
  getCurrentRewardLevel,
  getCurrentRewardLevelName,
} from "../../components/constant/getCurrentRewardLevel"; // Fetches the current reward level based on points.
import InfinitySlider from "../../components/InfinitySlider/infinitySlider"; // A UI component, possibly for sliding through rewards or similar.
import { ImgTopBtn, GlassBtn } from "../../components/buttons/index";
const DashboardSlider = ({
  pointsBalance,
  setPointsBalance,
  giftCardBalance,
  setGiftCardBalance,
}) => {
  console.log("DashboardSlider rendering");
  // A map to track the active slide. It appears to switch between points and gift card slides.
  const dashboardSliderMap = {
    1: "points",
    2: "giftcard",
    points: 1,
    giftcard: 2,
  };
  // const [escrowBalance, setEscrowBalance] = useState(0);
  const [openModal, setOpenModal] = useState(false); // State to track if a modal is open or not.
  const [activeSlide, setActiveSlide] = useState(1); // Tracks which slide is currently active. Initialized to the 'points' slide.
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Tracks if it's the initial load of the component.
  const dashboardSliderRef = useRef(null); // A reference to the slider element, possibly for direct DOM manipulations or focus.
  const [currentRewardLevelName, setCurrentRewardLevelName] = useState("");
  const [currentRewardLevelRate, setCurrentRewardLevelRate] = useState("");
  const isGiftCardSlideActive = activeSlide === dashboardSliderMap["giftcard"];

  // Get the current reward rate based on points
  useEffect(() => {
    console.log("DashboardSlider mounted");
    window.scrollTo(0, 0);

    const slideChangeTimeout = setTimeout(() => {
      if (dashboardSliderRef.current) {
        dashboardSliderRef.current.handleNext();
        // Determine the new active slide index, get its name, and update it
        const newActiveSlideIndex = activeSlide === 1 ? 2 : 1;
        const newActiveSlideName = dashboardSliderMap[newActiveSlideIndex];
        setActiveSlide(newActiveSlideIndex);
        // Log the new active slide index and name
        console.log(
          "New Active Slide Index:",
          newActiveSlideIndex,
          "Name:",
          newActiveSlideName
        );
      }
    }, 500);
    return () => {
      console.log("Clearing automatic slide change");
      clearTimeout(slideChangeTimeout);
    };
  }, []); // Ensure this array is empty
  // The useEffect dependency array is empty, so this runs only on mount
  // Empty dependency array for on-mount effect// Empty dependency array to ensure this runs only once on mount

  // This hook gets the current reward level based on the points balance and sets the 'nameLevel' state.
  // Function to update the reward level name and rate based on points balance
  const handleSetCurrentRewardLevel = useCallback((balance) => {
    const currentRewardLevelRate = getCurrentRewardLevel(balance); // This gets the rate
    const currentRewardLevelName = getCurrentRewardLevelName(balance); // This gets the name

    console.log("Reward Level Rate:", currentRewardLevelRate);
    console.log("Reward Level Name:", currentRewardLevelName);

    setCurrentRewardLevelName(currentRewardLevelName); // Setting the name
    setCurrentRewardLevelRate(currentRewardLevelRate); // Setting the rate
  }, []);

  // Effect to update reward level name and rate when pointsBalance changes
  useEffect(() => {
    handleSetCurrentRewardLevel(pointsBalance);
  }, [pointsBalance, handleSetCurrentRewardLevel]);

  // importing required variables and functions from the `useAddZerosWithCursor` hook
  const {
    originalValue,
    currentValue,
    inputRef,
    handleChange,
    setCursor,
    setCurrentValue,
    setOriginalValue,
  } = useAddZerosWithCursor();

  // toggle redeem modal and clear input field
  // or cancel trasnaction and clear input field
  const toggleRedeemModal = () => {
    setOriginalValue(0);
    setCurrentValue("");
    setOpenModal(!openModal);
  };

  // Function to decrease points. Only decrements if the resultant value isn't negative.
  const handleDecreasePoints = useCallback(
    (n) => {
      if (pointsBalance - n >= 0) {
        setPointsBalance((prev) => prev - n);
      }
    },
    [pointsBalance]
  );

  // Function to increase points.
  const handleIncreasePoints = useCallback((n) => {
    setPointsBalance((prev) => prev + n);
  }, []);

  // const handleDecrementEscrowpoints = () => {
  //   // setpointsBalance((prevBalance) => prevBalance + 1500);
  //   // setGiftCardBalance((prevBalance) => prevBalance + 1500);
  // };
  // Redeem points. Converts points to a dollar value and adds it to the gift card balance.
  const redeemPoint = useCallback(() => {
    console.log("redeemPoint called");
    if (originalValue <= pointsBalance) {
      setPointsBalance((prev) => prev - originalValue);
      // Convert the originalValue (in points) to dollars
      const dollarValue = originalValue * 0.01;
      setGiftCardBalance((prev) => prev + dollarValue); // Ensure you're adding dollarValue here
      toggleRedeemModal();
    }
  }, [originalValue, pointsBalance, setPointsBalance]);
  const getCarouselClass = () => {
    const carouselClass =
      activeSlide === dashboardSliderMap["giftcard"]
        ? "gift-card-carousel"
        : "points-carousel";
    console.log("Carousel Class:", carouselClass); // Check the output in the browser console
    return carouselClass;
  };

  // data for each dashboard
  const Meet_on_up_DASHBOARDS = [
    <div
      className="points border points-balace-container dashboard-points"
      key="points"
      id="points"
    >
      <div className="points-left">
        <div>
          <p className="points-header">Points</p>
          <p className="header-level" id="point_level" />
          <p id="level_status">...{currentRewardLevelName}</p>
          <p className="info-flex hide-mobile underline-gold">
            <img src="/info.svg" alt="" />
            VIEW DETAILS
          </p>
        </div>
      </div>
      <div className="points-center">
        <div className="AvailPntsBal">
          <p>
            Balance
            {/* Available Points Balance for larger screens*/}
            <br />
            <span
              className="AvailPntsBal-figure"
              data-value={pointsBalance}
              id="AvailPntsBal"
            >
              {pointsBalance.toLocaleString()}
            </span>
          </p>
          <button
            type="button"
            className="points-testing-decrease-slider"
            style={{ position: "relative", right: "-25px", fontSize: "1rem" }}
            onClick={() => handleDecreasePoints(1000)}
          >
            Decrease (-1000)
          </button>
          <button
            type="button"
            className="points-testing-increase-slider"
            style={{ position: "relative", left: "45px", fontSize: "1rem" }}
            onClick={() => handleIncreasePoints(1000)}
          >
            Increase (+1000)
          </button>
          {/* <RewardStatus pointsBalance={pointsBalance} /> */}
        </div>
      </div>
      <div className="points-right">
          <div>
            <button
              className={`redeemButton ${
                pointsBalance < 999 ? "redeem-disabled" : ""
              } redeem_btn posbtnleft`}
              onClick={toggleRedeemModal}
            >
              Redeem
            </button>
        </div>
      </div>
    </div>,
    <div
      className="points points-balace-container dashboard-gift-balance"
      key="giftCard"
      id="giftCard"
    >
      <div className="points-left">
        <div>
          <p className="points-header">Level</p>
          <p className="header-level" id="point_level" />
          <p id="level_status">...{currentRewardLevelName}</p>
          <p className="info-flex hide-mobile underline-gold">
            <img src="/info.svg" alt="" /> VIEW DETAILS
          </p>
        </div>
      </div>
      <div className="giftcard-center">
        <div className="GiftCardBal">
          <div className="giftcard-avail-content">
            BALANCE
            <br />
            <p className="dollarIcon">
              <img className="GiftCardBalanceLeft" src="/dollar.svg" alt="" />
              <span id="GiftCardBalance">
                {/* Parse the giftCardBalance as a float.
                  Use the toLocaleString() method to format the number, ensuring that:
                  The format follows the US style for thousands separator (i.e., commas).
                  Always has two decimal places by setting both the minimumFractionDigits and maximumFractionDigits to 2.
                  giftCardBalance will always show two decimal places and will have commas for values in the thousands or more. */}
                {parseFloat(giftCardBalance).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
              <img className="GiftCardBalanceRight" src="/zeros.svg" alt="" />
            </p>
          </div>
          <div className="giftcard-footer">
            <h4 className="dashboard-name">Gift Card</h4>
          </div>
        </div>
      </div>
    </div>,
  ];
  return (
    <div>
      <div
        className="dashboardSlider"
        style={{
          backgroundColor:
            activeSlide === dashboardSliderMap["giftcard"]
              ? "rgba(0, 0, 0, 0.7)" // Color for point slide
              : "rgba(0, 0, 0, 0.3)", // Color for other slides
        }}
      >
        <div id="dashboard"></div>
        {renderArrows(
          dashboardSliderRef,
          activeSlide,
          setActiveSlide,
          dashboardSliderMap
        )}
        <InfinitySlider
          transitionDuration={isInitialLoad ? "0.6s" : "0.3s"}
          className={getCarouselClass()}
          activeSlide={activeSlide}
          onSlideChange={setActiveSlide}
          slides={Meet_on_up_DASHBOARDS}
          ref={dashboardSliderRef}
        />
      </div>
      {/* redeem modal */}
      <div
        id="redeem_modal"
        className="redeem-modal"
        style={{ display: openModal ? "block" : "none" }}
      >
        <div
          className={`redeem-modal-content ${
            openModal ? "slideDown" : "slideUp"
          }`}
        >
          <div className="modal-content">
            <div className="modal-logo ">
              <span id="reedmHdr">
                <p>Meet on up</p>
              </span>
              <img src="/meetonup.svg" alt="" />
            </div>
            <div id="RdmPopup">
              <ImgTopBtn
                id="cancel-btn"
                className="close-redeem-modal"
                imgTopBtnBorderRadius="25px"
                imgTopBtnText=" X Cancel"
                imgTopBtnWidth="fit-content"
                imgTopBtnTextFontSize="1.3rem"
                imgTopBtnnFontFamily="Roboto-Slab"
                imgTopBtnFontWeight="900"
                imgTopBtnFontColor="#964b00"
                paddingRight="5px"
                paddingLeft="5px"
                extraImgTopBtnStyles={{
                  left: "70%",
                }}
                onClick={() => {
                  toggleRedeemModal();
                }}
              />
              <p className="RdmInptTitle">Enter Points Amount</p>
              <div id="redeemInput" className="blinker-wrapper">
                <input
                  type="text"
                  className="input-redeem-values profileMobileNumClass"
                  ref={inputRef}
                  onChange={handleChange}
                  value={currentValue}
                  onFocus={setCursor}
                  onKeyDown={setCursor}
                  onKeyUp={setCursor}
                  onClick={setCursor}
                  maxLength={16}
                  placeholder=" "
                />
                <span className="blinking-cursor small-cursor">|</span>
              </div>
              <div className="redeemBtnErrorMessage">
                You can redeem up to the $
                <span className="errorPointLimitAmount" />
              </div>
              <GlassBtn
                id="apply-redeem-btn"
                disabled={currentValue < 1 || originalValue > pointsBalance}
                className={
                  currentValue < 1 || originalValue > pointsBalance
                    ? "inactive-button"
                    : "active-button"
                }
                glassBtnText="Press here to redeem"
                glassBtnTextFontSize="1.3rem"
                glassBtnFontWeight="900"
                glassBtnTextDecoration="none"
                glassBtnHeight="30px"
                glassBtnFontColor={
                  currentValue < 1 || originalValue > pointsBalance
                    ? "#707070"
                    : "#f1cb55"
                }
                onClick={redeemPoint} // Ensure redeemPoint is defined and is the correct function to call
                extraStyles={{
                  top: "10px",
                  border: "2px solid",
                  borderColor:
                    currentValue < 1 || originalValue > pointsBalance
                      ? "#a5a7aa"
                      : "#f1cb55",
                  cursor:
                    currentValue < 1 || originalValue > pointsBalance
                      ? "no-drop"
                      : "pointer",
                }}
                glassHoverStyle={{
                  height: "73px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardSlider;
