// storage
import localforage from "localforage";
// This will use a different driver order.

export default function buildPersistStoreConfigs() {
  return {
    driver: [localforage.WEBSQL,
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE],
    debug: false,
    key: "meetonup",
    storage: localforage,
    version: 1,
    whitelist: ["auth", "user"],
  };
}