import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import mockData from "../../seedMockData/seedMockData.js";
import { handleFileChange } from "../../utils/changeFileHandler.js";
import UserComment from "../comment/comment.jsx";
import UserReply from "../userReply/userReply.jsx";
import {
  onThreadReply,
  startThreadReply,
  onJoinThread,
} from "../../controller/errorHandlers/commentListenersErrorHanders.js";
import { AvatarThumbNailIcon } from "../icons/index.jsx";
import { clearReplyStateAndStorage } from "../../controller/watcherAndClearFunctions/commentClearsWatchersFunctions.js";
import { PlainBtn } from "../buttons/index.jsx";
import EmotionEmoji from "../emoji/emoji.jsx";
import "./userPostDetails.scss";

const PostDetails = ({
  userCommentWatcher,
  clearUserPostCommentLocalStorage,
  triggerFileUpload,
}) => {
  const currentContext = "updatePost";
  const { postId } = useParams();
  const modalRef = useRef();
  const commentSectionRef = useRef(null); // Ref for the comment section
  const loggedInUser = {
    id: "user-uuid-1",
    name: "AcctHolder",
  };
  const loggedInUserInfo = mockData.users.find(
    (user) => user.id === loggedInUser.id
  );

  const [postDetails, setPostDetails] = useState({
    mediaPath: "",
    comments: [], // grabbing comments and replies on this component
  });
  const [activeReply, setActiveReply] = useState({ index: null, username: "" });
  const [joinThread, setJoinThread] = useState(false);
  const [startThread, setStartThread] = useState(false);
  const [threadReply, setThreadReply] = useState(false);
  const [replyValue, setReplyValue] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [selectedEmojiId, setSelectedEmojiId] = useState(null);
  const [activeExpressionId, setActiveExpressionId] = useState(null);
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const [teaserComment, setTeaserComment] = useState(null);
  const [viewRepliesForCommentId, setViewRepliesForCommentId] = useState(null);

  useEffect(() => {
    console.log("PostId:", postId); // Debug postId
    const post = mockData.posts.find((p) => p.id === postId);
    // Since you are now directly including the imagePath in the post object,
    // you no longer need to find the media separately.
    const imagePath = post?.imagePath; // Directly access imagePath from post object
    console.log("Media Path:", imagePath); // Debug media path
    const comments = mockData.comments
      .filter((comment) => comment.postId === postId)
      .map((comment) => {
        const threads = mockData.commentThreads
          .filter((thread) => thread.id === comment.threadId)
          .sort((a, b) => a.autoIndex - b.autoIndex); // Ascending order
        return { ...comment, threads };
      });
    setPostDetails({ ...post, mediaPath: imagePath, comments });
  }, [postId]);
  // useEffect to clear reply state and local storage when activeReply changes
  useEffect(() => {
    clearReplyStateAndStorage(setReplyValue, currentContext, activeReply);
  }, [setReplyValue, activeReply, currentContext]);
  // In the component where setJoinStartThread is defined
  useEffect(() => {
    console.log("startThread state updated:", startThread);
  }, [startThread]); // This useEffect will run whenever joinStartThread changes

  useEffect(() => {
    console.log("Thread Reply State: ", threadReply);
  }, [threadReply]);
  // Toggle the visibility of the emojis and set the active expression ID
  // Function to handle the emoji button click
  const handleEmojiButton = (commentId, threadId = null) => {
    const expressionId = threadId ? `${commentId}-${threadId}` : commentId;
    console.log(
      `handleEmojiButton: expressionId = ${expressionId}, activeExpressionId = ${activeExpressionId}`
    );
    if (expressionId !== activeExpressionId || !showEmojis) {
      console.log(
        "Opening emoji selector for a new target or was previously closed"
      );
      setShowEmojis(true);
      setActiveExpressionId(expressionId);
    } else {
      console.log("Toggling emoji selector visibility for the same target");
      setShowEmojis(!showEmojis);
    }
  };
  // Function to handle emoji selection
  const chooseEmoji = (emojiId) => {
    if (emojiId) {
      console.log("Selected emoji:", emojiId);
      setSelectedEmojiId(emojiId); // Update the state with selected emoji ID
      setShowEmojis(false); // Close the emoji selector
      // Additional logic for emoji selection can be added here
    }
  };

  const scrollToComments = () => {
    if (commentSectionRef.current) {
      // Calculate the position of the comment section
      const commentSectionTop =
        commentSectionRef.current.getBoundingClientRect().top;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offset = 20; // Adjust this offset as needed for any headers/margins

      // Scroll to the comment section
      window.scrollTo({
        top: commentSectionTop + scrollTop - offset,
        behavior: "smooth",
      });
    }
  };

  const toggleCommentsModal = () => {
    setCommentsModalVisible(!commentsModalVisible); // This will toggle the state

    // If we're opening the comments, scroll to them
    if (!commentsModalVisible) {
      // Delay the scroll action to account for dynamic content loading
      setTimeout(() => {
        scrollToComments();
      }, 300); // Adjust the delay as needed
    }
  };

  // Function to toggle viewing replies for a comment
  const toggleViewReplies = (commentId) => {
    if (viewRepliesForCommentId === commentId) {
      setViewRepliesForCommentId(null);
    } else {
      setViewRepliesForCommentId(commentId);
    }
  };
  useEffect(() => {
    // Assuming your comments are sorted, pick the first one as the teaser
    // Later, this can be adjusted to pick a comment based on different criteria
    const firstComment = postDetails.comments[0];
    if (firstComment) {
      // If there's a thread (reply), include the first reply with it
      const firstReply = firstComment.threads && firstComment.threads[0];
      setTeaserComment({
        ...firstComment,
        threads: firstReply ? [firstReply] : [],
      });
    }
  }, [postDetails.comments]);
  // useEffect to set the teaser comment when the component mounts
  // Function to render a single comment block, which is our teaser comment
  // will post the condition in the jsx file as a one liner
  const renderCommentBlock = (comment) => (
    <div key={comment.id} className="comment-block">
      <div className="comment-top-level">
        {mockData.users.find((user) => user.id === comment.sourceId)
          ?.imagePath ? (
          <img
            src={
              mockData.users.find((user) => user.id === comment.sourceId)
                .imagePath
            }
            alt="User Thumbnail"
            className="commentThumbnail"
          />
        ) : (
          <AvatarThumbNailIcon className="default-avatar-icon" />
        )}
        <div className="userNameCommentWrapper">
          <p className="comment-text">{comment.text}</p>
          <p className="comment-username">
            {mockData.users.find((user) => user.id === comment.sourceId)
              ?.username || "Unknown User"}
          </p>
        </div>
      </div>
      {/* ...other comment elements like reply buttons, emojis, etc... */}
    </div>
  );

  return (
    <div className="post-details-container">
      {/* Media Section */}
      <div className="media-section">
        {postDetails.mediaPath && (
          <img src={postDetails.mediaPath} alt="Post media" />
        )}
      </div>
      {/* Trigger Button for Comments Modal */}
      {/* Combined Comment and Discussion Input Section */}
      <div className="comment-and-discussion-section" ref={commentSectionRef}>
        <button onClick={toggleCommentsModal}>
          {commentsModalVisible ? "Hide Comments" : "View All Comments"}
        </button>

        {/* Teaser Comment */}
        {/* Render only the teaser comment when the modal isn't open */}
        {!commentsModalVisible &&
          teaserComment &&
          renderCommentBlock(teaserComment)}
        {/* Comments Modal */}
        {commentsModalVisible && (
          <div className="modal" ref={modalRef}>
            <div className="modal-content">
              <div className="comments-container">
                {/* Map over comments to render them */}
                {/* Render each comment and its threads */}
                {postDetails.comments.map((comment) => (
                  <div key={comment.id} className="comment-block">
                    {/* Top-level Comment */}
                    <div className="comment-top-level">
                      {mockData.users.find(
                        (user) => user.id === comment.sourceId
                      )?.imagePath ? (
                        <img
                          src={
                            mockData.users.find(
                              (user) => user.id === comment.sourceId
                            ).imagePath
                          }
                          alt="User Thumbnail"
                          className="commentThumbnail"
                        />
                      ) : (
                        <AvatarThumbNailIcon className="default-avatar-icon" />
                      )}
                      <div className="userNameCommentWrapper">
                        <p className="comment-text">{comment.text}</p>
                        <p className="comment-username">
                          {mockData.users.find(
                            (user) => user.id === comment.sourceId
                          )?.username || "Unknown User"}
                        </p>
                      </div>
                    </div>
                    {/* Button to join an existing thread or start a new one */}
                    <div className="Express-Respons-btns">
                      <PlainBtn
                        plainBtnText={
                          comment.threads && comment.threads.length > 0
                            ? "Join Thread"
                            : "Start Thread"
                        }
                        onClick={() =>
                          comment.threads && comment.threads.length > 0
                            ? onJoinThread(
                                comment,
                                setActiveReply, // State setter function from useState
                                setJoinThread // State setter function from useState
                              )
                            : startThreadReply(
                                comment,
                                startThread, // Assuming startThread is available in this scope
                                setActiveReply, // Assuming setActiveReply is available in this scope
                                setStartThread // Assuming setStartThread is available in this scope
                              )
                        }
                        plainBtnTextFontSize="1rem"
                        plainBtnWidth="fit-content"
                        plainBtnHeight="40px"
                        plainBtnFontFamily="Roboto"
                        plainBtnFontWeight="bold"
                        plainBtnFontColor="#964b00"
                        plainBtnBorderRadius="5px"
                        extraStyles={{
                          paddingRight: "10px",
                        }}
                      />
                      {/* <EmojiChoices 
                      {/* Emoji Button for each comment */}
                      <PlainBtn
                        plainBtnText="Reaction"
                        onClick={() => handleEmojiButton(comment.id)}
                        image={
                          <span
                            role="img"
                            aria-label="heart"
                            className="pulsing-heart"
                          >
                            ❤️
                          </span>
                        }
                        imageOnTop={false} // Set to true if you want the emoji above the text
                        extraStyles={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                        plainBtnHeight="40px"
                        plainBtnFontFamily="Roboto"
                        plainBtnFontWeight="bold"
                        plainBtnFontColor="#964b00"
                        plainBtnBorderRadius="5px"
                      />
                      {/* Show Emojis for this comment if conditions are met */}
                      {showEmojis && activeExpressionId === comment.id && (
                        <EmotionEmoji onSelectEmoji={chooseEmoji} />
                      )}
                    </div>
                    {/* Display UserReply component if activeReply matches this comment */}
                    {activeReply.commentId === comment.id && ( // allows user to start or join a new thread
                      <UserReply
                        currentContext={"updatePost"}
                        targetId={comment.id}
                        loggedInUserInfo={loggedInUserInfo}
                        activeReply={activeReply}
                        startThread={startThread}
                        joinThread={joinThread}
                        setActiveReply={setActiveReply}
                        setStartThread={setStartThread}
                        setJoinThread={setJoinThread}
                      />
                    )}
                    {/* Button to View Replies */}
                    {/* {comment.threads && comment.threads.length > 0 && (
                    <button onClick={() => toggleViewReplies(comment.id)}>
                      {viewRepliesForCommentId === comment.id ? 'Hide Replies' : 'View Replies'}
                    </button>
                  )} */}
                    {/* Thread Replies */}
                    {/* {viewRepliesForCommentId === comment.id && comment.threads.map((thread) => ( */}
                    {comment.threads.map((thread) => (
                      <div key={thread.autoIndex} className="thread-reply">
                        <div className="userThreadBubblePosition">
                          {mockData.users.find(
                            (user) => user.id === thread.userId
                          )?.imagePath ? (
                            <img
                              src={
                                mockData.users.find(
                                  (user) => user.id === thread.userId
                                ).imagePath
                              }
                              alt="User Thumbnail"
                              className="replyThumbnail"
                            />
                          ) : (
                            <AvatarThumbNailIcon className="default-avatar-icon" />
                          )}
                          <div className="userNameThreadWrapper">
                            <p className="thread-text">{thread.text}</p>
                            <p className="thread-username">
                              {mockData.users.find(
                                (user) => user.id === thread.userId
                              )?.username || "Unknown User"}
                            </p>
                          </div>
                        </div>
                        <div className="Express-Respons-btns">
                          <PlainBtn
                            plainBtnText="Reply"
                            onClick={() =>
                              onThreadReply(
                                thread.userId,
                                thread.id,
                                thread.autoIndex,
                                currentContext, // State from this component
                                activeReply, // State from this component
                                setReplyValue, // State setter from useState in this component
                                setActiveReply, // State setter from useState in this component
                                setThreadReply // State setter from useState in this component
                              )
                            }
                            plainBtnTextFontSize="1rem"
                            plainBtnWidth="fit-content"
                            plainBtnHeight="40px"
                            plainBtnFontFamily="Roboto"
                            plainBtnFontWeight="bold"
                            plainBtnFontColor="#964b00"
                            plainBtnBorderRadius="5px"
                            extraStyles={{
                              paddingRight: "10px",
                            }}
                          />
                          {/* Emoji Button for each thread */}
                          <PlainBtn
                            plainBtnText="Reaction"
                            onClick={() =>
                              handleEmojiButton(thread.id, thread.autoIndex)
                            }
                            image={
                              <span role="img" aria-label="heart">
                                ❤️
                              </span>
                            } // Pass the heart emoji as the image prop
                            imageOnTop={false} // Set to true if you want the emoji above the text
                            extraStyles={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                            plainBtnHeight="40px"
                            plainBtnFontFamily="Roboto"
                            plainBtnFontWeight="bold"
                            plainBtnFontColor="#964b00"
                            plainBtnBorderRadius="5px"
                          />
                          {/* Show Emojis for this thread if conditions are met */}
                          {/* Show Emojis for this specific thread */}
                          {showEmojis &&
                            activeExpressionId ===
                              `${thread.id}-${thread.autoIndex}` && (
                              <EmotionEmoji onSelectEmoji={chooseEmoji} />
                            )}
                        </div>
                        {activeReply.threadId === thread.id && // allows user to reply to a thread id with an index
                          activeReply.autoIndex === thread.autoIndex && (
                            <UserReply
                              currentContext={"updatePost"}
                              targetId={thread.id}
                              loggedInUserInfo={loggedInUserInfo}
                              activeReply={activeReply}
                              setActiveReply={setActiveReply}
                              threadReply={threadReply}
                              setThreadReply={setThreadReply}
                            />
                          )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <UserComment // starts a new comment
          loggedInUserInfo={loggedInUserInfo}
          currentContext={"updatePost"}
          onChange={userCommentWatcher}
          onFileChange={handleFileChange}
          onCommentClear={clearUserPostCommentLocalStorage}
          onUploadTrigger={triggerFileUpload}
        />
      </div>
    </div>
  );
};
export default PostDetails;

// To tease users with a single comment and a response, prompting them to click
// 'View comments' to see more, you can adjust your component to initially show
// only one comment and optionally one reply. You can use state to track which
// comment to show, and this can later be adjusted to implement
// various conditions when integrating with the backend.
