import React, { useEffect } from 'react'
import {Start, Stop} from '../icons';
import './bookControl.scss'
import { CustomButton } from '../buttons';

const BookControl = ({
  initialDelay,
  delay, 
  setDelay,
  isPlaying,
  setIsPlaying
}) => {
  const delayStep = 200; // delay between page flips in milliseconds
  const minDelay = 200; // milliseconds
  const maxDelay = 5000; // milliseconds
  const sliderProgress = delay / maxDelay * 100; // calculate percentage

  useEffect(() => {
    if (delay === maxDelay) {
      setIsPlaying(false);
    }
  }, [delay]);

  const handlePlayPauseClick = () => {
    setIsPlaying((prevState) => {
      if (prevState === false) {
        setDelay(initialDelay);
        return true;
      }
      return false;
    });
  };

  const handleIncreaseSpeed = () => {
    setDelay((prevSpeed) =>
      prevSpeed - delayStep >= minDelay ? prevSpeed - delayStep : prevSpeed,
    );
  };

  const handleDecreaseSpeed = () => {
    setDelay((prevSpeed) =>
      prevSpeed + delayStep <= maxDelay ? prevSpeed + delayStep : prevSpeed,
    );
  };
  const handleDelayChange = (event) => {
    setDelay(Number(event.target.value));
  };
  
  return (
    <div id='book-control'>

          <button 
            className="start-stop-button"
            onClick={handlePlayPauseClick}
          >
            {isPlaying ? <Stop /> : <Start />}
            {isPlaying ? 'Stop Flipping Pages' : 'Start Flipping Pages'}
          </button>

          <div className="slider-label"> 
            { 
              isPlaying 
              && parseInt((maxDelay-delay)/40) !== 0 
              && <span className="black"> Speed: </span>
            }
            {
              parseInt((maxDelay - delay) / 40) === 0 ? (
                <span>
                  0 Flipping Value Stops Automation Press Start
                </span>
              ) 
              : isPlaying ? (
                <>
                  <span>{parseInt((maxDelay - delay) / 40)}</span>
                  <span> Flipping Frames Per Minute</span>
                </>
              )
              : (
                <span>
                  Flipping Pages Automation Stopped Press Start
                </span>
              )
            }
          </div>

        <section className='slider-and-control-buttons-container'>
          <CustomButton
            className="speed-button"
            customBtnText='Slower'
            customBtnWidth='fit-content'
            customBtnTextFontSize='1.5rem'
            customBtnHeight='40px'
            customBtnFontFamily='Roboto-Slab'
            customBtnFontWeight='900'
            animationActive={true} // Enable the animation
            onClick={handleDecreaseSpeed}
          >
          </CustomButton>

          <input
            className="slider-ui"
            type='range'
            min={minDelay} // Minimum delay value in milliseconds
            max={maxDelay} // Maximum delay value in milliseconds
            step={delayStep} // Increment step for the range slider
            value={delay}
            onChange={handleDelayChange}
            style={{
              background: `linear-gradient(90deg, #fdfd96 ${sliderProgress}%, #ddd ${sliderProgress}%)`,
            }}
          />
          <CustomButton
            className="speed-button"
            customBtnText='Faster'
            customBtnWidth='fit-content'
            customBtnTextFontSize='1.5rem'
            customBtnHeight='40px'
            customBtnFontFamily='Roboto-Slab'
            customBtnFontWeight='900'
            animationActive={true} // Enable the animation
            onClick={handleIncreaseSpeed}
          >
          </CustomButton>
        </section>

    </div>
  )
}

export default BookControl