import React, { createContext, useState, useEffect } from "react";
import axios from "../api/axios";

// create the auth context
export const AuthContext = createContext();

// create the auth provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoadingAuthContext, setIsLoading] = useState(true);

  // check if the user is logged in on initial render
  useEffect(() => {
    setIsLoading(true);
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
    setIsLoading(false);
  }, []);

  // log in the user
  const login = async ({ username, password }) => {
    setIsLoading(true);
    try {
      const response = await axios.post("https://my-login-endpoint", {
        username,
        password,
      });

      setUser(response.data);
    } catch (e) {
      console.log(e);
      window.alert("Your credentials were not valid.");
    }
    setIsLoading(false);
  };

  // log out the user
  const logout = async () => {
    // Delete JWT cookie
    setUser(null);
  };

  // return the provider component with the auth context value
  return (
    <AuthContext.Provider value={{ user, login, logout, isLoadingAuthContext }}>
      {children}
    </AuthContext.Provider>
  );
};

// create the useAuth hook
export const useAuth = () => {
  const auth = React.useContext(AuthContext);
  if (!auth) {
    throw new Error("You must use this hook within the AuthProvider");
  }
  return auth;
};

// This file defines the authentication context for your React application.
// It creates a React context that holds the authentication state and functions
// needed to change that state, like logging in and logging out a user.
// It leverages the React Context API to manage and share the authentication
// state globally in your React app.

// const handlelogin = async ({ email_or_phone, password }) => {
//   // You might define these state variables using the useState hook at the top of your component
//   // setLoading(true);
//   try {
//     // Here, replace the Redux dispatch call with a context function or a simple API call to log in the user
//     // You would call your login function from your context here
//     const loginResponse = await login({ email_or_phone, password });

//     // After successful login, you'd want to fetch the user profile; this could be done with another API call
//     const userProfile = await fetchUserProfile(loginResponse.jwtToken);

//     // Determine the next steps based on the user's profile data
//     if (userProfile && !userProfile.phoneNumberVerified) {
//       // Handle the case where phone number is not verified
//       // ...
//     } {
//     }
//   } catch (err) {
//     // Handle any errors that occur during the login process
//     // setError(err.message);
//   }
//   // Set loading to false to hide the loading spinner once the process is complete
//   // setLoading(false);
// };
// const fetchUserProfile = async (jwtToken) => {
//   // Replace this with your API call to fetch the user profile using the JWT token
//   return fetch("/path/to/your/api/endpoint", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": `Bearer ${jwtToken}`,
//     },
//   })
//     .then((response) => response.json())
//     .catch((error) => {
//       console.error("Error fetching user profile:", error);
//     });
// };
