import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const clearReplyStateAndStorage = (currentContext, activeReply) => {
  const userReplyCommentKey = getStorageKey(
    `userReplyCommentValue_${activeReply.commentId}_${activeReply.autoIndex}`,
    currentContext
  );
  localStorage.removeItem(userReplyCommentKey);
  return ""; // Return empty string to reset the state
};

export const userReplyWatcher = (
  value,
  setReplyValue,
  setReplyError,
  activeReply,
  currentContext
) => {
  const userPostReplyTextAreaLength = value.length;
  const maxAllowedCharacters = 150;
  if (userPostReplyTextAreaLength > maxAllowedCharacters) {
    // If length exceeds 150 characters, display error message and truncate the input
    setReplyError("Maximum 150 characters allowed");
    // Truncate the value to the maximum allowed characters
    const truncatedValue = value.substring(0, maxAllowedCharacters);
    setReplyValue(truncatedValue);
    // Update localStorage with the truncated value
    const userReplyCommentKey = getStorageKey(
      `userReplyCommentValue_${activeReply.commentId}_${activeReply.autoIndex}`,
      currentContext
    );
    localStorage.setItem(userReplyCommentKey, truncatedValue);
  } else {
    // Update the value if within the limit and clear any error message
    setReplyError("");
    setReplyValue(value);
    // Update localStorage with the new value
    const userReplyCommentKey = getStorageKey(
      `userReplyCommentValue_${activeReply.commentId}_${activeReply.autoIndex}`,
      currentContext
    );
    localStorage.setItem(userReplyCommentKey, value);
  }
};


export const cancelComment = (
  setUserCommentValue,
  setUserCharacterCommentError,
  setCommentFile,
  currentContext,
  setIsImageUploaded,
  fileInputRef,
) => {
  // Reset state variables
  setUserCommentValue("");
  setUserCharacterCommentError(false);
  setCommentFile(null);
  setIsImageUploaded(false);
  if (fileInputRef.current) {
    fileInputRef.current.value = null; // Reset file input
  }
  // Clear local storage
  const userCommentKey = getStorageKey("userCommentValue", currentContext);
  localStorage.removeItem(userCommentKey);
  // Additional logic if required...
};
