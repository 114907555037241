import React, { useEffect, useState } from "react";
import ProfilePicture from "../profilePic/profilePic";
import { PlusSign } from "../../components/icons";
import { WhiteBlendButton } from "../../components/buttons";
import "./profileBook.scss";

// Destructuring the props directly in the function parameters.
const ProfileBook = ({
  userData,
  onAddBioBtnClick,
  onAddPicBtnClick, // setting up a dyamic onlick to link it to another part of the app so I can connect the components with state
  loggedInUser,
  profileUser,
}) => {
  // Check if the logged-in user is viewing their own profile this will hide the user's user name and allow the user to 
  // have the modify profile and other permission buttons
  const isProfileOwner = loggedInUser.username === profileUser;
  // Local state to manage whether the book is opened or not.
  const [opened, setOpened] = useState(false);
  // UseEffect to initialize the book as opened.
  useEffect(() => {
    {
      setOpened(true);
    }
  }, []);

  return (
    <div className={opened ? "opened-book" : ""}>
      <div className="container">
        <ProfilePicture
          profileImgPath={userData?.imagePath}
          // passed in a class name so we can style it locally
          className="ProfilePicBookPg"
        />
        {!isProfileOwner && (
          <h1 style={{ color: "#000", fontWeight: "600", fontSize: "1rem" }}>
            {userData.username}
          </h1>
        )}
        {/* Only show "Add a bio" button if the viewer is the profile owner. */}
        {isProfileOwner && (
          <WhiteBlendButton
            // not rendering the component as a function like slider so passing the component directly to the prop
            whiteBlendBtnImage={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="23.7"
                viewBox="0 0 30 23.7"
              >
                <g
                  id="Group_13"
                  data-name="Group 13"
                  transform="translate(16851 16085)"
                >
                  <g id="Camera-03" transform="translate(-16851 -16085)">
                    <path
                      id="Path_30"
                      data-name="Path 30"
                      d="M30,22.5a1.272,1.272,0,0,1-1.4,1.2H1.4A1.272,1.272,0,0,1,0,22.5V2.5A1.272,1.272,0,0,1,1.4,1.3H28.5a1.272,1.272,0,0,1,1.4,1.2v20Z"
                      fill="#964b00"
                    />
                    <path
                      id="Path_31"
                      data-name="Path 31"
                      d="M2.5,1.8V.3A.319.319,0,0,1,2.8,0H6.2a.265.265,0,0,1,.3.3V1.8Z"
                    />
                    <path
                      id="Path_32"
                      data-name="Path 32"
                      d="M17.4,4.8a.319.319,0,0,1-.3.3H12.8a.319.319,0,0,1-.3-.3V2a.319.319,0,0,1,.3-.3h4.3a.319.319,0,0,1,.3.3Z"
                    />
                    <path
                      id="Path_33"
                      data-name="Path 33"
                      d="M16.9,4.2a.319.319,0,0,1-.3.3H13.4a.319.319,0,0,1-.3-.3V2.6a.319.319,0,0,1,.3-.3h3.2a.319.319,0,0,1,.3.3Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_34"
                      data-name="Path 34"
                      d="M15.9,3.7a.319.319,0,0,1-.3.3H14.5a.319.319,0,0,1-.3-.3V3.1a.319.319,0,0,1,.3-.3h1.1a.319.319,0,0,1,.3.3Z"
                    />
                    <g id="Group_13-2" data-name="Group 13">
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M28.1,3.1v2h-4v-2h4m.1-.4H24a.319.319,0,0,0-.3.3V5.2a.319.319,0,0,0,.3.3h4.2a.319.319,0,0,0,.3-.3V2.9c0-.1-.1-.2-.3-.2Z"
                      />
                    </g>
                    <rect
                      id="Rectangle_43"
                      data-name="Rectangle 43"
                      width="30"
                      height="15.6"
                      transform="translate(0 6.9)"
                      fill="#fdfd96"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(2.4 9.2)"
                      fill="#fff"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="0.6"
                      cy="0.6"
                      r="0.6"
                      transform="translate(2.8 9.6)"
                      fill="#d14527"
                    />
                    <g id="Group_16" data-name="Group 16">
                      <g id="Group_14" data-name="Group 14">
                        <path
                          id="Path_36"
                          data-name="Path 36"
                          d="M8.7,19.2A8.042,8.042,0,0,1,10,8,7.951,7.951,0,0,1,21.2,9.3"
                          fill="#fff"
                        />
                        <path
                          id="Path_37"
                          data-name="Path 37"
                          d="M21.2,9.3A7.973,7.973,0,1,1,8.7,19.2"
                          fill="#fff"
                        />
                      </g>
                      <g id="Group_15" data-name="Group 15">
                        <path
                          id="Path_38"
                          data-name="Path 38"
                          d="M15,6.9A7.2,7.2,0,1,1,7.8,14,7.149,7.149,0,0,1,15,6.9m0-1.3a8.5,8.5,0,1,0,8.5,8.5A8.623,8.623,0,0,0,15,5.6Z"
                        />
                      </g>
                      <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="4.4"
                        cy="4.4"
                        r="4.4"
                        transform="translate(10.9 9.7)"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M.9,0A.9.9,0,1,1,0,.9.9.9,0,0,1,.9,0Z"
                        transform="translate(12.9 13.7)"
                        fill="#fff"
                      />
                      <circle
                        id="Ellipse_5"
                        data-name="Ellipse 5"
                        cx="0.8"
                        cy="0.8"
                        r="0.8"
                        transform="translate(14.5 15.1)"
                        fill="#fff"
                      />
                    </g>
                    <rect
                      id="Rectangle_44"
                      data-name="Rectangle 44"
                      width="3.7"
                      height="0.8"
                      transform="translate(24.3 3.2)"
                    />
                  </g>
                </g>
              </svg>
            }
            // className="brown-btn"
            onClick={onAddPicBtnClick} // passing in a dymaic variable that will be linked to state to render a form on another part of the app
            className="add-a-profile-pic-btn"
            whiteBlendBtnFontColor="rgb(150, 75, 0)"
            whiteBlendBtnFontFamily="roboto-slab"
            whiteBlendBtnFontWeight="800"
            whiteBlendBtnText="Add a Profile Pic"
            whiteBlendBtnWidth="fit-content"
          />
        )}
        <div className="cover">
          <figure className="front">
            <span className="book-title">
              LITTLE
              <br />
              BLACK
              <br />
              BOOK
            </span>{" "}
          </figure>
          <figure className="back bio">
            <h3>Bio</h3>
            {/* bio is otional so below is written according to the requirements */}
            <p>
              {userData?.bio ||
                "This user hasn't written about themselves yet."}
            </p>
            {isProfileOwner && (
              <WhiteBlendButton
                className="add-bio-button"
                whiteBlendBtnImage={
                  <PlusSign height="15px" width="15px" fillColor="#964B00" />
                }
                whiteBlendBtnFontColor="rgb(150, 75, 0)"
                whiteBlendBtnFontFamily="roboto-slab"
                whiteBlendBtnFontWeight="800"
                whiteBlendBtnText="Add a Bio"
                whiteBlendBtnWidth="fit-content"
                onClick={onAddBioBtnClick}
              />
            )}
          </figure>
        </div>
      </div>
    </div>
  );
};
export default ProfileBook;

// ProfileBook is a React functional component that receives userData, onAddBioBtnClick, onAddPicBtnClick, loggedInUser, and profileUser as props.
// The isProfileOwner boolean is determined by comparing the logged-in user's username with the profile username.
// The component's state, opened, is used to control whether the "book" is open or closed and is initialized to true via useEffect.
// The ProfilePicture component displays the user's profile image.
// A h1 tag displays the username from userData.
// If the logged-in user is the profile owner, a button is shown allowing them to add a profile picture.
// The cover div wraps two figure elements: .front and .back. The .front shows a book title, and the .back contains a bio section with an option for the owner to add their bio.
// The opened state is used to apply a class that animates the opening of the book using a CSS rotation transformation.

// scss
// .container is styled to be a flex container, centering its children both horizontally and vertically, with a set width and height.
// .front represents the front of the book with a leather texture background, and text styling for the book title.
// .cover is positioned absolutely at
// the bottom of the container and has a CSS transformation applied to simulate a book cover. It flips 180 degrees when the book is opened.

// The .opened-book class applies the flip transformation to the .cover element when the book is considered open.
// figure elements are block elements with absolute positioning, filling their parent's dimensions.
// The .bio class applies black text color, specific text alignment, and font styling.
// figure.back is styled similarly to the .front but rotates 180 degrees to be visible when the book is open.
// The media queries adjust the styles based on the screen height, particularly for an element with the class .author, which is not included in the provided code but seems to be intended for author information.
// .ProfilePicBookPg provides specific styling for the profile picture when it's displayed within the ProfileBook component, particularly setting the height and managing overflow.
