import React, { useState, useEffect, useRef } from "react";
import { DownArrow } from "../icons";
import { PlainBtn } from "../buttons/index";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import PropTypes from "prop-types"; //  expected to receive props from their parent components. It helps to validate the types of the props and provide default values if necessary, which can prevent bugs and make the code more maintainable and self-documenting.
import "./datePicker.scss";

const DatePicker = React.memo(
  ({
    onStartDateChange,
    onEndDateChange,
    showYearDropdown = true,
    showMonthDropdown = true,
    showStartDate = true,
    showEndDate = true,
    showAdditionalDate = false,
    onAdditionalDateChange,
    clearEndDate,
    clearStartDate,
    startDateValue,
    endDateValue,
    setStartDateValue,
    setEndDateValue,
    onDateErrorChange,
    placeholder,
    dateInputClassName,
  }) => {
    const [additionalDate, setAdditionalDate] = useState(
      localStorage.getItem("additionalDateValue") || ""
    );
    const [creditCardExpirationDate, setCreditCardExpirationDate] = useState(
      localStorage.getItem("creditCardExpirationDate") || ""
    );

    const [isDatePickerPopupOpen, setIsDatePickerPopupOpen] = useState(false);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [dateError, setDateError] = useState(""); // State to track date errors
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const dropdownRef = useRef(null);
    const dropdownListRef = useRef(null); // Newly added ref for the list
    const firstOptionRef = useRef(null);
    const [isYearOpen, setIsYearOpen] = useState(false);
    const yearListRef = useRef(null);
    const currentYearIndex = new Date().getFullYear();
    const years = Array.from(
      { length: 20 },
      (_, i) => currentYearIndex - 10 + i
    );
    const [selectedYear, setSelectedYear] = useState(null); // Initial state set to null
    const [yearFocusedIndex, setYearFocusedIndex] = useState(-1);
    const yearOptionRefs = useRef([]);
    const [mode, setMode] = useState("start"); // or "end", depending on the logic
    // using this to set the day on the calendar and not the input for default
    const [selectedDay, setSelectedDay] = useState(new Date().getDate());
    // state management for when user selects a date and on default current date will appear so
    // user can change it
    const hardcodedMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const next = () => {
      if (currentMonth.getMonth() === 11) {
        setCurrentYear((prevYear) => prevYear + 1);
        setCurrentMonth(new Date(currentYear + 1, 0, 1));
      } else {
        setCurrentMonth(
          (prev) => new Date(currentYear, prev.getMonth() + 1, 1)
        );
      }
    };
    const previous = () => {
      if (currentMonth.getMonth() === 0) {
        setCurrentYear((prevYear) => prevYear - 1);
        setCurrentMonth(new Date(currentYear - 1, 11, 1));
      } else {
        setCurrentMonth(
          (prev) => new Date(currentYear, prev.getMonth() - 1, 1)
        );
      }
    };
    const daysInCurrentMonth =
      32 - new Date(currentYear, currentMonth.getMonth(), 32).getDate();
    const startDayOfMonth = new Date(
      currentYear,
      currentMonth.getMonth(),
      1
    ).getDay();
    const calendarDays = [];
    for (let i = 0; i < startDayOfMonth; i++) {
      calendarDays.push(null);
    }

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      calendarDays.push(i);
    }
    const handleDropdownKeyNavigation = (e, isYear = false) => {
      e.preventDefault();
      const dropdownContainer = isYear
        ? yearListRef.current
        : dropdownListRef.current;
      if (!dropdownContainer) return;
      const optionHeight = firstOptionRef.current
        ? firstOptionRef.current.offsetHeight
        : 40;

      const updateIndex = (currentIndex, increment) => {
        const newIndex = currentIndex + increment;
        return newIndex < 0
          ? hardcodedMonths.length - 1
          : newIndex >= hardcodedMonths.length
          ? 0
          : newIndex;
      };
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        let newIndex =
          e.key === "ArrowDown"
            ? updateIndex(focusedIndex, 1)
            : updateIndex(focusedIndex, -1);
        setFocusedIndex(newIndex);

        const itemTop = newIndex * optionHeight;
        const itemBottom = itemTop + optionHeight;
        if (
          itemBottom >
          dropdownContainer.scrollTop + dropdownContainer.clientHeight
        ) {
          dropdownContainer.scrollTop =
            itemBottom - dropdownContainer.clientHeight;
        } else if (itemTop < dropdownContainer.scrollTop) {
          dropdownContainer.scrollTop = itemTop;
        }
      } else if (/^[a-z]$/i.test(e.key)) {
        const nextMonth = getNextMonthStartingWith(
          e.key,
          hardcodedMonths[focusedIndex]
        );
        const nextIndex = hardcodedMonths.indexOf(nextMonth);
        setFocusedIndex(nextIndex);
        setSearchTerm((prev) => prev + e.key);
        // Logic to center the selected month in the dropdown
        const halfContainerHeight = dropdownContainer.clientHeight / 2;
        const itemTop = nextIndex * optionHeight;
        dropdownContainer.scrollTop =
          itemTop - halfContainerHeight + optionHeight / 2;
      }
    };
    const monthMap = {
      j: ["January", "June", "July"],
      a: ["April", "August"],
      m: ["March", "May"],
    };
    const getNextMonthStartingWith = (currentKey, currentMonth) => {
      const mappedMonths =
        monthMap[currentKey.toLowerCase()] ||
        hardcodedMonths.filter((month) =>
          new RegExp(`^${currentKey}`, "i").test(month)
        );
      const currentMonthIndex = mappedMonths.indexOf(currentMonth);
      if (
        currentMonthIndex === -1 ||
        currentMonthIndex === mappedMonths.length - 1
      ) {
        return mappedMonths[0];
      } else {
        return mappedMonths[currentMonthIndex + 1];
      }
    };
    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
      if (searchTerm.length > 0) {
        const index = hardcodedMonths.findIndex((month) =>
          new RegExp(`^${searchTerm}`, "i").test(month)
        );
        if (index !== -1) {
          setFocusedIndex(index);
        }
      }
    }, [searchTerm]);
    const handleDayClick = (day) => {
      console.log("Day clicked:", day); // Log the clicked day
      setSelectedDay(day);
      const selectedDate = new Date(currentYear, currentMonth.getMonth(), day);
      console.log("Selected Date:", selectedDate); // Log the selected date
      if (mode === "start") {
        setStartDateValue(selectedDate.toISOString());
        if (onStartDateChange) onStartDateChange(selectedDate);
      } else if (mode === "end") {
        setEndDateValue(selectedDate.toISOString());
        if (onEndDateChange) onEndDateChange(selectedDate);
      } else if (mode === "additional") {
        const selectedDate = new Date(
          currentYear,
          currentMonth.getMonth(),
          day
        );
        setCreditCardExpirationDate(selectedDate.toISOString());
        // Invoke callback if provided on the child
        if (onAdditionalDateChange) onAdditionalDateChange(selectedDate);
      }
      setIsDatePickerPopupOpen(false); // Close the calendar popup
      setIsOpen(false); // Close month dropdown if open
      setIsYearOpen(false); // Close year dropdown if open
    };
    useEffect(() => {
      // set local storage on payments
      // this way this does not show up in global state but it shows in local state add currentContext there as well
      // see event list page, the start and end handlers on how to do it
      // localStorage.setItem(
      //   "creditCardExpirationDate",
      //   creditCardExpirationDate
      // );
    }, [creditCardExpirationDate]);

    useEffect(() => {
      if (isOpen && dropdownRef.current) {
        dropdownRef.current.focus();
      }
    }, [isOpen]);

    const handleArrowKeys = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          previous();
          break;
        case "ArrowRight":
          next();
          break;
        default:
          break;
      }
    };
    useEffect(() => {
      window.addEventListener("keydown", handleArrowKeys);
      return () => {
        window.removeEventListener("keydown", handleArrowKeys);
      };
    }, []);
    const handleYearKeyNavigation = (e) => {
      const yearContainer = yearListRef.current;
      const updateIndex = (currentIndex, increment) => {
        const newIndex = currentIndex + increment;
        return newIndex < 0
          ? years.length - 1
          : newIndex >= years.length
          ? 0
          : newIndex;
      };

      let newIndex = yearFocusedIndex;

      if (e.key === "ArrowDown") {
        e.preventDefault();
        newIndex = updateIndex(yearFocusedIndex, 1);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        newIndex = updateIndex(yearFocusedIndex, -1);
      }
      const focusedElem = yearOptionRefs.current[newIndex];
      if (focusedElem) {
        const elemTop = focusedElem.offsetTop;
        const elemBottom = elemTop + focusedElem.offsetHeight;
        if (elemBottom > yearContainer.scrollTop + yearContainer.clientHeight) {
          yearContainer.scrollTop = elemBottom - yearContainer.clientHeight;
        } else if (elemTop < yearContainer.scrollTop) {
          yearContainer.scrollTop = elemTop;
        }
      }

      setYearFocusedIndex(newIndex);
    };
    // Handlers to open date picker
    const openDatePicker = (modeValue) => {
      setIsDatePickerPopupOpen(true);
      setMode(modeValue); // Assuming you have a state 'mode' to distinguish between start and end date
      // Reset dropdowns when opening the date picker
      setIsOpen(false); // Reset month dropdown
      setIsYearOpen(false); // Reset year dropdown
    };

    useEffect(() => {
      if (isYearOpen && yearListRef.current) {
        const optionHeight = yearListRef.current.firstChild
          ? yearListRef.current.firstChild.offsetHeight
          : 40;
        const focusedYearIndex = selectedYear
          ? years.indexOf(selectedYear)
          : -1;
        setFocusedIndex(focusedYearIndex);
        if (focusedYearIndex >= 0) {
          yearListRef.current.scrollTop = focusedYearIndex * optionHeight;
        }
      }
    }, [isYearOpen, selectedYear, years]);

    function formatDateForInput(dateString) {
      if (!dateString) return ""; // Return empty string for falsy values
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return ""; // Return empty string for invalid dates
      const monthNumber = date.getMonth();
      const day = date.getDate();
      const year = date.getFullYear();
      const monthName = getMonthName(monthNumber + 1); // +1 because months are zero-indexed
      return `${monthName} ${day}, ${year}`;
    }

    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleString("en-US", { month: "long" });
    }

    useEffect(() => {
      // localStorage.setItem("startDateValue", startDate);
      // localStorage.setItem("endDateValue", endDate);
      // localStorage.setItem("additionalDateValue", additionalDate);
    }, [startDateValue, endDateValue]);

    function isDayInPast(year, month, day) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1); // subtract one day from today to get "yesterday"

      const givenDate = new Date(year, month, day);
      return givenDate < yesterday;
    }
    const validateDates = () => {
      const start = new Date(startDateValue);
      const end = new Date(endDateValue);
      console.log("Validating dates:", start, end); // Log the dates being validated
      if (start && end && start > end) {
        const errorMessage = "End date must be after the start date.";
        setDateError(errorMessage);
        console.log("Date order error should be true");
        onDateErrorChange(true); // true indicates an error
      } else {
        setDateError(""); // Clear error message if dates are valid
        console.log("Date order error should be false");
        onDateErrorChange(false); // false indicates no error
      }
    };

    useEffect(() => {
      validateDates(); // Validate dates whenever start or end date changes
    }, [startDateValue, endDateValue]);

    const clearCreditCardExpirationDate = () => {
      // Define the key and hard coded currentContext to target payments
      const CCExpiredDateKey = getStorageKey("expiryDate", "payments");
      // Check if the key exists in localStorage and get the item
      if (localStorage.getItem(CCExpiredDateKey) !== null) {
        localStorage.removeItem(CCExpiredDateKey); // Remove the item
      } else {
        console.log("Key not found in localStorage:", CCExpiredDateKey);
      }
      setCreditCardExpirationDate(""); // Clear the state
    };
    // Input change handlers for start and end dates
    const handleStartDateInputChange = (selectedDate) => {
      const newStartDate = new Date(selectedDate);
      if (!isNaN(newStartDate.getTime())) {
        setStartDateValue(newStartDate.toISOString());
        if (onStartDateChange) onStartDateChange(newStartDate);
        validateDates(); // Validate dates after setting the new start date
      }
    };

    const handleEndDateInputChange = (selectedDate) => {
      const newEndDate = new Date(selectedDate);
      if (!isNaN(newEndDate.getTime())) {
        setEndDateValue(newEndDate.toISOString());
        if (onEndDateChange) onEndDateChange(newEndDate);
        validateDates(); // Validate dates after setting the new start date
      }
    };

    const handleAdditionalDateChange = (selectedDate) => {
      const newDate = new Date(selectedDate);
      if (!isNaN(newDate.getTime())) {
        const isoDate = newDate.toISOString();
        setAdditionalDate(isoDate);
        if (onAdditionalDateChange) onAdditionalDateChange(newDate);
      }
    };

    return (
      <div className="start-end-date-picker">
        {showStartDate && (
          <div className="inputClearFlex">
            <input
              readOnly // This makes the input un-editable directly
              value={formatDateForInput(startDateValue)}
              onStartDateChange={handleStartDateInputChange} // Make sure this is `onChange`, not `onStartDateChange`
              placeholder="Start Date"
              onFocus={() => openDatePicker("start")}
            />
            <PlainBtn
              plainBtnWidth="fit-content"
              plainBtnHeight="35px"
              plainBtnText="Clear"
              plainBtnFontWeight="900"
              plainBtnFontColor="#964b00"
              plainBtnTextFontSize=".75rem"
              plainBtnBorderRadius="0px"
              plainBtnFontFamily="Robot-Slab"
              textLocalWhiteBtnStyle={{
                marginRight: "10px",
              }}
              onClick={clearStartDate}
            ></PlainBtn>
          </div>
        )}
        {dateError && <div className="date-error-message">{dateError}</div>}
        {showEndDate && (
          <div className="inputClearFlex">
            <input
              readOnly // This makes the input un-editable directly
              value={formatDateForInput(endDateValue)}
              onEndDateChange={handleEndDateInputChange} // Make sure this is `onChange`, not `onEndDateChange`
              placeholder="End Date"
              onFocus={() => openDatePicker("end")}
            />
            <PlainBtn
              plainBtnWidth="fit-content"
              plainBtnHeight="35px"
              plainBtnText="Clear"
              plainBtnFontWeight="900"
              plainBtnFontColor="#964b00"
              plainBtnTextFontSize=".75rem"
              plainBtnBorderRadius="0px"
              plainBtnFontFamily="Robot-Slab"
              textLocalWhiteBtnStyle={{
                marginRight: "10px",
              }}
              onClick={clearEndDate}
            ></PlainBtn>
          </div>
        )}
        {showAdditionalDate && (
          <div>
            <input
              readOnly // This makes the input un-editable directly
              value={formatDateForInput(creditCardExpirationDate)}
              onChange={handleAdditionalDateChange} // This needs to be adjusted
              placeholder={placeholder} 
              onFocus={() => openDatePicker("additional")}
              className={dateInputClassName} // Use the inputClassName prop here
            />
            <PlainBtn
              plainBtnWidth="fit-content"
              plainBtnHeight="35px"
              plainBtnText="Clear Date"
              plainBtnFontWeight="900"
              plainBtnFontColor="#964b00"
              plainBtnTextFontSize=".75rem"
              plainBtnBorderRadius="0px"
              plainBtnFontFamily="Robot-Slab"
              textLocalWhiteBtnStyle={{
                marginRight: "10px",
              }}
              onClick={clearCreditCardExpirationDate}
            ></PlainBtn>
          </div>
        )}
        {isDatePickerPopupOpen && (
          <div className="date-picker-content">
            <div className="header">
              <button className="button-arrow left" onClick={previous}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.148"
                  height="11.758"
                  viewBox="0 0 7.148 11.758"
                >
                  <path
                    id="arrow-left"
                    d="M5.441,0,0,5.159l.545.576L5.411,1.121l4.358,4.6.576-.545Z"
                    transform="translate(0.707 11.052) rotate(-90)"
                    fill="#fdfd96"
                    stroke="#fdfd96"
                    strokeWidth="1"
                  />
                </svg>
              </button>
              <span className="current-month-year">
                {hardcodedMonths[currentMonth.getMonth()]} {currentYear}
              </span>
              <button className="button-arrow right" onClick={next}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.148"
                  height="11.758"
                  viewBox="0 0 7.148 11.758"
                >
                  <path
                    id="arrow-right"
                    d="M5.441,5.734,0,.576.545,0,5.411,4.613,9.769,.017l.576.545Z"
                    transform="translate(0.707 11.052) rotate(-90)"
                    fill="#fdfd96"
                    stroke="#fdfd96"
                    strokeWidth="1"
                  />
                </svg>
              </button>
            </div>
            <div className="date-selection-container">
              {showMonthDropdown && (
                <div
                  className={`month-select ${
                    isOpen ? "month-dropdown-open" : "month-dropdown-closed"
                  }`}
                  tabIndex={0}
                  onKeyDown={handleDropdownKeyNavigation}
                  ref={dropdownRef}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div
                    className="display-content"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="selected">
                      {isOpen
                        ? selectedMonth !== null &&
                          selectedMonth >= 0 &&
                          selectedMonth < 12
                          ? hardcodedMonths[selectedMonth]
                          : "Select a month"
                        : "Select Month"}
                    </div>
                    <DownArrow fillColor="#964B00" />
                  </div>
                  {isOpen && (
                    <div className="month-options" ref={dropdownListRef}>
                      {hardcodedMonths.map((month, index) => (
                        <div
                          key={month}
                          tabIndex={-1}
                          ref={index === 0 ? firstOptionRef : null}
                          onClick={() => {
                            setSelectedMonth(index);
                            setCurrentMonth(new Date(currentYear, index, 1));
                            setIsOpen(false);
                          }}
                          className={index === focusedIndex ? "focused" : ""}
                        >
                          {month}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {showYearDropdown && (
                // Year Dropdown
                <div
                  className={`year-select ${
                    isYearOpen ? "year-dropdown-open" : "year-dropdown-closed"
                  }`}
                  tabIndex={0}
                  onKeyDown={handleYearKeyNavigation}
                  onClick={() => setIsYearOpen(!isYearOpen)}
                >
                  <div
                    className="display-content"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="selected">
                      {isYearOpen
                        ? selectedYear !== null
                          ? selectedYear
                          : "Select a year"
                        : "Select Year"}
                    </div>
                    <DownArrow fillColor="#964B00" />
                  </div>
                  {isYearOpen && (
                    <div className="year-options" ref={yearListRef}>
                      {years.map((year, index) => (
                        <div
                          key={year}
                          tabIndex={0}
                          ref={(el) => (yearOptionRefs.current[index] = el)}
                          onClick={() => {
                            setSelectedYear(year);
                            setCurrentYear(year);
                            setIsYearOpen(false);
                          }}
                          className={
                            index === yearFocusedIndex ? "focused" : ""
                          }
                        >
                          {year}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* Days of the week and days */}
              <div className="day-headers">
                {daysOfWeek.map((day, index) => (
                  <div key={index} className="day-header">
                    {day}
                  </div>
                ))}
              </div>
              <div className="days">
                {calendarDays.map((day, index) => (
                  <div
                    key={index}
                    className={`day ${day === selectedDay ? "selected" : ""} ${
                      day === new Date().getDate() && //  checks if the day matches today's day.
                      currentYear === new Date().getFullYear() && // checks if the current year matches this year.
                      currentMonth.getMonth() === new Date().getMonth()
                        ? "bg-info" //   utility class used to give an element a light blue background color dded to a "day" element if the day, year, and month match today's date. Essentially, it's highlighting the current day in the date picker.
                        : ""
                    } ${
                      isDayInPast(currentYear, currentMonth.getMonth(), day)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() =>
                      isDayInPast(currentYear, currentMonth.getMonth(), day)
                        ? null
                        : day && handleDayClick(day)
                    }
                  >
                    {day || ""}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
DatePicker.propTypes = {
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onAdditionalDateChange: PropTypes.func,
  showStartDate: PropTypes.bool,
  showEndDate: PropTypes.bool,
  showAdditionalDate: PropTypes.bool,
  onDateErrorChange: PropTypes.func, 
  dateInputClassName: PropTypes.string,
};

DatePicker.defaultProps = {
  onStartDateChange: () => {},
  onEndDateChange: () => {},
  onAdditionalDateChange: () => {},
  showStartDate: true,
  showEndDate: true,
  showAdditionalDate: false,
  dateInputClassName:'',
  onDateErrorChange: () => {}, // Default function if not provided
};
export default React.memo(DatePicker);
