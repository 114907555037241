import React from "react";
import "./paymentMethods.scss";
const InvoiceModal = ({ isOpen, onClose, invoiceData, contentClass }) => {
  if (!isOpen) return null;
  const {
    orderSubTotalBal,
    grandTotalBal,
    totalDueBal,
    ["Gift-Card-Balance-Used"]: GiftCardBalUsed,
    beginningGiftCardBal,
    currentGiftCardBal,
    beginningPointsBalance,
    currentPointsBalance,
    orderPointsEarned,
    invoiceTimeStamp,
    beginingRewardLevelName,
    beginingRewardLevel,
    currentRewardLevelName,
    currentRewardLevel,
  } = invoiceData.checkoutInvoice;
  // Log some of the data
  console.log("Invoice Data:", invoiceData);
  console.log("Order Subtotal:", orderSubTotalBal);
  console.log("Grand Total:", grandTotalBal);
  console.log("Total Due:", totalDueBal);
  console.log("Gift Card Balance Used:", GiftCardBalUsed);
  console.log("Beginning Gift Card Balance:", beginningGiftCardBal);
  console.log("Current Gift Card Balance:", currentGiftCardBal);
  // Convert values to strings

console.log("orderSubTotalStr:", orderSubTotalBal); // Add this line

  return (
    <div className="modal-overlay">
      <div className="invoice-modal">
        {/* Display the invoice data */}
        <div className={contentClass}>
          <p>
            Tier Price Selected: $
            {(invoiceData.tierPriceSelected)}
          </p>
          <p>
            Order Subtotal: {(orderSubTotalBal)}
          </p>
          <p>Grand Total: {(grandTotalBal)}</p>
          <p>Total Due: {(totalDueBal)}</p>
          <p>
            Gift Card Balance Used:
            {(GiftCardBalUsed)}
          </p>
          <p>
            Beginning Gift Card Balance:
            {(beginningGiftCardBal)}
          </p>
          <p>
            Current Gift Card Balance:
            {(currentGiftCardBal)}
          </p>
          <p>
            Beginning Points Balance: {(beginningPointsBalance)}
          </p>
          <p>
            Current Points Balance: {(currentPointsBalance)}
          </p>
          <p>Order Points Earned: {(orderPointsEarned)}</p>
          <p>
            Beginning Reward Level: ({beginingRewardLevelName},
            {beginingRewardLevel})
          </p>
          <p>
            {" "}
            Current Reward Level({currentRewardLevelName},{currentRewardLevel})
          </p>
          <p>Invoice Timestamp: {invoiceTimeStamp}</p>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default InvoiceModal;
