import React from "react";
import SlideBtn from "../buttons/slideBtn/slideBtn";
import { PlusSign } from "../../components/icons";
import ProfilePicture from "../../components/profilePic/profilePic";
import "./userPost.scss";

const CreatePost = ({
  userData,
  onCreatePostSlidEnd,
  className,
  isCreatePostOnlyOnDesktop = false, // if create a post exist hide for mobile, will be true for desktop since bottom up approach
}) => {
  const currentForm="postForm";
  const contextType="postForm";
  const currentCotext="createPost";
  // Class for hiding the "Create Post" on mobile/tablet
  const hideCreatePost = isCreatePostOnlyOnDesktop ? "hide-create-post-on-mobile" : "";
  return (
    <div className={`user-post-container ${className} ${hideCreatePost}`}>
      {/* Display user's profile picture or a placeholder */}
      <ProfilePicture
        profileImgPath={userData?.imagePath}
        className="user-profile-picture create-share-profile-pic"
      />
      {/* Heading/Text */}
      <h3 className="Creat_Share">Create & Share</h3>
      {/* Slider Button */}
      <div className="slide-button-wrapper">
        <SlideBtn
          sliderImage={() => (
            <PlusSign height="25px" width="25px" fillColor="#964B00" />
          )}
          roundSlideBtnLocalStyle={{
            height:"30px",
            width:"30px",
          }}
          slideBtnHeight="35px"
          slideBtnText="Slide to create"
          slideBtnLocalStyles={{
            position:"relative",
            width:"179px",
            left:"15%",
            top:"18px",
          }}
          onHandleSlideEnd={onCreatePostSlidEnd}
        />
      </div>
    </div>
  );
};

export default CreatePost;
