import React, { useState, useEffect, useRef } from "react";
import { handleFileChange } from "../../utils/changeFileHandler.js";
import {
  userReplyWatcher,
} from "../../controller/watcherAndClearFunctions/commentClearsWatchersFunctions.js";
import { clearReplyStateAndStorage } from "../../controller/watcherAndClearFunctions/commentClearsWatchersFunctions.js";
import { CameraIcon, TouchIcon, PaperPlane2Icon } from "../../components/icons";
import { ImgTopBtn } from "../buttons/index.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import "./userReply.scss";

const UserReply = ({
  currentContext,
  targetId,
  onReply,
  loggedInUserInfo, // will be passed from group or post component
  activeReply, // will be passed from group or post component
  setActiveReply,
  setJoinThread, // passed in from group/post details component to reset state to false (close the comment box)
  setStartThread, // passed in from group/post details component to reset state to false (close the comment box)
  setThreadReply, // passed in from group/post details component to reset state to false (close the comment box)
}) => {
  const textareaRef = useRef(null);
  const replyContainerRef = useRef(null);
  const buttonsRef = useRef(null);
  const fileInputRef = useRef(null);
  const [replyValue, setReplyValue] = useState("");
  const [replyError, setReplyError] = useState("");
  const [replyFile, setReplyFile] = useState(null);
  const [replyValueFocused, setReplyValueFocused] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  // put this in the controller folder for error handling and talking to the apis..
  const handleSendReply = () => {
    if (replyValue && targetId) {
      onReply(
        targetId,
        replyValue,
        replyFile,
        activeReply.threadId,
        activeReply.autoIndex
      );
      setReplyValue("");
      setReplyFile(null);
      setIsImageUploaded(false);
    }
  };

  const onFileChange = (event) => {
    handleFileChange(event, setReplyFile, currentContext, setIsImageUploaded);
  };
  // Function to trigger file upload when camera icon is clicked
  const triggerFileUpload = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };
  const handleUploadClick = () => {
    triggerFileUpload(fileInputRef);
  };
  const handleTextareaResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset the height initially to 'auto' to shrink in case of content removal
      textarea.style.height = "auto";
      // Set the height to the scrollHeight
      // scrollHeight is the total height of the content of the textarea
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Call this function on events that change the content, like input or change
  useEffect(() => {
    handleTextareaResize(); // This will adjust the height based on the content
  }, [replyValue]); // Depend on replyValue to re-trigger when content changes

  // You might want to call this function on different events to ensure it updates appropriately
  useEffect(() => {
    handleTextareaResize(); // Resize on component mount and when replyValue changes
  }, [replyValue]);
  
  console.log("setActiveReply before handleCancelReply in UserReply:", setActiveReply);

const handleCancelReply = () => {
  setReplyValue(""); // Clear the reply value
  setReplyError(false); // Reset the error message
  setReplyFile(null); // Clear the reply file
  setIsImageUploaded(false); // Reset the image upload flag

  // Reset the activeReply state
  setActiveReply({ index: null, username: "" });

  // Clear the file input if present
  if (fileInputRef.current) {
    fileInputRef.current.value = ""; // Reset file input
  }

  // Clear local storage
  const userReplyCommentKey = getStorageKey(
    `userReplyCommentValue_${activeReply.commentId}_${activeReply.autoIndex}`,
    currentContext
  );
  localStorage.removeItem(userReplyCommentKey);

  // Notify parent component to reset thread states
  if (setStartThread && setJoinThread && setThreadReply) {
    setStartThread(false);
    setJoinThread(false);
    setThreadReply(false);
  }
};

  return (
    <div id="replyComponent-container" ref={replyContainerRef}>
      <div className="reply-bubble">
        {loggedInUserInfo && (
          <div className="userReplyThumbnail">
            <img
              src={loggedInUserInfo.imagePath}
              alt={loggedInUserInfo.username}
            />
          </div>
        )}
        {activeReply.username && (
          // <p className="replying-to">Replying to {activeReply.username}</p> use this for testing
          <p className="replying-to">{activeReply.username},</p>
        )}
      </div>
      <textarea
        className="growable-textarea textarea"
        value={replyValue}
        onInput={handleTextareaResize}
        onChange={(e) =>
          userReplyWatcher(
            e.target.value,
            setReplyValue,
            setReplyError,
            activeReply,
            currentContext
          )
        }
        rows="4"
        placeholder="Write your reply..."
      />
      {replyError && <div className="character-error">{replyError}</div>}
      <div className="buttons-container" ref={buttonsRef}>
        <ul className="UserPostDetails-discussion-buttons" ref={buttonsRef}>
          <li
            className={`UserPostDetails-listItem ${
              replyValue ? "active" : "disabled"
            }`}
            style={{
              cursor: replyValue || isImageUploaded ? "pointer" : "not-allowed",
            }}
          >
            <TouchIcon
              width="25"
              height="25"
              fillColor={replyValue || isImageUploaded ? "#fdfd96" : "#ccc"}
            />
            <span
              className="icon-label"
              style={{
                color: replyValue || isImageUploaded ? "#fdfd96" : "#ccc",
              }}
            >
              Tag
            </span>
          </li>
          <li className="UserPostDetails-listItem">
            <CameraIcon
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
              onClick={handleUploadClick} // Use triggerFileUpload function on click
            />
            <span className="icon-label" style={{ color: "#fdfd96" }}>
              Upload
            </span>
          </li>
          <li
            className={`UserPostDetails-listItem ${
              replyValue ? "active" : "disabled"
            }`}
            style={{
              cursor: replyValue ? "pointer" : "not-allowed",
            }}
          >
            <PaperPlane2Icon
              width="25"
              height="25"
              fillColor={replyValue || isImageUploaded ? "#fdfd96" : "#ccc"}
            />
            <span
              className="icon-label"
              style={{
                color: replyValue || isImageUploaded ? "#fdfd96" : "#ccc",
              }}
            >
              Send
            </span>
          </li>
        </ul>
        <div className="file-upload-wrapper">
          <input
            id="file-upload"
            ref={fileInputRef}
            type="file"
            onChange={onFileChange} // Use handleFileChange function on file input change
            style={{ display: "none" }} // Hide the input field
          />
        </div>
        {replyValue && (
          <button
            className="clear-button"
            // ref={clearPostCommentBtnRef}
            onClick={() => {
              setReplyValue(""); // Clear the textarea
              setReplyError(false); // Reset the error message
              clearReplyStateAndStorage(currentContext, activeReply);
            }}
          >
            Clear
          </button>
        )}
        {(replyValue || isImageUploaded) && (
          <ImgTopBtn
            id="cancel-btn"
            imgTopBtnBorderRadius="25px"
            imgTopBtnText=" X Cancel"
            imgTopBtnWidth="fit-content"
            imgTopBtnTextFontSize="1.3rem"
            imgTopBtnnFontFamily="Roboto-Slab"
            imgTopBtnFontWeight="900"
            imgTopBtnFontColor="#964b00"
            paddingRight="5px"
            paddingLeft="5px"
            extraImgTopBtnStyles={{
              bottom: "20px",
              left: "10px",
            }}
            onClick={handleCancelReply}
          />
        )}
      </div>
      {!replyValueFocused && replyValue === "" && (
        <span className="blinking-cursor blinking">|</span>
      )}
    </div>
  );
};
export default UserReply;
