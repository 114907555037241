import { useEffect, useState } from "react";
import {
  dollarsToCentsCalc,
  centsToDollarsCalc,
} from "../../components/constant/conversions.js";
import {
  getCurrentRewardLevel,
  getCurrentRewardLevelName,
} from "../../components/constant/getCurrentRewardLevel.js";
import { ImgTopBtn } from "../../components/buttons/index.jsx";
import "./checkout.scss";

// this file manages calculations, invoice, and event listeners
const PlaceOrderSummary = ({
  setGiftCardBalUsed,
  giftCardBalUsed,
  maxGiftBal,
  setMaxGiftBal,
  setSelectedPaymentMethod,
  beginingRewardLevel,
  beginingPointsBalance,
  beginingRewardLevelName,
  giftCardBalance,
  tierPriceSelected,
  merchantFee, // passing in the merchant fee to be calculated
  setSelectedMerchantFee,
  setShowPaymentDetails,
  validateBillingDetails,
  setHasAttemptedToOrder,
  placeOrder,
  setPlaceOrder,
  handleGenerateReceipt,
  onAttemptingToOrder, // only placing the call back for the event listner to send the click and map through on the parent where state lives to ensure a tier price was selected
}) => {
  const [bullsEyeAnimation, setBullsEyeAnimation] = useState({});
  useEffect(() => {
    // Clearing localStorage when tierPriceSelected changes.
    localStorage.clear();
    console.log("tierPriceSelected:", tierPriceSelected);
    const itemsSubtotalCents = dollarsToCentsCalc(tierPriceSelected);
    console.log("itemsSubtotalCents:", itemsSubtotalCents);
    let merchantFeeCalcCents = Math.floor(itemsSubtotalCents * merchantFee);
    console.log("merchantFeeCalcCents:", merchantFeeCalcCents);
    // Check if gift card covers the entire itemsSubtotal right now it just === tierPriceSelected
    if (itemsSubtotalCents > 0 && giftCardBalUsed * 100 >= itemsSubtotalCents) {
      // Resetting merchant fee state and local variable
      setSelectedMerchantFee(0);
      merchantFeeCalcCents = 0;
      // Resetting payment dropdown
      setSelectedPaymentMethod(null);
      setShowPaymentDetails(false);
      setHasAttemptedToOrder(false);
      // Assuming you have a setter for hasAttemptedToOrder
      // If everything is valid and order processing continues, reset hasAttemptedToOrder.      // setPaymentError(false); // Ensure the error state is false when you're resetting
    } else {
      // If gift card doesn't cover entire itemsSubtotal, calculate the merchant fee
      merchantFeeCalcCents = Math.floor(itemsSubtotalCents * merchantFee);
      setShowPaymentDetails(true);
    }
    const grandTotalCalcCents = itemsSubtotalCents + merchantFeeCalcCents; // grandTotal is items subtotal + merchant fee
    console.log("grandTotalCalcCents:", grandTotalCalcCents);
    // data validation to allow user to use up the the max gift card balance not highter than grand total
    let totalDueCalcCents;
    if (giftCardBalUsed * 100 === maxGiftBal * 100) {
      totalDueCalcCents = grandTotalCalcCents - giftCardBalUsed * 100;
    } else {
      totalDueCalcCents = grandTotalCalcCents;
    }
    console.log("totalDueCalcCents:", totalDueCalcCents);
    // order summary that is getting updated in real time as user is adding payment type
    setPlaceOrder((prevOrder) => ({
      ...prevOrder,
      orderSubTotal: centsToDollarsCalc(itemsSubtotalCents),
      grandTotal: centsToDollarsCalc(grandTotalCalcCents),
      totalDue: centsToDollarsCalc(totalDueCalcCents),
      merchantFee: centsToDollarsCalc(merchantFeeCalcCents),
    }));
    // grand total is more than the gift card balance)
    if (grandTotalCalcCents >= dollarsToCentsCalc(giftCardBalance)) {
      // if true split payment they can't cover the full amount the maximum amount they can use from the gift card is the total balance on the card. This is set by:
      setMaxGiftBal(giftCardBalance);
    } else {
      // If false (i.e., the grand total is less than the gift card balance), the maximum gift card balance they should be allowed to use should be the grand total itself. This is set by
      setMaxGiftBal(centsToDollarsCalc(grandTotalCalcCents));
    }
  }, [giftCardBalUsed, tierPriceSelected, merchantFee, giftCardBalance]);
  const onChangeHandle = (event) => {
    const value = +event.target.value;
    setGiftCardBalUsed(value); // Always update the value, regardless of the condition
    console.log("Gift Card Balance Used updated to:", value);

  };
  // total due calculated not total due because it can be 0 on initial which would not trigger a split or single card payment method
  const onPlaceOrder = () => {
    // error handling for split payment and single payment from user's card
    onAttemptingToOrder(); // call back to the parent payment method page to invoke the function where state lives to check and see if teir pricing was selected
    // Set this right away to indicate an order attempt was made.
    setHasAttemptedToOrder(true);
    if (placeOrder.totalDue > 0 && !validateBillingDetails()) {
      console.log("Incomplete billing details for non-zero total due."); // create a scroll feature to see the errors
      return; // Exit the function if conditions aren't met.
    } else {
      setHasAttemptedToOrder(false); // If everything is valid and order processing continues, reset hasAttemptedToOrder.
      const totalDueCalcCents = placeOrder.totalDue; // Calculate how many points are earned from this order if split payment or earned points on payment recieved
      const orderPointsEarned = Math.round(
        beginingRewardLevel * totalDueCalcCents
      ); // points are rounded to keep them descrete values
      const currentPointsBalance = Math.round(
        beginingPointsBalance + orderPointsEarned
      ); // Update the points balance after the order
      console.log("is this rounding");
      let currentRewardLevel;
      let currentRewardLevelName;
      if (currentPointsBalance === 0) {
        // if no rewards were earned from gift card full purchase then no change
        currentRewardLevel = beginingRewardLevel;
        currentRewardLevelName = beginingRewardLevelName;
      } else {
        currentRewardLevel = getCurrentRewardLevel(currentPointsBalance); // getting level for the current points balance
        currentRewardLevelName =
          getCurrentRewardLevelName(currentPointsBalance); // getting level name for the current points balance
      }
      if (giftCardBalUsed === placeOrder.grandTotal && giftCardBalUsed > 0) {
        currentPointsBalance = beginingPointsBalance + totalDueCalcCents;
      }
      if (
        (giftCardBalUsed === placeOrder.grandTotal && giftCardBalUsed > 0) ||
        (placeOrder.totalDue > 0 && validateBillingDetails())
      ) {
        const invoice = {
          checkoutInvoice: {
            orderSubTotalBal: `$${placeOrder.orderSubTotal}`,
            grandTotalBal: `$${placeOrder.grandTotal}`,
            totalDueBal: `$${placeOrder.totalDue}`,
            ["Gift-Card-Balance-Used"]: `$${giftCardBalUsed}`,
            beginningGiftCardBal: `$${giftCardBalance.toFixed(2)}`,
            currentGiftCardBal: `$${(giftCardBalance - giftCardBalUsed).toFixed(
              2
            )}`,
            beginningPointsBalance: beginingPointsBalance
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            currentPointsBalance: currentPointsBalance
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            orderPointsEarned: orderPointsEarned
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            beginingRewardLevelName: beginingRewardLevelName,
            beginingRewardLevel: beginingRewardLevel
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            currentRewardLevelName: currentRewardLevelName,
            currentRewardLevel: currentRewardLevel
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            invoiceTimeStamp: `${new Date().toLocaleTimeString()}  ${new Date().getFullYear()}/${
              new Date().getMonth() + 1
            }/${new Date().getDate()}`,
          },
          tierPriceSelected,
        };
        handleGenerateReceipt(invoice);
        localStorage.setItem("invoice", JSON.stringify(invoice));
      }
    } // This is the closing brace for the else block
  };

  return (
    <div className="confirm_order_section">
      <div className="order_summary">
        <img src="/images/icons/invoicePayment.svg" alt="" />
        <h2>Order Summary</h2>
      </div>
      <div className="order_summary_table">
        <div className="ItemSub">
          <h2>Items Subtotal:</h2>
          {/* {console.log(
              "Checking orderSubTotal:",
              placeOrder?.orderSubTotal
            ) || placeOrder?.orderSubTotal?.toLocaleString()} place to or in a function to check it */}
          <span className="subtotal_price">
            ${placeOrder?.orderSubTotal?.toLocaleString()}
          </span>
        </div>
        <div className="Item_reffer_fee">
          <h2>Merchant Fee:</h2>
          <span className="item_subtotal_fee">
            ${placeOrder?.merchantFee?.toLocaleString()}(
            {/* {(merchantFee * 100).toFixed(2)}%) 
            will reformat the line below to remove the 00s and the "." */}
            {Math.round(merchantFee * 100)}%)
          </span>
        </div>
        <div className="Item_grand_total">
          <h2>Grand Total:</h2>
          <span className="Item_grand_total_price" data-itemgrandtotalamount="">
            ${placeOrder?.grandTotal?.toLocaleString()}
          </span>
        </div>
        {giftCardBalance > 0 && placeOrder.grandTotal > 0 && (
          <>
            <div className="rangeSlider">
              <h2>Add Gift Card:</h2>
              <div className="wrapper_giftcard">
                <div className="slider_giftcard">
                  {/* Adjust the styles using the new state variables */}
                  <div
                    className="thumb_giftcard"
                    style={{ left: `${(giftCardBalUsed / maxGiftBal) * 100}%` }}
                  >
                    <span />
                  </div>
                  <div
                    className="progress-bar_giftcard"
                    style={{
                      width: `${(giftCardBalUsed / maxGiftBal) * 100}%`,
                    }}
                  />
                  {/* <!-- ---------------------------#Change--------------------------------- --> */}
                  {/* Update the input range with the new state variables */}
                  <div id="giftCard_slider_container">
                    <input
                      type="range"
                      onInput={onChangeHandle} // This shows feedback on the UI
                      min="0"
                      value={giftCardBalUsed}
                      data-maxvalue=""
                      id="giftCard_balance_range"
                      max={maxGiftBal}
                      step="0.01"
                    />
                  </div>
                  {/* Continue with the other elements */}
                  <div
                    className="arrowHittingBullseye"
                    style={bullsEyeAnimation}
                  >
                    <img src="/images/icons/arrowForRange.svg" alt="" />
                  </div>
                  {giftCardBalUsed > 0 && giftCardBalUsed === maxGiftBal && (
                    <div className="bullseye_showHide">
                      <img src="/images/icons/bullseye.svg" alt="" />
                    </div>
                  )}
                </div>
              </div>
              <span id="selected_balance_fromRangeSlider">
                {/* {giftCardBalUsed > 0 ? `-$${giftCardBalUsed.toFixed(2)}` : "$0"} */}
                {giftCardBalUsed > 0 &&
                (giftCardBalUsed === maxGiftBal ||
                  placeOrder.totalDue === "0.00" ||
                  setSelectedPaymentMethod === null)
                  ? `-$${giftCardBalUsed.toFixed(2)}`
                  : "-$0.00"}
              </span>
            </div>
            <div className="text_guide_range">
              <p>Slide To The Right Apply Gift Card Balance</p>
            </div>
          </>
        )}
        <hr className="underLine" />
        <div className="total_due">
          <h1>Total Due:</h1>
          <h4 className="due_amount">
            ${placeOrder?.totalDue?.toLocaleString()}
          </h4>
        </div>
      </div>
      <ImgTopBtn
        imgTopBtnText="Place Your Order"
        onClick={onPlaceOrder}
        imgTopBtnWidth="200px"
        imgTopBtnImage={<img src="/images/icons/submissionIcon.svg" alt="" />}
      />
      <div className="termContainer_subtotalSection">
        <p>
          By placing your order you agree to bidonow's{" "}
          <a href="#"> privacy notice, conditions of use </a> and all the terms
          found <a href="#">here</a> .
        </p>
      </div>
    </div>
  );
};

export default PlaceOrderSummary;
