// CommunityPadMobileSearch.jsx
import React, { useState } from "react";
import { GlassBtn } from "../../components/buttons";
import {
  Members,
  Posts,
  Filter,
  FindUsername,
  XIcon,
} from "../../components/icons";
import { CustomButton, PlainBtn } from "../../components/buttons";
import mockData from "../../seedMockData/seedMockData";
import "./communityPadMobileSearch.scss";

const CommunityPadMobileSearch = ({
  // regular handle on click call backs to parent
  onShowAll, // call back from the parent handler to show both post and members
  onShowPosts, // call back from the parent handler to here to show member post
  onShowMembers, // call back from the parent handler to here to show members
  onShowFollowingList, // call back from the parent handler to here to show current user's followings list
  onShowFollowersList, // call back from the parent handler to here to show current user's followers list
  showFollowOptions, // receive the state variable from the parent component community page used for the following buttons to appear when you click on member
  followers, // receive from the parent component community page used for followers total (the number or data is passed here), style is on for this both child and parent
  following, // receive from the parent component community page used for followers total (the number or data is passed here), style is on for this both child and parent
  handleUserNameChange,
  userNameValueSearchCriteria,
  view,
  allUsers,
  setAllUsers,
  onUserNameSearchValueFocused,
  setOnUserNameSearchValueFocused,
  setOnUserNameSearchValueBlur,
  clearUserNameValueSearchCriteriaLocalStorage,
}) => {
  const [showUserNameSearch, setShowUserNameSearch] = useState(false); // show the search field input when clicked management state, will only be used for mobile

  const usersWithPosts = allUsers
  .map((user) => {
    // Find the first post associated with the user
    const userPost = mockData.posts.find((post) => post.userId === user.id);

    // If a post exists, add the postId and imagePath to the user object
    return userPost ? { ...user, postId: userPost.id, imagePath: userPost.imagePath } : user;
  })
  .filter((user) => user.postId); // Ensure only users with posts are included


  const showUserNameSearchInput = () => {
    setShowUserNameSearch(true);
  };
  // When the search criteria changes, filter the users.
  const findUserName = () => {
    const userNameResults = mockData.users.filter((user) =>
      user.username
        .toLowerCase()
        .includes(userNameValueSearchCriteria.toLowerCase())
    );
    console.log("Filtered users:", userNameResults);
    setAllUsers(userNameResults);
  };
  return (
    <div id="community-pad-mobile-search">
      <div className="btns-container">
        <span className="all-cta">
          <GlassBtn
            glassBtnImage={
              <Filter
                height="15px"
                width="15px"
                fillColor={view === "all" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnText="All"
            glassBtnFontColor={view === "all" ? "#fff" : "#fdfd96"}
            glassBtnTextDecorationColor={view === "all" ? "#fff" : "#fdfd96"}
            onClick={onShowAll}
            className={view === "all" ? "active-button" : "inactive-button"}
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        </span>
        <span className="posts-cta">
          <GlassBtn
            glassBtnImage={
              <Posts
                height="15px"
                width="15px"
                fillColor={view === "posts" ? "#fff" : "#e2b645"} // Dynamic fillColor
              />
            }
            className={view === "posts" ? "active-button" : "inactive-button"}
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnText="POST"
            glassBtnFontColor={view === "posts" ? "#fff" : "#e2b645"}
            glassBtnTextDecorationColor={view === "posts" ? "#fff" : "#e2b645"}
            onClick={onShowPosts}
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        </span>
        <span className="members-cta">
          <GlassBtn
            glassBtnImage={
              <Members
                height="15px"
                width="15px"
                fillColor={view === "members" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            className={view === "members" ? "active-button" : "inactive-button"}
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnText="Members"
            glassBtnFontColor={view === "members" ? "#fff" : "#fdfd96"}
            glassBtnTextDecorationColor={
              view === "members" ? "#fff" : "#fdfd96"
            }
            onClick={onShowMembers}
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
              zIndex: 9,
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
              zIndex: 9,
            }}
          />
        </span>
      </div>
      {showFollowOptions && (
        <>
          <div className="mbPadFollowOption-container">
            <GlassBtn
              glassBtnImage={
                <div>
                  <span
                    className={
                      view === "followers"
                        ? "followersNumPadMble-active"
                        : "followersNumPadMble-inactive"
                    }
                  >
                    {/* followers is added to check if it is undefined before rendering it since some may not have any */}
                    {followers && followers.length}
                  </span>
                </div>
              }
              onClick={onShowFollowersList}
              glassBtnHeight="20px"
              glassBtnWidth="100px"
              glassBtnTextFontSize=".75rem"
              glassBtnFontWeight="900"
              glassBtnText="Followers"
              className={
                view === "followers" ? "active-button" : "inactive-button"
              }
              glassBtnFontColor={view === "followers" ? "#fff" : "#fdfd96"}
              glassBtnTextDecorationColor={
                view === "followers" ? "#fff" : "#fdfd96"
              }
              hi2Style={{
                transform: "rotate(90deg) translate(-80px, 50px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-80px, -50px)",
              }}
            />
            <GlassBtn
              glassBtnImage={
                <div>
                  <span
                    className={
                      view === "following"
                        ? "followingNumPadMble-active"
                        : "followingNumPadMble-inactive"
                    }
                  >
                    {/* following is added to check if it is undefined before rendering it since some may not have any */}
                    {following && following.length}
                  </span>
                </div>
              }
              onClick={onShowFollowingList}
              glassBtnHeight="20px"
              glassBtnWidth="100px"
              glassBtnTextFontSize=".75rem"
              glassBtnFontWeight="900"
              glassBtnText="Following"
              className={
                view === "following" ? "active-button" : "inactive-button"
              }
              glassBtnFontColor={view === "following" ? "#fff" : "#e2b645"}
              glassBtnTextDecorationColor={
                view === "following" ? "#fff" : "#e2b645"
              }
              hi2Style={{
                transform: "rotate(90deg) translate(-80px, 50px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-80px, -50px)",
              }}
            />
          </div>
        </>
      )}
      <div className="input-container">
        {!showUserNameSearch && (
          <>
            <CustomButton
              image={<FindUsername height="30px" fillColor="#964b00" />}
              className={"search-icon-btn search-instruction"}
              onClick={showUserNameSearchInput}
              customBtnText="Press for search"
              customBtnFontColor="#964b00"
              customBtnBorderColor="#964b00"
              customBtnBorderWidth="2px"
              paddingRight="5px"
              imagePadding="5px" // Adjust as needed
            />
          </>
        )}
        {showUserNameSearch && (
          <div className="search-container">
            <input
              placeholder="Enter username"
              value={userNameValueSearchCriteria}
              onChange={(e) =>
                handleUserNameChange(e.target.value, "userNameValue")
              }
              onFocus={() => setOnUserNameSearchValueFocused(true)}
              onBlur={() => setOnUserNameSearchValueBlur(false)}
            />
             {!onUserNameSearchValueFocused && userNameValueSearchCriteria === "" && (
              <span className="blinking-cursor blinking topten-cursor">|</span>
            )}
            {userNameValueSearchCriteria && (
              <button
                className="Clear-mb-pad-communitypg"
                onClick={() => {
                  clearUserNameValueSearchCriteriaLocalStorage();
                }}
                style={{
                  color: "#fdfd96",
                  backgroundColor: "transparent",
                  borderColor: "#fdfd96",
                }}
              >
                Clear
              </button>
            )}
            {userNameValueSearchCriteria && (
              <PlainBtn
                className="search search-btn-mbpad"
                image={
                  <FindUsername
                    width="20px"
                    height="20px"
                    fillColor="#964b00"
                  />
                }
                imageOnTop={true}
                plainBtnHeight="45px"
                onClick={() => {
                  clearUserNameValueSearchCriteriaLocalStorage();
                  findUserName(userNameValueSearchCriteria);
                }}
                plainBtnFontWeight="900"
                plainBtnFontColor="#964b00"
                plainBtnBorderColor="#964b00"
                plainBtnBorderRadius="0px"
                plainBtnText="Search"
                padding-right="5px"
                marginRight="3px"
              />
            )}
            {userNameValueSearchCriteria && (
              <PlainBtn
                className="cancel cancel-btn-mbpad"
                image={<XIcon width="20px" height="20px" fillColor="#964b00" />}
                onClick={() => {
                  clearUserNameValueSearchCriteriaLocalStorage();
                  setShowUserNameSearch(false);
                }}
                imageOnTop={true}
                plainBtnHeight="45px"
                plainBtnFontWeight="900"
                plainBtnFontColor="#964b00"
                plainBtnBorderColor="#964b00"
                plainBtnBorderRadius="0px"
                plainBtnText="Cancel"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default CommunityPadMobileSearch;
