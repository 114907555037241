const FormatDateAndTimeDisplay = (event) => {
  const startDate = new Date(event.eventStartDate);
  const endDate = new Date(event.eventEndDate);
  // Extract the various parts of the date
  const weekday = startDate.toLocaleString("default", { weekday: "long" });
  const date = startDate.getDate();
  const month = startDate.toLocaleString("default", { month: "long" });
  const year = startDate.getFullYear();
  // Generate the date string
  const formattedDate = `${weekday}, ${date}, ${month}, ${year}`;
  let timeString = "";
  if (event.eventStartTime && event.eventEndTime) {
    timeString = `From ${event.eventStartTime.slice(
      0,
      8
    )} - ${event.eventEndTime.slice(0, 8)}`;
  } else if (event.eventStartTime) {
    timeString = `At ${event.eventStartTime.slice(0, 8)}`;
  } else if (event.eventEndTime) {
    timeString = `Until ${event.eventEndTime.slice(0, 8)}`;
  }

  return `${formattedDate} ${timeString}`;
};
export default FormatDateAndTimeDisplay;

// Usage
// const dateString = "2023-04-15";
// const start = "14:00";
// const end = "16:00";

// console.log(formatDateAndTime(dateString, start, end));
