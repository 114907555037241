import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  BellIcon,
  CommunityIcon,
  ChatIcon,
  CalendarIcon,
  ProfileIcon,
  DownArrow,
  People,
} from "../../icons";
import CancelDropDownAcct from "./cancelDropDownAcct";

export default function NavLinks({
  notification,
}) {
  // passing data props here to be executed to the child do not need to set up a call back yet to update state in the parent
  const userProfileImage = null; // Would need to somehow reference a user's profile image
  const location = useLocation(); // Get current location using useLocation hook
  // Determine if the current page is one of the following
  const currentPage = {
    connections: location.pathname === "/connections",
    community: location.pathname === "/community",
    chats: location.pathname === "/chats",
    groups: location.pathname.includes("/groups"), // the includes will check the path and allow the children such as the details to have the indicator for active link on the nav
    events: location.pathname.includes("/events"),  // the includes will check the path and allow the children such as the details to have the indicator for active link on the nav
    notifications: location.pathname === "/notifications",
    profile: location.pathname.includes("/profile"), // since profile is under your account adding this will allow the profile circle to have the active link and when you click on your account you will see profile the word in active color. 
    settings: location.pathname === "/settings",
    paymentMethods: location.pathname === "/payment-methods",
    receiptsInvoices: location.pathname === "/receipts-invoices",
    giftCardsRewards: location.pathname === "/dashboard",
    subcategoryMenu: location.pathname.includes("/select-subcategory") && location.pathname.includes("/subcatmenu"),
  };
  // Style constants
  const activeLinkColor = "#fff"; // word color indicator for a link clicked
  const hoverFillLinkColor = "#fdfd96";
  const hoverFillSvgColor = "#815121"; // svg color when it is not clicked
  const activeIconColor = "#000"; // svg color indicator for a link clicked
  // useState hooks for hover states
  const [communityHover, setCommunityHover] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [chatsHover, setChatsHover] = useState(false);
  const [notificationsHover, setNotificationsHover] = useState(false);
  const [eventsHover, setEventsHover] = useState(false);
  const [groupsHover, setGroupsHover] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isCloseHovered, setIsCloseHovered] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  // UseState hook for hover state of each dropdown link
  const [profileHover, setProfileHover] = useState(false);
  const [settingsHover, setSettingsHover] = useState(false);
  const [giftCardsRewardsHover, setGiftCardsRewardsHover] = useState(false);
  const [paymentMethodsHover, setPaymentMethodsHover] = useState(false);
  const [receiptsInvoicesHover, setReceiptsInvoicesHover] = useState(false);

  // Switch handling logic for when link is hovered
  const handleDropdownMouseEnter = (linkName) => {
    switch (linkName) {
      case "profile":
        setProfileHover(true);
        break;
      case "settings":
        setSettingsHover(true);
        break;
      case "gift-cards-rewards":
        setGiftCardsRewardsHover(true);
        break;
      case "payment-methods":
        setPaymentMethodsHover(true);
        break;
      case "receipts-invoices":
        setReceiptsInvoicesHover(true);
        break;
      default:
        break;
    }
  };

  const handleDropdownMouseLeave = (linkName) => {
    switch (linkName) {
      case "profile":
        setProfileHover(false);
        break;
      case "settings":
        setSettingsHover(false);
        break;
      case "gift-cards-rewards":
        setGiftCardsRewardsHover(false);
        break;
      case "payment-methods":
        setPaymentMethodsHover(false);
        break;
      case "receipts-invoices":
        setReceiptsInvoicesHover(false);
        break;
      default:
        break;
    }
  };
  // Handle mouse enter and leave for the 'Your Account' dropdown
  const handleAccountMouseEnter = () => {
    setHovered(true);
  };

  const handleAccountMouseLeave = () => {
    setHovered(false);
  };
  if (isDropdownVisible) {
    // Dropdown JSX code
  }
  // Dropdown toggle function
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
    // Add this log to see the state right after setting it
    setIsCloseHovered(false); // Reset the hover state of the close button
  };

  const handleAccountClick = (event) => {
    event.preventDefault(); // Prevents the default NavLink behavior
    console.log(
      "Account Clicked, Dropdown Visibility before toggle:",
      isDropdownVisible
    );
    toggleDropdown();
  };

  // combining state for complex nested and separate links
  const handleMouseEnter = (linkName) => {
    setHoveredLink(linkName);
  };
  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  const handleNavLinkClicked = () => {
    setIsDropdownVisible(false);
  };

  // remove this when backend is ready hard coded login state
  const [user] = useState({
    id: "user-uuid-5",
    username: "AcctHolder",
  });
  useEffect(() => {
    if (location.pathname.includes(`/profile/${user.username}#member`)) {
      console.log("Navigated to the profile of", user.username);
    }
  }, [location, user.username]);
  const scrollWithOffset = (el, additionalOffset = 0) => {
    // a hash id is needed with this on the component as this will calculate how far from the header it will scroll down from to the id, the key is the offset property with the scroll
    // so that it scrolls at the right position and moves from the header
    const offset = 100; // The offset value should be equal to the height of your fixed header
    const elementPosition = el.offsetTop;
    const offsetPosition = elementPosition - offset - additionalOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <HashLink
        to="/community#listing"
        scroll={(el) => scrollWithOffset(el)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
        className="nav-link"
        onClick={handleNavLinkClicked}
        onMouseEnter={() => setCommunityHover(true)}
        onMouseLeave={() => setCommunityHover(false)}
        style={{
          color: currentPage.community
            ? activeLinkColor
            : communityHover
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div
          className={`nav-link-circle ${currentPage.community ? "active" : ""}`}
        >
          <CommunityIcon
            fillColor={
              currentPage.community
                ? activeIconColor // for dynamic precision control
                : communityHover // If not on 'community' but the link is hovered, it sets the color to `hoverFillSvgColor`.
                ? hoverFillSvgColor // If not on 'community' but the svg is hovered, it sets the color to `hoverFillSvgColor`.
                : "#e2b645"
            }
          />
        </div>
        <span>Community</span>
      </HashLink>
      <HashLink
        to="/groups#listing"
        scroll={(el) => scrollWithOffset(el,150)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
        className="nav-link"
        onClick={handleNavLinkClicked}
        onMouseEnter={() => setGroupsHover(true)}
        onMouseLeave={() => setGroupsHover(false)}
        style={{
          color: currentPage.groups
            ? activeLinkColor
            : groupsHover
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div
          className={`nav-link-circle ${currentPage.groups ? "active" : ""}`}
        >
          <People
            width="40" // Change the width to your desired value, e.g., "40"
            height="40" // Change the height to your desired value, e.g., "40"
            fillColor={
              currentPage.groups
                ? activeIconColor
                : groupsHover
                ? hoverFillSvgColor
                : "#e2b645"
            }
          />
        </div>
        <span>Groups</span>
      </HashLink>
      <HashLink
        to="/events#listing"
        scroll={(el) => scrollWithOffset(el)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
        className="nav-link"
        onClick={handleNavLinkClicked}
        onMouseEnter={() => setEventsHover(true)}
        onMouseLeave={() => setEventsHover(false)}
        style={{
          color: currentPage.events
            ? activeLinkColor
            : eventsHover
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div
          className={`nav-link-circle ${currentPage.events ? "active" : ""}`}
        >
          <CalendarIcon
            width="35" // Change the width to your desired value, e.g., "40"
            height="30" // Change the height to your desired value, e.g., "40"
            fillColor={
              currentPage.events
                ? activeIconColor
                : eventsHover
                ? hoverFillSvgColor
                : "#e2b645"
            }
          />
        </div>
        <span>Events</span>
      </HashLink>
      <HashLink
        to="/chats"
        className="nav-link nav-link-chat"
        onClick={handleNavLinkClicked}
        onMouseEnter={() => setChatsHover(true)}
        onMouseLeave={() => setChatsHover(false)}
        style={{
          color: currentPage.chats
            ? activeLinkColor
            : chatsHover
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div className={`nav-link-circle ${currentPage.chats ? "active" : ""}`}>
          <ChatIcon
            fillColor={
              currentPage.chats
                ? activeIconColor // for dynamic precision control since backgrounds for content and svg are different
                : chatsHover
                ? hoverFillSvgColor
                : "#e2b645"
            }
          />
        </div>
        <span>Chats</span>
      </HashLink>
      <HashLink
        to="/notifications"
        className="nav-link"
        onClick={handleNavLinkClicked}
        onMouseEnter={() => setNotificationsHover(true)}
        onMouseLeave={() => setNotificationsHover(false)}
        style={{
          color: currentPage.notifications
            ? activeLinkColor
            : notificationsHover
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div
          className={`nav-link-circle ${
            currentPage.notifications ? "active" : ""
          }`}
        >
          <BellIcon
            fillColor={
              currentPage.notifications
                ? activeIconColor
                : notificationsHover
                ? hoverFillSvgColor
                : "#e2b645"
            }
          />
        </div>
        <span>Notifications</span>
        {notificationsHover && (
          <div className="notification-dropdown">
            {notifications.slice(0, 3).map((notification) => (
              <HashLink
                key={notification.id}
                to={notification.link}
                className="notification-item"
              >
                {notification.text}
              </HashLink>
            ))}
            {notifications.length > 3 && (
              <div className="see-all-notifications">
                <HashLink to="/notifications">See all notifications</HashLink>
              </div>
            )}
          </div>
        )}
      </HashLink>
      <HashLink
        to="/profile#member"
        scroll={(el) => scrollWithOffset(el, 150)} // Adding an additional 50px offset for profile page
        className="nav-link nav-link-account"
        onMouseEnter={() => handleMouseEnter("account")}
        onMouseLeave={handleMouseLeave}
        onClick={handleAccountClick} // Using our custom click handler
        style={{
          color: currentPage.profile
            ? activeLinkColor
            : hoveredLink === "account"
            ? hoverFillLinkColor
            : "#e2b645",
        }}
      >
        <div
          className={`nav-link-circle ${isDropdownVisible ? "no-shadow" : ""}`}
          style={{ backgroundColor: "white" }}
        >
          {userProfileImage ? (
            <h1>RENDER USER PROFILE IMAGE</h1>
          ) : (
            <ProfileIcon
              fillColor={
                currentPage.profile
                  ? activeIconColor
                  : hoveredLink === "account"
                  ? hoverFillSvgColor
                  : "#e2b645"
              }
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: isDropdownVisible
                ? "#fff"
                : hovered
                ? hoverFillLinkColor
                : "#e2b645",
            }}
          >
            Your Account
          </span>
          <DownArrow
            fillColor={
              isDropdownVisible
                ? "#fff"
                : hovered
                ? hoverFillLinkColor
                : "#e2b645"
            }
            width="16"
            height="16"
            style={{ marginLeft: "5px" }}
            onMouseEnter={handleAccountMouseEnter}
            onMouseLeave={handleAccountMouseLeave}
          />
        </div>
      </HashLink>
      {isDropdownVisible && (
        <div
          className="dropdown-menu"
          style={{
            position: "absolute",
            top: "100%",
            right: "0",
            backgroundColor: "#030303",
            borderRadius: "5px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: "10px", // Adjust as needed
              right: "10px", // Adjust as needed
              cursor: "pointer",
            }}
            onClick={toggleDropdown}
            onMouseEnter={() => setIsCloseHovered(true)}
            onMouseLeave={() => setIsCloseHovered(false)}
          >
            {/* hover color and original state when not hovered for link and svg*/}
            <CancelDropDownAcct
              isActive={isDropdownVisible}
              animationType="6"
              marginBottom="15px"
              fillColor={isCloseHovered ? "#fdfd96" : "#e2b645"}
              style={{ marginRight: "5px" }}
            />
            <span
              style={{
                color: isCloseHovered ? "#fdfd96" : "#e2b645",
                fontWeight: "800",
              }}
            >
              Cancel
            </span>
          </div>
          <div
            style={{
              marginRight: "40px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HashLink
              //  to={`/profile/${userId}`}
              to={`/profile/${user.username}#member`} // Access the username from the state above hardcoded login state to be acct holder-->remove this when backend starts const [user, setUser] = useState({ id: "user-uuid-5", username: "AcctHolder", });
              scroll={(el) => scrollWithOffset(el, 150)} // Adding an additional 50px offset for profile page
              className="dropdown-link"
              onMouseEnter={() => handleDropdownMouseEnter("profile")}
              onMouseLeave={() => handleDropdownMouseLeave("profile")}
              onClick={handleNavLinkClicked}
              // contactinating the varaialbes by binding the color with an if else conditional terary operator
              //   if (currentPage.profile) {
              //     color = '#fff';
              // } else if (profileHover) {
              //     color = hoverFillLinkColor;
              // } else {
              //     color = "#e2b645";
              // }
              style={{
                color: currentPage.profile
                  ? "#fff"
                  : profileHover
                  ? hoverFillLinkColor
                  : "#e2b645",
              }}
            >
              Profile
            </HashLink>
            <HashLink
              to="/settings#update"
              scroll={(el) => scrollWithOffset(el)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
              className="dropdown-link"
              onMouseEnter={() => handleDropdownMouseEnter("settings")}
              onMouseLeave={() => handleDropdownMouseLeave("settings")}
              onClick={handleNavLinkClicked}
              style={{
                color: currentPage.settings
                  ? "#fff"
                  : settingsHover
                  ? hoverFillLinkColor
                  : "#e2b645",
              }}
            >
              Settings
            </HashLink>
            <HashLink
              to="/payment-methods#select-tier"
              scroll={(el) => scrollWithOffset(el, 150)} // Adding an additional 50px offset for profile page
              className="dropdown-link"
              onMouseEnter={() => handleDropdownMouseEnter("payment-methods")}
              onMouseLeave={() => handleDropdownMouseLeave("payment-methods")}
              onClick={handleNavLinkClicked}
              style={{
                color: currentPage.paymentMethods
                  ? "#fff"
                  : paymentMethodsHover
                  ? hoverFillLinkColor
                  : "#e2b645",
              }}
            >
              Payment Methods
            </HashLink>
            <HashLink
              to="/receipts-invoices#approved"
              scroll={(el) => scrollWithOffset(el)} // the function above helps me position it correctly that the listing hasing section with an awesome scroll effect
              className="dropdown-link"
              onMouseEnter={() => handleDropdownMouseEnter("receipts-invoices")}
              onMouseLeave={() => handleDropdownMouseLeave("receipts-invoices")}
              onClick={handleNavLinkClicked}
              style={{
                color: currentPage.receiptsInvoices
                  ? "#fff"
                  : receiptsInvoicesHover
                  ? hoverFillLinkColor
                  : "#e2b645",
              }}
            >
              Receipts/Invoices
            </HashLink>
            <HashLink
              to="/dashboard#dashboard" // Redirects to dashboard
              className="dropdown-link"
              scroll={(el) => scrollWithOffset(el)}
              onMouseEnter={() =>
                handleDropdownMouseEnter("gift-cards-rewards")
              }
              onMouseLeave={() =>
                handleDropdownMouseLeave("gift-cards-rewards")
              }
              onClick={handleNavLinkClicked}
              style={{
                color: currentPage.giftCardsRewards
                  ? "#fff"
                  : giftCardsRewardsHover
                  ? hoverFillLinkColor
                  : "#e2b645",
              }}
            >
              Rewards/Gift Cards
            </HashLink>
          </div>
        </div>
      )}
    </>
  );
}
