export function debounce(func, delay) {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  

// invoking the function from outside the functions reason
    // Separation of Logic: We separated the core logic of the slide completion 
    // into its own function (coreSlideCompletion). This function solely handles 
    // what should be done when the slide is completed, without worrying about the debouncing.

    // Single Debounced Function: The debouncedHandleSlideCompletion 
    // function is then defined outside of any other function, 
    // ensuring it's only created once during the component's lifecycle. 
    // This means that all calls to this function within a short duration 
    // will be debounced, giving you the desired effect. 
    // Since it's created only once, it retains its debouncing effect 
    // across all invocations.

    // Preventing Infinite Loops: By separating the core 
    // logic and the debouncing, we've ensured that there's no 
    // way for an infinite loop to occur. The debounced function 
    // calls the core logic directly without any further debouncing.