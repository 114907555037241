import React, { useState, useEffect, useRef } from "react";
import mockData from "../../seedMockData/seedMockData";
import { useParams, useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";
import CurrentTimeDay from "../../components/currentTimeDay/currentTimeDay.jsx";
import {
  GlassBtn,
  ToggleSlider,
  SwitchSlider,
  SlideBtn,
  SolidColorButton,
  UpdateEventSlider,
  ImgTopBtn,
} from "../../components/buttons/index.jsx";
import {
  Xylophone,
  ClipBoardCheck,
  ThumbIndexOut,
  XIcon,
  ScrollList,
  Remote,
  EyeIcon,
  EyeSlashIcon,
} from "../../components/icons/index.jsx";
import { Back, FindUsername, Filter } from "../../components/icons/index.jsx";
import { debounce } from "../../utils/searchDebounceUtils";
import StatusList from "../../components/statusList/statusList";
import { useFilterNavScroll } from "../../utils/scrollUtils.js";
import FormatDateAndTimeDisplay from "../../utils/guiTimeFormat.js";
import { capitalizeFirstLetter } from "../../utils/stringUtils.js";
import timeCalculator from "../../utils/timeCalculator";
import Connections from "../../components/connections/connections";
import { EventPopup } from "../../components/forms";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import EventDetailsPadMobileNav from "./eventDetailsPadMobileNav.jsx";
import "./eventDetails.scss";

//toDo add compression for images to fit on backend
const EventDetailsPage = ({ updateEvent = true }) => {
  const navigate = useNavigate();
  const currentContext = "eventDetails";
  const filterNavContainerRef = useRef(null);
  const [currentForm, setCurrentForm] = useState("eventPopupUpdate");
  const [contextType, setContextType] = useState("eventPopupUpdate");
  // used for active color so i can manipulate the scss for error handling
  const [userNameEventSearchError, setUserNameEventSearchError] =
    useState(false);
  // and filtering the active list
  const invitedRef = useRef(null);
  const interestedRef = useRef(null);
  const attendingRef = useRef(null);
  const declinedRef = useRef(null);
  const maybeRef = useRef(null);
  const excludedRef = useRef(null);
  // used to scroll you to that section into view
  const guestAdminSectionRef = useRef(null);
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const { calendarId } = useParams();
  console.log("calendarId from useParams:", calendarId);
  const [isPrivateEvent, setIsPrivateEvent] = useState(false); // Assuming you want to start with 'Public'
  // Local state to manage blocked users
  const [isInviteManager, setIsInviteManager] = useState(true);
  const [isButtonPressed, setIsButtonPressed] = useState(false); // will reset the error handler = required messaage and red border
  const [eventSearchCriteriaValue, setEventSearchCriteriaValue] = useState(""); // used to monitor when the input changes will set up a handler to see the value change
  const [isSearchEventInputEmpty, setIsSearchEventInputEmpty] = useState(false); // used to check if the input is empty for error handling and to reset state with is button pressed
  const [isSliderSlid, setIsSliderSlid] = useState(false); // an event listener will be used for actviiting error messages after the slider has slid if conditions are not met
  const [isSearchMode, setIsSearchMode] = useState(false); // default to not search mode
  const [isFocused, setIsFocused] = useState(false);
  const [excludedUsers, setExcludedUsers] = useState([]);
  const [invited, setInvitedList] = useState([]);
  const [userAction, setUserAction] = useState(null);
  const [showAdminControls, setShowAdminControls] = useState(false);
  const [showPartyStatus, setShowPartyStatus] = useState(false);
  const [activityLogs, setActivityLogs] = useState(mockData.activityLogs);
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState(null);
  const [currentList, setCurrentList] = useState(null); // Can be 'interested', 'attending', etc.
  const resetFilters = () => {
    setEventSearchCriteriaValue("");
    setIsSearchEventInputEmpty(true);
    setCurrentList("");
  };
  const handleGoing = () => {
    setActiveTab((prevAction) => (prevAction === "Going" ? null : "Going"));
    showConfetti(); // Show confetti if user is going
  };
  const handleInterested = () => {
    setActiveTab((prevAction) =>
      prevAction === "Interested" ? null : "Interested"
    );
    // cheaper to grab count than to query each time example for count result update
    // specificEvent.eventTotalInterested += 1; // Increment the count
    // might also have logic here to push this change to a backend,
    // if i have one, or update local state, etc.
  };

  const handleInvite = () => {
    setUserAction((userAction) => (userAction === "invite" ? null : "invite"));
  };

  // Toggle functions
  const toggleAdminControls = () => {
    setShowAdminControls((prev) => !prev); // Toggle the visibility
  };

  const toggleParty = () => {
    setShowPartyStatus((prev) => !prev); // Toggle the visibility
  };
  const handleShowGuestStatus = () => {
    setActiveTab("guestStatus");
  };

  const handleShowAdminControls = () => {
    setActiveTab("adminControls");
  };
  useEffect(() => {
    if (activeTab === "adminControls" && guestAdminSectionRef.current) {
      guestAdminSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy(0, 850); // Adjust this value as needed
      }, 100);
    } else if (activeTab === "guestStatus" && guestAdminSectionRef.current) {
      guestAdminSectionRef.current.scrollIntoView({ behavior: "smooth" });
      // No additional scrolling adjustment needed for guestStatus
    }
  }, [activeTab, guestAdminSectionRef]);

  // // Show one list at a time, and for error handling, and message switching using condtional rendering
  const handleCurrentStatusList = (status) => {
    handleButtonPress();
    setCurrentList(status);
  };
  //event form update
  const togglePopup = () => {
    setEventFormOpen(!eventFormOpen);
  };
  const handleCancel = () => {
    setActiveTab(null); // Reset active tab to bring back 'Update Event' button
    // additional logic if needed
  };

  // call back to the slider inside the update
  // event form button
  const handleSlideEnd = () => {
    setContextType("eventPopupUpdate"); // Set contextType for updating an event
    setCurrentForm("eventPopupUpdate");
    // Show the popup when the slider ends
    togglePopup();
  };
  const setElementsZIndexToZero = () => {
    document.querySelectorAll(".events-btn").forEach((element) => {
      element.style.zIndex = "0";
    });
    // For the .events-cover-picture .current-time-overlay
    document
      .querySelectorAll(".events-cover-picture .current-time-overlay")
      .forEach((element) => {
        element.style.zIndex = "0"; // This will put it behind the popup
      });
  };
  const resetElementsZIndex = () => {
    document.querySelectorAll(".events-btn").forEach((element) => {
      element.style.zIndex = "5";
    });
    // For the .events-cover-picture .current-time-overlay
    document
      .querySelectorAll(".events-cover-picture .current-time-overlay")
      .forEach((element) => {
        element.style.zIndex = "2"; // This will keep it above the page content, but below the popup
      });
  };
  // this will monitor the stae of the popup so it can reset it back to z index intial sta
  useEffect(() => {
    if (eventFormOpen) {
      setElementsZIndexToZero();
    } else {
      resetElementsZIndex();
    }
  }, [eventFormOpen]);

  const [users, setUsers] = useState({
    interested: [],
    attending: [],
    maybe: [],
    blocked: [],
    declined: [],
    invited: [],
    excluded: [],
  });

  // used to filter the list when user searches user name
  const isAnyButtonActive = () => {
    const isMaybeActive =
      maybeRef.current &&
      maybeRef.current.classList.contains("maybeNum-active");
    const isDeclinedActive =
      declinedRef.current &&
      declinedRef.current.classList.contains("declinedNum-active");
    const isAttendingActive =
      attendingRef.current &&
      attendingRef.current.classList.contains("attendingNum-active");
    const isInterestedActive =
      interestedRef.current &&
      interestedRef.current.classList.contains("interestedNum-active");
    const isInvitedActive =
      invitedRef.current &&
      invitedRef.current.classList.contains("invitedNum-active");
    const isExcludedActive =
      excludedRef.current &&
      excludedRef.current.classList.contains("excludedNum-active");
    const isCurrentListActive = currentList !== null;

    // console.log("Is 'Maybe' active:", isMaybeActive);
    // console.log("Is 'Declined' active:", isDeclinedActive);
    // console.log("Is 'Attending' active:", isAttendingActive);
    console.log("Is 'Interested' active:", isInterestedActive);
    // console.log("Is 'Invited' active:", isInvitedActive);
    // console.log("Is 'Excluded' active:", isExcludedActive);

    return (
      isMaybeActive ||
      isDeclinedActive ||
      isAttendingActive ||
      isInterestedActive ||
      isInvitedActive ||
      isExcludedActive ||
      isCurrentListActive
    );
  };
  useFilterNavScroll();
  // toDo change message here..
  const getToggleLabels = (isPrivate) => {
    switch (isPrivate) {
      case true:
      case false:
        return {
          left: "",
          right: "",
        };
    }
  };
  const labels = getToggleLabels(isPrivateEvent);

  // Joining the calendar data with the media data for the specific event
  // Directly using the calendar data without joining with the media array
  let specificEvent;
  if (calendarId) {
    specificEvent = mockData.calendars.find(
      (calendar) => calendar.id === calendarId
    );
  } else {
    console.error("calendarId is undefined or null");
    // Handle the case where calendarId is not available
  }

  // Now, specificEvent will have all the calendar data including imagePath
  // You can use specificEvent.eventImagePath or similar to access the image path

  // Extracting the excludedCount directly from the refactored specificEvent
  const excludedCount = specificEvent ? specificEvent.eventTotalExcluded : 0;

  // No need to fetch the currentCalendar separately, as specificEvent already has the info
  // const currentCalendar = mockData.calendars.find(cal => cal.id === calendarId);
  const getUsersByStatus = (status) => {
    console.log("getUsersByStatus called with status:", status);
    let logActions;
    switch (status) {
      case "attending":
        logActions = ["party", "attending", "guest", "going"];
        break;
      case "administrator":
        logActions = ["administrator", "created event"];
        break;
      case "host":
        logActions = ["host"];
        break;
      case "cohost":
        logActions = ["cohost"];
        break;
      case "maybe":
        logActions = ["maybe"];
        break;
      case "blocked":
        logActions = ["blocked"];
        break;
      case "declined":
        logActions = ["declined"];
        break;
      case "invited":
        logActions = ["invited"];
        break;
      case "excluded":
        logActions = ["excluded"];
        break;
      case "interested":
        logActions = ["interested", "join-request"];
        break;
      case "inactive":
        logActions = ["left", "removed"];
        break;
      default:
        logActions = [];
    }
    // Filter activity logs based on group ID and the determined activity statuses
    const specificEventActivityLogs = mockData.activityLogs.filter(
      (log) =>
        log.relatedId === calendarId &&
        log.objectType === "event" &&
        logActions.includes(log.activity)
    );
    // Map the logs to user objects
    const usersByStatus = specificEventActivityLogs
      .map((log) => {
        let userId;
        // For 'interested', 'declined', and 'member', use sourceId
        if (
          status === "administrator" ||
          status === "attending" ||
          status === "excluded" ||
          status === "host" ||
          status === "cohost" ||
          status === "maybe" ||
          status === "interested" || // request to join
          status === "cohost-request" || // request to join
          status === "host-request" || // request to join
          status === "declined"
        ) {
          userId = log.sourceId;
        } else if (
          status === "invited" ||
          status === "inactive" ||
          status === "cohost-invite" ||
          status === "host-invite"
        ) {
          userId = log.targetId;
        }
        // Return the user object if found
        return mockData.users.find((user) => user.id === userId);
      })
      .filter(Boolean);
    // Add console log here to see the status and associated users
    console.log(`Status: ${status}, Users:`, usersByStatus);

    return usersByStatus;
  };

  // fetching the users by status labels
  useEffect(() => {
    setUsers({
      interested: getUsersByStatus("interested"),
      attending: getUsersByStatus("attending"),
      declined: getUsersByStatus("declined"),
      maybe: getUsersByStatus("maybe"),
      invited: getUsersByStatus("invited"),
      excluded: getUsersByStatus("excluded"),
    });
  }, []);

  // specifically invoking the mock data to avoid a block scope error
  // i need the state up top for adding users on the gui for user experience only
  // still need to add the decrement and increment count function for bakend

  // setting count variables will be updating it with increment and decrement
  // this will save on joins and computational counts each time
  const interestedCount = specificEvent.eventTotalInterested;
  const maybeCount = specificEvent.eventTotalInterested;
  const attendingCount = specificEvent.eventTotalAttending;
  const declinedCount = specificEvent.eventTotalDeclined;
  const invitedCount = specificEvent.eventTotalInvited;

  const handleUnInvite = (username) => {
    setActivityLogs((prevLogs) =>
      prevLogs.filter(
        (log) =>
          !(
            log.targetId ===
              mockData.users.find((u) => u.username === username).id &&
            log.relatedId === calendarId &&
            log.objectType === "event" &&
            log.activity === "invited"
          )
      )
    );
  };

  // First, calculate the locationDetails at the top of your component or function

  const toggleEventPrivacy = () => {
    setIsPrivateEvent((prev) => !prev);
  };

  const togglePartyStatus = () => {
    setIsInviteManager((prev) => !prev);
  };

  // }
  //   First, get the button's position and dimensions.
  //   Calculate the button's center position (or wherever you'd like the confetti to appear relative to the button).
  //   Normalize these values to fit the 0 to 1 range of the origin property.
  //   Use these values when triggering the confetti.
  const showConfetti = () => {
    // Dynamically determine the button's position each time showConfetti is called and launch the confetti from the center of the button. As a result, even if the button moves due to scrolling or resizing, the confetti should always originate from the button's position.
    const button = document.querySelector(".action-buttons .button"); // Adjust your selector if necessary

    if (!button) return;

    const rect = button.getBoundingClientRect();
    const x = rect.left + rect.width / 2; // center of the button in x-direction
    const y = rect.top + rect.height / 2; // center of the button in y-direction

    const normalizedX = x / window.innerWidth;
    const normalizedY = y / window.innerHeight;

    confetti({
      particleCount: 150,
      spread: 60,
      origin: {
        x: normalizedX,
        y: normalizedY,
      },
    });
  };

  const handleUserNameChange = (value) => {
    setEventSearchCriteriaValue(value);
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.setItem(userNameKey, value);
    if (userNameEventSearchError) {
      setUserNameEventSearchError("");
    }
    setEventSearchCriteriaValue(value);
    setIsSearchEventInputEmpty(!value);
  };
  const clearUserNameEventSearchCriteriaLocalStorage = () => {
    setEventSearchCriteriaValue(""); // Reset the group search criteria value
    const userNameKey = getStorageKey("userNameValue", currentContext); // Assuming currentContext is defined in your component
    localStorage.removeItem(userNameKey); // Remove the item from local storage
  };
  const filterUsersByStatusAndCriteria = (status, criteria) => {
    const allUsers = getUsersByStatus(status);
    if (!criteria) return allUsers;
    return allUsers.filter((user) =>
      user.username.toLowerCase().includes(criteria.toLowerCase())
    );
  };

  const handleSlideCompletion = () => {
    if (!eventSearchCriteriaValue && !isButtonPressed) {
      setIsSliderSlid(true);
      setIsSearchEventInputEmpty(true);
      setUserNameEventSearchError("Required"); // Set error message when input is empty
    } else {
      // Setting the users based on eventSearchCriteriaValue
      setUsers({
        interested: filterUsersByStatusAndCriteria(
          "interested",
          eventSearchCriteriaValue
        ),
        attending: filterUsersByStatusAndCriteria(
          "attending",
          eventSearchCriteriaValue
        ),
        declined: filterUsersByStatusAndCriteria(
          "declined",
          eventSearchCriteriaValue
        ),
        maybe: filterUsersByStatusAndCriteria(
          "maybe",
          eventSearchCriteriaValue
        ),
        invited: filterUsersByStatusAndCriteria(
          "invited",
          eventSearchCriteriaValue
        ),
        excluded: filterUsersByStatusAndCriteria(
          "excluded",
          eventSearchCriteriaValue
        ),
      });
      setIsSliderSlid(true); // Show the buttons
      setIsSearchEventInputEmpty(false); // Hide the "Required" message
      setEventSearchCriteriaValue(""); // Reset the group search criteria value
      clearUserNameEventSearchCriteriaLocalStorage("");
      setIsSearchEventInputEmpty(false); // Hide the "Required" message
    }
    setIsButtonPressed(false);
  };

  const handleButtonPress = () => {
    setIsButtonPressed(true);
    setIsSliderSlid(false);
  };

  // monitors eventSearchCriteriaValue: This will tell us when the search criteria is reset.
  useEffect(() => {
    // console.log("useEffect - eventSearchCriteriaValue changed to:", eventSearchCriteriaValue); // working as expected
    if (eventSearchCriteriaValue === "") {
      setIsSearchMode(false);
    }
  }, [eventSearchCriteriaValue]);

  const handleExclude = (username, status) => {
    // Remove user from current status list
    const updatedStatusList = users[status].filter(
      (user) => user.username !== username
    );

    // Add user to excluded list
    const updatedExcludedList = [
      ...users.excluded,
      users[status].find((user) => user.username === username),
    ];

    // Update state
    setUsers({
      ...users,
      [status]: updatedStatusList,
      excluded: updatedExcludedList,
    });
  };

  const handleInclude = (username) => {
    // Find the user by username
    const user = mockData.users.find((u) => u.username === username);

    // If the user is found, change the user's status from 'excluded' to 'invited' (or another status as per your needs)
    if (user) {
      const request = mockData.activityLogs.find(
        (log) =>
          log.userId === user.Id &&
          log.relatedId === calendarId &&
          log.objectType === "calendar"
      );

      if (request && request.type === "calendar") {
        request.status = "invited"; // You can change this to any status
      }

      // Then, refresh your lists
      setExcludedUsers(getUsersByStatus("excluded"));
      setInvitedList(getUsersByStatus("invited"));
      // ...and so on for other lists if necessary
    }
  };
  useEffect(() => {
    if (currentList && filterNavContainerRef.current) {
      const element = filterNavContainerRef.current;
      const rect = element.getBoundingClientRect();

      // Calculate the position to scroll to
      const positionToScrollTo = window.scrollY + rect.top;

      // Scroll to that position
      window.scrollTo({
        top: positionToScrollTo,
        behavior: "smooth",
      });
    }
  }, [currentList, filterNavContainerRef]);

  const show_RedBorder_Required = !isAnyButtonActive() && isSliderSlid;

  const debouncedHandleSlideCompletion = debounce(handleSlideCompletion, 300);
  const capitalizedEventTitle = capitalizeFirstLetter(specificEvent.eventTitle);
  console.log("Specific Event:", specificEvent);
  const handleDecline = async (status, userId) => {
    try {
      // Construct the request payload
      const payload = {
        userId: userId,
        groupId: calendarId, // Assuming you have groupId in your component's state
        status: status,
      };

      // Placeholder for making an API call to the backend
      // Replace this with your actual API call
      // const response = await yourApi.removeUserFromStatus(payload);

      // Assuming the response contains the updated list of users
      // const updatedUsers = response.data;

      // Update your local state based on the response
      // This is a placeholder logic, adjust according to your state structure
      setUsers((prevUsers) => ({
        ...prevUsers,
        [status]: prevUsers[status].filter((user) => user.id !== userId),
      }));

      // Optionally, you can show a success message or handle the response further
    } catch (error) {
      // Handle any errors here, such as showing error messages
      console.error("Error removing user:", error);
    }
  };
  const handleApprove = (userId) => {
    // Example logic to update the state
    setUsers((prevUsers) => {
      const newInterested = prevUsers.interested.filter(
        (user) => user.id !== userId
      );
      const approvedUser = prevUsers.interested.find(
        (user) => user.id === userId
      );
      const newMembers = [...prevUsers.members, approvedUser];
      return {
        ...prevUsers,
        interested: newInterested,
        members: newMembers,
      };
    });

    // Placeholder for future backend integration
    // fetch('/api/approveUser', { method: 'POST', body: JSON.stringify({ userId }) })
    //   .then(response => response.json())
    //   .then(data => /* handle the response */);
  };

  return (
    <>
      <div id="EventDetailspage-container">
        <div className="events-cover-picture">
          {specificEvent.imagePath && (
            <img
              src={specificEvent.imagePath}
              alt={capitalizedEventTitle.eventTitle || "Event Image"}
            />
          )}
          <div className="current-time-overlay">
            {/* toDo save bolean in the database*/}
            <CurrentTimeDay />
          </div>
        </div>
        <div className="event-details">
          {specificEvent.eventStartDate && specificEvent.eventEndDate && (
            <>
              <h2 className="compactSpacing date-time">
                {FormatDateAndTimeDisplay(specificEvent)}
              </h2>
              {specificEvent.eventStartDate && specificEvent.eventEndDate && (
                <p className="duration">
                  Duration:{" "}
                  {timeCalculator(
                    specificEvent.eventStartDate,
                    specificEvent.eventEndDate
                  )}
                  hours
                </p>
              )}
            </>
          )}
          {specificEvent.eventTitle && (
            <h2 className="compactSpacing eventsDetailsTitle event-title">
              {capitalizedEventTitle}
            </h2>
          )}
          <p>{specificEvent.eventDescription}</p>
          <h3 className="compact-Spacing hosts">
            {/* Displaying Admin */}
            Event By:
            {getUsersByStatus("administrator").map((admin, index) => (
              <Connections
                key={index}
                userId={admin.id}
                username={admin.username}
                firstName={admin.firstName}
                lastName={admin.lastName}
                profileThumbnail={admin.imagePath}
                showChatSlider={true}
                showUsernameOffSlider={false}
              />
            ))}
            {/* Displaying Moderators */}
            {getUsersByStatus("host").length > 0 && <span>host(s): </span>}
            {getUsersByStatus("host").map((host, index) => (
              <Connections
                key={index}
                userId={host.id}
                username={host.username}
                firstName={host.firstName}
                lastName={host.lastName}
                profileThumbnail={host.imagePath}
                showChatSlider={true}
                showUsernameOffSlider={false}
              />
            ))}
            {getUsersByStatus("cohost").length > 0 && <span>cohost(s): </span>}
            {getUsersByStatus("cohost").map((cohost, index) => (
              <Connections
                key={index}
                userId={cohost.id}
                username={cohost.username}
                firstName={cohost.firstName}
                lastName={cohost.lastName}
                profileThumbnail={cohost.imagePath}
                showChatSlider={true}
                showUsernameOffSlider={false}
              />
            ))}
          </h3>
          <div className="EventLocation">
            {specificEvent.eventPlace && (
              <h2 className="compactSpacing">
                Place: {specificEvent.eventPlace}
              </h2>
            )}
          </div>
          <div className="address">
            {specificEvent.eventStreet && (
              <p className="compactSpacing">{specificEvent.eventStreet}</p>
            )}
            {specificEvent.eventCity && (
              <p className="compactSpacing">{specificEvent.eventCity},</p>
            )}
            {specificEvent.eventState && (
              <p className="compactSpacing">{specificEvent.eventState},</p>
            )}
            {specificEvent.eventZipCode && (
              <p className="compactSpacing">{specificEvent.eventZipCode}</p>
            )}
            <p className="compactSpacing">:United States</p>
            {specificEvent.isRemote && (
              <div className="only-remote">·Virtual</div>
            )}
          </div>
        </div>
        <EventDetailsPadMobileNav
          onGoing={handleGoing}
          onInterested={handleInterested}
          onInvite={handleInvite}
          onShowGuestStatus={handleShowGuestStatus}
          userAction={userAction}
          setUserAction={setUserAction}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          userNameEventSearchError={userNameEventSearchError}
          setUserNameEventSearchError={setUserNameEventSearchError}
          onHandleSlideEnd={handleSlideEnd}
          onTogglePopup={togglePopup}
          guestAdminSectionRef={guestAdminSectionRef}
          onHandleCancel={handleCancel}
          onResetFilters={resetFilters}
          eventSearchCriteriaValue={eventSearchCriteriaValue}
          handleUserNameChange={handleUserNameChange}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          setEventSearchCriteriaValue={setEventSearchCriteriaValue}
          setIsSearchEventInputEmpty={setIsSearchEventInputEmpty}
          isAnyButtonActive={isAnyButtonActive}
          isSliderSlid={isSliderSlid}
          showPartyStatus={showPartyStatus}
          onToggleParty={toggleParty}
          showAdminControls={showAdminControls}
          interestedRef={interestedRef}
          currentList={currentList}
          attendingRef={attendingRef}
          attendingCount={attendingCount}
          interestedCount={interestedCount}
          invitedRef={invitedRef}
          declinedRef={declinedRef}
          invitedCount={invitedCount}
          declinedCount={declinedCount}
          maybeRef={maybeRef}
          maybeCount={maybeCount}
          excludedRef={excludedRef}
          excludedCount={excludedCount}
          show_RedBorder_Required={show_RedBorder_Required}
          onHandleCurrentStatusList={handleCurrentStatusList}
          onHandleShowAdminControls={handleShowAdminControls}
          onToggleAdminControls={toggleAdminControls}
          onDebouncedHandleSlideCompletion={debouncedHandleSlideCompletion}
        />
        {/* Displaying Admin Info add update abilities */}
        {/* toDo who invited here, get it from notifications table with the profile pic */}
        <div className="action-buttons">
          {activeTab === "Going" ? (
            <GlassBtn
              glassBtnImage={
                <XIcon fillColor="#964b00" height="25px" width="25px" />
              }
              onClick={() => setActiveTab(null)}
              glassBtnText="Cancel Going"
              glassBtnFontColor="#964b00"
              glassBtnHeight="27px"
              glassBtnTextDecorationColor="#964b00"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
          ) : (
            <GlassBtn
              glassBtnImage={
                <ThumbIndexOut fillColor="#fdfd96" height="25px" width="25px" />
              }
              onClick={handleGoing}
              glassBtnText="Going"
              glassBtnFontColor="#fdfd96"
              glassBtnHeight="27px"
              glassBtnTextDecorationColor="#fdfd96"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
          )}
          {activeTab === "Interested" ? (
            <GlassBtn
              glassBtnImage={
                <XIcon fillColor="#964b00" height="25px" width="25px" />
              }
              onClick={() => setActiveTab(null)}
              glassBtnText="UnInterested"
              glassBtnFontColor="#964b00"
              glassBtnHeight="27px"
              glassBtnTextDecorationColor="#964b00"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
          ) : (
            <GlassBtn
              glassBtnImage={
                <ClipBoardCheck
                  fillColor="#e2b645"
                  height="25px"
                  width="25px"
                />
              }
              onClick={handleInterested}
              glassBtnText="Interested"
              glassBtnFontColor="#e2b645"
              glassBtnHeight="27px"
              glassBtnTextDecorationColor="#e2b645"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
          )}
          <GlassBtn
            glassBtnImage={
              <Xylophone
                height="25px"
                width="25px"
                fillColor={userAction === "invite" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            onClick={handleInvite}
            glassBtnText="Invite"
            glassBtnFontColor={userAction === "invite" ? "#fff" : "#fdfd96"}
            glassBtnHeight="27px"
            glassBtnTextDecorationColor={
              userAction === "invite" ? "#fff" : "#fdfd96"
            }
            glassBtnTextFontSize="1.3rem"
            glassBtnFontWeight="800"
          />
          <div className="second-row-buttons">
            <GlassBtn
              glassBtnImage={
                <ScrollList
                  height="25px"
                  width="25px"
                  fillColor={activeTab === "guestStatus" ? "#000" : "#e2b645"}
                />
              }
              onClick={handleShowGuestStatus}
              glassBtnText="Party"
              glassBtnFontColor={
                activeTab === "guestStatus" ? "#000" : "#e2b645"
              }
              glassBtnHeight="27px"
              glassBtnTextDecorationColor={
                activeTab === "guestStatus" ? "#000" : "#e2b645"
              }
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
            {/* Conditional rendering for admin and co-hosts */}
            {/* {(isCoHost || isAdmin) && ( */}
            <GlassBtn
              glassBtnImage={
                <Remote
                  height="25px"
                  width="25px"
                  fillColor={activeTab === "adminControls" ? "#000" : "#fdfd96"}
                />
              }
              onClick={handleShowAdminControls}
              glassBtnText="Admin"
              glassBtnFontColor={
                activeTab === "adminControls" ? "#000" : "#fdfd96"
              }
              glassBtnHeight="27px"
              glassBtnTextDecorationColor={
                activeTab === "adminControls" ? "#000" : "#fdfd96"
              }
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="800"
            />
          </div>
        </div>
        {!activeTab && (
          <UpdateEventSlider
            className="update-event-slider" /* Add this class */
            onHandleSlideEnd={handleSlideEnd}
            eventFormOpen={eventFormOpen}
            togglePopup={togglePopup}
            currentForm={currentForm}
            setContextType={setContextType}
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            eventPopup={
              <EventPopup
                onClose={() => setEventFormOpen(false)}
                eventFormOpen={eventFormOpen}
                updateEvent={updateEvent}
                setCurrentForm={setCurrentForm}
                contextType={contextType} // Passing contextType as a prop
              />
            }
            currentEventData={specificEvent}
          />
        )}
        {activeTab !== "adminControls" && activeTab !== "guestStatus" && (
          <>
            <SwitchSlider
              leftLabel={labels.left}
              rightLabel={labels.right}
              handleChangeSlider={toggleEventPrivacy}
              checked={isPrivateEvent}
              className="mrgn-top"
            />
            <div className="flex-column">
              <ToggleSlider
                defaultPosition={isInviteManager ? "right" : "left"}
                handleChangeSlider={togglePartyStatus}
                activeContent="Yes"
                inactiveContent="No"
                checked={isInviteManager}
                className="events-details-slider-wrapper"
                showGuestInviteText={true}
                sliderWrapperStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "33px",
                }}
                sliderTextStyle={{
                  textAlign: "center", // Center the text
                }}
              />
            </div>
          </>
        )}

        {(activeTab === "guestStatus" ||
          activeTab === "party" ||
          activeTab === "adminControls") && (
          <div
            className="filterNavContainer stickyTop"
            ref={filterNavContainerRef}
          >
            <div className="backBtnEventsPg" ref={guestAdminSectionRef}>
              <SolidColorButton
                solidColorBtnImage={
                  <Back
                    height="15px"
                    width="15px"
                    fillColor="#964b00"
                    strokeWidth="4"
                    strokeColor="#964b00"
                  />
                }
                className="gold-btn"
                solidColorBtnText="Back"
                solidColorBtnFontWeight="800"
                solidColorBtnFontFamily="helvetica"
                solidColorBtnTextFontSize="1.3rem"
                solidColorBtnFontColor="#964b00"
                solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                onClick={() => navigate(-1)}
              />
              <SolidColorButton
                solidColorBtnImage={
                  <XIcon
                    height="15px"
                    width="15px"
                    fillColor="#964b00"
                    strokeWidth="4"
                    strokeColor="#964b00"
                  />
                }
                onClick={handleCancel}
                solidColorBtnText="Cancel/Close"
                className="gold-btn"
                solidColorBtnFontWeight="800"
                solidColorBtnFontFamily="helvetica"
                solidColorBtnTextFontSize="1.3rem"
                solidColorBtnFontColor="#964b00"
                solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              />
              <SolidColorButton
                className="gold-btn"
                solidColorBtnImage={
                  <Filter height="25px" width="25px" fillColor="#964b00" />
                }
                onClick={resetFilters}
                solidColorBtnText="Reset Filters"
                solidColorBtnFontWeight="800"
                solidColorBtnFontFamily="helvetica"
                solidColorBtnTextFontSize="1.3rem"
                solidColorBtnFontColor="#964b00"
                solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              />
            </div>
            <section id="event-search-bar-wrapper">
              <div className="connections-input-slider-container">
                <div className="connections-input-container blinker-wrapper">
                  <input
                    value={eventSearchCriteriaValue}
                    placeholder={
                      userNameEventSearchError
                        ? userNameEventSearchError
                        : "Enter username"
                    }
                    className={
                      userNameEventSearchError ? "required-message" : ""
                    }
                    type="text"
                    onChange={(e) =>
                      handleUserNameChange(e.target.value, "userNameValue")
                    }
                    onFocus={() => {
                      setIsFocused(true);
                      setEventSearchCriteriaValue("");
                      setIsSearchEventInputEmpty(false);
                    }}
                  />
                  {eventSearchCriteriaValue && (
                    <button
                      className="Clear-eventDetailsPgBtn"
                      onClick={clearUserNameEventSearchCriteriaLocalStorage}
                      style={{
                        color: "#fdfd96",
                        backgroundColor: "transparent",
                        borderColor: "#fdfd96",
                      }}
                    >
                      Clear
                    </button>
                  )}
                  {!isFocused && eventSearchCriteriaValue === "" && (
                    <span className="blinking-cursor blinking">|</span>
                  )}
                </div>
                <SlideBtn
                  sliderImage={() => <FindUsername fillColor="#964B00" />}
                  slideBtnText="Slide to find username"
                  slideBtnWidth="300px"
                  marginBottom="2px"
                  onHandleSlideEnd={debouncedHandleSlideCompletion}
                />
              </div>
            </section>
            <div
              className={`party-list ${
                show_RedBorder_Required && !isAnyButtonActive() && isSliderSlid
                  ? "red-border"
                  : ""
              }`}
            >
              {show_RedBorder_Required && (
                <span className="error-message">
                  {userNameEventSearchError}
                </span>
              )}
              <div className="guest-list">
                <div className="show-hide-control-buttons">
                  {(activeTab === "adminControls" ||
                    activeTab === "guestStatus") && (
                    <>
                      <SolidColorButton
                        solidColorBtnImage={
                          showPartyStatus ? (
                            <EyeSlashIcon
                              height="15px"
                              width="15px"
                              fillColor="#964b00"
                              strokeWidth="4"
                              strokeColor="#964b00"
                            />
                          ) : (
                            <EyeIcon
                              height="15px"
                              width="15px"
                              fillColor="#964b00"
                              strokeWidth="4"
                              strokeColor="#964b00"
                            />
                          )
                        }
                        className="gold-btn"
                        solidColorBtnText={
                          showPartyStatus ? "Hide Party" : "Show Party"
                        } // Text based on showPartyStatus state
                        solidColorBtnFontWeight="900"
                        solidColorBtnFontFamily="helvetica"
                        solidColorBtnTextFontSize=".75rem"
                        solidColorBtnFontColor="#964b00"
                        solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                        onClick={toggleParty}
                      />
                      {activeTab === "adminControls" && (
                        <SolidColorButton
                          solidColorBtnImage={
                            showAdminControls ? (
                              <EyeSlashIcon
                                height="15px"
                                width="15px"
                                fillColor="#964b00"
                                strokeWidth="4"
                                strokeColor="#964b00"
                              />
                            ) : (
                              <EyeIcon
                                height="15px"
                                width="15px"
                                fillColor="#964b00"
                                strokeWidth="4"
                                strokeColor="#964b00"
                              />
                            )
                          }
                          className="gold-btn"
                          solidColorBtnText={
                            showAdminControls
                              ? "Hide Admin Controls"
                              : "Show Admin Controls"
                          }
                          solidColorBtnFontWeight="900"
                          solidColorBtnFontFamily="helvetica"
                          solidColorBtnTextFontSize=".75rem"
                          solidColorBtnFontColor="#964b00"
                          solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                          onClick={toggleAdminControls}
                        />
                      )}
                    </>
                  )}
                </div>
                {/* <h3 className="partyListHeader">Guests: show guest show admin controls</h3> */}
                <div className="actionBtnEventsDetailsDsktp">
                  {showPartyStatus && (
                    <>
                      <GlassBtn
                        onClick={() => handleCurrentStatusList("interested")}
                        glassBtnImage={
                          <span
                            ref={interestedRef}
                            className={`interestedNum ${
                              currentList === "interested"
                                ? "interestedNum-active"
                                : "interestedNum-inactive"
                            }`}
                          >
                            {interestedCount}
                          </span>
                        }
                        glassBtnText={
                          currentList === "interested"
                            ? "Interested List"
                            : "Show Interested List"
                        }
                        glassBtnFontColor={
                          currentList === "interested" ? "#fff" : "#e2b645"
                        }
                        glassBtnTextDecorationColor={
                          currentList === "interested" ? "#fff" : "#e2b645"
                        }
                        imageOnTop={true}
                        glassBtnTextFontSize="1rem"
                        glassBtnFontWeight="800"
                        glassBtnHeight="27px"
                        glassBtnWidth="fit-content"
                        glassHoverStyle={{
                          height: "10px",
                        }}
                      />
                      <GlassBtn
                        onClick={() => handleCurrentStatusList("attending")}
                        glassBtnImage={
                          <span
                            ref={attendingRef}
                            className={`attendingNum ${
                              currentList === "attending"
                                ? "attendingNum-active"
                                : "attendingNum-inactive"
                            }`}
                          >
                            {attendingCount}
                          </span>
                        }
                        glassBtnText={
                          currentList === "attending"
                            ? "Attending List"
                            : "Show Attending List"
                        }
                        glassBtnFontColor={
                          currentList === "attending" ? "#fff" : "#fdfd96"
                        }
                        glassBtnTextDecorationColor={
                          currentList === "attending" ? "#fff" : "#fdfd96"
                        }
                        imageOnTop={true}
                        glassBtnTextFontSize="1rem"
                        glassBtnFontWeight="800"
                        glassBtnHeight="27px"
                        glassBtnWidth="fit-content"
                        glassHoverStyle={{
                          height: "10px",
                        }}
                      />
                    </>
                  )}
                </div>
                {activeTab === "adminControls" && (
                  <div className="admin-ctrls">
                    <div className="lrgScreeBtnDetailsPgActions">
                      {showAdminControls && (
                        <>
                          <ImgTopBtn
                            onClick={() => handleCurrentStatusList("invited")}
                            image={
                              <span
                                ref={invitedRef}
                                className={`invitedNum ${
                                  currentList === "invited"
                                    ? "invitedNum-active"
                                    : "invitedNum-inactive"
                                }`}
                              >
                                {invitedCount}
                              </span>
                            }
                            imageOnTop={true}
                            imgTopBtnText={
                              currentList === "invited"
                                ? "Invited List"
                                : "Show Invited List"
                            }
                            imgTopBtnFontColor={
                              currentList === "invited" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextDecorationColor={
                              currentList === "invited" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextFontSize="1rem"
                            imgTopBtnFontWeight="800"
                            imgTopBtnHeight="fit-content"
                            imgTopBtnWidth="fit-content"
                            paddingLeft="10px"
                            paddingRight="10px"
                          />
                          <ImgTopBtn
                            onClick={() => handleCurrentStatusList("declined")}
                            image={
                              <span
                                ref={declinedRef}
                                className={`declinedNum ${
                                  currentList === "declined"
                                    ? "declinedNum-active"
                                    : "declinedNum-inactive"
                                }`}
                              >
                                {declinedCount}
                              </span>
                            }
                            imageOnTop={true}
                            imgTopBtnText={
                              currentList === "declined"
                                ? "Declined List"
                                : "Show Declined List"
                            }
                            imgTopBtnFontColor={
                              currentList === "declined" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextDecorationColor={
                              currentList === "declined" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextFontSize="1rem"
                            imgTopBtnFontWeight="800"
                            imgTopBtnHeight="fit-content"
                            imgTopBtnWidth="fit-content"
                            paddingLeft="10px"
                            paddingRight="10px"
                          />
                          <ImgTopBtn
                            onClick={() => handleCurrentStatusList("maybe")}
                            image={
                              <span
                                ref={maybeRef}
                                className={`maybeNum ${
                                  currentList === "maybe"
                                    ? "maybeNum-active"
                                    : "maybeNum-inactive"
                                }`}
                              >
                                {maybeCount}
                              </span>
                            }
                            imageOnTop={true}
                            imgTopBtnText={
                              currentList === "maybe"
                                ? "Maybe List"
                                : "Show Maybe List"
                            }
                            imgTopBtnFontColor={
                              currentList === "maybe" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextDecorationColor={
                              currentList === "maybe" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextFontSize="1rem"
                            imgTopBtnFontWeight="800"
                            imgTopBtnHeight="fit-content"
                            imgTopBtnWidth="fit-content"
                            paddingLeft="10px"
                            paddingRight="10px"
                          />
                          <ImgTopBtn
                            onClick={() => handleCurrentStatusList("excluded")}
                            image={
                              <span
                                ref={excludedRef}
                                className={`excludedNum ${
                                  currentList === "excluded"
                                    ? "excludedNum-active"
                                    : "excludedNum-inactive"
                                }`}
                              >
                                {excludedCount}
                              </span>
                            }
                            imageOnTop={true}
                            imgTopBtnText={
                              currentList === "excluded"
                                ? "Excluded List"
                                : "Show Excluded List"
                            }
                            imgTopBtnFontColor={
                              currentList === "excluded" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextDecorationColor={
                              currentList === "excluded" ? "#000" : "#964b00"
                            }
                            imgTopBtnTextFontSize="1rem"
                            imgTopBtnFontWeight="800"
                            imgTopBtnHeight="fit-content"
                            imgTopBtnWidth="fit-content"
                            paddingLeft="10px"
                            paddingRight="10px"
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {(activeTab === "adminControls" || activeTab === "guestStatus") && (
          <>
            <div>
              {currentList === "interested" && (
                <StatusList
                  status="interested"
                  userList={users.interested}
                  handleApprove={handleApprove}
                  handleDecline={handleDecline}
                />
              )}
              {currentList === "attending" && (
                <>
                  <StatusList
                    status="attending"
                    userList={users.attending}
                    handleExclude={handleExclude}
                    handleUnInvite={handleUnInvite}
                  />
                </>
              )}
            </div>
          </>
        )}
        {activeTab === "adminControls" && (
          <div>
            {currentList === "invited" && (
              <StatusList
                status="invited"
                userList={users.invited}
                handleExclude={handleExclude}
                handleUnInvite={handleUnInvite}
              />
            )}
            {currentList === "maybe" && (
              <StatusList
                status="maybe"
                userList={users.maybe}
                handleExclude={handleExclude}
                handleUnInvite={handleUnInvite}
              />
            )}
            {currentList === "declined" && (
              <StatusList
                status="declined"
                userList={users.declined}
                handleExclude={handleExclude}
                handleUnInvite={handleUnInvite}
              />
            )}
            {currentList === "excluded" && (
              <StatusList
                status="excluded"
                userList={users.excluded}
                handleInclude={handleInclude}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default EventDetailsPage;
//  This is the end of your conditional rendering
// This is the end of the return for the map callback

// notes for popup
// The parent page manages the state and visibility of the form.
// The slider button merely acts as a trigger for the form to appear.
// The form simply presents itself when it's required and hides when it's not.
// Parent Page:
// Maintain a state to handle the form visibility (isFormOpen or something similar). example isPopup
// Implement a method (toggleForm or similar) to toggle the visibility state.
// Pass this method as a callback to the slider button. example onsliderHandleEnd (when the slide is complete event listener) that is on this parent page at the top and programmed then passed to the slider button below to pass the call back to the slider
// Use the visibility state to conditionally render the form.
// Slider Button:
// Invoke the callback (received from the parent) when the slider button reaches its end or when the desired action occurs.(when the slide is complete event listener)
// Form:
// Render based on the state provided by the parent. Example the toggleForm method to close the form upon successful submission or when the user wishes to cancel.

// create event and update event slider) acts as a mediator between the parent page and the form. It simplifies the interaction by abstracting the details of the form handling and provides a central place to control both the triggering mechanism (slider button) and the form itself.

// const handleInvite = () => {
//   setUserAction((userAction) => (userAction === "invite" ? null : "invite"));
// };
// If the condition is true (which means userAction is currently "invite"), the ternary operator returns null. This effectively sets userAction to null, making it inactive or false in a logical sense.
// If the condition is false (which means userAction is not "invite"), the ternary operator returns "invite". This sets userAction to "invite", activating it or making it true in a logical sense.
