import React, { useState, useEffect, useRef } from "react";
import RadioHeadBtn from "./radioHead";
// import selectedDropDown from "../../selected/selected";
import DatePicker from "../../datePicker/datePicker";
import StateDropdown from "../../stateDropdown/stateDropDown";
import { UpArrow, DownArrow } from "../../icons";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import "./payments.scss"; // or .css if it's a CSS file

function PaymentsForm({
  onTierSelected,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  showPaymentDetails,
  onPaymentMethodSelected,
  billingDetails,
  handleFieldChange, // this handler will be used to see if user field out the paymet requirements so it is passed in here to talk to the payments method page component and used below for all the fields but it is wrapped for the drop downs since they have their own internal handlers as components
  selectedTier,
  setSelectedTier,
  setShowPaymentDropDown,
  showPaymentDropDown,
  hasAttemptedToOrder,
  tierError,
  setTierError,
  tierSectionRef,
  paymentDetailsRef,
}) {
  // date picker call back prop setting state for error handler here so both handlers can work together
  const [selectedDate, setSelectedDate] = useState("");
  const currentContext = "payment";
  const [selectedState, setSelectedState] = useState(""); // State for the chosen state
  const [isDropdownOpen, setDropdownOpen] = useState(false); // The state drop down has props here so state will be set here for error handling purposes
  const [creditCardExpirationDate, setCreditCardExpirationDate] = useState(
    localStorage.getItem(
      getStorageKey("CreditCardExpirationDate", currentContext)
    ) || ""
  );

  const [focusedIndex, setFocusedIndex] = useState(null); // used for payments method instead of a seperate component will build it here to lift up state for the sibling that handles calculations and data validation is needed

  const handleSelectedPaymentMethod = (method) => {
    setSelectedPaymentMethod(method); // This sets the payment method name
    let merchantFee = handlePaymentMethodChange(method); // This gets the merchant fee
    onPaymentMethodSelected({ name: method, fee: merchantFee }); // Notifies the parent
    handleFieldChange("selectedPaymentMethod", method); // error handling passing method since billing is expecting a string value in the parent instead of method.name
    setShowPaymentDropDown(false); // Close the dropdown
  };

  const handlePaymentMethodChange = (method) => {
    let merchantFee = 0;
    switch (method) {
      case "visa":
        merchantFee = 0.03;
        break;
      case "mastercard":
        merchantFee = 0.06;
        break;
      case "americanExpress":
        merchantFee = 0.07;
        break;
      case "discover":
        merchantFee = 0.04;
        break;
      default:
        break;
    }
    return merchantFee;
  };

  const calculateTotalDue = (tier) => {
    switch (tier) {
      case "tier1":
        return 9.99;
      case "tier2":
        return 19.99;
      case "tier3":
        return 29.99;
      default:
        return 0;
    }
  };
  const handleTierSelection = (tier) => {
    setSelectedTier(tier);
    onTierSelected(calculateTotalDue(tier));
    setTierError(false);
  };

  console.log("selectedPaymentMethod:", selectedPaymentMethod);
  const handleDropdownKeyDown = (event) => {
    const methodsLength = ["visa", "mastercard", "americanExpress", "discover"]
      .length;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex === null ? 0 : (prevIndex + 1) % methodsLength
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex === null ? 0 : (prevIndex - 1 + methodsLength) % methodsLength
      );
    } else if (event.key === "Escape") {
      setShowPaymentDropDown(false);
    }
  };

  useEffect(() => {
    if (selectedState) {
      handleFieldChange("state", selectedState);
    }
  }, [selectedState]);

  const handleDateChangeFromPicker = (dateValue) => {
    // Convert to a standard format, e.g., ISO string, if dateValue is a Date object
    const formattedDate =
      dateValue instanceof Date ? dateValue.toISOString() : dateValue;
    setCreditCardExpirationDate(formattedDate); // Update the credit card expiration date value
    setSelectedDate(formattedDate); // Update state on the date picker call back by notifying the parent
    handleFieldChange("expiryDate", formattedDate); // Update the billingDetails
    // // Additional logic for validation or error handling
  };

  useEffect(() => {
    if (selectedDate) {
      handleFieldChange("expiryDate", selectedDate);
    }
  }, [selectedDate]);

  const clearState = () => {
    // Define the key and hard coded currentContext to target payments
    const state = getStorageKey("state", "payments");
    // Check if the key exists in localStorage and get the item
    if (localStorage.getItem(state) !== null) {
      localStorage.removeItem(state); // Remove the item
    } else {
      console.log("Key not found in localStorage:", state);
    }
    setSelectedState(""); // Clear the state
    setDropdownOpen(false);
  };

  return (
    <div className="payment-form">
      <form id="payments-form">
        <h2>Select a Pricing Tier</h2>
        <div ref={tierSectionRef} className={tierError ? "error-border" : ""}>
          <RadioHeadBtn
            label="Tier 1 - $9.99/month"
            name="pricingTier"
            value="tier1"
            checked={selectedTier === "tier1"}
            onChange={() => handleTierSelection("tier1")}
          />
          {tierError && <span className="required-label">Required</span>}
        </div>
        <div className={tierError ? "error-border" : ""}>
          <RadioHeadBtn
            label="Tier 2 - $19.99/month"
            name="pricingTier"
            value="tier2"
            checked={selectedTier === "tier2"}
            onChange={() => handleTierSelection("tier2")}
          />
          {tierError && <span className="required-label">Required</span>}
        </div>
        <div className={tierError ? "error-border" : ""}>
          <RadioHeadBtn
            label="Tier 3 - $29.99/month"
            name="pricingTier"
            value="tier3"
            checked={selectedTier === "tier3"}
            onChange={() => handleTierSelection("tier3")}
          />
          {tierError && <span className="required-label">Required</span>}
        </div>
        {selectedPaymentMethod && selectedPaymentMethod.name && (
          <div className="payment-label-container">
            {selectedPaymentMethod.name.charAt(0).toUpperCase() +
              selectedPaymentMethod.name.slice(1)}
          </div>
        )}
        <h2>Payment Method Details</h2>
        {/* Error for payment method */}
        {!selectedPaymentMethod && hasAttemptedToOrder && (
          <span className="error-text">Payment method required</span>
        )}
        <div
          className="paymentPlacholder"
          onClick={() => setShowPaymentDropDown(!showPaymentDropDown)}
        >
          {selectedPaymentMethod
            ? selectedPaymentMethod.charAt(0).toUpperCase() +
              selectedPaymentMethod.slice(1)
            : "Select Payment Method"}
          {showPaymentDropDown ? (
            <UpArrow fillColor="#964B00" />
          ) : (
            <DownArrow fillColor="#964B00" />
          )}
        </div>
        {showPaymentDropDown && (
          <div className="dropdown-content" onKeyDown={handleDropdownKeyDown}>
            {["visa", "mastercard", "americanExpress", "discover"].map(
              (methodName, index) => (
                <p
                  key={index}
                  onClick={() => handleSelectedPaymentMethod(methodName)}
                  tabIndex={0}
                  ref={(el) => {
                    if (focusedIndex === index && el) el.focus();
                  }}
                >
                  {methodName === "americanExpress"
                    ? "American Express"
                    : methodName.charAt(0).toUpperCase() + methodName.slice(1)}
                </p>
              )
            )}
          </div>
        )}
        {showPaymentDetails ? (
          <>
            {!billingDetails.isExpiryDateFocused &&
              !billingDetails.expiryDate &&
              hasAttemptedToOrder && (
                <span className="error-text">Required</span>
              )}
            <div className="input-wrapper blinker-wrapper">
              <DatePicker
                showStartDate={false}
                showEndDate={false}
                showAdditionalDate={true}
                placeholder="Enter Expiration Date (MM/YY)"
                selectedDate={billingDetails.expiryDate}
                currentContext={currentContext}
                onAdditionalDateChange={handleDateChangeFromPicker}
                dateInputClassName="billing-expiry-input"
                onFocus={() => handleFieldChange("isExpiryDateFocused", true)}
                onBlur={() => handleFieldChange("isExpiryDateFocused", false)}
                className={`billing-expiry-date-picker ${
                  billingDetails.isExpiryDateFocused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isExpiryDateFocused &&
                !billingDetails.expiryDate && (
                  <span className="blinking-cursor">|</span>
                )}
            </div>
            {!billingDetails.isCreditCardFocused &&
              billingDetails.creditCard === "" &&
              hasAttemptedToOrder && (
                <span className="error-text">Required</span>
              )}
            <div className="input-wrapper blinker-wrapper">
              <input
                placeholder="Enter Credit Card Number"
                type="text"
                maxLength="16"
                value={billingDetails.creditCard}
                onChange={(e) =>
                  handleFieldChange("creditCard", e.target.value)
                }
                onFocus={() => handleFieldChange("isCreditCardFocused", true)}
                onBlur={() => handleFieldChange("isCreditCardFocused", false)}
                className={`billing-credit-card-input ${
                  billingDetails.isCreditCardFocused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isCreditCardFocused &&
                billingDetails.creditCard === "" && (
                  <span className="blinking-cursor">|</span>
                )}
            </div>
            <h2>Name</h2>

            <div ref={paymentDetailsRef}  className="billingNameWrapper">
              <div className="input-wrapper blinker-wrapper">
                {!billingDetails.isFirstNameFocused &&
                  billingDetails.firstName === "" &&
                  hasAttemptedToOrder && (
                    <span className="error-text">Required</span>
                  )}
                <input
                  value={billingDetails.firstName}
                  onChange={(e) =>
                    handleFieldChange("firstName", e.target.value)
                  }
                  onFocus={() => handleFieldChange("isFirstNameFocused", true)}
                  onBlur={() => handleFieldChange("isFirstNameFocused", false)}
                  placeholder="Enter First Name as on card"
                  className={`billing-first-name-input ${
                    billingDetails.isFirstNameFocused ? "focused-input" : ""
                  }`}
                />
                {!billingDetails.isFirstNameFocused &&
                  billingDetails.firstName === "" && (
                    <span className="blinking-cursor">|</span>
                  )}
              </div>

              <div className="input-wrapper blinker-wrapper">
                {!billingDetails.isLastNameFocused &&
                  billingDetails.lastName === "" &&
                  hasAttemptedToOrder && (
                    <span className="error-text">Required</span>
                  )}
                <input
                  value={billingDetails.lastName}
                  onChange={(e) =>
                    handleFieldChange("lastName", e.target.value)
                  }
                  onFocus={() => handleFieldChange("isLastNameFocused", true)}
                  onBlur={() => handleFieldChange("isLastNameFocused", false)}
                  placeholder="Enter Last Name as on card"
                  className={`billing-last-name-input ${
                    billingDetails.isLastNameFocused ? "focused-input" : ""
                  }`}
                />
                {!billingDetails.isLastNameFocused &&
                  billingDetails.lastName === "" && (
                    <span className="blinking-cursor">|</span>
                  )}
              </div>
            </div>
            <h2>
              Billing Address
              <span className="billingAddDescription">
                (registered to the payment method used)
              </span>
            </h2>
            {!billingDetails.isAddress1Focused &&
              billingDetails.address1 === "" &&
              hasAttemptedToOrder && (
                <span className="error-text">Required</span>
              )}
            <div className="input-wrapper blinker-wrapper">
              <input
                value={billingDetails.address1}
                onChange={(e) => handleFieldChange("address1", e.target.value)}
                onFocus={() => handleFieldChange("isAddress1Focused", true)}
                onBlur={() => handleFieldChange("isAddress1Focused", false)}
                placeholder="Adress Line 1 (ex. Street and house number)"
                className={`billing-address-line1-input ${
                  billingDetails.isAddress1Focused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isAddress1Focused &&
                billingDetails.address1 === "" && (
                  <span className="blinking-cursor">|</span>
                )}
            </div>
            <div className="input-wrapper blinker-wrapper">
              <input
                value={billingDetails.address2}
                onChange={(e) => handleFieldChange("address2", e.target.value)}
                onFocus={() => handleFieldChange("isAddress2Focused", true)}
                onBlur={() => handleFieldChange("isAddress2Focused", false)}
                placeholder="Address Line 2 ex. Apartment, suite, unit, building, or floor numbers"
                className={`billing-address-line2-input ${
                  billingDetails.isAddress2Focused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isAddress2Focused &&
                billingDetails.address2 === "" && (
                  <span className="blinking-cursor">|</span>
                )}
            </div>
            {!billingDetails.isCityFocused &&
              billingDetails.city === "" &&
              hasAttemptedToOrder && (
                <span className="error-text">Required</span>
              )}
            <div className="input-wrapper blinker-wrapper">
              <input
                value={billingDetails.city}
                onChange={(e) => handleFieldChange("city", e.target.value)}
                onFocus={() => handleFieldChange("isCityFocused", true)}
                onBlur={() => handleFieldChange("isCityFocused", false)}
                placeholder="Enter City"
                className={`billing-city-input ${
                  billingDetails.isCityFocused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isCityFocused && billingDetails.city === "" && (
                <span className="blinking-cursor">|</span>
              )}
            </div>
            {!selectedState && hasAttemptedToOrder && (
              <span className="error-text">Required</span>
            )}
            <div className="input-wrapper blinker-wrapper">
              <StateDropdown
                selectedState={selectedState}
                clearState={clearState}
                setSelectedState={setSelectedState}
                isOpen={isDropdownOpen}
                onToggle={setDropdownOpen}
                className="billing-statedropdown"
              />
            </div>
            <div className="input-wrapper blinker-wrapper">
              <input
                value={billingDetails.postalCode}
                onChange={(e) =>
                  handleFieldChange("postalCode", e.target.value)
                }
                onFocus={() => handleFieldChange("isPostalCodeFocused", true)}
                onBlur={() => handleFieldChange("isPostalCodeFocused", false)}
                placeholder="Enter billing zipcode"
                className={`billing-zipcode-input ${
                  billingDetails.isPostalCodeFocused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isPostalCodeFocused &&
                billingDetails.postalCode === "" && (
                  <span className="blinking-cursor">|</span>
                )}
              {!billingDetails.isPostalCodeFocused &&
                billingDetails.postalCode === "" &&
                hasAttemptedToOrder && (
                  <span className="error-text">Required</span>
                )}
            </div>
            <div className="input-wrapper blinker-wrapper">
              <input
                value={billingDetails.country}
                onChange={(e) => handleFieldChange("country", e.target.value)}
                onFocus={() => handleFieldChange("isCountryFocused", true)}
                onBlur={() => handleFieldChange("isCountryFocused", false)}
                placeholder="Enter billing country"
                className={`billing-country-input ${
                  billingDetails.isCountryFocused ? "focused-input" : ""
                }`}
              />
              {!billingDetails.isCountryFocused &&
                billingDetails.country === "" && (
                  <span className="blinking-cursor">|</span>
                )}
              {!billingDetails.isCountryFocused &&
                billingDetails.country === "" &&
                hasAttemptedToOrder && (
                  <span className="error-text">Required</span>
                )}
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
}
export default PaymentsForm;
