export const CONTEXT_TYPES = {
  SIGN_UP: "signUp",
  SIGN_IN: "signIn",
  Account_Verification: "enterMobileNumber",
  Verify_Account: "verifyAccountOtp",
  Reset_Password: "resetPassword",
  Event_Popup_Create: "eventPopupCreate",
  Group_Popup_Create: "groupPopupCreate",
  Post_Form: "postForm",
};
