import { INCREASE_POINTS_BALANCE, DECREASE_POINTS_BALANCE } from "../actions/actionPointsBalance.js";
const initialState = {
  balance: 0,
};
const pointsBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_POINTS_BALANCE:
      return {
        ...state,
        balance: state.balance + action.payload,
      };
    case DECREASE_POINTS_BALANCE:
      return {
        ...state,
        balance: state.balance - action.payload,
      };
    default:
      return state;
  }
};
export default pointsBalanceReducer;
