import React, { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import { SolidColorButtonRef } from "../buttons";
import {  XIcon, ClipBoardCheck } from "../../components/icons";
import { NavLink } from "react-router-dom";
import { getCapitalizedLocationDetail } from "../../utils/stringUtils.js";
import "./eventCardSansMedia.scss";
// console.log(document.querySelectorAll(".white-gold-btn"));

const EventCardSansMedia = ({ event }) => {
  const {
    id,
    eventStartDate,
    eventEndDate,
    eventTotalInterested,
    eventTotalAttending,
  } = event;

  const [animations, setAnimations] = useState({});
  const [userAction, setUserAction] = useState(false);

  let locationClassName = "";

  let locationDetails =
    event.eventPlace ||
    event.eventStreet ||
    event.eventCity ||
    event.eventState ||
    event.eventZipCode;

  if (event.isRemote && locationDetails) {
    locationDetails = `${event.eventPlace ? event.eventPlace + ", " : ""}
                      ${event.eventStreet ? event.eventStreet + ", " : ""}
                      ${event.eventCity ? event.eventCity + ", " : ""}
                      ${event.eventState ? event.eventState + ", " : ""}
                      ${event.eventZipCode ? event.eventZipCode + ", " : ""}
                      United States · Virtual`;
    locationClassName = "sans-media-location-remote-and-physical";
  } else if (event.isRemote) {
    locationDetails = "Virtual";
    locationClassName = "sans-media-only-remote";
  } else if (locationDetails) {
    locationDetails = `${event.eventPlace ? event.eventPlace + ", " : ""}
                      ${event.eventStreet ? event.eventStreet + ", " : ""}
                      ${event.eventCity ? event.eventCity + ", " : ""}
                      ${event.eventState ? event.eventState + ", " : ""}
                      ${event.eventZipCode ? event.eventZipCode + ", " : ""}
                      United States`;
    locationClassName = "sans-media-in-person";
  }
  // In EventCard component
  const buttonRef = React.useRef(null);

  // When passing the button component, pass the ref as well
  <solidColorButton ref={buttonRef} /* other props */ />;

  const OnAnimate = (buttonType) => {
    setAnimations((prev) => ({
      ...prev,
      [buttonType]: true,
    }));
    setTimeout(() => {
      setAnimations((prev) => ({
        ...prev,
        [buttonType]: false,
      }));
    }, 2000);
  };

  // comment this out if you want to remove the confetti launching on mount
  useEffect(() => {
    showConfetti();
  }, []); // Empty dependency array means it will run once after the initial render

  const showConfetti = () => {
    const button = buttonRef.current;
    if (!button) {
      return;
    }
    const rect = button.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;
    const normalizedX = x / window.innerWidth;
    const normalizedY = y / window.innerHeight;

    confetti({
      particleCount: 150,
      spread: 60,
      origin: {
        x: normalizedX,
        y: normalizedY,
      },
    });
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(date);
  };
  return (
    <NavLink className="sans-media-event-card" to={`/events/${id}`}>
      <div className="sans-media-event-info">
        <div className="sans-media-eventDate-s">
          <p>
            {eventStartDate && (
              <>
                Starts: {formatDate(new Date(eventStartDate))}
                {event.eventStartTime && ` @ ${event.eventStartTime}`}
              </>
            )}
            {/* commented end date and duration out for the events listings page */}
            {/* {eventStartDate && eventEndDate && " - "}
            {eventEndDate && (
              <>
                Ends: {formatDate(new Date(eventEndDate))}
                {event.eventEndTime && ` @ ${event.eventEndTime}`}
              </>
            )}
            <br />
            {eventStartDate &&
              eventEndDate &&
              `Duration: ${timeCalculator(eventStartDate, eventEndDate)} hours`} */}
          </p>
          {event.eventTitle && (
            <h1 className="sans-media-eventTitle">{event.eventTitle}</h1>
          )}
          {/* no relestate for mobile so commentdd out description for eventslisting page */}
          {/* {event.eventDescription && (
            <p className="eventDescription">{event.eventDescription}</p>
          )} */}
        </div>
        {locationDetails && (
          <div
            className={`sans-media-event-location-type ${locationClassName}`}
          >
            {getCapitalizedLocationDetail(locationDetails)}
          </div>
        )}
        <div>
          <span className="sans-media-eventTotalInterested">
            {eventTotalInterested} people interested
          </span>
          <span className="sans-media-spacer"></span>
          <span className="sans-media-eventTotalAttending">
            {eventTotalAttending} people attending
          </span>
        </div>
      </div>
      <div className="sans-media-button-container">
        {userAction ? (
          <SolidColorButtonRef
            solidColorBtnImage={
              <XIcon fillColor="#964b00" height="25px" width="25px" />
            }
            onClick={(e) => {
              e.preventDefault();
              setUserAction(false);
            }}
            solidColorBtnText="Cancel Interested"
            solidColorBtnFontColor="#964b00"
            className="gold-btn"
            solidColorBtnWidth="fit-content"
            solidColorBtnTextFontSize="1.5rem"
            solidColorBtnHeight="40px"
            solidColorBtnFontFamily="Roboto-Slab"
            solidColorBtnFontWeight="900"
          />
        ) : (
          <SolidColorButtonRef
            solidColorBtnImage={
              <ClipBoardCheck fillColor="#964b00" height="25px" width="25px" />
            }
            ref={buttonRef}
            solidColorBtnText="Interested"
            solidColorBtnFontColor="#964b00"
            className="gold-btn"
            solidColorBtnWidth="fit-content"
            solidColorBtnTextFontSize="1.5rem"
            solidColorBtnHeight="40px"
            solidColorBtnFontFamily="Roboto-Slab"
            solidColorBtnFontWeight="900"
            animationActive={true}
            onClick={(e) => {
              e.preventDefault();
              setUserAction(true);
              showConfetti();
              console.log("Button clicked");
            }}
          />
        )}
      </div>
    </NavLink>
  );
};

export default EventCardSansMedia;
