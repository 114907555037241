import React from 'react';
import "./customRadioBtn.scss"; // assuming you have your styles in a separate SCSS file

const CustomRadioButton = ({ label, name, value, checked, onChange }) => {
    return (
        <div className="custom-radio">
            <input 
                type="radio" 
                id={value} 
                name={name} 
                value={value} 
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={value}>{label}</label>
        </div>
    );
};

export default CustomRadioButton;
