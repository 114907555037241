import React from "react";
import { LeftArrow, RightArrow } from "../../components/icons";


export const renderArrows = (
  sliderRef,
  activeSlide,
  setActiveSlide,
  dashboardSliderMap
) => {
  const handlePrevNextClick = (direction) => {
    let newActiveSlideIndex;
    if (direction === "prev") {
      newActiveSlideIndex = activeSlide === 1 ? 2 : 1; // Assuming only 2 slides are available
    } else {
      // direction === "next"
      newActiveSlideIndex = activeSlide === 1 ? 2 : 1; // Assuming only 2 slides are available
    }
    const newActiveSlideName = dashboardSliderMap[newActiveSlideIndex];

    console.log(
      `Clicked ${direction}. Current Slide: ${activeSlide} (${dashboardSliderMap[activeSlide]}), New Slide: ${newActiveSlideIndex} (${newActiveSlideName})`
    );
    setActiveSlide(newActiveSlideIndex);

    // Trigger the appropriate slide change method
    if (direction === "prev") {
      sliderRef.current.handlePrev();
    } else {
      sliderRef.current.handleNext();
    }
  };

  return (
    <div className="slider-arrow">
      <button
        type="button"
        className="arrow-btn prev"
        onClick={() => handlePrevNextClick("prev")}
      >
        <LeftArrow strokeColor="#e2b645"></LeftArrow>
      </button>
      <button
        type="button"
        className="arrow-btn next"
        onClick={() => handlePrevNextClick("next")}
      >
        <RightArrow strokeColor="#e2b645"></RightArrow>
      </button>
    </div>
  );
};
