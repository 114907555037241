// Importing necessary hooks and styles for the component.
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./slider.scss";

// Function Parameters: The function provided to map has two parameters:
// slide and index. The slide parameter refers to the current item in the
// slides array (i.e., the content of the slide), and the index parameter
// refers to the position of that item in the array (starting from 0).
// Creating the InfinitySlider component and setting it up to use a forwarded ref.
const InfinitySlider = forwardRef(({ slides }, ref) => {
  // A UI component, possibly for sliding through rewards or similar.
  // Using useRef to keep track of the slider and carousel DOM elements.
  const sliderRef = useRef(null);
  const carouselRef = useRef(null);
  // Using useState to keep track of the direction in which the slider is moving.
  const [direction, setDirection] = useState(null);
  // setting state to handle slider transition or slide timing
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  // Function to handle moving to the previous slide.
  const handlePrev = () => {
    if (direction === -1 || direction === null) {
      // If the slider is moving to the left or hasn't moved yet, adjust the slider's DOM elements.
      sliderRef.current.appendChild(sliderRef.current.firstElementChild);
      setDirection(1);
    }
    // Adjust the styles to move the slider content to the right.
    carouselRef.current.style.justifyContent = "flex-end";
    sliderRef.current.style.transform = `translate(${100 / slides.length}%)`;
  };
  // Function to handle moving to the next slide.
  const handleNext = () => {
    setDirection(-1);
    // Adjust the styles to move the slider content to the left.
    carouselRef.current.style.justifyContent = "flex-start";
    sliderRef.current.style.transform = `translate(-${100 / slides.length}%)`;
  };
  // Function to directly go to a specific slide by its index.
  const sliderGoTo = (index) => {
    if (index < slides.length) {
      for (let i = 0; i < index; i++) {
        handleNext();
      }
    }
  };
  // Function to handle what happens after the slider completes its transition.
  const handleTransitionEnd = () => {
    // If direction is 1 (right), adjust the DOM elements.
    if (isInitialLoad) {
      setIsInitialLoad(false);
      sliderRef.current.style.transition = "all 0.5s";
    }
    if (direction === 1) {
      sliderRef.current.prepend(sliderRef.current.lastElementChild);
    } else {
      // Otherwise, adjust for moving left.
      sliderRef.current.appendChild(sliderRef.current.firstElementChild);
    }
    // Reset the transition and transformation styles.
    sliderRef.current.style.transition = "none";
    sliderRef.current.style.transform = "translate(0)";
    setTimeout(() => {
      sliderRef.current.style.transition = "all 0.5s";
    });
  };
  // Expose certain functions outside of the component using the ref.
  useImperativeHandle(ref, () => ({
    handlePrev,
    handleNext,
    sliderGoTo,
  }));
  

  return (
    <>
      <div className="carousel" ref={carouselRef}>
        <div
          className="slider-content"
          ref={sliderRef}
          onTransitionEnd={handleTransitionEnd}
          style={{
            width: `${100 * slides.length}%`,
            transition: isInitialLoad ? "all 2s" : "all 0.5s",
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              style={{
                width: `${100 / slides.length}%`,
                flexBasis: `${100 / slides.length}%`,
              }}
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
    </>
  );
});
// Export the component to be used elsewhere.
export default InfinitySlider;
