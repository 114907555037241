import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/material.css";
import { useAuth } from "../../../context/authContext";
import { EyeSlashIcon, EyeIcon } from "../../icons";
import { GlassBtn, CustomButton } from "../../buttons";
import ToolTip from "../../tooltip/tooltip";
import { handleResetPassWordErrors } from "../../../controller/errorHandlers/handleResetPassWordErrors.js";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator.js";
import { validatePasswordValue } from "../../../constants/validate";
import {
  handleResetPasswordValueChange,
  onResetPasswordValueFocused,
  onResetPasswordValueBlur,
  handleConfirmResetPasswordChange,
} from "../../../controller/watcherAndClearFunctions/resetPasswordClearsWatchersFn.js";
import "../formsCommon.scss";
import "./resetPassword.scss";
const ResetPassword = ({ setCurrentForm, setContextType }) => {
  const currentContext = "resetPasswordSignin";
  const [resetPasswordFocused, setResetPasswordFocused] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [
    updatePasswordSigninFlowAttempted,
    setUpdatePasswordSigninFlowAttempted,
  ] = useState(false);
  const [resetPasswordTooltipVisible, setResetPasswordTooltipVisible] =
    useState(false);
  const [passwordValidationRules, setPasswordValidationRules] = useState([
    false,
  ]);
  const [showConfirmResetPassword, setShowConfirmResetPassword] =
    useState(false);
  const [confirmResetPasswordFocused, setConfirmResetPasswordFocused] =
    useState(false); // if focus then no blinker
  const [resetPasswordValue, setResetPasswordValue] = useState(
    localStorage.getItem(getStorageKey("resetPasswordValue", currentContext)) ||
      ""
  );
  const [confirmResetPasswordValue, setConfirmResetPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("confirmPasswordValue", currentContext)
    ) || ""
  );
  const [resetPasswordError, setResetPassWordError] = useState("");
  const [confirmResetPasswordError, setConfirmResetPasswordError] =
    useState("");

  const onResetPassword = async () => {
    setUpdatePasswordSigninFlowAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  useEffect(() => {
    console.log("Checking if form should be submitted:");
    console.log("resetPasswordValue:", resetPasswordValue);
    console.log("confirmResetPasswordValue:", confirmResetPasswordValue);
    if (updatePasswordSigninFlowAttempted) {
      console.log(
        "currentContext before calling handleResetPassWordErrors:",
        currentContext
      );
      const { errors } = handleResetPassWordErrors(
        resetPasswordValue, // values first order matters
        confirmResetPasswordValue,
        currentContext, // this always needs to be after the values
        setResetPasswordTooltipVisible, // setters next order matters
        setResetPassWordError,
        setConfirmResetPasswordError
      );
      if (!errors) {
        localStorage.clear();
        setCurrentForm("signIn");
        setContextType("signIn");
        console.log("No validation errors. Proceeding with form submission.");
      }
      setUpdatePasswordSigninFlowAttempted(false); // Reset for next submission attempt
    }
  }, [
    updatePasswordSigninFlowAttempted,
    resetPasswordValue,
    confirmResetPasswordValue,
  ]);

  return (
    <div className="form-wrapper">
      <form id="reset-password-form">
        <h1 className="reset-password-header">Reset Password</h1>
        <div className="input-group-with-tooltip">
          <div className="input-group blinker-wrapper">
            <label htmlFor="resetPasswordValue">Create a New Password</label>
            <input
              type={showResetPassword ? "text" : "password"}
              id="new_password"
              autoComplete="off"
              placeholder={
                resetPasswordError ? resetPasswordError : "Enter Password Here"
              }
              className={resetPasswordError ? "red-placeholder" : ""}
              value={resetPasswordValue}
              onChange={(e) =>
                handleResetPasswordValueChange(
                  e.target.value,
                  currentContext,
                  resetPasswordError,
                  setResetPasswordValue,
                  validatePasswordValue,
                  setPasswordValidationRules,
                  setResetPasswordTooltipVisible,
                  setResetPassWordError
                )
              }
              onFocus={() =>
                onResetPasswordValueFocused(
                  resetPasswordValue,
                  setResetPasswordFocused,
                  validatePasswordValue,
                  setPasswordValidationRules,
                  setResetPasswordTooltipVisible
                )
              }
              onBlur={() =>
                onResetPasswordValueBlur(
                  setResetPasswordFocused,
                  setResetPasswordTooltipVisible
                )
              }
            />
            {!resetPasswordFocused && resetPasswordValue === "" && (
              <span className="blinking-cursor signup-cursor">|</span>
            )}
            {(resetPasswordFocused || resetPasswordError) && (
              <ToolTip
                rules={passwordValidationRules}
                toolTipVisible={resetPasswordTooltipVisible}
                setToolTipVisible={setResetPasswordTooltipVisible}
              />
            )}
            {/* undefined and empty string is false since you have to specify undefind as false it can mean many things */}
            {resetPasswordValue && resetPasswordValue !== "" && (
              <div
                className="show-hide-password-toggle"
                onClick={() => setShowResetPassword((prev) => !prev)}
              >
                {showResetPassword ? (
                  <EyeSlashIcon width="20" height="15" />
                ) : (
                  <EyeIcon width="20" height="15" />
                )}
                {showResetPassword ? "Hide" : "Show"}
              </div>
            )}
          </div>
        </div>
        <div className="input-group blinker-wrapper">
          <label htmlFor="confirm_password">Confirm Password:</label>
          <input
            type={showConfirmResetPassword ? "text" : "password"}
            id="confirm_password"
            autoComplete="off"
            placeholder={
              confirmResetPasswordError
                ? confirmResetPasswordError
                : "Confirm your password"
            }
            className={confirmResetPasswordError ? "red-placeholder" : ""}
            value={confirmResetPasswordValue}
            onChange={(e) =>
              handleConfirmResetPasswordChange(
                e.target.value,
                confirmResetPasswordError,
                currentContext,
                setConfirmResetPasswordValue,
                setConfirmResetPasswordError
              )
            }
            onFocus={() => setConfirmResetPasswordFocused(true)}
            onBlur={() => setConfirmResetPasswordFocused(false)}
          />
          {confirmResetPasswordError &&
            confirmResetPasswordError !== "Required" && (
              <div className="error-message">{confirmResetPasswordError}</div>
            )}

          {!confirmResetPasswordFocused && confirmResetPasswordValue === "" && (
            <span className="blinking-cursor signup-cursor">|</span>
          )}
          {/* confirmed password is undefined so need to write it twice to say undefined is also false since it is not intialized as a string */}
          {confirmResetPasswordValue && confirmResetPasswordValue !== "" && (
            <div
              className="show-hide-password-toggle"
              onClick={() => setShowConfirmResetPassword((prev) => !prev)}
              style={{
                bottom: confirmResetPasswordError ? "28px" : "5px", // Adjust based on error message height
              }}
            >
              {showConfirmResetPassword ? (
                <EyeSlashIcon width="20" height="15" />
              ) : (
                <EyeIcon width="20" height="15" />
              )}
              {showConfirmResetPassword ? "Hide" : "Show"}
            </div>
          )}
        </div>
        <div>
          <GlassBtn
            onClick={onResetPassword}
            glassBtnText="Update Password"
            glassBtnWidth="fit-content"
            glassBtnHeight="20px"
            sparkle="true"
            top="6px"
            fullwidthGlassHi={true}
            hi2Style={{
              transform: "rotate(90deg) translate(-30px, 100px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate( -30px, -97px)",
            }}
          />
        </div>
        <CustomButton
          customBtnText="X Cancel"
          customBtnWidth="150px"
          customBtnTextFontSize="1.5rem"
          customBtnFontFamily="Roboto-Slab"
          customBtnFontWeight="900"
          animationActive={true}
          onClick={() => {
            localStorage.clear();
            setContextType("signIn"); // redirect user
            setCurrentForm("signIn");
          }}
        />
      </form>
    </div>
  );
};
export default ResetPassword;
