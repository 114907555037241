import { getStorageKey } from "./localStorageUniqueKeyGenerator.js";
export const handleFileChange = async (
    event,
    setCommentFile,
    currentContext,
    setIsImageUploaded,
    ) => {
    const file = event.target.files[0];
    if (file) {
      setCommentFile(file);
      // Read the file and convert it to a Base64 encoded string
      const reader = new FileReader();
      reader.onloadend = () => {
        // Save the Base64 encoded string to localStorage
        const imageKey = getStorageKey("postImage", currentContext);
        localStorage.setItem(imageKey, reader.result);
        setIsImageUploaded(true); // Update the state to indicate the image is uploaded
      };
      reader.readAsDataURL(file);
    }
  };

