import React from "react";
import "./emoji.scss";

const EmotionEmoji = ({ onSelectEmoji }) => {
  // Function to handle emoji click
  const chooseEmoji = (emojiId) => {
    console.log(`Emoji clicked: ${emojiId}`);
    onSelectEmoji(emojiId);
  };
  return (
    <div>
      {/* Angry Emoji */}
      <div className="emoji-angry-face" onClick={() => chooseEmoji("angry")}>
        <div className="emoji-angry-expression">
          <div className="emoji--angry-eyebrows"></div>
          <div className="emoji-eye emoji-eye-left"></div>
          <div className="emoji-eye emoji-eye-right"></div>
          <div className="emoji-angry-mouth"></div>
        </div>
      </div>

      {/* Wow Emoji */}
      <div className="emoji-wow-face" onClick={() => chooseEmoji("wow")}>
        <div className="emoji-wow-expression">
          <div className="emoji-wow-eyes  emoji-wow-eye-left"></div>
          <div className="emoji-wow-eyes emoji-wow-eye-right "></div>
          <div className="emoji-wow-eyebrows emoji-wow-eyebrow-right"></div>
          <div className="emoji-wow-eyebrows emoji-wow-eyebrow-left"></div>
          <div className="emoji-wow-mouth"></div>
        </div>
      </div>

      {/* Serious Emoji */}
      <div
        className="emoji-serious-face"
        onClick={() => chooseEmoji("serious")}
      >
        <div className="emoji-serious-expression">
          <div className="emoji-serious-eyebrows"></div>
          <div className="emoji-eye emoji-eye-left"></div>
          <div className="emoji-eye emoji-eye-right"></div>
          <div className="emoji-serious-mouth"></div>
        </div>
      </div>
      {/* Serious Emoji */}
      <div
        className="emoji-teethy-laugh-face"
        onClick={() => chooseEmoji("teethy-laugh")}
      >
        <div className="emoji-teethy-laugh-expression">
          <div className="emoji-teethy-laugh-eyebrows"></div>
          <div className="emoji-teethy-laugh-eye emoji-teethy-laugh-eye-left"></div>
          <div className="emoji-teethy-laugh-eye emoji-teethy-laugh-eye-right"></div>
          <div className="emoji-teethy-laugh-mouth"></div>
        </div>
      </div>
      {/* Toungue Laugh Emoji */}
      <div
        className="emoji-toungue-laugh-face"
        onClick={() => chooseEmoji("toungue-laugh")}
      >
        <div className="emoji-toungue-laugh-expression">
          <div className="emoji-toungue-laugh-eyebrows"></div>
          <div className="emoji-toungue-laugh-eye emoji-toungue-laugh-eye-left"></div>
          <div className="emoji-toungue-laugh-eye emoji-toungue-laugh-eye-right"></div>
          <div className="emoji-toungue-laugh-mouth">
            <div className="emoji-tongue"></div>
          </div>
        </div>
      </div>
      <div
        className="emoji-annoyed-face"
        onClick={() => chooseEmoji("annoyed")}
      >
        <div className="emoji-annoyed-eyes" id="annoyed-left-eye">
          <div id="left-pupil" className="pupil"></div>
          <div id="left-eyelid" className="eyelid"></div>
        </div>
        <div className="emoji-annoyed-eyes" id="annoyed-right-eye">
          <div id="right-pupil" className="pupil"></div>
          <div id="right-eyelid" className="eyelid"></div>
          <div className="emoji-annoyed-mouth"></div>
        </div>
      </div>
      <div className="emoji-sad-face" onClick={() => chooseEmoji("sad")}>
        <div className="emoji-sad-pupil"></div>
        <div id="sad-left-eye" className="emoji-sad-eye">
          <div id="sad-left-droop" className="emoji-sad-droop"></div>
          <div className="emoji-sad-pupil"></div>
        </div>
        <div id="sad-right-eye" className="emoji-sad-eye">
          <div id="sad-right-droop" className="emoji-sad-droop"></div>
          <div className="emoji-sad-pupil"></div>
        </div>
        <div className="emoji-sad-mouth"></div>
      </div>
      <div
        className="emoji-zipper-face"
        onClick={() => chooseEmoji("zipper-face")}
      >
        <div className="emoji-zipper-face-eye emoji-zipper-face-eye-left"></div>
        <div className="emoji-zipper-face-eye emoji-zipper-face-eye-right"></div>
        <div className="zipper">
          <div
            className="zipper-track"
            style={{ "--delay": "0s", zIndex: "1" }}
          ></div>
          <div
            className="zipper-track"
            style={{ "--delay": "0.2s", zIndex: "1" }}
          ></div>
          <div
            className="zipper-track"
            style={{ "--delay": "0.4s", zIndex: "1" }}
          ></div>
          <div
            className="zipper-track"
            style={{ "--delay": "0.6s", zIndex: "1" }}
          ></div>
          <div
            className="zipper-track"
            style={{ "--delay": "0.8s", zIndex: "1" }}
          ></div>
          <div className="zipper-line"></div> {/* Add the zipper line */}
          <div className="zipper-pull"></div>
          <div className="zipper-pull-hook"></div>
          <div className="zipper-pull-attacher"></div>{" "}
        </div>
      </div>
      <div className="emoji-yay-face" onClick={() => chooseEmoji("yay")}>
        <div className="emoji-yay-expression">
          <div className="emoji-yay-eye emoji-yay-eye-left"></div>
          <div className="emoji-yay-eye emoji-yay-eye-right"></div>
          <div className="emoji-yay-mouth"></div>
        </div>
      </div>
      <div
        className="emoji-heart-eyes-face"
        onClick={() => chooseEmoji("heart-eyes-face")}
      >
        <div className="emoji-heart-eyes-expression">
          <div className="emoji-heart-eye emoji-heart-eye-left">❤</div>
          <div className="emoji-heart-eye emoji-heart-eye-right">❤</div>
          <div className="emoji-heart-eyes-mouth"></div>
        </div>
      </div>
      <div
        className="emoji-wink-heart-kiss-face"
        onClick={() => chooseEmoji("wink-heart-kis")}
      >
        <div className="emoji-wink-heart-kiss-expression">
          <div className="emoji-wink-eye emoji-wink-eye-left" />
          <div className="emoji-wink-eye emoji-wink-eye-right"></div>
          <div className="emoji--heart-mouth-kiss" />
          <div className="floating-heart">❤️</div>
        </div>
      </div>
      <div className="emoji-love-face" onClick={() => chooseEmoji("love")}>
        <div className="emoji-love-heart">❤️</div>
      </div>
    </div>
  );
};
export default EmotionEmoji;
