export const handleAcctValidationErrors = (
  acctPhoneNumberValue,
  setAcctPhoneNumberErrors,
  currentContext,
) => {
  let errors = {};
  if (currentContext === "resetPasswordSignin" &&  acctPhoneNumberValue.length ===0) {
      errors.acctPhoneNumberErrors = "Required";
    } 
    // else if (currentContext === "resetPasswordSignin" && (acctPhoneNumberValue.length < 10 || /^(.)\1+$/.test(acctPhoneNumberValue))) {
    //   // Validate if the phone number is at least 10 digits and not all the same digit
    //   errors.acctPhoneNumberErrors = "Invalid phone number";
    // }
  
    setAcctPhoneNumberErrors(errors.acctPhoneNumberErrors || "");
    return { errors }; // Ensure that an object is returned in all cases
  };
