// the layout is the child of the browser router
// the layout also has pages and routing sections
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LandingPage from "./pages/landingPage/landingPage.jsx";
import Layout from "./components/layout/layout.jsx";
import CommunityPage from "./pages/communityPage/communityPage";
import { AnimationProvider } from "./context/animationContext.jsx";
// import ChatPage from "./pages/chatPage/chatPage";
import NotificationPopupListPage from "./pages/notificationPage/NotificationPopupListPage.jsx";
import EventsListPage from "./pages/eventsListPage/eventsListPage";
import GroupsListPage from "./pages/groupsListPage/groupsListPage.jsx";
import ProfilePage from "./pages/profilePage/profilePage";
import DashboardPage from "./pages/dashboard";
import ConnectionsPage from "./pages/connectionsPage/connectionsPage";
import EventDetailsPage from "./pages/eventDetailsPage/eventDetailsPage";
import GroupDetailsPage from "./pages/groupDetailPage/groupDetailPage.jsx";
import PaymentMethodsPage from "./pages/paymentMethods/paymentMethods";
import ReceiptInvoicesListPage from "./pages/receiptsInvoicesPage/receiptsInvoicesPage.jsx";
import SettingsPage from "./pages/settingsPage/settingsPage";
import SubcategoryMenuPage from "./pages/subCatMenuPage/subCatMenuPage.jsx";
import PostDetails from "./components/postDetails/userPostDetails.jsx";

// global styles to be used anywhere in the app
import "./styles/blinker.scss";
import "./styles/colorAnimation.scss";
import "./styles/globalStyle.scss";

const App = () => {
  const [pointsBalance, setPointsBalance] = useState(0);
  const [giftCardBalance, setGiftCardBalance] = useState(0);

  const location = useLocation();
  return (
    <AnimationProvider>
      {/* <ScrollToTopComponent /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="community" element={<CommunityPage />} />
          <Route path="/post/:postId" element={<PostDetails />} />
          {/* <Route path="chat" element={<ChatPage />} /> */}
          <Route
            path="notifications"
            exact
            element={<NotificationPopupListPage />}
          />
          <Route
            path="/receipts-invoices"
            exact
            element={<ReceiptInvoicesListPage />}
          />
          <Route path="groups" exact element={<GroupsListPage />} />
          <Route path="events" exact element={<EventsListPage />} />
          <Route
            path="/select-subcategory/:categoryId"
            element={<SubcategoryMenuPage />}
          />
          <Route
            path="dashboard"
            exact
            element={
              <DashboardPage
                pointsBalance={pointsBalance}
                setPointsBalance={setPointsBalance}
                giftCardBalance={giftCardBalance}
                setGiftCardBalance={setGiftCardBalance}
              />
            }
          />
          <Route
            path="profile/:username"
            element={
              <ProfilePage
                pointsBalance={pointsBalance}
                setPointsBalance={setPointsBalance}
                key={location.key}
              />
            }
          />
          <Route path="events/:calendarId" element={<EventDetailsPage />} />
          <Route path="groups/:groupId" element={<GroupDetailsPage />} />
          <Route path="/connections/:username" element={<ConnectionsPage />} />
          {/* ... (other routes can be added here as siblings to the LandingPage route) */}
          <Route
            path="payment-methods"
            element={
              <PaymentMethodsPage
                pointsBalance={pointsBalance}
                setPointsBalance={setPointsBalance}
                giftCardBalance={giftCardBalance}
                setGiftCardBalance={setGiftCardBalance}
              />
            }
          />
          <Route path="settings" element={<SettingsPage />} />
        </Route>
      </Routes>
    </AnimationProvider>
  );
};
export default App;

// BrowserRouter //  one minute read
// It allows you to create a router object which
// contains your routes and their respective components.
// The route configurations are specified in a nested structure
// where each route can have child routes.
// RouterProvider
// It is a component that takes the router object
// created by createBrowserRouter and uses it to
// render your app, making the router's data available
// to the rest of your app through React Router's data APIs.
// Index Route: An index route is a route that matches the base
// path of a parent route. In your code, you have defined an
// index route for the root path ("/") within the Routes
// component. This means that when the URL matches the root
// path ("/"), the component specified as LandingPage will be
// rendered as the main content. The index route is often used
// for the default route of a section of your application.
// Path Route: A path route is a route that matches a specific
// path segment. In your code, you have defined a path route
// for the "/community" path within the Routes component.
// This means that when the URL matches the "/community"
// path, the component specified as CommunityPage will be
// rendered as the main content. Path routes are used for
// handling specific paths or sub-paths in your application.
