import React, { useState, useEffect, useRef } from "react";
import { WhiteGoldButton, ToggleSlider, SlideBtn } from "../../buttons";
import { CommunityIcon } from "../../icons";
import StateDropdown from "../../stateDropdown/stateDropDown";
import PrivateInviteOptions from "../../privateInviteOptions/privateInviteOptions";
import CustomCheckbox from "../../buttons/customCheckbox/customCheckBox";
import CustomRadioButton from "../../buttons/customRadioBtn/customRadioBtn";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import { createGroupErrorHandler } from "../../../controller/errorHandlers/groupFormErrorHandler.js";
import {
  groupTitleWatcher,
  descriptionValueWatcher,
  groupCityWatcher,
  clearGroupCity,
  clearGroupTitle,
  clearGroupDescription,
} from "../../../controller/watcherAndClearFunctions/groupClearsWatchersFunctions.js";
import FileUploadButton from "../profileEventGroupPicPageUploads/profileEventGroupPicPageUploads.jsx";
import "./groupForm.scss";

const GroupPopup = ({ onClose, groupFormOpen, updateGroup, contextType }) => {
  const currentContext = updateGroup ? "updateGroup" : "createGroup";
  const [groupVisibility, setGroupVisibility] = useState("public");
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({}); // Object to manage which groups are selected
  const [selectedFollowers, setSelectedFollowers] = useState([]); // Object to manage which Followers are selected
  const [selectedFollowing, setSelectedFollowing] = useState([]); // Object to manage which Following are selected
  const [groupIsInPerson, setGroupIsInPerson] = useState(false);
  const [groupIsRemote, setGroupIsRemote] = useState(false);
  const [excludeChecked, setExcludeChecked] = useState(false);
  const [isStateDropDownOpen, setStateDropDownOpen] = useState(false);
  const [showManageGroupOptions, setShowManageGroupOptions] = useState(true);
  const [isGroupInviteManager, setIsGroupInviteManager] = useState(true);
  const [groupZipCodeError, setGroupZipCodeError] = useState(false);
  const [groupCityError, setGroupCityError] = useState("");
  const [selectedStateError, setSelectedStateError] = useState("");
  const [groupTitleFocused, setGroupTitleFocused] = useState(false);
  const [cityFocused, setCityFocused] = useState(false);
  const [zipCodeFocused, setZipCodeFocused] = useState(false);
  const [groupZipCodeValue, setGroupZipCodeValue] = useState("");
  const [groupCityValue, setGroupCityValue] = useState("");
  const [groupTitleValue, setGroupTitleValue] = useState("");
  const [groupDescriptionValue, setGroupDescriptionValue] = useState("");
  const [descriptionFocused, setDescriptionFocused] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUploadError, setFileUploadError] = useState("");
  const [groupProfilePicUploadAttempted, setGroupProfilePicUploadAttempted] =
    useState(false);
  const [selectedState, setSelectedState] = useState(
    localStorage.getItem(
      getStorageKey("groupSelectedStateValue", currentContext)
    ) || ""
  ); // State value with local storage persistence for selected state value
  const [createGroupAttempted, setCreateGroupAttempted] = useState(false);
  const topOfGroupFormRef = useRef(null);
  const [togglePosition, setTogglePosition] = useState("left"); // Set the initial position
  const handleToggleChange = (position) => {
    console.log("Toggled");
    console.log("handleToggle called with position:", position);
    setTogglePosition(position);
    // You can perform other actions based on the position here
  };
  const handleGroupSelect = (group, isSelected) => {
    setSelectedGroups((prev) => ({ ...prev, [group]: isSelected }));
  };
  const groups = ["Group A", "Group B", "Group C"]; // Dummy list of groups

  const followersList = ["Alice", "Bob", "Charlie"];
  const followingList = ["Dave", "Eve", "Frank"];

  const handleRemoveUser = (userListSetter, username) => {
    userListSetter((prevList) => prevList.filter((user) => user !== username));
  };

  useEffect(() => {
    // console.log("showManageGroupOptions value:", showManageGroupOptions);
  }, [showManageGroupOptions]);
  const onUpdateGroup = async () => {
    // setCreateGroupAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };

  const onCreateGroup = async () => {
    console.log("onCreateGroup triggered");

    if (currentContext === "createGroup" && !file) {
      setFileUploadError("Group Profile Picure is required to create page.");
    } else {
      setCreateGroupAttempted(true);
    }
  };

  useEffect(() => {
    // see below for updated code for backend need to review we need to also add a state for the file we are sending to backend
    if (createGroupAttempted) {
      const { errors } = createGroupErrorHandler(
        groupZipCodeValue,
        groupCityValue,
        selectedState,
        groupIsInPerson,
        currentContext,
        setGroupZipCodeError,
        setGroupCityError,
        setSelectedStateError
      );
      console.log("Form submission stopped due to errors:", errors);

      if (Object.keys(errors).length > 0) {
        // Log the error and scroll to the top of the form
        console.log("Form submission stopped due to errors:", errors);
        if (topOfGroupFormRef && topOfGroupFormRef.current) {
          topOfGroupFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // Check for file upload error
        if (currentContext === "createGroup" && fileUploadError) {
          console.log("File upload error:", fileUploadError);
        } else {
          // No errors, proceed with backend submission and form reset
          // Implement backend submission logic here (if not already implemented)
          resetGroupForm();
        }
      }
      setCreateGroupAttempted(false); // Reset for next submission attempt
    }
  }, [
    createGroupAttempted,
    groupZipCodeValue,
    groupCityValue,
    selectedState,
    groupIsInPerson,
    currentContext,
    topOfGroupFormRef,
    setGroupZipCodeError,
    setSelectedStateError,
  ]);
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setFileUploadError(null); // Reset file upload error
    }
  };

  const resetGroupForm = () => {
    clearGroupIsInPerson(setGroupIsInPerson, currentContext);
    clearGroupIsRemote(setGroupIsRemote, currentContext);
    clearGuestIsGroupInviteManager(setIsGroupInviteManager, currentContext);
    clearState(setSelectedState, setStateDropDownOpen, currentContext);
    clearGroupZipCode(setGroupZipCodeValue, currentContext);
    clearGroupCity(setGroupCityValue, currentContext);
    clearGroupTitle(setGroupTitleValue, currentContext);
    clearGroupDescription(setGroupDescriptionValue, currentContext);
    clearGroupVisibility(setGroupVisibility, currentContext);
    resetPrivateState(
      setShowFollowers,
      setShowFollowing,
      setExcludeChecked,
      setSelectedFollowers,
      setSelectedFollowing,
      currentContext
    );
    onClose();
  };

  const groupPermissionsWatcher = (visibility) => {
    if (visibility === "public") {
      resetPrivateState();
    }
    setGroupVisibility(visibility);
    const visibilityKey = getStorageKey("groupVisibility", currentContext);
    localStorage.setItem(visibilityKey, visibility);
  };

  useEffect(() => {
    const visibilityKey = getStorageKey("groupVisibility", currentContext);
    const storedVisibility = localStorage.getItem(visibilityKey);
    if (storedVisibility) {
      setGroupVisibility(storedVisibility);
    }
  }, [currentContext]);
  const clearGroupVisibility = () => {
    setGroupVisibility("public"); // Update the state
    const visibilityKey = getStorageKey("groupVisibility", currentContext);
    localStorage.removeItem(visibilityKey);
  };

  const groupIsInPersonWatcher = (e) => {
    const check = e.target.checked; // Extract the checked state from the group object
    setGroupIsInPerson(check); // Update the state
    const GroupIsInPersonKey = getStorageKey("GroupIsInPerson", currentContext);
    localStorage.setItem(GroupIsInPersonKey, check.toString()); // Convert boolean to string for localStorage
  };
  const groupRemoteCheckWatcher = (e) => {
    const check = e.target.checked; // Extract the checked state from the group object
    setGroupIsRemote(check); // Update the state
    const GroupIsRemoteKey = getStorageKey("GroupIsRemote", currentContext);
    localStorage.setItem(GroupIsRemoteKey, check.toString()); // Convert boolean to string for localStorage
  };
  const guestIsGroupInviteManagerWatcher = (position) => {
    const checked = position === "right"; // `isChecked` is true if position is "right"
    setIsGroupInviteManager(checked);
    const GuestIsGroupInviteManagerKey = getStorageKey(
      "GuestIsGroupInviteManagerKey",
      currentContext
    );
    localStorage.setItem(GuestIsGroupInviteManagerKey, checked.toString()); // Store in localStorage
  };
  const clearState = () => {
    // Define the key and hard coded currentContext to target payments
    const state = getStorageKey("State", currentContext);
    // Check if the key exists in localStorage and get the item
    if (localStorage.getItem(state) !== null) {
      localStorage.removeItem(state); // Remove the item
    } else {
      console.log("Key not found in localStorage:", state);
    }
    setSelectedState(""); // Clear the state
    setStateDropDownOpen(false);
  };
  useEffect(() => {
    if (selectedState) {
      const key = getStorageKey("State", currentContext);
      localStorage.setItem(key, selectedState);
      setSelectedState("State", selectedState);
    }
    if (selectedStateError) {
      setSelectedStateError("");
    }
  }, [selectedState, currentContext]);

  const groupZipCodeWatcher = (value) => {
    // Clear the error message when the user starts typing
    if (groupZipCodeError) {
      setGroupZipCodeError("");
    }
    // Only allow values that are numbers
    if (/^\d*$/.test(value)) {
      setGroupZipCodeValue(value);
      const GroupZipCodeKey = getStorageKey(
        "GroupZipCodeValue",
        currentContext
      );
      localStorage.setItem(GroupZipCodeKey, value);
    }
  };
  const clearGuestIsGroupInviteManager = (e) => {
    setIsGroupInviteManager(false); // Update the state
    const GuestIsGroupInviteManagerKey = getStorageKey(
      "GuestIsGroupInviteManagerKey",
      currentContext
    );
    localStorage.removeItem(GuestIsGroupInviteManagerKey); // Convert boolean to string for localStorage
  };

  const clearGroupIsInPerson = () => {
    setGroupIsInPerson(false); // Update the state
    const GroupIsInPersonKey = getStorageKey("GroupIsInPerson", currentContext);
    localStorage.removeItem(GroupIsInPersonKey); // Convert boolean to string for localStorage
  };
  const clearGroupIsRemote = () => {
    setGroupIsRemote(false); // Update the state
    const GroupIsRemoteKey = getStorageKey("GroupIsRemote", currentContext);
    localStorage.removeItem(GroupIsRemoteKey); // Convert boolean to string for localStorage
  };
  const clearGroupZipCode = () => {
    setGroupZipCodeValue("");
    const GroupZipCodeKey = getStorageKey("GroupZipCodeValue", currentContext);
    localStorage.removeItem(GroupZipCodeKey);
  };

  const inviteFollowersWatcher = () => {
    const newShowFollowers = !showFollowers;
    setShowFollowers(newShowFollowers);
    const showFollowersKey = getStorageKey("showFollowers", currentContext);
    localStorage.setItem(showFollowersKey, newShowFollowers.toString());
  };
  const inviteFollowingWatcher = () => {
    const newShowFollowing = !showFollowing;
    setShowFollowing(newShowFollowing);
    const showFollowingKey = getStorageKey("showFollowing", currentContext);
    localStorage.setItem(showFollowingKey, newShowFollowing.toString());
  };
  const inviteExcludeCheckedWatcher = () => {
    const newExcludeChecked = !excludeChecked;
    setExcludeChecked(newExcludeChecked);
    const excludeCheckedKey = getStorageKey("excludeChecked", currentContext);
    localStorage.setItem(excludeCheckedKey, newExcludeChecked.toString());
  };
  const inviteSelectedFollowerWatcher = (follower) => {
    const newSelectedFollowers = selectedFollowers.includes(follower)
      ? selectedFollowers.filter((f) => f !== follower)
      : [...selectedFollowers, follower];
    setSelectedFollowers(newSelectedFollowers);
    const selectedFollowersKey = getStorageKey(
      "selectedFollowers",
      currentContext
    );
    localStorage.setItem(
      selectedFollowersKey,
      JSON.stringify(newSelectedFollowers)
    );
  };

  const inviteSelectedFollowingWatcher = (following) => {
    const newSelectedFollowing = selectedFollowing.includes(following)
      ? selectedFollowing.filter((f) => f !== following)
      : [...selectedFollowing, following];
    setSelectedFollowing(newSelectedFollowing);
    const selectedFollowingKey = getStorageKey(
      "selectedFollowing",
      currentContext
    );
    localStorage.setItem(
      selectedFollowingKey,
      JSON.stringify(newSelectedFollowing)
    );
  };
  const resetPrivateState = (currentContext) => {
    setShowFollowers(true);
    setShowFollowing(true);
    setExcludeChecked(false);
    setSelectedFollowers([]);
    setSelectedFollowing([]);
    const keys = [
      getStorageKey("showFollowers", currentContext),
      getStorageKey("showFollowing", currentContext),
      getStorageKey("excludeChecked", currentContext),
      getStorageKey("selectedFollowers", currentContext),
      getStorageKey("selectedFollowing", currentContext),
    ];
    keys.forEach((key) => localStorage.removeItem(key));
  };
  return (
    <div
      ref={topOfGroupFormRef}
      className={`group-popup ${groupFormOpen ? "open" : "closed"}`}
    >
      {groupFormOpen && (
        <>
          <div className="popup-overlay" />
          <div className="popup-content">
            <WhiteGoldButton
              onClick={resetGroupForm}
              className="cancel-button"
              marginLeft="70%"
              whiteGoldBtnText="X Cancel"
              whiteGoldBtnFontColor="#964B00"
              whiteGoldBtnWidth="100px"
              whiteGoldBtnFontWeight="800"
              whiteGoldBtnFontFamily="roboto-slab"
              whiteGoldBtnTextFontSize="1.3rem"
            />
            <div className="header">
              <h2 className="black">Group Details</h2>
            </div>
            <div className="input-container blinker-wrapper">
              <input
                placeholder="Enter Group Name"
                value={groupTitleValue}
                onChange={(e) =>
                  groupTitleWatcher(
                    e.target.value,
                    setGroupTitleValue,
                    currentContext
                  )
                }
                onFocus={() => setGroupTitleFocused(true)}
                onBlur={() => setGroupTitleFocused(false)}
                className={`${
                  groupTitleFocused && groupTitleValue
                    ? "input-focused-value"
                    : ""
                } ${groupTitleFocused ? "focused-input" : ""} ${
                  groupTitleValue ? "input-with-value" : ""
                }`}
              />
              {groupTitleValue && (
                <button
                  className="clear-button"
                  onClick={() =>
                    clearGroupTitle(setGroupTitleValue, currentContext)
                  }
                >
                  Clear
                </button>
              )}
              {!groupTitleFocused && groupTitleValue === "" && (
                <span className="blinking-cursor blinking">|</span>
              )}
            </div>
            <div className="header">
              <h2 className="black weight700">Host Name:</h2>
            </div>
            <p>User Name Value Goes Here</p>
            <label className="black weight700">
              What are the group details?
            </label>
            <div className="input-container blinker-wrapper description">
              <input
                placeholder="Description"
                value={groupDescriptionValue}
                onChange={(e) =>
                  descriptionValueWatcher(
                    e.target.value,
                    setGroupDescriptionValue,
                    currentContext
                  )
                }
                onFocus={() => setDescriptionFocused(true)}
                onBlur={() => setDescriptionFocused(false)}
                className={`${
                  descriptionFocused && groupDescriptionValue
                    ? "input-focused-value"
                    : ""
                } ${descriptionFocused ? "focused-input" : ""} ${
                  groupDescriptionValue ? "input-with-value" : ""
                }`}
              />
              {groupDescriptionValue && (
                <button
                  className="clear-button"
                  onClick={() =>
                    clearGroupDescription(
                      setGroupDescriptionValue,
                      currentContext
                    )
                  }
                >
                  Clear
                </button>
              )}
              {!descriptionFocused && groupDescriptionValue === "" && (
                <span className="blinking-cursor blinking margtop5percent">
                  |
                </span>
              )}
            </div>
            <div className="header">
              <h2 className="black weight700">Who can see the group</h2>
            </div>
            <div>
              <div className="visibility-container">
                <div className="private-section">
                  <CustomRadioButton
                    label="Private"
                    name="visibility"
                    value="private-group"
                    checked={groupVisibility === "private"}
                    onChange={() => groupPermissionsWatcher("private")}
                  />
                  {/* <strong className="black">Private</strong> */}
                  {groupVisibility === "private" && (
                    <PrivateInviteOptions
                      showFollowers={showFollowers}
                      setShowFollowers={inviteFollowersWatcher} // Updated to use inviteFollowersWatcher
                      showFollowing={showFollowing}
                      setShowFollowing={inviteFollowingWatcher} // Updated to use inviteFollowingWatcher
                      excludeChecked={excludeChecked}
                      setExcludeChecked={inviteExcludeCheckedWatcher} // Updated to use inviteExcludeCheckedWatcher
                      followersList={followersList}
                      followingList={followingList}
                      setSelectedFollowers={inviteSelectedFollowerWatcher} // Updated to use inviteSelectedFollowerWatcher
                      selectedFollowers={selectedFollowers}
                      setSelectedFollowing={inviteSelectedFollowingWatcher} // Updated to use inviteSelectedFollowingWatcher
                    />
                  )}
                </div>
                <div className="group-section">
                  <label>
                    <CustomCheckbox
                      label="Group Members"
                      name="showGroupOptions"
                      checked={showGroupOptions}
                      onChange={() => setShowGroupOptions((prev) => !prev)}
                    />
                    <p>Members of a group that you are in</p>
                  </label>
                  {showGroupOptions &&
                    groups.map((group) => (
                      <label key={group} className="group-column">
                        <CustomCheckbox
                          key={group}
                          label={group}
                          name={group}
                          checked={!!selectedGroups[group]}
                          onChange={(e) =>
                            handleGroupSelect(group, e.target.checked)
                          }
                        />
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div>
              <CustomRadioButton
                label="Public"
                name="visibility"
                value="public-group"
                checked={groupVisibility === "public"}
                onChange={() => groupPermissionsWatcher("public")}
              />
              <p>Anyone can see the group on/off meetonup</p>
            </div>
            <div className="header">
              <h2 className="weight700 mrg-top">
                How will people attend the group?
              </h2>
            </div>
            <label>
              <CustomCheckbox
                label="In person"
                name="inPerson"
                checked={groupIsInPerson}
                onChange={groupIsInPersonWatcher}
              />
            </label>
            {groupIsInPerson && (
              <>
                <div className="input-container blinker-wrapper">
                  <input
                    placeholder={
                      groupCityError ? groupCityError : "Enter City Here"
                    }
                    className={groupCityError ? "required-message" : ""}
                    value={groupCityValue}
                    onChange={(e) =>
                      groupCityWatcher(
                        e.target.value,
                        setGroupCityValue,
                        currentContext,
                        groupCityError,
                        setGroupCityError
                      )
                    }
                    onFocus={() => setCityFocused(true)}
                    onBlur={() => setCityFocused(false)}
                  />
                  {groupCityValue && (
                    <button
                      className="clear-button"
                      onClick={() =>
                        clearGroupCity(setGroupCityValue, currentContext)
                      }
                    >
                      Clear
                    </button>
                  )}
                  {!cityFocused && groupCityValue === "" && (
                    <span className="blinking-cursor blinking">|</span>
                  )}
                </div>
                <div className="input-container blinker-wrapper">
                  <input
                    placeholder={
                      groupZipCodeError
                        ? groupZipCodeError
                        : "Enter Zip Code Here"
                    }
                    className={groupZipCodeError ? "required-message" : ""}
                    value={groupZipCodeValue}
                    onChange={(e) =>
                      groupZipCodeWatcher(e.target.value, "groupZipCodeValue")
                    }
                    onFocus={() => setZipCodeFocused(true)}
                    onBlur={() => setZipCodeFocused(false)}
                  />
                  {groupZipCodeError &&
                    groupZipCodeError !== "ZipCode is required" && (
                      <div className="error-message">{groupZipCodeError}</div>
                    )}
                  {groupZipCodeValue && (
                    <button
                      className="clear-button"
                      onClick={() =>
                        clearGroupZipCode(setGroupZipCodeValue, currentContext)
                      }
                    >
                      Clear
                    </button>
                  )}
                  {!zipCodeFocused && groupZipCodeValue === "" && (
                    <span className="blinking-cursor blinking">|</span>
                  )}
                </div>
                <StateDropdown
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  clearState={clearState}
                  isOpen={isStateDropDownOpen}
                  currentContext={currentContext}
                  onToggle={setStateDropDownOpen}
                />
                {selectedStateError && (
                  <div className="error-message">{selectedStateError}</div>
                )}
              </>
            )}
            <label>
              <CustomCheckbox
                label="Remotely"
                name="RemoteGroup"
                checked={groupIsRemote}
                onChange={groupRemoteCheckWatcher}
              />
            </label>
            <div>
              <WhiteGoldButton
                onClick={() => setShowManageGroupOptions((prev) => !prev)}
                // Toggle the visibility on button click
                whiteGoldBtnText="Manage Group Settings"
                whiteGoldBtnFontColor="#964B00"
                whiteGoldBtnWidth="fit-content"
                whiteGoldBtnFontWeight="800"
                whiteGoldBtnFontFamily="roboto-slab"
                whiteGoldBtnTextFontSize="1.3rem"
              />
              {showManageGroupOptions && (
                <div>
                  <ToggleSlider
                    defaultPosition={isGroupInviteManager ? "right" : "left"}
                    handleChangeSlider={guestIsGroupInviteManagerWatcher}
                    activeContent="Yes"
                    inactiveContent="No"
                    checked={isGroupInviteManager}
                    showGuestInviteText={true}
                    sliderWrapperStyle={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      left: "43%",
                      gap: "33px",
                    }}
                    sliderTextStyle={{
                      textAlign: "center", // Center the text
                    }}
                  />
                </div>
              )}
              {/* FileUploadButton Component */}
              <FileUploadButton
                onFileChange={handleFileChange} // Replace with your file change handler
                fileUploadError={fileUploadError} // Replace with your file upload error state
              />
              {/* Add the SlideBtn component here, at the bottom of your popup content */}
              <SlideBtn
                slideBtnText={
                  updateGroup
                    ? "Slide to Update Group"
                    : "+ Slide to Create Group"
                }
                slideBtnWidth={updateGroup ? "250px" : "fit-content"}
                slidBtnFontSize="1rem"
                marginLeft={updateGroup ? "22%" : "20%"}
                sliderImage={() => (
                  <CommunityIcon
                    fillColor="#964B00"
                    width="40px"
                    height="30px"
                  />
                )}
                style={{
                  width: "200px",
                  border: "1px solid rgba(0, 0, 0, 0.6)",
                }}
                onHandleSlideEnd={(ev) => {
                  if (updateGroup) {
                    onUpdateGroup(ev, contextType); // Assuming you have an onUpdateGroup function
                  } else {
                    onCreateGroup(ev, contextType);
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupPopup;
// const sendDataToBackend = async (data) => {
//   const response = await fetch('https://your-backend-api.com/submit-group', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data)
//   });

//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }

//   return response.json(); // Or handle the response as needed
// };

// useEffect(() => {
//   if (createGroupAttempted) {
//     const { errors } = createGroupErrorHandler(
//       groupZipCodeValue,
//       groupCityValue,
//       selectedState,
//       groupIsInPerson,
//       currentContext,
//       setGroupZipCodeError,
//       setGroupCityError,
//       setSelectedStateError
//     );
//     console.log("Errors received:", errors);

//     if (Object.keys(errors).length > 0) {
//       // Log the error and scroll to the top of the form
//       console.log("Form submission stopped due to errors:", errors);
//       if (topOfGroupFormRef && topOfGroupFormRef.current) {
//         topOfGroupFormRef.current.scrollIntoView({ behavior: "smooth" });
//       }
//     } else {
//       // No errors, proceed with submitting data to backend
//       submitGroupDataToBackend().then(() => {
//         // After successful backend submission, reset the form
//         resetGroupForm();
//       }).catch(error => {
//         // Handle any errors from the backend submission
//         console.error("Error submitting group data:", error);
//         // Optionally update the UI to reflect the error
//       });
//     }

//     setCreateGroupAttempted(false); // Reset for next submission attempt
//   }
// }, [
//   createGroupAttempted,
//   // ... other dependencies ...
// ]);

// const submitGroupDataToBackend = async () => {
//   // Implement the logic to submit data to the backend
//   // This function should return a Promise
//   // For example:
//   return sendDataToBackend({
//     groupZipCodeValue,
//     groupCityValue,
//     selectedState,
//     // ... other form data ...
//   });
// };

// In your current setup, the file collected for the group profile picture is stored in the file state. This is appropriate for handling file uploads in React. When you're ready to send your form data to the backend, including the file, you'll need to include the file state value along with your other form data.

// Here's how you can modify your form submission logic to include the file:

// Include File in Backend Submission Logic
// When you're ready to submit your data to the backend, you'll typically use a FormData object to include the file along with other form data. This is especially relevant if you're sending the data via a multipart/form-data request, which is common for file uploads.

// Here's an example of how you might prepare your data for submission, including the file:
// const submitGroupData = async () => {
//   if (createGroupAttempted && !fileUploadError) {
//     const formData = new FormData();
//     formData.append('zipCode', groupZipCodeValue);
//     formData.append('city', groupCityValue);
//     formData.append('state', selectedState);
//     // Add other form fields as needed

//     if (file) {
//       formData.append('profilePicture', file); // Add the file to the form data
//     }

//     try {
//       const response = await fetch('your-backend-endpoint', {
//         method: 'POST',
//         body: formData,
//         // Don't set 'Content-Type' header, the browser will set it with the correct boundary
//       });
//       if (response.ok) {
//         // Handle successful submission
//         resetGroupForm();
//       } else {
//         // Handle errors
//       }
//     } catch (error) {
//       // Handle network or other errors
//     }
//   }
// };
