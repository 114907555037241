import Faq from "./faq";
import RedemptionBenefits from "./redemptionBenefits";
import RewardLevels from "./rewardLevels.jsx";
import DashboardSlider from "./slider";
import "./dashBoard.scss";

// passing the state intialized on the parent component for intial state that is set to 0 so it can be updated globally within this component
const DashboardPage = ({
  pointsBalance,
  setPointsBalance,
  giftCardBalance,
  setGiftCardBalance,
}) => {
  return (
    <div>
      <DashboardSlider
        pointsBalance={pointsBalance}
        setPointsBalance={setPointsBalance}
        giftCardBalance={giftCardBalance}
        setGiftCardBalance={setGiftCardBalance}
      />
      <RewardLevels
        pointsBalance={pointsBalance}
        setPointsBalance={setPointsBalance}
      />
      <RedemptionBenefits />
      <Faq />
    </div>
  );
};
export default DashboardPage;
