import React, { useState, useEffect } from "react";
import "./toggleSlider.scss";

const ToggleSlider = ({
  leftLabel = "",
  rightLabel = "",
  defaultPosition = "right",
  handleChangeSlider,
  onChange,
  className = "", // Set a default empty string here
  checked,
  activeContent = "ON", // default value if not provided
  inactiveContent = "OFF", // default value if not provided
  showGuestInviteText = false,
  sliderWrapperStyle={},
  sliderTextStyle={},
}) => {
  const [active, setActive] = useState(defaultPosition === "right");

  const handleClick = () => {
    const newActiveState = !active;
    setActive(newActiveState);
    if (handleChangeSlider) {
      handleChangeSlider(newActiveState ? "right" : "left");
    }
  };
  


  useEffect(() => {
    if (checked !== undefined) {
      setActive(checked);
    }
  }, [checked]);

  return (
    <div className={`slider-wrapper ${className}`} style={sliderWrapperStyle}>
      {showGuestInviteText && (
        <p style={sliderTextStyle}>Guests can invite others?</p>
      )}
      <div>
        <label className="checkbox-slider">
          <span>{active ? rightLabel : leftLabel}</span>
          <input type="checkbox" onChange={handleClick} checked={active} />
          <div className={`inner ${active ? "active" : ""}`}>
            <div
              className="toggle"
              data-content={active ? activeContent : inactiveContent}
            />
          </div>
        </label>
      </div>
    </div>
  );
};
export default ToggleSlider;
