import React from "react";
import { PlusSign } from "../../icons";
import "./createPostCircle.scss";

const CreatePostCircle = ({
  onClick,
  text,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  borderWidth,
  borderColor,
  borderRadius,
}) => {
  const wrapperStyle = {
    borderWidth: borderWidth,
    borderColor: borderColor,
    borderStyle: borderWidth ? "solid" : "none", // Apply border style only if borderWidth is provided
    borderRadius: borderRadius,
  };

  return (
    <div className="create-post-wrapper" onClick={onClick} style={wrapperStyle}>
      <div className="create-post-circle">
        <PlusSign height="20px" width="20px" fillColor="#964B00" />
      </div>
      {text && (
        <span
          className="create-post-text"
          style={{
            color: color,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontWeight: fontWeight,
          }}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default CreatePostCircle;
