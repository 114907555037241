// Action types
export const INCREASE_POINTS_BALANCE = "INCREASE_POINTS_BALANCE";
export const DECREASE_POINTS_BALANCE = "DECREASE_POINTS_BALANCE";

// Action creators
export const increasePointsBalance = (data) => ({
  type: INCREASE_POINTS_BALANCE,
  payload: data,
});
export const decreasePointsBalance = (data) => ({
  type: DECREASE_POINTS_BALANCE,
  payload: data,
});
