import mockData from "../../seedMockData/seedMockData";
import ReceiptCard from "../../components/receiptCard/receiptCard";
import "./receiptsInvoicesPage.scss";

// can't use default with a const and need to set it up this way to invoke the logginuser
const ReceiptInvoicesListPage = () => {
  console.log("Rendering ReceiptInvoicesListPage component");

  const userId = "user-uuid-1";
  // id property and invoices have a userId property.
  // When you're trying to find a user from the users array
  // by their id, you would compare u.id to the userId you're
  // looking for. Here, u is just a temporary variable representing
  // each user in the array as find iterates over them
  const user = mockData.users.find((u) => u.id === userId); // Note: It should be u.id if your mock data users have 'id' property
  // Changed find to filter for invoices to return all invoices for a user
  console.log("Found user:", user);

  const invoice = mockData.invoiceReceipt.filter(
    (inv) => inv.userId === userId
  );
  console.log("Found invoices:", invoice);

  return (
    <div id="approved">
      <p className="paddingreceipt">add</p>
      <div className="receipt-list-container">
        {invoice.map((receipt) => (
          <ReceiptCard key={receipt.id} receipt={receipt} />
        ))}
      </div>
    </div>
  );
};
export default ReceiptInvoicesListPage;

//   find:
//   It returns the first element in the array that satisfies the provided testing function.
//   If no elements satisfy the testing function, it returns undefined.
//   It's useful when you're searching for a single item and you know there's either one or no item that matches the criteria.

// filter:
// It returns a new array containing all the elements that satisfy the provided testing function.
// If no elements satisfy the testing function, it returns an empty array [].
// It's useful when you expect multiple items to match the criteria, or when you want to filter out items based on certain conditions.
// const arr = [1, 2, 3, 4, 5];
// const result = arr.filter(item => item > 3);
// console.log(result); // Outputs: [4, 5] (because both 4 and 5 are greater than 3)
