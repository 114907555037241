import mockData from "../../seedMockData/seedMockData.js";
import { clearReplyStateAndStorage } from "../watcherAndClearFunctions/commentClearsWatchersFunctions.js";
// // Function to handle when a user clicks on reply
export const startThreadReply = (
  comment,
  startThread,
  setActiveReply,
  setStartThread // Assuming setStartThread is passed as a parameter
) => {
  const newThreadId = generateNewThreadId();
  const newAutoIndex = 1; // Starting index for a new thread

  setActiveReply({
    commentId: comment.id,
    threadId: newThreadId,
    autoIndex: newAutoIndex,
    username:
      mockData.users.find((user) => user.id === comment.sourceId)?.username ||
      "Unknown User",
  });

  console.log("Start Thread State: ", startThread); // Ensure startThread is being used correctly
  setStartThread(true);
  // // Function to handle when a user clicks to join a thread or start a new one

  function generateNewThreadId() {
    // Implement your logic to generate a unique thread ID
    return "new-thread-" + Date.now(); // Example implementation
  }
};

export const onThreadReply = (
  userId,
  threadId,
  autoIndex,
  currentContext,
  activeReply,
  setReplyValue,
  setActiveReply,
  setThreadReply
) => {
  const newReplyValue = clearReplyStateAndStorage(currentContext, activeReply);
  setReplyValue(newReplyValue); // Reset the reply value state

  const username =
    mockData.users.find((user) => user.id === userId)?.username ||
    "Unknown User";

  setActiveReply({
    threadId: threadId,
    autoIndex: autoIndex,
    username: username,
  });

  setThreadReply(true);
};
export const onJoinThread = (comment, setActiveReply, setJoinThread) => {
  const threadId = comment.threads[0].id;

  // Find the highest autoIndex in the thread
  const highestAutoIndex = comment.threads.reduce(
    (max, thread) => Math.max(max, thread.autoIndex),
    0
  );
  // Increment by 1 for the new reply
  const newAutoIndex = highestAutoIndex + 1;
  setActiveReply({
    commentId: comment.id,
    threadId: threadId,
    autoIndex: newAutoIndex,
    username:
      mockData.users.find((user) => user.id === comment.sourceId)?.username ||
      "Unknown User",
  });

  setJoinThread(true);
};
