import React from 'react';
import "./radioHead.scss"; // assuming you have your styles in a separate SCSS file

const RadioHeadBtn = ({ label, name, value, checked, onChange }) => {
    return (
        <div className="RadioHeadWrapper">
        <div className="radioHead">  {/* <-- Add this .radioHead class */}

        <input 
                type="radio" 
                id={value} 
                name={name} 
                value={value} 
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={value}>{label}</label>
        </div>
        </div>
    );
};

export default RadioHeadBtn;
