import React, { useState, useEffect } from "react";
import "./currentDayTime.scss";

const CurrentDayTime = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    // Update the date state every second
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div id="current-day-calendar">
      <section className="month-section">
        <div className="month-name">
          {date.toLocaleString("default", { month: "short" })}
        </div>
      </section>
      <section className="day-section">
        <div className="day-as-text">
          {date.toLocaleString("default", { weekday: "long" })}
        </div>
        <div className="day-as-number">{date.getDate()}</div>
      </section>
    </div>
  );
};
export default CurrentDayTime;
