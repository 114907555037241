import { selectAuth } from "../redux/selector/auth.js";

// Constants representing the initial state of various types of data requests
export const INITIAL_BLANK_REQUEST = { errors: undefined, loading: false };
export const INITIAL_PLAIN_DATA_REQUEST = {
  data: undefined,
  ...INITIAL_BLANK_REQUEST,
};
export const INITIAL_PLANT_DATA_ARRAY = {
  data: [],
  ...INITIAL_BLANK_REQUEST,
};
export const INITIAL_PLANT_DATA_STRING = {
  data: '',
  ...INITIAL_BLANK_REQUEST,
};
export const INITIAL_PLANT_DATA_OBJECT = {
  data: {},
  ...INITIAL_BLANK_REQUEST,
};


// Function to extract the authentication token from the state
export function getToken(state) {
  const auth = selectAuth(state);
  return auth?.data?.token;
}

// Function to build HTTP headers using the token
export function buildHeadersFromToken(token) {
  return {
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : undefined,
  };
}

// Deprecated function to build HTTP headers using the state. 
// It's advised to use 'getToken' and 'buildHeadersFromToken' 
// individually as needed instead of using this function.
export function buildHeaders(state) {
  const token = getToken(state);
  return buildHeadersFromToken(token);
}

