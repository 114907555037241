import React from "react";
import { SlideBtn } from "../index.jsx";
import ChatAnimation from "../../chatAnimation/chatAnimation.jsx";
import { CommunityIcon } from "../../icons";
import "./createGroupSlider.scss";

const CreateGroupSlider = ({
  onHandleSlideEnd,
  groupFormOpen, // state is set on the groups list page
  togglePopup,
  groupPopup,
  hideChatOnSmallScreen = true, // Default value is true, meaning hide on small screens
  className, // class name prob to control the parent div

}) => {
  const chatAnimationClass = hideChatOnSmallScreen ? "hide-chat-animation" : "";

  const handleSlideEnd = () => {
    togglePopup();
    onHandleSlideEnd();
  };
  return (
    <>
      <div className={`groups-btn ${className}`}> {/* Use the className prop */}
        <div className={chatAnimationClass}>
          <ChatAnimation />
        </div>

        <SlideBtn
          slideBtnText="+ Create group"
          marginLeft="0px"
          slidBtnFontSize="1rem"
          slideBtnWidth="180px"
          sliderImage={() => (
            <CommunityIcon fillColor="#964B00" width="20px" height="20px" />
          )}
          onClick={togglePopup}
          onHandleSlideEnd={handleSlideEnd}
          className="creatGroupSlider" // Pass your custom class here

        />
      </div>
      {groupFormOpen && (
        <div>
          <div
            className={`overlay ${groupFormOpen ? "active" : ""}`}
            onClick={togglePopup}
          ></div>
          <div className="popup">{groupFormOpen && groupPopup}</div>
        </div>
      )}
    </>
  );
};

export default CreateGroupSlider;
