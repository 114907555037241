import { SET_RECEIPT, CLEAR_RECEIPT } from "../actions/actionReceipt.js";

const initialState = {};
const receiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECEIPT:
        return {
            ...state,
            ...action.payload,
        };
    case CLEAR_RECEIPT:
        return {};
    default:
      return state;
  }
};

export default receiptReducer;