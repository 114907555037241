import React, { useState, useEffect, useRef } from "react";
import UserPost from "../../components/userPost/userPost";
import CreatePost from "../../components/userPost/createPost";
import mockData from "../../seedMockData/seedMockData";
import { SlideBtn, GlassBtn } from "../../components/buttons";
import PostForm from "../../components/forms/postForm/post.jsx";
import { FindUsername } from "../../components/icons";
import Connections from "../../components/connections/connections";
import { useUserProfile } from "../../context/userProfileContext.jsx";
import { Posts, Members, Filter } from "../../components/icons";
import { debounce } from "../../utils/searchDebounceUtils";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import CommunityPadMobileSearch from "./communityPadMobileSearch";
import "./communityPage.scss";

const CommunityPage = ({ profileThumbnail }) => {
  const { userProfileInfo } = useUserProfile();
  const currentContext = "community";
  const [allUsers, setAllUsers] = useState(mockData.users); // grabbing all users
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [onUserNameSearchValueFocused, setOnUserNameSearchValueFocused] =
    useState(false);
  const [onUserNameSearchValueBlur, setOnUserNameSearchValueBlur] =
    useState(false);
  const [userNameValueSearchCriteria, setUserNameValueSearchCriteria] =
    useState("");
  const [userNameSearchError, setUserNameSearchError] = useState(false);
  const [isSliderSlid, setIsSliderSlid] = useState(false); // error handling state and showing buttons logic
  const [showPostForm, setShowPostForm] = useState(false);
  const [followers, setFollowers] = useState([]); // used to grab the followers from backend
  const [following, setFollowing] = useState([]); // used to grab the following from backend
  const [view, setView] = useState("all"); // used for the button indicator and view type such as post, members, followers and following
  const [showFollowOptions, setShowFollowOptions] = useState(false);
  const buttonsContainerRef = useRef(null); // reference to show button when the slider is slid on the desktop
  console.log(buttonsContainerRef); // Should not log `null` values during/after rendering
  const usersWithPosts = allUsers
    .map((user) => {
      // Find the first post associated with the user
      const userPost = mockData.posts.find((post) => post.userId === user.id);

      // Add the postId and post's imagePath to the user object, but keep the user's imagePath separate
      if (userPost) {
        return {
          ...user,
          postId: userPost.id,
          postImagePath: userPost.imagePath, // Separate field for post's image
        };
      }
      return null;
    })
    .filter((user) => user !== null);
  // Ensure only users with posts are included
  const handleViewMore = () => {
    setPaginationLimit((prevLimit) => prevLimit + 10);
  };
  // these functions will get connections and show members or member post along with follow option buttons so more filters to see followers or following when user clicks on members and hides it for other buttons
  const showAll = () => {
    setView("all");
    setShowFollowOptions(false); // Hide follow options buttons for all other views
  };
  const handleShowUserPosts = () => {
    setView("posts");
    setShowFollowOptions(false); // Hide follow options buttons for all other views
  };
  const handleShowMembers = () => {
    setView("members");
    setShowFollowOptions(true); // Show follow options buttons when members are clicked
  };

  const handleShowFollowersList = () => {
    setView("followers");
  };
  const handleShowFollowingList = () => {
    setView("following");
  };
  // Post Toggle functions
  const handleOpenPostForm = () => setShowPostForm(true);
  const handleClosePostForm = () => setShowPostForm(false);

  const handleUserNameChange = (value) => {
    setUserNameValueSearchCriteria(value);
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.setItem(userNameKey, value);
    if (userNameSearchError) {
      setUserNameSearchError("");
    }
  };
  const handleSlideCompletion = () => {
    const userNameResults = mockData.users.filter((user) =>
      user.username
        .toLowerCase()
        .includes(userNameValueSearchCriteria.toLowerCase())
    );
    if (userNameValueSearchCriteria.length !== 0) {
      setIsSliderSlid(true);
      setAllUsers(userNameResults);
    } else {
      setIsSliderSlid(false);
      const userNameSearchError = "Required";
      setUserNameSearchError(userNameSearchError);
    }
  };

  // Fetch followers and following data
  useEffect(() => {
    // Fetch and set followers and following here
    // For now, using mock data
    const fetchedFollowers = mockData.users; // Replace with actual data fetch logic
    const fetchedFollowing = mockData.users; // Replace with actual data fetch logic

    setFollowers(fetchedFollowers);
    setFollowing(fetchedFollowing);
  }, []);
  useEffect(() => {
    if (!userNameValueSearchCriteria && isSliderSlid) setIsSliderSlid(false);
  }, [userNameValueSearchCriteria]);
  const debouncedHandleSlideCompletion = debounce(handleSlideCompletion, 300);

  const clearUserNameValueSearchCriteriaLocalStorage = () => {
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.removeItem(userNameKey);
    setUserNameValueSearchCriteria(""); // Reset the state
  };

  return (
    <div id="community-page">
      <div id="communnitywrapper">
        <section id="search-bar-wrapper" className="underlay">
          <div id="listing"></div>
          <div className="input-container">
            <input
              type="text"
              value={userNameValueSearchCriteria}
              onChange={(e) =>
                handleUserNameChange(e.target.value, "userNameValue")
              }
              onFocus={() => setOnUserNameSearchValueFocused(false)}
              onBlur={() => setOnUserNameSearchValueBlur(false)}
              placeholder={
                userNameSearchError ? userNameSearchError : "Enter username"
              }
              className={userNameSearchError ? "required-message" : ""}
            />
            {userNameValueSearchCriteria && (
              <button
                className="Clear-communitypg"
                onClick={clearUserNameValueSearchCriteriaLocalStorage}
                style={{
                  color: "#fdfd96",
                  backgroundColor: "transparent",
                  borderColor: "#fdfd96",
                }}
              >
                Clear
              </button>
            )}
            {!onUserNameSearchValueFocused &&
              userNameValueSearchCriteria === "" && (
                <span className="blinking-cursor blinking community-username-input-cursor ">
                  |
                </span>
              )}
            <SlideBtn
              sliderImage={() => <FindUsername fillColor="#964B00" />}
              slideBtnText="Slide to find username"
              onHandleSlideEnd={debouncedHandleSlideCompletion}
            ></SlideBtn>
          </div>
          {/* {isSliderSlid && (  removing this feature for now, when user slides the slider buttons will show. Testing user response for when they always show*/}
          <div className="buttons-container" ref={buttonsContainerRef}>
            <GlassBtn
              glassBtnImage={
                <Filter
                  height="25px"
                  width="25px"
                  fillColor={view === "all" ? "#fff" : "#fdfd96"} // Dynamic fillColor
                />
              }
              glassBtnTextFontSize="1.3rem"
              glassBtnText="All"
              glassBtnFontWeight="900"
              glassBtnFontColor={view === "all" ? "#fff" : "#fdfd96"}
              glassBtnTextDecorationColor={view === "all" ? "#fff" : "#fdfd96"}
              onClick={showAll}
              className={view === "all" ? "active-button" : "inactive-button"}
            />
            <GlassBtn
              glassBtnImage={
                <Posts
                  height="25px"
                  width="25px"
                  fillColor={view === "posts" ? "#fff" : "#e2b645"} // Dynamic fillColor
                />
              }
              className={view === "posts" ? "active-button" : "inactive-button"}
              glassBtnText="Posts"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="900"
              glassBtnFontColor={view === "posts" ? "#fff" : "#e2b645"}
              glassBtnTextDecorationColor={
                view === "posts" ? "#fff" : "#e2b645"
              }
              onClick={handleShowUserPosts}
            />
            <GlassBtn
              glassBtnImage={
                <Members
                  height="25px"
                  width="25px"
                  fillColor={view === "members" ? "#fff" : "#fdfd96"} // Dynamic fillColor
                />
              }
              className={
                view === "members" ? "active-button" : "inactive-button"
              }
              glassBtnText="Members"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="900"
              glassBtnFontColor={view === "members" ? "#fff" : "#fdfd96"}
              glassBtnTextDecorationColor={
                view === "members" ? "#fff" : "#fdfd96"
              }
              onClick={handleShowMembers}
            />
          </div>
          {/* )} */}
          {/* Conditionally render Followers and Following buttons */}
          {showFollowOptions && (
            <>
              <div className="followOption-container">
                <GlassBtn
                  glassBtnImage={
                    <div>
                      <span
                        className={
                          view === "followers"
                            ? "followersNumDskCommunity-active"
                            : "followersNumDskCommunity-inactive"
                        }
                      >
                        {/* followers is added to check if it is undefined before rendering it since some may not have any */}
                        {followers && followers.length}
                      </span>
                    </div>
                  }
                  onClick={handleShowFollowersList}
                  glassBtnText="Followers"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="900"
                  glassBtnHeight="35px"
                  className={
                    view === "followers" ? "active-button" : "inactive-button"
                  }
                  glassBtnFontColor={view === "followers" ? "#fff" : "#fdfd96"}
                  glassBtnTextDecorationColor={
                    view === "followers" ? "#fff" : "#fdfd96"
                  }
                />
                <GlassBtn
                  glassBtnImage={
                    <div>
                      <span
                        className={
                          view === "following"
                            ? "followingNumDskCommunity-active"
                            : "followingNumDskCommunity-inactive"
                        }
                      >
                        {/* following is added to check if it is undefined before rendering it since some may not have any */}
                        {following && following.length}
                      </span>
                    </div>
                  }
                  onClick={handleShowFollowingList}
                  glassBtnText="Following"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="900"
                  glassBtnHeight="35px"
                  className={
                    view === "following" ? "active-button" : "inactive-button"
                  }
                  glassBtnFontColor={view === "following" ? "#fff" : "#e2b645"}
                  glassBtnTextDecorationColor={
                    view === "following" ? "#fff" : "#e2b645"
                  }
                />
              </div>
            </>
          )}
        </section>
        <CommunityPadMobileSearch
          onShowPosts={handleShowUserPosts}
          onShowMembers={handleShowMembers}
          onUserNameSearchValueFocused={onUserNameSearchValueFocused}
          onShowAll={showAll}
          setUserNameValueSearchCriteria={setUserNameValueSearchCriteria}
          handleUserNameChange={handleUserNameChange}
          onShowFollowersList={handleShowFollowersList}
          onShowFollowingList={handleShowFollowingList}
          userNameSearchError={userNameSearchError}
          setUserNameSearchError={setUserNameSearchError}
          setAllUsers={setAllUsers}
          setOnUserNameSearchValueFocused={setOnUserNameSearchValueFocused}
          setOnUserNameSearchValueBlur={setOnUserNameSearchValueBlur}
          userNameValueSearchCriteria={userNameValueSearchCriteria}
          clearUserNameValueSearchCriteriaLocalStorage={
            clearUserNameValueSearchCriteriaLocalStorage
          }
          currentContext={currentContext}
          view={view}
          allUsers={allUsers}
          showFollowOptions={showFollowOptions} // pass the state to show following and followers when members is clicked from this component
          followers={followers} // showing total count of followers for current user that was passed in from this community page to this component
          following={following} // showing total count of following for current user that was passed in from this community page to this component
        />
        {/* Render User Posts */}
        {view === "all" || view === "posts" ? (
          <>
            <section className="user-posts">
              <CreatePost
                isCreatePostOnlyOnDesktop={true} // hiding on screen width <812px (small screens) they will create a post a different way
                userData={userProfileInfo}
                onCreatePostSlidEnd={handleOpenPostForm}
              />
              {showPostForm && <PostForm onClose={handleClosePostForm} />}
              {usersWithPosts.slice(0, paginationLimit).map((user) => {
                // Find the post associated with the user
                const userPost = mockData.posts.find(
                  (post) => post.userId === user.id
                );
                // Find comments related to the user's post
                // Find the comments and threads related to the user's post
                const userComments = mockData.comments
                  .filter((comment) => comment.postId === user.postId)
                  .map((comment) => {
                    // Find threads for each comment
                    const threads = mockData.commentThreads
                      .filter((thread) => thread.id === comment.threadId)
                      .sort((a, b) => b.autoIndex - a.autoIndex); // Sort by autoIndex

                    return { ...comment, threads };
                  });
                console.log(
                  "Comments and threads for user:",
                  user.username,
                  userComments
                );
                return (
                  <UserPost
                    key={user.id}
                    username={user.username}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profileThumbnail={user.imagePath} // Profile picture of the user
                    media={user.postImagePath} // Correct media associated with the user's post
                    postId={user.postId}
                    comments={user.Comments} // Assuming this is also included in the user object
                  />
                );
              })}
              {usersWithPosts.length > paginationLimit && (
                <GlassBtn glassBtnText="View More" onClick={handleViewMore} />
              )}
            </section>
          </>
        ) : null}
        {/* Render Member Connections */}
        {view === "all" || view === "members" ? (
          <>
            <section className="connections-list">
              <h4 className="MemHeader">Slide right to view members profile</h4>
              {allUsers.slice(0, paginationLimit).map((user) => (
                <Connections
                  key={user.id}
                  username={user.username}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  profileThumbnail={user.imagePath}
                />
              ))}
              {allUsers.length > paginationLimit && (
                <GlassBtn glassBtnText="View More" onClick={handleViewMore} />
              )}
            </section>
          </>
        ) : null}
        {view === "followers" && (
          <div className="followers-list">
            <h2>Followers</h2>
            {followers.map((user) => (
              <Connections
                key={user.id}
                username={user.username}
                firstName={user.firstName}
                lastName={user.lastName}
                profileThumbnail={user.imagePath}
              />
            ))}
          </div>
        )}
        {view === "following" && (
          <div className="following-list">
            <h2>Following</h2>
            {following.map((user) => (
              <Connections
                key={user.id}
                username={user.username}
                firstName={user.firstName}
                lastName={user.lastName}
                profileThumbnail={user.imagePath}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default CommunityPage;

// steps to get user data
// will need the import to invoke the code below
// path to where where fetchUserData is defined
// import { fetchUserData } from './path_to_your_function';
// example code and the api invoking will be in the api folder calling the endpoints
// export const fetchUserData = async () => {
//   const endpoint = "https://your-api-url.com/userdata"; // replace with your actual API endpoint

//   try {
//       const response = await fetch(endpoint);
//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const data = await response.json();
//       return data;
//   } catch (error) {
//       console.error("There was a problem fetching the user data:", error);
//       throw error; // or return some default/error data
//   }
// };

// in the component before the return
// set state
// const [userData, setUserData] = useState(null);
// Fetch userData from backend or context or wherever the logged-in user's data is.
// Example using the mock fetchUserData function.
// Replace this with the actual data fetching method.
// const fetchUserData = async () => {
//   const response = await fetch('YOUR_API_ENDPOINT_HERE');
//   const data = await response.json();
//   return data;
// };

// useEffect(() => {
//   async function fetchData() {
//     const data = await fetchUserData(); // Replace this with the actual function.
//     setUserData(data);
//   }

//   fetchData();
// }, []);
// lastly in the return
{
  /* uncomment below when the signup works */
}
{
  /* <CreatePost profile={userData?.profile} username={userData?.username} /> */
}

// function CommunityPage() {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const fetchPosts = async () => {
//       const data = await fetchCommunityPosts();
//       setPosts(data);
//     };

//     fetchPosts();
//   }, []);

//   return (
//     <div>
//       {posts.map(post =>
//         <PostComponent key={post.postId} {...post} />
//       )}
//     </div>
//   );
// }

{
  /* create post is a static set up, below it is dynamic ready 
        when signup is ready remove the static code and uncomment out the dynamic code */
}

{
  /* uncomment below when the signup works */
}
{
  /* <CreatePost profile={userData?.profile} username={userData?.username} /> */
}
