import React  from 'react';
import {SlideBtn} from "../index.jsx";
import CurrentDayTime from '../../currentDayTime/currentDayTime';
import { CommunityIcon } from '../../icons';
import "./createEventBtn.scss"


const CreateEventBtn = ({ onHandleSlideEnd, eventFormOpen, togglePopup, eventPopup, className }) => {
  const handleSlideEnd = () => {
    togglePopup();
    onHandleSlideEnd();
  };
  return (
    <div>
      <div className="events-btn">
        <CurrentDayTime/>
        <SlideBtn
          slideBtnText="Create Event"
          slideBtnWidth="fit-content"
          slidBtnFontSize=".75rem"
          sliderImage={() => <CommunityIcon fillColor="#964B00" width="20px" height="20px" />}
          style={{
            width: '200px',
            border: '1px solid rgba(0, 0, 0, 0.6)',
            paddingLeft:"20px",
            paddingRight:"20px",
          }}
          onClick={togglePopup}
          onHandleSlideEnd={handleSlideEnd}
        />
      </div>
      {eventFormOpen && (
        <div>
          <div className={`overlay ${eventFormOpen ? "active" : ""}`} onClick={togglePopup}></div>
          <div className="popup">
            {eventFormOpen && eventPopup}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEventBtn;
