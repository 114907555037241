// import { POINTS_AND_EARNINGS } from './dashBoardWrapper';
import { POINTS_AND_EARNINGS } from '../../constants/rewardPoints.js';
import RewardStatus from '../../components/rewardsCurrencyStatus/currentRewardStatus';
// import CirclePoints from "../../components/rewardsCircle/rewardsCircle"; uncomment to test with dashboard to make sure it is correct

const RewardLevels = ({ pointsBalance }) => {
  console.log("Points balance in RewardLevels:", pointsBalance);

  return (
    <section className="reward-levels">
            <div className="member-reward-status-card-div">
          <div className="member-reward-status-card">
            <h3>Reward Status Indicator</h3>
            <div className="statusShow">
            <RewardStatus pointsBalance={pointsBalance} />
            {/* <CirclePoints pointsBalance={pointsBalance}  /> */}
              {/* <PointsCircle /> */}
            </div>
          </div>
        </div>
      <div className="reward-level-flex">
        <div className="points">
          <div className="levels">
            <h1>Reward Levels</h1>
            {POINTS_AND_EARNINGS.map((point, index) => (
              <div key={index}>
                <p className="nameOflevel">
                  <img src={point.icon} alt="" />
                  <span>{point.title}</span>
                </p>
                <p>{point.points} points</p>
              </div>
            ))}
         </div>
          <div className="earnings">
            <h1>Earnings</h1>
            {POINTS_AND_EARNINGS.map((earning, index) => (
              <div key={index}>
                <p className="nameOflevel">
                  <img src={earning.icon} alt="" />
                  <span className="highliter">{earning.title}</span>
                </p>
                <p>{earning.earnings} x points per $1.00</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default RewardLevels;
