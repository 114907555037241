import React, { useState, useMemo, useEffect, useRef } from "react";
import PaymentsForm from "../../components/forms/payments/payments";
import DashBoardSection from "./miniDashBoard";
import PlaceOrderSummary from "./placeOrderSummary";
import InvoiceModal from "./inVoiceModal";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import {
  getCurrentRewardLevel,
  getCurrentRewardLevelName,
} from "../../components/constant/getCurrentRewardLevel.js";

import "./paymentMethods.scss";

// this file is used for state management and logic for all the payment components
// it is not prop drilling as most of it is just parent child except for maybe one or two
// this is called lifting up state and centeralizing commmunication which is more managable and efficient for scale and cost
export default function PaymentsMethodsPage({
  pointsBalance, // passed from the dashboard page that will be used in all components this file will manage state and other things for all of them
  setPointsBalance, // passed from the dashboard page that will be used in all components this file will manage state and other things for all of them
  giftCardBalance, // passed from the dashboard page that will be used in all components this file will manage state and other things for all of them
  setGiftCardBalance, // passed from the dashboard page that will be used in all components this file will manage state and other things for all of them
}) {
  const tierSectionRef = useRef(null);
  const paymentDetailsRef = useRef(null);
  const currentContext = "payments";
  const [selectedTierPrice, setSelectedTierPrice] = useState(0); // initializing state here to be passed to the payments form
  const [tierError, setTierError] = useState(false); // error state handler set on the parent for if no tier price is selected
  const [selectedMerchantFee, setSelectedMerchantFee] = useState(0); // New state for storing selected merchant fee passing it to the children form will grab the data and the place order summary will calculate it and use it for data validation
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  // setting a call back state up to manage both child component for single payment reset, if gift card used balance covers full amount then resets the dropdown payment method and hides the billing details (the gift card slider will allow user to do this) else payment method covers full amount when the slider is slid to the left and user clicks on payments so this is used for two states and the gui for model views controller philosophy
  const [giftCardBalUsed, setGiftCardBalUsed] = useState(0); // setting state on the parent that will be used as a call back to reset the gift card slider and value for single payment when the payment method covers the full order balance
  const [showPaymentDetails, setShowPaymentDetails] = useState(true); // setting this for show and hide from this parent that will be passed to the form and the place order summary
  const [selectedTier, setSelectedTier] = useState("");
  const [hasAttemptedToOrder, setHasAttemptedToOrder] = useState(false);
  const [invoice, setInvoice] = useState(null); // changed from 0 to null. Represents the absence of any value. It implies that the invoice doesn't exist or hasn't been created yet. By setting invoice to null, you're explicitly stating that there is no invoice data available at the moment.
  const [isGeneratingReceipt, setIsGeneratingReceipt] = useState(false); // will be used to close the receipt on the checkout and process the receipt on the place order summary
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [attemptedToOrder, setAttemptedToOrder] = useState(false);
  const [maxGiftBal, setMaxGiftBal] = useState(0);
  const [beginingPointsBalance, setBeginingPointsBalance] =
    useState(pointsBalance);
  const beginingRewardLevelName = getCurrentRewardLevelName(
    beginingPointsBalance
  );
  const beginingRewardLevel = getCurrentRewardLevel(beginingPointsBalance);
  const [placeOrder, setPlaceOrder] = useState({
    orderSubTotal: 0,
    percentMerchantFee: 0,
  });
  const [onPlaceOrder, setOnPlaceOrder] = useState({}); // object to create the invoice and will pass in the invoice varaible in the handler below to attach the data and change the object name to invoice
  // callback to process the merchant fee.
  const handlePaymentMethodSelected = (method) => {
    setSelectedPaymentMethod(method.name);
    setSelectedMerchantFee(method.fee);
  };

  const [showPaymentDropdown, setShowPaymentDropdown] = useState(false); // setting state to show the place holder and not the list everytime a value is selected the drop down will close and the value will replace the placeholder
  // setting and passing state managed on this component to the payments form component
  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "", // this will be optional still needs to be here see below logic on how it is handled
    city: "",
    state: "", // Dropdown state value will be stored here
    postalCode: "",
    country: "",
    creditCard: "",
    expiryDate: "", // The date picker value will be stored here
    selectedPaymentMethod: "", // Storing the payment method value from drop down
    isFirstNameFocused: false,
    isLastNameFocused: false,
    isAddress1Focused: false,
    isAddress2Focused: false,
    isCityFocused: false,
    isStateFocused: false,
    isPostalCodeFocused: false,
    isCountryFocused: false,
    isCreditCardFocused: false,
    isExpiryDateFocused: false,
    isSelectedPaymentMethodFocused: false,
  });
  // mapping through the billing details array above, managing errors on this component and passing the show errors call back to payments form component
  const showErrors = useMemo(() => {
    let errorObj = {};
    for (let key in billingDetails) {
      if (key !== "address2" && !billingDetails[key]) {
        // key !== 'address2' ensures address2 is not marked as required, but still in the block to handle the blinker focus logic.
        errorObj[`${key}Error`] = "Required";
      } else {
        errorObj[`${key}Error`] = null;
      }
    }
    return errorObj;
  }, [billingDetails]);

  const validateBillingDetails = () => {
    const isValid = () => {
      for (let key in billingDetails) {
        // Exclude optional address2 and any focus-related keys to not be marked as errors
        if (
          !key.includes("Focused") &&
          key !== "address2" &&
          !billingDetails[key]
        ) {
          // console.log("Missing or invalid billing detail:", key);
          return false; // If a required field is missing, return false immediately.
        }
      }
      return true; // All fields are valid.
    };

    return isValid(); // Returns true or false based on billing details' validity.
  };
  // managing updated state on all the fields and passing the call back to the payments form component below
  const handleFieldChange = (fieldName, value) => {
    setBillingDetails((prevState) => {
      const newState = { ...prevState, [fieldName]: value };
      // console.log("Updated state: ", newState);
      // comment out after testing below
      const localStorageKey = getStorageKey(fieldName, currentContext);
      localStorage.setItem(localStorageKey, value);
      // console.log("Setting item in localStorage:", localStorageKey, value);
      return newState;
    });
  };
  const scrollWithOffset = (additionalOffset = 0) => {
    const offset = 100; // Adjust this value based on your fixed header's height
    const elementPosition = tierSectionRef.current.offsetTop;
    const offsetPosition = elementPosition - offset - additionalOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  function onAttemptingToOrder() {
    // Set the flag to true to trigger the useEffect
    setAttemptedToOrder(true);
  }
  useEffect(() => {
    if (attemptedToOrder) {
      // Reset the flag
      setAttemptedToOrder(false);
    // check if selected tier pricing is empty or not slected
    if (selectedTier === "") {
        setTierError(true); // then there is an error message set state to true and pass it to the forms component to show the error message where it is empty
        scrollWithOffset(tierSectionRef.current, 150); // Pass the additional offset here to scroll higher 
      } else {
       // proceed to validate billing detials...
        setTierError(false);
      }
      if (!validateBillingDetails()) {
        scrollWithOffset(paymentDetailsRef.current, 150);  // Scroll to payment details section
      }
      else {
        // Proceed with order as both tier and payment details are complete...
      }
    }
  }, [attemptedToOrder, selectedTier, billingDetails]);
  // Handler to show the receipt popup and pass the invoice data set it here for other childrend to have access if needed and scalability so will pass the handler down
  const handleGenerateReceipt = (invoice) => {
    // console.log("handleGenerateReceipt called with invoice:", invoice);
    setOnPlaceOrder(invoice);
    // console.log("what is in this invoice", invoice); // checking invoice
    setIsGeneratingReceipt(true);
    // New Logic
    setInvoice(invoice);
    setShowInvoiceModal(true);
  };

  return (
    <div id="select-tier">
      <div>
        <DashBoardSection
          pointsBalance={pointsBalance}
          setPointsBalance={setPointsBalance}
          giftCardBalance={giftCardBalance}
          setGiftCardBalance={setGiftCardBalance}
        />
        <PaymentsForm
          tierSectionRef={tierSectionRef} // scrolling user up if there is an error here
          paymentDetailsRef={paymentDetailsRef}
          onTierSelected={setSelectedTierPrice} // passing the setter function as a callback
          onPaymentMethodSelected={handlePaymentMethodSelected} // passing the handler function as a callback, joining the child (form) and parent payment method page on the child by passing in the on payment selected to the child (form) to accept the handle payment method selected on the parent here that will manage passing the value to the process order summary but it needs to talk to the sibling to get it which is why the handler is here to pass off the value to both siblings re-assigning the name just to understand the event call back and not to get lost for scaling
          showPaymentDropDown={showPaymentDropdown}
          setShowPaymentDropDown={setShowPaymentDropdown}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          showPaymentDetails={showPaymentDetails}
          billingDetails={billingDetails}
          handleFieldChange={handleFieldChange}
          showErrors={showErrors}
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          hasAttemptedToOrder={hasAttemptedToOrder}
          tierError={tierError}
          setTierError={setTierError}
          invoice={invoice}
          setInvoice={setInvoice}
        />
        {showInvoiceModal && (
          <InvoiceModal
            isOpen={showInvoiceModal}
            onClose={() => setShowInvoiceModal(false)}
            invoiceData={invoice}
            contentClass="invoice-content"
          />
        )}
        <PlaceOrderSummary
          pointsBalance={pointsBalance}
          setPointsBalance={setPointsBalance}
          placeOrder={placeOrder}
          setPlaceOrder={setPlaceOrder}
          maxGiftBal={maxGiftBal}
          beginingRewardLevel={beginingRewardLevel}
          setMaxGiftBal={setMaxGiftBal}
          beginingPointsBalance={beginingPointsBalance}
          setBeginingPointsBalance={setBeginingPointsBalance}
          beginingRewardLevelName={beginingRewardLevelName}
          giftCardBalance={giftCardBalance}
          setGiftCardBalance={setGiftCardBalance}
          tierPriceSelected={selectedTierPrice}
          onTierSelected={setSelectedTierPrice} // passing the setter function as a callback
          merchantFee={selectedMerchantFee} // passing the selected merchant fee down to the PlaceOrderSummary
          showPaymentDropdown={showPaymentDropdown}
          setShowPaymentDropdown={setShowPaymentDropdown}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          giftCardBalUsed={giftCardBalUsed}
          setGiftCardBalUsed={setGiftCardBalUsed} // gift card used is the gift card balanace that will be used on the order and need to set state here since it goes inbetween components
          setSelectedMerchantFee={setSelectedMerchantFee}
          setShowPaymentDetails={setShowPaymentDetails} // used for hiding payment details when gift card balance used covers full amount where subtotal is > 0 not factoring in merchant fee since we don't charge on gift card balance used for the order
          validateBillingDetails={validateBillingDetails} // used for processing order when total due is greater than 0 for split payment and single payment card balance used not when gift card used balance pays for full order
          setHasAttemptedToOrder={setHasAttemptedToOrder} // used to show the required errors called it attempted because filling out the billing details failed so they will see errors
          onAttemptingToOrder={onAttemptingToOrder} // call back from the child to map through this componet for the click since state and mapping is place here
          isGeneratingReceipt={isGeneratingReceipt} // passing state down to call back here
          setInvoice={setInvoice} // setting the invoice, which is before payment and then will be passed as a receipt to be confirmed since invoice is before payment and receipt is after payment
          handleGenerateReceipt={handleGenerateReceipt} // passing the handler down to manage the trigger to show the receipt
        />
      </div>
    </div>
  );
}
