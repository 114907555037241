import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import UserPost from "../../components/userPost/userPost";
import { useUserProfile } from "../../context/userProfileContext.jsx";
import ProfileBook from "../../components/profileBook/profileBook";
import {
  SlideBtn,
  GlassBtn,
  ConnectionPgNavSlider,
} from "../../components/buttons";
import {
  FollowIcon,
  UnfollowIcon,
  LineThroughCircle,
  UnlockIcon,
} from "../../components/icons";
import BioForm from "../../components/forms/bioForm/bioForm";
import ProfilePicture from "../../components/profilePic/profilePic";
import PicVidUploadForm from "../../components/forms/picVidUpload/picVidUpload";
import CreatePost from "../../components/userPost/createPost";
import mockData from "../../seedMockData/seedMockData";
import PointsCircle from "../../components/rewardsCircle/rewardsCircle";
import ChatBox from "../../components/chatBox/chatBox";
import "./profilePage.scss";

function ProfilePage({ pointsBalance, setPointsBalance }) {
  const { username } = useParams();
  const [paramLevel, setParamLevel] = useState({}); // Add this state
  const [showUploadForm] = useState(false);
  const [chatBoxZIndex, setChatBoxZIndex] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  const { userProfileInfo, setUserProfileInfo } = useUserProfile();

  useEffect(() => {
    const foundUserProfile = mockData.users.find(
      (user) => user.username === username
    );
    if (foundUserProfile) {
      setUserProfileInfo(foundUserProfile);
    }
  }, [username, setUserProfileInfo]);

  const buttonsRowRef = useRef(null);
  // Sample hard coded data to represent the logged-in user, we will replace this with sessions.
  const user = {
    id: "user-uuid-5",
    username: "AcctHolder",
  };
  const isProfileOwner = user.username === username;
  const isOtherUser = !isProfileOwner;

  useEffect(() => {
    const foundUserProfile = mockData.users.find(
      (user) => user.username === username
    );
    if (foundUserProfile) {
      const posts = mockData.posts
        .filter((post) => post.userId === foundUserProfile.id)
        .map((post) => {
          return {
            ...post,
            media: post.imagePath, // Directly use imagePath from the post
          };
        });
      setUserProfileInfo(foundUserProfile);
      setUserPosts(posts);
    }
  }, [username]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        buttonsRowRef.current &&
          buttonsRowRef.current.classList.add("underlay");
      } else {
        buttonsRowRef.current &&
          buttonsRowRef.current.classList.remove("underlay");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // replace SOME_THRESHOLD with your desired value
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showBioForm, setShowBioForm] = useState(false);
  const [showPicVidUploadForm, setShowPicVidUploadForm] = useState(false);

  const handleOpenBioForm = () => setShowBioForm(true);
  const handleCloseBioForm = () => setShowBioForm(false);
  const handleOpenPicVidUploadForm = () => setShowPicVidUploadForm(true);
  const handleClosePicVidUploadForm = () => setShowPicVidUploadForm(false);
  // call back function to navigate user to the connections page and see follows, followers list
  // and show vidpic form
  // this just invokes the code programmed on the parent for the slider nav
  // to go to the conections page
  function handleConnectionsSlideEnd() {}
  function handleChatSlideEnd(setChatBoxOpen) {
    setChatBoxOpen(true);
  }

  // handle toggle for the un/follow glass btn
  function handleFollowToggle() {
    setIsFollowing((prevState) => !prevState);
  }
  // Handle toggle for the block/unblock glass btn
  function handleBlockToggle() {
    setIsBlocking((prevState) => !prevState);
  }
  console.log("UserData:", userProfileInfo);
  function closeChatBox() {
    setChatBoxOpen(false);
  }

  useEffect(() => {
    if (chatBoxOpen) {
      setElementsZIndexToZero();
      setChatBoxZIndex("3"); // Making it higher than the overlay's z-index
    } else {
      resetElementsZIndex();
    }
  }, [chatBoxOpen]);

  const classNamesAndZIndices = [
    { className: ".stickUserPost", zIndex: "200" },
    { className: "h4.user-posts-header", zIndex: "1" },
    { className: ".user-post-header", zIndex: "2" },
    { className: ".glassref", zIndex: "1" },
    { className: ".glasshover", zIndex: "2" },
  ];

  const setElementsZIndexToZero = () => {
    classNamesAndZIndices.forEach(({ className }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = "-1";
      });
    });
  };

  const resetElementsZIndex = () => {
    classNamesAndZIndices.forEach(({ className, zIndex }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = zIndex;
      });
    });
  };

  return (
    <div id="member" className="profilePg">
      <div>
        {/* Profile Book Component */}
        <ProfileBook
          isProfileOwner={isProfileOwner}
          userData={userProfileInfo}
          userProfileInfo={userProfileInfo}
          loggedInUser={user}
          profileUser={username}
          onAddBioBtnClick={handleOpenBioForm}
          onAddPicBtnClick={handleOpenPicVidUploadForm}
        />
        {/* Conditional rendering for PicVidUploadForm */}
        {showPicVidUploadForm && (
          <PicVidUploadForm onClose={handleClosePicVidUploadForm} />
        )}

        {/* Conditional rendering for BioForm */}
        {showBioForm && <BioForm onClose={handleCloseBioForm} />}
        <div
          id="pointsConectWrap"
          style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}
        >
          <ConnectionPgNavSlider
            key={userProfileInfo.id}
            username={userProfileInfo.username}
            slideBtnText="View connections"
            className="ProfileConnects"
            slideBtnWidth="fit-content"
            onHandleSlideEnd={handleConnectionsSlideEnd}
          />
          <div className="profileUnlockLevel">
            <PointsCircle
              pointsBalance={pointsBalance}
              setPointsBalance={setPointsBalance}
              NameLevelValueStyle={{
                color: "#e2b645",
              }}
              CurrentPntsBalStyle={{
                color: "#e2b645",
              }}
              unlockLevelStyle={{
                color: "#e2b645",
              }}
              pointsToGoStyle={{
                color: "#e2b645",
              }}
              className="positionUnlockContent"
            />
          </div>
        </div>
        <section className="sticky-container">
          {/* Buttons Row, only shown if the profile is of another user */}
          {isOtherUser && (
            <div className="buttons-row" ref={buttonsRowRef}>
              <div className="followProfile-btn">
                <GlassBtn
                  onClick={handleFollowToggle}
                  glassBtnImage={
                    isFollowing ? (
                      <UnfollowIcon
                        height="25px"
                        width="25px"
                        fillColor="#e2b645"
                      />
                    ) : (
                      <FollowIcon
                        height="25px"
                        width="25px"
                        fillColor="#e2b645"
                      />
                    )
                  }
                  glassBtnFontColor="#e2b645"
                  glassBtnTextDecorationColor="#e2b645"
                  glassBtnFontFamily="roboto-slab"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="800"
                  glassBtnText={isFollowing ? "Unfollow" : "Follow"}
                />
              </div>
              <div className="BlockProfile-btn">
                <GlassBtn
                  onClick={handleBlockToggle}
                  glassBtnImage={
                    isBlocking ? (
                      <UnlockIcon
                        height="25px"
                        width="25px"
                        fillColor="#fdfd96"
                      />
                    ) : (
                      <LineThroughCircle
                        height="25px"
                        width="25px"
                        strokeColor="#fdfd96"
                      />
                    )
                  }
                  glassBtnFontColor="#fdfd96"
                  glassBtnTextDecorationColor="#fdfd96"
                  glassBtnFontFamily="roboto-slab"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="800"
                  glassBtnText={isBlocking ? "Unblock" : "Block"}
                />
              </div>
              <div className="ChatProfile-btn">
                <SlideBtn
                  slideBtnText="Chat"
                  onHandleSlideEnd={() => setChatBoxOpen(true)} // just invoking a single function with no additional logic, you can pass the function directly like the slide end up top but  I used an arrow function to explicitly call the handleChatSlideEnd with the setChatBoxOpen function as an argument. This pattern is often useful when you want to pass some additional data or context that isn't directly available in the child component.
                  slideBtnWidth="150px"
                  className="chat-slide-btn"
                />
                <>{chatBoxOpen && <ChatBox onCloseChat={closeChatBox} />}</>
              </div>
            </div>
          )}
          <div className={`stickyProfilePic ${isVisible ? "is-visible" : ""}`}>
            <ProfilePicture
              key={userProfileInfo.id}
              username={userProfileInfo.username}
              profileImgPath={userProfileInfo.imagePath}
              className="PositionShow picSize"
            />
          </div>
          {/* User Posts Header */}
          <h4 className="user-posts-header">
            {userProfileInfo.firstName && userProfileInfo.lastName
              ? `${userProfileInfo.firstName} ${userProfileInfo.lastName} Posts`
              : "User Name Posts"}
          </h4>
        </section>
        {/* Profile Posts */}
        <div className="profile-posts">
          {isProfileOwner && (
            <>
              <CreatePost
                userData={userProfileInfo}
                onCreatePostSlidEnd={handleOpenPicVidUploadForm}
              />
              {showUploadForm && (
                <div className="underlay-popup">
                  <PicVidUploadForm onClose={handleClosePicVidUploadForm} />
                </div>
              )}
            </>
          )}
          {userPosts.length > 0 &&
            userPosts.map((post) => (
              <UserPost
                isProfilePage={true}
                key={post.id}
                username={userProfileInfo.username}
                media={post.media}
                isProfileOwner={isProfileOwner}
                showChatSlider={false}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
export default ProfilePage;
