import { useEffect, useState, useRef } from "react";
// Reward Level Object used for calculations and redemptions
// rate means how fast you earn points at each level
import { Rewardlevels } from "../../constants/rewardPoints";
// Define the RewardStatus component which is the animated indicator
// initializing at 0 from the other component using absoulute definition
const RewardStatus = ({ pointsBalance = 0 }) => {
  const [rewardStatus, setRewardStatus] = useState(0);
  const [messageLevel, setMessageLevel] = useState("");
  const rewardLevels = useRef(Rewardlevels);

  // rewardStatusMultiplier - the coefficient for each interval, since we have a non-linear dependence of the indicator position on the value Points
  // if it is a negative balance set the indicator at the 0 position
  useEffect(() => {
    console.log("Current pointsBalance:", pointsBalance);

    if (pointsBalance < 0) {
      setRewardStatus(0);
      console.log("Setting rewardStatus to 0 due to negative balance");
    } else if (pointsBalance >= 0 && pointsBalance <= 1000) {
      // We have 5 equal intervals for the indicator position 100 / 5 = 20, in this interval the value varies from 0 to 1000.
      const rewardStatusMultiplier = 20 / 1000;
      // to calculate position to move the indicator to, need to multiply the point value by this coefficient
      setRewardStatus(rewardStatusMultiplier * pointsBalance);
      console.log("Setting rewardStatus for pointsBalance between 0-1000");
    } else if (pointsBalance > 1000 && pointsBalance <= 5000) {
      // We have 5 equal intervals for the indicator position, in this interval the value varies from 1000 to 5000.
      const rewardStatusMultiplier = 20 / (5000 - 1000);
      // to 20% of the previous interval, we add how much to move the indicator for the current one
      setRewardStatus(20 + rewardStatusMultiplier * (pointsBalance - 1000));
      console.log("Setting rewardStatus for pointsBalance between 1001-5000");
    } else if (pointsBalance > 5000 && pointsBalance <= 15000) {
      // We have 5 equal intervals for the indicator position, in this interval the value varies from 15000 to 5000.
      const rewardStatusMultiplier = 40 / (15000 - 5000);
      // to 40% of the previous interval, we add how much to move the indicator for the current one
      setRewardStatus(40 + rewardStatusMultiplier * (pointsBalance - 5000));
      console.log("Setting rewardStatus for pointsBalance between 5001-15000");
    } else if (pointsBalance > 15000 && pointsBalance <= 25000) {
      // We have 5 equal intervals for the indicator position, in this interval the value varies from 1000 to 5000.
      const rewardStatusMultiplier = 20 / (25000 - 15000);
      // to 80% of the previous interval, we add how much to move the indicator for the current one
      setRewardStatus(80 + rewardStatusMultiplier * (pointsBalance - 15000));
      console.log("Setting rewardStatus for pointsBalance between 15001-25000");
    } else {
      setRewardStatus(100);
      console.log("Setting rewardStatus to 100 for pointsBalance > 25000");
    }
    // Determine the current reward level based on bidpointsBalance
    const levels = rewardLevels.current;
    console.log("Reward Levels:", levels);
    const currentLevelIndex = levels.findIndex((level) => {
      return (
        pointsBalance >= level.pointsRange[0] &&
        pointsBalance <= level.pointsRange[1]
      );
    });
    console.log("currentLevelIndex:", currentLevelIndex);
    if (pointsBalance >= levels.at(-1).pointsRange[0]) {
      const congratsMessage = `Congratulations on Becoming a ${
        levels.at(-1).name
      } Status Member!`;
      console.log("Setting message:", congratsMessage);
      setMessageLevel(congratsMessage);
    } else {
      const updateMessage = `Only ${
        levels[currentLevelIndex].pointsRange[1] - pointsBalance + 1
      } More Points Until You Reach ${
        levels[currentLevelIndex + 1].name
      } Status!`;
      console.log("Setting message:", updateMessage);
      setMessageLevel(updateMessage);
    }
  }, [pointsBalance]);
  // Only re-run the effect if pointsBalance changes
  return (
    <>
      {/* Display the progress bar with reward status indicator */}
      <div className="contentAllBars">
        <div className="member-reward-status-card-icons fill-line">
          <div className="wrapperProgress">
            <div className="linearLine" style={{ width: `${rewardStatus}%` }} />
            <div
              className="lineForIndicate"
              style={{ left: `${rewardStatus}%` }}
            >
              <img
                src="/rewardindicator.svg"
                className="statusIndicator"
                alt=""
              />
            </div>
          </div>
          {/* Static images that represent different reward statuses */}
          <img
            src="/images/icons/member.svg"
            className="statusImg active"
            alt=""
          />
          <img src="/star-circle.svg" className="statusImg" alt="" />
          <img src="/starmedal-circle.svg" className="statusImg" alt="" />
          <img src="/crown-circle.svg" className="statusImg" alt="" />
          <img src="/platinum-circle.svg" className="statusImg" alt="" />
          <img src="/diamond-circle.svg" className="statusImg" alt="" />
        </div>
        {/* Display the points required for different reward statuses */}
        <div className="icon-numbers">
          <p>0</p>
          <p>1,000</p>
          <p>5,000</p>
          <p>10,000</p>
          <p>15,000</p>
          <p>25,000+</p>
        </div>
      </div>
      {/* Display the message indicating how far from the next reward status */}
      <p className="diffNextStatus">{messageLevel}</p>
    </>
  );
};

export default RewardStatus;
