import {
  validatePasswordValue,
  validateUserNameValue,
} from "../../constants/validate.js";
export const signupErrorHandler = (
  firstNameValue,
  lastNameValue,
  emailValue,
  userNameValue,
  phoneNumberValue,
  confirmPasswordValue,
  currentPasswordValue,
  originalPasswordValue,
  newPasswordValue,
  currentContext,
  setFirstNameValueError,
  setLastNameValueError,
  setEmailValueError,
  setUserNameValueError,
  setUserNameToolTipVisible,
  setPhoneNumberValueError,
  setConfirmPasswordValueError,
  setCurrentPasswordValueError,
  setCurrentPasswordToolTipVisible,
  setOriginalPasswordValueError,
  setOriginalPasswordToolTipVisible,
  setNewPasswordValueError,
  setNewPasswordToolTipVisible
) => {
  let errors = {};
  if (currentContext === "signup" && firstNameValue.length === 0) {
    errors.firstNameValueError = "Required";
    setFirstNameValueError(errors.firstNameValueError);
  } else {
    setFirstNameValueError(""); // Clear the error if not required or value is provided
  }
  if (currentContext === "signup" && lastNameValue.length === 0) {
    errors.lastNameValueError = "Required";
    setLastNameValueError(errors.lastNameValueError);
  } else {
    setLastNameValueError(""); // Clear the error if not required or value is provided
  }
  if (currentContext === "signup" && emailValue.length === 0) {
    errors.emailValueError = "Required";
    setEmailValueError(errors.emailValueError);
  } else {
    setEmailValueError(""); // Clear the error if not required or value is provided
  }
  if (userNameValue.length === 0) {
    if (currentContext === "signup") {
      errors.userNameValueError = "Required";
      setUserNameValueError("Required");
    } else if (currentContext === "settings") {
      errors.userNameValueError = "";
      setUserNameValueError("");
    }
  } else {
    // This part runs for both 'signup' and 'settings' when userNameValue is not empty
    const userNameResults = validateUserNameValue(userNameValue);
    const userNameValid = userNameResults.every((rule) => rule.isValid);
    if (!userNameValid) {
      errors.userNameValueError = "Username validation failed";
      setUserNameValueError("Username validation failed");
      setUserNameToolTipVisible(true);
    } else {
      setUserNameToolTipVisible(false);
      setUserNameValueError("");
    }
  }

  if (phoneNumberValue.length === 0 && currentContext === "signup") {
    errors.phoneNumberValueError = "Required";
  }
  setPhoneNumberValueError(errors.phoneNumberValueError || "");

  if (confirmPasswordValue.length === 0 && currentContext === "signup") {
    errors.confirmPasswordValueError = "Required";
    setConfirmPasswordValueError(errors.confirmPasswordValueError);
  } else if (confirmPasswordValue !== currentPasswordValue) {
    errors.confirmPasswordValueError = "Passwords do not match";
    setConfirmPasswordValueError(errors.confirmPasswordValueError);
  } else {
    setConfirmPasswordValueError(""); // Clear the error message if the passwords match
  }
  if (currentPasswordValue.length === 0 && currentContext === "signup") {
    errors.currentPasswordValueError = "Required";
    setCurrentPasswordValueError(errors.currentPasswordValueError);
  } else {
    const currentPasswordValidationResults =
      validatePasswordValue(currentPasswordValue);
    const isAnyRuleInvalid = currentPasswordValidationResults.some(
      (rule) => !rule.isValid
    );
    if (isAnyRuleInvalid) {
      errors.currentPasswordValueError = "Password validation failed";
      setCurrentPasswordValueError("Password validation failed");
      setCurrentPasswordToolTipVisible(true); // Show tooltip
    } else {
      setCurrentPasswordToolTipVisible(false); // Hide tooltip when all validation rules are met
      setCurrentPasswordValueError(""); // Clear the error message
    }
    if (currentContext === "settings" && originalPasswordValue.length > 0) {
      const originalPasswordValidationResults = validatePasswordValue(
        originalPasswordValue
      );
      const isAnyRuleInvalid = originalPasswordValidationResults.some(
        (rule) => !rule.isValid
      );
      if (isAnyRuleInvalid) {
        errors.originalPasswordValueError = "Password validation failed";
        setOriginalPasswordValueError("Password validation failed");
        setOriginalPasswordToolTipVisible(true); // Show tooltip
      } else {
        setOriginalPasswordToolTipVisible(false); // Hide tooltip when all validation rules are met
        setOriginalPasswordValueError(""); // Clear the error message
      }
    }
    if (currentContext === "settings" && newPasswordValue.length > 0) {
      const newPasswordValidationResults =
        validatePasswordValue(newPasswordValue);
      const isAnyRuleInvalid = newPasswordValidationResults.some(
        (rule) => !rule.isValid
      );
      if (isAnyRuleInvalid) {
        errors.newPasswordValueError = "Password validation failed";
        setNewPasswordValueError("Password validation failed");
        setNewPasswordToolTipVisible(true); // Show tooltip
      } else {
        setNewPasswordToolTipVisible(false); // Hide tooltip when all validation rules are met
        setNewPasswordValueError(""); // Clear the error message
      }
    }
  }
  console.log("Errors:", errors);
  return { errors };
};
