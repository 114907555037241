import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { GlassBtn, UnlockSlideBtn, CustomButton } from "../../buttons";
import { EyeSlashIcon, EyeIcon } from "../../icons";
import PhoneInput from "react-phone-input-2";
import ToolTip from "../../tooltip/tooltip";
// import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator.js";
import { validatePasswordValue } from "../../../constants/validate";
import {
  onSignInFocusPassword,
  onSignInBlurPassword,
  phoneInputWatcher,
  signinPasswordWatcher,
} from "../../../controller/watcherAndClearFunctions/signinWatcherFunctions.js";
import {
  handleSigninErrors,
  onResetPassword,
} from "../../../controller/errorHandlers/signinErrorHandler.js";
import "react-phone-input-2/lib/material.css";
import "../formsCommon.scss";
import "./signIn.scss";
const SignIn = ({
  setCurrentForm,
  contextType,
  setContextType,
  isSettings = false,
  isSignIn = true,
}) => {
  // const { signin } = useAuth();
  const [showSigninPassword, setShowSigninPassword] = useState(false);
  const [signinPasswordFocused, setSigninPasswordFocused] = useState(false);
  const currentContext = isSettings ? "settings" : "signin";
  const [isFocused, setIsFocused] = useState(false);
  const [passwordValidationRules, setPasswordValidationRules] = useState([
    false,
  ]);
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [showAccountVerification, setShowAccountVerification] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signInPhoneInputError, setSignInPhoneInputError] = useState("");
  const [signInPasswordError, setSignInPasswordError] = useState("");
  const [signInPasswordValue, setSignInPasswordValue] = useState(
    localStorage.getItem("signInPasswordValue") || ""
  );
  const [signInPhoneNumberValue, setSignInPhoneNumberValue] = useState(
    localStorage.getItem("signInPhoneNumberValue") || ""
  );
  const [signInAttempted, setSignInAttempted] = useState(false);
  const onSignin = async () => {
    setSignInAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  useEffect(() => {
    if (signInAttempted) {
      const { isRequired } = handleSigninErrors(
        signInPasswordValue,
        signInPhoneNumberValue,
        setToolTipVisible,
        setSignInPasswordError,
        setSignInPhoneInputError,
        currentContext
      );
      if (!isRequired) {
        // Proceed with form submission logic
        localStorage.clear();
        // ...
      }
      setSignInAttempted(false); // Reset for next submission attempt
    }
  }, [signInAttempted, signInPasswordValue, signInPhoneNumberValue]); // Dependencies

  // const auth = useAuth();
  // console.log(auth);
  const handleSignUpClick = () => {
    // this needs to be from the signin form only not the settings
    // when user presses signin up the set toggle true value is passed to the signup form and user will scroll to the end for user experience feedback
    setCurrentForm("signUp"); // Switch to the signup form
    setContextType("signUp"); // Set the contextType for signup
    localStorage.clear();
    setTimeout(() => {
      const stopScrollElement = document.getElementById("stopScroll");
      if (stopScrollElement) {
        stopScrollElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    // if not signup state max height is not set else signup state apply max hieght
    // inital state is true since onload the signin will appear therefore max height is not applied when signin is false
    <div
      className="form-wrapper"
      style={{ maxHeight: !isSignIn ? "none" : "360px" }}
    >
      <form id="sign-in-form">
        <div className="input-group blinker-wrapper">
          <label htmlFor="phone_number">
            <h4 className="about">Connect with Like Minded People</h4>
            <p className="phone_num">Enter your mobile number below:</p>
          </label>
          <PhoneInput
            type="text"
            country={"us"}
            autoComplete="phone_number"
            placeholder={signInPhoneInputError}
            className={signInPhoneInputError ? "red-placeholder" : ""}
            inputClass="custom-placeholder-color"
            value={signInPhoneNumberValue}
            onChange={(value) =>
              phoneInputWatcher(
                value,
                setSignInPhoneNumberValue,
                currentContext,
                signInPhoneInputError,
                setSignInPhoneInputError
              )
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            error={!!signInPhoneInputError.phone_number}
            inputProps={{
              id: "phone_number",
              required: true,
            }}
            helperText={
              signInPhoneInputError.phone_number && "Invalid phone number"
            }
          />
          {!isFocused && signInPhoneNumberValue === "" && (
            <span className="blinking-cursor signin-mobile-cursor">|</span>
          )}
        </div>
        {/* Password input field */}
        <div className="input-group blinker-wrapper">
          <label htmlFor="signInPassword">Sign in Password:</label>
          <input
            type={showSigninPassword ? "text" : "password"}
            id="signInPassword"
            autoComplete="off"
            placeholder={
              signInPasswordError ? signInPasswordError : "Enter Password Here"
            }
            className={signInPasswordError ? "red-placeholder" : ""}
            value={signInPasswordValue} // Bind input to signInPassword state
            onChange={(e) =>
              signinPasswordWatcher(
                e.target.value,
                setSignInPasswordValue,
                currentContext,
                validatePasswordValue,
                setPasswordValidationRules,
                setToolTipVisible,
                signInPasswordError,
                setSignInPasswordError
              )
            }
            onFocus={() =>
              onSignInFocusPassword(
                signInPasswordValue,
                setSigninPasswordFocused,
                setPasswordValidationRules,
                setToolTipVisible
              )
            }
            onBlur={() =>
              onSignInBlurPassword(setSigninPasswordFocused, setToolTipVisible)
            }
          />
          {!signinPasswordFocused && signInPasswordValue === "" && (
            <span className="blinking-cursor signin-password-cursor">|</span>
          )}
          {/* when a value shows up in the field the show/hide is present */}
          {signInPasswordValue && (
            <div
              className="show-hide-password-toggle"
              onClick={() => setShowSigninPassword((prev) => !prev)}
            >
              {showSigninPassword ? (
                <EyeSlashIcon width="20" height="15" />
              ) : (
                <EyeIcon width="20" height="15" />
              )}
              <span className="show-hide-label">
                {showSigninPassword ? "Hide" : "Show"}
              </span>
            </div>
          )}
          {(signinPasswordFocused || signInPasswordError) && (
            <ToolTip
              rules={passwordValidationRules}
              toolTipVisible={toolTipVisible}
              setToolTipVisible={setToolTipVisible}
            />
          )}
        </div>
        <div id="sign-in-button">
          <UnlockSlideBtn
            onHandleSlideEnd={(ev) => {
              // Call onSignin and pass contextType
              onSignin(ev, contextType);
            }}
            slideBtnText="Slide right to log in"
            slidBtnFontSize="1.2rem"
            sliBtnFontColor="#964B00"
            slidBtnFontWeight="800"
            slideBtnWidth="195px"
          />
        </div>
        <CustomButton
          customBtnText="Reset Password"
          customBtnWidth="fit-content"
          customBtnFontFamily="Roboto-Slab"
          customBtnFontWeight="800"
          customBtnHeight="30px"
          paddingLeft="2px"
          paddingRight="2px"
          animationActive={true}
          className="forgot-password"
          onClick={() =>
            onResetPassword(
              resetPassword,
              setContextType,
              setShowAccountVerification,
              setCurrentForm
            )
          }
        />
        {/* <hr/> */}
        <div className="seprator-icon">
          <div className="and-or horizontal-bar vertical-icon">
            <span>OR</span>
          </div>
        </div>
        <GlassBtn
          glassBtnText="Create a new account"
          glassBtnHeight="20px"
          glassBtnWidth="fit-content"
          sparkle="true"
          top="-10px"
          onClick={handleSignUpClick}
          hi2Style={{
            transform: "rotate(90deg) translate(-30px, 100px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate( -30px, -100px)",
          }}
        />
        {/* <p className="black"><span className="underline">Click Here to contact Tech Support for Questions/Issues.</span> By signing up you consent to opt-in to receive SMS messages from meetonup.com. We send transactional messages for verification and security purposes only.</p> */}
      </form>
    </div>
  );
};
export default SignIn;
