import { SET_LEVEL } from "../actions/actionCurrentRewardLevel.js";

const initialState = {};

const currentRewardLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEVEL:
      return action.payload;
    default:
      return state;
  }
};

export default currentRewardLevelReducer;