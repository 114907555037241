import React, { useState, useEffect, useRef } from "react";
import { CameraIcon, TouchIcon, PaperPlane2Icon } from "../../icons";
import mockData from "../../../seedMockData/seedMockData";
import { handleTextareaResize } from "../../../utils/postCommentHelperFunction";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator";
import { ImgTopBtn } from "../../buttons";
import "./post.scss";

const PostForm = ({ onClose }) => {
  const currentContext = "creatPost";
  const [postFile, setPostFile] = useState(null);
  const [postCommentValue, setPostCommentValue] = useState("");
  const [postCommentValueFocused, setPostCommentValueFocused] = useState("");
  const [postCommentValueBlur, setPostCommentValueBlur] = useState("");
  const [postCharacterCommentError, setPostCharacterCommentError] =
    useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadPostFileError, setUploadPostFileError] = useState("");
  const [uploadMediaAttempted, setUploadMediaAttempted] = useState(false);
  // Refs for handling dynamic positioning
  const textareaRef = useRef(null);
  const postContainerRef = useRef(null);
  const thumbnailRef = useRef(null);
  const buttonsRef = useRef(null);
  const clearPostBtnRef = useRef(null);
  const fileInputRef = useRef(null); // Reference for the hidden file input

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setPostFile(file);
      // Read the file and convert it to a Base64 encoded string
      const reader = new FileReader();
      reader.onloadend = () => {
        // Save the Base64 encoded string to localStorage
        const imageKey = getStorageKey("postImage", currentContext);
        localStorage.setItem(imageKey, reader.result);
        setIsImageUploaded(true); // Update the state to indicate the image is uploaded
      };
      reader.readAsDataURL(file);
    }
  };
  // Function to trigger file upload when camera icon is clicked
  const triggerFileUpload = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  const onUploadMedia = async () => {
    setUploadMediaAttempted(true);
  };

  useEffect(() => {
    if (uploadMediaAttempted) {
      if (!postFile) {
        setUploadPostFileError("Profile picture is required.");
      } else {
        console.log("File uploaded:", postFile);
        setUploadMediaAttempted(false);
      }
    }
  }, [uploadMediaAttempted, postFile]);

  const resetPostForm = () => {
    setPostFile(null);
    clearPostCommentLocalStorage("");
    setPostCommentValue("");
    setPostCharacterCommentError("");
    clearPostCommentLocalStorage();
    // Reset textarea height
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Or any default height you prefer
    }
  };

  const loggedInUser = {
    id: "user-uuid-1",
    name: "AcctHolder",
  };
  const loggedInUserInfo = mockData.users.find(
    (user) => user.id === loggedInUser.id
  );
  const loggedInUserThumbnail = loggedInUserInfo
    ? loggedInUserInfo.imagePath
    : "";
  const postCommentWatcher = (value) => {
    const postCommentTextAreaLength = value.length;
    const maxAllowedCharacters = 150;
    if (postCommentTextAreaLength > maxAllowedCharacters) {
      setPostCharacterCommentError("Maximum 150 characters allowed");
      const truncatedValue = value.substring(0, maxAllowedCharacters);
      setPostCommentValue(truncatedValue);
    } else {
      setPostCharacterCommentError(false);
      setPostCommentValue(value);
    }
    // Update localStorage with the new value
    const postCommentKey = getStorageKey("postCommentValue", currentContext);
    localStorage.setItem(postCommentKey, value);
  };
  const clearPostCommentLocalStorage = () => {
    const postCommentKey = getStorageKey("postCommentValue", currentContext); // Assuming currentContext is defined in your component
    localStorage.removeItem(postCommentKey); // Remove the item from local storage
  };

  return (
    <div className="post-wrapper">
      <div className="form-post" ref={postContainerRef}>
        <div className="postTopContain">
          {/* Thumbnail for the logged-in user */}
          {loggedInUserThumbnail && (
            <div className="LoggedinPostCommentThumbnail" ref={thumbnailRef}>
              <img
                src={loggedInUserThumbnail}
                alt={loggedInUserInfo.username}
              />
            </div>
          )}
          <ImgTopBtn
            id="cancel-btn"
            imgTopBtnBorderRadius="25px"
            imgTopBtnText=" X Cancel"
            imgTopBtnWidth="fit-content"
            imgTopBtnTextFontSize="1.3rem"
            imgTopBtnnFontFamily="Roboto-Slab"
            imgTopBtnFontWeight="900"
            imgTopBtnFontColor="#964b00"
            paddingRight="5px"
            paddingLeft="5px"
            extraImgTopBtnStyles={{
              bottom: "20px",
            }}
            onClick={() => {
              // Add any logic here that you need to perform on cancel
              onClose(); // This should close the form
            }}
          />
        </div>
        <div
          className="discussion-input-container postComment-container"
          ref={postContainerRef}
        >
          <textarea
            className={` ${postCharacterCommentError ? "error-message" : ""}`}
            ref={textareaRef}
            value={postCommentValue}
            onInput={() =>
              handleTextareaResize(textareaRef, thumbnailRef, buttonsRef)
            }
            placeholder={"What is on your mind..."}
            onChange={(e) =>
              postCommentWatcher(e.target.value, "postCommentValue")
            }
            onFocus={() => setPostCommentValueFocused(true)}
            onBlur={() => setPostCommentValueFocused(false)}
            rows="5"
          />
          {!postCommentValueFocused && postCommentValue === "" && (
            <span className="blinking-cursor blinking-middle">|</span>
          )}
          {postCharacterCommentError && (
            <div className="character-error">{postCharacterCommentError}</div>
          )}
          {/* Conditional rendering for the clear button */}
        </div>
        {uploadPostFileError && (
          <div className="error-message">{uploadPostFileError}</div>
        )}
        <div className="file-upload-wrapper">
          <input
            id="file-upload"
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the input field
          />
        </div>
        <div className="post-form-buttons">
          {postCommentValue && (
            <div className="post-clear-comment-button">
              <button
                className="clear-button"
                ref={clearPostBtnRef}
                onClick={() => {
                  resetPostForm("");
                }}
              >
                Clear
              </button>
            </div>
          )}
          <ul className="post-comment-btns" ref={buttonsRef}>
            <li
              className={`postListItem ${
                postCommentValue || isImageUploaded ? "active" : "disabled"
              }`}
              style={{
                cursor:
                  postCommentValue || isImageUploaded
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              <TouchIcon
                width="25"
                height="25"
                fillColor={
                  postCommentValue || isImageUploaded ? "#fdfd96" : "#ccc"
                }
              />
              <span
                className="icon-label"
                style={{
                  color:
                    postCommentValue || isImageUploaded ? "#fdfd96" : "#ccc",
                }}
              >
                Tag
              </span>
            </li>
            <li className="postListItem">
              <CameraIcon
                width="25"
                height="25"
                id="cameraIcon"
                style={{ cursor: "pointer" }}
                onClick={triggerFileUpload} // Trigger file upload on click
              />
              <span className="icon-label" style={{ color: "#fdfd96" }}>
                Upload
              </span>
            </li>
            <li
              className={`postListItem ${
                postCommentValue || isImageUploaded ? "active" : "disabled"
              }`}
              style={{
                cursor:
                  postCommentValue || isImageUploaded
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              <PaperPlane2Icon
                width="25"
                height="25"
                fillColor={
                  postCommentValue || isImageUploaded ? "#fdfd96" : "#ccc"
                }
              />
              <span
                className="icon-label"
                style={{
                  color:
                    postCommentValue || isImageUploaded ? "#fdfd96" : "#ccc",
                }}
              >
                Send
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default PostForm;
