/** ------------------------------
 * Convert a dollar amount to cents. This is useful for financial calculations
 * where cents are a more precise unit than dollars. The result is rounded to
 * avoid floating point errors and formatted with commas for readability.
 *
 * @param {number} dollars - The dollar amount to convert.
 * @returns {string} - The equivalent amount in cents, formatted as a string.
 */

// ----------------
/**
 * Convert a cent amount to dollars. This is the inverse of dollarsToCents.
 * It converts an amount represented in cents back to a dollar value, formatted
 * as a string with two decimal places.
 *
 * @param {number} cents - The cents amount to convert.
 * @returns {string} - The equivalent amount in dollars, formatted as a string.
 */

// This function converts a cent amount to dollars by dividing it by 100. 
// It includes a check to handle non-numeric or NaN inputs and returns "0.00" 
// in such cases. It then uses formatWithCommasAndDecimals to format the 
// result as a string with two decimal places.
// --------
export const centsToDollarsCalc = (cents) => {
  const dollars = cents / 100;
  return dollars.toFixed(2); // Returns the amount in dollars with two decimal places
};
// This function converts a cent amount to dollars by dividing it by 100. It uses 
// toFixed(2) to format the result with exactly two decimal places and returns it as a string.

// -----
export const dollarsToCentsCalc = (dollars) => {
  const cents = Math.round(dollars * 100);
  return cents; // Returns the value in cents without formatting
};
// This function converts a dollar amount to cents by multiplying it by 
// 100 and rounding it. It returns the result as a numeric value 
// (not formatted as a string).

