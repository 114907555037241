import React from "react";
import Connections from "../connections/connections";
import GlassBtn from "../buttons/glassBtn/glassBtn";
import "./statusList.scss";

const StatusList = ({
  status,
  type, // added 'type' prop to determine if it's a calendar or group event
  userList,
  handleExclude,
  handleUnInvite,
  handleInclude,
  handleMakeHost,
  handleMakeCohost,
  handleDemoteHost,
  handleDemoteCohost,
  handleApprove, // new handler for backend testing
  handleDecline, // new handler for backend testing
  handleRemove,
}) => {
  return (
    <div>
      <div className="statusContainer">
        {userList.map((user) => (
          <div key={user.id}>
            <Connections
              userId={user.id}
              username={user.username}
              firstName={user.firstName}
              lastName={user.lastName}
              profileThumbnail={user.imagePath}
            />
            <div className="statusGlassBtns">
              {status !== "excluded" &&
                status !== "members" &&
                status !== "interested" &&
                status !== "attending" &&
                status !== "declined" && (
                  <>
                    <GlassBtn
                      onClick={() => handleExclude(user.username, status)}
                      glassBtnText="Exclude"
                      glassBtnFontColor="#e2b645"
                      glassBtnTextDecorationColor="#e2b645"
                      glassBtnFontFamily="roboto-slab"
                      glassBtnTextFontSize="1.3rem"
                      glassBtnFontWeight="800"
                      glassBtnHeight="20px"
                      glassHoverStyle={{
                        height: "60px",
                      }}
                    />
                    <GlassBtn
                      onClick={() => handleUnInvite(user.username)}
                      glassBtnFontColor="#e2b645"
                      glassBtnTextDecorationColor="#e2b645"
                      glassBtnFontFamily="roboto-slab"
                      glassBtnTextFontSize="1.3rem"
                      glassBtnFontWeight="800"
                      glassBtnText="UnInvite"
                      glassBtnHeight="20px"
                      glassHoverStyle={{
                        height: "60px",
                      }}
                    />
                    {status === "admin_party" && (
                      <div>
                        <GlassBtn
                          text="Make Host"
                          onClick={() => handleMakeHost(user.id)}
                        />
                        <GlassBtn
                          text="Make Cohost"
                          onClick={() => handleMakeCohost(user.id)}
                        />
                      </div>
                    )}
                    {status === "host" && (
                      <GlassBtn
                        text="Demote"
                        onClick={() => handleDemoteHost(user.id)}
                      />
                    )}
                    {status === "cohost" && (
                      <GlassBtn
                        text="Demote"
                        onClick={() => handleDemoteCohost(user.id)}
                      />
                    )}
                  </>
                )}
              {(status === "members" || status === "attending") && (
                <GlassBtn
                  onClick={() => handleRemove(user.username, status)}
                  glassBtnText="Remove"
                  glassBtnFontColor="#e2b645"
                  glassBtnTextDecorationColor="#e2b645"
                  glassBtnFontFamily="roboto-slab"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="800"
                  glassBtnHeight="20px"
                  glassHoverStyle={{
                    height: "60px",
                  }}
                />
              )}
              {status === "excluded" && (
                <GlassBtn
                  onClick={() => handleInclude(user.username)}
                  glassBtnFontColor="#e2b645"
                  glassBtnTextDecorationColor="#e2b645"
                  glassBtnFontFamily="roboto-slab"
                  glassBtnTextFontSize="1.3rem"
                  glassBtnFontWeight="800"
                  glassBtnText="Include"
                  glassBtnHeight="20px"
                  glassHoverStyle={{
                    height: "60px",
                  }}
                />
              )}
              {status === "interested" && (
                <>
                  <GlassBtn
                    onClick={() => handleApprove(user.username, status)}
                    glassBtnText="Approve"
                    glassBtnFontColor="#e2b645"
                    glassBtnTextDecorationColor="#e2b645"
                    glassBtnFontFamily="roboto-slab"
                    glassBtnTextFontSize="1.3rem"
                    glassBtnFontWeight="800"
                    glassBtnHeight="20px"
                    glassHoverStyle={{
                      height: "60px",
                    }}
                  />
                  <GlassBtn
                    onClick={() => handleDecline(user.username, status)}
                    glassBtnText="Decline"
                    glassBtnFontColor="#e2b645"
                    glassBtnTextDecorationColor="#e2b645"
                    glassBtnFontFamily="roboto-slab"
                    glassBtnTextFontSize="1.3rem"
                    glassBtnFontWeight="800"
                    glassBtnHeight="20px"
                    glassHoverStyle={{
                      height: "60px",
                    }}
                  />
                </>
              )}
              {type === "groupEvent" && (
                <>
                  {status === "pending" && (
                    <>
                      <GlassBtn
                        text="Approve"
                        onClick={() => handleApprove(user.id)}
                      />
                      <GlassBtn
                        text="Decline"
                        onClick={() => handleDecline(user.id)}
                      />
                    </>
                  )}
                  {status === "approved" && (
                    <GlassBtn
                      text="Exclude"
                      onClick={() => handleExclude(user.username, status)}
                    />
                  )}
                  {status === "declined" && (
                    <GlassBtn
                      text="Reconsider"
                      onClick={() => handleApprove(user.id)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusList;
