import React from "react";
import { CONTEXT_TYPES } from "../../../src/constants/contextType";
import {
  SignIn,
  SignUp,
  EventPopup,
  GroupPopup,
  AccountVerification,
  PhoneVerification,
  ResetPassword,
  PostForm,
} from "./index";
const AuthorizationForm = ({
  currentForm,
  setCurrentForm, // Receiving setCurrentForm/ This prop decides which form to display
  contextType,
  setContextType,
  updateEvent,
  updateGroup,
  updatePost,
}) => {
  console.log("AuthorizationForm - updateEvent:", updateEvent);
  return (
    <div>
      {contextType === CONTEXT_TYPES.SIGN_UP &&
        currentForm === CONTEXT_TYPES.SIGN_UP && (
          <SignUp
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
          />
        )}
      {contextType === CONTEXT_TYPES.SIGN_IN &&
        currentForm === CONTEXT_TYPES.SIGN_IN && (
          <SignIn
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
          />
        )}
      {contextType === CONTEXT_TYPES.Account_Verification &&
        currentForm === CONTEXT_TYPES.Account_Verification && (
          <AccountVerification
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
          />
        )}
      {contextType === CONTEXT_TYPES.Verify_Account &&
        currentForm === CONTEXT_TYPES.Verify_Account && (
          <PhoneVerification
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
          />
        )}
      {contextType === CONTEXT_TYPES.Reset_Password &&
        currentForm === CONTEXT_TYPES.Reset_Password && (
          <ResetPassword
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
          />
        )}
      {contextType === CONTEXT_TYPES.Event_Popup_Create &&
        currentForm === CONTEXT_TYPES.Event_Popup_Create && (
          <EventPopup
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
            updateEvent={updateEvent}
          />
        )}
      {contextType === CONTEXT_TYPES.Group_Popup_Create &&
        currentForm === CONTEXT_TYPES.Group_Popup_Create && (
          <GroupPopup
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
            updateGroup={updateGroup}
          />
        )}
      {contextType === CONTEXT_TYPES.Post_Form &&
        currentForm === CONTEXT_TYPES.Post_Form && (
          <PostForm
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            setContextType={setContextType}
            updatePost={updatePost}
          />
        )}
    </div>
  );
};
export default AuthorizationForm;
