import React, { useState, useRef, useEffect } from "react";
import { CameraIcon, TouchIcon, PaperPlane2Icon } from "../../components/icons";
import { ImgTopBtn } from "../buttons/index.jsx";
import { handleFileChange } from "../../utils/changeFileHandler.js";
import mockData from "../../seedMockData/seedMockData";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import { cancelComment } from "../../controller/watcherAndClearFunctions/commentClearsWatchersFunctions.js";
import "./comment.scss";
// target id is ether group or post id...
const UserComment = ({
  currentContext,
  targetId,
  replyToId = null,
}) => {
  const textareaRef = useRef(null);
  const buttonsRef = useRef(null);
  const thumbnailRef = useRef(null);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [userCommentValue, setUserCommentValue] = useState("");
  const [userCharacterCommentErrorValue, setUserCharacterCommentError] =
    useState("");
  const [CommentFile, setCommentFile] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [userCommentValueFocused, setUserCommentValueFocused] = useState(false);

  const loggedInUser = {
    id: "user-uuid-1",
    name: "AcctHolder",
  };
  const loggedInUserInfo = mockData.users.find(
    (user) => user.id === loggedInUser.id
  );
  const loggedInUserThumbnail = loggedInUserInfo
    ? loggedInUserInfo.imagePath
    : "";



  const adjustHeights = () => {
    const textarea = textareaRef.current;
    const container = containerRef.current;
    if (textarea && container) {
      // Reset textarea height to shrink if content is removed
      textarea.style.height = "auto";
      // Set textarea height based on scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`;
      // Adjust the container's height to match the textarea
      container.style.height = `${textarea.scrollHeight}px`;
      // grow from bottom will allow the container to grow from bottom up, this will be used on the groups detail page
      // state is set up top and it will be set to true on the child
    }
  };
  useEffect(() => {
    adjustHeights();
  }, [userCommentValue]);
  const userCommentWatcher = (value) => {
    const userPostCommentTextAreaLength = value.length;
    const maxAllowedCharacters = 150;
    if (userPostCommentTextAreaLength > maxAllowedCharacters) {
      // If length exceeds 150 characters, display error message and truncate the input
      setUserCharacterCommentError("Maximum 150 characters allowed");
      // Truncate the value to the maximum allowed characters
      const truncatedValue = value.substring(0, maxAllowedCharacters);
      setUserCommentValue(truncatedValue);
      // Update localStorage with the truncated value
      const userPostCommentKey = getStorageKey(
        "userCommentValue",
        currentContext
      );
      localStorage.setItem(userPostCommentKey, truncatedValue);
    } else {
      // Update the value if within the limit and clear any error message
      setUserCharacterCommentError(false);
      setUserCommentValue(value); // Update localStorage with the new value
      const userPostCommentKey = getStorageKey(
        "userCommentValue",
        currentContext
      );
      localStorage.setItem(userPostCommentKey, value);
    }
  };
  const clearUserPostCommentLocalStorage = () => {
    setUserCommentValue(""); // Clear the textarea
    // setUserCommentValue("");
    const userPostCommentKey = getStorageKey(
      "userCommentValue",
      currentContext
    ); // Assuming currentContext is defined in your component
    localStorage.removeItem(userPostCommentKey); // Remove the item from local storage
  };
  // Function to trigger file upload when camera icon is clicked
  const triggerFileUpload = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };
  const handleUploadClick = () => {
    triggerFileUpload(fileInputRef);
  };

  const onFileChange = (event) => {
    handleFileChange(event, setCommentFile, currentContext, setIsImageUploaded);
  };
  // Use `targetId` to determine the target of the comment
  const sendComment = () => {
    if (userCommentValue && targetId) {
      postComment(targetId, userCommentValue, replyToId);
      // Reset comment field after sending
      setUserCommentValue("");
    }
  };
  // Placeholder function for posting comments or replies
  const postComment = (targetId, comment, replyToId) => {
    console.log(
      `Posting comment to target ID ${targetId}, reply to ID ${replyToId}: ${comment}`
    ); // Logic/API call to post the comment or reply to the specified target
  };
  
  return (
    <div className="userComment-container">
      {loggedInUserThumbnail && (
        <div className="LoggedinUserPostThumbnail" ref={thumbnailRef}>
          <img src={loggedInUserThumbnail} alt={loggedInUserInfo.username} />
        </div>
      )}
      <div className="content-buttons-container" ref={containerRef}>
        <textarea
          ref={textareaRef}
          value={userCommentValue}
          onChange={(e) =>
            userCommentWatcher(e.target.value, "userCommentValue")
          }
          placeholder="Write your comment..."
        />
      </div>
      {userCharacterCommentErrorValue && (
        <div className="character-error">{userCharacterCommentErrorValue}</div>
      )}
      <div className="buttons-container" ref={buttonsRef}>
        <ul className="UserPostDetails-discussion-buttons" ref={buttonsRef}>
          <li
            className={`UserPostDetails-listItem ${
              userCommentValue ? "active" : "disabled"
            }`}
            style={{
              cursor:
                userCommentValue || isImageUploaded ? "pointer" : "not-allowed",
            }}
          >
            <TouchIcon
              width="25"
              height="25"
              fillColor={
                userCommentValue || isImageUploaded ? "#fdfd96" : "#ccc"
              }
            />
            <span
              className="icon-label"
              style={{
                color: userCommentValue || isImageUploaded ? "#fdfd96" : "#ccc",
              }}
            >
              Tag
            </span>
          </li>
          <li className="UserPostDetails-listItem">
            <CameraIcon
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
              onClick={handleUploadClick} // Use triggerFileUpload function on click
            />
            <span className="icon-label" style={{ color: "#fdfd96" }}>
              Upload
            </span>
          </li>
          <li
            className={`UserPostDetails-listItem ${
              userCommentValue ? "active" : "disabled"
            }`}
            style={{
              cursor: userCommentValue ? "pointer" : "not-allowed",
            }}
          >
            <PaperPlane2Icon
              width="25"
              height="25"
              fillColor={
                userCommentValue || isImageUploaded ? "#fdfd96" : "#ccc"
              }
            />
            <span
              className="icon-label"
              style={{
                color: userCommentValue || isImageUploaded ? "#fdfd96" : "#ccc",
              }}
            >
              Send
            </span>
          </li>
        </ul>
        <div className="file-upload-wrapper">
          <input
            id="file-upload"
            ref={fileInputRef}
            type="file"
            onChange={onFileChange} // Use handleFileChange function on file input change
            style={{ display: "none" }} // Hide the input field
          />
        </div>
        {userCommentValue && (
          <button
            className="clear-button"
            onClick={() => {
              setUserCommentValue(""); // Clear the textarea
              setUserCharacterCommentError(false); // Reset the error message
              clearUserPostCommentLocalStorage("");
            }}
          >
            Clear
          </button>
        )}
        {(userCommentValue || isImageUploaded) && (
          <ImgTopBtn
            id="cancel-btn"
            imgTopBtnBorderRadius="25px"
            imgTopBtnText=" X Cancel"
            imgTopBtnWidth="fit-content"
            imgTopBtnTextFontSize="1.3rem"
            imgTopBtnnFontFamily="Roboto-Slab"
            imgTopBtnFontWeight="900"
            imgTopBtnFontColor="#964b00"
            paddingRight="5px"
            paddingLeft="5px"
            extraImgTopBtnStyles={{
              bottom: "20px",
              left: "10px",
            }}
            onClick={() =>
              cancelComment(
                setUserCommentValue, // Function to reset the reply value
                setUserCharacterCommentError,
                setCommentFile,
                currentContext,
                setIsImageUploaded,
                fileInputRef,
              )
            }
          />
        )}
      </div>
      {!userCommentValueFocused && userCommentValue === "" && (
        <span className="blinking-cursor blinking">|</span>
      )}
    </div>
  );
};
export default UserComment;
// API Endpoint: Your backend should have an API endpoint that provides user information, including the thumbnail image path. This endpoint might look something like /api/user/{userId}.

// Fetching Data: In your React component, you will need to fetch this data from the backend. This is typically done using fetch or libraries like axios.

// State Management: Once the data is fetched, you can use React's state management to store the user information. This state will then be used to render the component.

// Use Effect for Data Fetching: You can use the useEffect hook to trigger the data fetch when the component mounts.

// State Management: Once the data is fetched, you can use React's state management to store the user information. This state will then be used to render the component.
// const [loggedInUserInfo, setLoggedInUserInfo] = useState(null);

// useEffect(() => {
//   const userId = "user-uuid-1"; // Replace with dynamic ID as needed
//   axios.get(`/api/user/${userId}`)
//     .then(response => {
//       setLoggedInUserInfo(response.data);
//     })
//     .catch(error => {
//       console.error('Error fetching user data:', error);
//     });
// }, []);

// if (!loggedInUserInfo) {
//   return <div>Loading...</div>; // or any other loading state
// }

// const loggedInUserThumbnail = loggedInUserInfo.imagePath;
// If authentication is required, ensure that your API requests are properly authenticated (e.g., sending an authentication token).
