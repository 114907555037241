import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Connections from "../../components/connections/connections";
import { SlideBtn, GlassBtn, SolidColorButton } from "../../components/buttons";
import {
  FindUsername,
  Back,
  LineThroughCircle,
  UnlockIcon,
  UnfollowIcon,
} from "../../components/icons";
import { debounce } from "../../utils/searchDebounceUtils";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import mockData from "../../seedMockData/seedMockData";
import "./connectionsPage.scss";

const ConnectionsPage = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  // console.log(username);
  const currentContext = "connectsionPage";
  const [showFollowersList, setShowFollowersList] = useState(false);
  const [showFollowingList, setShowFollowingList] = useState(false);
  const [showBlockedUsersList, setShowBlockedUsersList] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(mockData.users);
  const [userNameValueSearchCriteria, setUserNameValueSearchCriteria] =
    useState(""); // used to monitor when the input changes will set up a handler to see the value change
  const [onUserNameSearchValueFocused, setOnUserNameSearchValueFocused] =
    useState(false);
  const [onUserNameSearchValueBlur, setOnUserNameSearchValueBlur] =
    useState(false);
  const [userNameSearchError, setUserNameSearchError] = useState(false);
  const [isSearchInputEmpty, setIsSearchInputEmpty] = useState(false); // used to check if the input is empty for error handling and to reset state with is button pressed
  const [isSliderSlid, setIsSliderSlid] = useState(false); // an event listener will be used for actviiting error messages after the slider has slid if conditions are not met
  const [isFocused, setIsFocused] = useState(false);
  const [blocked, setBlockedUser] = useState([]);
  const [followers, setFollowersList] = useState([]);
  const [following, setFollowingList] = useState([]);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [filteredFollowers, setFilteredFollowers] = useState([]);
  const [filteredFollowing, setFilteredFollowing] = useState([]);
  const [filteredBlocked, setFilteredBlocked] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false); // Add this line to define isSearchMode state

  // Toggle functions
  const toggleFollowersList = () => {
    console.log("Followers button clicked");
    setShowFollowersList(true);
    setShowFollowingList(false);
    setShowBlockedUsersList(false);
  };

  const toggleFollowingList = () => {
    console.log("Following button clicked");
    setShowFollowersList(false);
    setShowFollowingList(true);
    setShowBlockedUsersList(false);
  };

  const toggleBlockedUsersList = () => {
    console.log("Blocked button clicked");
    setShowFollowersList(false);
    setShowFollowingList(false);
    setShowBlockedUsersList(true);
  };

  // references the search bar in the render section or html
  // for the following references below to work
  const searchBarRef = useRef(null);
  // used for the underlay scss so user's can see buttons and input
  // when it scrolls
  const filterNavRef = useRef(null);
  // used for active color so i can manipulate the scss for error handling
  // and filtering the active list
  const followersRef = useRef(null);
  const followingRef = useRef(null);
  const blockingRef = useRef(null);

  const isAnyButtonActive = () =>
    (followersRef.current &&
      followersRef.current.classList.contains("followersNum-active")) ||
    (followingRef.current &&
      followingRef.current.classList.contains("followingNum-active")) ||
    (blockingRef.current &&
      blockingRef.current.classList.contains("blockingNum-active"));

  // invoking the function below followers.length to get count and show correct ending
  // const glassBtnText = SingularPlural(
  //   followers.length,
  //   "Follower",
  //   "Followers"
  // );
  const user = {
    id: "user-uuid-5",
    username: "AcctHolder",
  };
  const isProfileOwner = user.username === username;
  const isOtherUser = !isProfileOwner;
  useEffect(() => {
    console.log("useEffect triggered");
    const foundUserProfile = mockData.users.find(
      (user) => user.username === username
    );
    if (foundUserProfile) {
      const followersLogs = mockData.activityLogs.filter(
        // Fetching Followers
        (log) =>
          log.targetId === foundUserProfile.id && log.activity === "following"
      );
      const followers = followersLogs
        .map((log) => {
          const followerInfo = mockData.users.find(
            (user) => user.id === log.sourceId
          );
          return followerInfo ? followerInfo.username : null;
        })
        .filter(Boolean);

      const followingLogs = mockData.activityLogs.filter(
        // Fetching Following
        (log) =>
          log.sourceId === foundUserProfile.id && log.activity === "following"
      );
      const following = followingLogs
        .map((log) => {
          const followingInfo = mockData.users.find(
            (user) => user.id === log.targetId
          );
          return followingInfo ? followingInfo.username : null;
        })
        .filter(Boolean);
      const posts = mockData.posts.reduce((acc, post) => {
        if (post.userId === foundUserProfile.id && post.imagePath) {
          // Directly use imagePath from the post
          acc.push({
            ...post, // Spread the existing properties of the post
            media: post.imagePath, // Add the imagePath as 'media' property
          });
        }
        return acc;
      }, []);

      const blockedLogs = mockData.activityLogs.filter(
        // Fetching Blocked Users
        (log) =>
          log.sourceId === foundUserProfile.id && log.activity === "blocking"
      );
      const blockedUsers = blockedLogs
        .map((log) => {
          const blockedUserInfo = mockData.users.find(
            (user) => user.id === log.targetId
          );
          return blockedUserInfo ? blockedUserInfo.username : null;
        })
        .filter(Boolean);
      setFollowersList(followers);
      setFollowingList(following);
      setBlockedUser(blockedUsers);
    }
  }, [username]);

  function handleFollow(username) {
    return new Promise((resolve, reject) => {
      const targetUser = mockData.users.find((u) => u.username === username);
      const hasBlocked = mockData.activityLogs.some(
        (log) =>
          log.sourceId === user.id &&
          log.targetId === targetUser.id &&
          log.activity === "blocking"
      );
      if (targetUser && !hasBlocked) {
        mockData.activityLogs.push({
          id: `activity-log-uuid-${Date.now()}`,
          sourceId: user.id,
          activity: "following",
          targetId: targetUser.id,
        });
        resolve("Followed successfully");
      } else {
        reject("Unable to follow");
      }
    });
  }
  function handleUnfollow(username) {
    const targetUser = mockData.users.find((u) => u.username === username);
    if (targetUser) {
      mockData.activityLogs = mockData.activityLogs.filter((log) => {
        return !(
          log.sourceId === user.id &&
          log.targetId === targetUser.id &&
          log.activity === "following"
        );
      });

      // Now update the local state for instant UI feedback
      setFollowingList((prevFollowing) =>
        prevFollowing.filter((u) => u !== username)
      );
      setFollowersList((prevFollowers) =>
        prevFollowers.filter((u) => u !== username)
      );
    }
  }
  function handleBlock(username) {
    console.log("blocking", username);
    const targetUser = mockData.users.find((u) => u.username === username);
    if (targetUser) {
      mockData.activityLogs.push({
        id: `acitivity-log-uuid-${Date.now()}`,
        sourceId: user.id,
        activity: "blocking",
        targetId: targetUser.id,
      });
      // Now update the local state for instant UI feedback
      setBlockedUser((prevBlocked) => [...prevBlocked, username]);
      setFollowingList((prevFollowing) =>
        prevFollowing.filter((u) => u !== username)
      );
    }
    // You can also remove any following relations between the user and targetUser here.
    mockData.activityLogs = mockData.activityLogs.filter((log) => {
      return !(
        log.sourceId === user.id &&
        log.targetId === targetUser.id &&
        log.activity === "following"
      );
    });
  }

  function handleUnblock(username) {
    return new Promise((resolve, reject) => {
      const targetUser = mockData.users.find((u) => u.username === username);
      if (targetUser) {
        mockData.activityLogs = mockData.activityLogs.filter((log) => {
          return !(
            log.sourceId === user.id &&
            log.targetId === targetUser.id &&
            log.activity === "blocking"
          );
        });
        setBlockedUser((prevBlocked) =>
          prevBlocked.filter((u) => u !== username)
        );

        console.log("Unblocked user:", username);
        resolve("Unblocked successfully");
      } else {
        reject("User not found");
      }
    });
  }
  const userNameWatcher = (value) => {
    setUserNameValueSearchCriteria(value);
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.setItem(userNameKey, value);
    if (userNameSearchError) {
      setUserNameSearchError("");
    }
  };

  // invoking the code to change the relationship
  // make an API call to the backend to process
  // this change, and the backend would then
  // return the updated data, which you'd reflect
  // // in your UI.
  // POST endpoint that expects the user ID (or username) of the user to unblock.
  // The backend will then process this request, update the database accordingly,
  // and respond with a success message or the updated data.
  // When a user clicks "Unblock and Follow", make a call to the "unblock" endpoint.
  //  Once that's successful, you can then call the "follow" endpoint.
  // After the API call succeeds, update your local state or make another
  // API call to fetch the updated list of blocked/following users, and
  // then update your UI.
  function handleUnblockAndFollow(username) {
    handleUnblock(username)
      .then(() => handleFollow(username))
      .catch(console.error);
  }
  // Event listener for changes in the search input
  const handleChange = (e) => {
    const value = e.target.value;
    setUserNameValueSearchCriteria(value);
    setIsSearchInputEmpty(!value); // If value is empty, it will be true, else false
  };
  const handleSlideCompletion = () => {
    if (!userNameValueSearchCriteria && !isButtonPressed) {
      // Show the "Required" message
      setIsSliderSlid(true);
      setIsSearchInputEmpty(true);
      setUserNameSearchError("Required"); // Set error message when input is empty
    } else {
      const searchResults = mockData.users.filter((user) =>
        user.username
          .toLowerCase()
          .includes(userNameValueSearchCriteria.toLowerCase())
      );
      setFilteredUsers(searchResults);
      setIsSliderSlid(true); // Show the buttons
      setIsSearchInputEmpty(false); // Hide the "Required" message
      // Filter the active list based on the userNameValueSearchCriteria
      setFilteredFollowers(
        followers.filter((user) => user.includes(userNameValueSearchCriteria))
      );
      setFilteredFollowing(
        following.filter((user) => user.includes(userNameValueSearchCriteria))
      );
      setFilteredBlocked(
        blocked.filter((user) => user.includes(userNameValueSearchCriteria))
      );

      setIsSearchMode(true);
    }
    // Reset the button press status after sliding
    setIsButtonPressed(false);
  };
  const handleButtonPress = () => {
    setIsButtonPressed(true);
    setIsSliderSlid(false);
  };
  useEffect(() => {
    // Reset isSearchMode to false when userNameValueSearchCriteria is empty
    if (userNameValueSearchCriteria === "") {
      setIsSearchMode(false);
    }
  }, [userNameValueSearchCriteria]);
  const debouncedHandleSlideCompletion = debounce(handleSlideCompletion, 300);
  const clearUserNameValueSearchCriteriaLocalStorage = () => {
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.removeItem(userNameKey);
    setUserNameValueSearchCriteria(""); // Reset the state
  };
  const show_RedBorder_Required = !isAnyButtonActive() && isSliderSlid;

  return (
    <div id="pageContainer">
      <div
        className="filterNav stickyTop Connectionsunderlay"
        ref={filterNavRef}
      >
        <div className="backBtnConnecsPg">
          <SolidColorButton
            solidColorBtnImage={
              <Back
                height="15px"
                width="15px"
                fillColor="#964b00"
                strokeWidth="4"
                strokeColor="#964b00"
              />
            }
            className="gold-btn"
            solidColorBtnText="Back"
            solidColorBtnFontWeight="800"
            solidColorBtnFontFamily="helvetica"
            solidColorBtnTextFontSize="1.3rem"
            solidColorBtnFontColor="#964b00"
            solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            onClick={() => navigate(-1)}
          >
            🔙
          </SolidColorButton>
        </div>
        <section id="search-bar-wrapper" ref={searchBarRef}>
          <div className="connections-input-slider-container">
            <div className="connections-input-container blinker-wrapper">
              <input
                placeholder={
                  userNameSearchError ? userNameSearchError : "Enter username"
                }
                className={userNameSearchError ? "required-message" : ""}
                type="text"
                value={userNameValueSearchCriteria}
                onChange={(e) =>
                  userNameWatcher(e.target.value, "userNameValue")
                }
                onFocus={() => {
                  setOnUserNameSearchValueFocused(false);
                  setUserNameValueSearchCriteria(""); // Reset the input value
                  setIsSearchInputEmpty(false); // Hide the "Required" message when focused
                }}
                onBlur={() => setOnUserNameSearchValueBlur(false)}
              />
              {!isFocused && userNameValueSearchCriteria === "" && (
                <span className="blinking-cursor blinking">|</span>
              )}
              {isSearchInputEmpty && !isButtonPressed && (
                <span className="connects-required-message">Required</span>
              )}
              {userNameValueSearchCriteria && (
                <button
                  className="Clear-eventDetailsPgBtn"
                  onClick={clearUserNameValueSearchCriteriaLocalStorage}
                  style={{
                    color: "#fdfd96",
                    backgroundColor: "transparent",
                    borderColor: "#fdfd96",
                  }}
                >
                  Clear
                </button>
              )}
            </div>
            <SlideBtn
              sliderImage={() => <FindUsername fillColor="#964B00" />}
              className="searchSlidConnectionsPg"
              slideBtnText="Slide to find username"
              slideBtnWidth="300px"
              onHandleSlideEnd={debouncedHandleSlideCompletion}
            />
          </div>
        </section>
        <div
          className={`${
            show_RedBorder_Required && !isAnyButtonActive() && isSliderSlid
              ? "red-error-border"
              : ""
          }`}
        >
          {show_RedBorder_Required && (
            <span className="error-message">{userNameSearchError}</span>
          )}
          <div className="btnsContain buttonsContainer-wrapper">
            <GlassBtn
              onClick={() => {
                handleButtonPress();
                toggleFollowersList();
              }}
              glassBtnImage={
                <span
                  ref={followersRef}
                  className={`followersNum ${
                    showFollowersList
                      ? "followersNum-active"
                      : "followersNum-inactive"
                  }`}
                >
                  {followers.length}
                </span>
              }
              imageOnTop={true}
              glassBtnFontColor={showFollowersList ? "#fff" : "#fdfd96"}
              glassBtnHeight="27px"
              glassHoverStyle={{
                height: showFollowersList ? "27px" : "27px",
              }}
              glassBtnTextDecorationColor={
                showFollowersList ? "#fff" : "#fdfd96"
              }
              glassBtnFontFamily="Helvetica"
              glassBtnTextFontSize="1.2rem"
              glassBtnFontWeight="800"
              glassBtnText={
                showFollowersList ? "Followers List" : "Show Followers List"
              }
              data-num={followers.length}
              data-text="Followers"
            />
            <GlassBtn
              glassBtnImage={
                <span
                  ref={followingRef}
                  className={`followingNum ${
                    showFollowingList
                      ? "followingNum-active"
                      : "followingNum-inactive"
                  }`}
                >
                  {following.length}
                </span>
              }
              imageOnTop={true}
              onClick={() => {
                handleButtonPress();
                toggleFollowingList();
              }}
              glassBtnFontColor={showFollowingList ? "#fff" : "#e2b645"}
              glassBtnTextDecorationColor={
                showFollowingList ? "#fff" : "#e2b645"
              }
              glassBtnFontFamily="Helvetica"
              glassBtnTextFontSize="1.2rem"
              glassBtnFontWeight="800"
              glassBtnText={
                showFollowingList ? "Following List" : "Show Following List"
              }
              glassBtnHeight="27px"
              glassHoverStyle={{
                height: showFollowersList ? "27px" : "27px",
              }}
              data-num={following.length}
              // data-text="Following"
            />
            {isProfileOwner && (
              <GlassBtn
                onClick={() => {
                  handleButtonPress();
                  toggleBlockedUsersList();
                }}
                glassBtnImage={
                  <span
                    ref={blockingRef}
                    className={`blockingNum ${
                      showBlockedUsersList
                        ? "blockingNum-active"
                        : "blockingNum-inactive"
                    }`}
                  >
                    {blocked.length}
                  </span>
                }
                imageOnTop={true}
                glassBtnFontColor={showBlockedUsersList ? "#fff" : "#fdfd96"}
                glassBtnHeight="27px"
                glassHoverStyle={{
                  height: showFollowersList ? "27px" : "27px",
                }}
                glassBtnTextDecorationColor={
                  showBlockedUsersList ? "#fff" : "#fdfd96"
                }
                glassBtnFontFamily="Helvetica"
                glassBtnTextFontSize="1.2rem"
                glassBtnFontWeight="800"
                glassBtnText={
                  showBlockedUsersList ? "Blocked List" : "Show Blocked List"
                }
              />
            )}
          </div>
        </div>
      </div>
      <section className="connections-list">
        {showFollowersList && (
          <>
            <h4 className="ConnectionsPgHeader">
              Followers: Slide right to view profile
            </h4>
            <div className="listsContainer">
              {(isSearchMode ? filteredFollowers : followers).map(
                (follower) => {
                  const user = mockData.users.find(
                    (u) => u.username === follower
                  );
                  return (
                    <div key={user.id}>
                      <Connections
                        userId={user.id}
                        username={user.username}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        profileThumbnail={user.imagePath}
                      />
                      {isProfileOwner && (
                        <>
                          <GlassBtn
                            glassBtnImage={
                              <LineThroughCircle
                                strokeColor="#fdfd96"
                                height="25px"
                                width="25px"
                              />
                            }
                            onClick={() => handleBlock(user.username)}
                            glassBtnFontColor="#fdfd96"
                            glassBtnTextDecorationColor="#fdfd96"
                            glassBtnFontFamily="roboto-slab"
                            glassBtnTextFontSize="1.3rem"
                            glassBtnFontWeight="800"
                            glassBtnText="Block"
                          />
                          <GlassBtn
                            glassBtnImage={
                              <UnfollowIcon
                                height="25px"
                                width="25px"
                                fillColor="#e2b645"
                              />
                            }
                            onClick={() => handleUnfollow(user.username)}
                            glassBtnFontColor="#e2b645"
                            glassBtnTextDecorationColor="#e2b645"
                            glassBtnFontFamily="roboto-slab"
                            glassBtnTextFontSize="1.3rem"
                            glassBtnFontWeight="800"
                            glassBtnText="Unfollow"
                          />
                          {/* Add more buttons for profile owner */}
                        </>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}

        {showFollowingList && (
          <>
            <h4 className="ConnectionsPgHeader">
              Following: Slide right to view profile
            </h4>
            <div className="listsContainer">
              {(isSearchMode ? filteredFollowing : following).map(
                (followingUser) => {
                  const user = mockData.users.find(
                    (u) => u.username === followingUser
                  );
                  return (
                    <div key={user.id}>
                      <Connections
                        userId={user.id}
                        username={user.username}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        profileThumbnail={user.imagePath}
                      />
                      {isProfileOwner && (
                        <>
                          <GlassBtn
                            glassBtnImage={
                              <LineThroughCircle
                                strokeColor="#fdfd96"
                                height="25px"
                                width="25px"
                              />
                            }
                            onClick={() => handleBlock(user.username)}
                            glassBtnFontColor="#fdfd96"
                            glassBtnTextDecorationColor="#fdfd96"
                            glassBtnFontFamily="roboto-slab"
                            glassBtnTextFontSize="1.3rem"
                            glassBtnFontWeight="800"
                            glassBtnText="Block"
                          />
                          <GlassBtn
                            glassBtnImage={
                              <UnfollowIcon
                                height="25px"
                                width="25px"
                                fillColor="#e2b645"
                              />
                            }
                            onClick={() => handleUnfollow(user.username)}
                            glassBtnFontColor="#e2b645"
                            glassBtnTextDecorationColor="#e2b645"
                            glassBtnFontFamily="roboto-slab"
                            glassBtnTextFontSize="1.3rem"
                            glassBtnFontWeight="800"
                            glassBtnText="Unfollow"
                          />
                          {/* Add more buttons for profile owner */}
                        </>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}

        {isProfileOwner && showBlockedUsersList && (
          <>
            <h4 className="BlockedPgHeader">Blocked Members</h4>
            <div className="listsContainer">
              {(isSearchMode ? filteredBlocked : blocked).map((blockedUser) => {
                const user = mockData.users.find(
                  (u) => u.username === blockedUser
                );
                return (
                  <div key={user.id}>
                    <Connections
                      userId={user.id}
                      username={user.username}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      profileThumbnail={user.imagePath}
                      showChatSlider={false} // setting this to false so user does not navigate to the profile since source user blocked target user
                      showUsernameOffSlider={true} // set to false on the parent
                    />
                    {isProfileOwner && (
                      <>
                        <GlassBtn
                          glassBtnImage={
                            <UnlockIcon
                              fillColor="#e2b645"
                              height="25px"
                              width="25px"
                            />
                          }
                          onClick={() => handleUnblock(user.username)}
                          glassBtnFontColor="#e2b645"
                          glassBtnTextDecorationColor="#e2b645"
                          glassBtnHeight="37px"
                          glassHoverStyle={{
                            height: "50px",
                          }}
                          glassBtnFontFamily="roboto-slab"
                          glassBtnTextFontSize="1.3rem"
                          glassBtnFontWeight="800"
                          glassBtnText="Unblock"
                        />
                        <GlassBtn
                          onClick={() => handleUnblockAndFollow(user.username)}
                          glassBtnFontColor="#fdfd96"
                          glassBtnHeight="35px"
                          glassHoverStyle={{
                            height: "70px",
                          }}
                          top="5px"
                          glassBtnTextDecorationColor="#fdfd96"
                          glassBtnFontFamily="roboto-slab"
                          glassBtnTextFontSize="1.3rem"
                          glassBtnFontWeight="800"
                          glassBtnText="Unblock UnFollow"
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default ConnectionsPage;
