import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import mockData from "../../seedMockData/seedMockData.js";
import EventCard from "../../components/eventCard/eventCard.jsx";
import EventCardSansMedia from "../../components/eventCard/eventCardSansMedia.jsx.jsx";
import { EventPopup } from "../../components/forms"; // Import the EventPopup component
import SubCategoryPopup from "../../components/forms/categoryPopup/subCategoryPopup.jsx";
import {
  CreateEventBtn,
  WhiteGoldButton,
  GlassBtn,
  CustomButton,
  PlainBtn,
  WhiteBtn,
} from "../../components/buttons/index.jsx";
import {
  EyeIcon,
  EyeSlashIcon,
  XIcon,
  CommunityMagnifyingGlassIcon,
  CatagoriesIcon,
  OpenBookIcon,
  PinDropIcon,
  CalendarIcon,
} from "../../components/icons/index.jsx";
import GlobalThreeLineAnimation from "../../globalViews/globalThreeLineAnimation.jsx";
import StateDropdown from "../../components/stateDropdown/stateDropDown.jsx";
import categoryData from "../../constants/many-manyCat.json";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import categoriesData from "../../constants/many-manyCat.json";
import { convertToSlug } from "../../utils/stringUtils.js";
import { CategoryNameFormatSearch } from "../../components/forms/categoryPopup/catVertical.jsx";
import DatePicker from "../../components/datePicker/datePicker.jsx";
import "./eventsListPage.scss";

export default function EventsListPage({ updateEvent = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentContext = "eventsList";
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState("eventPopupCreate");
  const [contextType, setContextType] = useState("eventPopupCreate");
  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [isFieldScaled, setIsFieldScaled] = useState(false);
  const [cityFocused, setCityFocused] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [selectedState, setSelectedState] = useState(
    localStorage.getItem(
      getStorageKey("eventsListSelectedState", currentContext)
    ) || ""
  ); // State value with local storage persistence for selected state value
  const [cityValue, setCityValue] = useState(
    localStorage.getItem(
      getStorageKey("eventsListCityValue", currentContext)
    ) || ""
  ); // City value with local storage persistence
  const [zipCodeValue, setZipCodeValue] = useState(
    localStorage.getItem(
      getStorageKey("eventsListZipCodeValue", currentContext)
    ) || ""
  ); // Zip code value with local storage persistence
  const [searchEventTitleValue, setSearchEventTitleValue] = useState(
    localStorage.getItem(
      getStorageKey("eventsListSearchEventTitleValue", currentContext)
    ) || ""
  ); // Search value with local storage persistence

  // Fetching and setting initial values from local storage for the date filters
  const [startDateValue, setStartDateValue] = useState(
    localStorage.getItem(getStorageKey("startDateValue", currentContext)) || ""
  );

  const [endDateValue, setEndDateValue] = useState(
    localStorage.getItem(getStorageKey("endDateValue", currentContext)) || ""
  );
  const [zipCodeFocused, setZipCodeFocused] = useState("");
  const [isStateDropDownOpen, setStateDropDownOpen] = useState(false); // The state drop down has props here so state will be set here for error handling purposes
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [dateOrderError, setDateOrderError] = useState(false);
  const [showFilters, setShowFilters] = useState(false); // Controls visibility of all filters
  const [showSearch, setShowSearch] = useState(false); // Toggles search input field visibility
  const [showLocationFields, setShowLocationFields] = useState(false); // Controls visibility of location fields
  const [showDates, setShowDates] = useState(false); // parent is managing the filters so if it is false then you will see the button that says dates once you press it then you will see the fields
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [searchFollowers, setSearchFollowers] = useState(false);
  const [searchFollowing, setSearchFollowing] = useState(false);
  const [cancelTriggered, setCancelTriggered] = useState(false); // call back to close the menu after it has been redirected and will be used in the useEffect call back as it is checking to see if any values collected and not to close the other filters
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const filterKey = selectedSubcategory ? selectedSubcategory.id : "default"; // Key for React component re-rendering
  // This will hold our "joined" data
  const eventInfo = mockData.calendars.map((calendar) => {
    // Find related user
    const adminUser = mockData.users.find(
      (user) => user.id === calendar.adminId
    );
    // Check if the event is a group event by looking at activity logs
    const isGroupEvent = mockData.activityLogs.some(
      (log) => log.relatedId === calendar.id && log.objectType === "group event"
    );

    // Return the object for all event info data
    return {
      ...calendar,
      adminUser,
      eventImageType: calendar.media_type, // keeping the "event" prefix
      eventImagePath: calendar.imagePath, // keeping the "event" prefix
      isGroupEvent: isGroupEvent, // Flag to identify group events
    };
  });

  // When returning to the original page
  useEffect(() => {
    // re-initialize the filters based on local storage persisted state since user is redirected to this page from subcat menu page
    // Fetch filter states from local storage
    const city =
      localStorage.getItem(
        getStorageKey("eventsListCityValue", currentContext)
      ) || "";
    const zipCode =
      localStorage.getItem(
        getStorageKey("eventsListZipCodeValue", currentContext)
      ) || "";
    const search =
      localStorage.getItem(
        getStorageKey("eventsListSearchEventTitleValue", currentContext)
      ) || "";
    const state =
      localStorage.getItem(
        getStorageKey("eventsListSelectedState", currentContext)
      ) || "";
    const startDate =
      localStorage.getItem(getStorageKey("startDateValue", currentContext)) ||
      "";
    const endDate =
      localStorage.getItem(getStorageKey("endDateValue", currentContext)) || "";
    const subcategory =
      JSON.parse(localStorage.getItem("selectedSubcategory", currentContext)) ||
      null;
    // Set the filter states
    setCityValue(city);
    setZipCodeValue(zipCode);
    setSearchEventTitleValue(search);
    setSelectedState(state);
    setStartDateValue(startDate);
    setEndDateValue(endDate);
    setSelectedSubcategory(subcategory);
    // Update visibility of related fields
    setShowLocationFields(
      city.length > 0 || zipCode.length > 0 || state.length > 0
    );
    setShowSearch(search.length > 0);
    setShowDates(startDate.length > 0 || endDate.length > 0);
    // Set the filter visibility based on if any filter has a value
    setShowFilters(
      city.length > 0 ||
        zipCode.length > 0 ||
        search.length > 0 ||
        state.length > 0 ||
        startDate !== null ||
        endDate !== null ||
        subcategory !== null
    );
  }, [
    setCityValue,
    setZipCodeValue,
    setSearchEventTitleValue,
    setSelectedState,
    setStartDateValue,
    setEndDateValue,
    setSelectedSubcategory,
    setShowLocationFields,
    setShowSearch,
    setShowDates,
  ]);

  // Check if any search criteria is entered
  const isSearchCriteriaEntered =
    selectedState ||
    cityValue ||
    zipCodeValue ||
    searchEventTitleValue ||
    startDateValue ||
    endDateValue ||
    selectedSubcategory;

  const handleSearchClick = () => {
    setShowSearch(true);
    setIsFieldScaled(true); // Trigger the scaling effect
  };
  const handleLocationFieldsClick = () => {
    setShowLocationFields(true);
  };
  const [subCatMenuData, setSubCatMenuData] = useState({
    idCategory: "",
    subCatMenuOpen: false,
  });
  const handleCategoryClick = (categoryName, categoryId) => {
    // once the category icon/name is pressed then mapping through to get the subcategories for the parent category clicked
    // console.log("Category Clicked:", categoryName, "ID:", categoryId);
    const subcategories = categoryData.childs.filter((child) =>
      child.parent_categories.includes(categoryId)
    );
    // console.log("Fetched Subcategories:", subcategories);
    navigate(`/select-subcategory/${categoryId}`, {
      // using useNav to redirect you from the groups page to the subcategory menu page where the subcategory popup is hosted
      state: {
        subcategories,
        from: "events",
        categoryName: categoryName,
        categoryIconKey: convertToSlug(categoryName), // Pass a key or identifier
      },
    });
  };
  const handleDateErrorChange = (hasError) => {
    console.log("Date order error status:", hasError); // This will log the error status
    setDateOrderError(hasError);
  };

  const handleSubcategorySelect = (subcategoryId) => {
    // once a subcategory is pressed
    setSelectedSubcategory(subcategoryId);
    // console.log("Subcategory selected:", subcategoryId);
    setShowCategories(false); // Hide the categories container
  };
  useEffect(() => {
    // console.log("Render - showFilters:", showFilters);
    // call back to pass the selected category from the subcatmenu page as we are using usenav and locaton to pass the value
    console.log("Location state changed:", location.state);
    if (location.state?.selectedSubcategory) {
      setSelectedSubcategory(location.state.selectedSubcategory);
      console.log(
        "Selected Subcategory location state:",
        location.state.selectedSubcategory
      );
      localStorage.setItem(
        getStorageKey("selectedSubcategory", currentContext),
        JSON.stringify(location.state.selectedSubcategory)
      );
    }

    // Check if any filter-related criteria is present
    const anyFilterCriteriaPresent =
      location.state?.selectedSubcategory ||
      zipCodeValue.length > 0 ||
      cityValue.length > 0 ||
      startDateValue.length > 0 ||
      endDateValue.length > 0 ||
      (selectedState && selectedState !== "") ||
      searchEventTitleValue.length > 0;

    setShowFilters(anyFilterCriteriaPresent);

    if (location.state?.selectedSubcategory) {
      setSelectedSubcategory(location.state.selectedSubcategory);
    }
    // React to cancel action
    if (cancelTriggered) {
      // Logic to handle when cancel is triggered
      setShowFilters(anyFilterCriteriaPresent);
    }
    // Reset cancelTriggered for future actions
    setCancelTriggered(false);
  }, []);

  const clearSubcategory = () => {
    setSelectedSubcategory("");
    const subcategoryKey = getStorageKey("selectedSubcategory", currentContext);
    localStorage.removeItem(subcategoryKey);
  };
  // this will monitor the stae of the popup so it can reset it back to z index intial state
  useEffect(() => {
    if (eventFormOpen) {
      setElementsZIndexToZero();
    } else {
      resetElementsZIndex();
    }
  }, [eventFormOpen]);
  // Function to toggle filters visibility
  const toggleFiltersVisibility = () => {
    setShowFilters(!showFilters);
  };
  const classNamesAndZIndices = [
    { className: ".group-list-search", zIndex: "5" },
    { className: "header.sticky-header", zIndex: "20" },
    { className: ".join-button", zIndex: "0" },
    { className: ".events-btn", zIndex: "5" },
    { className: ".event-listing-page-toggle-filters", zIndex: "2" },
    { className: ".Event-list-search ", zIndex: "2" },
  ];
  const setElementsZIndexToZero = () => {
    classNamesAndZIndices.forEach(({ className }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = "-1";
      });
    });
  };
  const resetElementsZIndex = () => {
    classNamesAndZIndices.forEach(({ className, zIndex }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = zIndex;
      });
    });
  };

  useEffect(() => {
    // Load categories from JSON file or a fetch request
    setCategories(categoriesData.parents); // imported from the categories jason file
  }, []);

  const handleCategoriesClick = () => {
    setShowCategories(!showCategories);
  };
  const togglePopup = () => {
    setEventFormOpen(!eventFormOpen);
  };
  const handleSlideEnd = () => {
    // Show the popup when the slider ends
    setContextType("eventPopupCreate");
    setCurrentForm("eventPopupCreate");
    // Show the popup when the slider ends
    togglePopup();
  };
  const findEvent = () => {
    // Check if there is some input and it's not exactly 4 digits
    if (zipCodeValue.length > 0 && zipCodeValue.length !== 4) {
      setZipCodeError("Must enter a 4-digit zipcode");
    } else if (dateOrderError) {
      // Prevent form submission due to date order error
      console.log("Date order is incorrect. Please correct the dates.");
      // You can add more user-facing error handling here if needed
    } else {
      // Clear any existing error
      setZipCodeError("");
      setZipCodeValue("");
      setCityValue("");
      setSelectedState("");
      setSearchEventTitleValue("");
      setSelectedSubcategory("");
      setShowFilters(!showFilters);
      localStorage.clear();
      // Proceed with form submission logic
      // ... rest of your logic for form submission ...
    }
  };

  const clearEventTitleSearch = () => {
    setSearchEventTitleValue("");
    const EventTitleKey = getStorageKey(
      "eventsListSearchEventTitleValue",
      currentContext
    );
    localStorage.removeItem(EventTitleKey);
    setShowSearch(false);
  };
  const clearEventZipCodeSearch = () => {
    setZipCodeValue("");
    const eventZipCodeKey = getStorageKey(
      "eventsListZipCodeValue",
      currentContext
    );
    localStorage.removeItem(eventZipCodeKey);
  };
  const clearEventCitySearch = () => {
    setCityValue("");
    const eventCityKey = getStorageKey("eventsListCityValue", currentContext);
    localStorage.removeItem(eventCityKey);
  };
  useEffect(() => {
    // Fetch and set followers and following here
    const fetchedFollowers = mockData.users; // Replace with actual fetch logic
    const fetchedFollowing = mockData.users; // Replace with actual fetch logic
    setFollowers(fetchedFollowers);
    setFollowing(fetchedFollowing);
  }, []);
  const toggleSearchFollowers = () => {
    setSearchFollowers((prevState) => !prevState);
  };
  const toggleSearchFollowing = () => {
    //created independent state active state since both can be active together
    setSearchFollowing((prevState) => !prevState);
  };
  const toggleShowDates = () => {
    //created independent state active state since both can be active together
    setShowDates((prev) => !prev);
    // Clear local storage and update state when hiding the date pickers
    if (showDates) {
      const startDateKey = getStorageKey("startDateValue", currentContext);
      localStorage.removeItem(startDateKey);
      const endDateKey = getStorageKey("endDateValue", currentContext);
      localStorage.removeItem(endDateKey);
      // Assuming you have state variables like `startDateValue` and `endDateValue`
      setStartDateValue("");
      setEndDateValue("");
      // If additional handlers to notify changes, call them here
    }
  };

  const handleStartDateChange = (startDate) => {
    const dateString = startDate ? startDate.toISOString() : "";
    const startDateKey = getStorageKey("startDateValue", currentContext);
    setStartDateValue(startDate);
    localStorage.setItem(startDateKey, dateString);
  };
  const handleEndDateChange = (endDate) => {
    const dateString = endDate ? endDate.toISOString() : "";
    const endDateKey = getStorageKey("endDateValue", currentContext);
    setEndDateValue(endDate); // Update state
    localStorage.setItem(endDateKey, dateString); // Update localStorage
  };

  const handleCityInputChange = (value) => {
    setCityValue(value);
    const EventCityKey = getStorageKey("eventsListCityValue", currentContext);
    localStorage.setItem(EventCityKey, value);
  };
  const handleEventTitleSearchInputChange = (value) => {
    setSearchEventTitleValue(value);
    const EventTitleKey = getStorageKey(
      "eventsListSearchEventTitleValue",
      currentContext
    );
    localStorage.setItem(EventTitleKey, value);
  };
  const handleZipCodeInputChange = (value) => {
    if (zipCodeError) {
      setZipCodeError("");
    }
    // Update zipCodeValue only if it's numeric and up to 4 digits
    if (/^\d{0,4}$/.test(value)) {
      setZipCodeValue(value);
      const EventZipCodeKey = getStorageKey(
        "eventsListZipCodeValue",
        currentContext
      );
      localStorage.setItem(EventZipCodeKey, value);
    }
  };
  const clearState = () => {
    // Define the key and hard coded currentContext to target payments
    const state = getStorageKey("State", "eventsList");
    // Check if the key exists in localStorage and get the item
    if (localStorage.getItem(state) !== null) {
      localStorage.removeItem(state); // Remove the item
    } else {
      console.log("Key not found in localStorage:", state);
    }
    setSelectedState(""); // Clear the state
    setStateDropDownOpen(false);
  };
  useEffect(() => {
    if (selectedState) {
      const key = getStorageKey("State", currentContext);
      localStorage.setItem(key, selectedState);
      setSelectedState("State", selectedState);
    }
  }, [selectedState, currentContext]);
  const clearStartDate = () => {
    const startDateKey = getStorageKey("startDateValue", "eventsList");
    localStorage.removeItem(startDateKey);
    setStartDateValue("");
  };
  const clearEndDate = () => {
    const endDateKey = getStorageKey("endDateValue", "eventsList");
    localStorage.removeItem(endDateKey);
    setEndDateValue("");
  };

  return (
    <div id="events-page">
      <div id="listing"></div>
      <div className="event-listing-page-toggle-filters">
        <GlassBtn
          glassBtnImage={
            showFilters ? (
              <EyeSlashIcon
                height="15px"
                width="15px"
                fillColor={showFilters ? "#e2b645" : "#fdfd96"}
                style={{
                  position: "absolute",
                  paddingBottom: "1px",
                  left: "43%",
                }}
              />
            ) : (
              <EyeIcon
                height="15px"
                width="15px"
                fillColor={showFilters ? "#e2b645" : "#fdfd96"}
                style={{
                  position: "absolute",
                  paddingBottom: "1px",
                  left: "43%",
                }}
              />
            )
          }
          onClick={toggleFiltersVisibility}
          glassBtnText={
            showFilters ? "Hide Search Filters" : "Show Search Filters"
          }
          glassBtnFontColor={showFilters ? "#e2b645" : "#fdfd96"}
          glassBtnWidth="130px"
          glassBtnFontWeight="800"
          glassBtnFontFamily="roboto-slab"
          glassBtnHeight="35px"
          glassBtnTextFontSize=".75rem"
          glassBtnTextDecorationColor={showFilters ? "#e2b645" : "#fdfd96"}
          imageOnTop={true}
          textLocalStyle={{
            position: "absolute",
            bottom: "15%",
          }}
          hi2Style={{
            transform: "rotate(90deg) translate(-70px, 65px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate(-70px, -65px)",
          }}
          glassHoverStyle={{ height: "70px" }}
        />
      </div>
      {showFilters && (
        <div className="Event-list-search">
          {/* Show/Hide Filters Button */}
          <h2>Select search criteria:</h2>
          <div className="event-follow-options-filter-container">
            <GlassBtn
              glassBtnImage={
                // total followers to whom is following this user
                <div style={{ position: "relative", bottom: "15px" }}>
                  <span
                    className={
                      searchFollowers
                        ? "followers-event-active"
                        : "followers-event-inactive"
                    }
                  >
                    {/* following is added to check if it is undefined before rendering it since some may not have any */}
                    {following && following.length}
                  </span>
                </div>
              }
              onClick={toggleSearchFollowers}
              glassBtnText={
                searchFollowers ? "Cancel Follower Search" : "Search Followers"
              }
              glassBtnFontColor={searchFollowers ? "#e2b645" : "#fdfd96"}
              glassBtnWidth="130px"
              glassBtnFontWeight="800"
              glassBtnFontFamily="roboto-slab"
              glassBtnHeight="35px"
              glassBtnTextFontSize=".75rem"
              glassBtnTextDecorationColor={
                searchFollowers ? "#e2b645" : "#fdfd96"
              }
              imageOnTop={true}
              textLocalStyle={{
                position: "absolute",
                bottom: "15%",
              }}
              hi2Style={{
                transform: "rotate(90deg) translate(-70px, 65px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-70px, -65px)",
              }}
              glassHoverStyle={{ height: "48px" }}
            />
            <GlassBtn
              glassBtnImage={
                // total users this user is following
                <div style={{ position: "relative", bottom: "15px" }}>
                  <span
                    className={
                      searchFollowing
                        ? "Following-event-active"
                        : "Following-event-inactive"
                    }
                  >
                    {/* following is added to check if it is undefined before rendering it since some may not have any */}
                    {following && following.length}
                  </span>
                </div>
              }
              onClick={toggleSearchFollowing}
              glassBtnText={
                searchFollowing ? "Cancel Following Search" : "Search Following"
              }
              glassBtnFontColor={searchFollowing ? "#e2b645" : "#fdfd96"}
              glassBtnWidth="130px"
              glassBtnFontWeight="800"
              glassBtnFontFamily="roboto-slab"
              glassBtnHeight="35px"
              glassBtnTextFontSize=".75rem"
              glassBtnTextDecorationColor={
                searchFollowing ? "#e2b645" : "#fdfd96"
              }
              imageOnTop={true}
              textLocalStyle={{
                position: "absolute",
                bottom: "15%",
              }}
              hi2Style={{
                transform: "rotate(90deg) translate(-70px, 65px)",
              }}
              hi3Style={{
                transform: "rotate(90deg) translate(-70px, -65px)",
              }}
              glassHoverStyle={{ height: "48px" }}
            />
          </div>
          {/* Conditional Rendering of 'Press to Find Event' Button */}
          {isSearchCriteriaEntered && (
            <CustomButton // user will see a loader, all the buttons will close when finished and user will search results the clear loader when it stops it will say a message like success or no event found
              image={
                <CommunityMagnifyingGlassIcon
                  height="35px"
                  width="35px"
                  fillColor="#964b00"
                />
              }
              className={"search-icon-btn search-instruction"}
              onClick={findEvent}
              // onClick={() => setSearchGroupInfo(true)} // for backend api
              customBtnText="Press to Find Event"
              customBtnFontColor="#964b00"
              customBtnBorderColor="#964b00"
              customBtnBorderWidth="2px"
              paddingRight="5px"
              imagePadding="5px" // Adjust as needed
            />
          )}
          <div>
            {!showDates && (
              <CustomButton
                image={
                  <CalendarIcon
                    height="25px"
                    width="25px"
                    fillColor="#964b00"
                  />
                }
                className="cancel-categories-btn"
                onClick={() => setShowDates(true)}
                customBtnText="Dates"
                customBtnFontColor="#964b00"
                customBtnBorderColor="#964b00"
                customBtnBorderWidth="2px"
                paddingRight="20px"
                imagePadding="5px"
              />
            )}
            {showDates && ( // if show dates is not false then show this
              <div className="date-picker-section">
                <DatePicker
                  currentContext={currentContext}
                  onClick={() => setDatePickerOpen(true)}
                  onStartDateChange={handleStartDateChange}
                  onEndDateChange={handleEndDateChange}
                  clearStartDate={clearStartDate}
                  clearEndDate={clearEndDate}
                  setStartDateValue={setStartDateValue}
                  setEndDateValue={setEndDateValue}
                  startDateValue={startDateValue}
                  endDateValue={endDateValue}
                  onDateErrorChange={handleDateErrorChange}
                  showAdditionalDate={false}
                  isClearable={true}
                  showYearDropdown={false}
                  showMonthDropdown={true}
                  dateFormat="MMMM d, yyyy Date"
                  placeholder="Enter/Select  Date"
                />
                <GlassBtn
                  glassBtnImage={
                    <XIcon
                      height="10px"
                      width="10px"
                      fillColor="#fdfd96"
                      style={{
                        position: "absolute",
                        paddingBottom: "1px",
                        // left: "43%",
                      }}
                    />
                  }
                  onClick={toggleShowDates}
                  glassBtnText="Cancel Date Search"
                  glassBtnFontColor="#fdfd96"
                  glassBtnWidth="130px"
                  glassBtnFontWeight="800"
                  glassBtnFontFamily="roboto-slab"
                  glassBtnHeight="35px"
                  glassBtnTextFontSize=".75rem"
                  glassBtnTextDecorationColor="#fdfd96"
                  imageOnTop={true}
                  textLocalStyle={{
                    position: "absolute",
                    bottom: "15%",
                  }}
                  hi2Style={{
                    transform: "rotate(90deg) translate(-70px, 65px)",
                  }}
                  hi3Style={{
                    transform: "rotate(90deg) translate(-70px, -65px)",
                  }}
                  glassHoverStyle={{ height: "70px" }}
                />
              </div>
            )}
            {!showLocationFields && (
              <>
                <CustomButton
                  image={
                    <PinDropIcon
                      height="35px"
                      width="35px"
                      fillColor="#964b00"
                    />
                  }
                  className={"search-icon-btn search-instruction"}
                  onClick={handleLocationFieldsClick} // Corrected method name
                  customBtnText="Location"
                  customBtnFontColor="#964b00"
                  customBtnBorderColor="#964b00"
                  customBtnBorderWidth="2px"
                  paddingRight="5px"
                  imagePadding="5px" // Adjust as needed
                />
              </>
            )}
            {showLocationFields && (
              <div className="location-fields">
                <StateDropdown
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  clearState={clearState}
                  isOpen={isStateDropDownOpen}
                  currentContext={currentContext}
                  onToggle={setStateDropDownOpen}
                />
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter City here.."
                    className="location-input"
                    value={cityValue}
                    onChange={(e) =>
                      handleCityInputChange(
                        e.target.value,
                        "eventsListCityValue"
                      )
                    }
                    onFocus={() => setCityFocused(true)}
                    onBlur={() => setCityFocused(false)}
                  />
                  {!cityFocused && cityValue === "" && (
                    <span className="blinking-cursor blinking topten-cursor">
                      |
                    </span>
                  )}
                  {cityValue && (
                    <button
                      className="Clear-Event-City-Search"
                      onClick={clearEventCitySearch}
                      style={{
                        color: "#fdfd96",
                        backgroundColor: "transparent",
                        borderColor: "#fdfd96",
                      }}
                    >
                      Clear
                    </button>
                  )}
                </div>
                <div className="input-container pos-cancel-search">
                  <input
                    type="text"
                    placeholder="Enter Zipcode here.."
                    className="location-input zipcode-input"
                    value={zipCodeValue}
                    onChange={(e) =>
                      handleZipCodeInputChange(
                        e.target.value,
                        "eventsListZipCodeValue"
                      )
                    }
                    onFocus={() => {
                      setZipCodeFocused(true);
                      setZipCodeError(""); // Clear any existing error message
                    }}
                    onBlur={() => {
                      setZipCodeFocused(false);
                      setZipCodeError(""); // Clear any existing error message
                    }}
                  />
                  {!zipCodeFocused && zipCodeValue === "" && (
                    <span className="blinking-cursor blinking topten-cursor">
                      |
                    </span>
                  )}
                  {zipCodeError && (
                    <div className="zip-code-error-message">{zipCodeError}</div>
                  )}

                  {zipCodeValue && (
                    <button
                      className="Clear-Event-ZipCode-Search"
                      onClick={clearEventZipCodeSearch}
                      style={{
                        color: "#fdfd96",
                        backgroundColor: "transparent",
                        borderColor: "#fdfd96",
                      }}
                    >
                      Clear
                    </button>
                  )}
                  <GlassBtn
                    glassBtnImage={
                      <XIcon
                        height="10px"
                        width="10px"
                        fillColor="#fdfd96"
                        style={{
                          position: "absolute",
                          paddingBottom: "1px",
                          // left: "43%",
                        }}
                      />
                    }
                    onClick={() => {
                      clearEventZipCodeSearch();
                      clearEventCitySearch();
                      localStorage.removeItem("eventsListSelectedState", "");
                      setZipCodeError("");
                      setShowLocationFields(false);
                    }}
                    glassBtnText="Cancel Location Search"
                    glassBtnFontColor="#fdfd96"
                    glassBtnWidth="130px"
                    glassBtnFontWeight="800"
                    glassBtnFontFamily="roboto-slab"
                    glassBtnHeight="35px"
                    glassBtnTextFontSize=".75rem"
                    glassBtnTextDecorationColor="#fdfd96"
                    imageOnTop={true}
                    textLocalStyle={{
                      position: "absolute",
                      bottom: "15%",
                    }}
                    hi2Style={{
                      transform: "rotate(90deg) translate(-70px, 65px)",
                    }}
                    hi3Style={{
                      transform: "rotate(90deg) translate(-70px, -65px)",
                    }}
                    glassHoverStyle={{ height: "70px" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="input-container">
            {!showSearch && (
              <>
                <CustomButton
                  image={
                    <OpenBookIcon
                      height="35px"
                      width="35px"
                      fillColor="#964b00"
                    />
                  }
                  className={"search-icon-btn search-instruction"}
                  onClick={handleSearchClick}
                  customBtnText="Event Title"
                  customBtnFontColor="#964b00"
                  customBtnBorderColor="#964b00"
                  customBtnBorderWidth="2px"
                  paddingRight="5px"
                  imagePadding="5px" // Adjust as needed
                />
              </>
            )}
            {showSearch && (
              <div className="search-container">
                <input
                  placeholder="Enter event title here …"
                  value={searchEventTitleValue}
                  onChange={(e) =>
                    handleEventTitleSearchInputChange(
                      e.target.value,
                      "eventsListSearchEventTitleValue"
                    )
                  }
                  onFocus={() => setSearchFocused(true)}
                  onBlur={() => setSearchFocused(false)}
                />
                {!searchFocused && searchEventTitleValue === "" && (
                  <span className="blinking-cursor blinking topten-cursor">
                    |
                  </span>
                )}
                {searchEventTitleValue && (
                  <button
                    className="Clear-Event-Title-Search"
                    onClick={clearEventTitleSearch}
                    style={{
                      color: "#fdfd96",
                      backgroundColor: "transparent",
                      borderColor: "#fdfd96",
                    }}
                  >
                    Clear
                  </button>
                )}
                {searchEventTitleValue && (
                  <PlainBtn
                    className="cancel-event-title-search"
                    image={
                      <XIcon width="20px" height="20px" fillColor="#964b00" />
                    }
                    onClick={() => {
                      clearEventTitleSearch();
                    }}
                    imageOnTop={true}
                    plainBtnHeight="45px"
                    plainBtnFontWeight="900"
                    plainBtnFontColor="#964b00"
                    plainBtnBorderColor="#964b00"
                    plainBtnBorderRadius="0px"
                    plainBtnText="Cancel Title Search"
                    marginLeft="50%"
                    textLocalWhiteBtnStyle={{
                      whiteSpace: "nowrap",
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {/* displays the parent categories */}
          {/* This condition checks if both showCategories and selectedSubcategory are false.
             If both are false, it means neither a category is selected nor the categories button is supposed to show.
             In this case, it renders the CustomButton for selecting categories. */}
          {!showCategories && !selectedSubcategory ? (
            <>
              <CustomButton
                image={
                  <CatagoriesIcon
                    height="35px"
                    width="35px"
                    fillColor="#964b00"
                  />
                }
                className={"search-icon-btn search-instruction"}
                onClick={handleCategoriesClick}
                customBtnText="Categories"
                customBtnFontColor="#964b00"
                customBtnBorderColor="#964b00"
                customBtnBorderWidth="2px"
                paddingRight="5px"
                imagePadding="5px"
              />
            </>
          ) : null}
          <div className="event-categories-search-container">
            {showCategories && (
              <>
                <WhiteBtn
                  whiteBtnImage={
                    <GlobalThreeLineAnimation
                      fillColor="#e2b645"
                      height="25px"
                      width="15px"
                      lineWidth="25px"
                      lineHeight="5px"
                      animationType="6"
                      marginLeft="10px"
                      marginBottom="20px"
                    />
                  }
                  className="cancel-categories-btn"
                  whiteBtnText="Cancel"
                  whiteBtnFontColor="#e2b645"
                  whiteGoldBtnTextFontSize=".75rem"
                  whiteBtnBorderColor="#e2b645"
                  whiteBtnPaddingRight="20px"
                  whiteBtnheight="50px"
                  extraStyles={{
                    backdropFilter: "blur(100px)", // Example: applying a blur filter
                    backgroundColor: "rgba(0, 0, 0, 0.7333333333)",
                  }}
                  onClick={() => setShowCategories(false)}
                />
                <div className="CategoryContainer">
                  {categories.map((category, index) => (
                    <CategoryNameFormatSearch
                      key={index}
                      categoryName={category.name}
                      categoryId={category.id}
                      handleCategoryClick={handleCategoryClick}
                    />
                  ))}
                  {subCatMenuData.subCatMenuOpen && (
                    <SubCategoryPopup
                      key={filterKey}
                      onCancel={() => setCancelTriggered(true)} // passing the call back so the filters can be left open if any values are collected since this component is managing the filter state, will be place in the useEffect above
                      subCatMenuData={subCatMenuData}
                      setSubCatMenuData={setSubCatMenuData}
                      selectedSubcategory={selectedSubcategory}
                      onSubcategorySelect={handleSubcategorySelect}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {/* Displaying the selected subcategory */}
          <div
            className={`subcategory-select ${
              selectedSubcategory?.name ? "selected" : ""
            }`}
          >
            {/* // This condition checks if a subcategory has been selected.
                    If selectedSubcategory is not null, it means a subcategory is selected.
                    In this case, it shows the selected subcategory's name and a button to clear the selection. */}
            {selectedSubcategory && (
              <div className="selected-subcategory-display">
                <WhiteGoldButton
                  onClick={clearSubcategory}
                  whiteGoldBtnText="Clear Subcategory"
                  whiteGoldBtnFontColor="#964B00"
                  whiteGoldBtnWidth="fit-content"
                  whiteGoldBtnFontWeight="800"
                  whiteGoldBtnFontFamily="roboto-slab"
                  whiteGoldBtnTextFontSize="1rem"
                />
                <p className="selectedSubcategory">
                  {selectedSubcategory.name}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      <section className="events-main-content">
        <div className="events-list">
          <CreateEventBtn
            className="events-btn"
            onHandleSlideEnd={handleSlideEnd}
            eventFormOpen={eventFormOpen}
            togglePopup={togglePopup}
            currentForm={currentForm}
            setContextType={setContextType}
            setCurrentForm={setCurrentForm}
            contextType={contextType}
            eventPopup={
              <EventPopup
                onClose={() => setEventFormOpen(false)} // prop recieved state from the parent component above
                eventFormOpen={eventFormOpen}
                updateEvent={updateEvent}
                contextType={contextType} // Passing contextType as a prop so i can define currentContext and change it
              />
            }
          />
          <div className="events-list">
            {/* mapping over the calendar to get all the event info */}
            {eventInfo.map((calendar) =>
              calendar.eventImagePath ? (
                // Here, we're passing the entire "joined" object down as a prop
                // if user has media show this event card component else show this one hence the name sans media(without media)
                <EventCard key={calendar.id} event={calendar} />
              ) : (
                <EventCardSansMedia key={calendar.id} event={calendar} />
              )
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
