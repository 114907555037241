import _startsWith from "lodash/startsWith.js";

// types
import AUTH from "../types/authTypes.js";
import TYPES from "../types/globalTypes.js";

// reducer
import todoReducer from "./todoReducer.js";
import { INITIAL_PLAIN_DATA_REQUEST } from "../../utils/authTokenReduxUtils.js";

// utils

const INIT_STATE = {
  profile: INITIAL_PLAIN_DATA_REQUEST,
};

const { PREFIXES } = TYPES;

function userReducer(state = INIT_STATE, action = {}) {
  const { type } = action;

  if (type === AUTH.UN_AUTHORIZE) return INIT_STATE;

  if (_startsWith(type, PREFIXES.USER)) {
    return todoReducer(state, action);
  }

  return state;
}

export default userReducer;