export const handleTextareaResize = (textareaRef, containerRef) => {
    if (textareaRef.current && containerRef.current) {
        const textarea = textareaRef.current;
        // Reset the height to shrink the textarea and calculate the new height
        textarea.style.height = "auto";
        const newHeight = textarea.scrollHeight;
        // Adjust the height of the textarea
        textarea.style.height = `${newHeight}px`;
    }
};

export const adjustElementPositions = (
    textareaRef,
    postContainerRef,
    thumbnailRef,
    clearButtonRef,
    buttonsRef,
    postContent
) => {
    if (textareaRef.current && postContainerRef.current) {
        const textarea = textareaRef.current;
        const container = postContainerRef.current;
        // Calculate new positions
        const newHeight = textarea.scrollHeight;
        const containerHeight = container.clientHeight;
        const elementsTopPosition = containerHeight - newHeight;

        // Adjust thumbnail and clear button
        if (thumbnailRef.current && clearButtonRef.current) {
            thumbnailRef.current.style.top = `${elementsTopPosition}px`;
            // Position the clear button next to the thumbnail
            const thumbnailWidth = thumbnailRef.current.offsetWidth;
            clearButtonRef.current.style.left = `${thumbnailWidth + 5}px`; // 5px is a gap, adjust as needed
            clearButtonRef.current.style.top = `${elementsTopPosition}px`;
        }

        // Adjust list buttons
        if (buttonsRef.current && postContent) {
            // Position the buttons at the same level as the thumbnail and clear button
            buttonsRef.current.style.top = `${elementsTopPosition}px`;
        }
    }
};
