import React, { useEffect, useState } from "react";
import { GlassBtn, CustomButton } from "../../buttons";
import { PhoneIcon } from "../../icons";
import { codeValidationErrorHandler } from "../../../controller/errorHandlers/codeValidationErrorHandler";
import {handlCodeValueChange} from "../../../controller/watcherAndClearFunctions/phoneVerificationClearsWatchers.js";
import {
  getStorageKey,
} from "../../../utils/localStorageUniqueKeyGenerator.js";
// import CustomSpinner from "../../CustomSpinner/CustomSpinner";
import "./phoneVerification.scss";

const PhoneVerification = ({
  isForgotPassword,
  setCurrentForm,
  setContextType,
  isSettings = false,
}) => {
  const [codeValue, setCodeValue] = useState("");
  const [active, setActive] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [attemptedAccountVerification, setAttemptedAccountVerification] =
    useState(false);
  const [time, setTime] = useState(30);
  const currentContext = isSettings ? "settings" : "resetPasswordSignin";
  const [showRequestNewCodeLoader, setShowRequestCodeLoader] = useState(false);
  const [isFocused, setIsFocused] = useState(false);


  const onConfirmCodeSent = async () => {
    setAttemptedAccountVerification(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  useEffect(() => {
    if (attemptedAccountVerification) {
      const { errors } = codeValidationErrorHandler(
        codeValue,
        setCodeError,
        currentContext
      );
      // if no errors in the accoutPhoneNumberErrors logic proceed to next form
      if (!errors.codeError) {
        // Clear local storage items related to EnterMobileNumber form
        localStorage.clear();
        // proceed with form submission logic
        setCurrentForm("resetPassword");
        setContextType("resetPassword");
        // other logic to proceed
      } else {
        console.log("Validation errors present, not proceeding to next form.");
      }
      setAttemptedAccountVerification(false);
    }
  }, [attemptedAccountVerification, codeValue]);

  const handleSkip = async () => {
    // console.log('Code not submitted: SKIP') // TODO add async call to handle SKIP scenario
  };
  const handleRequestNewCode = async () => {
    setShowRequestCodeLoader(true);
    if (time === 0) {
      try {
        // console.log('Requesting new code') // TODO add async call to request new code
        setShowRequestCodeLoader(false);
      } catch (err) {
        console.log(err);
        setShowRequestCodeLoader(false);
      }
      setTime(30);
    }
  };

  useEffect(() => {
    if (codeValue.length === 6) {
      return setActive(true);
    }
    return setActive(false);
  }, [codeValue]);

  useEffect(() => {
    if (time > 0) setTimeout(() => setTime(time - 1), 1000);
  }, [time]);


  return (
    <div className="form-wrapper">
      <form id="phone-verification-form">
        <section id="top-section">
          <h1>
            {" "}
            <PhoneIcon fillColor="black" />
            &nbsp;Verify Mobile
          </h1>
          <p id="timer">{time} Seconds </p>
          <p id="timer-text"> Must wait 30 seconds to press send code </p>
          <h2>Enter Code Sent to Mobile: </h2>
          <div className="input-group blinker-wrapper">
            <input
              id="verify-input"
              type="text"
              minLength="6"
              maxLength="6"
              placeholder={codeValue.length === 0 ? "# # # # # #" : ""}
              onChange={(e) => 
                handlCodeValueChange(
                e.target.value,
                currentContext,
                codeError,
                setCodeValue,
                getStorageKey,
                setCodeError
              )}
              
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            {codeError && <div className="error-message">{codeError}</div>}
            {!isFocused && codeValue === "" && (
              <span className="blinkingcursor confirm-cursor">|</span>
            )}
          </div>
          <GlassBtn
            glassBtnText="Confirm Code Sent"
            glassBtnWidth="fit-content"
            glassBtnHeight="20px"
            sparkle="true"
            fullwidthGlassHi={true}
            top="10px"
            glassConfirm={true}
            onClick={onConfirmCodeSent}
            hi2Style={{
              transform: "rotate(90deg) translate(-30px, 100px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate( -30px, -97px)",
            }}
          />
        </section>
        <section id="bottom-section">
          <CustomButton
            id="cancel-btn"
            type="button"
            customBtnText=" X Cancel"
            customBtnWidth="fit-content"
            customBtnTextFontSize="1rem"
            customBtnFontFamily="Roboto-Slab"
            customBtnFontWeight="900"
            animationActive={true}
            onClick={() => {
              localStorage.clear();
              setCurrentForm("signIn");
              setContextType("signIn");
            }}
          />
          {!isForgotPassword && (
            <CustomButton
              id="skip-btn"
              onClick={handleSkip}
              type="button"
              customBtnText="Skip >"
              customBtnWidth="fit-content"
              customBtnTextFontSize="1rem"
              customBtnFontFamily="Roboto-Slab"
              customBtnFontWeight="900"
              animationActive={true} // Enable the animation
              className={`${time !== 0 ? " hidden" : ""}`}
            >
              {/* {'Skip >'} */}
            </CustomButton>
          )}
        </section>
      </form>
    </div>
  );
};
export default PhoneVerification;
