import React, { useState, useCallback } from "react";
import { useAddZerosWithCursor } from "../../components/constant/dashboardValidation.js";
import PointsCircle from "../../components/rewardsCircle/rewardsCircle";
import { ImgTopBtn } from "../../components/buttons/index.jsx";
import "./checkout.scss";

const DashBoardSection = ({
  pointsBalance,
  setPointsBalance,
  giftCardBalance,
  setGiftCardBalance,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const {
    originalValue,
    currentValue,
    inputRef,
    handleChange,
    setCursor,
    setCurrentValue,
    setOriginalValue,
  } = useAddZerosWithCursor();

  // toggle redeem modal and clear input field
  // or cancel trasnaction and clear input field
  const toggleRedeemModal = () => {
    setOriginalValue(0);
    setCurrentValue("");
    setOpenModal(!openModal);
  };

  const redeemPoint = useCallback(() => {
    if (originalValue <= pointsBalance) {
      setPointsBalance((prev) => prev - originalValue);
      // Convert the originalValue (in points) to dollars
      const dollarValue = originalValue * 0.01;
      setGiftCardBalance((prev) => prev + dollarValue); // Ensure you're adding dollarValue here
      toggleRedeemModal();
    }
  }, [originalValue, pointsBalance, setPointsBalance]);

  return (
    <div className="dashboardContainer">
      <h1 className="dashboardCheckoutHeader">Dashboard</h1>
      <div className="cardCoinatiner">
        <div className="circleOfpoints miniDashboard">
          <PointsCircle
            pointsBalance={pointsBalance}
            setPointsBalance={setPointsBalance}
            NameLevelValueStyle={{
              color: "#e2b645",
            }}
            CurrentPntsBalStyle={{
              color: "#e2b645",
            }}
            unlockLevelStyle={{
              color: "#e2b645",
            }}
            pointsToGoStyle={{
              color: "#e2b645",
            }}
            className="positionUnlockContent"
          />
        </div>
        <div className="showAvailableBidpoints">
          <div>
            <p>Available GiftCard Balance</p>
            <img src="/images/icons/i-icon.svg" alt="" />
          </div>

          <h3 className="usergiftcardBalance">
            $
            {parseFloat(giftCardBalance).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
          <p>(Slide to Redeem, Can Not Exceed Order Amount)</p>
        </div>
        <div className="showAvailableBidpoints">
          <div>
            <p>Available BidPoints Balance</p>{" "}
            <img src="/images/icons/i-icon.svg" alt="" />
          </div>
          <div className="redeemBtnandPoints">
            {/* this is user available bidpoints balance */}
            <span className="bidPoints">{pointsBalance.toLocaleString()}</span>
            <div>
              <ImgTopBtn
                onClick={toggleRedeemModal}
                disabled={pointsBalance < 999 && !openModal}
                imgTopBtnText={openModal ? "CANCEL" : "REDEEM"}
                className={`${
                  pointsBalance < 999 && !openModal
                    ? "redeem-disabled"
                    : "redeem"
                } redeem_btn posbtnleft`}
                imgTopBtnFontColor="#000"
                imgTopBtnBorderColor="transparent"
                imgTopBtnTextFontSize="1rem"
                imgTopBtnWidth="auto"
                imgTopBtnHeight="auto"
                imgTopBtnFontFamily="Arial"
                imgTopBtnBoxShadow="none"
                imgTopBtnBorderRadius="0px"
              />
            </div>
          </div>
          <section
            className="showing_Bidpoints"
            style={{ display: openModal ? "block" : "none" }}
          >
            <div className="header_showBidpoints">
              <p className="RdmInptTitle">
                <u>Enter Points Amounts</u>
              </p>
            </div>
            <div id="redeemInput" className="blinker-wrapper">
              <input
                type="text"
                className="input-redeem-values profileMobileNumClassName"
                ref={inputRef}
                onChange={handleChange}
                value={currentValue}
                onFocus={setCursor}
                onKeyDown={setCursor}
                onKeyUp={setCursor}
                onKeyPress={setCursor}
                onClick={setCursor}
                // maxLength={8}
                maxLength={16}
                placeholder=" "
              />
              <span className="blinking-cursor small-cursor">|</span>
              <span className="triplet-zeros-for-redeem">,000</span>
            </div>

            <div className="redeemBtnErrorMessage">
              You can redeem up to the $
              <span className="errorPointLimitAmount" />
            </div>

            <button
              type="button"
              id="apply-redeem-btn"
              className="redeemBidBtn"
              data-amount="0"
              disabled={currentValue < 1 || originalValue > pointsBalance}
              onClick={redeemPoint}
            >
              Press here to redeem
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DashBoardSection;
