import React, { useState } from "react";
import { WhiteBtn } from "../buttons";
import mockData from "../../seedMockData/seedMockData";
import { getHighestPostPeriod } from "../../utils/postUtils.js";
// import mockData from "../../seedMockData/seedMockData";
import { CommunityIcon, XIcon } from "../../components/icons";
import { NavLink } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/stringUtils";

import "./groupCard.scss";
// console.log(document.querySelectorAll(".white-gold-btn"));
const GroupCard = ({ id, group, groupId, groupMedia }) => {
  const { groupTitle } = group;
  const buttonRef = React.useRef(null);
  // When passing the button component, pass the ref as well
  <WhiteBtn ref={buttonRef} /* other props */ />;

  // const [animations, setAnimations] = useState({});
  const [userAction, setUserAction] = useState(false);
  const loggedInUser = {
    id: "user-uuid-5",
    name: "AcctHolder",
  };
  const getFollowingIds = (userId, activityLogs) => {
    return activityLogs
      .filter((log) => log.sourceId === userId && log.activity === "following")
      .map((log) => log.targetId);
  };

  const getFollowerIds = (userId, activityLogs) => {
    return activityLogs
      .filter((log) => log.targetId === userId && log.activity === "following")
      .map((log) => log.sourceId);
  };
  const findGroupMemberConnections = (
    loggedInUserId,
    activityLogs,
    groupId
  ) => {
    // Find all users who are members of the group
    const groupMemberIds = activityLogs
      .filter(
        (log) =>
          log.relatedId === groupId &&
          log.activity === "member" &&
          log.objectType === "group"
      )
      .map((log) => log.sourceId);

    // If users are following eachother they are considered a connection
    // Get the IDs of users the logged-in user is following
    const followingIds = getFollowingIds(loggedInUserId, activityLogs);

    // Get the IDs of users following the logged-in user
    const followerIds = getFollowerIds(loggedInUserId, activityLogs);

    // Find mutual connections who are also members of the group
    const mutualGroupMemberConnections = groupMemberIds.filter(
      (userId) => followingIds.includes(userId) && followerIds.includes(userId)
    );

    // Join with users data
    return mutualGroupMemberConnections.map((uuid) => {
      const user = mockData.users.find((user) => user.id === uuid);
      return {
        username: user ? user.username : "Unknown User",
        imagePath: user ? user.imagePath : "defaultImagePath",
      };
    });
  };

  // Usage in the component
  const connections = findGroupMemberConnections(
    loggedInUser.id,
    mockData.activityLogs,
    groupId // Pass the group ID here
  );

  // console.log('Sample Activity Log:', mockData.activityLogs.slice(0, 5));
  const totalConnectionsInGroup = connections.length;
  const groupPosts = mockData.posts.filter((post) => post.groupId === groupId);
  if (groupPosts.length === 0) {
    return null;
  }
  console.log("Connections for logged-in user:", connections);

  // Get the period with the highest post count for the first post in the group
  // Note: Adjust as per your use case if you need to aggregate posts data
  const highestPostPeriod = getHighestPostPeriod(groupPosts);
  // Lookup the media data for this group

  return (
    <NavLink className="group-card" to={`/groups/${id}`}>
      <section
        className="group-card-top-section"
        style={{ backgroundColor: !groupMedia ? "#fefefe" : "" }}
      >
        {groupMedia ? (
          <img
            src={groupMedia}
            alt={
              group.groupTitle
                ? capitalizeFirstLetter(group.groupTitle)
                : "Group Image"
            }
          />
        ) : (
          <div className="no-image-placeholder"></div>
        )}
      </section>

      <section className="group-card-bottom-section">
        {groupTitle && (
          <h1 className="groupTitle">
            {capitalizeFirstLetter(group?.groupTitle)}
          </h1>
        )}
        <div className="gpInterestedPost">
          <p>{group.groupTotalMembers} members&nbsp;</p>
          {highestPostPeriod && (
            <p>
              ·{highestPostPeriod.count} Post Per {highestPostPeriod.name}
            </p>
          )}
        </div>
        {connections.length > 0 ? (
          <div className="connection-info">
            <div className="profile-thumbnails">
              {connections
                .filter((connections) => connections.imagePath)
                .slice(0, 3) // show the first three connections of the loggedin user looking at the group card
                .map((connections, index) => (
                  <div key={index} className="profile-thumbnail">
                    <img
                      src={connections.imagePath || "defaultImagePath"}
                      alt={connections.username || "Unknown User"}
                      title={connections.username || "Unknown User"}
                    />
                  </div>
                ))}
            </div>
            <p className="connection-text">
              {connections.length === 1
                ? `${connections[0].username || "Unknown User"} is a member.`
                : `${connections[0].username || "Unknown User"} and ${
                    connections.length - 1
                  } other${connections.length > 2 ? "s" : ""} are members.`}
            </p>
          </div>
        ) : (
          <p style={{ color: "red", fontWeight: "bold" }}>
            No connections found.
          </p>
        )}
        <div className="join-button">
          {userAction ? (
            <WhiteBtn
              whiteBtnImage={
                <XIcon fillColor="#fdfd96" height="20px" width="20px" />
              }
              onClick={(e) => {
                e.preventDefault();
                setUserAction(false);
              }}
              className="cancelGroupJoin"
              whiteBtnText="Cancel Request"
              whiteBtnFontColor="#fdfd96"
              whiteBtnWidth="200px"
              whiteBtnfontSize="1rem"
              whiteBtnHeight="20px"
              whiteBtnFontFamily="Roboto-Slab"
              whiteBtnFontWeight="900"
              whiteBtnBorderColor="#fdfd96"
              extraStyles={{
                backdropFilter: "blur(100px)",
              }}
            />
          ) : (
            <WhiteBtn
              whiteBtnImage={
                <CommunityIcon fillColor="#fdfd96" height="15px" width="15px" />
              }
              ref={buttonRef}
              whiteBtnText="Join"
              whiteBtnFontColor="#fdfd96"
              whiteBtnWidth="fit-content"
              whiteBtnfontSize="1rem"
              whiteBtnHeight="20px"
              whiteBtnFontFamily="Roboto-Slab"
              whiteBtnFontWeight="900"
              whiteBtnBorderColor="#fdfd96"
              whiteBtnAnimationActive={true}
              extraStyles={{
                backdropFilter: "blur(100px)",
              }}
              onClick={(e) => {
                e.preventDefault();
                setUserAction(true);
              }}
            />
          )}
        </div>
      </section>
    </NavLink>
  );
};
export default GroupCard;

// Block-level elements, like div and p, create a "block" of space in the layout and will, by default, start on a new line, extending the full width of its parent container. Block elements can contain inline elements and other block-level elements.
// Inline elements, like span, do not start on a new line and only take up as much width as necessary. Inline elements cannot contain block-level elements.
// not adding a tag to a flex container means items won't be arranged because there is no properties defined
// Remember, when using this method, the data is only passed during that specific navigation. If the user refreshes the GroupDetailsPage, that data will be lost. If persisting the data across page reloads is crucial, you might consider other methods. However, for many use cases, this approach works just fine.
