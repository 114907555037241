import { Rewardlevels } from "../../constants/rewardPoints.js";
export const getCurrentRewardLevelName = (points) => {
  const level = Rewardlevels.find((lvl) => {
    return points >= lvl.pointsRange[0] && points <= lvl.pointsRange[1];
  });
{
  return level.name;
}
};
export const getCurrentRewardLevel = (points) => {
  const level = Rewardlevels.find((lvl) => {
    return points >= lvl.pointsRange[0] && points <= lvl.pointsRange[1];
  });
  {
    return level.rate;
  }
};
