import React from "react";
import mockData from "../../seedMockData/seedMockData";
import ProfilePicture from "../profilePic/profilePic";
import "./notificationCard.scss";

const NotificationCard = ({ notification, sourceUsername }) => {
  // Find the user by their username to get their profile picture
  const user = mockData.users.find((u) => u.username === sourceUsername);
  const userProfileImage = user
    ? user.imagePath
    : "path-to-default-profile-pic-or-icon";

  return (
    <div className="notification-card notification-card-spacing">
      <div className="profile-thumbnail">
        <ProfilePicture
          borderWidth="3px"
          avatarWidth="90%" // size for the SVG avatar
          avatarHeight="90%" // size for the SVG avatar
          profileImgPath={userProfileImage}
          showAvatarBorder={true}
          borderRadius="50%"
        />
      </div>
      <div className="notification-content">
        <p>{notification.activity_data.message}</p>
        <span className="timestamp">
          {new Date(notification.sentTimeStamp).toLocaleString()}
        </span>
      </div>
    </div>
  );
};

export default NotificationCard;
