import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const handlCodeValueChange = (
    value,
    currentContext,
    codeError,
    setCodeValue,
    setCodeError
    ) => {
    setCodeValue(value); // Update state
    const Key = getStorageKey("verifyOtpValue", currentContext);
    console.log("Generated localStorage Key:", Key); // Debugging the generated key
    console.log("Code Input Value:", value); // Debugging the input value
    localStorage.setItem(Key, value); // Ensure 'value' is a string
    if (codeError) {
      setCodeError("");
    }
  };