import React from "react";
import SignUp from "../../components/forms/signUp/signUp.jsx";
import "./settingsPage.scss"; // Import the CSS for this component

// toDo preopulate the form for user see below

// const [userData, setUserData] = useState({});

// useEffect(() => {
//     // Fetch user data from your backend or context/store
//     // Then set it in the userData state
// }, []);  // Empty dependency array ensures this runs only once when the component mounts.
// popuplate the form to allow user's to update their data
// const { register, handleSubmit, reset } = useForm({
//   defaultValues: {
//       firstName: userData.firstName,
//       lastName: userData.lastName,
//       email: userData.email,
//       mobileNumber: userData.mobileNumber,
//       // ... other fields
//   }
// });
const SettingsPage = () => {
  return (
    // update is a wrapper and an id used with the hashlink to set it at the right location
    <div id="update">
      <div className="settings-container">
        <h3>Click on a button to update</h3>
        <SignUp isSettings={true} />
      </div>
    </div>
  );
};
export default SettingsPage;
