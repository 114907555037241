import { validatePasswordValue } from "../../constants/validate.js";
export const handleResetPassWordErrors = (
  resetPasswordValue, // values first order matters
  confirmResetPasswordValue,
  currentContext, // this always needs to be after the values
  setResetPasswordTooltipVisible, // setters next order matters
  setResetPassWordError,
  setConfirmResetPasswordError
) => {
  let errors = {};
  // console.log("Starting validation process");
  // // returning true or false to see if the current context is reset password and if the imput is empty at the time of the event to fix the required not returning
  // console.log("resetPasswordValue empty:", resetPasswordValue.length === 0);
  // console.log(
  //   "currentContext is resetPasswordSignin:",
  //   currentContext === "resetPasswordSignin"
  // );
  // Validate reset password
  if (
    currentContext === "resetPasswordSignin" &&
    resetPasswordValue.length === 0
  ) {
    console.log("Reset password is required");
    errors.resetPassWordError = "Required";
    setResetPassWordError(errors.resetPassWordError);
    setResetPasswordTooltipVisible(false); // Show tooltip for required error
  } else if (resetPasswordValue.length > 0) {
    const resetPasswordValidationResults =
      validatePasswordValue(resetPasswordValue);
    const isResetPasswordValid = resetPasswordValidationResults.every(
      (rule) => rule.isValid
    );
    console.log(
      "Reset password validation results:",
      resetPasswordValidationResults,
      "Is valid:",
      isResetPasswordValid
    );
    if (!isResetPasswordValid) {
      console.log("Reset password validation failed");
      errors.resetPassWordError = "Password validation failed";
      setResetPassWordError("Password validation failed");
      setResetPasswordTooltipVisible(true); // Show tooltip if password is invalid
    } else {
      console.log("Reset password is valid");
      setResetPasswordTooltipVisible(false); // Hide tooltip if password is valid
      setResetPassWordError("");
    }
  }

  // Validate confirm reset password
  if (
    confirmResetPasswordValue.length === 0 &&
    currentContext === "resetPasswordSignin"
  ) {
    errors.confirmResetPasswordError = "Required";
    setConfirmResetPasswordError(errors.confirmResetPasswordError);
  } else if (
    confirmResetPasswordValue.length > 0 &&
    confirmResetPasswordValue !== resetPasswordValue
  ) {
    errors.confirmResetPasswordError = "Passwords do not match";
    setConfirmResetPasswordError("Passwords do not match");
  } else {
    setConfirmResetPasswordError("");
  }
  console.log("Errors:", errors);
  return { errors };
};
