// Action types
export const INCREASE_GIFT_CARD_BALANCE = "INCREASE_GIFT_CARD_BALANCE";
export const DECREASE_GIFT_CARD_BALANCE = "DECREASE_GIFT_CARD_BALANCE";

// Action creators
export const increaseGiftCardBalance = (data) => ({
  type: INCREASE_GIFT_CARD_BALANCE,
  payload: data,
});
export const decreaseGiftCardBalance = (data) => ({
  type: DECREASE_GIFT_CARD_BALANCE,
  payload: data,
});
