import profile1 from "./assets/profile-1.jpg";
import profile2 from "./assets/profile-2.jpg";
import profile3 from "./assets/profile-3.jpg";

import media1 from "./assets/media-1.jpg";
import media2 from "./assets/media-2.jpg";
import media3 from "./assets/media-3.jpg";
import media4 from "./assets/media-4.jpg";

const mockData = {
  users: [
    {
      id: "user-uuid-1", // assinged at the time of signup from the uuid package after user is verified
      username: "stanMarsh", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      isInviteManager: true, // permission to invite others to groups, events, and group events default is set to true
      email: "stan@example.com", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      phoneNumber: "123-456-7890", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword1", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      firstName: "Stan", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      lastName: "Marsh", // collected at the signup, and in settings they can update permissions applied for accounnt owner
      currentBidPointsBal: 1000, // calculated by earnigs at a rate points does not equate to penny but how fast they are earning at the level they currently are..redemptions happen on the dashboard, mini dashboard and earnings happend in the place order calcualtions based on payment method applied and not gift card used
      currentRewardLevel: "gold", // nominal value for reward level that corresponds to the rater or mulitplier (how many points per dollar they earn)
      currentGiftCardBal: 50.5, // used for calculations in the mini dashboard as a constant in the invoice modal and reciept component redemption is always 1 penny per dollar
      giftCardBalUsed: 25.5, // dashboard display and calculated in the place order summary when used also displayed in the invoice modal and receipt component
      bio: "Testing Bio Data 1", // profile page in the book component we will collect the data there if user has permission to upload for the profile
      ipAddress: "12345", // need to find out how to store ip address since user can signup from other i think we should collected it at login and then use it to style the user's chat dynamically
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile1, // url stored on s3 bucket
    },
    {
      id: "user-uuid-2",
      username: "EricCartman",
      isInviteManager: true,
      email: "eric@example.com",
      phoneNumber: "345-456-7890",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword2",
      firstName: "Eric",
      lastName: "Cartman",
      currentBidPointsBal: 1000,
      currentRewardLevel: "gold",
      currentGiftCardBal: 50.5,
      giftCardBalUsed: 25.5,
      bio: "Testing Bio Data 2",
      ipAddress: "6789",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile2,
    },
    {
      id: "user-uuid-3",
      username: "KennyMcCormick",
      isInviteManager: true,
      email: "kenny@example.com",
      phoneNumber: "567-456-7890",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword",
      firstName: "Kenny",
      lastName: "McCormick",
      currentBidPointsBal: 1000,
      currentRewardLevel: "gold",
      currentGiftCardBal: 50.5,
      giftCardBalUsed: 25.5,
      bio: "Testing Bio Data 3",
      ipAddress: "1011",
      isNotifiable: true, // User wants to receive notifications
      imagePath: null,
    },
    {
      id: "user-uuid-4",
      username: "LauraPhipps",
      isInviteManager: true,
      email: "laura@example.com",
      phoneNumber: "890-456-7890",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword",
      firstName: "Laura",
      lastName: "Phipps",
      currentBidPointsBal: 1000,
      currentRewardLevel: "gold",
      currentGiftCardBal: 50.5,
      giftCardBalUsed: 25.5,
      bio: "Testing Bio Data 4",
      ipAddress: "1213",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile3,
    },
    {
      id: "user-uuid-5",
      username: "AcctHolder",
      isInviteManager: true,
      email: "acctHolder@example.com",
      phoneNumber: "310-993-7890",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword",
      firstName: "Account",
      lastName: "Holder",
      currentBidPointsBal: 1000,
      currentRewardLevel: "gold",
      currentGiftCardBal: 50.5,
      giftCardBalUsed: 25.5,
      bio: "Testing Bio Data 5",
      ipAddress: "1415",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile3,
    },
    {
      id: "user-uuid-6",
      username: "brendaSis",
      isInviteManager: true,
      email: "brenda@example.com",
      phoneNumber: "310-978-2165",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword1",
      firstName: "Brenda",
      lastName: "Thompso",
      currentBidPointsBal: 25000,
      currentRewardLevel: "blackDiamond",
      currentGiftCardBal: 0,
      giftCardBalUsed: 0,
      bio: "Testing Bio Data 6",
      ipAddress: "1617",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile1,
    },
    {
      id: "user-uuid-7",
      username: "rickKawin",
      isInviteManager: false,
      email: "rick@example.com",
      phoneNumber: "510-310-7990",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword2",
      firstName: "Rick",
      lastName: "Kawin",
      currentBidPointsBal: 0,
      currentRewardLevel: "star",
      currentGiftCardBal: 0,
      giftCardBalUsed: 0,
      bio: "Testing Bio Data 7",
      ipAddress: "1819",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile2,
    },
    {
      id: "user-uuid-8",
      username: "elleWoods",
      isInviteManager: true,
      email: "elle@example.com",
      phoneNumber: "310-444-2222",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword",
      firstName: "Elle",
      lastName: "Woods",
      currentBidPointsBal: 10000,
      currentRewardLevel: "platinum",
      currentGiftCardBal: 0,
      giftCardBalUsed: 0,
      bio: "Testing Bio Data 8",
      ipAddress: "2021",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile3,
    },
    {
      id: "user-uuid-9",
      username: "Lowlow",
      isInviteManager: true,
      email: "lowl@example.com",
      phoneNumber: "890-456-7890",
      phoneNumberVerified: true,
      skipVerified: true,
      passwordHash: "hashedPassword",
      firstName: "Low",
      lastName: "Low",
      currentBidPointsBal: 1000,
      currentRewardLevel: "gold",
      currentGiftCardBal: 50.5,
      giftCardBalUsed: 25.5,
      bio: "Testing Bio Data 4",
      ipAddress: "2223",
      isNotifiable: true, // User wants to receive notifications
      imagePath: profile3,
    },
  ],
  invoiceReceipt: [
    {
      id: "uuid-1", // assigned after user approves the invoice
      userId: "user-uuid-1", // who paid for the order
      tierPriceSelected: "$1000", // each tier prices total balance
      orderSubTotalBal: "2400", // the total order balance
      grandTotalBal: "2500", // merchant fee plus item subtotal right now item subtotal is just the tier pricing but that can change
      totalDueBal: "50", // the balance after gift card bal used applied on the order
      GiftCardBalUsed: "3", // the gift card balanced used on the order, data validations is up to the grand total amount, there should be no negative balance
      beginningGiftCardBal: "3500", // gift card balance before the order was placed, when points are redeemed we convert points with 1 penny libality per dollar so 2% discount but they do not get a reciept for that this will show in the dashboard
      currentGiftCardBal: "6000", // gift card balance after order is placed, if gift card used bal is applied then they would see a decreased balance from begining gift card balance to current gift card balance
      beginningPointsBalance: "7000", // points before order is placed
      currentPointsBalance: "6000", // if points were earned on the order then they would see a difference from the begining points balance
      orderPointsEarned: "400", // they can use this to make sure current points balance is correct if any earnings was applied, whic of course is payment method used - gift card bal used
      isInvoiceConfirmed: true, // if invoice is confirmend then a receipt is generated so this we could store the false ones for a few days just for trouble shooting so we may do a cleaing function for all the false ones, perhaps batch processing with a snap shot to the database
      beginingRewardLevelName: "gold", // reward level name assocatd with the mulitplier or rater before the order
      beginingRewardLevel: "3", // rewared level used to calculate order points on the order
      currentRewardLevelName: "silver", // reward level name assocatd with the mulitplier or rater after order is placed
      currentRewardLevel: "4", // reward level mutliplier after the order is placed
      invoiceStartDate: new Date(2023, 9, 22), // when invoice is generated
      receiptConfirmedEndDate: new Date(2023, 9, 22), // when invoice is paid and receipt is now generated
    },
    {
      id: "uuid-2",
      userId: "user-uuid-1",
      tierPriceSelected: "$1000",
      orderSubTotalBal: "2400",
      grandTotalBal: "2500",
      totalDueBal: "50",
      GiftCardBalUsed: "3",
      beginningGiftCardBal: "3500",
      currentGiftCardBal: "6000",
      beginningPointsBalance: "7000",
      currentPointsBalance: "6000",
      orderPointsEarned: "400",
      isInvoiceConfirmed: true,
      beginingRewardLevelName: "gold",
      beginingRewardLevel: "3",
      currentRewardLevelName: "silver",
      currentRewardLevel: "4",
      invoiceStartDate: new Date(2023, 9, 22),
      receiptConfirmedEndDate: new Date(2023, 9, 22),
    },
    {
      id: "uuid-3", // assigned after user approves the invoice
      userId: "user-uuid-1", // who paid for the order
      tierPriceSelected: "$1000", // each tier prices total balance
      orderSubTotalBal: "2400", // the total order balance
      grandTotalBal: "2500", // merchant fee plus item subtotal right now item subtotal is just the tier pricing but that can change
      totalDueBal: "50", // the balance after gift card bal used applied on the order
      GiftCardBalUsed: "3", // the gift card balanced used on the order, data validations is up to the grand total amount, there should be no negative balance
      beginningGiftCardBal: "3500", // gift card balance before the order was placed, when points are redeemed we convert points with 1 penny libality per dollar so 2% discount but they do not get a reciept for that this will show in the dashboard
      currentGiftCardBal: "6000", // gift card balance after order is placed, if gift card used bal is applied then they would see a decreased balance from begining gift card balance to current gift card balance
      beginningPointsBalance: "7000", // points before order is placed
      currentPointsBalance: "6000", // if points were earned on the order then they would see a difference from the begining points balance
      orderPointsEarned: "400", // they can use this to make sure current points balance is correct if any earnings was applied, whic of course is payment method used - gift card bal used
      isInvoiceConfirmed: true, // if invoice is confirmend then a receipt is generated so this we could store the false ones for a few days just for trouble shooting so we may do a cleaing function for all the false ones, perhaps batch processing with a snap shot to the database
      beginingRewardLevelName: "gold", // reward level name assocatd with the mulitplier or rater before the order
      beginingRewardLevel: "3", // rewared level used to calculate order points on the order
      currentRewardLevelName: "silver", // reward level name assocatd with the mulitplier or rater after order is placed
      currentRewardLevel: "4", // reward level mutliplier after the order is placed
      invoiceStartDate: new Date(2023, 9, 22), // when invoice is generated
      receiptConfirmedEndDate: new Date(2023, 9, 22), // when invoice is paid and receipt is now generated
    },
    {
      id: "uuid-4",
      userId: "user-uuid-1",
      tierPriceSelected: "$1000",
      orderSubTotalBal: "2400",
      grandTotalBal: "2500",
      totalDueBal: "50",
      GiftCardBalUsed: "3",
      beginningGiftCardBal: "3500",
      currentGiftCardBal: "6000",
      beginningPointsBalance: "7000",
      currentPointsBalance: "6000",
      orderPointsEarned: "400",
      isInvoiceConfirmed: true,
      beginingRewardLevelName: "gold",
      beginingRewardLevel: "3",
      currentRewardLevelName: "silver",
      currentRewardLevel: "4",
      invoiceStartDate: new Date(2023, 9, 22),
      receiptConfirmedEndDate: new Date(2023, 9, 22),
    },
    {
      id: "uuid-5", // assigned after user approves the invoice
      userId: "user-uuid-1", // who paid for the order
      tierPriceSelected: "$1000", // each tier prices total balance
      orderSubTotalBal: "2400", // the total order balance
      grandTotalBal: "2500", // merchant fee plus item subtotal right now item subtotal is just the tier pricing but that can change
      totalDueBal: "50", // the balance after gift card bal used applied on the order
      GiftCardBalUsed: "3", // the gift card balanced used on the order, data validations is up to the grand total amount, there should be no negative balance
      beginningGiftCardBal: "3500", // gift card balance before the order was placed, when points are redeemed we convert points with 1 penny libality per dollar so 2% discount but they do not get a reciept for that this will show in the dashboard
      currentGiftCardBal: "6000", // gift card balance after order is placed, if gift card used bal is applied then they would see a decreased balance from begining gift card balance to current gift card balance
      beginningPointsBalance: "7000", // points before order is placed
      currentPointsBalance: "6000", // if points were earned on the order then they would see a difference from the begining points balance
      orderPointsEarned: "400", // they can use this to make sure current points balance is correct if any earnings was applied, whic of course is payment method used - gift card bal used
      isInvoiceConfirmed: true, // if invoice is confirmend then a receipt is generated so this we could store the false ones for a few days just for trouble shooting so we may do a cleaing function for all the false ones, perhaps batch processing with a snap shot to the database
      beginingRewardLevelName: "gold", // reward level name assocatd with the mulitplier or rater before the order
      beginingRewardLevel: "3", // rewared level used to calculate order points on the order
      currentRewardLevelName: "silver", // reward level name assocatd with the mulitplier or rater after order is placed
      currentRewardLevel: "4", // reward level mutliplier after the order is placed
      invoiceStartDate: new Date(2023, 9, 22), // when invoice is generated
      receiptConfirmedEndDate: new Date(2023, 9, 22), // when invoice is paid and receipt is now generated
    },
    {
      id: "uuid-6",
      userId: "user-uuid-1",
      tierPriceSelected: "$1000",
      orderSubTotalBal: "2400",
      grandTotalBal: "2500",
      totalDueBal: "50",
      GiftCardBalUsed: "3",
      beginningGiftCardBal: "3500",
      currentGiftCardBal: "6000",
      beginningPointsBalance: "7000",
      currentPointsBalance: "6000",
      orderPointsEarned: "400",
      isInvoiceConfirmed: true,
      beginingRewardLevelName: "gold",
      beginingRewardLevel: "3",
      currentRewardLevelName: "silver",
      currentRewardLevel: "4",
      invoiceStartDate: new Date(2023, 9, 22),
      receiptConfirmedEndDate: new Date(2023, 9, 22),
    },
  ],
  calendars: [
    { // all numbers need to be intialized default 0
      id: "calendar-uuid-1",
      adminId: "user-uuid-2",
      isGroupEvent:false,
      isRemote: true,
      eventTitle: "Laura's Chic-Fila Party",
      eventTotalInterested: 250,
      eventTotalAttending: 100,
      eventTotalDeclined: 50,
      eventTotalInvited: 300,
      eventTotalMaybe: 400,
      eventTotalExcluded: 25,
      eventPlace: "The Mansion",
      eventStreet: "123 Melrose",
      eventCity: "Boca",
      eventState: "Florida",
      eventZipCode: "90210",
      isPrivate: false,
      eventDescription: "Save the cows!",
      eventStartDate: new Date(2023, 9, 22),
      eventStartTime: "03:00 PM",
      eventEndDate: new Date(2023, 9, 22),
      eventEndTime: "05:00 PM",
      imagePath: media1,
    },
    {
      id: "calendar-uuid-2",
      adminId: "user-uuid-3",
      isGroupEvent:false,
      isRemote: false,
      eventTitle: "Celebrating Progress",
      eventTotalInterested: 150,
      eventTotalAttending: 50,
      eventTotalDeclined: 50,
      eventTotalInvited: 300,
      eventTotalMaybe: 400,
      eventTotalExcluded: 25,
      eventPlace: "Place Test",
      eventStreet: "1234 5 street name",
      eventCity: "city",
      eventState: "Michigan",
      eventZipCode: "1234",
      isPrivate: false,
      eventDescription: "Lifestyle!",
      eventStartDate: new Date(2023, 9, 22),
      eventEndDate: new Date(2023, 9, 22),
      imagePath: media4,
    },
    {
      id: "calendar-uuid-3",
      adminId: "user-uuid-3",
      isGroupEvent:false,
      isRemote: false,
      eventTitle: "Celebrating Progress",
      eventTotalInterested: 150,
      eventTotalAttending: 50,
      eventTotalDeclined: 50,
      eventTotalInvited: 300,
      eventTotalMaybe: 400,
      eventTotalExcluded: 25,
      eventPlace: "Place Test",
      eventStreet: "1234 5 street name",
      eventCity: "city",
      eventState: "Michigan",
      eventZipCode: "1234",
      isPrivate: false,
      eventDescription: "Lifestyle!",
      eventStartDate: new Date(2023, 9, 22),
      eventEndDate: new Date(2023, 9, 22),
      imagePath: null,
    },
    {
      id: "calendar-uuid-25",
      adminId: "user-uuid-3",
      isGroupEvent:true,
      isRemote: false,
      eventTitle: "Test Group Event",
      eventTotalInterested: 150,
      eventTotalAttending: 50,
      eventTotalDeclined: 50,
      eventTotalInvited: 300,
      eventTotalMaybe: 400,
      eventTotalExcluded: 25,
      eventPlace: "Place Test",
      eventStreet: "1234 5 street name",
      eventCity: "city",
      eventState: "Michigan",
      eventZipCode: "1234",
      isPrivate: false,
      eventDescription: "Lifestyle!",
      eventStartDate: new Date(2023, 9, 22),
      eventEndDate: new Date(2023, 9, 22),
      imagePath: null,
    },
  ],
  groups: [
    {
      id: "g1", // all numbers need to be intialized default 0
      adminId: "user-uuid-1",
      groupTitle: "Vibe with Your Tribe",
      groupTotalMembers: "250",
      groupTotalExcluded: 25,
      groupTotalInterested: 0,
      groupTotalInvited: 300,
      groupTotalDeclined: 50,
      groupTotalModerators: 300,
      lastActive: "last week",
      isPrivate: false,
      groupDescription: "Innovative People",
      imagePath: media1,
    },
    {
      id: "g2",
      adminId: "user-uuid-2",
      groupTitle: "Italians R US",
      groupTotalMembers: "150",
      groupTotalExcluded: 20,
      groupTotalInvited: 100,
      groupTotalDeclined: 25,
      isPrivate: false,
      groupDescription: "Ciao",
      imagePath: media2,
    },
    {
      id: "g3",
      adminId: "user-uuid-3",
      groupTitle: "Alexa, Siri, Bixby",
      groupTotalMembers: "100",
      groupTotalExcluded: 40,
      groupTotalInvited: 120,
      groupTotalDeclined: 35,
      isPrivate: true,
      groupDescription: "They will team up on you and take your electricity",
      imagePath: media3,
    },
  ],
  posts: [
    // user has many post and comments post belongs to a user and a comment belongs
    // to a post
    {     // all numbers need to be intialized default 0
      id: "post-uuid-1",
      userId: "user-uuid-1",
      imagePath: media1,
      groupId: null,
      commentId: "comment-uuid-1",
      totalDayPost: 5,
      totalWeekPost: 0,
      totalMonthPost: 0,
      totalYearPost: 0,
    },
    {
      id: "post-uuid-5",
      userId: "user-uuid-1",
      imagePath: media2,
      groupId: null,
      commentId: "comment-uuid-2",
      totalDayPost: 5,
      totalWeekPost: 10,
      totalMonthPost: 0,
      totalYearPost: 0,
    },
    {
      id: "post-uuid-6",
      userId: "user-uuid-1",
      groupId: "g3",
      imagePath: media3,
      commentId: "comment-uuid-3",
      totalDayPost: 3,
      totalWeekPost: 0,
      totalMonthPost: 11,
      totalYearPost: 0,
    },
    {
      id: "post-uuid-7",
      userId: "user-uuid-1",
      groupId: null,
      imagePath: media4,
      commentId: "comment-uuid-4",
      totalDayPost: 0,
      totalWeekPost: 0,
      totalMonthPost: 0,
      totalYearPost: 12,
    },
    {
      id: "post-uuid-12",
      userId: "user-uuid-2",
      imagePath: media4,
      groupId: "g1",
      commentId: "comment-uuid-5",
      totalDayPost: 0,
      totalWeekPost: 0,
      totalMonthPost: 0,
      totalYearPost: 0,
    },
    {
      id: "post-uuid-23",
      userId: "user-uuid-3",
      imagePath: media4,
      groupId: "g2",
      commentId: "comment-uuid-6",
      totalDayPost: 0,
      totalWeekPost: 0,
      totalMonthPost: 0,
      totalYearPost: 0,
    },
    {
      id: "post-uuid-15",
      userId: "user-uuid-5",
      imagePath: media4,
      commentId: "comment-uuid-7",
      totalDayPost: 0,
      totalWeekPost: 0,
      totalMonthPost: 0,
      totalYearPost: 0,
    },
  ],
  activityLogs: [
    {
      // even if it is self notification it works for all accepting scenarios
      id: "acivity-log-id-229",
      sourceId: "user-uuid-7", // stan new admin for group
      relatedId: "calendar-uuid-25", 
      targetId: "user-uuid-1", // stan // to get all join users the target user id is needed for groups
      activity: "created group event",
      dataType: "status",
      objectType: "group event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the admin of the groupevent is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current admin role for event name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-1",
      sourceId: "user-uuid-2", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-2",
      sourceId: "user-uuid-1", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-3", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-3",
      sourceId: "user-uuid-1", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-4", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    // in order to get someone's followers you need to know who is following the person
    {
      id: "acivity-log-id-9",
      sourceId: "user-uuid-2", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-10",
      sourceId: "user-uuid-3", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-11",
      sourceId: "user-uuid-4", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-15",
      sourceId: "user-uuid-3", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-2", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "video",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-17",
      sourceId: "user-uuid-2", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-4", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-19",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-20",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-2", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-21",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-3", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      threadId: null, // ID of the thread the comment belongs to
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-22",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-4", // to get all the followers we will need to know all the source users the target users is being followed by
      relatedId: null, // optional and data validation (when user is the object type this will be null)
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-23",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      activity: "following",
      targetId: "user-uuid-6", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-24",
      sourceId: "user-uuid-8", // to get all the followings we will need the target user the source user is following
      relatedId: 'g1', 
      activity: "following",
      targetId: "user-uuid-5", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-24",
      sourceId: "user-uuid-8", // to get all the followings we will need the target user the source user is following
      relatedId: 'g1', 
      activity: "",
      targetId: "user-uuid-1", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when user logs in they will see their following list and this is filed in that list
      id: "acivity-log-id-25",
      sourceId: "user-uuid-5", // to get all the followings we will need the target user the source user is following
      relatedId: null, 
      activity: "following",
      targetId: "user-uuid-8", // to get all the followers we will need to know all the source users the target users is being followed by
      dataType: "status",
      objectType: "user",
      targetOwnerId: null, // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetId", // user id is following you
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user will see their block list when they log in and is not notifiable to the other user
      id: "acivity-log-id-26",
      sourceId: "user-uuid-5", // to get all the blocking we will need the target user the source user is blocking
      activity: "blocking",
      targetId: "user-uuid-9", // to get all the blockers we will need to know all the source users the target user is being blocked by
      dataType: "status",
      objectType: "user", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: false,
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // liked a video
      id: "acivity-log-id-29",
      sourceId: "user-uuid-8", // the actor on the event
      targetId: "user-uuid-2", // the owner of the post
      relatedId: "post-uuid-1",
      activity: "liked",
      dataType: "status",
      objectType: "video",
      targetOwnerId: "user-uuid-4", // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetOwnerId", // user id like post id
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // join a thread for chat will be the comment id
      id: "acivity-log-id-30",
      sourceId: "user-uuid-5", // the user who initiated the chat thread
      targetId: "user-uuid-4", // to get all join users the target user id is needed for groups
      activity: "invited",
      relatedId: "comment-id",
      dataType: "category",
      objectType: "chat",
      targetId: "user-uuid-1",
      createdAt: new Date(),
      updatedAt: new Date(),
      // deletedAt can be null or a Date object
      deletedAt: null,
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
    },
    {
      // activity log example for a user who is interested in an event
      id: "acivity-log-id-31",
      sourceId: "user-uuid-1", // user id interested in event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4", // admin of event
      activity: "interested", // when a user presses interested it means the user wants to join
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // activity log example for a user who is invited" in an event
      id: "acivity-log-id-32",
      sourceId: "user-uuid-4", // send a notification request to join the target user
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      postSourceId: null, // Optional: ID of the user who created the post (only relevant for post-related activities)
      threadId: null, // ID of the thread the comment belongs to
      targetId: "user-uuid-2", // person sending a request to join the calendar event in this case invite manager is requesting a user to join
      activity: "invited", // person sending a request to join the calendar event in this case invite manager is requesting a user to join, we would need to get all the target user ids for invited
      postSourceId: null, // Optional: ID of the user who created the post (only relevant for post-related activities)
      threadId: null, // ID of the thread the comment belongs to
      dataType: null,
      objectType: "calendar", // the object of the activity
      createdAt: new Date(),
      updatedAt: new Date(),
      // deletedAt can be null or a Date object
      deletedAt: null,
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
    },
    {
      id: "acivity-log-id-33",
      sourceId: "user-uuid-4", // invite manager id
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "invited", // person sending a request to join the calendar event in this case invite manager is requesting a user to join, we would need to get all the target user ids for invited
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-34",
      sourceId: "user-uuid-4", // invite manager id
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-7",
      activity: "invited", // person sending a request to join the calendar event in this case invite manager is requesting a user to join, we would need to get all the target user ids for invited
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-35",
      sourceId: "user-uuid-4", // user who is interested in joining event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-9", // admin of event
      activity: "invited", // person sending a request to join the calendar event in this case invite manager is requesting a user to join, we would need to get all the target user ids for invited
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-36",
      sourceId: "user-uuid-9", // user who is interested in joining event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4", // admin of event
      activity: "interested", // when a user presses interested it means the user wants to join
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-37",
      sourceId: "user-uuid-6", // user who is interested in joining event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4", // admin of event
      activity: "interested", // when a user presses interested it means the user wants to join
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-38",
      sourceId: "user-uuid-4", // user who is interested in joining event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-10", // admin of event
      activity: "invited", // person sending a request to join the calendar event in this case invite manager is requesting a user to join, we would need to get all the target user ids for invited
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-39",
      sourceId: "user-uuid-4", // user who is interested in joining event
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-11", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-41",
      sourceId: "user-uuid-7", // user who is interested in joining event
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-42",
      sourceId: "user-uuid-9", // user who is interested in joining event
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-43",
      sourceId: "user-uuid-11", // user who is interested in joining event
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-44",
      sourceId: "user-uuid-10", // user who is interested in joining event
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-45",
      sourceId: "user-uuid-5", // user who is interested in joining event
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // admin of event
      activity: "interested", // asking to join event
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-46",
      sourceId: "user-uuid-3", //  the uuid of the person attending
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-8", // person sending a request to join the calendar event in this case admin for any interested activities
      activity: "attending", // data validation if attending from interested then switch source and target user with the update
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "Meetonup hopes you have a safe and fun time attending event name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
      {  //  i think i got rid of maybe
      id: "acivity-log-id-45",
      sourceId: "user-uuid-6", // targetId: Switches to the original source user, who is now the recipient of the response, to get all the maybe users we would need the source user uuid
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-12", // admin of event
      activity: "interested", // when maybe is pressed we will update the target and source ids as well as they switch and when maybe is updated then we will switch the users again with the update
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: "user-uuid-2",
      notifiableId: "targetOwnerId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt:  null,  // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-48",
      sourceId: "user-uuid-2",
      relatedId: "g2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // admin of the group
      activity: "member",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the admin of the group is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "you are currently the admin of this group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-49",
      sourceId: "user-uuid-2", // the invite manager id
      relatedId: "g2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4",
      activity: "invited",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-348",
      sourceId: "user-uuid-2", //eric the person who sent the admin request or created the admin role is now admin
      relatedId: "g2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-2", // the person who became the admin of the group
      activity: "administrator", // invite manager sent request to join group event
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the source id becomes the target id because it was who was the last admin send notification of the change
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "Congrats on your administrator role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-348",
      sourceId: "user-uuid-9", //eric the person who sent the admin request or created the admin role
      relatedId: "g2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-9", // the person who became the admin of the group
      activity: "left", // invite manager sent request to join group event
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // notify the user "you left the group"
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, 
      statusMessageReadAt: null, 
    },
    {
      id: "acivity-log-id-348",
      sourceId: "user-uuid-1", // admin who removed the user
      relatedId: "g2", 
      targetId: "user-uuid-2", // the person who became the admin of the group
      activity: "removed",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // you have been removed from group name
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, 
      statusMessageReadAt: null, 
    },
    {
      id: "acivity-log-id-149",
      sourceId: "user-uuid-5", // invite manager account holder in this case admi
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-6", // notify user and brenda in this case and to get all inivted users get the targe users
      activity: "invited", // invite manager sent request to join group event
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-150",
      sourceId: "user-uuid-5", // the invite manager in this case admin accout holder
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // stan marsh // to get all inivted users get the targe users
      activity: "invited",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-151",
      sourceId: "user-uuid-8", //lowlow
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // to get all join users the target user id is needed for groups
      activity: "member", // data validation, if interested switched to joined then switch source and target user, invite manager sent request to join group event
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the admin of the group is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "Welcome to this group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // even if it is self notification it works for all accepting scenarios
      id: "acivity-log-id-249",
      sourceId: "user-uuid-1", // stan new admin for group
      relatedId: "g1", 
      targetId: "user-uuid-1", // stan // to get all join users the target user id is needed for groups
      activity: "created group",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the admin of the group is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current admin role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // even if it is self notification it works for all accepting scenarios
      id: "acivity-log-id-549",
      sourceId: "user-uuid-5", // account holder new admin, get all the source ids for admin
      relatedId: "g1", 
      targetId: "user-uuid-1", // notify old admin of new adminstrator
      activity: "administrator",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the admin of the group is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current admin role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user accepts or gets accepted to join the user is now a member
      id: "acivity-log-id-269",
      sourceId: "user-uuid-4", // laura the member of the group id
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-5", // the admin of the group
      activity: "member",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the group admin is notified user is now member
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "your now a member of group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-549",
      sourceId: "user-uuid-4", // account holder is the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4",
      activity: "cohost",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-549",
      sourceId: "user-uuid-1", // stan the person who is cohosting
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-6", // brenda admin of event
      activity: "cohost",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetid", // the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-549",
      sourceId: "user-uuid-1", // stan the uuid of the person attending
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-4", // admin of the event
      activity: "attending", // data validation if attending from interested then switch source and target user with the update
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null, // the admin of the user who owns the event
      notifiableId: "targetId", // notify the admin of the event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "Meetonup hopes you have a safe and fun time attending event name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-659",
      sourceId: "user-uuid-9", // account holder the person who will be attending
      relatedId: "calendar-uuid-2", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // ID of the event admin, if different from the cohost
      activity: "host", // data validation permissions =  admin priveledges
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current hosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-349",
      sourceId: "user-uuid-6", // stan the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // brenda //  evet admin of event which is calendar id
      activity: "cohost",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-329",
      sourceId: "user-uuid-6", // stan the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // brenda //  evet admin of event which is calendar id
      activity: "interested",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-329",
      sourceId: "user-uuid-6", // stan the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // brenda //  evet admin of event which is calendar id
      activity: "declined",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-319",
      sourceId: "user-uuid-6", // stan the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // brenda //  evet admin of event which is calendar id
      activity: "excluded",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user accepts or gets accepted this works for both
      id: "acivity-log-id-319",
      sourceId: "user-uuid-6", // stan the person who is cohosting
      relatedId: "calendar-uuid-1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-1", // brenda //  evet admin of event which is calendar id
      activity: "maybe",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", //  the event or admin is notified user is now cohosting
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "congrats on your current cohosting role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to cohost
      id: "acivity-log-id-539",
      sourceId: "user-uuid-1", // user who asked to cohost
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-2", // admin of the event
      activity: "cohost-request",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a cohost request to admin
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to cohost
      id: "acivity-log-id-739",
      sourceId: "user-uuid-1", // user who asked to cohost
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-2", // admin of the event
      activity: "host",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a cohost request to admin
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to cohost
      id: "acivity-log-id-839",
      sourceId: "user-uuid-1", // user who asked to cohost
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-2", // admin of the event
      activity: "administrator",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a cohost request to admin
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to cohost
      id: "acivity-log-id-639",
      sourceId: "user-uuid-1", // user who asked to cohost
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-2", // admin of the event
      activity: "cohost-invite",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a cohost request to admin
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to cohost
      id: "acivity-log-id-139",
      sourceId: "user-uuid-1", // user who asked to cohost
      relatedId: "calendar-uuid-1",
      targetId: "user-uuid-2", // admin of the event
      activity: "interested",
      dataType: "status",
      objectType: "event", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a cohost request to admin
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // user is invited to moderate
      id: "acivity-log-id-269",
      sourceId: "user-uuid-4", // laura - the id of the invite manager
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-9", // the id of who will recieve the request
      activity: "moderating-request",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send the user a request to be a group moderator for the group name
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-349",
      sourceId: "user-uuid-2", // eric
      relatedId: "g1", // the id of the calendar event the user wants to join
      targetId: "user-uuid-3", // kenny
      activity: "moderator",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: "user-uuid-",
      notifiableId: "targetId", // the admin of the group is not notified
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: new Date(), // Update when notification is sent not when the log entery is done, send a message to the source id saying "Congrats on your moderating role for group name"
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // the joined, cancels or user gets rejected will change the invited status
      id: "acivity-log-id-51",
      sourceId: "user-uuid-5", // the invite manager in this case admin
      relatedId: "g1", // the group the user is invited to join
      targetId: "user-uuid-7", // rick kawin got invited
      activity: "invited",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // the joined, cancels or user gets rejected will change the invited status
      id: "acivity-log-id-751",
      sourceId: "user-uuid-4", // laura phipps declined person need all source ids
      relatedId: "g1", // the group the user is invited to join
      targetId: "user-uuid-5", //notify admin of the group
      activity: "declined",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // the joined, cancels or user gets rejected will change the invited status
      id: "acivity-log-id-851",
      sourceId: "user-uuid-4", // laura phipps excluded
      relatedId: "g1", // the group the user is invited to join
      targetId: "user-uuid-5", //notify admin of the group
      activity: "excluded",
      dataType: "status",
      objectType: "group", // the object of the activity
      targetOwnerId: null,
      notifiableId: "targetId", // send a request to admin to join event
      isNotifiable: true, //
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user uploads a video it creates a post id
      id: "acivity-log-id-52",
      sourceId: "user-uuid-1",
      relatedId: "media-1",
      targetId: "post-id",
      activity: "created post and uploaded video", // invite manager sent request to join group event
      dataType: "blob",
      objectType: "video", // the object of the activity
      targetOwnerId: "user-uuid-1",
      notifiableId: "targetOwnerId", // this is a self notification where user is notified of their own activity
      isNotifiable: true, // users will see their activity incase of issues they can turn it off
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      id: "acivity-log-id-53",
      sourceId: "user-uuid-1", // the actor on the event file the uuid with the reaction type
      relatedId: "Post-Id2", // when another object is involved besides the user object
      targetId: "user-uuid-2", // the owner of the post
      activity: "liked",
      dataType: "status",
      objectType: "image", // the object of the activity
      targetOwnerId: "user-uuid-4", // the owner of the post will have to query that and then store it here so we don't have to query again and can just get it from the events
      notifiableId: "targetOwnerId", // user id like post id
      isNotifiable: true,
      isReadAt: null, // when a time stamp is present we will show notifications as marked read if all notifications are read then the red dot at the bell goes away
      isNotifiedAt: null, // Set when the user is notified or remains null based on user settings, if no target user it remains null
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Update when notification is sent not when the log entery is done
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      //reply to id is set in the mock data for replying to a specific thread id that is indexed, this can not go on being nested as user will be allowed to reply once to the thread id, which creates a new thread id and is indexed, need to check how that is done
      id: "activity-log-id-54",
      sourceId: "user-uuid-1", // User who made the comment
      relatedId: "comment-uuid-1", // ID of the comment
      targetId: "thread-uuid-1+IndexId",
      activity: "replied to a thread id",
      dataType: "text",
      objectType: "comment",
      targetOwnerId: "user-uuid", // the owner of the thread id with the auto index number
      notifiableId: "targetOwnerId", // let the user know a comment is made to the specific thread posted on the comment
      isNotifiable: true,
      isReadAt: null, // Timestamp when the log is marked as read
      isNotifiedAt: null, // Timestamp when the user is notified
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Initialized as null, updated when a notification is sent
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when we need to place a comment on a post
      id: "activity-log-id-55",
      sourceId: "user-uuid-1", // User who made the comment
      relatedId: "comment-uuid-1", // ID of the comment
      targetId: "post-uuid-1",
      activity: "created comment",
      dataType: "text",
      objectType: "comment",
      targetOwnerId: "user-uuid", // id of user who owns the post
      notifiableId: "targetOwnerId", // let the user know a comment is made on the user's post
      isNotifiable: true,
      isReadAt: null, // Timestamp when the log is marked as read
      isNotifiedAt: null, // Timestamp when the user is notified
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Initialized as null, updated when a notification is sent
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user replies to a comment but not a thread id and there are not other replies on the comment
      id: "activity-log-id-56",
      sourceId: "user-uuid-1", // User who made the comment
      relatedId: "post-uuid-1", // ID of the comment
      targetId: "comment-uuid-2",
      activity: "started a thread",
      dataType: "text",
      objectType: "comment",
      targetOwnerId: "user-uuid", // id of user who owns the comment
      notifiableId: "targetOwnerId", // let the user know a thread was started on the user's comment
      isNotifiable: true,
      isReadAt: null, // Timestamp when the log is marked as read
      isNotifiedAt: null, // Timestamp when the user is notified
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Initialized as null, updated when a notification is sent
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
    {
      // when a user replies to a comment but not a thread id, but there are other replies on the comment
      id: "activity-log-id-57",
      sourceId: "user-uuid-1", // User who made the comment
      relatedId: "post-uuid-1", // ID of the comment
      targetId: "comment-uuid-2",
      activity: "joined  a thread",
      dataType: "text",
      objectType: "comment",
      targetOwnerId: "user-uuid", // id of user who owns the comment
      notifiableId: "targetOwnerId", // let the user know another user joined the thread belonging to the user's comment
      isNotifiable: true,
      isReadAt: null, // Timestamp when the log is marked as read
      isNotifiedAt: null, // Timestamp when the user is notified
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: null, // deletedAt can be null or a Date object
      sentStatusChangeNotificationAt: null, // Initialized as null, updated when a notification is sent
      statusMessageReadAt: null, // Initialized as null, updated when the notification is read
    },
  ],
  comments: [
    {
      id: "comment-uuid-1",
      postId: "post-uuid-1",
      sourceId: "user-uuid-1",
      groupId: null, // the id of the calendar event the user wants to join
      threadId: "thread-uuid-1",
      text: "This is a comment on Stan's first post",
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: "comment-uuid-2",
      sourceId: "user-uuid-2",
      postId: "post-uuid-1",
      groupId: null, // the id of the calendar event the user wants to join
      threadId: "thread-uuid-2",
      text: "New Comment Different Post",
      replyToId: "comment-uuid-1", // This comment is a reply to comment-uuid-1
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: "comment-uuid-3",
      postId: "post-uuid-1",
      sourceId: "user-uuid-1",
      groupId: "g1", // the id of the calendar event the user wants to join
      threadId: "thread-uuid-3",
      text: "Hey, hey, welcome to chat gpt and laura's group",
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: "comment-uuid-4",
      sourceId: "user-uuid-2",
      postId: "post-uuid-1",
      groupId: "g2", // the id of the calendar event the user wants to join
      threadId: "thread-uuid-4",
      text: "New comment for a different post for another group",
      replyToId: "comment-uuid-1", // This comment is a reply to comment-uuid-1
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    {
      id: "comment-uuid-5",
      sourceId: "user-uuid-2",
      postId: "post-uuid-1",
      groupId: null, // the id of the calendar event the user wants to join
      threadId: null,
      text: "no thread yet",
      replyToId: "comment-uuid-1", // This comment is a reply to comment-uuid-1
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    // More comments...
  ],
  commentThreads: [
    {
      id: "thread-uuid-1",
      userId: "user-uuid-4", // User who started the thread
      autoIndex: 1,
      replyToIndex: 1,
      createdAt: new Date(),
      updatedAt: new Date(),
      text: "Thread for Stan's first post",
    },
    {
      id: "thread-uuid-1",
      userId: "user-uuid-3", // Different user replying
      autoIndex: 2,
      replyToIndex: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      text: "replying to the comment",
    },
    {
      id: "thread-uuid-1",
      userId: "user-uuid-1",
      autoIndex: 3,
      replyToIndex: 2,
      createdAt: new Date(),
      updatedAt: new Date(),
      text: "replying to index above",
    },
    {
      id: "thread-uuid-3",
      userId: "user-uuid-2", // Different user replying
      autoIndex: 1,
      replyToIndex: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      text: "Hey Chat GPT, Excited to be Here!",
    },
    {
      id: "thread-uuid-4",
      userId: "user-uuid-2", // Different user replying
      autoIndex: 1,
      replyToIndex: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      text: "Hey, threads are so much fun, linear approach",
    },
  ],
};
export default mockData;
