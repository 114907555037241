import { useEffect, useState } from "react";
import BookPage from "../bookPage/bookPage";
import { useMediaQuery } from "../../hooks/index.js";
import { WhiteBtn } from "../buttons/index.jsx";
import GlobalThreeLineAnimation from "../../globalViews/globalThreeLineAnimation.jsx";
import "./book.scss";
import BookControl from "../bookControl/bookControl";

const Book = ({ users }) => {
  const initialDelay = 1000;
  const [delay, setDelay] = useState(initialDelay); // Speed in milliseconds (1 second)
  const [animationFrame, setAnimationFrame] = useState(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [pageNum, setPage] = useState(1);
  const [forwardAnimation, setForwardAnimation] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false); // NEW: state to control animation based on scroll
  const [showBookControl, setShowBookControl] = useState(false);

  useEffect(() => {
    // Function to handle scrolling
    const handleScroll = () => {
      // Check if screen width is less or equal to 550px
      if (window.innerWidth <= 550) {
        if (window.scrollY > 100) {
          // if scrolled more than 100 pixels
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Note: empty dependency array to add the listener only on mount

  useEffect(() => {
    let interval;
    let shouldFlip = true;

    if (isPlaying && !isScrolled) {
      interval = setInterval(
        () => {
          requestAnimationFrame(() => {
            if (!shouldFlip) return;

            shouldFlip = false;

            if (forwardAnimation) {
              setPage((prevPage) => prevPage + 2);
              if (pageNum > users.length + 3) {
                setForwardAnimation(false);
                setPage((prevPage) => prevPage - 1);
              }
            } else {
              setPage((prevPage) => prevPage - 2);
              if (pageNum < 3) {
                setForwardAnimation(true);
                setPage((prevPage) => prevPage + 1);
              }
            }

            if (pageNum >= 1 && pageNum <= users.length + 4) {
              const page = document.getElementById(`pg${pageNum}`);
              if (pageNum % 2 !== 0) {
                page.classList.add("flipped");
                page.nextElementSibling.classList.add("flipped");
              } else {
                page.classList.remove("flipped");
                page.previousElementSibling.classList.remove("flipped");
              }
            }
          });

          setTimeout(() => {
            shouldFlip = true;
          }, delay);
        },
        forwardAnimation ? delay : 50
      );
    }

    return () => {
      clearInterval(interval);
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [pageNum, forwardAnimation, isPlaying, delay, isScrolled]); // Note: include isScrolled in dependencies

  const isOddNumberOfPages = users.length % 2 !== 0;
  const isLastPage = (idx) => {
    return idx === users.length - 1;
  };
  useEffect(() => {
    const _pages = document.getElementsByClassName("page");

    for (let i = 0; i < _pages.length; i++) {
      const page = _pages[i];

      if (i % 2 === 0) {
        page.style.zIndex = _pages.length - i;
      }
    }
  }, [users]);

  
  const excludeCommunityUsers = users.filter(
    (user) => user.type !== "community"
  );
  const contactListUsers = isDesktop
    ? excludeCommunityUsers
    : excludeCommunityUsers.slice(0, 4);

  return (
    // <div id='book' style={{ marginTop: '120px' }}>
    <div id="book">
      <div id="pages" className="pages">
        <div id="pg1" className="page book-cover">
          <span className="book-title">
            LITTLE
            <br />
            BLACK
            <br />
            BOOK
          </span>
        </div>

        <div id="pg2" className="page">
          <p className="book-intro">
            Connect and Create on Meet On Up!
          </p>
        </div>

        <div id="pg3" className="page contact-list-page">
          {/* <div id="contact-list">
            {contactListUsers.map((user) => (
              <BookPage key={JSON.stringify(user)} user={user} />
            ))}
          </div> */}
        </div>

        {users.map((user, userIndex) => (
          // 3 pages are above so starting from 4th Page, userIndex will return 0, 1, 2, 3, ... with respect to user 1, user 2, user 3, ...
          <div
            id={`pg${userIndex + 4}`}
            key={JSON.stringify(user)}
            className={`${
              isOddNumberOfPages && isLastPage(userIndex) ? "book-cover" : ""
            } page`}
          >
            <p className="username">{user.firstName}</p>
            <div className="profile-image-container">
              <img alt="profile" src={user.thumbnail} />
            </div>
          </div>
        ))}

        {/* 
          Add an additional page when we have an even number of pages
          So that it looks like an actual book. 
          users.length + 4, it means the last page has id users.length + 4
          i.e first 3 intro pages + num of users + 1 last page = users.length + 4
        */}
        {!isOddNumberOfPages && (
          <div id={`pg${users.length + 4}`} className="page book-cover" />
        )}
      </div>
      <div id="book">
        <div
          id="book-control-button"
        >
          {showBookControl ? (
            <WhiteBtn
              whiteBtnImage={
                <GlobalThreeLineAnimation
                  fillColor="#964b00"
                  height="45px"
                  width="35px"
                  lineWidth="45px"
                  lineHeight="5px"
                  animationType="1"
                />
              }
              onClick={(e) => {
                e.preventDefault();
                setShowBookControl(false);
              }}
              whiteBtnText="Hide Book Control"
              whiteBtnFontColor="#964b00"
              whiteBtnBorderColor="#964b00"
            />
          ) : (
            <WhiteBtn
              whiteBtnImage={
                <GlobalThreeLineAnimation
                  fillColor="#964b00"
                  height="45px"
                  width="35px"
                  lineWidth="15px"
                  lineHeight="5px"
                  animationType="5"
                />
              }
              onClick={(e) => {
                e.preventDefault();
                setShowBookControl(true);
              }}
              whiteBtnText="Show Book Control"
              whiteBtnFontColor="#964b00"
              whiteBtnBorderColor="#964b00"
              whiteBtnfontSize=".75rem"
              whiteBtnwidth="fit-content"
              whiteBtnheight="50px"
              whiteBtnAnimationActive = {true}
            />
          )}
        </div>
        {showBookControl && (
          <BookControl
            initialDelay={initialDelay}
            delay={delay}
            setDelay={setDelay}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        )}
      </div>
    </div>
  );
};

export default Book;
