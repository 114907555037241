export const getStorageKey = (baseKey, currentContext) =>
  `${currentContext}_${baseKey}`; // generating a unique key for local storage key value pairs solutions, else it will be global and cause filling issues

  // export const forEachKeyInContext = (currentContext, callback) => {
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key && key.startsWith(`${currentContext}_`)) {
  //       callback(key, localStorage.getItem(key));
  //     }
  //   }
  // };
    // When removing an item from localStorage
    // forEachKeyInContext("payments", (key, value) => {
    //   console.log(`Key: ${key}, Value: ${value}`);
    // });