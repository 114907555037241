// Action types
export const SET_PLACE_ORDER = "SET_PLACE_ORDER";
export const CANCEL_PLACE_ORDER = "CANCEL_PLACE_ORDER";

// Action creators
export const setPlaceOrder = (data) => ({
  type: SET_PLACE_ORDER,
  payload: data,
});
export const cancelPlaceOrder = () => ({
  type: CANCEL_PLACE_ORDER
});
