import { validatePasswordValue } from "../../constants/validate.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const onSignInFocusPassword = (
    signInPasswordValue,
    setSigninPasswordFocused,
    setPasswordValidationRules,
    setToolTipVisible
  ) => {
    setSigninPasswordFocused(true);
    const signinPasswordValidationResults = validatePasswordValue(
      signInPasswordValue
    ).map((rule) => ({
      ...rule,
      isValid: rule.isValid,
    }));
    setPasswordValidationRules(signinPasswordValidationResults);
    setToolTipVisible(
      signinPasswordValidationResults.some((rule) => !rule.isValid) // only show the tool tip on focus if any rule is not valid
    );
  };
  export const onSignInBlurPassword = (
    setSigninPasswordFocused,
    setToolTipVisible
  ) => {
    setSigninPasswordFocused(false);
    setToolTipVisible(false);
  };
  export const signinPasswordWatcher = (
    value,
    setSignInPasswordValue,
    currentContext,
    validatePasswordValue,
    setPasswordValidationRules,
    setToolTipVisible,
    signInPasswordError,
    setSignInPasswordError
  ) => {
    setSignInPasswordValue(value); // Update state
    const signInPasswordKey = getStorageKey(
      "signinPasswordValue",
      currentContext
    );
    localStorage.setItem(signInPasswordKey, value);
    const validationResults = validatePasswordValue(value);
    setPasswordValidationRules(validationResults);
    setToolTipVisible(!validationResults.every((rule) => rule.isValid)); // close the tool tip when all the rules are valid
    if (signInPasswordError) {
      setSignInPasswordError("");
    }
  };
  export const phoneInputWatcher = (
    value,
    setSignInPhoneNumberValue,
    currentContext,
    signInPhoneInputError,
    setSignInPhoneInputError
  ) => {
    setSignInPhoneNumberValue(value);
    const signinPhoneNumberKey = getStorageKey(
      "verifyAccountValue",
      currentContext
    );
    localStorage.setItem(signinPhoneNumberKey, value);
    if (signInPhoneInputError) {
      setSignInPhoneInputError("");
    }
  };
  