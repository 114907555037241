import React, { useState, useEffect, useRef } from "react";
import { debounce } from "../../../utils/searchDebounceUtils";

import "./unlockSlideBtn.scss";

function UnlockSlideBtn({
  slideBtnText,
  className,
  onHandleSlideEnd,
  style,
  slideBtnWidth,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  sliBtnFontColor,
  slidBtnFontWeight,
  slideBtnheight,
  slideBtnLeft,
  right,
  slideBtTop,
  lockIconColor = "#964B00", 
  unlockIconColor = "#964B00", 
  currentContext, // New prop to determine the context that is defined on the signup form for settings or signup
}) {
  const slideRef = useRef(null);
  const slideBtnRef = useRef(null);
  const debouncedHandleSlideEnd = debounce(onHandleSlideEnd, 300); // 300ms delay, adjust as necessary
  const [isLocked, setIsLocked] = useState(true);
  const [showLockIcon, setShowLockIcon] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowLockIcon((prev) => !prev); // Toggle the icon visibility
    }, 1000); // Change 3000 to the desired interval in milliseconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  function onSlide(ev) {
    ev.stopPropagation(); // Stop the event from propagating up
    if (!slideBtnRef.current) return; // Safety check

    const pointerX = ev.clientX;

    // see notes underneath the component for algebra calc break down
    const btnBounds = slideBtnRef.current.getBoundingClientRect();
    const slideBounds = slideRef.current.getBoundingClientRect();

    const pos = pointerX - btnBounds.x - btnBounds.width / 2;
    const minPos = 0;
    const maxPos =
      slideBounds.width - btnBounds.width - slideBounds.width * 0.04;
      const halfWayPos = maxPos / 2;

    slideBtnRef.current.style.left = clamp(minPos, pos, maxPos) + "px";

    // the lower the tolerance number the easier it is to unlock
    // The slider won't need to be dragged all the way to the actual end (290px) to trigger the debouncedHandleSlideEnd() function.
    // before we were using the tolerance level by subtracting it, but I removed it and we did the half way approach to allow
    // users to submit easy since other devices were not getting the singal to call submit for various reasons
    // if others are performing the same then update the slider with this below asa you can see tolerance is removed
    // but for memory i am leaving it as a marker to update other sliders in this code block if necassary
    const tolerance = 0;
     // Check if the slider is at least halfway
     if (pos >= halfWayPos) {
      // Call onHandleSlideEnd with the event object
      if (onHandleSlideEnd) {
        onHandleSlideEnd(ev, currentContext);
        debouncedHandleSlideEnd();
      }

      const isUnlockedPosition = pos >= halfWayPos;
      setIsLocked(!isUnlockedPosition);
    }
  }
  function onSlideBtnPointerDown(ev) {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up

    if (slideBtnRef.current) {
      slideBtnRef.current.setPointerCapture(ev.pointerId);
      slideBtnRef.current.addEventListener("pointermove", onSlide);
    }
  }

  function onSlideBtnPointerUp(ev) {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    if (slideBtnRef.current) {
      slideBtnRef.current.releasePointerCapture(ev.pointerId);
      slideBtnRef.current.removeEventListener("pointermove", onSlide);
      // Animating the slider button back to its starting position
      const animation = slideBtnRef.current.animate(
        { left: "0" },
        { duration: 200 }
      );

      animation.finished.then(() => {
        if (slideBtnRef.current) {
          slideBtnRef.current.style.left = "0";
        }
      });
    }
  }

  function clamp(min, val, max) {
    return Math.max(min, Math.min(val, max));
  }
  // The logic for starting the slide gesture
  const onSlideStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Add mousemove and touchmove event listeners
    document.addEventListener("mousemove", onSlide);
    document.addEventListener("touchmove", onSlide);
  };
  // The logic for ending the slide gesture
  const onSlideEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Remove mousemove and touchmove event listeners
    document.removeEventListener("mousemove", onSlide);
    document.removeEventListener("touchmove", onSlide);
  };
  // Attach mouse and touch event handlers to the slider button
  useEffect(() => {
    const slideButton = slideBtnRef.current;

    const options = { passive: false }; // Options object

    slideButton.addEventListener("pointerdown", onSlideBtnPointerDown, options);
    slideButton.addEventListener("pointerup", onSlideBtnPointerUp, options);
    slideButton.addEventListener("touchstart", onSlideStart, options);
    slideButton.addEventListener("touchend", onSlideEnd, options);

    return () => {
      slideButton.removeEventListener(
        "pointerdown",
        onSlideBtnPointerDown,
        options
      );
      slideButton.removeEventListener(
        "pointerup",
        onSlideBtnPointerUp,
        options
      );
      slideButton.removeEventListener("touchstart", onSlideStart, options);
      slideButton.removeEventListener("touchend", onSlideEnd, options);
    };
  }, []);

  const UnlockIcon = ({
    width = "26.754",
    height = "35.277",
    fillColor = "#0000",
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.754 35.277"
      width={width}
      height={height}
    >
      <path
        id="unlock-icon"
        d="M.8,16.1h2.5V10.676A10.8,10.8,0,0,1,6.34,3.151h0a10.069,10.069,0,0,1,14.583,0h0a10.8,10.8,0,0,1,3.032,7.525v.253h-4.02v-.313a6.619,6.619,0,0,0-1.853-4.594h0a6.17,6.17,0,0,0-6.87-1.41,6.519,6.519,0,0,0-3.9,6V16.1H25.95a.82.82,0,0,1,.8.83V34.473a.82.82,0,0,1-.8.83H.8a.82.82,0,0,1-.8-.83V16.938a.847.847,0,0,1,.232-.593A.8.8,0,0,1,.8,16.1Zm12.869,9.6,1.274,6.07H11.453l1.027-6.15a2.451,2.451,0,0,1-1.609-2.768,2.359,2.359,0,0,1,4.69.259,2.432,2.432,0,0,1-1.887,2.575Z"
        transform="translate(0 -0.025)"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
  const LockIcon = ({
    width = "26.754", // Width to match UnlockIcon
    height = "35.277", // Height to match UnlockIcon
    fillColor = "#000", // Default fill color (adjust as needed)
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.15 37.521"
      width={width}
      height={height}
      fill={fillColor}
    >
      <g transform="translate(16.5 -24.499)">
        <g transform="translate(-16.5 24.499)">
          <path
            d="M51.705,37.52H74.445a1.706,1.706,0,0,0,1.705-1.705V16.486a1.706,1.706,0,0,0-1.705-1.705H72.739V9.664a9.664,9.664,0,1,0-19.329,0v5.116H51.705A1.706,1.706,0,0,0,50,16.486V35.815A1.706,1.706,0,0,0,51.705,37.52Zm13.075-10.8v2.276a1.705,1.705,0,1,1-3.411,0V26.717a2.842,2.842,0,1,1,3.411,0ZM56.822,9.664a6.253,6.253,0,0,1,12.507,0v5.116H56.822Z"
            transform="translate(-50)"
          />
        </g>
      </g>
    </svg>
  );
  return (
    <div
      ref={slideRef}
      className={`unlock-slide ${className}`}
      style={{
        width: slideBtnWidth,
        height: slideBtnheight,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        right:right,
        top:slideBtTop,
        left:slideBtnLeft,
        ...style,
      }}
    >
      <div
        ref={slideBtnRef}
        className="unlockSlidebtn"
        onPointerDown={onSlideBtnPointerDown} // Attached onPointerDown event handler
        onPointerUp={onSlideBtnPointerUp} // Attached onPointerUp event handler
      >
        {showLockIcon ? (
          <LockIcon fillColor={lockIconColor} />
        ) : (
          <UnlockIcon fillColor={unlockIconColor} />
        )}
      </div>
      <div
        className="unlockLabel"
        style={{
          color: sliBtnFontColor,
          fontWeight: slidBtnFontWeight,
        }}
      >
        {slideBtnText}
      </div>
    </div>
  );
}

export default UnlockSlideBtn;
// Calculating pos (Position of the Slider Button):

// pointerX: This is the X-coordinate of the mouse pointer or touch point.
// btnBounds.x: The X-coordinate of the left side of the slider button.
// btnBounds.width / 2: Half the width of the slider button.
// The formula pointerX - btnBounds.x - btnBounds.width / 2 calculates the new position of the slider button relative to the slider track (slideRef). It adjusts for the width of the button to ensure the button's center, not its edge, follows the pointer.
// Defining minPos (Minimum Position):

// const minPos = 0;: This sets the minimum position of the slider button to 0, meaning the button can't go beyond the start of the slider track.
// Calculating maxPos (Maximum Position):

// slideBounds.width: The total width of the slider track.
// btnBounds.width: The width of the slider button.
// slideBounds.width * 0.04: This is 4% of the slider track's width, subtracted from the total available sliding distance. It's a slight adjustment to ensure the button doesn't overlap or go beyond the end of the slider track.
// The formula slideBounds.width - btnBounds.width - slideBounds.width * 0.04 calculates the farthest position to the right that the slider button can move without going off the track.
