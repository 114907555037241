import { useState, useEffect, useContext, useRef } from "react";
import { PaperPlane } from "../../components/icons/index";
import { WhiteBtn } from "../buttons";
// Ensure that the path to messageContext.js is correct
import { MessageContext } from "../../context/messageContext";
import Message from "./message";
import { PlainBtn } from "../buttons/index";
// import { useMessages } from "../../context/messageContext";
import "./chatBox.scss";

// onClosechat  is passed from the profile to here state is set on the profile with the close chatbox function and passed down to onCloseChatBox and passed up and in this scope and down to the click
const ChatBox = ({ onCloseChat }) => {
  const containerRef = useRef(null);
  const textareaRef = useRef(null);
  const [showDialogEmoji, setShowDialogEmoji] = useState(false);
  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [replyToId, setReplyToId] = useState(null); // State to track which message we're replying to
  // In ChatBox component
  const {
    messages,
    sendMessage,
    deleteMessage,
    deleteThreadMessages,
    currentUser, // Get the current user from context
    setCurrentThreadId, // Function to set current thread ID (if needed)
    currentThreadId, // from the context
  } = useContext(MessageContext); // actually all from the context provider
  const currentUserId = currentUser?.id;
  const messageInputRef = useRef(null); // Ref for the message input
  const [emoji] = useState({
    "😍": {
      name: "HeartEyes-01",
    },
    "🙂": {
      name: "SlightlySmiling-02",
    },
    "😡": {
      name: "Angry-03",
    },
    "😭": {
      name: "Crying-04",
    },
    "🤬": {
      name: "Cursing-05",
    },
    "🤨": {
      name: "RaisedEyebrow-06",
    },
    "🥰": {
      name: "SmilingFaceWithHearts-07",
    },
    "😇": {
      name: "Angel-08",
    },
    "😎": {
      name: "Cool-09",
    },
    "🙃": {
      name: "UpsideDown-10",
    },
    "😶": {
      name: "FaceWithoutMouth-11",
    },
    "😄": {
      name: "GrinningFaceWithSmilingEyes-12",
    },
    "😬": {
      name: "Grimacing-13",
    },
    "😮": {
      name: "OpenMouth-14",
    },
    "😂": {
      name: "TearsOfJoy-15",
    },
    "😘": {
      name: "FaceBlowingaKiss-77",
    },
    "😗": {
      name: "KissingFace-78",
    },
    "🤐": {
      name: "ZipperMouthFace-79",
    },
    "😵": {
      name: "DizzyFace-80",
    },
    "😷": {
      name: "FaceWithMedicalMask-81",
    },
    "🤫": {
      name: "ShushingFace-82",
    },
    "😖": {
      name: "ConfoundedFace-83",
    },
    "🥸": {
      name: "DisguisedFace-84",
    },
    "🤗": {
      name: "HuggingFace-85",
    },
    "😦": {
      name: "FrowningFaceWithOpenMouth-86",
    },
    "😉": {
      name: "WinkingFace-87",
    },
    "😰": {
      name: "AnxiousFaceWithSweat-88",
    },
    "😫": {
      name: "TiredFace-89",
    },
    "🕶": {
      name: "Sunglasses-91",
    },
    "🤣": {
      name: "RollingOnTheFloorLaughing-92",
    },
    "😁": {
      name: "BeamingFaceWithSmilingEyes-35",
    },
    "🥹": {
      name: "Pleading-36",
    },
    "🤑": {
      name: "MoneyMouthFace-37",
    },
    "😳": {
      name: "Flushed-38",
    },
    "🥷": {
      name: "Ninja-39",
    },
    "🤢": {
      name: "Nauseated-40",
    },
    "🎧": {
      name: "Headphone-41",
    },
    "☠": {
      name: "SkullAndCrossbones-42",
    },
    "😴": {
      name: "Sleeping-43",
    },
    "🤩": {
      name: "StarStruck-44",
    },
    "👑": {
      name: "Crown-45",
    },
    "😱": {
      name: "ScreamingInFear-46",
    },
    "🤔": {
      name: "Thinking-47",
    },
    "🤒": {
      name: "FaceWithThermometer-48",
    },
    "🥱": {
      name: "Yawning-49",
    },
    "👻": {
      name: "Ghost-50",
    },
    "👨🏻‍🦱": {
      name: "ManCurlyHair-51",
    },
    "👨": {
      name: "Man-52",
    },
    "🌝": {
      name: "FullMoonFace-53",
    },
    "😽": {
      name: "KissingCat-54",
    },
    "🙋‍♂️": {
      name: "ManRaisingHand-55",
    },
    "💁‍♂️": {
      name: "ManTippingHand-56",
    },
    "🤷‍♂️": {
      name: "ManShrugging-57",
    },
    "🤦‍♂️": {
      name: "ManFacepalming-58",
    },
    "😲": {
      name: "Astonished-60",
    },
    "😋": {
      name: "FaceSavoringFood-61",
    },
    "🤯": {
      name: "MindBlown-62",
    },
    "🥳": {
      name: "Partying-63",
    },
    "😬": {
      name: "GrimacingFace-95",
    },
    "😜": {
      name: "WinkingTongueOut-65",
    },
    "😛": {
      name: "TongueOut-67",
    },
    "😝": {
      name: "TongueOutEyesClosed-66",
    },
    "🤪": {
      name: "Zany-68",
    },
    "🤥": {
      name: "LyingFace-69",
    },
    "🙌": {
      name: "RaisingHands-70",
    },
    "🤷": {
      name: "PersonShrugging-71",
    },
    "🤦": {
      name: "PersonFacepalming-72",
    },
    "🥸": {
      name: "DisguisedFace-73",
    },
    "🤷‍♀️": {
      name: "WomanShrugging-74",
    },
    "🤦‍♀️": {
      name: "WomanFacepalming-75",
    },
    "🙋‍♀️": {
      name: "WomanRaisingHand-76",
    },
    "💁‍♀️": {
      name: "WomanTippingHand-77",
    },
    "😸": {
      name: "GrinningCat-78",
    },
    "😏": {
      name: "Smirking-79",
    },
    "😌": {
      name: "Relieved-80",
    },
    "😔": {
      name: "Sad-01",
    },
    "😞": {
      name: "Sad-02",
    },
    "😥": {
      name: "Sad-03",
    },
    "😢": {
      name: "Sad-04",
    },
    "😭": {
      name: "Sad-05",
    },
    "😓": {
      name: "Sad-06",
    },
    "😫": {
      name: "Sad-07",
    },
    "😩": {
      name: "Sad-08",
    },
    "🥺": {
      name: "Sad-09",
    },
    "😪": {
      name: "Sad-10",
    },
    "☹️": {
      name: "Sad-11",
    },
    "😖": {
      name: "Sad-12",
    },
    "😟": {
      name: "Sad-13",
    },
    "🙁": {
      name: "Sad-14",
    },
    "😠": {
      name: "Angry-01",
    },
    "😡": {
      name: "Angry-02",
    },
    "🤬": {
      name: "Angry-03",
    },
    "😤": {
      name: "Angry-04",
    },
    "😾": {
      name: "Angry-05",
    },
    "😿": {
      name: "Angry-06",
    },
    "🙎‍♂️": {
      name: "Angry-07",
    },
    "🙎‍♀️": {
      name: "Angry-08",
    },
    "😕": {
      name: "Other-01",
    },
    "🥴": {
      name: "Other-02",
    },
    "😰": {
      name: "Other-03",
    },
    "😨": {
      name: "Other-04",
    },
    "😧": {
      name: "Other-05",
    },
    "🥵": {
      name: "Other-06",
    },
    "🥶": {
      name: "Other-07",
    },
    "😅": {
      name: "GrinningFaceWithSweat-97",
    },
    "🤦🏻‍♀️": {
      name: "WomanFacepalmingLightSkinTone-78",
    },
    "🤦🏼‍♀️": {
      name: "WomanFacepalmingMediumLightSkinTone-79",
    },
    "🤦🏽‍♀️": {
      name: "WomanFacepalmingMediumSkinTone-80",
    },
    "🤦🏾‍♀️": {
      name: "WomanFacepalmingMediumDarkSkinTone-81",
    },
    "🤦🏿‍♀️": {
      name: "WomanFacepalmingDarkSkinTone-82",
    },
    "🤦🏻‍♂️": {
      name: "ManFacepalmingLightSkinTone-83",
    },
    "🤦🏼‍♂️": {
      name: "ManFacepalmingMediumLightSkinTone-84",
    },
    "🤦🏽‍♂️": {
      name: "ManFacepalmingMediumSkinTone-85",
    },
    "🤦🏾‍♂️": {
      name: "ManFacepalmingMediumDarkSkinTone-86",
    },
    "🤦🏿‍♂️": {
      name: "ManFacepalmingDarkSkinTone-87",
    },
    "🙋🏻‍♀️": {
      name: "WomanRaisingHandLightSkinTone-88",
    },
    "🙋🏼‍♀️": {
      name: "WomanRaisingHandMediumLightSkinTone-89",
    },
    "🙋🏽‍♀️": {
      name: "WomanRaisingHandMediumSkinTone-90",
    },
    "🙋🏾‍♀️": {
      name: "WomanRaisingHandMediumDarkSkinTone-91",
    },
    "🙋🏿‍♀️": {
      name: "WomanRaisingHandDarkSkinTone-92",
    },
    "🙋🏻‍♂️": {
      name: "ManRaisingHandLightSkinTone-93",
    },
    "🙋🏼‍♂️": {
      name: "ManRaisingHandMediumLightSkinTone-94",
    },
    "🙋🏽‍♂️": {
      name: "ManRaisingHandMediumSkinTone-95",
    },
    "🙋🏾‍♂️": {
      name: "ManRaisingHandMediumDarkSkinTone-96",
    },
    "🙋🏿‍♂️": {
      name: "ManRaisingHandDarkSkinTone-97",
    },
    "💁🏻‍♀️": {
      name: "WomanTippingHandLightSkinTone-98",
    },
    "💁🏼‍♀️": {
      name: "WomanTippingHandMediumLightSkinTone-99",
    },
    "💁🏽‍♀️": {
      name: "WomanTippingHandMediumSkinTone-100",
    },
    "💁🏾‍♀️": {
      name: "WomanTippingHandMediumDarkSkinTone-101",
    },
    "💁🏿‍♀️": {
      name: "WomanTippingHandDarkSkinTone-102",
    },
    "💁🏻‍♂️": {
      name: "ManTippingHandLightSkinTone-103",
    },
    "💁🏼‍♂️": {
      name: "ManTippingHandMediumLightSkinTone-104",
    },
    "💁🏽‍♂️": {
      name: "ManTippingHandMediumSkinTone-105",
    },
    "💁🏾‍♂️": {
      name: "ManTippingHandMediumDarkSkinTone-106",
    },
    "💁🏿‍♂️": {
      name: "ManTippingHandDarkSkinTone-107",
    },
  });


  const handleSend = () => {
    if (message.trim()) {
      // Send the actual message
      sendMessage(message, currentUserId, currentThreadId, replyToId);
      // Reset states after sending the message
      setMessage("");
      setReplyToId(null);
      // Simulate a mock reply for styling purposes after a delay
      setTimeout(() => {
        sendMessage(
          "This is a mock reply.",
          "other-user",
          currentThreadId,
          null
        );
      }, 1000);
    }
  };
  const handleDeleteMessage = (messageId) => {
    deleteMessage(messageId);
  };
  const handleDeleteThread = (threadId) => {
    deleteThreadMessages(threadId);
  };
  const handleReply = (messageId) => {
    setReplyToId(messageId);
    // Logic to focus on the message input for replying
  };
  const handleEmojiSelect = (emojiChar) => {
    setMessage((prevState) => prevState + emojiChar);
    setShowDialogEmoji(false);
  };
  const toggleDialogPopup = () => {
    setShowDialogEmoji((prevState) => !prevState);
  };
  // Scroll to the bottom of the chat whenever messages update
  useEffect(() => {
    const messagesContainer = document.querySelector(".messages");
    if (messagesContainer) {
      // By adding the if (messagesContainer) check, you ensure that the code trying to access .scrollHeight only runs if the messagesContainer is not null. container is supposed to be present this safe gaurds when things render not in correct order or at the same time
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  const adjustHeights = () => {
    const textarea = textareaRef.current;
    const container = containerRef.current;
    if (textarea && container) {
      // Reset textarea height to shrink if content is removed
      textarea.style.height = "auto";
      // Set textarea height based on scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`;
      // Adjust the container's height to match the textarea
      container.style.height = `${textarea.scrollHeight}px`;
      // grow from bottom will allow the container to grow from bottom up, this will be used on the groups detail page
      // state is set up top and it will be set to true on the child
    }
  };
  // Call adjustHeights whenever the message state changes
  useEffect(() => {
    adjustHeights();
  }, [message]);


  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="sp-messages popup-width popup-width-center">
        <div className="inbox-section">
          <button
            onClick={onCloseChat}
            type="button"
            className="globalized-btn clear-btn"
          >
            X Close Chat
          </button>
          <div className="chatFocus"></div> {/* This is the backdrop */}
          <div className="chat-container messages">
            {/* Combined classes for thumb scrolling to appear correctly */}
            {messages.map((message) => (
              <div className="message-block">
                <div className="message-content-wrapper">
                  <Message
                    key={message.id}
                    msg={message}
                    onReply={handleReply}
                    currentUserId={currentUserId}
                    onDeleteMessage={handleDeleteMessage}
                    onDeleteThread={handleDeleteThread}
                  />
                  <div className="message-actions">
                    {/* buttons for delete, reply, etc. */}
                  </div>
                  <div className="timestamp">
                    {/* Render the timestamp here */}
                    {message.timestamp}
                  </div>
                </div>
              </div>
            ))}
            <div
              className="input-container"
              ref={containerRef} // Attach the ref to the input
            >
              <textarea
                placeholder="Type your message..."
                value={message}
                ref={textareaRef}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className="message-input" // Add a class for styling the input
              />
              {!isFocused && message === "" && (
                <span className="blinking-cursor blinking">|</span>
              )}

              <div className="beneathSend">
              <PlainBtn
                plainBtnText="Express"
                onClick={() => setShowDialogEmoji((prev) => !prev)}
                image={
                  <span role="img" aria-label="heart" className="pulsing-heart">
                    ❤️
                  </span>
                }
                imageOnTop={false} // Adjust as needed
                extraStyles={{
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
                plainBtnHeight="40px"
                plainBtnFontFamily="Roboto"
                plainBtnFontWeight="bold"
                plainBtnFontColor="#964b00"
                plainBtnBorderRadius="5px"
              />
              {/* Emoji Selector Button */}
              {showDialogEmoji && (
                <div className="emoji-selector">
                  {Object.keys(emoji).map((emojiChar) => (
                    <button
                      key={emoji[emojiChar].name}
                      onClick={() => handleEmojiSelect(emojiChar)}
                      className="emoji-btn"
                    >
                      {emojiChar}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="inbox-type-btns">
              <WhiteBtn
                onClick={handleSend} // Changed to handleSend which sends the message
                whiteBtnText="Send Message"
                whiteBtnImage={<PaperPlane fillColor="#e2b645" />}
                whiteBtnfontSize="1.3rem"
                whiteBtnFontColor="#e2b645"
                whiteBtnBorderColor="#e2b645"
                whiteBtnBorderWidth="YourBorderWidth"
              />
            </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default ChatBox;

// const newMessage = {
//   id: Date.now(), // This should eventually be replaced with IDs from the backend
//   threadId: ThreadId, // This will be assigned when creating a message in a thread
//   replyToId: null, // This will be assigned when replying to a specific message
//   text: modifiedText,
//   timestamp: new Date().toLocaleTimeString(),
//   sender: sender, // Add the sender property. You can change this to 'other' to simulate the other user or me for reciever
// };

{
  /* <div className="messages">
              {messages.map((message) => (
                <div key={message.id} className={`message ${message.sender}`}>
                  <div
                    className="message-content"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  ></div>
                  <div className="flex-column">
                    <span className="timestamp">{message.timestamp}</span>
                    <div className="message-actions">
                      <button className="delete-btn">Delete</button>
                      <button className="reply-btn">Reply</button>
                    </div>
                  </div>
                </div>
              ))}
            </div> */
}

// In the context of a React component, if you're only returning JSX without needing any additional logic, you can use parentheses:

//             Curly Braces {} for a Function Block:
// If you need to perform more actions, like variable declarations or if statements, you use curly braces. This defines a function "block body." You'll need to write a return statement to return something from the function:

// Use parentheses () when you have a single expression that you want to return.
// Use curly braces {} when you have more complex logic that requires multiple statements, and use return to return the final result.
