import { useLocation } from "react-router-dom";
// import { slide as BurgerMenu } from "react-burger-menu";
import { SiteLogoSmall } from "../../icons";
import { useMediaQuery } from "../../../hooks";
import NavLinks from "./navLinks";

import "./siteHeader.scss";

export default function SiteHeader() {
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width: 812px)");


  if (location.pathname === "/") return null;

  return (
    <div id="site-header">
      <h1 id="site-name-logo">
        Meet on up
        <SiteLogoSmall />
      </h1>
      <nav>
        <NavLinks />
      </nav>
      {!isDesktop && (
        <div className="footer-nav">
          <NavLinks />
        </div>
      )}
    </div>
  );
}
