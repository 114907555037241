import React, { useState, useEffect } from "react";
import { GlassBtn, ImgTopBtn } from "../../buttons/index";
import "./picVidUpload.scss";

function PicVidUploadForm({ onClose }) {
  const [file, setFile] = useState(null);
  const [fileUploadError, setFileUploadError] = useState("");
  const [profilePicUploadAttempted, setProfilePicUploadAttempted] =
    useState(false);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]); // Update the file state
      setFileUploadError(""); // Clear any existing error
    }
  };

  const onUploadProfilePic = async () => {
    setProfilePicUploadAttempted(true);
  };
  useEffect(() => {
    if (profilePicUploadAttempted) {
      if (!file) {
        setFileUploadError("Profile picture is required.");
      } else {
        console.log("File uploaded:", file);
        // Clear the file state if needed, etc.
        setProfilePicUploadAttempted(false);
      }
    }
  }, [profilePicUploadAttempted, file]);

  const handleFileUploadError = () => {
    if (!file) {
      setFileUploadError("Profile picture is required.");
    } else {
      // Handle the submission process here
      console.log("File uploaded:", file);
      // Clear the file state if needed, etc.
      setProfilePicUploadAttempted(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    // If there are other states associated with this form in the future, reset them here.
    // e.g., setDescription(""), example if a description state is added.
  };

  return (
    <div className="pic-upload-form-wrapper">
      <form>
        <ImgTopBtn
          id="cancel-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnText=" X Cancel"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="#964b00"
          paddingRight="5px"
          paddingLeft="5px"
          marginLeft="50%"
          onClick={() => {
            resetForm();
            onClose();
          }}
        />

        {/* File Input and Label/Button combination */}
        <label className="file-upload-btn" htmlFor="file-upload">
          Click here and choose a file
          <input id="file-upload" type="file" onChange={handleFileChange} />
        </label>
        {fileUploadError && (
          <div className="error-message">{fileUploadError}</div>
        )}
        <GlassBtn
          glassBtnImage={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="23.7"
              viewBox="0 0 30 23.7"
            >
              <g
                id="Group_17"
                data-name="Group 17"
                transform="translate(16851 16085)"
              >
                <g id="Camera-03" transform="translate(-16851 -16085)">
                  <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M30,22.5a1.272,1.272,0,0,1-1.4,1.2H1.4A1.272,1.272,0,0,1,0,22.5V2.5A1.272,1.272,0,0,1,1.4,1.3H28.5a1.272,1.272,0,0,1,1.4,1.2v20Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M2.5,1.8V.3A.319.319,0,0,1,2.8,0H6.2a.265.265,0,0,1,.3.3V1.8Z"
                  />
                  <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M17.4,4.8a.319.319,0,0,1-.3.3H12.8a.319.319,0,0,1-.3-.3V2a.319.319,0,0,1,.3-.3h4.3a.319.319,0,0,1,.3.3Z"
                  />
                  <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M16.9,4.2a.319.319,0,0,1-.3.3H13.4a.319.319,0,0,1-.3-.3V2.6a.319.319,0,0,1,.3-.3h3.2a.319.319,0,0,1,.3.3Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M15.9,3.7a.319.319,0,0,1-.3.3H14.5a.319.319,0,0,1-.3-.3V3.1a.319.319,0,0,1,.3-.3h1.1a.319.319,0,0,1,.3.3Z"
                  />
                  <g id="Group_13" data-name="Group 13">
                    <path
                      id="Path_35"
                      data-name="Path 35"
                      d="M28.1,3.1v2h-4v-2h4m.1-.4H24a.319.319,0,0,0-.3.3V5.2a.319.319,0,0,0,.3.3h4.2a.319.319,0,0,0,.3-.3V2.9c0-.1-.1-.2-.3-.2Z"
                    />
                  </g>
                  <rect
                    id="Rectangle_43"
                    data-name="Rectangle 43"
                    width="30"
                    height="15.6"
                    transform="translate(0 6.9)"
                    fill="#fdfd96"
                  />
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(2.4 9.2)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="0.6"
                    cy="0.6"
                    r="0.6"
                    transform="translate(2.8 9.6)"
                    fill="#d14527"
                  />
                  <g id="Group_16" data-name="Group 16">
                    <g id="Group_14" data-name="Group 14">
                      <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M8.7,19.2A8.042,8.042,0,0,1,10,8,7.951,7.951,0,0,1,21.2,9.3"
                        fill="#fff"
                      />
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M21.2,9.3A7.973,7.973,0,1,1,8.7,19.2"
                        fill="#fff"
                      />
                    </g>
                    <g id="Group_15" data-name="Group 15">
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M15,6.9A7.2,7.2,0,1,1,7.8,14,7.149,7.149,0,0,1,15,6.9m0-1.3a8.5,8.5,0,1,0,8.5,8.5A8.623,8.623,0,0,0,15,5.6Z"
                      />
                    </g>
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="4.4"
                      cy="4.4"
                      r="4.4"
                      transform="translate(10.9 9.7)"
                    />
                    <path
                      id="Path_39"
                      data-name="Path 39"
                      d="M.9,0A.9.9,0,1,1,0,.9.9.9,0,0,1,.9,0Z"
                      transform="translate(12.9 13.7)"
                      fill="#fff"
                    />
                    <circle
                      id="Ellipse_5"
                      data-name="Ellipse 5"
                      cx="0.8"
                      cy="0.8"
                      r="0.8"
                      transform="translate(14.5 15.1)"
                      fill="#fff"
                    />
                  </g>
                  <rect
                    id="Rectangle_44"
                    data-name="Rectangle 44"
                    width="3.7"
                    height="0.8"
                    transform="translate(24.3 3.2)"
                  />
                </g>
              </g>
            </svg>
          }
          //  passing the string "false" which is a truthy value.
          // therefore a string will not work because it needs to check the truthy value
          onClick={onUploadProfilePic}
          glassBtnFontFamily="roboto-slab"
          glassBtnFontWeight="800"
          glassBtnText="Submit"
          glassBtnFontColor="#FDFD96"
          glassBtnTextDecorationColor="#FDFD96"
          glassBtnBorderColor="964b00"
          glassBtnTextFontSize="1.3rem"
          glassBtnMarginTop="20px"
        ></GlassBtn>
      </form>
    </div>
  );
}
export default PicVidUploadForm;
