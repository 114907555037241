import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GlassBtn,
  SlideBtn,
  SolidColorButton,
  ImgTopBtn,
} from "../../components/buttons/index.jsx";
import { EventPopup } from "../../components/forms";
import {
  Xylophone,
  ClipBoardCheck,
  ThumbIndexOut,
  XIcon,
  ScrollList,
  Remote,
  FindUsername,
  EyeIcon,
  EyeSlashIcon,
  Back,
  Filter,
} from "../../components/icons/index.jsx";
import confetti from "canvas-confetti";
import "./eventDetailsPadMobileNav.scss";
const EventDetailsPadMobileNav = ({
  onShowGuestStatus,
  userAction,
  onInterested,
  onInvite  ,
  setActiveTab,
  activeTab,
  onGoing,
  guestAdminSectionRef,
  onHandleCancel,
  onResetFilters,
  eventSearchCriteriaValue,
  handleUserNameChange,
  setIsFocused,
  isFocused,
  setEventSearchCriteriaValue,
  setIsSearchEventInputEmpty,
  onDebouncedHandleSlideCompletion,
  isAnyButtonActive,
  isSliderSlid,
  showPartyStatus,
  onToggleParty,
  showAdminControls,
  onHandleShowAdminControls,
  onHandleCurrentStatusList,
  onToggleAdminControls,
  interestedRef,
  currentList,
  attendingRef,
  excludedRef,
  invitedRef,
  attendingCount,
  interestedCount,
  declinedRef,
  invitedCount,
  declinedCount,
  maybeRef,
  maybeCount,
  excludedCount,
  clearUserNameEventSearchCriteriaLocalStorage,
  userNameEventSearchError,
  show_RedBorder_Required,
}) => {
  const mbpdeventfilterNavContainerRef = useRef(null);
  const navigate = useNavigate();
  const showConfetti = () => {
    // Selector for the "Going" button
    const button = document.querySelector(".going-button");
    if (!button) return;
    // Check if button is visible
    const style = window.getComputedStyle(button);
    if (style.display === "none" || style.visibility === "hidden") {
      return; // Don't show confetti if button is hidden
    }
    const rect = button.getBoundingClientRect();
    const x = rect.left + rect.width / 2; // Center of the button in x-direction
    const y = rect.top + rect.height / 2; // Center of the button in y-direction

    const normalizedX = x / window.innerWidth;
    const normalizedY = y / window.innerHeight;

    confetti({
      particleCount: 150,
      spread: 60,
      origin: { x: normalizedX, y: normalizedY },
    });
  };
  showConfetti();
  const scrollToRef = (ref) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const positionToScrollTo = window.scrollY + rect.top;
      window.scrollTo({
        top: positionToScrollTo,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (currentList) {
      scrollToRef(mbpdeventfilterNavContainerRef);
    }
  }, [currentList, scrollToRef, mbpdeventfilterNavContainerRef]);

  return (
    <div id="event-details-pad-mobile-nav">
      <div
        className={`topNavEventsDetailsMbPad ${
          activeTab === "party" || activeTab === "adminControls"
            ? "hide-top-nav"
            : ""
        }`}
      >
        {activeTab === "Going" ? (
          <GlassBtn
            glassBtnImage={
              <XIcon fillColor="#964b00" height="15px" width="15px" />
            }
            onClick={() =>  setActiveTab(null)}
            glassBtnText="Cancel"
            glassBtnFontColor="#964b00"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnTextDecorationColor="#964b00"
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        ) : (
          <GlassBtn
            glassBtnImage={
              <ThumbIndexOut fillColor="#fdfd96" height="15px" width="15px" />
            }
            onClick={onGoing}
            className="going-button"
            glassBtnText="Going"
            glassBtnFontColor="#fdfd96"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnTextDecorationColor="#fdfd96"
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        )}
        {activeTab === "Interested" ? (
          <GlassBtn
            glassBtnImage={
              <XIcon fillColor="#964b00" height="15px" width="15px" />
            }
            onClick={() =>  setActiveTab(null)}
            glassBtnText="Cancel"
            glassBtnFontColor="#964b00"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnTextDecorationColor="#964b00"
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        ) : (
          <GlassBtn
            glassBtnImage={
              <ClipBoardCheck fillColor="#e2b645" height="15px" width="15px" />
            }
            onClick={onInterested}
            glassBtnText="Interested"
            glassBtnFontColor="#e2b645"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnTextDecorationColor="#e2b645"
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
        )}
        <GlassBtn
          glassBtnImage={
            <Xylophone
              height="15px"
              width="15px"
              fillColor={userAction === "invite" ? "#fff" : "#fdfd96"}
            />
          }
          onClick={onInvite}
          glassBtnText="Invite"
          glassBtnHeight="20px"
          glassBtnWidth="100px"
          glassBtnTextFontSize=".75rem"
          glassBtnFontWeight="900"
          glassBtnFontColor={userAction === "invite" ? "#fff" : "#fdfd96"}
          glassBtnTextDecorationColor={
            userAction === "invite" ? "#fff" : "#fdfd96"
          }
          hi2Style={{
            transform: "rotate(90deg) translate(-80px, 50px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate(-80px, -50px)",
          }}
        />
        <GlassBtn
          glassBtnImage={
            <ScrollList
              height="15px"
              width="15px"
              fillColor={activeTab === "guestStatus" ? "#000" : "#e2b645"}
            />
          }
          onClick={onShowGuestStatus}
          glassBtnText="Party"
          glassBtnHeight="20px"
          glassBtnWidth="100px"
          glassBtnTextFontSize=".75rem"
          glassBtnFontWeight="900"
          glassBtnFontColor={activeTab === "guestStatus" ? "#000" : "#e2b645"}
          glassBtnTextDecorationColor={
            activeTab === "guestStatus" ? "#000" : "#e2b645"
          }
          hi2Style={{
            transform: "rotate(90deg) translate(-80px, 50px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate(-80px, -50px)",
          }}
        />
        <GlassBtn
          glassBtnImage={
            <Remote
              height="15px"
              width="15px"
              fillColor={activeTab === "adminControls" ? "#000" : "#fdfd96"}
            />
          }
          onClick={onHandleShowAdminControls}
          glassBtnText="Admin"
          glassBtnHeight="20px"
          glassBtnWidth="100px"
          glassBtnTextFontSize=".75rem"
          glassBtnFontWeight="900"
          glassBtnFontColor={activeTab === "adminControls" ? "#000" : "#fdfd96"}
          glassBtnTextDecorationColor={
            activeTab === "adminControls" ? "#000" : "#fdfd96"
          }
          hi2Style={{
            transform: "rotate(90deg) translate(-80px, 50px)",
          }}
          hi3Style={{
            transform: "rotate(90deg) translate(-80px, -50px)",
          }}
        />
      </div>
      {(activeTab === "guestStatus" ||
        activeTab === "party" ||
        activeTab === "adminControls") && (
        <div
          className="mbpdeventfilterNavContainer stickyTop"
          ref={mbpdeventfilterNavContainerRef}
        >
          <div className="mb-pad-events-navtop" ref={guestAdminSectionRef}>
            <SolidColorButton
              solidColorBtnImage={
                <Back
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              className="gold-btn"
              solidColorBtnText="Back"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="100px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              onClick={() => navigate(-1)}
            />
            <SolidColorButton
              solidColorBtnImage={
                <XIcon
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              onClick={onHandleCancel}
              solidColorBtnText="Cancel/Close"
              className="gold-btn"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="100px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
            <SolidColorButton
              className="gold-btn"
              solidColorBtnImage={
                <Filter height="25px" width="25px" fillColor="#964b00" />
              }
              onClick={onResetFilters}
              solidColorBtnText="Reset Filters"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnWidth="100px"
              solidColorBtnFontColor="#964b00"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
          </div>
          <div id="event-mp-pad-search-bar-wrapper">
            <div className="connections-input-slider-container">
              <div className="connections-input-container blinker-wrapper">
                <input
                  value={eventSearchCriteriaValue}
                  placeholder={
                    userNameEventSearchError
                      ? userNameEventSearchError
                      : "Enter username"
                  }
                  className={userNameEventSearchError ? "required-message" : ""}
                  type="text"
                  onChange={(e) =>
                    handleUserNameChange(e.target.value, "userNameValue")
                  }
                  onFocus={() => {
                    setIsFocused(true);
                    setEventSearchCriteriaValue("");
                    setIsSearchEventInputEmpty(false);
                  }}
                />
                {eventSearchCriteriaValue && (
                  <button
                    className="Clear-eventDetailsPgBtn"
                    onClick={clearUserNameEventSearchCriteriaLocalStorage}
                    style={{
                      color: "#fdfd96",
                      backgroundColor: "transparent",
                      borderColor: "#fdfd96",
                    }}
                  >
                    Clear
                  </button>
                )}
                {!isFocused && eventSearchCriteriaValue === "" && (
                  <span className="blinking-cursor blinking">|</span>
                )}
              </div>
              <SlideBtn
                sliderImage={() => <FindUsername fillColor="#964B00" />}
                slideBtnText="Slide to find username"
                slideBtnWidth="250px"
                slidBtnFontSize="1rem"
                marginBottom="2px"
                onHandleSlideEnd={onDebouncedHandleSlideCompletion}
              />
            </div>
          </div>
          <div className="guest-list">
            <div
              className={`btnsContain ${
                show_RedBorder_Required && !isAnyButtonActive() && isSliderSlid
                  ? "red-border"
                  : ""
              }`}
            >
              {show_RedBorder_Required && (
                <span className="error-message">
                  {userNameEventSearchError}
                </span>
              )}
              <div className="show-hide-control-buttons">
                {activeTab === "adminControls" && (
                  <>
                    <SolidColorButton
                      solidColorBtnImage={
                        showPartyStatus ? (
                          <EyeSlashIcon
                            height="15px"
                            width="15px"
                            fillColor="#964b00"
                            strokeWidth="4"
                            strokeColor="#964b00"
                          />
                        ) : (
                          <EyeIcon
                            height="15px"
                            width="15px"
                            fillColor="#964b00"
                            strokeWidth="4"
                            strokeColor="#964b00"
                          />
                        )
                      }
                      className="gold-btn"
                      solidColorBtnText={
                        showPartyStatus ? "Hide Party" : "Show Party"
                      } // Text based on showPartyStatus state
                      solidColorBtnFontWeight="900"
                      solidColorBtnFontFamily="helvetica"
                      solidColorBtnTextFontSize=".75rem"
                      solidColorBtnFontColor="#964b00"
                      solidColorBtnWidth="100px"
                      solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                      onClick={onToggleParty}
                    />
                    <SolidColorButton
                      solidColorBtnImage={
                        showAdminControls ? (
                          <EyeSlashIcon
                            height="15px"
                            width="15px"
                            fillColor="#964b00"
                            strokeWidth="4"
                            strokeColor="#964b00"
                          />
                        ) : (
                          <EyeIcon
                            height="15px"
                            width="15px"
                            fillColor="#964b00"
                            strokeWidth="4"
                            strokeColor="#964b00"
                          />
                        )
                      }
                      className="gold-btn"
                      solidColorBtnText={
                        showAdminControls ? "Hide Admin" : "Show Admin"
                      } // Text based on showPartyStatus state
                      solidColorBtnFontWeight="900"
                      solidColorBtnFontFamily="helvetica"
                      solidColorBtnTextFontSize=".75rem"
                      solidColorBtnWidth="100px"
                      solidColorBtnFontColor="#964b00"
                      solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                      onClick={onToggleAdminControls}
                    />
                  </>
                )}
              </div>
              {/* <h3 className="partyListHeader">Guests: show guest show admin controls</h3> */}
              <div className="actionBtnEventsDetailsMbPad">
                {showPartyStatus && (
                  <>
                    <GlassBtn
                      onClick={() => {
                        onHandleCurrentStatusList("interested");
                        scrollToRef(mbpdeventfilterNavContainerRef);
                      }}
                      glassBtnImage={
                        <span
                          ref={interestedRef}
                          className={`interestedNum ${
                            currentList === "interested"
                              ? "interestedNum-active"
                              : "interestedNum-inactive"
                          }`}
                        >
                          {interestedCount}
                        </span>
                      }
                      glassBtnText={
                        currentList === "interested"
                          ? "Interested List"
                          : "Show Interested"
                      }
                      glassBtnFontColor={
                        currentList === "interested" ? "#fff" : "#e2b645"
                      }
                      glassBtnTextDecorationColor={
                        currentList === "interested" ? "#fff" : "#e2b645"
                      }
                      imageOnTop={true}
                      glassBtnTextFontSize=".75rem"
                      glassBtnFontWeight="800"
                      glassBtnHeight="20px"
                      glassBtnWidth="100px"
                      glassHoverStyle={{
                        height: currentList === "interested" ? "18px" : "20px",
                      }}
                      hi2Style={{
                        transform: "rotate(90deg) translate(-80px, 50px)",
                      }}
                      hi3Style={{
                        transform: "rotate(90deg) translate(-80px, -50px)",
                      }}
                    />
                    <GlassBtn
                      onClick={() => {
                        onHandleCurrentStatusList("attending");
                        scrollToRef(mbpdeventfilterNavContainerRef);
                      }}
                      glassBtnImage={
                        <span
                          ref={attendingRef}
                          className={`attendingNum ${
                            currentList === "attending"
                              ? "attendingNum-active"
                              : "attendingNum-inactive"
                          }`}
                        >
                          {attendingCount}
                        </span>
                      }
                      glassBtnText={
                        currentList === "attending"
                          ? "Attending List"
                          : "Show Attending"
                      }
                      glassBtnFontColor={
                        currentList === "attending" ? "#fff" : "#fdfd96"
                      }
                      glassBtnTextDecorationColor={
                        currentList === "attending" ? "#fff" : "#fdfd96"
                      }
                      imageOnTop={true}
                      glassBtnFontWeight="800"
                      glassBtnTextFontSize=".75rem"
                      glassBtnHeight="20px"
                      glassBtnWidth="100px"
                      glassHoverStyle={{
                        height: currentList === "attending" ? "18px" : "20px",
                      }}
                      hi2Style={{
                        transform: "rotate(90deg) translate(-80px, 50px)",
                      }}
                      hi3Style={{
                        transform: "rotate(90deg) translate(-80px, -50px)",
                      }}
                    />
                  </>
                )}
              </div>
              {activeTab === "adminControls" && (
                <div className="admin-ctrls">
                  <div className="actionBtns">
                    {showAdminControls && (
                      <>
                        <ImgTopBtn
                          onClick={() => {
                            onHandleCurrentStatusList("invited");
                            scrollToRef(mbpdeventfilterNavContainerRef);
                          }}
                          image={
                            <span
                              ref={invitedRef}
                              className={`invitedNum ${
                                currentList === "invited"
                                  ? "invitedNum-active"
                                  : "invitedNum-inactive"
                              }`}
                            >
                              {invitedCount}
                            </span>
                          }
                          imageOnTop={true}
                          imgTopBtnText={
                            currentList === "invited"
                              ? "Invited List"
                              : "Show Invited List"
                          }
                          imgTopBtnFontColor={
                            currentList === "invited" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextDecorationColor={
                            currentList === "invited" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextFontSize=".75rem"
                          imgTopBtnWidth="fit-content"
                          imgTopBtnFontWeight="800"
                          imgTopBtnHeight="fit-content"
                          paddingLeft="10px"
                          paddingRight="10px"
                        />
                        <ImgTopBtn
                          onClick={() => {
                            onHandleCurrentStatusList("declined");
                            scrollToRef(mbpdeventfilterNavContainerRef);
                          }}
                          image={
                            <span
                              ref={declinedRef}
                              className={`declinedNum ${
                                currentList === "declined"
                                  ? "declinedNum-active"
                                  : "declinedNum-inactive"
                              }`}
                            >
                              {declinedCount}
                            </span>
                          }
                          imageOnTop={true}
                          imgTopBtnText={
                            currentList === "declined"
                              ? "Declined List"
                              : "Show Declined List"
                          }
                          imgTopBtnFontColor={
                            currentList === "declined" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextDecorationColor={
                            currentList === "declined" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextFontSize=".75rem"
                          imgTopBtnFontWeight="800"
                          imgTopBtnHeight="fit-content"
                          imgTopBtnWidth="fit-content"
                          paddingLeft="10px"
                          paddingRight="10px"
                        />
                        <ImgTopBtn
                          onClick={() => {
                            onHandleCurrentStatusList("maybe");
                            scrollToRef(mbpdeventfilterNavContainerRef);
                          }}
                          image={
                            <span
                              ref={maybeRef}
                              className={`maybeNum ${
                                currentList === "maybe"
                                  ? "maybeNum-active"
                                  : "maybeNum-inactive"
                              }`}
                            >
                              {maybeCount}
                            </span>
                          }
                          imageOnTop={true}
                          imgTopBtnText={
                            currentList === "maybe"
                              ? "Maybe List"
                              : "Show Maybe List"
                          }
                          imgTopBtnFontColor={
                            currentList === "maybe" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextDecorationColor={
                            currentList === "maybe" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextFontSize=".75rem"
                          imgTopBtnFontWeight="800"
                          imgTopBtnHeight="fit-content"
                          imgTopBtnWidth="fit-content"
                          paddingLeft="10px"
                          paddingRight="10px"
                        />
                        <ImgTopBtn
                          onClick={() => onHandleCurrentStatusList("excluded")}
                          image={
                            <span
                              ref={excludedRef}
                              className={`excludedNum ${
                                currentList === "excluded"
                                  ? "excludedNum-active"
                                  : "excludedNum-inactive"
                              }`}
                            >
                              {excludedCount}
                            </span>
                          }
                          imageOnTop={true}
                          imgTopBtnText={
                            currentList === "excluded"
                              ? "Excluded List"
                              : "Show Excluded List"
                          }
                          imgTopBtnFontColor={
                            currentList === "excluded" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextDecorationColor={
                            currentList === "excluded" ? "#000" : "#964b00"
                          }
                          imgTopBtnTextFontSize=".75rem"
                          imgTopBtnFontWeight="800"
                          imgTopBtnHeight="fit-content"
                          imgTopBtnWidth="fit-content"
                          paddingLeft="10px"
                          paddingRight="10px"
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EventDetailsPadMobileNav;
