import React from "react";
import { useNavigate } from "react-router-dom";
import ConnectionBtn from "../buttons/connectionBtn/connectionBtn";
import "./connects.scss";

const Connections = ({
  userId,
  username,
  firstName,
  lastName,
  profileThumbnail,
  onInvite,
  showChatSlider = true,
  showUsernameOffSlider = false,
}) => {
  const navigate = useNavigate();
  function goToUserProfile(additionalOffset = 0) {
    // console.log('Navigating to user profile:', `/profile/${username}`);
    // navigate(`/profile/${username}`);
    // change to true when you have a way to get the username, replace 'username' with the actual username variable
    navigate(`/profile/${username}#member`, { replace: false });

    // The logic to scroll to the element
    setTimeout(() => {
      // Timeout is used to wait for the navigation to complete
      const el = document.getElementById("member");
      if (el) {
        const offset = 100; // Height of your fixed header/navbar
        const elementPosition =
          el.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset - additionalOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  }

  // Define a CSS class based on showUsernameOffSlider
  const usernameClass = showUsernameOffSlider
    ? "show-username"
    : "hide-username";

  return (
    <div className="user-container">
      {showChatSlider && (
        <ConnectionBtn
          sliderImage={profileThumbnail}
          slideBtnText={username}
          onHandleSlideEnd={() => goToUserProfile(50)} // Passes an additional 50 pixels offset to the call back
        />
      )}
      <div className={`connections-info ${usernameClass}`}>
        {showUsernameOffSlider && (
          <div className="username">
            <span className="italic">UserName:</span>
            {username}
          </div>
        )}
        <div className="full-name">{`${firstName} ${lastName}`}</div>
      </div>
    </div>
  );
};

export default Connections;

{
  /* <button onClick={() => onInvite(userId)}>Invite to Event</button> */
}
