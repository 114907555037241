export const validatePasswordValue = (password) => {
    return [
      {
        isValid: password.length >= 8,
        message: "At least 8 characters",
      },
      {
        isValid: /[A-Z]/.test(password),
        message: "At least one capital letter",
      },
      {
        isValid: /[0-9]/.test(password),
        message: "At least one number",
      },
      {
        isValid: /[\W_]/.test(password) && !/[<>$^!\/\[\]]/.test(password),
        message:
          'At least one special character except the following: "$ <> & ^ ! / [ ] *"',
      },
      {
        isValid: password.length > 0 && password.length < 34,
        message: "Less than 33 characters are allowed",
      },
    ];
  };


  export const validateUserNameValue = (userNameValueValue = "") => {
    return [
      {
        isValid: userNameValueValue.length >= 4,
        message: "At least 4 characters",
      },
      {
        isValid: /[A-Z]/.test(userNameValueValue),
        message: "At least one capital letter",
      },
      {
        isValid: /[0-9]/.test(userNameValueValue),
        message: "At least one number",
      },
      {
        isValid:
          userNameValueValue.length !== 0 && userNameValueValue.length <= 21,
        message: "Less than 21 characters are allowed",
      },
    ];
  };
  