import { useEffect } from "react";

export const useFilterNavScroll = () => {
    useEffect(() => {

  let retries = 0;
  const maxRetries = 5;

  const adjustScrollableHeight = () => {
    const filterNav = document.querySelector(".filterNavContainer");
    const scrollableContent = document.querySelector(".scrollable-content");

    if (filterNav && scrollableContent) {
      const filterNavHeight = filterNav.offsetHeight;
      scrollableContent.style.maxHeight = `calc(100vh - ${filterNavHeight}px)`;
      // scrollableContent.style.paddingTop = `${filterNavHeight}px`;

      // If elements are found and styles are applied, we can clear the interval
      clearInterval(interval);
    }
  };

  const interval = setInterval(() => {
    adjustScrollableHeight();
    retries++;

    if (retries >= maxRetries) {
      clearInterval(interval);
    }
  }, 500); // retry every 500ms

  // Optional: Adjust height in response to window resize events
  window.addEventListener("resize", adjustScrollableHeight);

  return () => {
    clearInterval(interval);
    window.removeEventListener("resize", adjustScrollableHeight);
  };
}, []);
}