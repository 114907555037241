import axios, { REQUEST_METHOD } from "./axios.js";

// API: get the username of current user
export function apiGetUsername({ headers, data }) {
  return axios.fetch({
    url: "/username",
    headers,
    method: REQUEST_METHOD.GET,
    params: data,
  });
}

// API get the OTP in flow: forgot username
export function apiGetOTPForgotUsername({ headers, data }) {
  return axios.fetch({
    url: "/forgot-username",
    headers,
    method: REQUEST_METHOD.GET,
    params: data,
  });
}

// API post the OTP in flow: forgot username to back-end for validation
export function apiPostValidateOTPForgotUsername({ headers, data }) {
  return axios.fetch({
    url: "/username-with-otp",
    headers,
    method: REQUEST_METHOD.POST,
    data,
  });
}

// API post the OTP in flow: forgot password to back-end for validation
export function apiPostValidateOTPForgotPassword({ headers, data }) {
  return axios.fetch({
    url: "/validate-otp-password",
    headers,
    method: REQUEST_METHOD.POST,
    data,
  });
}

// API get the OTP in flow: forgot password
export function apiForgotPassword({ headers, data }) {
  return axios.fetch({
    url: "/forgot-password",
    headers,
    method: REQUEST_METHOD.GET,
    params: data
  });
}

// API update the new password for current account
export function apiResetPassword({ headers, data }) {
  return axios.fetch({
    url: "/reset-password",
    headers,
    method: REQUEST_METHOD.POST,
    data
  });
}

// sign up: register a new user
export function apiSignUp(data) {
  return axios.post({
    url: "/sign-up",
    method: REQUEST_METHOD.POST,
    data
  });
}

// confirm sign up: confirms sign up by verifying OTP code sent to phone
export function apiConfirmSignUp({ headers, data }) {
  return axios.fetch({
    url: "/me/validate-otp",
    method: REQUEST_METHOD.POST,
    headers,
    data
  });
}

// resend sign up code: requests another OTP code to be sent for confirmation
export function apiResendSignUp({ headers, data }) {
  return axios.fetch({
    url: "/me/generate-otp",
    method: REQUEST_METHOD.POST,
    headers,
    data
  });
}

// sign in: sign in via username/password and get JWT in response
export function apiSignIn(username, password) {
  return axios.fetch({
    url: "/sign-in",  // The endpoint where the sign-in API is exposed.
    method: REQUEST_METHOD.POST, // The HTTP method used is POST, which is common for sign-in operations as it allows sending data in the request body.
    data: {
      username, // The username parameter that was passed to the function is included in the request body data.
      password  // The password parameter that was passed to the function is included in the request body data.
    }
  });
}


// sign out: actually idempotent because we do not support revoking JWT
export function apiSignOut() {
  return axios.fetch({
    url: "/sign-out",
    method: REQUEST_METHOD.GET
  });
}

// create guest: create guest user and get associated JWT
export function apiCreateGuest() {
  return axios.fetch({
    url: "/create-guest",
    method: REQUEST_METHOD.POST
  });
}

export default {
  apiGetUsername,
  apiGetOTPForgotUsername,
  apiPostValidateOTPForgotUsername,
  apiPostValidateOTPForgotPassword,
  apiForgotPassword,
  apiResetPassword,
  apiSignUp,
  apiConfirmSignUp,
  apiResendSignUp,
  apiSignIn,
  apiSignOut,
  apiCreateGuest,
};
