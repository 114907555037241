import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import mockData from "../../seedMockData/seedMockData.js";
import GroupCard from "../../components/groupCard/groupCard.jsx";
import {
  CreateGroupSlider,
  CustomButton,
  GlassBtn,
  PlainBtn,
  WhiteBtn,
  WhiteGoldButton,
} from "../../components/buttons/index.jsx";
import { GroupPopup } from "../../components/forms"; // Import the groupPopup component
import categoriesData from "../../constants/many-manyCat.json";
import { CategoryNameFormatSearch } from "../../components/forms/categoryPopup/catVertical.jsx";
import SubCategoryPopup from "../../components/forms/categoryPopup/subCategoryPopup.jsx";
import categoryData from "../../constants/many-manyCat.json";
import {
  CommunityMagnifyingGlassIcon,
  XIcon,
  EyeIcon,
  EyeSlashIcon,
  CatagoriesIcon,
  OpenBookIcon,
  PinDropIcon,
} from "../../components/icons/index.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import GlobalThreeLineAnimation from "../../globalViews/globalThreeLineAnimation.jsx";
import StateDropdown from "../../components/stateDropdown/stateDropDown.jsx";
import { convertToSlug } from "../../utils/stringUtils.js";
import "./groupsListPage.scss";
import "../../components/forms/categoryPopup/catVertical.scss"; // Importing SCSS file

// Load your category data from the JSON file and prepare it for rendering.
// This might be done in the GroupsListPage component or in a separate
// hook/utility function.

export default function GroupsListPage({ updateGroup = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentContext = "groupsList";
  // State hooks for various UI elements and data
  const [groupFormOpen, setGroupFormOpen] = useState(false); // Tracks if the group form is open
  const [currentForm, setCurrentForm] = useState("groupPopupCreate");
  const [contextType, setContextType] = useState("groupPopupCreate");
  const [groupFormZIndex, setGroupFormZIndex] = useState(false); // Manages z-index for the group form
  const [showCategories, setShowCategories] = useState(false); // Controls visibility of categories
  const [categories, setCategories] = useState([]); // Stores categories data
  const [groupTitleSearchFocused, setGroupTitleSearchFocused] = useState(false); // Tracks if the search input is focused
  const [onUserNameSearchValueBlur, setOnUserNameSearchValueBlur] =
    useState(false);
  const [isFieldScaled, setIsFieldScaled] = useState(false); // Indicates if the field is scaled (for UI effects)
  const [showFilters, setShowFilters] = useState(false); // Controls visibility of all filters
  const [showSearch, setShowSearch] = useState(false); // Toggles search input field visibility
  const [showLocationFields, setShowLocationFields] = useState(false); // Controls visibility of location fields
  // Initialize state variables with values from local storage or default to an empty string
  const [selectedState, setSelectedState] = useState(
    localStorage.getItem(getStorageKey("State", currentContext)) || ""
  );
  const [cityValue, setCityValue] = useState(
    localStorage.getItem(
      getStorageKey("groupsListCityValue", currentContext)
    ) || ""
  );
  const [zipCodeValue, setZipCodeValue] = useState(
    localStorage.getItem(
      getStorageKey("groupsListZipCodeValue", currentContext)
    ) || ""
  );
  const [searchGroupTitleValue, setSearchGroupTitleValue] = useState(
    localStorage.getItem(
      getStorageKey("groupsListTitleSearchValue", currentContext)
    ) || ""
  );
  const [cityFocused, setCityFocused] = useState(""); // Tracks if city input is focused
  const [zipCodeError, setZipCodeError] = useState(""); // Stores any zip code input error message
  const [zipCodeFocused, setZipCodeFocused] = useState(""); // Tracks if zip code input is focused
  const [isDropdownOpen, setDropdownOpen] = useState(false); // State for dropdown open status
  const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Stores the selected subcategory
  const [searchFollowers, setSearchFollowers] = useState(false); // Toggles follower search functionality
  const [searchFollowing, setSearchFollowing] = useState(false); // Toggles following search functionality
  const [followers, setFollowers] = useState([]); // Stores follower data
  const [following, setFollowing] = useState([]); // Stores following data
  const [cancelTriggered, setCancelTriggered] = useState(false); // call back to close the menu after it has been redirected and will be used in the useEffect call back as it is checking to see if any values collected and not to close the other filters
  const filterKey = selectedSubcategory ? selectedSubcategory.id : "default"; // Key for React component re-rendering
  const toggleFiltersVisibility = () => {
    setShowFilters(!showFilters);
  };
  // When returning to the original page
  useEffect(() => {
    // re-initialize the filters based on local storage persisted state since user is redirected to this page from subcat menu page
    // Fetch filter states from local storage
    const city =
      localStorage.getItem(
        getStorageKey("groupsListCityValue", currentContext)
      ) || "";
    const zipCode =
      localStorage.getItem(
        getStorageKey("groupsListZipCodeValue", currentContext)
      ) || "";
    const search =
      localStorage.getItem(
        getStorageKey("groupsListTitleSearchValue", currentContext)
      ) || "";
    const state =
      localStorage.getItem(getStorageKey("State", currentContext)) || "";
    const subcategory =
      JSON.parse(localStorage.getItem("selectedSubcategory", currentContext)) ||
      null;
    // Set the filter states
    setCityValue(city);
    setZipCodeValue(zipCode);
    setSearchGroupTitleValue(search);
    setSelectedState(state);
    setSelectedSubcategory(subcategory);
    // Update visibility of related fields
    setShowLocationFields(
      city.length > 0 || zipCode.length > 0 || state.length > 0
    );
    setShowSearch(search.length > 0);
    // Set the filter visibility based on if any filter has a value
    setShowFilters(
      city.length > 0 ||
        zipCode.length > 0 ||
        search.length > 0 ||
        state.length > 0 ||
        subcategory !== null
    );
  }, [
    setCityValue,
    setZipCodeValue,
    setSearchGroupTitleValue,
    setSelectedState,
    setSelectedSubcategory,
    setShowLocationFields,
    setShowSearch,
  ]);

  // Check if any search criteria is entered if so show the search button used for all fields
  const isSearchCriteriaEntered =
    selectedState ||
    cityValue ||
    zipCodeValue ||
    searchGroupTitleValue ||
    selectedSubcategory;

  const handleSearchClick = () => {
    setShowSearch(true);
    setIsFieldScaled(true); // Trigger the scaling effect used to make sure it scales with scss correctly
  };
  const handleLocationFieldsClick = () => {
    setShowLocationFields(true); // used for when location button is pressed it will show all location fields
  };
  const [subCatMenuData, setSubCatMenuData] = useState({
    idCategory: "",
    subCatMenuOpen: false,
  });
  const handleCategoryClick = (categoryName, categoryId) => {
    // once the category icon/name is pressed then mapping through to get the subcategories for the parent category clicked
    console.log("Category Clicked:", categoryName, "ID:", categoryId);
    const subcategories = categoryData.childs.filter((child) =>
      child.parent_categories.includes(categoryId)
    );
    // console.log("Fetched Subcategories:", subcategories);
    navigate(`/select-subcategory/${categoryId}`, {
      // using useNav to redirect you from the groups page to the subcategory menu page where the subcategory popup is hosted
      state: {
        subcategories,
        from: "groups", // needed this since we also have an events page and we had to redirect the user back to the page
        categoryName: categoryName,
        categoryIconKey: convertToSlug(categoryName), // Pass a key or identifier
      },
    });
  };
  const handleSubcategorySelect = (subcategoryId) => {
    console.log("Subcategory Selected:", subcategoryId);
    // once a subcategory is pressed
    setSelectedSubcategory(subcategoryId);
    setShowCategories(false); // Hide the categories container
  };
  useEffect(() => {
    // console.log("Render - showFilters:", showFilters);
    // call back to pass the selected category from the subcatmenu page as we are using usenav and locaton to pass the value
    console.log("Location state changed:", location.state);
    if (location.state?.selectedSubcategory) {
      setSelectedSubcategory(location.state.selectedSubcategory);
      console.log(
        "Selected Subcategory location state:",
        location.state.selectedSubcategory
      );
      localStorage.setItem(
        getStorageKey("selectedSubcategory", currentContext),
        JSON.stringify(location.state.selectedSubcategory)
      );
    }
    // Check if any filter-related criteria is present
    const anyFilterCriteriaPresent =
      location.state?.selectedSubcategory ||
      zipCodeValue.length > 0 ||
      cityValue.length > 0 ||
      (selectedState && selectedState !== "") ||
      searchGroupTitleValue.length > 0;
    setShowFilters(anyFilterCriteriaPresent);

    if (location.state?.selectedSubcategory) {
      setSelectedSubcategory(location.state.selectedSubcategory);
    }
    // React to cancel action
    if (cancelTriggered) {
      // Logic to handle when cancel is triggered
      setShowFilters(anyFilterCriteriaPresent);
    }
    // Reset cancelTriggered for future actions
    setCancelTriggered(false);
  }, []);

  // This will hold our "joined" data
  const groupInfo = mockData.groups.map((group) => {
    // Find the admin user of the group
    const adminUser = mockData.users.find((user) => user.id === group.adminId);

    // Return the aggregated object for all group info
    return {
      ...group,
      adminUser,
    groupImagePath: group.imagePath, // Directly use imagePath from the group
    };
  });
  

  const clearSubcategory = () => {
    setSelectedSubcategory("");
    const subcategoryKey = getStorageKey("selectedSubcategory", currentContext);
    localStorage.removeItem(subcategoryKey);
  };
  useEffect(() => {
    if (groupFormOpen) {
      setElementsZIndexToZero();
      setGroupFormZIndex("20"); // Making it higher than the overlay's z-index
    } else {
      resetElementsZIndex();
    }
  }, [groupFormOpen]);
  const classNamesAndZIndices = [
    { className: ".group-list-search", zIndex: "5" },
    { className: "header.sticky-header", zIndex: "20" },
    { className: ".join-button", zIndex: "0" },
    // { className: ".groups-btn", zIndex: "0" },
  ];
  const setElementsZIndexToZero = () => {
    classNamesAndZIndices.forEach(({ className }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = "-1";
      });
    });
  };
  const resetElementsZIndex = () => {
    classNamesAndZIndices.forEach(({ className, zIndex }) => {
      document.querySelectorAll(className).forEach((element) => {
        element.style.zIndex = zIndex;
      });
    });
  };
  useEffect(() => {
    // Load categories from JSON file or a fetch request
    setCategories(categoriesData.parents); // imported from the categories jason file
  }, []);

  const handleCategoriesClick = () => {
    setShowCategories(!showCategories);
  };

  const togglePopup = () => {
    setGroupFormOpen(!groupFormOpen);
  };
  const handleSlideEnd = () => {
    // Show the popup when the slider ends
    setContextType("groupPopupCreate");
    setCurrentForm("groupPopupCreate");
    togglePopup();
  }; // Closing bracket for handleSlideEnd function
  const findGroup = () => {
    // Check if there is some input and it's not exactly 4 digits
    if (zipCodeValue.length > 0 && zipCodeValue.length !== 4) {
      setZipCodeError("Must enter a 4-digit zipcode");
    } else {
      // Clear any existing error
      setZipCodeError("");
      setZipCodeValue("");
      setCityValue("");
      setSelectedState("");
      setSearchGroupTitleValue("");
      setSelectedSubcategory("");
      localStorage.clear();
    }
  };
  useEffect(() => {
    // Fetch and set followers and following here
    const fetchedFollowers = mockData.users; // Replace with actual fetch logic
    const fetchedFollowing = mockData.users; // Replace with actual fetch logic
    setFollowers(fetchedFollowers);
    setFollowing(fetchedFollowing);
  }, []);

  const toggleSearchFollowers = () => {
    //created independent state active state since both can be active together
    setSearchFollowers((prevState) => !prevState);
  };
  const toggleSearchFollowing = () => {
    //created independent state active state since both can be active together
    setSearchFollowing((prevState) => !prevState);
  };
  const handleCityInputChange = (value) => {
    setCityValue(value); // Update the state
    const GroupCityKey = getStorageKey("groupsListCityValue", currentContext);
    localStorage.setItem(GroupCityKey, value);
  };
  const handleGroupTitleSearchInputChange = (value, key) => {
    setSearchGroupTitleValue(value);
    const GroupTitleKey = getStorageKey(
      "groupsListTitleSearchValue",
      currentContext
    );
    localStorage.setItem(GroupTitleKey, value);
  };
  const handleZipCodeInputChange = (value) => {
    setZipCodeValue(value); // Update the state
    const GroupZipCodeKey = getStorageKey(
      "groupsListZipCodeValue",
      currentContext
    );
    localStorage.setItem(GroupZipCodeKey, value);
    // Clear the error message when the user starts typing
    if (zipCodeError) {
      setZipCodeError("");
    }
  };
  const clearGroupTitleSearch = () => {
    setSearchGroupTitleValue("");
    const groupSearchGroupTitleKey = getStorageKey(
      "groupsListTitleSearchValue",
      currentContext
    );
    localStorage.removeItem(groupSearchGroupTitleKey);
    setShowSearch(false);
  };
  const clearGroupZipCodeSearch = () => {
    setZipCodeValue("");
    const groupZipCodeKey = getStorageKey(
      "groupsListZipCodeValue",
      currentContext
    );
    localStorage.removeItem(groupZipCodeKey);
  };
  const clearGroupCitySearch = () => {
    setCityValue("");
    const groupCityKey = getStorageKey("groupsListCityValue", currentContext);
    localStorage.removeItem(groupCityKey);
  };
  const clearState = () => {
    // Define the key and hard coded currentContext to target payments
    const state = getStorageKey("State", "groupsList");
    // Check if the key exists in localStorage and get the item
    if (localStorage.getItem(state) !== null) {
      localStorage.removeItem(state); // Remove the item
    } else {
      console.log("Key not found in localStorage:", state);
    }
    setSelectedState(""); // Clear the state
    setDropdownOpen(false);
  };
  useEffect(() => {
    if (selectedState) {
      const key = getStorageKey("State", currentContext);
      localStorage.setItem(key, selectedState);
      setSelectedState("State", selectedState);
    }
  }, [selectedState, currentContext]);
  

  return (
    <div id="groups-page">
      <section className="groups-main-content">
        <div id="listing"></div>
        {/* Group List Search Criteria Buttons */}
        <div className="toggle-filters">
          <GlassBtn
            glassBtnImage={
              showFilters ? (
                <EyeSlashIcon
                  height="15px"
                  width="15px"
                  fillColor={showFilters ? "#e2b645" : "#fdfd96"}
                  style={{
                    position: "absolute",
                    paddingBottom: "1px",
                    left: "43%",
                  }}
                />
              ) : (
                <EyeIcon
                  height="15px"
                  width="15px"
                  fillColor={showFilters ? "#e2b645" : "#fdfd96"}
                  style={{
                    position: "absolute",
                    paddingBottom: "1px",
                    left: "43%",
                  }}
                />
              )
            }
            onClick={toggleFiltersVisibility}
            glassBtnText={
              showFilters ? "Hide Search Filters" : "Show Search Filters"
            }
            glassBtnFontColor={showFilters ? "#e2b645" : "#fdfd96"}
            glassBtnWidth="130px"
            glassBtnFontWeight="800"
            glassBtnFontFamily="roboto-slab"
            glassBtnHeight="35px"
            glassBtnTextFontSize=".75rem"
            glassBtnTextDecorationColor={showFilters ? "#e2b645" : "#fdfd96"}
            imageOnTop={true}
            textLocalStyle={{
              position: "absolute",
              bottom: "15%",
            }}
            hi2Style={{
              transform: "rotate(90deg) translate(-70px, 65px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-70px, -65px)",
            }}
            glassHoverStyle={{ height: "70px" }}
          />
        </div>
        {showFilters && (
          <div className="group-list-search">
            {/* Show/Hide Filters Button */}
            <h2>Select search criteria:</h2>
            <div className="group-follow-options-filter-container">
              <GlassBtn
                glassBtnImage={
                  // total followers to whom is following this user
                  <div style={{ position: "relative", bottom: "15px" }}>
                    <span
                      className={
                        searchFollowers
                          ? "followers-group-active"
                          : "followers-group-inactive"
                      }
                    >
                      {/* following is added to check if it is undefined before rendering it since some may not have any */}
                      {following && following.length}
                    </span>
                  </div>
                }
                onClick={toggleSearchFollowers}
                glassBtnText={
                  searchFollowers
                    ? "Cancel Follower Search"
                    : "Search Followers"
                }
                glassBtnFontColor={searchFollowers ? "#e2b645" : "#fdfd96"}
                glassBtnWidth="130px"
                glassBtnFontWeight="800"
                glassBtnFontFamily="roboto-slab"
                glassBtnHeight="35px"
                glassBtnTextFontSize=".75rem"
                glassBtnTextDecorationColor={
                  searchFollowers ? "#e2b645" : "#fdfd96"
                }
                imageOnTop={true}
                textLocalStyle={{
                  position: "absolute",
                  bottom: "15%",
                }}
                hi2Style={{
                  transform: "rotate(90deg) translate(-70px, 65px)",
                }}
                hi3Style={{
                  transform: "rotate(90deg) translate(-70px, -65px)",
                }}
                glassHoverStyle={{ height: "48px" }}
              />
              <GlassBtn
                glassBtnImage={
                  // total users this user is following
                  <div style={{ position: "relative", bottom: "15px" }}>
                    <span
                      className={
                        searchFollowing
                          ? "Following-group-active"
                          : "Following-group-inactive"
                      }
                    >
                      {/* following is added to check if it is undefined before rendering it since some may not have any */}
                      {following && following.length}
                    </span>
                  </div>
                }
                onClick={toggleSearchFollowing}
                glassBtnText={
                  searchFollowing
                    ? "Cancel Following Search"
                    : "Search Following"
                }
                glassBtnFontColor={searchFollowing ? "#e2b645" : "#fdfd96"}
                glassBtnWidth="130px"
                glassBtnFontWeight="800"
                glassBtnFontFamily="roboto-slab"
                glassBtnHeight="35px"
                glassBtnTextFontSize=".75rem"
                glassBtnTextDecorationColor={
                  searchFollowing ? "#e2b645" : "#fdfd96"
                }
                imageOnTop={true}
                textLocalStyle={{
                  position: "absolute",
                  bottom: "15%",
                }}
                hi2Style={{
                  transform: "rotate(90deg) translate(-70px, 65px)",
                }}
                hi3Style={{
                  transform: "rotate(90deg) translate(-70px, -65px)",
                }}
                glassHoverStyle={{ height: "48px" }}
              />
            </div>
            {searchFollowers && (
              <div>
                {/* Display Followers */}
                {followers.map((follower) => (
                  <p key={follower.id}>{follower.name}</p> // Adjust as per your data structure
                ))}
              </div>
            )}
            {searchFollowing && (
              <div>
                {/* Display Following */}
                {following.map((follow) => (
                  <p key={follow.id}>{follow.name}</p> // Adjust as per your data structure
                ))}
              </div>
            )}

            {/* Conditional Rendering of 'Press to Find Group' Button */}
            {isSearchCriteriaEntered && (
              <CustomButton // user will see a loader, all the buttons will close when finished and user will search results the clear loader when it stops it will say a message like success or no group found
                image={
                  <CommunityMagnifyingGlassIcon
                    height="35px"
                    width="35px"
                    fillColor="#964b00"
                  />
                }
                className={"search-icon-btn search-instruction"}
                onClick={findGroup}
                // onClick={() => setSearchGroupInfo(true)} // for backend api
                customBtnText="Press to find group"
                customBtnFontColor="#964b00"
                customBtnBorderColor="#964b00"
                customBtnBorderWidth="2px"
                paddingRight="5px"
                imagePadding="5px" // Adjust as needed
              />
            )}
            <div>
              {!showLocationFields && (
                <>
                  <CustomButton
                    image={
                      <PinDropIcon
                        height="35px"
                        width="35px"
                        fillColor="#964b00"
                      />
                    }
                    className={"search-icon-btn search-instruction"}
                    onClick={handleLocationFieldsClick} // Corrected method name
                    customBtnText="Location"
                    customBtnFontColor="#964b00"
                    customBtnBorderColor="#964b00"
                    customBtnBorderWidth="2px"
                    paddingRight="5px"
                    imagePadding="5px" // Adjust as needed
                  />
                </>
              )}
              {showLocationFields && (
                <div className="location-fields">
                  <StateDropdown
                    selectedState={selectedState}
                    clearState={clearState}
                    setSelectedState={setSelectedState}
                    isOpen={isDropdownOpen}
                    onToggle={setDropdownOpen}
                  />
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Enter City Here.."
                      className="location-input"
                      value={cityValue}
                      onChange={(e) =>
                        handleCityInputChange(
                          e.target.value,
                          "groupsListCityValue"
                        )
                      }
                      onFocus={() => setCityFocused(true)}
                      onBlur={() => setCityFocused(false)}
                    />
                    {cityValue && (
                      <button
                        className="Clear-Group-City-Search"
                        onClick={() => {
                          clearGroupCitySearch();
                        }}
                        style={{
                          color: "#fdfd96",
                          backgroundColor: "transparent",
                          borderColor: "#fdfd96",
                        }}
                      >
                        Clear
                      </button>
                    )}
                    {!cityFocused && cityValue === "" && (
                      <span className="blinking-cursor blinking topten-cursor">
                        |
                      </span>
                    )}
                  </div>
                  <div className="input-container pos-cancel-search">
                    <input
                      type="text"
                      placeholder="Enter Zipcode Here.."
                      className="location-input zipcode-input"
                      value={zipCodeValue}
                      onChange={(e) =>
                        handleZipCodeInputChange(
                          e.target.value,
                          "groupsListZipCodeValue"
                        )
                      }
                      onFocus={() => {
                        setZipCodeFocused(true);
                        setZipCodeError(""); // Clear any existing error message
                      }}
                      onBlur={() => {
                        setZipCodeFocused(false);
                        setZipCodeError(""); // Clear any existing error message
                      }}
                    />
                    {!zipCodeFocused && zipCodeValue === "" && (
                      <span className="blinking-cursor blinking topten-cursor">
                        |
                      </span>
                    )}
                    {zipCodeError && (
                      <div className="zip-code-error-message">
                        {zipCodeError}
                      </div>
                    )}

                    {zipCodeValue && (
                      <button
                        className="Clear-Group-ZipCode-Search"
                        onClick={() => {
                          clearGroupZipCodeSearch();
                        }}
                        style={{
                          color: "#fdfd96",
                          backgroundColor: "transparent",
                          borderColor: "#fdfd96",
                        }}
                      >
                        Clear
                      </button>
                    )}
                    <GlassBtn
                      glassBtnImage={
                        <XIcon
                          height="10px"
                          width="10px"
                          fillColor="#fdfd96"
                          style={{
                            position: "absolute",
                            paddingBottom: "1px",
                            // left: "43%",
                          }}
                        />
                      }
                      onClick={() => {
                        clearGroupZipCodeSearch();
                        clearGroupCitySearch();
                        localStorage.removeItem("State", "");
                        setShowLocationFields(false);
                      }}
                      glassBtnText="Cancel Location Search"
                      glassBtnFontColor="#fdfd96"
                      glassBtnWidth="130px"
                      glassBtnFontWeight="800"
                      glassBtnFontFamily="roboto-slab"
                      glassBtnHeight="35px"
                      glassBtnTextFontSize=".75rem"
                      glassBtnTextDecorationColor="#fdfd96"
                      imageOnTop={true}
                      textLocalStyle={{
                        position: "absolute",
                        bottom: "15%",
                      }}
                      hi2Style={{
                        transform: "rotate(90deg) translate(-70px, 65px)",
                      }}
                      hi3Style={{
                        transform: "rotate(90deg) translate(-70px, -65px)",
                      }}
                      glassHoverStyle={{ height: "70px" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="input-container">
              {!showSearch && (
                <>
                  <CustomButton
                    image={
                      <OpenBookIcon
                        height="35px"
                        width="35px"
                        fillColor="#964b00"
                      />
                    }
                    className={"search-icon-btn search-instruction"}
                    onClick={handleSearchClick}
                    customBtnText="Group Title"
                    customBtnFontColor="#964b00"
                    customBtnBorderColor="#964b00"
                    customBtnBorderWidth="2px"
                    paddingRight="5px"
                    imagePadding="5px" // Adjust as needed
                  />
                </>
              )}
              {showSearch && (
                <div className="search-container">
                  <input
                    className={`search-field ${isFieldScaled ? "scaled" : ""}`}
                    placeholder="Enter group title here …"
                    value={searchGroupTitleValue}
                    onChange={(e) =>
                      handleGroupTitleSearchInputChange(
                        e.target.value,
                        "groupsListTitleSearchValue"
                      )
                    }
                    onFocus={() => setGroupTitleSearchFocused(true)}
                    onBlur={() => setOnUserNameSearchValueBlur(false)}
                  />
                  {!groupTitleSearchFocused && searchGroupTitleValue === "" && (
                    <span className="blinking-cursor blinking topten-cursor">
                      |
                    </span>
                  )}
                  {searchGroupTitleValue && (
                    <button
                      className="Clear-Group-Title-Search"
                      onClick={() => {
                        clearGroupTitleSearch();
                      }}
                      style={{
                        color: "#fdfd96",
                        backgroundColor: "transparent",
                        borderColor: "#fdfd96",
                      }}
                    >
                      Clear
                    </button>
                  )}
                  {searchGroupTitleValue && (
                    <PlainBtn
                      className="cancel-group-title-search"
                      image={
                        <XIcon width="20px" height="20px" fillColor="#964b00" />
                      }
                      onClick={() => {
                        clearGroupTitleSearch();
                        setShowSearch(false);
                      }}
                      imageOnTop={true}
                      plainBtnHeight="45px"
                      plainBtnFontWeight="900"
                      plainBtnFontColor="#964b00"
                      plainBtnBorderColor="#964b00"
                      plainBtnBorderRadius="0px"
                      plainBtnText="Cancel Title Search"
                      marginLeft="50%"
                      textLocalWhiteBtnStyle={{
                        whiteSpace: "nowrap",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {/* displays the parent categories */}
            {/* This condition checks if both showCategories and selectedSubcategory are false.
         If both are false, it means neither a category is selected nor the categories button is supposed to show.
         In this case, it renders the CustomButton for selecting categories. */}
            {!showCategories && !selectedSubcategory ? (
              <>
                <CustomButton
                  image={
                    <CatagoriesIcon
                      height="25px"
                      width="25px"
                      fillColor="#964b00"
                    />
                  }
                  className={"search-icon-btn search-instruction"}
                  onClick={handleCategoriesClick}
                  customBtnText="Categories"
                  customBtnFontColor="#964b00"
                  customBtnBorderColor="#964b00"
                  customBtnBorderWidth="2px"
                  paddingRight="5px"
                  imagePadding="5px"
                />
              </>
            ) : null}
            <div className="group-categories-search-container">
              {showCategories && (
                <>
                  <WhiteBtn
                    whiteBtnImage={
                      <GlobalThreeLineAnimation
                        fillColor="#e2b645"
                        height="25px"
                        width="15px"
                        lineWidth="25px"
                        lineHeight="5px"
                        animationType="6"
                        marginLeft="10px"
                        marginBottom="20px"
                      />
                    }
                    className="cancel-categories-btn"
                    whiteBtnText="Cancel"
                    whiteBtnFontColor="#e2b645"
                    whiteGoldBtnTextFontSize=".75rem"
                    whiteBtnBorderColor="#e2b645"
                    whiteBtnPaddingRight="20px"
                    whiteBtnheight="50px"
                    extraStyles={{
                      backdropFilter: "blur(100px)", // Example: applying a blur filter
                      backgroundColor: "rgba(0, 0, 0, 0.7333333333)",
                    }}
                    onClick={() => setShowCategories(false)}
                  />
                  <div className="CategoryContainer">
                    {categories.map((category, index) => (
                      <CategoryNameFormatSearch
                        key={index}
                        categoryName={category.name}
                        categoryId={category.id}
                        handleCategoryClick={handleCategoryClick}
                      />
                    ))}
                    {subCatMenuData.subCatMenuOpen && (
                      <SubCategoryPopup
                        key={filterKey}
                        onCancel={() => setCancelTriggered(true)} // passing the call back so the filters can be left open if any values are collected since this component is managing the filter state, will be place in the useEffect above
                        subCatMenuData={subCatMenuData}
                        setSubCatMenuData={setSubCatMenuData}
                        selectedSubcategory={selectedSubcategory}
                        onSubcategorySelect={handleSubcategorySelect}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            {/* Displaying the selected subcategory */}
            <div
              className={`subcategory-select ${
                selectedSubcategory?.name ? "selected" : ""
              }`}
            >
              {/* // This condition checks if a subcategory has been selected.
                    If selectedSubcategory is not null, it means a subcategory is selected.
                    In this case, it shows the selected subcategory's name and a button to clear the selection. */}
              {selectedSubcategory && (
                <div className="selected-subcategory-display">
                  <WhiteGoldButton
                    onClick={clearSubcategory}
                    whiteGoldBtnText="Clear Subcategory"
                    whiteGoldBtnFontColor="#964B00"
                    whiteGoldBtnWidth="fit-content"
                    whiteGoldBtnFontWeight="800"
                    whiteGoldBtnFontFamily="roboto-slab"
                    whiteGoldBtnTextFontSize="1rem"
                  />
                  <p className="selectedSubcategory">
                    {selectedSubcategory.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        <section className="groups-main-content">
          <div className="grouplistformbtn">
            <CreateGroupSlider
              onHandleSlideEnd={handleSlideEnd}
              groupFormOpen={groupFormOpen}
              togglePopup={togglePopup}
              currentForm={currentForm}
              setContextType={setContextType}
              setCurrentForm={setCurrentForm}
              contextType={contextType}
              groupPopup={
                <GroupPopup
                  onClose={() => setGroupFormOpen(false)}
                  groupFormOpen={groupFormOpen}
                  updateGroup={updateGroup}
                  contextType={contextType}
                />
              }
            />
            <div className="groups-list">
              {groupInfo.length ? (
                groupInfo.map((group) => (
                  <GroupCard
                    key={group.id}
                    id={group.id}
                    group={group}
                    groupId={group.id}
                    groupMedia={group.imagePath} // Use group.imagePath
                    />
                ))
              ) : (
                <p>No groups available.</p>
              )}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
