import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { CustomButton } from "../../buttons";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator.js";
import { handleAcctValidationErrors } from "../../../controller/errorHandlers/accountValidationErrorHandler.js";
import { handlePhoneInputChange } from "../../../controller/watcherAndClearFunctions/accountVerificationClearsWatchersFn.js";
import "react-phone-input-2/lib/material.css";
import "../formsCommon.scss";
import "./accountVerification.scss";
const AccountVerification = ({
  setContextType,
  contextType,
  setCurrentForm,
  isSettings = false,
}) => {
  const currentContext = isSettings ? "settings" : "resetPasswordSignin";
  const [isFocused, setIsFocused] = useState(false);
  const [mobielEntryAttempted, setMobielEntryAttempted] = useState(false);
  const [acctPhoneNumberErrors, setAcctPhoneNumberErrors] = useState("");
  const [acctPhoneNumberValue, setAcctPhoneNumberValue] = useState(
    localStorage.getItem(getStorageKey("verifyAccountValue", currentContext)) ||
      ""
  );

  const onVerifyAccount = async () => {
    setMobielEntryAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  useEffect(() => {
    if (mobielEntryAttempted) {
      const { errors } = handleAcctValidationErrors(
        acctPhoneNumberValue,
        setAcctPhoneNumberErrors,
        currentContext
      );
      // if no errors in the accoutPhoneNumberErrors logic proceed to next form
      if (!errors.acctPhoneNumberErrors) {
        // Clear local storage items related to EnterMobileNumber form
        localStorage.clear();
        // proceed with form submission logic
        setCurrentForm("verifyAccountOtp");
        setContextType("verifyAccountOtp");
        // other logic to proceed
      } else {
        console.log("Validation errors present, not proceeding to next form.");
      }
      setMobielEntryAttempted(false);
    }
  }, [mobielEntryAttempted, acctPhoneNumberValue]);

  return (
    <div className="form-wrapper">
      <form id="forgot-password-form">
        <div className="input-group blinker-wrapper">
          <label htmlFor="phone_number">Enter Your Mobile Number:</label>
          <PhoneInput
            type="text"
            country={"us"}
            autoComplete="phone_number"
            placeholder={acctPhoneNumberErrors}
            className={acctPhoneNumberErrors ? "red-placeholder" : ""}
            inputClass="custom-placeholder-color"
            value={acctPhoneNumberValue}
            onChange={(value) =>
              handlePhoneInputChange(
                value,
                currentContext,
                acctPhoneNumberErrors,
                setAcctPhoneNumberValue,
                setAcctPhoneNumberErrors
              )
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            error={!!acctPhoneNumberErrors.phone_number}
            inputProps={{
              id: "phone_number",
              required: true,
            }}
            helperText={
              acctPhoneNumberErrors.phone_number && "Invalid phone number"
            }
          />
          {!isFocused && acctPhoneNumberValue === "" && (
            <span className="blinking-cursor signin-mobile-cursor">|</span>
          )}
        </div>
        <div className="cta-section">
          <CustomButton
            id="cancel-button"
            type="button"
            customBtnText="X Cancel"
            customBtnWidth="130px"
            customBtnTextFontSize="1.5rem"
            customBtnFontFamily="Roboto-Slab"
            customBtnFontWeight="900"
            animationActive={true} // Enable the animation
            onClick={() => {
              setCurrentForm("signIn");
              setContextType("signIn");
            }}
          />
          <CustomButton
            id="update-button"
            type="button"
            customBtnText="Verify Account"
            customBtnWidth="165px"
            customBtnTextFontSize="1.5rem"
            customBtnFontFamily="Roboto-Slab"
            customBtnFontWeight="900"
            animationActive={true} // Enable the animation
            onClick={(e) => {
              onVerifyAccount(e, contextType);
            }}
          />
        </div>
      </form>
    </div>
  );
};
export default AccountVerification;
