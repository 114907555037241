import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {WhiteGoldButton} from '../buttons'; 
import {DownArrow} from "../../components/icons"
import './timeDropDown.scss';
const TimeDropdown = ({ selectedTime, setSelectedTime, isOpen, onToggle, isEndTime, monthDropdownOpen }) => {
  const dropdownRef = useRef(null);
const timeSlots = [...Array(24).keys()].map(i => {
  const hour = i % 12 || 12; // Convert 0 to 12
  const amPm = i < 12 ? 'AM' : 'PM';
  return `${hour}:00 ${amPm}`;
});  const [focusedIndex, setFocusedIndex] = useState(0);
  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      onToggle(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);
 
  const [searchTerm, setSearchTerm] = useState('');
        useEffect(() => {
          if (searchTerm.length > 0) {
            const index = timeSlots.findIndex(time => 
              new RegExp(`^${searchTerm}`, 'i').test(time)
            );
            if (index !== -1) {
              setFocusedIndex(index);
            }
          }
        }, [searchTerm]);
  const handleDropdownKeyNavigation = (e) => {
    const updateIndex = (currentIndex, increment) => {
      const newIndex = currentIndex + increment;
      return newIndex < 0 ? timeSlots.length - 1 : (newIndex >= timeSlots.length ? 0 : newIndex);
    };
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(updateIndex(focusedIndex, -1));
    } else if (e.key === 'Enter') {
      setSelectedTime(timeSlots[focusedIndex]);
      onToggle(false);
    } else if (/^[0-9]$/i.test(e.key)) {  // If the key is a number
      setSearchTerm(prev => prev + e.key);
      // Find the first time slot that matches the current search term
      const index = timeSlots.findIndex(time => 
        new RegExp(`^${searchTerm}`, 'i').test(time)
      );
      if (index !== -1) {
        setFocusedIndex(index);
      }
      // Reset the search term after a short delay
      setTimeout(() => {
        setSearchTerm('');
      }, 1000); // 1 second delay
    }
  };
  
  const handleClear = () => {
    setSelectedTime(''); // Reset the selected time to an empty string
    onToggle(false); // Close the dropdown
  };
  const selectedStyles = selectedTime ? {
    color: "white",
    fontSize: "1.3rem"
} : {};
// const dropdownStyle = {
//   zIndex: datePickerOpen ? -1 : 1000, // Set z-index to -1 when datePicker is open, otherwise set to 1000 (or any appropriate value you prefer)
// };
const timeSelectClass = monthDropdownOpen ? 'time-select-adjusted-zindex-open' : 'time-select-adjusted-zindex-closed';
console.log("Time Select Class:", timeSelectClass);

return (
<div 
      className={`time-select ${selectedTime ? 'time-selected' : ''} ${isEndTime ? 'end' : ''} ${isOpen ? 'time-select-opened' : 'time-select-closed'}`}
      ref={dropdownRef} 
      onKeyDown={handleDropdownKeyNavigation}
  >
    {selectedTime && (
      <div className="time-label-container">
        <WhiteGoldButton
          onClick={handleClear}
          whiteGoldBtnText="Clear Time"
          whiteGoldBtnFontColor="#964B00"
          whiteGoldBtnWidth="fit-content"
          whiteGoldBtnFontWeight="800"
          whiteGoldBtnFontFamily="roboto-slab"
          whiteGoldBtnTextFontSize="1rem"
        />
        <label className="time-label">{isEndTime ? 'End Time' : 'Start Time'}</label>
      </div>
    )}

    <div onClick={() => onToggle(!isOpen)}>
      {selectedTime || (isEndTime ? 'Select End Time' : 'Select Start Time')}
      <DownArrow fillColor="#964B00" />
    </div>
    {isOpen && (
      <div className="time-options">
        {timeSlots.map((time, index) => (
          <div
            key={index}
            onClick={() => {
              setSelectedTime(time);
              onToggle(false);
            }}
            tabIndex={0}
            ref={(el) => {
              if (focusedIndex === index && el) el.focus();
            }}
            className={focusedIndex === index ? 'focused' : ''}
          >
            {time}
          </div>
        ))}
      </div>
    )}
  </div>
);
};
TimeDropdown.propTypes = {
  selectedTime: PropTypes.string,
  setSelectedTime: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  isEndTime: PropTypes.bool,
};

TimeDropdown.defaultProps = {
  onToggle: () => {},
};

export default TimeDropdown;
