import React, { useState, useEffect } from "react";
import {ToggleSlider} from "../../components/buttons/index";
import "./currentTimeDay.scss"

const CurrentTimeDay = () => {
    const [date, setDate] = useState(new Date());
    const [isMilitaryTime, setIsMilitaryTime] = useState(false); // Default to am and pm time

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const toggleTimeFormat = (position) => {
        if (position === 'right') {
            setIsMilitaryTime(true);
        } else if (position === 'left') {
            setIsMilitaryTime(false);
        }
    };
    
    const getFormattedTime = () => {
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0'); // Adds leading zero if needed
        const seconds = date.getSeconds().toString().padStart(2, '0'); // Adds leading zero if needed
        if (isMilitaryTime) {
            return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        } else {
            const hours = date.getHours();
            const period = hours >= 12 ? "PM" : "AM";
            return `${hours % 12 || 12}:${minutes}:${seconds} ${period}`;
        }
    };
    useEffect(() => {
        console.log("isMilitaryTime changed to:", isMilitaryTime);
    }, [isMilitaryTime]);
    

    return (
        <div className="eventListcaltwo">
            <div className="eventListdaytwo">{date.toLocaleString('default', { weekday: 'short' })}</div>
            <div className="eventListmonthtwo">{date.toLocaleString('default', { month: 'long' })}</div>
            <div className="eventListclock">
                <span className="eventListtime">{getFormattedTime()}</span>
                <ToggleSlider
                 checked={isMilitaryTime} 
                 handleChangeSlider={toggleTimeFormat}  // <-- This should be handleChangeSlider
                 content={isMilitaryTime ? "ON" : "OFF"}
                 sliderWrapperStyle={{
                    position: "relative",
                    top: "240%", /* This will position it just below the time */
                    left: "100%",
                    transform: "translateX(-50%)", /* Centers horizontally */
                  }}
            />
            </div>
          <p>Turn on for military time</p>
        </div>
    );
};

export default CurrentTimeDay;
