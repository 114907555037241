import React, { useState } from 'react';
import './switchSlider.scss';

const SwitchSlider = ({
    className,
    }) => {
    const [isRight, setIsRight] = useState(false);

    const switchSlider = () => {
        setIsRight(!isRight);
    };

    return (
        <div className={`slider-switch ${isRight ? 'right' : 'left'} ${className}`}>
            <span className="label">Private View</span>
            <span className="slider" onClick={switchSlider}>
                <span className="switch">
                    <span className="grip"></span>
                </span>
            </span>
            <span className="label">Public View</span>
        </div>
    );
}

export default SwitchSlider;
