import React, { useState, createContext, useContext } from "react";

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [userProfileInfo, setUserProfileInfo] = useState({});

  return (
    <UserProfileContext.Provider value={{ userProfileInfo, setUserProfileInfo }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => {
  return useContext(UserProfileContext);
}
