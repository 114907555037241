import React from "react";
import CustomCheckbox from "../buttons/customCheckbox/customCheckBox";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator";
import "./privateInviteOptions.scss";
// this file is used for styling purposes to stack the list on top of eachother
const PrivateInviteOptions = ({
  setShowFollowers: inviteFollowersWatcher, // Rename prop to match the function name
  showFollowers,
  setShowFollowing: inviteFollowingWatcher, // Rename prop to match the function name
  showFollowing,
  setExcludeChecked: inviteExcludeCheckedWatcher, // Rename prop to match the function name
  excludeChecked,
  followersList,
  followingList,
  setSelectedFollowers: inviteSelectedFollowerWatcher, // Rename prop to match the function name
  selectedFollowers,
  setSelectedFollowing: inviteSelectedFollowingWatcher, // Rename prop to match the function name
  selectedFollowing,
}) => {
  return (
    <div className="column-display">
      <label className="weight700">
        <CustomCheckbox
          label="Invite Followers"
          name="showFollowers"
          type="checkbox"
          checked={showFollowers}
          onChange={inviteFollowersWatcher} // Use the watcher function here
        />
      </label>
      <label className="weight700">
        <CustomCheckbox
          label="Invite Following"
          name="showFollowing"
          type="checkbox"
          checked={showFollowing}
          onChange={inviteFollowingWatcher} // Use the watcher function here
        />
      </label>
      <label className="weight700">
        <CustomCheckbox
          label="Exclude"
          name="excludeChecked"
          type="checkbox"
          checked={excludeChecked}
          onChange={inviteExcludeCheckedWatcher} // Use the watcher function here
        />
      </label>
      {excludeChecked &&
        showFollowers &&
        followersList.map((follower) => (
          <label key={follower} className="weight700">
            <CustomCheckbox
              label={follower}
              name={follower}
              type="checkbox"
              checked={selectedFollowers?.includes(follower)} // Use optional chaining
              onChange={() => inviteSelectedFollowerWatcher(follower)}
            />
          </label>
        ))}
      {excludeChecked &&
        showFollowing &&
        followingList.map((following) => (
          <label key={following} className="weight700">
            <CustomCheckbox
              label={following}
              name={following}
              type="checkbox"
              checked={selectedFollowing?.includes(following)} // Use optional chaining
              onChange={() => inviteSelectedFollowingWatcher(following)}
            />
          </label>
        ))}
    </div>
  );
};
export default PrivateInviteOptions;
