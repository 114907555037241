import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SubCategoryPopup from "../../components/forms/categoryPopup/subCategoryPopup";

const SubcategoryMenuPage = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // Check if the current page is the subcategory menu
  console.log("Current page path:", location.pathname);

  // Determine the current page
  const fromPage = location.state?.from; // 'events' or 'groups' that was tracked from the handlecategory click function
  const { subcategories, categoryName, categoryIcon } = location.state || {}; // Fetch subcategories from navigation state

  // Initialize subCatMenuData with the fetched subcategories
  const [subCatMenuData, setSubCatMenuData] = useState({
    idCategory: categoryId,
    subCatMenuOpen: true,
    data: { subcategories },
    categoryName: categoryName,
    categoryIcon: categoryIcon,
  });
  console.log("SubcategoryMenuPage State:", subCatMenuData);
  const handleSubcategorySelect = (subcategoryId) => {
    console.log("Subcategory selected:", subcategoryId);
    console.log("Subcategory selected:", subcategoryId, "fromPage:", fromPage);

    // Determine the current page and navigate accordingly
    // Navigate and pass subCatFilter state
    if (fromPage === "events") {
      navigate("/events", {
        state: {
          selectedSubcategory: subcategoryId,
          fromSubcategoryMenu: true,
          // from: 'subcatmenu'
        },
      });
    } else if (fromPage === "groups") {
      navigate("/groups", {
        state: {
          selectedSubcategory: subcategoryId,
          fromSubcategoryMenu: true,
          // from: 'subcatmenu'
        },
      });
    } else {
      console.error(
        "Navigation context is not identified issue with path route or location"
      );
    }
  };

  return (
    <div>
      <SubCategoryPopup
        onSubcategorySelect={handleSubcategorySelect}
        subCatMenuData={subCatMenuData}
        setSubCatMenuData={setSubCatMenuData} // This is now just a placeholder as it's not used
      />
    </div>
  );
};
export default SubcategoryMenuPage;
