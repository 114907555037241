export const createEventErrorHandler = (
  eventZipCodeValue,
  eventCityValue,
  selectedState,
  eventIsInPerson,
  currentContext,
  dateOrderError,
  setEventZipCodeError,
  setEventCityError,
  setSelectedStateError
) => {
  let errors = {};

  if (eventIsInPerson && currentContext === "createEvent") {
    console.log("In-person event validation");

    // ZIP Code Validation
    if (eventZipCodeValue.length === 0) {
      errors.zipCodeError = "ZipCode is required";
      setEventZipCodeError(errors.zipCodeError);
    } else if (eventZipCodeValue.length !== 4) {
      errors.zipCodeError = "Must enter a 4-digit ZIP code";
      setEventZipCodeError(errors.zipCodeError);
    } else {
      setEventZipCodeError("");
    }

    // City Validation
    if (eventCityValue.length === 0) {
      errors.cityError = "City is required";
      setEventCityError(errors.cityError);
    } else {
      setEventCityError("");
    }

    // State Validation
    if (selectedState.length === 0) {
      errors.stateError = "State is required";
      setSelectedStateError(errors.stateError);
    } else {
      setSelectedStateError("");
    }
  }
  // Date Order Error
  if (dateOrderError) {
    console.log("Date order error found");
    errors.dateOrderError = "Start date must be before end date";
  }

  console.log("Errors after validation:", errors);
  return { errors };
};
