// Ensure that the path to messageContext.js is correct
import { useContext } from "react";
import { MessageContext } from "../../context/messageContext";
import mockData from "../../seedMockData/seedMockData.js";
import "./chatBox.scss";

// Message component

const Message = ({ msg, onReply, onDeleteMessage, onDeleteThread }) => {
  const {
    messages,
    currentUser, // Get the current user from context
  } = useContext(MessageContext); // actually all from the context provider
  const currentUserId = currentUser?.id;
  // const currentUserIP = currentUser?.ipAddress;

  const currentUserIP = mockData.users.find(
    (user) => user.id === currentUserId
  )?.ipAddress;

  // Assuming `activityLogs` is an array of activity log entries
  const activityLogs = mockData.activityLogs;
  // Find the relevant activity log entry for the current message thread
  const activityLogEntry = activityLogs.find(
    (log) => log.relatedId === msg.threadId
  );
  // Determine if the current user is the source or target of the chat
  const isCurrentUserSource =
    activityLogEntry && activityLogEntry.sourceUserId === currentUserId;
  const isCurrentUserTarget =
    activityLogEntry && activityLogEntry.targetUserId === currentUserId;
  // Determine message class based on user's role and senderId
  let messageClass;
  if (isCurrentUserSource || isCurrentUserTarget) {
    messageClass = msg.senderId === currentUserId ? "me" : "other-user";
  } else {
    // If the current user is neither source nor target, determine based on senderId
    messageClass = msg.senderId === currentUserId ? "other-user" : "me";
  }
  // Determine if the message was sent by the current user
  const isCurrentUserSender = msg.senderId === currentUserId; // Corrected the variable name here
  // Determine if the message was received by the current user
  const isCurrentUserReceiver = msg.receiverId === currentUserId;
  // Determine the class based on sender and receiver

  return (
    <div className={`message ${isCurrentUserSender ? "me" : "other-user"}`}>
      <div
        className={`message-content ${
          isCurrentUserReceiver ? "me-content" : "other-user-content"
        }`}
        dangerouslySetInnerHTML={{ __html: msg.text }}
      ></div>
      <div className="message-actions">
        {msg.senderId === currentUserId && (
          <>
            <button
              className="delete-btn"
              onClick={() => onDeleteMessage(msg.id)}
            >
              Delete
            </button>
            {msg.replyToId == null && (
              <button onClick={() => onDeleteThread(msg.threadId)}>
                Delete Thread
              </button>
            )}
          </>
        )}
        <button className="reply-btn" onClick={() => onReply(msg.id)}>
          Reply
        </button>
      </div>
      {/* Display original message if current message is a reply */}
      {msg.replyToId && (
        <div className="original-message">
          Replying to: {messages.find((m) => m.id === msg.replyToId)?.text}
        </div>
      )}
    </div>
  );
};
export default Message;

// both the source user (the one who initiates the chat) and the target user
// (the one who receives the chat request) have the ability to send and receive messages.
// The styling of the messages will be such that:

// On the Source User's Device:

// Messages sent by the source user will be styled as "me."
// Messages received from the target user will be styled to indicate that they are from a guest (different styling than "me").
// On the Target User's Device:

// Messages sent by the target user will be styled as "me."
// Messages received from the source user will be styled to indicate that they are from a guest.
