import React, { createContext, useContext, useState, useEffect } from "react";

const AnimationContext = createContext();

export const useAnimation = () => {
  return useContext(AnimationContext);
};

export const AnimationProvider = ({ children }) => {
  const [isButtonAnimating, setIsButtonAnimating] = useState(false);
  const [isChatAnimating, setIsChatAnimating] = useState(false);
  useEffect(() => {
    if (isButtonAnimating) {
      const timer = setTimeout(() => {
        setIsButtonAnimating(false); // End the button animation
        setIsChatAnimating(true); // Start the chat animation
      }, 20000); // 20s timeout

      return () => clearTimeout(timer); // Cleanup timeout on unmount
    }
  }, [isButtonAnimating]);

  useEffect(() => {
    if (isChatAnimating) {
      // Your chat animation logic here
    }
  }, [isChatAnimating]);

  return (
    <AnimationContext.Provider
      value={{
        isButtonAnimating,
        setIsButtonAnimating,
        isChatAnimating,
        setIsChatAnimating,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};
