export const SiteLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.607 15">
    <g
      id="open_now-01"
      data-name="open now-01"
      transform="translate(-4.252 -3.951)"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M4.252,7.554v8.1a.9.9,0,0,0,.9.9H15.96a.9.9,0,0,0,.9-.9v-8.1Z"
        fill="#adadad"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M16.859,8V5.753a.9.9,0,0,0-.9-.9H5.152a.9.9,0,0,0-.9.9V8Z"
        fill="#5c5d5d"
      />
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M6.953,5.957H6.5a.2.2,0,1,1,0-.408h.45a.2.2,0,0,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_2" data-name="Group 2">
        <path
          id="Path_4"
          data-name="Path 4"
          d="M8.305,5.957H7.854a.2.2,0,1,1,0-.408h.451a.2.2,0,0,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_3" data-name="Group 3">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M13.257,5.957h-.45a.2.2,0,0,1,0-.408h.45a.2.2,0,1,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_4" data-name="Group 4">
        <path
          id="Path_6"
          data-name="Path 6"
          d="M14.607,5.957h-.449a.2.2,0,1,1,0-.408h.449a.2.2,0,1,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <path
        id="Path_7"
        data-name="Path 7"
        d="M6.953,5.753a.45.45,0,0,0,.9,0V4.4a.45.45,0,1,0-.9,0V5.753Z"
        fill="#596c76"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M13.257,5.753a.451.451,0,0,0,.9,0V4.4a.451.451,0,1,0-.9,0Z"
        fill="#596c76"
      />
      <g id="Group_5" data-name="Group 5" transform="translate(-0.212 -1.059)">
        <text
          id="_"
          data-name="!"
          transform="translate(9.463 17.01)"
          fill="#fdfd96"
          fontSize="10"
          fontFamily="MyriadPro-Bold, Myriad Pro"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            !
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export const CatagoriesIcon = ({
  width = "25.496",
  height = "18.747",
  fillColor = "#000000",
}) => (
  <svg
    className="categories-menu-header-icon"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25.496 18.747"
  >
    <rect width="5.249" height="5.249" transform="translate(6.75 13.498)" />
    <rect width="5.249" height="5.249" transform="translate(6.75)" />
    <rect width="5.249" height="5.249" transform="translate(6.75 6.749)" />
    <rect width="5.249" height="5.249" transform="translate(0 6.749)" />
    <rect width="5.249" height="5.249" transform="translate(0)" />
    <rect width="5.249" height="5.249" transform="translate(0 13.498)" />
    <rect width="5.249" height="5.249" transform="translate(20.247)" />
    <rect width="5.249" height="5.249" transform="translate(20.247 6.749)" />
    <rect width="5.249" height="5.249" transform="translate(20.247 13.498)" />
    <rect width="5.249" height="5.249" transform="translate(13.499 13.498)" />
    <rect width="5.249" height="5.249" transform="translate(13.499 6.749)" />
    <rect width="5.249" height="5.249" transform="translate(13.499)" />
  </svg>
);

export const Back = ({
  fillColor = "#fff",
  width = "16.354",
  height = "23.502",
  strokeWidth = "2",
  strokeColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16.354 29.502"
  >
    <path
      id="arrow-01"
      d="M15.518,16.354,0,1.641,1.555,0,15.433,13.156,27.86.048,29.5,1.6Z"
      transform="translate(16.354) rotate(90)"
      strokeWidth={strokeWidth}
      stroke={strokeColor}
      fill={fillColor}
    />
  </svg>
);

export const ScrollList = ({ fillColor = "#fff", width, height }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 100 100" // removed spaces in viewBox
    enableBackground="new 0 0 100 100" // changed to camelCase and removed spaces
    xmlSpace="preserve" // changed to camelCase
  >
    <g display="none">
      <path
        display="inline"
        d="M12.309,88.729c0,2.725,2.212,4.937,4.937,4.937h26.466H70.18c2.725,0,4.936-2.212,4.936-4.937V60.807
		l-3.574,7.774c-0.551,1.203-1.455,2.174-2.604,2.828l-0.018,0.009l-0.02,0.009l-10.33,5.646c-0.627,0.345-1.336,0.522-2.055,0.522
		c-0.961,0-1.902-0.326-2.649-0.924c-0.784-0.625-1.335-1.503-1.54-2.492l-1.913-9.192H27.446c-1.288,0-2.333-1.044-2.333-2.332
		s1.045-2.334,2.333-2.334h22.473c0.093-0.504,0.251-0.998,0.466-1.465l2.996-6.514H27.446c-1.288,0-2.333-1.046-2.333-2.333
		c0-1.288,1.045-2.333,2.333-2.333h28.081l11.338-24.628l1.168-2.539l0.018,0.01l0.075-0.168l2.706-5.87
		c-0.215-0.028-0.438-0.047-0.662-0.047H43.713h-8.801v1.633c0.019,0.168,0.028,0.345,0.028,0.522v13.523
		c0,3.835-3.117,6.952-6.952,6.952H14.456c-0.14,0-0.29-0.009-0.429-0.019h-1.717V88.729L12.309,88.729z M27.446,72.221h17.265
		c1.288,0,2.334,1.045,2.334,2.333s-1.046,2.333-2.334,2.333H27.446c-1.288,0-2.333-1.045-2.333-2.333S26.158,72.221,27.446,72.221z
		"
      />
      <path
        display="inline"
        d="M27.987,34.256c2.296,0,4.153-1.857,4.153-4.153V16.571c0-1.25-1.026-2.081-2.09-2.081
		c-0.513,0-1.026,0.187-1.456,0.616L12.99,30.71c-1.307,1.307-0.382,3.546,1.465,3.546H27.987z"
      />
      <path
        display="inline"
        d="M90.216,10.253l-7.587-3.49c-0.636-0.29-1.297-0.429-1.951-0.429c-1.764,0-3.453,1.008-4.246,2.716
		l-1.586,3.444l16.042,7.372l0.019,0.009l1.586-3.443C93.585,14.099,92.558,11.327,90.216,10.253z"
      />
      <polygon
        display="inline"
        points="89.655,22.563 89.73,22.423 73.66,15.041 73.596,15.181 73.398,15.61 70.683,21.508 
		75.115,23.552 86.744,28.89 	"
      />
      <polygon
        display="inline"
        points="75.115,54.106 85.475,31.597 75.115,26.837 69.414,24.215 58.616,47.667 56.47,52.334 
		53.362,59.081 55.966,60.321 63.833,64.055 69.34,66.668 	"
      />
      <path
        display="inline"
        d="M52.635,61.843c0.009,0.103,0.027,0.205,0.046,0.299l0.589,2.846l1.791,8.634
		c0.149,0.718,0.783,1.175,1.455,1.175c0.234,0,0.477-0.056,0.71-0.187l10.331-5.646c0.019-0.01,0.027-0.019,0.047-0.028
		l-8.334-3.957L52.635,61.843z"
      />
    </g>
    <g>
      <g>
        <path
          fill={fillColor}
          d="M15.409,7.4h59.729c3.729,0,6.754,2.909,6.754,6.639c0,0,3.876,12.442,0,37.467
			c-2.205,14.214-6.924,31.389-1.098,31.389h3.796c3.73,0,6.753,2.863,6.753,6.592s-3.022,6.59-6.753,6.59H22.467
			c-9.116,0-10.804-26.576-6.754-48.272c2.376-12.719,6.754-27.222,1.434-27.222h-1.738c-3.73,0-6.752-2.863-6.752-6.591
			C8.657,10.262,11.679,7.4,15.409,7.4z"
        />
      </g>
      <rect x="28" y="67.75" fill="#fff" width="44" height="3.5" />
      <rect x="28" y="33.75" fill="#fff" width="44" height="3.5" />
      <rect x="28" y="50.75" fill="#fff" width="44" height="3.5" />
    </g>
  </svg>
);
export const Remote = ({
  width = "70.786",
  height = "70.786",
  fillColor = "#C79F27",
}) => (
  <svg
    className="remotesvg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 65"
    width={width}
    height={height}
  >
    <path
      id="remote-control-svgrepo-com"
      d="M36.781,0a1.008,1.008,0,0,0,.25,2,6.688,6.688,0,0,1,2.156.406,12.5,12.5,0,0,1,5.094,3.281,13.666,13.666,0,0,1,3.281,5.156A7.02,7.02,0,0,1,48,13a1,1,0,1,0,2,0,10.178,10.178,0,0,0-.562-2.781,15.627,15.627,0,0,0-3.719-5.906A14.725,14.725,0,0,0,39.813.5,9.369,9.369,0,0,0,36.969,0h-.187ZM28.688,6a4.363,4.363,0,0,0-2.969,1.156l-.031.031L1.188,31.781a4.291,4.291,0,0,0-.031,5.906l.031.031L12.406,48.906a4.252,4.252,0,0,0,5.875.031l.031-.031,24.5-24.5a.466.466,0,0,0,.031-.062,4.369,4.369,0,0,0-.031-5.937L31.594,7.188A4.076,4.076,0,0,0,28.688,6Zm8.125,0A1,1,0,0,0,37,8a2.818,2.818,0,0,1,.938.188,6.084,6.084,0,0,1,2.344,1.531,6.084,6.084,0,0,1,1.531,2.344A2.818,2.818,0,0,1,42,13a1,1,0,1,0,2,0,5.99,5.99,0,0,0-.312-1.562,8.24,8.24,0,0,0-5.125-5.125A5.99,5.99,0,0,0,37,6h-.187ZM28.688,8a2.084,2.084,0,0,1,1.5.594L41.406,19.813a2.387,2.387,0,0,1-.062,3.25L16.906,47.469a2.233,2.233,0,0,1-3.094.031l-3.366-3.366L2.625,36.313l-.031-.031a2.231,2.231,0,0,1,0-3.062l24.5-24.594A2.333,2.333,0,0,1,28.688,8ZM28,14a8,8,0,1,0,8,8A8.015,8.015,0,0,0,28,14Zm0,2a6,6,0,1,1-6,6A5.987,5.987,0,0,1,28,16Zm0,4a2,2,0,1,0,2,2A2,2,0,0,0,28,20ZM15,27a2,2,0,1,0,2,2A2,2,0,0,0,15,27Zm-5,5a2,2,0,1,0,2,2A2,2,0,0,0,10,32Zm11,1a2,2,0,1,0,2,2A2,2,0,0,0,21,33Zm-5,5a2,2,0,1,0,2,2A2,2,0,0,0,16,38Z"
      transform="translate(0.004 35.356) rotate(-45)"
      fill={fillColor}
    />
  </svg>
);

// does not necessarily need to pass an event object (e) because the action we want to perform doesn't
// require any information from the event. The purpose of the onClick in this context is to trigger
// another action - specifically, to programmatically click the hidden file input.
//giving the div an id and passing out to control positioning locally
export const CameraIcon = ({ width = "30", height = "23.7", onClick, id }) => (
  <div id={id} onClick={onClick} style={{ cursor: "pointer" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 23.7"
      style={{ cursor: "pointer" }}
    >
      <g id="Group_17" data-name="Group 17" transform="translate(16851 16085)">
        <g id="Camera-03" transform="translate(-16851 -16085)">
          <path
            id="Path_30"
            data-name="Path 30"
            d="M30,22.5a1.272,1.272,0,0,1-1.4,1.2H1.4A1.272,1.272,0,0,1,0,22.5V2.5A1.272,1.272,0,0,1,1.4,1.3H28.5a1.272,1.272,0,0,1,1.4,1.2v20Z"
            fill="#fff"
          />
          <path
            id="Path_31"
            data-name="Path 31"
            d="M2.5,1.8V.3A.319.319,0,0,1,2.8,0H6.2a.265.265,0,0,1,.3.3V1.8Z"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M17.4,4.8a.319.319,0,0,1-.3.3H12.8a.319.319,0,0,1-.3-.3V2a.319.319,0,0,1,.3-.3h4.3a.319.319,0,0,1,.3.3Z"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M16.9,4.2a.319.319,0,0,1-.3.3H13.4a.319.319,0,0,1-.3-.3V2.6a.319.319,0,0,1,.3-.3h3.2a.319.319,0,0,1,.3.3Z"
            fill="#fff"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M15.9,3.7a.319.319,0,0,1-.3.3H14.5a.319.319,0,0,1-.3-.3V3.1a.319.319,0,0,1,.3-.3h1.1a.319.319,0,0,1,.3.3Z"
          />
          <g id="Group_13" data-name="Group 13">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M28.1,3.1v2h-4v-2h4m.1-.4H24a.319.319,0,0,0-.3.3V5.2a.319.319,0,0,0,.3.3h4.2a.319.319,0,0,0,.3-.3V2.9c0-.1-.1-.2-.3-.2Z"
            />
          </g>
          <rect
            id="Rectangle_43"
            data-name="Rectangle 43"
            width="30"
            height="15.6"
            transform="translate(0 6.9)"
            fill="#fdfd96"
          />
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="1"
            cy="1"
            r="1"
            transform="translate(2.4 9.2)"
            fill="#fff"
          />
          <circle
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="0.6"
            cy="0.6"
            r="0.6"
            transform="translate(2.8 9.6)"
            fill="#d14527"
          />
          <g id="Group_16" data-name="Group 16">
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_36"
                data-name="Path 36"
                d="M8.7,19.2A8.042,8.042,0,0,1,10,8,7.951,7.951,0,0,1,21.2,9.3"
                fill="#fff"
              />
              <path
                id="Path_37"
                data-name="Path 37"
                d="M21.2,9.3A7.973,7.973,0,1,1,8.7,19.2"
                fill="#fff"
              />
            </g>
            <g id="Group_15" data-name="Group 15">
              <path
                id="Path_38"
                data-name="Path 38"
                d="M15,6.9A7.2,7.2,0,1,1,7.8,14,7.149,7.149,0,0,1,15,6.9m0-1.3a8.5,8.5,0,1,0,8.5,8.5A8.623,8.623,0,0,0,15,5.6Z"
              />
            </g>
            <circle
              id="Ellipse_3"
              data-name="Ellipse 3"
              cx="4.4"
              cy="4.4"
              r="4.4"
              transform="translate(10.9 9.7)"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M.9,0A.9.9,0,1,1,0,.9.9.9,0,0,1,.9,0Z"
              transform="translate(12.9 13.7)"
              fill="#fff"
            />
            <circle
              id="Ellipse_5"
              data-name="Ellipse 5"
              cx="0.8"
              cy="0.8"
              r="0.8"
              transform="translate(14.5 15.1)"
              fill="#fff"
            />
          </g>
          <rect
            id="Rectangle_44"
            data-name="Rectangle 44"
            width="3.7"
            height="0.8"
            transform="translate(24.3 3.2)"
          />
        </g>
      </g>
    </svg>
  </div>
);

export const SiteLogoSmall = () => (
  <svg
    width="100"
    height="100"
    id="meetonup-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.607 15"
  >
    <g
      id="open_now-01"
      data-name="open now-01"
      transform="translate(-4.252 -3.951)"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M4.252,7.554v8.1a.9.9,0,0,0,.9.9H15.96a.9.9,0,0,0,.9-.9v-8.1Z"
        fill="#adadad"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M16.859,8V5.753a.9.9,0,0,0-.9-.9H5.152a.9.9,0,0,0-.9.9V8Z"
        fill="#5c5d5d"
      />
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M6.953,5.957H6.5a.2.2,0,1,1,0-.408h.45a.2.2,0,0,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_2" data-name="Group 2">
        <path
          id="Path_4"
          data-name="Path 4"
          d="M8.305,5.957H7.854a.2.2,0,1,1,0-.408h.451a.2.2,0,0,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_3" data-name="Group 3">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M13.257,5.957h-.45a.2.2,0,0,1,0-.408h.45a.2.2,0,1,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <g id="Group_4" data-name="Group 4">
        <path
          id="Path_6"
          data-name="Path 6"
          d="M14.607,5.957h-.449a.2.2,0,1,1,0-.408h.449a.2.2,0,1,1,0,.408Z"
          fill="#fdfd96"
        />
      </g>
      <path
        id="Path_7"
        data-name="Path 7"
        d="M6.953,5.753a.45.45,0,0,0,.9,0V4.4a.45.45,0,1,0-.9,0V5.753Z"
        fill="#596c76"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M13.257,5.753a.451.451,0,0,0,.9,0V4.4a.451.451,0,1,0-.9,0Z"
        fill="#596c76"
      />
      <g id="Group_5" data-name="Group 5" transform="translate(-0.212 -1.059)">
        <text
          id="_"
          data-name="!"
          transform="translate(9.463 17.01)"
          fill="#fdfd96"
          fontSize="10"
          fontFamily="MyriadPro-Bold, Myriad Pro"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            !
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
export const Xylophone = ({
  width = "61.379",
  height = "42",
  fillColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 61.379 42"
  >
    <g id="Group_21" data-name="Group 21" transform="translate(20602 18020)">
      <g id="Group_20" data-name="Group 20" transform="translate(-10188 -7939)">
        <path
          id="Rectangle_405"
          data-name="Rectangle 405"
          d="M8.5,0H15a0,0,0,0,1,0,0V17a0,0,0,0,1,0,0H8.5A8.5,8.5,0,0,1,0,8.5v0A8.5,8.5,0,0,1,8.5,0Z"
          transform="translate(-10414 -10071)"
          fill={fillColor}
        />
        <path
          id="Rectangle_406"
          data-name="Rectangle 406"
          d="M3.5,0H13a0,0,0,0,1,0,0V7a0,0,0,0,1,0,0H3.5A3.5,3.5,0,0,1,0,3.5v0A3.5,3.5,0,0,1,3.5,0Z"
          transform="translate(-10408 -10039) rotate(-90)"
          fill={fillColor}
        />
        <path
          id="Path_186"
          data-name="Path 186"
          d="M5.387,0H6.593a19.037,19.037,0,0,1,.926,5.387,19.037,19.037,0,0,1-.926,5.387H5.387A5.387,5.387,0,0,1,5.387,0Z"
          transform="translate(-10378.832 -10057.362) rotate(180)"
          fill={fillColor}
        />
        <path
          id="Path_185"
          data-name="Path 185"
          d="M-10222-10059.634s13.8-11.638,16.48-9.5-6.636,7.731-6.636,16.879,9.316,15.165,6.636,16.927-16.48-8.694-16.48-8.694Z"
          transform="translate(-177.98 -10.674)"
          fill={fillColor}
        />
        <rect
          id="Rectangle_408"
          data-name="Rectangle 408"
          width="4.824"
          height="12.462"
          rx="2.412"
          transform="translate(-10358.313 -10079.008) rotate(60) translate(0 5)"
          fill={fillColor}
        />
        <rect
          id="Rectangle_410"
          data-name="Rectangle 410"
          width="4.824"
          height="12.462"
          rx="2.412"
          transform="translate(-10355.898 -10051.069) rotate(120) translate(0 5)"
          fill={fillColor}
        />
        <rect
          id="Rectangle_409"
          data-name="Rectangle 409"
          width="4.824"
          height="12.462"
          rx="2.412"
          transform="translate(-10352.621 -10065.362) rotate(90) translate(0 5)"
          fill={fillColor}
        />
        <g
          id="Ellipse_103"
          data-name="Ellipse 103"
          transform="translate(-10393 -10081)"
          fill="none"
          strokeWidth="4"
        >
          <ellipse cx="11.5" cy="18.5" rx="11.5" ry="18.5" stroke="none" />
          <ellipse cx="11.5" cy="18.5" rx="9.5" ry="16.5" fill="none" />
        </g>
      </g>
    </g>
  </svg>
);

export const People = ({
  width = "80%",
  height = "80%",
  fillColor = "#fff",
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    fill={fillColor}
    width={width}
    height={height}
  >
    <g>
      <path
        d="M27.599,31.896c3.309,2.698,5.45,2.709,8.649,0.151c1.436,2.079,3.521,2.973,5.924,3.324
 c0.532,0.078,1.09,0.158,1.566,0.382c0.938,0.438,1.545,1.069,1.492,2.281c-0.099,2.261-0.047,4.528-0.016,6.792
 c0.008,0.564-0.167,0.84-0.707,1.035c-3.892,1.406-7.765,2.859-11.66,4.252c-0.431,0.154-1.008,0.16-1.438,0.012
 c-4-1.389-7.978-2.838-11.974-4.232c-0.6-0.211-0.843-0.459-0.828-1.133c0.051-2.199,0.019-4.4,0.019-6.6
 c0-1.52,0.617-2.307,2.139-2.653c0.65-0.147,1.322-0.202,1.976-0.337c1.805-0.37,3.287-1.32,4.557-2.622
 C27.438,32.405,27.479,32.164,27.599,31.896z"
      />
      <path
        d="M38.656,23.825c0.914,0.218,0.889,1.022,0.768,1.781c-0.109,0.68-0.374,1.337-0.585,2
 c-0.034,0.108-0.171,0.282-0.229,0.271c-0.961-0.199-0.855,0.674-1.174,1.132c-0.629,0.91-1.177,1.91-1.951,2.679
 c-2.016,2.001-4.899,2.016-6.925,0.02c-0.799-0.786-1.352-1.824-2-2.76c-0.287-0.415-0.18-1.212-1.053-1.035
 c-0.041,0.009-0.132-0.055-0.147-0.103c-0.224-0.693-0.477-1.38-0.644-2.086c-0.17-0.728-0.242-1.454,0.638-1.874
 c0.119-0.058,0.095-0.447,0.11-0.685c0.077-1.204,0.012-2.435,0.242-3.607c0.583-2.962,2.55-4.631,5.574-4.857
 c0.758-0.057,1.542-0.059,2.288,0.062c3.322,0.536,4.754,2.3,5.078,6.244C38.721,21.918,38.656,22.841,38.656,23.825z"
      />
      <path
        d="M52.988,27.68c1.228,1.668,2.861,2.42,4.764,2.714c0.469,0.072,0.948,0.152,1.381,0.332
 c0.754,0.313,1.203,0.819,1.176,1.753c-0.062,2.037-0.02,4.078-0.02,6.231c-1.191,0.448-2.428,0.918-3.668,1.376
 c-1.971,0.729-3.956,1.415-5.909,2.188c-0.731,0.289-1.366,0.334-2.089,0.006c-0.863-0.395-2.127-0.448-2.564-1.094
 c-0.469-0.685-0.197-1.89-0.205-2.867c-0.016-2.185-0.972-3.239-3.18-3.609c-1.061-0.18-2.119-0.438-3.138-0.785
 c-0.949-0.324-1.265-1.908-0.493-2.577c0.5-0.434,1.199-0.835,1.834-0.88c1.738-0.127,3.123-0.995,4.516-1.868
 c0.283-0.178,0.402-0.622,0.484-0.757c1.217,0.467,2.361,1.113,3.582,1.32C50.805,29.392,51.971,28.625,52.988,27.68z"
      />
      <path
        d="M3.694,38.721c0-2.244-0.007-4.383,0.004-6.519c0.005-1.039,0.793-1.391,1.594-1.636c0.665-0.202,1.379-0.237,2.058-0.402
 c1.521-0.369,3.034-1.45,3.677-2.577c2.734,2.15,4.018,2.177,6.994,0.148c1.195,1.604,2.836,2.386,4.777,2.668
 c0.556,0.081,1.115,0.252,1.627,0.483c0.872,0.395,1.058,1.216,0.824,2.012c-0.123,0.42-0.612,0.881-1.039,1.027
 c-1.045,0.362-2.161,0.514-3.226,0.827c-0.659,0.192-1.325,0.45-1.903,0.813c-0.793,0.5-1.217,1.229-1.121,2.256
 c0.089,0.947-0.01,1.912,0.03,2.869c0.021,0.523-0.169,0.752-0.647,0.99c-1.721,0.858-3.336,0.906-5.167,0.125
 C9.431,40.634,6.563,39.747,3.694,38.721z"
      />
      <path
        d="M54.988,19.52c0.056,0.646-0.277,1.344,0.479,1.887c0.221,0.157,0.131,0.834,0.075,1.258
 c-0.058,0.434-0.245,0.851-0.381,1.272c-0.038,0.117-0.099,0.329-0.144,0.328c-1.033-0.032-0.856,0.965-1.254,1.466
 c-0.375,0.472-0.664,1.02-1.074,1.455c-1.89,1.999-4.545,1.949-6.344-0.141c-0.49-0.571-0.838-1.27-1.232-1.921
 c-0.203-0.336-0.172-0.753-0.805-0.828c-0.539-0.064-0.822-2.553-0.484-3.075c0.213-0.328,0.363-0.737,0.41-1.126
 c0.092-0.755-0.002-1.54,0.145-2.279c0.773-3.964,5.768-5.763,8.928-3.195c1.338,1.088,1.537,2.721,1.68,4.326
 C55.002,19.135,54.986,19.327,54.988,19.52z"
      />
      <path
        d="M19.994,19.434c-0.044,0.791-0.22,1.48,0.498,2.048c0.205,0.16,0.214,0.873,0.033,1.153
 c-0.379,0.59,0.064,1.748-1.169,1.786c-0.078,0.002-0.125,0.343-0.224,0.503c-0.464,0.756-0.847,1.588-1.431,2.238
 c-1.758,1.96-4.303,1.988-6.138,0.101c-0.502-0.517-0.853-1.18-1.281-1.769c-0.313-0.429-0.232-1.076-1.042-1.242
 c-0.574-0.119-0.728-2.551-0.347-3.078c0.18-0.249,0.292-0.585,0.325-0.894c0.055-0.503-0.003-1.019,0.028-1.527
 c0.269-4.379,3.336-5.544,6.517-5.128C18.578,13.993,19.851,15.438,19.994,19.434z"
      />
    </g>
  </svg>
);

export const LineThroughCircle = ({
  width = "15.414",
  height = "15.414",
  strokeColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.414 15.414"
    width={width}
    height={height}
  >
    <g
      id="Group_23"
      data-name="Group 23"
      transform="translate(20533.207 17989.207)"
    >
      <g id="Group_22" data-name="Group 22">
        <g
          id="Group_19"
          data-name="Group 19"
          transform="translate(-23411.191 -17998)"
        >
          <g
            id="Ellipse_3"
            data-name="Ellipse 3"
            transform="translate(2878.192 9)"
            fill="none"
            stroke={strokeColor}
            strokeWidth="1"
          >
            <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
            <circle cx="7.5" cy="7.5" r="7" fill="none" />
          </g>
        </g>
        <line
          id="Line_1"
          data-name="Line 1"
          x1="14"
          y2="14"
          transform="translate(-20532.5 -17988.5)"
          fill={strokeColor}
          stroke={strokeColor}
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export const ThumbIndexOut = ({
  width = "28.07",
  height = "33.684",
  fillColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.07 33.684"
    fill={fillColor}
    width={width}
    height={height}
  >
    <path
      id="thumbindexout"
      d="M25.209,10.6c-1.667-.354-6.465-1.269-7.77-1.527V4.147a4.211,4.211,0,0,0-8.421,0V14.613a7.058,7.058,0,0,0-2.58-1A3.638,3.638,0,0,0,2,17.116,4.555,4.555,0,0,0,3.434,20.3c5.575,5.575,7.983,7.193,8.5,13.381H25.86V31.231c0-7.234,4.211-8.465,4.211-14.076,0-3.436-1.489-5.834-4.862-6.552Zm.5,11.259a17.492,17.492,0,0,0-2.654,9.015h-8.6C13.433,25.516,9.1,22.01,5.419,18.317c-.966-.963-.552-1.923.62-1.927,1.773-.008,4.295,2.644,5.785,4.5V4.147a1.405,1.405,0,0,1,2.807,0V13.9a.8.8,0,1,0,1.6,0v-.807a1.193,1.193,0,0,1,2.386,0v1.787a.8.8,0,1,0,1.6,0V13.714a1.186,1.186,0,0,1,2.372,0v2.152a.8.8,0,1,0,1.6,0v-1.28a1,1,0,0,1,1.429-.907c.905.428,1.636,1.308,1.636,3.477a8.881,8.881,0,0,1-1.554,4.707Z"
      transform="translate(-2)"
      fill={fillColor}
    />
  </svg>
);

export const UnlockIcon = ({
  width = "26.754",
  height = "35.277",
  fillColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.754 35.277"
    width={width}
    height={height}
  >
    <path
      id="unlock-icon"
      d="M.8,16.1h2.5V10.676A10.8,10.8,0,0,1,6.34,3.151h0a10.069,10.069,0,0,1,14.583,0h0a10.8,10.8,0,0,1,3.032,7.525v.253h-4.02v-.313a6.619,6.619,0,0,0-1.853-4.594h0a6.17,6.17,0,0,0-6.87-1.41,6.519,6.519,0,0,0-3.9,6V16.1H25.95a.82.82,0,0,1,.8.83V34.473a.82.82,0,0,1-.8.83H.8a.82.82,0,0,1-.8-.83V16.938a.847.847,0,0,1,.232-.593A.8.8,0,0,1,.8,16.1Zm12.869,9.6,1.274,6.07H11.453l1.027-6.15a2.451,2.451,0,0,1-1.609-2.768,2.359,2.359,0,0,1,4.69.259,2.432,2.432,0,0,1-1.887,2.575Z"
      transform="translate(0 -0.025)"
      fill={fillColor}
      fillRule="evenodd"
    />
  </svg>
);

export const Members = ({
  width = "64px",
  height = "64px",
  fillColor = "#fdfd96",
}) => (
  <svg
    version="1.1"
    id="Members"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={width}
    height={height}
  >
    <g>
      <path
        fill={fillColor}
        d="M27.599,31.896c3.309,2.698,5.45,2.709,8.649,0.151c1.436,2.079,3.521,2.973,5.924,3.324
		c0.532,0.078,1.09,0.158,1.566,0.382c0.938,0.438,1.545,1.069,1.492,2.281c-0.099,2.261-0.047,4.528-0.016,6.792
		c0.008,0.564-0.167,0.84-0.707,1.035c-3.892,1.406-7.765,2.859-11.66,4.252c-0.431,0.154-1.008,0.16-1.438,0.012
		c-4-1.389-7.978-2.838-11.974-4.232c-0.6-0.211-0.843-0.459-0.828-1.133c0.051-2.199,0.019-4.4,0.019-6.6
		c0-1.52,0.617-2.307,2.139-2.653c0.65-0.147,1.322-0.202,1.976-0.337c1.805-0.37,3.287-1.32,4.557-2.622
		C27.438,32.405,27.479,32.164,27.599,31.896z"
      />
      <path
        fill={fillColor}
        d="M38.656,23.825c0.914,0.218,0.889,1.022,0.768,1.781c-0.109,0.68-0.374,1.337-0.585,2
		c-0.034,0.108-0.171,0.282-0.229,0.271c-0.961-0.199-0.855,0.674-1.174,1.132c-0.629,0.91-1.177,1.91-1.951,2.679
		c-2.016,2.001-4.899,2.016-6.925,0.02c-0.799-0.786-1.352-1.824-2-2.76c-0.287-0.415-0.18-1.212-1.053-1.035
		c-0.041,0.009-0.132-0.055-0.147-0.103c-0.224-0.693-0.477-1.38-0.644-2.086c-0.17-0.728-0.242-1.454,0.638-1.874
		c0.119-0.058,0.095-0.447,0.11-0.685c0.077-1.204,0.012-2.435,0.242-3.607c0.583-2.962,2.55-4.631,5.574-4.857
		c0.758-0.057,1.542-0.059,2.288,0.062c3.322,0.536,4.754,2.3,5.078,6.244C38.721,21.918,38.656,22.841,38.656,23.825z"
      />
      <path
        fill={fillColor}
        d="M52.988,27.68c1.228,1.668,2.861,2.42,4.764,2.714c0.469,0.072,0.948,0.152,1.381,0.332
		c0.754,0.313,1.203,0.819,1.176,1.753c-0.062,2.037-0.02,4.078-0.02,6.231c-1.191,0.448-2.428,0.918-3.668,1.376
		c-1.971,0.729-3.956,1.415-5.909,2.188c-0.731,0.289-1.366,0.334-2.089,0.006c-0.863-0.395-2.127-0.448-2.564-1.094
		c-0.469-0.685-0.197-1.89-0.205-2.867c-0.016-2.185-0.972-3.239-3.18-3.609c-1.061-0.18-2.119-0.438-3.138-0.785
		c-0.949-0.324-1.265-1.908-0.493-2.577c0.5-0.434,1.199-0.835,1.834-0.88c1.738-0.127,3.123-0.995,4.516-1.868
		c0.283-0.178,0.402-0.622,0.484-0.757c1.217,0.467,2.361,1.113,3.582,1.32C50.805,29.392,51.971,28.625,52.988,27.68z"
      />
      <path
        fill={fillColor}
        d="M3.694,38.721c0-2.244-0.007-4.383,0.004-6.519c0.005-1.039,0.793-1.391,1.594-1.636
		c0.665-0.202,1.379-0.237,2.058-0.402c1.521-0.369,3.034-1.45,3.677-2.577c2.734,2.15,4.018,2.177,6.994,0.148
		c1.195,1.604,2.836,2.386,4.777,2.668c0.556,0.081,1.115,0.252,1.627,0.483c0.872,0.395,1.058,1.216,0.824,2.012
		c-0.123,0.42-0.612,0.881-1.039,1.027c-1.045,0.362-2.161,0.514-3.226,0.827c-0.659,0.192-1.325,0.45-1.903,0.813
		c-0.793,0.5-1.217,1.229-1.121,2.256c0.089,0.947-0.01,1.912,0.03,2.869c0.021,0.523-0.169,0.752-0.647,0.99
		c-1.721,0.858-3.336,0.906-5.167,0.125C9.431,40.634,6.563,39.747,3.694,38.721z"
      />
      <path
        fill={fillColor}
        d="M54.988,19.52c0.056,0.646-0.277,1.344,0.479,1.887c0.221,0.157,0.131,0.834,0.075,1.258
		c-0.058,0.434-0.245,0.851-0.381,1.272c-0.038,0.117-0.099,0.329-0.144,0.328c-1.033-0.032-0.856,0.965-1.254,1.466
		c-0.375,0.472-0.664,1.02-1.074,1.455c-1.89,1.999-4.545,1.949-6.344-0.141c-0.49-0.571-0.838-1.27-1.232-1.921
		c-0.203-0.336-0.172-0.753-0.805-0.828c-0.539-0.064-0.822-2.553-0.484-3.075c0.213-0.328,0.363-0.737,0.41-1.126
		c0.092-0.755-0.002-1.54,0.145-2.279c0.773-3.964,5.768-5.763,8.928-3.195c1.338,1.088,1.537,2.721,1.68,4.326
		C55.002,19.135,54.986,19.327,54.988,19.52z"
      />
      <path
        fill={fillColor}
        d="M19.994,19.434c-0.044,0.791-0.22,1.48,0.498,2.048c0.205,0.16,0.214,0.873,0.033,1.153
		c-0.379,0.59,0.064,1.748-1.169,1.786c-0.078,0.002-0.125,0.343-0.224,0.503c-0.464,0.756-0.847,1.588-1.431,2.238
		c-1.758,1.96-4.303,1.988-6.138,0.101c-0.502-0.517-0.853-1.18-1.281-1.769c-0.313-0.429-0.232-1.076-1.042-1.242
		c-0.574-0.119-0.728-2.551-0.347-3.078c0.18-0.249,0.292-0.585,0.325-0.894c0.055-0.503-0.003-1.019,0.028-1.527
		c0.269-4.379,3.336-5.544,6.517-5.128C18.578,13.993,19.851,15.438,19.994,19.434z"
      />
    </g>
  </svg>
);

export const ClipBoardCheck = ({
  width = "100px",
  height = "100px",
  fillColor = "#333333",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="4.7749"
        strokeMiterlimit="10"
        d="M79.6,72.1v11c0,4.8-4,8.6-9,8.6h-6.2"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="4.7749"
        strokeMiterlimit="10"
        d="M46.8,91.7H29.3c-5,0-9-3.9-9-8.6V22c0-4.8,4-8.6,9-8.6h7.6"
      />
      <path
        fill="none"
        stroke={fillColor}
        strokeWidth="4.7749"
        strokeMiterlimit="10"
        d="M62.4,13.4h8.2c5,0,9,3.9,9,8.6v21.6"
      />
      <path
        fill={fillColor}
        d="M34.1,75.3L49,66.4l7.5,14.1c0,0,16.6-31.5,30.7-37.9c0,0-3.2,12,0,18.9c0,0-15.6,10-29.4,32.3L34.1,75.3z"
      />
      <path
        fill={fillColor}
        d="M70,26c0-3.5-0.7-4.1-4.1-4.2c-2.4,0-4.8,0-7.6,0c0-2.8,0-5.2,0-7.5c0-5.8-3.7-9.9-8.8-10
              c-5.2-0.1-8.8,3.9-8.8,9.9c0,2.5,0,5,0,7.6c-3,0-5.5,0-8.1,0c-2.6-0.1-3.8,1.2-3.7,3.8c0,1.5,0,3.1,0,4.7c13.8,0,27.3,0,41,0
              C70,28.7,70,27.3,70,26z M49.5,15.3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3c1.6,0,3,1.3,3,3C52.4,14,51.1,15.3,49.5,15.3z"
      />
    </g>
  </svg>
);
export const PaperPlane2Icon = ({
  width = "20px",
  height = "20px",
  fillColor = "#ADADAD",
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    enableBackground="new 0 0 20 20"
  >
    <path
      fill={fillColor}
      d="M16.968,2.49c0-0.016-0.004-0.031-0.008-0.047c-0.002-0.012-0.005-0.023-0.008-0.035
	c-0.01-0.018-0.019-0.035-0.029-0.049c-0.005-0.008-0.007-0.016-0.013-0.021l-0.003-0.002c-0.016-0.016-0.033-0.029-0.053-0.041
	c-0.006-0.004-0.011-0.01-0.016-0.014l0,0c-0.022-0.01-0.047-0.018-0.07-0.02c-0.009-0.002-0.018-0.004-0.027-0.004
	c-0.021-0.002-0.045,0-0.068,0.006c-0.009,0.002-0.018,0.006-0.025,0.01c-0.02,0.008-0.037,0.016-0.053,0.025
	C16.593,2.3,16.59,2.3,16.589,2.302L1.783,13.179c-0.106,0.078-0.13,0.229-0.052,0.336c0.046,0.059,0.117,0.096,0.193,0.096h4.327
	c0.065,0,0.128-0.025,0.172-0.074l8-8.303L12.3,8.126l-5.531,5.744c-0.136,0.139-0.322,0.217-0.517,0.217H6.014v2.307
	c0,0.131,0.107,0.238,0.238,0.238c0.07,0,0.138-0.029,0.183-0.084l1.895-2.234l3.691,3.168c0.043,0.037,0.097,0.057,0.154,0.057
	c0.022,0,0.045-0.004,0.066-0.01c0.077-0.021,0.139-0.082,0.162-0.158l4.555-14.807c0.003-0.012,0.004-0.021,0.005-0.031
	C16.966,2.517,16.968,2.503,16.968,2.49z"
    />
  </svg>
);
export const TouchIcon = ({
  width,
  height,
  fillColor = "#fff",
  stroke = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34.503 24.804"
  >
    <g
      id="Group_19062"
      data-name="Group 19062"
      transform="translate(-19.997 -928.252)"
    >
      <g id="Group_19059" data-name="Group 19059">
        <path
          id="iconmonstr-cursor-31"
          d="M19.446,7.971c-1.253-.266-4.859-.954-5.841-1.148V3.118a3.165,3.165,0,0,0-6.33,0v7.867a5.305,5.305,0,0,0-1.939-.754A2.734,2.734,0,0,0,2,12.866a3.424,3.424,0,0,0,1.078,2.4c4.19,4.19,6,5.407,6.392,10.058H19.935V23.476c0-5.438,3.165-6.363,3.165-10.581,0-2.583-1.119-4.386-3.655-4.925Zm.377,8.463a13.148,13.148,0,0,0-2,6.776H11.36c-.766-4.03-4.02-6.666-6.79-9.441-.726-.724-.415-1.445.466-1.449,1.332-.006,3.228,1.988,4.349,3.381V3.118a1.056,1.056,0,0,1,2.11,0v7.33a.6.6,0,1,0,1.2,0V9.841a.9.9,0,0,1,1.794,0v1.343a.6.6,0,0,0,1.2,0v-.876a.891.891,0,0,1,1.783,0v1.617a.6.6,0,0,0,1.2,0v-.962a.754.754,0,0,1,1.074-.682c.68.322,1.23.983,1.23,2.613a6.676,6.676,0,0,1-1.168,3.538Z"
          transform="matrix(0.035, 0.999, -0.999, 0.035, 45.232, 929.086)"
          fill={fillColor}
        />
        <line
          id="Line_716"
          data-name="Line 716"
          y1="7"
          x2="4"
          transform="translate(44.5 928.5)"
          fill="none"
          stroke={stroke}
          strokeWidth="1"
        />
        <line
          id="Line_717"
          data-name="Line 717"
          x2="8"
          transform="translate(46.5 938.5)"
          fill="none"
          stroke={stroke}
          strokeWidth="1"
        />
        <line
          id="Line_718"
          data-name="Line 718"
          x2="4"
          y2="7"
          transform="translate(46.5 942.5)"
          fill="none"
          stroke={stroke}
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
export const Filter = ({ fillColor = "#f08400", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="filters"
    width={width}
    height={height}
    viewBox="0 0 23.875 26.275"
  >
    <g id="Group_147" data-name="Group 147">
      <path
        id="Path_148"
        d="M33.936 39.584H31.6a4.089 4.089 0 0 0-3.909-2.984 4.192 4.192 0 0 0-3.938 2.984H12.338a1.138 1.138 0 1 0 0 2.277h11.415a4.05 4.05 0 0 0 7.815 0h2.338a1.136 1.136 0 0 0 1.138-1.138 1.091 1.091 0 0 0-1.108-1.139zm-6.246 2.984a1.851 1.851 0 0 1-1.846-1.846 1.846 1.846 0 0 1 3.692 0 1.851 1.851 0 0 1-1.845 1.847z"
        className="cls-1"
        data-name="Path 148"
        transform="translate(-11.2 -27.585)"
      />
      <path
        id="Path_149"
        d="M12.338 12.561h2.431a4.05 4.05 0 0 0 7.815 0h11.352a1.136 1.136 0 0 0 1.138-1.138 1.117 1.117 0 0 0-1.138-1.138H22.584a4.05 4.05 0 0 0-7.815 0h-2.431a1.136 1.136 0 0 0-1.138 1.138 1.117 1.117 0 0 0 1.138 1.138zm6.338-2.984a1.851 1.851 0 0 1 1.846 1.846 1.813 1.813 0 0 1-1.846 1.846 1.851 1.851 0 0 1-1.846-1.846 1.893 1.893 0 0 1 1.846-1.846z"
        className="cls-1"
        data-name="Path 149"
        transform="translate(-11.2 -7.3)"
      />
      <path
        id="Path_150"
        d="M33.936 68.884H22.584a4.05 4.05 0 0 0-7.815 0h-2.431a1.136 1.136 0 0 0-1.138 1.139 1.117 1.117 0 0 0 1.138 1.138h2.431a4.05 4.05 0 0 0 7.815 0h11.352a1.136 1.136 0 0 0 1.138-1.138 1.117 1.117 0 0 0-1.138-1.139zm-15.26 2.984a1.851 1.851 0 0 1-1.846-1.846 1.846 1.846 0 0 1 3.692 0 1.851 1.851 0 0 1-1.846 1.847z"
        className="cls-1"
        data-name="Path 150"
        transform="translate(-11.2 -47.871)"
      />
    </g>
    <style>
      {`   
        .cls-1 {
          fill: ${fillColor};
        }
      `}
    </style>
  </svg>
);

export const Pencil = ({
  width = "100px",
  height = "100px",
  fillColor = "black",
}) => (
  <svg
    id="editPen"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 100 100"
  >
    <g>
      <path
        fill={fillColor}
        d="M86.688,27.509l-4.52,4.495L70.434,20.653l-5.24-5.626l4.548-4.544"
      />
      <path
        fill={fillColor}
        d="M71.734,25.303l0.17,0.169L24.313,73.047l-0.162-0.158l23.795-23.795L71.734,25.303 M71.74,21.574L46.083,47.23
		L20.427,72.885l3.883,3.889l51.316-51.295L71.74,21.574L71.74,21.574z"
      />
      <path
        fill={fillColor}
        d="M65.193,18.75l0.166,0.17L41.568,42.715L17.773,66.507l-0.167-0.167L65.193,18.75 M65.193,15.026L13.882,66.34l3.891,3.891
		L43.43,44.577L69.084,18.92L65.193,15.026L65.193,15.026z"
      />
      <path
        fill={fillColor}
        d="M78.277,31.834l0.166,0.169l-47.57,47.601l-0.17-0.17L78.277,31.834 M78.277,28.111L26.979,79.436l3.894,3.894
		l51.295-51.324L78.277,28.111L78.277,28.111z"
      />
      <path
        fill={fillColor}
        d="M78.189,5.902c0.066,0,0.164,0.018,0.248,0.1l12.774,12.771c0.046,0.046,0.087,0.108,0.087,0.234
		c0,0.105-0.027,0.19-0.087,0.247l-6.378,6.383l-9.911-9.916l-3.354-3.343L77.947,6C78.031,5.92,78.125,5.902,78.189,5.902
		 M78.189,3.269c-0.762,0-1.527,0.291-2.105,0.872l-8.242,8.24l5.221,5.205L84.835,29.36l8.239-8.245
		c0.563-0.563,0.859-1.316,0.859-2.108s-0.297-1.53-0.859-2.093L80.299,4.141C79.721,3.56,78.957,3.269,78.189,3.269L78.189,3.269z"
      />
      <path
        fill={fillColor}
        d="M15.088,71.27l10.85,10.851L10.1,87.102L15.088,71.27 M13.882,66.34L6.067,91.13l24.806-7.802L13.882,66.34L13.882,66.34z"
      />
    </g>
  </svg>
);
export const TrashCan = ({
  fillColor = "#000102",
  width = "100px",
  height = "100px",
}) => (
  <svg
    version="1.1"
    id="TrashCan"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 100 100"
  >
    <g>
      <ellipse
        fill={fillColor}
        stroke="#010203"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        cx="47.575"
        cy="19.308"
        rx="27.522"
        ry="11.526"
      />
      <path
        fill={fillColor}
        d="M46.414,37.474c-11.727,0-22.03-3.007-26.248-7.663l1.01-0.916c3.911,4.316,14.053,7.216,25.238,7.216
       c11.977,0,23.918-4.297,27.561-8.312l1.01,0.916C70.604,33.545,57.786,37.474,46.414,37.474z"
      />
      <path
        fill={fillColor}
        d="M47.804,92.221c-16.133,0-27.487-10.039-27.656-10.191l-0.256-0.23V21.403h1.56v59.695
  c2.803,2.404,25.603,20.588,52.248-0.029V21.403h1.56V81.83l-0.299,0.234C65.244,89.682,55.92,92.221,47.804,92.221z"
      />
      <polygon
        fill={fillColor}
        points="29.694,81.139 27.285,81.139 27.285,33.94 29.694,34.518 	"
      />
      <polygon
        fill={fillColor}
        points="54.232,84.359 51.824,84.359 51.824,37.161 54.232,36.454 	"
      />
      <polygon
        fill={fillColor}
        points="67.865,81.139 65.457,81.139 65.457,33.94 67.779,32.525 	"
      />
      <polygon
        fill={fillColor}
        points="41.964,83.59 39.554,83.59 39.554,36.055 41.964,36.391 	"
      />
    </g>
  </svg>
);

export const OpenBookIcon = ({
  width = "40px",
  height = "40px",
  fillColor = "#000",
}) => (
  <svg
    className=" category-menu-parent-icon remove-border"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 51.469 50.9"
    enableBackground="new 0 0 51.469 50.9"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M42.163,37.617V9.052l-1.09,0.091c-4.371,0.361-8.658,1.406-12.706,3.097l-1.918,0.796v28.452l1.396-0.581
  c4.188-1.743,8.623-2.821,13.144-3.196L42.163,37.617z"
      />
      <path
        d="M45.734,13.275h-2.143v25c0.001,0.373-0.284,0.684-0.656,0.714l-1.829,0.15
  c-0.527,0.044-1.055,0.098-1.579,0.162c-0.17,0.021-0.339,0.048-0.508,0.072c-0.354,0.047-0.707,0.095-1.059,0.15
  c-0.205,0.033-0.409,0.071-0.613,0.107c-0.315,0.055-0.631,0.11-0.945,0.172c-0.214,0.043-0.429,0.091-0.647,0.137
  c-0.299,0.063-0.599,0.128-0.896,0.199c-0.223,0.053-0.445,0.109-0.666,0.166c-0.289,0.072-0.577,0.149-0.864,0.229
  c-0.224,0.062-0.447,0.126-0.67,0.192c-0.286,0.084-0.567,0.17-0.85,0.26c-0.221,0.071-0.442,0.143-0.662,0.214
  c-0.282,0.095-0.563,0.194-0.844,0.295c-0.214,0.078-0.429,0.156-0.647,0.238c-0.098,0.037-0.195,0.077-0.293,0.115h16.371V13.275z
  "
      />
      <path
        d="M10.396,9.144l-1.09-0.091v28.567l1.269,0.108c4.518,0.378,8.947,1.462,13.129,3.214l1.317,0.548V13.037
  l-1.917-0.796C19.057,10.55,14.769,9.505,10.396,9.144z"
      />
      <path
        d="M5.734,13.275v28.571h16.383c-0.088-0.034-0.176-0.071-0.264-0.104c-0.205-0.078-0.411-0.152-0.616-0.227
  c-0.291-0.105-0.58-0.208-0.873-0.306c-0.211-0.071-0.423-0.141-0.636-0.208c-0.289-0.095-0.58-0.185-0.872-0.269
  c-0.214-0.063-0.429-0.125-0.643-0.186c-0.294-0.082-0.588-0.159-0.883-0.234c-0.214-0.055-0.429-0.11-0.647-0.161
  c-0.3-0.072-0.601-0.137-0.901-0.202c-0.214-0.046-0.429-0.094-0.643-0.137c-0.312-0.061-0.624-0.116-0.938-0.171
  c-0.205-0.036-0.41-0.072-0.615-0.108c-0.346-0.055-0.691-0.101-1.038-0.147c-0.175-0.023-0.349-0.052-0.523-0.071
  c-0.523-0.063-1.048-0.118-1.571-0.162l-1.923-0.163c-0.371-0.032-0.655-0.342-0.654-0.714v-25H5.734z"
      />
    </g>
  </svg>
);
export const Posts = ({
  width = "100px",
  height = "100px",
  fillColor = "#e2b645",
}) => (
  <svg
    id="Posts"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={width}
    height={height}
  >
    <path
      fill={fillColor}
      d="M72.682,21.294H55.945v-1.937c-0.4-3.285-3.388-5.622-6.67-5.221c-2.734,0.332-4.888,2.488-5.22,5.221v2.025
	H27.319c-3.892,0-7.046,3.154-7.046,7.045v28.187c0,3.891,3.154,7.045,7.046,7.045h16.736v21.471
	c0.399,3.283,3.386,5.621,6.671,5.221c2.732-0.334,4.886-2.488,5.219-5.221V63.574h16.736c3.891,0,7.046-3.154,7.046-7.047V28.341
	C79.728,24.448,76.572,21.294,72.682,21.294z M44.294,29.982c0-1.322,1.05-2.372,2.372-2.372h6.102c1.321,0,2.37,1.05,2.37,2.372
	v1.866h-1.354v-1.866c0-0.573-0.441-1.015-1.016-1.015h-6.102c-0.576,0-1.016,0.442-1.016,1.015v1.866h-1.356V29.982z
	 M62.121,55.674H37.313V46.25h9.15v2.578h6.508V46.25h9.15V55.674z M47.819,47.472v-3.795h3.795v3.795H47.819z M63.612,44.829
	H52.971v-2.507h-6.508v2.507H35.821V33.203h8.473h10.843h8.475V44.829z"
    />
  </svg>
);

export const Start = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="-0.5 0 7 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="playbtn"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="play"
        transform="translate(-347.000000, -3766.000000)"
        fill="#815121"
      >
        <g id="icons" transform="translate(56.000000, 160.000000)">
          <path
            d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322"
            id="play-[#1003]"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const Stop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 217 384"
  >
    <path
      id="Pause"
      data-name="Pause"
      d="M224,435.8V76.1A12.1,12.1,0,0,0,211.8,64H140.2A12.1,12.1,0,0,0,128,76.1V435.8A12.184,12.184,0,0,0,140.2,448h71.6A12.121,12.121,0,0,0,224,435.8Z"
      transform="translate(-128 -64)"
      fill="#815121"
    />
    <path
      id="Path_70"
      data-name="Path 70"
      d="M371.8,64H300.2A12.163,12.163,0,0,0,288,76.1V435.8A12.184,12.184,0,0,0,300.2,448h71.6A12.184,12.184,0,0,0,384,435.8V76.1A12.1,12.1,0,0,0,371.8,64Z"
      transform="translate(-167 -64)"
      fill="#815121"
    />
  </svg>
);

export const PhoneSliderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="17.965"
    // height="17.964"
    width="25"
    height="25"
    viewBox="0 0 17.965 17.964"
  >
    <path
      id="Path_10"
      data-name="Path 10"
      d="M19.23,15.26l-2.54-.29a1.99,1.99,0,0,0-1.64.57l-1.84,1.84a15.045,15.045,0,0,1-6.59-6.59L8.47,8.94A1.991,1.991,0,0,0,9.04,7.3L8.75,4.78A2,2,0,0,0,6.76,3.01H5.03a1.985,1.985,0,0,0-2,2.07A16.992,16.992,0,0,0,18.92,20.97a1.985,1.985,0,0,0,2.07-2V17.24A1.982,1.982,0,0,0,19.23,15.26Z"
      transform="translate(-3.025 -3.01)"
      fill="#964B00"
    />
  </svg>
);
export const HeartIcon = ({
  fillColor = "#bf3b2b",
  width = "58.773",
  height = "54.072",
  onClick,
  className = "",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58.773 54.072"
      width={width}
      height={height}
      fill={fillColor}
      onClick={onClick}
      className={className}
      style={{ cursor: "pointer" }}
    >
      <path
        id="Heart"
        data-name="LoveLikes"
        d="M31.824,14.517a15.373,15.373,0,0,1,14.1-9.551c8.489,0,14.6,7.263,15.373,15.92a15.834,15.834,0,0,1-.5,6.017,26.591,26.591,0,0,1-8.108,13.521L31.824,59.036,11.315,40.422A26.611,26.611,0,0,1,3.207,26.9a15.834,15.834,0,0,1-.5-6.017c.77-8.656,6.884-15.92,15.375-15.92C24.386,4.964,29.454,8.894,31.824,14.517Z"
        transform="translate(-2.614 -4.964)"
        fill={fillColor}
      />
    </svg>
  </>
);
export const ChatIcon = ({
  fillColor = "#fff",
  width = "40px",
  height = "40px",
}) => (
  <>
    <svg
      id="PhoneIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={width} // Using width prop
      height={height} // Using height prop
      viewBox="0 0 62.521 55.574"
    >
      <g id="Group_7" data-name="Group 7" transform="translate(16554 15728)">
        <g
          id="Group_6"
          data-name="Group 6"
          transform="translate(-16554 -15728)"
        >
          <path
            id="Path_12"
            data-name="Path 12"
            d="M62.321,48.277c0-11.646-11.664-21.083-26.05-21.083s-26.05,9.437-26.05,21.083c0,6.615,3.766,12.518,9.658,16.383a9.637,9.637,0,0,1,.111,1.453c0,3.544-2.807,6.642-6.3,8.343v1.366c6.459-.148,12.92-1.147,18.517-6.721a32.55,32.55,0,0,0,4.061.255C50.657,69.356,62.321,59.918,62.321,48.277ZM26.15,52.382a3.412,3.412,0,1,1,3.411-3.411A3.412,3.412,0,0,1,26.15,52.382Zm10.675,0a3.412,3.412,0,1,1,3.412-3.411A3.411,3.411,0,0,1,36.824,52.382Zm9.913,0a3.412,3.412,0,1,1,3.411-3.411A3.411,3.411,0,0,1,46.738,52.382Z"
            transform="translate(-10.221 -27.194)"
            fill={fillColor}
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M151.387,138.979a8.294,8.294,0,0,1,.1-1.247c5.107-3.313,8.372-8.374,8.372-14.044a16.361,16.361,0,0,0-6.363-12.573q.065.839.066,1.691a21.624,21.624,0,0,1-2.517,10.115,25.5,25.5,0,0,1-6.64,8,33.368,33.368,0,0,1-20.887,7.092,26.29,26.29,0,0,0,13.764,3.746,27.947,27.947,0,0,0,3.521-.22c4.848,4.777,9.987,5.635,15.584,5.76v-1.167C153.359,144.672,151.387,142.013,151.387,138.979Z"
            transform="translate(-97.333 -91.724)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  </>
);

export const PaperPlane = ({
  width = "25.426",
  height = "25.426",
  fillColor = "#fff",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25.426 25.426"
    className="img-svg replaced-svg"
  >
    <g id="_1_3_-01" data-name="1 (3)-01" transform="translate(-5.121 -5.833)">
      <g transform="translate(11.08 11.927)">
        <g>
          <path
            d="M17.62,30.65v6.3L31.374,18.615Z"
            transform="translate(-17.62 -18.615)"
            fill={fillColor}
          ></path>
        </g>
      </g>
      <g transform="translate(5.121 5.833)">
        <g>
          <path
            d="M5.532,17.929a.746.746,0,0,0-.058,1.3l5.137,3.162,18.7-16.481.059-.078Z"
            transform="translate(-5.121 -5.833)"
            fill={fillColor}
          ></path>
        </g>
      </g>
      <g transform="translate(16.247 6.812)">
        <g transform="translate(0 0)">
          <path
            d="M42.623,8.006,28.46,26.933l8.6,5.289a.745.745,0,0,0,1.122-.5L42.76,7.886Z"
            transform="translate(-28.46 -7.886)"
            fill={fillColor}
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const PhoneIcon = ({ fillColor = "#fff" }) => (
  <svg
    id="_1-01_4_"
    data-name="1-01 (4)"
    xmlns="http://www.w3.org/2000/svg"
    width="18.66"
    height="19.393"
    viewBox="0 0 18.66 19.393"
  >
    <g id="Group_1411" data-name="Group 1411" transform="translate(0 0)">
      <path
        id="Path_449"
        data-name="Path 449"
        d="M18.494,21.484a15.24,15.24,0,0,1-3.6-2.811,15.7,15.7,0,0,1-2.708-3.741l.962-2.685L9.908,7,8.839,8.115v0c-.007.008-.015.013-.024.022-2.7,2.8-1.244,8.538,3.379,13.342s10.143,6.313,12.841,3.511c.008-.008.013-.017.021-.024l1.069-1.113-5.046-3.372Z"
        transform="translate(-7.466 -7.002)"
        fill={fillColor}
      />
      <path
        id="Path_450"
        data-name="Path 450"
        d="M39.21,17.1H40.7A9.921,9.921,0,0,0,30.991,7V8.555A8.4,8.4,0,0,1,39.21,17.1Z"
        transform="translate(-22.045 -7.002)"
        fill={fillColor}
      />
      <path
        id="Path_451"
        data-name="Path 451"
        d="M36.219,22.159h1.494a6.867,6.867,0,0,0-6.725-6.989v1.553A5.343,5.343,0,0,1,36.219,22.159Z"
        transform="translate(-22.043 -12.064)"
        fill={fillColor}
      />
      <path
        id="Path_452"
        data-name="Path 452"
        d="M33.232,27.221h1.494a3.815,3.815,0,0,0-3.735-3.883v1.553A2.289,2.289,0,0,1,33.232,27.221Z"
        transform="translate(-22.045 -17.126)"
        fill={fillColor}
      />
    </g>
  </svg>
);

export const CheckMark = ({ fill = "#000", stroke = "#000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.08 13.316"
    width="24"
    height="24"
  >
    <path
      id="Path_2332"
      data-name="Path 2332"
      d="M6297.454,19190.949l3.891,4.619a48.73,48.73,0,0,1,3.251-5.287c2.1-3.014,5.167-6.781,5.167-6.781s-3.692,3.83-5.768,6.16-2.772,3.119-2.772,3.119Z"
      transform="translate(-6297.072 -19183.152)"
      fill={fill}
      stroke={stroke}
      strokeWidth="1"
    />
  </svg>
);

export const EyeSlashIcon = ({
  width = "44.913",
  height = "20.359",
  fillColor = "#964b00",
  style = {}, // Add a style prop for local spacing
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="HideSvg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 64.913 50.359"
    >
      <path
        id="iconmonstr-eye-8"
        d="M52.329,0,43.4,8.374A35.272,35.272,0,0,0,32.072,6.508C11.868,6.508,0,23.994,0,23.994A55.228,55.228,0,0,0,13.736,37.709l-7.77,7.765L9.74,49.249,56.106,3.777,52.329,0ZM36.271,15.426a10.642,10.642,0,0,0-14.054,13.9l-4.607,4.557a48.512,48.512,0,0,1-10.837-9.77c3.985-4.4,12.858-12.263,25.3-12.263a29.632,29.632,0,0,1,6.967.841Zm-7.7,19.836L42.162,21.934A10.651,10.651,0,0,1,28.575,35.262ZM64.064,23.994S52.714,43.878,32.072,43.878a29.1,29.1,0,0,1-10.28-1.967L26.1,37.685a22.8,22.8,0,0,0,5.971.854c12.789,0,21.632-9.415,25.369-14.318a40.864,40.864,0,0,0-9.575-7.88l3.975-3.9A44.36,44.36,0,0,1,64.064,23.994Z"
        transform="translate(0.859) rotate(1)"
        fill={fillColor}
      />
    </svg>
  </>
);

export const NotInterestedIcon = ({
  width = "50px", // Adjusted to a more standard size
  height = "50px", // Adjusted to a more standard size
  fillColor = "#fff",
  onClick,
  className = "",
}) => (
  <div
    onClick={onClick}
    className={`not-interested-icon ${className}`}
    style={{ cursor: "pointer" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1279.178 420.009" // Adjust this based on your SVG content
      // Preserve aspect ratio and scale SVG content for different sizes
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="Group_1" data-name="Group 1" transform="translate(-1208 -2420)">
        <g
          id="_311831"
          data-name="311831"
          transform="translate(1207.778 2950.609)"
          fill={fillColor}
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M996-530c-43.7,2.2-80.3,7.4-166.5,23.6-108.4,20.3-139.7,24-198,23.1-45-.7-64.3-3.2-129-16.7-43.2-9-50.7-10.5-65-12.9-26.5-4.5-90.7-12.3-124.5-15.3-22.1-1.9-81.6-1.6-101.5.5C177.3-524,148.4-519.1,84-506c-21.7,4.5-49.1,9.9-60.9,12-11.8,2.2-21.6,4.1-21.7,4.3-.2.2-.6,9.9-1,21.7-.7,24.2.6,35.9,5.1,45.3,1.4,3.1,6.9,10.1,13.2,17C43.1-379.2,47.1-373,52-354.9c10.9,39.5,19.5,66.5,30.8,95.9,13,34,28.3,67,35.8,77.6,25.7,36,82.2,61.2,156.4,69.6,7.2.8,22.8,1.2,41.5,1.2,51.7-.2,84.8-5.5,116-18.9,51.6-22.2,91.2-62.9,129.4-133.3,9.3-17.2,13.1-26.7,19.7-49.4,8.3-29.2,11.1-37.3,16.9-49.3,6.4-13.2,10.6-18.4,17.7-21.6,5.1-2.3,6.1-2.4,26.6-2.2,25.6.2,29.5,1.1,37.1,7.9,12,10.8,18.4,23,29.6,56.8,16.7,50.4,33,83.7,56,114.4,9.2,12.3,25.7,29.8,36.2,38.4,55.4,45.3,147.1,66.1,230.3,52.2,68.9-11.5,124.2-46.1,153.7-96.1,11.6-19.8,21.6-47.1,33.2-91.3,19.9-75.6,27.5-91.9,49.5-106.4,5.8-3.8,8.7-6.4,9.6-8.6,1.3-3.2,1.4-4.9,1.3-32.5,0-8.3,0-18.2.1-22.2,0-5.8-.3-7.7-2-10-3.1-4.1-9-7.1-16.9-8.3-16.5-2.7-33.8-6.5-60.5-13.5-37-9.7-45.4-11.3-88.5-17.4C1053.5-530.1,1030-531.7,996-530ZM358.9-502c26.1,1.3,42.3,3.2,64.1,7.6,86.5,17.3,130.9,44.6,141,86.6,2.2,9.3,2.7,27.7,1,38.8-4.2,27-15.3,57.5-36.7,100.5-14.8,29.6-26,46.1-43.7,64.4C446.9-165.4,398.2-143.5,337-138c-38.6,3.6-98.4-3.2-128.6-14.6-36.5-13.7-64.6-39.6-81.5-75.1C113.7-255.3,97.8-312.6,92.4-352c-2.6-18.4-2.6-58.6-.1-69.3,3.1-13,8.7-22.8,19.2-33.2,20-20,49.4-31.2,100.5-38.4,30-4.2,68.2-7.8,96.5-9C334.9-503.1,336.6-503.1,358.9-502ZM978-502c52.2,2.3,109.6,9.1,138.5,16.5,44.7,11.5,69.4,29.8,78.9,58.5,7.3,22.1,5.1,66.9-5.5,114.2-5.6,25-17.4,62.8-25.4,80.9a145.309,145.309,0,0,1-86.6,80.4c-11.4,4-37.3,9.5-56.9,12-17.1,2.3-51.2,3.1-68,1.6-70-6.2-125.2-34.6-163.7-84.1-13.1-16.9-30.9-49.5-45.2-83-7.3-17.1-15.5-42.2-18.3-56-3-15-3.2-36.8-.4-47.5,10.6-40.4,50.7-66,131.4-83.9a388.534,388.534,0,0,1,71.4-9.5C951.2-503.1,952.7-503.1,978-502Z"
            fill={fillColor}
          />
        </g>
        <g id="closed-eyes" transform="translate(1325.474 2183.475)">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M194.864,491.96c-57.675,0-115.273-17.584-156.3-52.674a11.851,11.851,0,0,1,15.63-17.818c72.368,62.521,209.288,62.521,281.343,0a11.851,11.851,0,0,1,15.63,17.818C310.136,474.376,252.539,491.96,194.864,491.96Z"
            fill={fillColor}
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M184.155,618.238a9.615,9.615,0,0,1-2.11,0,11.644,11.644,0,0,1-9.769-14.145l7.815-46.109a11.9,11.9,0,1,1,23.445,4.064l-7.815,46.109a11.723,11.723,0,0,1-11.566,10.081Zm175.683-23.445a11.566,11.566,0,0,1-8.284-3.517L313.026,552.2a11.723,11.723,0,1,1,16.646-16.412L368.2,574.865a11.723,11.723,0,0,1,0,16.568,12.192,12.192,0,0,1-8.362,3.361Zm-265.713,0a12.191,12.191,0,0,1-7.815-3.361,11.723,11.723,0,0,1,0-16.568l38.528-39.076A11.723,11.723,0,1,1,141.016,552.2l-38.529,39.076a11.566,11.566,0,0,1-8.362,3.517Zm176.23,23.445a11.723,11.723,0,0,1-11.488-9.691l-7.815-46.109a11.723,11.723,0,1,1,22.82-4.454l7.815,46.109a11.644,11.644,0,0,1-9.456,13.6A9.617,9.617,0,0,1,270.356,618.238Z"
            transform="translate(-32.118 -74.543)"
            fill={fillColor}
          />
        </g>
        <g
          id="closed-eyes-2"
          data-name="closed-eyes"
          transform="translate(2004.474 2183.475)"
          fill={fillColor}
        >
          <path
            id="Path_3-2"
            data-name="Path 3"
            d="M194.864,491.96c-57.675,0-115.273-17.584-156.3-52.674a11.851,11.851,0,0,1,15.63-17.818c72.368,62.521,209.288,62.521,281.343,0a11.851,11.851,0,0,1,15.63,17.818C310.136,474.376,252.539,491.96,194.864,491.96Z"
            fill={fillColor}
          />
          <path
            id="Path_4-2"
            data-name="Path 4"
            d="M184.155,618.238a9.615,9.615,0,0,1-2.11,0,11.644,11.644,0,0,1-9.769-14.145l7.815-46.109a11.9,11.9,0,1,1,23.445,4.064l-7.815,46.109a11.723,11.723,0,0,1-11.566,10.081Zm175.683-23.445a11.566,11.566,0,0,1-8.284-3.517L313.026,552.2a11.723,11.723,0,1,1,16.646-16.412L368.2,574.865a11.723,11.723,0,0,1,0,16.568,12.192,12.192,0,0,1-8.362,3.361Zm-265.713,0a12.191,12.191,0,0,1-7.815-3.361,11.723,11.723,0,0,1,0-16.568l38.528-39.076A11.723,11.723,0,1,1,141.016,552.2l-38.529,39.076a11.566,11.566,0,0,1-8.362,3.517Zm176.23,23.445a11.723,11.723,0,0,1-11.488-9.691l-7.815-46.109a11.723,11.723,0,1,1,22.82-4.454l7.815,46.109a11.644,11.644,0,0,1-9.456,13.6A9.617,9.617,0,0,1,270.356,618.238Z"
            transform="translate(-32.118 -74.543)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  </div>
);

export const EyeIcon = ({
  width = "29.746",
  height = "19.019",
  fillColor = "#964b00",
  style = {}, // Add a style prop for local spacing
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="ShowSvg"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 49.746 29.019"
    >
      <path
        id="iconmonstr-eye-3"
        d="M31.091,19.509a6.218,6.218,0,1,1-12.318-1.2,3.874,3.874,0,0,0,4.985-4.914,6.562,6.562,0,0,1,1.115-.1A6.219,6.219,0,0,1,31.091,19.509ZM24.9,5C9.215,5,0,18.579,0,18.579s10.022,15.44,24.9,15.44c16.029,0,24.842-15.44,24.842-15.44S40.852,5,24.9,5Zm-.031,24.873A10.364,10.364,0,1,1,35.237,19.509,10.364,10.364,0,0,1,24.873,29.873Z"
        transform="translate(0 -5)"
        fill={fillColor}
      />
    </svg>
  </>
);

export const BellIcon = ({ fillColor = "white" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80%"
    height="80%"
    viewBox="0 0 32.43 41.802"
  >
    <g
      id="Bell_black-01"
      data-name="Bell  black-01"
      transform="translate(-734.268 -353.72)"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(734.268 353.72)">
        <g id="Group_8" data-name="Group 8">
          <path
            id="Path_23"
            data-name="Path 23"
            d="M757.481,429.624c0,.011,0,.022,0,.033a4.956,4.956,0,0,0,9.912,0c0-.011,0-.022,0-.033Z"
            transform="translate(-746.222 -392.811)"
            fill={fillColor}
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M766.306,384.339l-4.46-6.554v-8.175a11.368,11.368,0,0,0-9.135-11.145v-2.517a2.229,2.229,0,1,0-4.457,0v2.517a11.367,11.367,0,0,0-9.135,11.145v8.175l-4.461,6.554a2.253,2.253,0,0,0,1.863,3.521h27.923a2.253,2.253,0,0,0,1.862-3.521Z"
            transform="translate(-734.268 -353.72)"
            fill={fillColor}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const XIcon = ({
  fillColor = "#fff",
  width = "12.728",
  height = "12.727",
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 12.728 12.727"
  >
    <g transform="translate(-8.514 -8.518)">
      <path
        d="M19.377,10.38a6.364,6.364,0,1,0,0,9A6.365,6.365,0,0,0,19.377,10.38Zm-2.271,7.628-2.229-2.229-2.229,2.229-.9-.9,2.229-2.229-2.228-2.228.9-.9,2.228,2.228,2.228-2.229.9.9L15.777,14.88l2.229,2.229Z"
        fill={fillColor}
      />
    </g>
  </svg>
);

export const DownArrow = ({
  fillColor = "white",
  width = "12.592",
  height = "8.906",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12.592 8.906"
  >
    <path
      id="arrow_down-01_1_"
      data-name="arrow down-01 (1)"
      d="M4.4,7.45l6.3,8.906,6.3-8.906Z"
      transform="translate(-4.396 -7.449)"
      fill={fillColor}
    />
  </svg>
);

export const UpArrow = ({
  fillColor = "white",
  width = "12.592",
  height = "8.906",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12.592 8.906"
  >
    <path
      id="arrow_up-01_1_"
      data-name="arrow up-01 (1)"
      d="M4.4,7.45l6.3,8.906,6.3-8.906Z"
      transform="translate(-4.396 16.355) scale(1, -1)"
      fill={fillColor}
    />
  </svg>
);

export const FollowIcon = ({
  fillColor = "white",
  plusColor = "#fff",
  width = "96.193",
  height = "140.351",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 96.193 140.351"
  >
    <g
      id="Group_12"
      data-name="Group 12"
      transform="translate(16530 15610.351)"
    >
      <g
        id="uni_avtar-02"
        data-name="uni avtar-02"
        transform="translate(-16530 -15610.351)"
      >
        <path
          id="Path_25"
          data-name="Path 25"
          d="M171.974,75.63c14.062,0,25.461-13.846,25.461-30.926s-3.742-30.926-25.461-30.926S146.512,27.624,146.512,44.7,157.912,75.63,171.974,75.63Z"
          transform="translate(-123.878 -13.778)"
          fill={fillColor}
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M59.856,429.876c0-1.042-.009-.294,0,0Z"
          transform="translate(-59.85 -320.804)"
          fill={fillColor}
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.828c.014-.285,0-1.978,0,0Z"
          transform="translate(-331.936 -321.943)"
          fill={fillColor}
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M156,303.278c-.472-29.753-4.358-38.232-34.093-43.6,0,0-4.186,5.334-13.942,5.334s-13.943-5.334-13.943-5.334c-29.412,5.308-33.534,13.66-34.076,42.632-.044,2.366-.065,2.49-.073,2.215,0,.515,0,1.466,0,3.126,0,0,7.079,14.272,48.088,14.272s48.088-14.272,48.088-14.272c0-1.066,0-1.808,0-2.312A19.115,19.115,0,0,1,156,303.278Z"
          transform="translate(-59.863 -195.455)"
          fill={fillColor}
        />
      </g>
      <text
        id="plus"
        data-name="+"
        transform="translate(-16486 -15488)"
        fill={plusColor}
        fontSize="100"
        fontWeight="900"
        fontFamily="HelveticaNeue, Helvetica Neue"
      >
        <tspan x="-6" y="-6">
          +
        </tspan>
      </text>
    </g>
  </svg>
);

export const UnfollowIcon = ({
  fillColor = "white",
  width = "112.701",
  height = "161.351",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 112.701 161.351"
  >
    <g
      id="Group_12"
      data-name="Group 12"
      transform="translate(16527 15683.351)"
    >
      <g
        id="uni_avtar-02"
        data-name="uni avtar-02"
        transform="translate(-16586.85 -15697.129)"
      >
        <path
          id="Path_25"
          data-name="Path 25"
          d="M176.344,86.244c16.475,0,29.83-16.222,29.83-36.233s-4.385-36.233-29.83-36.233S146.512,30,146.512,50.011,159.868,86.244,176.344,86.244Z"
          transform="translate(-60.143 0)"
          fill={fillColor}
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M59.856,429.968c-.005-1.22-.01-.344,0,0Z"
          transform="translate(0 -288.4)"
          fill={fillColor}
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.99c.016-.334.005-2.317,0,0Z"
          transform="translate(-255.58 -289.469)"
          fill={fillColor}
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M172.491,310.76c-.553-34.859-5.106-44.793-39.944-51.08,0,0-4.9,6.249-16.335,6.249s-16.335-6.249-16.335-6.249c-34.459,6.219-39.288,16-39.924,49.948-.052,2.772-.076,2.917-.085,2.6,0,.6,0,1.718,0,3.662,0,0,8.294,16.721,56.34,16.721s56.34-16.721,56.34-16.721c0-1.249,0-2.118,0-2.709A22.4,22.4,0,0,1,172.491,310.76Z"
          transform="translate(-0.012 -170.656)"
          fill={fillColor}
        />
      </g>
      <text
        id="Minus"
        data-name="-"
        transform="translate(-16465 -15543)"
        fill="#fff"
        fontSize="200"
        fontWeight="900"
        fontFamily="HelveticaNeue, Helvetica Neue"
      >
        <tspan x="-35%" y="20%">
          -
        </tspan>
      </text>
    </g>
  </svg>
);

export const PlusSign = ({
  fillColor = "white",
  width = "49.863",
  height = "52.855",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 49.863 52.855"
  >
    <path
      id="_2-01_12_"
      data-name="2-01 (12)"
      d="M56.932,37.781H36.986V58.928H27.011V37.781H7.069V27.212H27.011V6.072h9.975v21.14H56.932Z"
      transform="translate(-7.069 -6.072)"
      fill={fillColor}
    />
  </svg>
);

// export const BellIcon = ({ fillColor = "white" }) => (
//   <>
//     <svg version="1.1" viewBox="0 0 48 48" xmlSpace="preserve">
//       <g id="Padding__x26__Artboard" />
//       <g id="Icons">
//         <g>
//           <path
//             d="M34.66833,26.4746c-0.11816-0.30566-0.28857-0.52539-0.46045-0.7207    c-0.25146-0.24414-0.51904-0.41797-0.8208-0.52539c-1.18018-0.8125-1.96826-1.76855-2.40772-2.92285    c-0.00305-0.00793-0.00952-0.01306-0.01306-0.02063l-1.31702-3.41882c-0.80566-2.10352-2.40137-3.77344-4.49072-4.69922    c-1.35278-0.60559-2.80066-0.828-4.20782-0.68048l-0.02313-0.05975c-0.22168-0.57812-0.65771-1.03613-1.22705-1.28906    s-1.20215-0.27051-1.77832-0.04785c-0.57764,0.22266-1.03516,0.6582-1.28857,1.22754    c-0.25293,0.56934-0.27002,1.2002-0.04785,1.77832l0.01929,0.05023c-3.008,2.28394-4.16516,6.26819-2.80396,9.79938    l1.32306,3.45502c0.00049,0.00128,0.00018,0.00269,0.00067,0.00397c0.4292,1.13184,0.48828,2.3877,0.17627,3.73242    c-0.33936,0.55957-0.4043,1.24707-0.17139,1.85254c0.00198,0.00513,0.00397,0.01025,0.00598,0.01535    c0.29112,0.73838,1.17953,1.04707,1.92106,0.76407l16.76032-6.39637C34.57571,28.08279,34.95658,27.23367,34.66833,26.4746    L34.66833,26.4746z M16.099,23.93456c-0.02246,0.00391-0.04492,0.00586-0.06689,0.00586    c-0.16406,0-0.31055-0.11621-0.34326-0.2832c-0.07715-0.40039-0.12012-0.80957-0.12695-1.21777    c-0.00293-0.19336,0.15088-0.35254,0.34424-0.35547c0.00195,0,0.00391,0,0.00586,0c0.19092,0,0.34668,0.15234,0.3501,0.34375    c0.00586,0.36719,0.04443,0.73633,0.11426,1.09668C16.41296,23.71386,16.28894,23.89746,16.099,23.93456z M18.97888,17.07812    c-0.46484,0.33008-0.88477,0.7207-1.24805,1.16113c-0.58984,0.7168-1.02588,1.56934-1.26123,2.46582    c-0.04102,0.15723-0.18311,0.26074-0.33838,0.26074c-0.0293,0-0.05908-0.00293-0.08887-0.01074    c-0.18701-0.0498-0.29883-0.24023-0.24951-0.42773c0.26025-0.99316,0.74365-1.93848,1.39795-2.7334    c0.40234-0.48828,0.86768-0.9209,1.38379-1.28613c0.15625-0.11328,0.37549-0.07617,0.48779,0.08301    C19.17419,16.74804,19.13659,16.96679,18.97888,17.07812z"
//             fill={fillColor}
//           />
//           <path
//             d="M25.0791,32.46466L24.42547,32.716l-4.18738,1.61023l-0.65363,0.25128    c0.29492,0.54974,0.75378,0.99011,1.34021,1.2467c0.36719,0.16113,0.75537,0.24121,1.14453,0.24121    c0.34473,0,0.68994-0.0625,1.02051-0.19043l0.25781-0.09863c0.70312-0.27051,1.26172-0.7998,1.57275-1.48828    C25.18292,33.7052,25.22833,33.07043,25.0791,32.46466z"
//             fill={fillColor}
//           />
//         </g>
//       </g>
//     </svg>
//   </>
// );
export const CommunityMagnifyingGlassIcon = ({
  fillColor = "#fff",
  width = "34.484",
  height = "34.521",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.484 34.521"
      height={height}
      width={width}
    >
      <g
        id="Group_25"
        data-name="Group 25"
        transform="translate(16422.998 15935)"
      >
        <g
          id="uni_avtar-02"
          data-name="uni avtar-02"
          transform="translate(-16419.504 -15928.522)"
        >
          <path
            id="Path_25"
            data-name="Path 25"
            d="M148.683,19.052a2.438,2.438,0,0,0,2.171-2.637c0-1.456-.319-2.637-2.171-2.637s-2.171,1.181-2.171,2.637A2.438,2.438,0,0,0,148.683,19.052Z"
            transform="translate(-144.582 -13.778)"
            fill={fillColor}
          />
          <path
            id="Path_26"
            data-name="Path 26"
            d="M59.851,429.386c0-.089,0-.025,0,0Z"
            transform="translate(-59.85 -420.086)"
            fill={fillColor}
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M428.122,430.963c0-.024,0-.169,0,0Z"
            transform="translate(-419.921 -421.594)"
            fill="#964b00"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M68.064,263.4c-.04-2.537-.372-3.26-2.907-3.717a1.781,1.781,0,0,1-2.378,0c-2.508.453-2.859,1.165-2.905,3.635,0,.2-.006.212-.006.189,0,.044,0,.125,0,.267,0,0,.6,1.217,4.1,1.217s4.1-1.217,4.1-1.217c0-.091,0-.154,0-.2A1.626,1.626,0,0,1,68.064,263.4Z"
            transform="translate(-59.868 -254.204)"
            fill={fillColor}
          />
        </g>
        <g
          id="uni_avtar-02-2"
          data-name="uni avtar-02"
          transform="translate(-16405.148 -15928.522)"
        >
          <path
            id="Path_25-2"
            data-name="Path 25"
            d="M148.664,19a2.416,2.416,0,0,0,2.152-2.613c0-1.443-.316-2.613-2.152-2.613s-2.152,1.17-2.152,2.613A2.416,2.416,0,0,0,148.664,19Z"
            transform="translate(-144.599 -13.778)"
            fill={fillColor}
          />
          <path
            id="Path_26-2"
            data-name="Path 26"
            d="M59.851,429.386c0-.088,0-.025,0,0Z"
            transform="translate(-59.85 -420.169)"
            fill={fillColor}
          />
          <path
            id="Path_27-2"
            data-name="Path 27"
            d="M428.122,430.962c0-.024,0-.167,0,0Z"
            transform="translate(-419.994 -421.676)"
            fill={fillColor}
          />
          <path
            id="Path_28-2"
            data-name="Path 28"
            d="M67.991,263.364c-.04-2.514-.368-3.231-2.881-3.684a1.765,1.765,0,0,1-2.356,0c-2.485.449-2.834,1.154-2.88,3.6,0,.2-.006.21-.006.187,0,.043,0,.124,0,.264,0,0,.6,1.206,4.064,1.206S68,263.734,68,263.734c0-.09,0-.153,0-.2A1.613,1.613,0,0,1,67.991,263.364Z"
            transform="translate(-59.868 -254.253)"
            fill={fillColor}
          />
        </g>
        <g
          id="hire_now-01_2_"
          data-name="hire now-01 (2)"
          transform="translate(-16422.998 -15935)"
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M24.148,14.562A14.71,14.71,0,0,0,13.7,18.917,14.764,14.764,0,0,0,24.148,44.126a14.662,14.662,0,0,0,8.676-2.8l.353-.248.072.071,7.364,7.365A1.9,1.9,0,1,0,43.3,45.825l-7.435-7.435.247-.354A14.811,14.811,0,0,0,24.148,14.562Zm8.781,23.544a12.41,12.41,0,1,1,0-17.526A12.346,12.346,0,0,1,32.929,38.106Z"
            transform="translate(-9.384 -14.562)"
            fill={fillColor}
          />
        </g>
        <g
          id="uni_avtar-02-3"
          data-name="uni avtar-02"
          transform="translate(-16413.42 -15924.34)"
        >
          <path
            id="Path_25-3"
            data-name="Path 25"
            d="M149.17,20.234a2.985,2.985,0,0,0,2.657-3.228c0-1.783-.391-3.228-2.657-3.228s-2.658,1.445-2.658,3.228A2.985,2.985,0,0,0,149.17,20.234Z"
            transform="translate(-144.15 -13.778)"
            fill={fillColor}
          />
          <path
            id="Path_26-3"
            data-name="Path 26"
            d="M59.851,429.4c0-.109,0-.031,0,0Z"
            transform="translate(-59.85 -418.012)"
            fill="#964b00"
          />
          <path
            id="Path_27-3"
            data-name="Path 27"
            d="M428.122,430.981c0-.03,0-.206,0,0Z"
            transform="translate(-418.083 -419.512)"
            fill="#964b00"
          />
          <path
            id="Path_28-3"
            data-name="Path 28"
            d="M69.9,264.231c-.049-3.105-.455-3.99-3.558-4.551a2.18,2.18,0,0,1-2.91,0c-3.07.554-3.5,1.426-3.557,4.45,0,.247-.007.26-.008.231,0,.054,0,.153,0,.326,0,0,.739,1.49,5.019,1.49s5.019-1.49,5.019-1.49c0-.111,0-.189,0-.241A1.993,1.993,0,0,1,69.9,264.231Z"
            transform="translate(-59.868 -252.977)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  </>
);

export const CommunityIcon = ({
  fillColor = "white",
  width = "80%",
  height = "80%",
}) => (
  <>
    <svg
      height={height} // to override style and not refactor elsewhere
      viewBox="0 0 24 24"
      width={width} // to override style and not refactor elsewhere
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12ZM17.5 13.9992L17.4101 14.0073C17.206 14.0443 17.0451 14.2053 17.0081 14.4094L17 14.4992L16.9996 16.9992L14.4977 17L14.4078 17.0081C14.2037 17.0451 14.0427 17.206 14.0057 17.4101L13.9977 17.5L14.0057 17.5899C14.0427 17.794 14.2037 17.9549 14.4078 17.9919L14.4977 18L17.0007 17.9992L17.0011 20.5035L17.0092 20.5934C17.0462 20.7975 17.2071 20.9584 17.4112 20.9954L17.5011 21.0035L17.591 20.9954C17.7951 20.9584 17.956 20.7975 17.9931 20.5934L18.0011 20.5035L18.0007 17.9992L20.5046 18L20.5944 17.9919C20.7985 17.9549 20.9595 17.794 20.9965 17.5899L21.0046 17.5L20.9965 17.4101C20.9595 17.206 20.7985 17.0451 20.5944 17.0081L20.5046 17L17.9996 16.9992L18 14.4992L17.9919 14.4094C17.9549 14.2053 17.794 14.0443 17.5899 14.0073L17.5 13.9992ZM12.0219 13.9998C11.375 15.0101 11 16.2113 11 17.5C11 18.3838 11.1764 19.2264 11.4959 19.9946C8.94206 19.8573 7.5 18.7731 7.5 16.75V15.75C7.5 14.7835 8.2835 14 9.25 14L12.0219 13.9998ZM8.126 9.00008C8.04375 9.31968 8 9.65473 8 10C8 11.1155 8.45665 12.1244 9.1932 12.8499L9.35526 13.001L9.25 13C8.94865 13 8.65863 13.0485 8.38729 13.138C7.52255 13.4235 6.84765 14.1264 6.60122 15.0082L6.56679 15.009C3.65786 15.009 2 13.9192 2 11.75V10.75C2 9.83183 2.70711 9.07881 3.60647 9.0058L3.75 9L8.126 9.00008ZM12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7ZM20.25 9C21.2165 9 22 9.7835 22 10.75L21.9989 11.7121C22.0186 12.0809 21.9887 12.4191 21.9115 12.7264C20.7518 11.6544 19.2023 11 17.5 11C16.9147 11 16.3475 11.0774 15.808 11.2224C15.9331 10.8383 16 10.4269 16 10C16 9.65473 15.9563 9.31968 15.874 9.00008L20.25 9ZM6.5 2C8.15685 2 9.5 3.34315 9.5 5C9.5 6.65685 8.15685 8 6.5 8C4.84315 8 3.5 6.65685 3.5 5C3.5 3.34315 4.84315 2 6.5 2ZM17.5 2C19.1569 2 20.5 3.34315 20.5 5C20.5 6.65685 19.1569 8 17.5 8C15.8431 8 14.5 6.65685 14.5 5C14.5 3.34315 15.8431 2 17.5 2Z"
        fill={fillColor}
      />
    </svg>
  </>
);

export const InformationIcon = ({
  fillColor1 = "#006fdb",
  fillColor2 = "#f08400",
  width = "40",
  height = "40",
}) => (
  <>
    <svg
      height={height} // to override style and not refactor elsewhere
      viewBox="0 0 8.164 16"
      width={width} // to override style and not refactor elsewhere
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.476,16.877a.209.209,0,0,0-.262-.072,24.461,24.461,0,0,1-2.428,1.047.175.175,0,0,1-.027-.031.278.278,0,0,1-.046-.169,31.014,31.014,0,0,1,1.306-5.266,18.335,18.335,0,0,0,.816-3.122.8.8,0,0,0-.288-.635,1.138,1.138,0,0,0-.742-.222,3.894,3.894,0,0,0-1.85.606A9.941,9.941,0,0,0,3.635,11a.209.209,0,0,0,.234.334c.3-.12,1.776-.727,1.992-.863a.907.907,0,0,1,.455-.167h.012a.244.244,0,0,1,0,.039,1.572,1.572,0,0,1-.078.448c-1.263,4.091-1.877,6.6-1.877,7.668a1.272,1.272,0,0,0,.312.9,1.1,1.1,0,0,0,.844.341,2.95,2.95,0,0,0,1.386-.475,16.206,16.206,0,0,0,2.529-2.083A.211.211,0,0,0,9.476,16.877Z"
        fill={fillColor1}
      />
      <path
        d="M11.085,3.348a1.055,1.055,0,0,0-.785-.319,1.334,1.334,0,0,0-1.012.46,1.564,1.564,0,0,0-.416,1.094,1.158,1.158,0,0,0,.306.829,1.031,1.031,0,0,0,.774.327,1.339,1.339,0,0,0,1.008-.486,1.6,1.6,0,0,0,.434-1.105A1.1,1.1,0,0,0,11.085,3.348Z"
        transform="translate(-5.231 -3.029)"
        fill={fillColor2}
      />
    </svg>
  </>
);
export const Dollar = ({ fillColor = "#fff", width = "8", height = "16" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 16"
    width={width}
    height={height}
  >
    <text
      id="_"
      transform="translate(0 12)"
      fill={fillColor}
      fontSize="12"
      fontFamily="NunitoSans-Regular, Nunito Sans"
    >
      <tspan x="0" y="0">
        $
      </tspan>
    </text>
  </svg>
);
export const Zeros = ({ fillColor = "#fff", width = "15", height = "16" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 16"
    width={width}
    height={height}
  >
    <text
      id="_00"
      transform="translate(0 12)"
      fill={fillColor}
      fontSize="12"
      fontFamily="NunitoSans-Regular, Nunito Sans"
    >
      <tspan x="0" y="0">
        00
      </tspan>
    </text>
  </svg>
);

export const LeftArrow = ({
  strokeColor = "#fff",
  strokeWidth = "2",
  width = "48.461",
  height = "64.373",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.461 34.373"
    width={width}
    height={height}
  >
    <path
      id="Path_5867"
      d="M-17775.453-22700.473c-1.572.674-15.766,15.875-15.766,15.875l15.314,15.77"
      transform="translate(17792.6 22701.787)"
      fill="none"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export const RightArrow = ({
  strokeColor = "#fff",
  strokeWidth = "2",
  width = "48.461",
  height = "64.373",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.461 34.373"
    width={width}
    height={height}
  >
    <path
      id="Path_5868"
      d="M-17791.219-22700.473c1.572.674,15.766,15.875,15.766,15.875l-15.314,15.77"
      transform="translate(17792.533 22701.787)"
      fill="none"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export const MemberLevel = ({
  outerStrokeColor = "#346cb4",
  outerFillColor = "#fff",
  innerStrokeColor = "#346cb4",
  innerFillColor = "#ee8322",
  width = "18.326",
  height = "18.325",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.326 18.325"
    width={width}
    height={height}
  >
    <g transform="translate(0.28 0.28)">
      <path
        d="M21.138,13.447a8.883,8.883,0,1,1-8.884-8.883A8.882,8.882,0,0,1,21.138,13.447Z"
        transform="translate(-3.372 -4.564)"
        fill={outerFillColor}
        stroke={outerStrokeColor}
        strokeMiterlimit="10"
        strokeWidth="0.56"
      />
      <g transform="translate(1.27 1.312)">
        <path
          d="M19.937,13.559a7.613,7.613,0,1,1-7.613-7.612A7.613,7.613,0,0,1,19.937,13.559Z"
          transform="translate(-4.711 -5.947)"
          fill={innerStrokeColor}
        />
        <ellipse
          cx="3.876"
          cy="3.876"
          rx="3.876"
          ry="3.876"
          transform="translate(3.715 3.717)"
          fill={outerFillColor}
        />
        <path
          d="M15.48,13.759a2.951,2.951,0,1,1-2.952-2.951A2.952,2.952,0,0,1,15.48,13.759Z"
          transform="translate(-4.962 -6.198)"
          fill={innerFillColor}
        />
      </g>
    </g>
  </svg>
);

export const StarLevel = ({
  fillColor = "#006fdb",
  width = "14.071",
  height = "13.435",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.071 13.435"
    width={width}
    height={height}
  >
    <g transform="translate(-6.206 -8.306)">
      <path
        d="M20.258,13.383a.392.392,0,0,0-.315-.266l-4.39-.624L13.578,8.523a.391.391,0,0,0-.349-.217h0a.389.389,0,0,0-.35.219L10.93,12.508l-4.39.653a.393.393,0,0,0-.315.266.386.386,0,0,0,.1.4l3.185,3.087-.736,4.373a.392.392,0,0,0,.384.456.372.372,0,0,0,.182-.046l3.919-2.077,3.931,2.054a.4.4,0,0,0,.179.043h0a.392.392,0,0,0,.39-.39.311.311,0,0,0-.011-.09l-.76-4.347,3.165-3.1A.382.382,0,0,0,20.258,13.383Z"
        fill={fillColor}
      />
      <path
        d="M22.715,23.092a.387.387,0,0,0-.11.346l.661,3.792-3.413-1.782a.4.4,0,0,0-.364,0l-3.4,1.8.638-3.8a.39.39,0,0,0-.113-.344l-2.761-2.68,3.81-.566a.386.386,0,0,0,.292-.214l1.693-3.457,1.715,3.448a.4.4,0,0,0,.295.214l3.813.543Z"
        transform="translate(-6.414 -6.615)"
        fill={fillColor}
      />
    </g>
  </svg>
);
export const SilverLevel = ({
  fillColor = "#c4c4c4",
  width = "19.097",
  height = "17.907",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 19.097 17.907"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="2.599" y2="0.605">
        <stop offset="0" stopColor="#c4c4c4" />
        <stop offset="0.351" stopColor="#d8d8d8" />
        <stop offset="0.527" stopColor="#f4f4f4" />
        <stop offset="0.69" stopColor="#d1d1d1" />
        <stop offset="1" stopColor="#c4c4c4" />
      </linearGradient>
    </defs>
    <g transform="translate(0 -15.232)">
      {/* ... (all the path data and other SVG elements from the new SVG) */}
    </g>
  </svg>
);

export const GoldLevel = ({ width = "50.371", height = "33.895" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 50.371 33.895"
    width={width}
    height={height}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="king"
          d="M48.389,54.406a1.408,1.408,0,0,1-.037.42L45.245,67.254a1.413,1.413,0,0,1-1.363,1.07l-18.642.094H6.591a1.412,1.412,0,0,1-1.37-1.071L2.113,54.872a1.41,1.41,0,0,1-.037-.431,2.968,2.968,0,1,1,2.773-.54l3.89,3.919a5.279,5.279,0,0,0,7.958-.538l6.393-8.471a2.967,2.967,0,1,1,4.238-.05l.005.007,6.347,8.494A5.3,5.3,0,0,0,37.9,59.379a5.239,5.239,0,0,0,3.729-1.545l3.914-3.915a2.966,2.966,0,1,1,2.842.486ZM44.942,72.843a1.412,1.412,0,0,0-1.412-1.412H7a1.412,1.412,0,0,0-1.412,1.412v3.39A1.412,1.412,0,0,0,7,77.645H43.529a1.412,1.412,0,0,0,1.412-1.412Z"
          transform="translate(0 -43.75)"
          fill="#cecece"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="0.007"
        y1="-0.415"
        x2="1.325"
        y2="1.816"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e9c156" />
        <stop offset="0.05" stopColor="#e8bd51" />
        <stop offset="0.29" stopColor="#e1ab39" />
        <stop offset="0.31" stopColor="#e3b141" />
        <stop offset="0.41" stopColor="#edcd66" />
        <stop offset="0.52" stopColor="#f5e384" />
        <stop offset="0.62" stopColor="#fbf299" />
        <stop offset="0.72" stopColor="#fefca6" />
        <stop offset="0.82" stopColor="#ffa" />
        <stop offset="0.85" stopColor="#fdfaa3" />
        <stop offset="0.9" stopColor="#f8ec90" />
        <stop offset="0.96" stopColor="#f0d571" />
        <stop offset="1" stopColor="#e9c156" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.245"
        y1="0.155"
        x2="0.887"
        y2="1.023"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#073c38" stopOpacity="0.412" />
        <stop offset="0.05" stopColor="#e8bd51" />
        <stop offset="0.29" stopColor="#e1ab39" />
        <stop offset="0.31" stopColor="#e3b141" />
        <stop offset="0.41" stopColor="#edcd66" />
        <stop offset="0.52" stopColor="#f5e384" />
        <stop offset="0.62" stopColor="#fbf299" />
        <stop offset="0.72" stopColor="#fefca6" />
        <stop offset="0.82" stopColor="#ffa" />
        <stop offset="0.85" stopColor="#fdfaa3" />
        <stop offset="0.9" stopColor="#f8ec90" />
        <stop offset="0.96" stopColor="#f0d571" />
        <stop offset="1" stopColor="#e9c156" />
      </linearGradient>
    </defs>
    <g
      id="Mask_Group_1071"
      data-name="Mask Group 1071"
      clipPath="url(#clip-path)"
    >
      <g id="_2" data-name="2" transform="translate(-26.179 -38.902)">
        <path
          id="Path_18897"
          data-name="Path 18897"
          d="M52.853,12.63c0,3.833-4.035,6.919-9.038,6.919a11.559,11.559,0,0,1-3.046-.4A1.856,1.856,0,0,1,39.014,19V7.732a1.857,1.857,0,0,1,1.755-2.147,11.564,11.564,0,0,1,3.049-.4C48.818,5.185,52.853,8.271,52.853,12.63ZM46.8,7.114a.928.928,0,0,0-1.049-.4,9.839,9.839,0,0,0-2.6.341,1.055,1.055,0,0,0-.744,1.02V19.27a1.051,1.051,0,0,0,.744,1.02,9.841,9.841,0,0,0,2.6.341.929.929,0,0,0,1.049-.4Z"
          fill="#14383c"
        />
        <path
          id="Path_18898"
          data-name="Path 18898"
          d="M22.367,17.708a.733.733,0,0,0,.646-.42,9.582,9.582,0,0,1,3.932-4.289,11.139,11.139,0,0,1-3.461-3.86.732.732,0,0,0-1.3.66,12.674,12.674,0,0,0,4.065,4.457,12.682,12.682,0,0,0-4.7,5.3.733.733,0,0,0,.818.25Z"
          fill="#14383c"
        />
        <path
          id="Path_18899"
          data-name="Path 18899"
          d="M32.21,7.014A1.055,1.055,0,0,0,31.4,7.985v8.982a9.866,9.866,0,0,0,3.452-.61,1.858,1.858,0,0,1,2.346,1.788v-.012a10.534,10.534,0,0,1-6.231,1.91A11.8,11.8,0,0,1,28.66,19,1.856,1.856,0,0,1,27,17.158v-8.3a1.056,1.056,0,0,0-1.619-.933A9.585,9.585,0,0,0,20.8,11.614a.734.734,0,0,0,.43,1.38,11.152,11.152,0,0,1,4.668-1.41,11.787,11.787,0,0,1-4.217,2.7A1.854,1.854,0,0,1,18,14.335V5.351A1.854,1.854,0,0,1,20.373,3.5,12.583,12.583,0,0,1,26.792,4.9a.735.735,0,0,0,.7-1.314A13.962,13.962,0,0,0,19.7,1.9,3.313,3.313,0,0,0,16.507,5.18V14.165a3.308,3.308,0,0,0,3.2,3.366,14.244,14.244,0,0,0,5.081-2.916,13.926,13.926,0,0,0,4.845,1.49,11.471,11.471,0,0,0,6.765-1.94,3.315,3.315,0,0,0,1.94-3.043V7.726a3.316,3.316,0,0,0-3.719-3.359,11.131,11.131,0,0,0-4.5,1.374.736.736,0,0,0-.313,1.02.735.735,0,0,0,1.021.313,9.668,9.668,0,0,1,3.9-1.193,1.846,1.846,0,0,1,2.082,1.886v5.821a1.847,1.847,0,0,1-1.085,1.748,9.021,9.021,0,0,0-5.926-1.67,12.731,12.731,0,0,0-4.257,1.31V7.983a9.615,9.615,0,0,0,2.781-.778A1.057,1.057,0,0,0,32.21,7.014Z"
          fill="#14383c"
        />
        <path
          id="Path_18900"
          data-name="Path 18900"
          d="M50.418,13.27a1.052,1.052,0,0,0-1.291-.851,10.643,10.643,0,0,0-7.482,6.35,1.057,1.057,0,0,0,.478,1.4,1.049,1.049,0,0,0,1.4-.479,8.567,8.567,0,0,1,6.02-5.107,1.049,1.049,0,0,0,.875-1.314Z"
          fill="#14383c"
        />
        <path
          id="Path_18901"
          data-name="Path 18901"
          d="M39.984,7.01a1.056,1.056,0,0,0-1.365-.769,11.417,11.417,0,0,0-5.24,3.839,1.058,1.058,0,1,0,1.558,1.443,9.3,9.3,0,0,1,4.269-3.131,1.053,1.053,0,0,0,.778-1.382Z"
          fill="#14383c"
        />
        <path
          id="Path_18902"
          data-name="Path 18902"
          d="M39.558,23.126a1.06,1.06,0,0,0-1.017.527,8.485,8.485,0,0,1-7.047,4.7,1.059,1.059,0,1,0,.211,2.11,10.6,10.6,0,0,0,8.831-5.89,1.057,1.057,0,0,0-.527-1.447A1.069,1.069,0,0,0,39.558,23.126Z"
          fill="#14383c"
        />
        <path
          id="Path_18903"
          data-name="Path 18903"
          d="M31.867,19.708a.732.732,0,0,0,.647-.42,9.582,9.582,0,0,1,3.932-4.289,11.14,11.14,0,0,1-3.462-3.86.733.733,0,0,0-1.3.66,12.672,12.672,0,0,0,4.066,4.457,12.682,12.682,0,0,0-4.7,5.3.733.733,0,0,0,.818.25Z"
          fill="#14383c"
        />
        <path
          id="Path_18904"
          data-name="Path 18904"
          d="M33.116,41.15a1.319,1.319,0,0,1-1.331,1.314,11.466,11.466,0,0,1-8.384-3.426,1.318,1.318,0,1,1,1.869-1.857,8.916,8.916,0,0,0,6.515,2.662,1.313,1.313,0,0,1,1.331,1.307Z"
          fill="#14383c"
        />
        <path
          id="Path_18905"
          data-name="Path 18905"
          d="M34.708,39.553a1.318,1.318,0,0,1-1.331,1.307,13.384,13.384,0,0,1-9.593-3.912,1.318,1.318,0,1,1,1.869-1.858,10.753,10.753,0,0,0,7.724,3.161,1.315,1.315,0,0,1,1.331,1.3Z"
          fill="#14383c"
        />
        <path
          id="Path_18906"
          data-name="Path 18906"
          d="M36.6,37.751a1.316,1.316,0,0,1-1.331,1.3,14.881,14.881,0,0,1-10.7-4.4,1.318,1.318,0,1,1,1.869-1.857,12.282,12.282,0,0,0,8.835,3.625,1.311,1.311,0,0,1,1.331,1.331Z"
          fill="#14383c"
        />
        <path
          id="Path_18907"
          data-name="Path 18907"
          d="M44.483,12.63c0,3.833-4.035,6.919-9.038,6.919a11.555,11.555,0,0,1-3.046-.4,1.854,1.854,0,0,1-1.646-2.149V7.732a1.857,1.857,0,0,1,1.755-2.147,11.567,11.567,0,0,1,3.049-.4C40.448,5.185,44.483,8.271,44.483,12.63ZM38.43,7.114a.927.927,0,0,0-1.049-.4,9.839,9.839,0,0,0-2.6.341,1.055,1.055,0,0,0-.744,1.02V19.27a1.051,1.051,0,0,0,.744,1.02,9.841,9.841,0,0,0,2.6.341.929.929,0,0,0,1.049-.4Z"
          fill="#14383c"
        />
        <path
          id="Path_18908"
          data-name="Path 18908"
          d="M17.759,15.127a9.454,9.454,0,0,0-3.6-4.94.731.731,0,1,0-.869,1.25,8.007,8.007,0,0,1,3.053,4.191.733.733,0,1,0,1.416-.5Z"
          fill="#14383c"
        />
        <path
          id="Path_18909"
          data-name="Path 18909"
          d="M23.957,21.273a11.14,11.14,0,0,1-3.461-3.86.732.732,0,0,0-1.3.66,12.676,12.676,0,0,0,4.065,4.457,12.682,12.682,0,0,0-4.7,5.3.732.732,0,1,0,1.272.859,11.139,11.139,0,0,1,4.169-4.711,11.14,11.14,0,0,1-3.932-4.26.731.731,0,1,0-1.3.659,12.672,12.672,0,0,0,4.065,4.457,12.67,12.67,0,0,0-4.414,4.36.731.731,0,1,0,1.269.86,11.132,11.132,0,0,1,4.9-4.835Z"
          fill="#14383c"
        />
      </g>
    </g>
  </svg>
);

export const PlatinumLevel = ({ width = "18.188", height = "14.568" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18.188 14.568"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#616468" />
        <stop offset="0.515" stopColor="#f8f8f8" />
        <stop offset="1" stopColor="#606367" />
      </linearGradient>
      <clipPath id="clip-path">
        <path
          id="Path_15950"
          data-name="Path 15950"
          d="M18.418,6.8c0,1.276-1.343,2.3-3.009,2.3A3.849,3.849,0,0,1,14.4,8.966a.618.618,0,0,1-.584.416.6.6,0,0,1-.3-.081,5.373,5.373,0,0,0,1.041-.873.134.134,0,0,1,.114-.034,3.023,3.023,0,0,0,.746.087,2.875,2.875,0,0,0,1.746-.544A1.471,1.471,0,0,0,17.794,6.8a1.464,1.464,0,0,0-.638-1.135A2.646,2.646,0,0,0,16,5.165V4.54A2.568,2.568,0,0,1,18.418,6.8ZM5.777,9.383A.6.6,0,0,0,6.1,9.289a5.259,5.259,0,0,1-.84-.672l-.154-.195-.04-.054a3.191,3.191,0,0,1-.853.114,2.868,2.868,0,0,1-1.74-.544A1.451,1.451,0,0,1,1.8,6.8a1.444,1.444,0,0,1,.672-1.135,2.687,2.687,0,0,1,1.2-.5c0-.154,0-.309,0-.47V4.533A2.579,2.579,0,0,0,1.23,6.8c0,1.276,1.343,2.3,3,2.3a3.775,3.775,0,0,0,1.014-.134.631.631,0,0,0,.531.416ZM4.32,4.842a4.87,4.87,0,0,0,1,2.955l.289.343.175.175a4.964,4.964,0,0,0,.893.672h0v.094a.806.806,0,0,0,.806.806h4.7a.806.806,0,0,0,.806-.806V8.987h0a4.863,4.863,0,0,0,2.358-4.164v-.47h.2a.631.631,0,0,0-.672-.463H4.83a.631.631,0,0,0-.672.463h.175Zm6.878,7.53.443-1.639a.121.121,0,0,0-.121-.154h-3.4A.128.128,0,0,0,8,10.726l.363,1.639a.121.121,0,0,0,.121.1h2.593A.134.134,0,0,0,11.2,12.372ZM8.289,15.1h3.029a.128.128,0,0,0,.121-.161l-.242-.86a.128.128,0,0,0-.121-.087H8.464a.121.121,0,0,0-.121.094l-.195.86a.121.121,0,0,0,.141.154Zm3.567-1.733v-.175a.282.282,0,0,0-.282-.282h-3.6a.282.282,0,0,0-.289.282v.175a.282.282,0,0,0,.289.282h3.6a.282.282,0,0,0,.282-.282Zm2.4,3.157h-1.2a.121.121,0,0,1-.121-.121h0a.86.86,0,0,0-.86-.86h-4.6a.86.86,0,0,0-.86.86h0a.121.121,0,0,1-.128.121H5.3a.766.766,0,0,0-.772.739v.06a.128.128,0,0,0,.121.128H14.9a.128.128,0,0,0,.128-.128v-.06a.772.772,0,0,0-.772-.772Z"
          transform="translate(-1.23 -3.885)"
          stroke="#707070"
          strokeWidth="1"
          fill="url(#linear-gradient)"
        />
      </clipPath>
    </defs>
    <g id="_2" data-name="2" transform="translate(-0.73 -3.382)">
      <path
        id="Path_15948"
        data-name="Path 15948"
        d="M18.418,6.8c0,1.276-1.343,2.3-3.009,2.3A3.849,3.849,0,0,1,14.4,8.966a.618.618,0,0,1-.584.416.6.6,0,0,1-.3-.081,5.373,5.373,0,0,0,1.041-.873.134.134,0,0,1,.114-.034,3.023,3.023,0,0,0,.746.087,2.875,2.875,0,0,0,1.746-.544A1.471,1.471,0,0,0,17.794,6.8a1.464,1.464,0,0,0-.638-1.135A2.646,2.646,0,0,0,16,5.165V4.54A2.568,2.568,0,0,1,18.418,6.8ZM5.777,9.383A.6.6,0,0,0,6.1,9.289a5.259,5.259,0,0,1-.84-.672l-.154-.195-.04-.054a3.191,3.191,0,0,1-.853.114,2.868,2.868,0,0,1-1.74-.544A1.451,1.451,0,0,1,1.8,6.8a1.444,1.444,0,0,1,.672-1.135,2.687,2.687,0,0,1,1.2-.5c0-.154,0-.309,0-.47V4.533A2.579,2.579,0,0,0,1.23,6.8c0,1.276,1.343,2.3,3,2.3a3.775,3.775,0,0,0,1.014-.134.631.631,0,0,0,.531.416ZM4.32,4.842a4.87,4.87,0,0,0,1,2.955l.289.343.175.175a4.964,4.964,0,0,0,.893.672h0v.094a.806.806,0,0,0,.806.806h4.7a.806.806,0,0,0,.806-.806V8.987h0a4.863,4.863,0,0,0,2.358-4.164v-.47h.2a.631.631,0,0,0-.672-.463H4.83a.631.631,0,0,0-.672.463h.175Zm6.878,7.53.443-1.639a.121.121,0,0,0-.121-.154h-3.4A.128.128,0,0,0,8,10.726l.363,1.639a.121.121,0,0,0,.121.1h2.593A.134.134,0,0,0,11.2,12.372ZM8.289,15.1h3.029a.128.128,0,0,0,.121-.161l-.242-.86a.128.128,0,0,0-.121-.087H8.464a.121.121,0,0,0-.121.094l-.195.86a.121.121,0,0,0,.141.154Zm3.567-1.733v-.175a.282.282,0,0,0-.282-.282h-3.6a.282.282,0,0,0-.289.282v.175a.282.282,0,0,0,.289.282h3.6a.282.282,0,0,0,.282-.282Zm2.4,3.157h-1.2a.121.121,0,0,1-.121-.121h0a.86.86,0,0,0-.86-.86h-4.6a.86.86,0,0,0-.86.86h0a.121.121,0,0,1-.128.121H5.3a.766.766,0,0,0-.772.739v.06a.128.128,0,0,0,.121.128H14.9a.128.128,0,0,0,.128-.128v-.06a.772.772,0,0,0-.772-.772Z"
        transform="translate(0 0)"
        stroke="#707070"
        strokeWidth="1"
        fill="url(#linear-gradient)"
      />
      <g
        id="Group_9794"
        data-name="Group 9794"
        transform="translate(1.23 3.885)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_15949"
          data-name="Path 15949"
          d="M22.429,2.648,1.748,22.4c-1.3,1.236-4.265.222-6.636-2.264l-4.85-5.085c-2.371-2.485-3.251-5.494-1.955-6.717L8.989-11.417c1.3-1.236,4.265-.222,6.636,2.264l4.85,5.071C22.832-1.6,23.725,1.412,22.429,2.648Z"
          transform="translate(3.209 1.312)"
          stroke="#707070"
          strokeWidth="1"
          fill="url(#linear-gradient)"
        />
      </g>
    </g>
  </svg>
);

export const DiamondLevel = ({ width = "16.75", height = "17.567" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16.75 17.567"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="2.604"
        y1="-0.083"
        x2="2.183"
        y2="-0.613"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#fff" />
      </linearGradient>
    </defs>
    <path
      d="M6.7,11.833H2.05l1.608-2.2.4-.554.26-.342Z"
      transform="translate(-1.852 -3.579)"
    />
    <path
      d="M8.5,11.573,6.02,8.35h5.447Z"
      transform="translate(-3.105 -3.455)"
    />
    <path
      d="M7.051,14.02l3.106,8.793L2.09,14.02Z"
      transform="translate(-1.864 -5.246)"
    />
    <path
      d="M10.47,12.006,12.988,9.29,15.5,12.006Z"
      transform="translate(-4.51 -3.752)"
    />
    <path
      d="M15.621,14.02l-2.737,7.759L10.14,14.02Z"
      transform="translate(-4.406 -5.246)"
    />
    <path
      d="M17.29,11.573,14.32,8.35h5.447Z"
      transform="translate(-5.726 -3.455)"
    />
    <path
      d="M17.526,14.02h4.981L14.42,22.813Z"
      transform="translate(-5.757 -5.246)"
    />
    <path
      d="M19.45,11.842,21.811,8.77,22.673,9.9,24.1,11.842Z"
      transform="translate(-7.346 -3.588)"
    />
    <path
      d="M14.553,4.955a.376.376,0,0,1-.26-.089A.363.363,0,0,1,14.15,4.6a.4.4,0,0,1,.144-.294L15.5,2.814a.411.411,0,0,1,.328-.144.349.349,0,0,1,.233.089.4.4,0,0,1,.062.561L14.889,4.812A.411.411,0,0,1,14.553,4.955Zm-4.448,0a.431.431,0,0,1-.342-.137L8.525,3.341a.4.4,0,0,1,.041-.561.4.4,0,0,1,.568.041l1.245,1.464a.363.363,0,0,1,.089.287.383.383,0,0,1-.137.274.4.4,0,0,1-.226.1Zm2.237-.192a.4.4,0,0,1-.4-.4V1.548a.4.4,0,0,1,.8,0V4.353a.4.4,0,0,1-.4.4Z"
      transform="translate(-3.865 -1.197)"
    />
    <g transform="translate(0 5.155)">
      <path
        d="M1.76,12.1s.91,1.2,2.258-.055c.937-.869,2.593-.212,2.593-.212L4.23,8.73Z"
        transform="translate(-1.76 -8.73)"
        opacity="0.4"
        fill="url(#linear-gradient)"
        style={{ mixBlendMode: "overlay", isolation: "isolate" }}
      />
    </g>
  </svg>
);

export const FindUsername = ({
  fillColor = "white",
  width = "36.697",
  height = "36.755",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 56.697 56.755"
  >
    <g id="Group_10" data-name="Group 10" transform="translate(-9.384 -14.562)">
      <path
        id="Path_14"
        data-name="Path 14"
        d="M33.657,14.562a24.186,24.186,0,0,0-17.172,7.16A24.274,24.274,0,0,0,33.657,63.169a24.107,24.107,0,0,0,14.264-4.6l.58-.408.118.117L60.726,70.388a3.129,3.129,0,1,0,4.424-4.426L52.925,53.739l.406-.582A24.35,24.35,0,0,0,33.657,14.562Zm14.438,38.71a20.4,20.4,0,1,1,0-28.814A20.3,20.3,0,0,1,48.095,53.272Z"
        fill={fillColor}
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M49.316,56.835h0c0-.059-.582-7.277-9.722-7.277-9.256,0-9.721,7.277-9.721,7.334v2.1a1.1,1.1,0,0,0,1.106,1.106H48.268a1.106,1.106,0,0,0,1.107-1.106Z"
        transform="translate(-5.938 -10.141)"
        fill={fillColor}
      />
      <path
        id="Path_16"
        data-name="Path 16"
        d="M41.26,42.731a5.1,5.1,0,0,0,4.832-5.356,5.1,5.1,0,0,0-4.832-5.357,5.1,5.1,0,0,0-4.832,5.357A5.1,5.1,0,0,0,41.26,42.731Z"
        transform="translate(-7.836 -5.058)"
        fill={fillColor}
      />
    </g>
  </svg>
);

export const ProfileIcon = ({ fillColor = "white" }) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80%"
      height="80%"
      viewBox="0 0 30.506 40.105"
    >
      <g
        id="uni_avtar-02"
        data-name="uni avtar-02"
        transform="translate(-59.85 -13.778)"
      >
        <path
          id="Path_25"
          data-name="Path 25"
          d="M154.587,33.393c4.459,0,8.074-4.391,8.074-9.807s-1.187-9.807-8.074-9.807-8.075,4.391-8.075,9.807S150.127,33.393,154.587,33.393Z"
          transform="translate(-79.484 0)"
          fill={fillColor}
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M59.852,429.51c0-.33,0-.093,0,0Z"
          transform="translate(0 -381.143)"
          fill={fillColor}
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.182c0-.09,0-.627,0,0Z"
          transform="translate(-337.769 -382.557)"
          fill={fillColor}
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M90.352,273.506c-.15-9.436-1.382-12.124-10.812-13.826a5.653,5.653,0,0,1-4.421,1.691A5.653,5.653,0,0,1,70.7,259.68c-9.327,1.683-10.634,4.332-10.806,13.52-.014.75-.021.79-.023.7,0,.163,0,.465,0,.991,0,0,2.245,4.526,15.25,4.526s15.25-4.526,15.25-4.526c0-.338,0-.573,0-.733A6.063,6.063,0,0,1,90.352,273.506Z"
          transform="translate(-0.016 -225.535)"
          fill={fillColor}
        />
      </g>
    </svg>
  </>
);

export const EventsGroupIcon = ({
  width = "42.4",
  height = "47.112",
  fillColor = "black",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42.4 47.112"
    >
      <path
        id="event-crowd"
        d="M165.689,132.445H132.711A4.725,4.725,0,0,1,128,127.734V94.756a4.725,4.725,0,0,1,4.711-4.711h9.846a7.039,7.039,0,0,1,13.285,0h9.846a4.725,4.725,0,0,1,4.711,4.711v32.978A4.725,4.725,0,0,1,165.689,132.445Zm-32.978-11.98v4.914h11.8v-4.421c0-1.67,1.1-4.6,4.683-6.819a19.485,19.485,0,0,0-4.169-.551C140.925,113.588,132.711,115.888,132.711,120.464Zm7.038-13.926a4.717,4.717,0,1,0,4.725-4.716A4.714,4.714,0,0,0,139.75,106.538ZM149.2,90.044a2.356,2.356,0,1,0,2.356,2.356A2.363,2.363,0,0,0,149.2,90.044Zm7.05,14.11a4.723,4.723,0,1,0,4.692,4.723A4.718,4.718,0,0,0,156.25,104.154Zm9.439,16.972c0-3.458-6.3-5.2-9.436-5.2s-9.439,1.738-9.439,5.2v4.252h18.875Z"
        transform="translate(-128 -85.333)"
        fill={fillColor}
      />
    </svg>
  </>
);

export const CalendarIcon = ({
  fillColor = "white",
  width = "70%",
  height = "70%",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60.691 61.756"
    >
      <g
        id="calendar-01_3_"
        data-name="calendar-01 (3)"
        transform="translate(-3.369 -1.5)"
      >
        <path
          id="Path_11"
          data-name="Path 11"
          d="M59.354,11.56v2.86a5.159,5.159,0,0,1-10.319,0V11.56H18.4v2.86a5.159,5.159,0,1,1-10.318,0V11.56H3.369v51.7H64.061v-51.7H59.354ZM15.211,60H6.624v-7.04h8.586V60Zm0-8.955H6.624V43h8.586v8.051Zm0-9.964H6.624V33.029h8.586v8.052Zm0-9.967H6.624V23.05h8.586v8.065ZM26.736,60h-9.61v-7.04h9.61Zm0-8.955h-9.61V43h9.61Zm0-9.964h-9.61V33.029h9.61Zm0-9.967h-9.61V23.05h9.61ZM38.259,60h-9.61v-7.04h9.61V60Zm0-8.955h-9.61V43h9.61v8.051Zm0-9.964h-9.61V33.029h9.61v8.052Zm0-9.967h-9.61V23.05h9.61v8.065ZM49.782,60H40.174v-7.04h9.608Zm0-8.955H40.174V43h9.608Zm0-9.964H40.174V33.029h9.608Zm0-9.967H40.174V23.05h9.608ZM60.806,60H51.7v-7.04h9.11V60Zm0-8.955H51.7V43h9.11v8.051Zm0-9.964H51.7V33.029h9.11v8.052Zm0-9.967H51.7V23.05h9.11v8.065ZM16.891,5.154V14.37a3.654,3.654,0,1,1-7.308,0V5.154a3.654,3.654,0,0,1,7.308,0Zm40.957,0V14.37a3.654,3.654,0,1,1-7.308,0V5.154a3.654,3.654,0,0,1,7.308,0Z"
          fill={fillColor}
        />
      </g>
    </svg>
  </>
);
export const AvatarThumbNailIcon = ({
  className,
  width="40",
  height="40",
  fillColor = "#fefefe",
}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fillColor}
      viewBox="0 0 14.036 18.454"
    >
      <g
        id="uni_avtar-02"
        data-name="uni avtar-02"
        transform="translate(-59.851 -13.778)"
      >
        <path
          id="Path_25"
          data-name="Path 25"
          d="M150.227,22.8c2.052,0,3.715-2.02,3.715-4.513s-.546-4.513-3.715-4.513-3.715,2.02-3.715,4.513S148.175,22.8,150.227,22.8Z"
          transform="translate(-83.359 0)"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M59.851,429.419c0-.152,0-.043,0,0Z"
          transform="translate(0 -399.725)"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.02c0-.042,0-.289,0,0Z"
          transform="translate(-354.236 -401.208)"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M73.895,266.042c-.069-4.342-.636-5.579-4.975-6.362a3.048,3.048,0,0,1-4.069,0c-4.292.775-4.893,1.993-4.972,6.221-.006.345-.009.363-.011.323,0,.075,0,.214,0,.456,0,0,1.033,2.083,7.017,2.083S73.9,266.68,73.9,266.68c0-.156,0-.264,0-.337A2.786,2.786,0,0,1,73.895,266.042Z"
          transform="translate(-0.017 -236.53)"
        />
      </g>
    </svg>
);
export const AvatarIcon = ({
  className,
  width = "112.701",
  height = "148.173",
  fillColor = "black",
}) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <g
        id="profile-avatar"
        data-name="uni avtar-02"
        transform="translate(-59.85 -13.778)"
      >
        <path
          fill={fillColor}
          id="Path_25"
          data-name="Path 25"
          d="M176.344,86.244c16.475,0,29.83-16.222,29.83-36.233s-4.385-36.233-29.83-36.233S146.512,30,146.512,50.011,159.868,86.244,176.344,86.244Z"
          transform="translate(-60.143 0)"
        />
        <path
          fill={fillColor}
          id="Path_26"
          data-name="Path 26"
          d="M59.856,429.968c-.005-1.22-.01-.344,0,0Z"
          transform="translate(0 -288.4)"
        />
        <path
          fill={fillColor}
          id="Path_27"
          data-name="Path 27"
          d="M428.122,431.99c.016-.334.005-2.317,0,0Z"
          transform="translate(-255.58 -289.469)"
        />
        <path
          fill={fillColor}
          id="Path_28"
          data-name="Path 28"
          d="M172.491,310.76c-.553-34.859-5.106-44.793-39.944-51.08,0,0-4.9,6.249-16.335,6.249s-16.335-6.249-16.335-6.249c-34.459,6.219-39.288,16-39.924,49.948-.052,2.772-.076,2.917-.085,2.6,0,.6,0,1.718,0,3.662,0,0,8.294,16.721,56.34,16.721s56.34-16.721,56.34-16.721c0-1.249,0-2.118,0-2.709A22.4,22.4,0,0,1,172.491,310.76Z"
          transform="translate(-0.012 -170.656)"
        />
      </g>
    </svg>
  </>
);
export const PinDropIcon = ({
  width = "100px",
  height = "100px",
  fillColor,
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    height={height}
    width={width}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="717.7 346.815 100 100"
    enableBackground="new 717.7 346.815 100 100"
  >
    <g>
      <path
        d="M767.108,349.808c-0.057,0-0.111-0.002-0.167-0.005c-0.056,0.003-0.111,0.005-0.168,0.005
		c-13.473,0.212-24.434,11.349-24.433,24.826c0,15.357,15.292,30.892,23.035,37.772c0.008,0.009,0.017,0.016,0.026,0.022
		c0.554,0.494,1.072,0.945,1.54,1.346c6.996-5.979,24.602-22.664,24.602-39.141C791.542,361.157,780.582,350.021,767.108,349.808z
		 M767.174,385.016c-7.11,0-12.874-5.764-12.874-12.874c0-7.11,5.764-12.875,12.874-12.875c7.11,0,12.874,5.765,12.874,12.875
		C780.048,379.252,774.285,385.016,767.174,385.016z"
      />
      <path
        d="M767.288,437.009c-14.747,0-29.677-4.23-29.677-12.313c0-5.673,7.351-9.444,16.83-11.192
		c1.833,1.844,3.568,3.468,5.091,4.824c-10.324,1.153-16.389,4.422-16.389,6.365c0,2.392,9.167,6.784,24.146,6.784
		s24.147-4.393,24.147-6.784c0-1.983-6.32-5.335-17.02-6.423c1.535-1.372,3.285-3.013,5.133-4.874
		c9.762,1.699,17.419,5.51,17.419,11.3C796.967,432.778,782.036,437.009,767.288,437.009z"
      />
    </g>
  </svg>
);
