import { validatedPasswordValue } from "../../constants/validate.js";
import {
  SettingsNameSectionForm_LOCAL_STORAGE_BASE_KEYS,
  SettingsContactInfoSectionForm_LOCAL_STORAGE_BASE_KEYS,
  SettingsPasswordSectionForm_LOCAL_STORAGE_BASE_KEYS,
} from "../../constants/keyNames.js";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
export const handleUserNameValueChange = (
  value,
  setUserNameValue,
  currentContext,
  validateUserNameValue,
  setUserNameValidationRules,
  setUserNameToolTipVisible,
  userNameValueError,
  setUserNameValueError
) => {
  setUserNameValue(value);
  // Generate a unique local storage key based on the current context
  const key = getStorageKey("userNameValue", currentContext);
  localStorage.setItem(key, value);
  const userNameValidationResults = validateUserNameValue(value);
  setUserNameValidationRules(userNameValidationResults); // Update validation rules state
  setUserNameToolTipVisible(
    !userNameValidationResults.every((rule) => rule.isValid)
  ); // Update tooltip visibility
  if (userNameValueError) {
    setUserNameValueError("");
  }
};
export const handlefirstNameValueChange = (
  value,
  currentContext,
  setFirstNameValue,
  firstNameValueError,
  setFirstNameValueError
) => {
  setFirstNameValue(value);
  // Generate a unique local storage key based on the current context
  const key = getStorageKey("firstNameValue", currentContext);
  localStorage.setItem(key, value);
  if (firstNameValueError) {
    setFirstNameValueError("");
  }
};

// Function to handle changes in the lastName field
export const handleLastNameChange = (
  value,
  lastNameValueError,
  currentContext,
  setLastNameValue,
  getStorageKey,
  setLastNameValueError
) => {
  setLastNameValue(value);
  // Generate a unique local storage key based on the current context
  const key = getStorageKey("lastNameValue", currentContext);
  localStorage.setItem(key, value);
  if (lastNameValueError) {
    setLastNameValueError("");
  }
};
export const handleEmailChange = (
  value,
  setEmailValue,
  currentContext,
  getStorageKey,
  emailValueError,
  setEmailValueError
) => {
  setEmailValue(value);
  const key = getStorageKey("emailValue", currentContext);
  localStorage.setItem(key, value);
  if (emailValueError) {
    setEmailValueError("");
  }
};
export const handlePhoneInputChange = (
  value,
  setPhoneNumberValue,
  currentContext,
  getStorageKey,
  phoneNumberValueError,
  setPhoneNumberValueError
) => {
  setPhoneNumberValue(value);
  const Key = getStorageKey("phoneNumberValue", currentContext);
  localStorage.setItem(Key, value);
  if (phoneNumberValueError) {
    setPhoneNumberValueError("");
  }
};
export const handleCurrentPasswordValueChange = (
  value,
  setCurrentPasswordValue,
  currentContext,
  getStorageKey,
  validatePasswordValue,
  setPasswordValidationRules,
  setCurrentPasswordToolTipVisible,
  currentPasswordValueError,
  setCurrentPasswordValueError
) => {
  setCurrentPasswordValue(value);
  const key = getStorageKey("currentPasswordValue", currentContext);
  localStorage.setItem(key, value);
  const currentPasswordValidationResults = validatePasswordValue(value);
  setPasswordValidationRules(currentPasswordValidationResults);
  setCurrentPasswordToolTipVisible(
    !currentPasswordValidationResults.every((rule) => rule.isValid)
  );
  if (currentPasswordValueError) {
    setCurrentPasswordValueError("");
  }
};
/// start here
export const handleNewPasswordChange = (
  value,
  newPasswordValueError,
  currentContext,
  setNewPasswordValue,
  validatePasswordValue,
  setNewPasswordValidationRules,
  setNewPasswordToolTipVisible,
  setNewPasswordValueError
) => {
  setNewPasswordValue(value);
  const Key = getStorageKey("newPasswordValue", currentContext);
  localStorage.setItem(Key, value);
  const validationResults = validatePasswordValue(value);
  setNewPasswordValidationRules(validationResults);
  setNewPasswordToolTipVisible(
    !validationResults.every((rule) => rule.isValid)
  );
  if (newPasswordValueError) {
    setNewPasswordValueError("");
  }
};
export const onNewPasswordValueFocused = (
  newPasswordValue,
  validatePasswordValue,
  setNewPasswordValueFocused,
  setNewPasswordValidationRules,
  setNewPasswordToolTipVisible
) => {
  setNewPasswordValueFocused("newPasswordValue");
  setNewPasswordValueFocused(true);
  const newPasswordValidationResults = validatePasswordValue(
    newPasswordValue
  ).map((rule) => ({
    ...rule,
    isValid: rule.isValid,
  }));
  setNewPasswordValidationRules(newPasswordValidationResults);
  setNewPasswordToolTipVisible(
    newPasswordValidationResults.some((rule) => !rule.isValid)
  );
};
export const onNewPasswordValueBlur = (
  setNewPasswordValueFocused,
  setNewPasswordToolTipVisible
) => {
  setNewPasswordValueFocused(false);
  setNewPasswordToolTipVisible(false);
};
export const handleConfirmPasswordChange = (
  value,
  confirmPasswordValueError,
  currentContext,
  setConfirmPasswordValue,
  setConfirmPasswordValueError
) => {
  setConfirmPasswordValue(value);
  const confirmPasswordKey = getStorageKey(
    "confirmPasswordValue",
    currentContext
  );
  localStorage.setItem(confirmPasswordKey, value);
  if (confirmPasswordValueError) {
    setConfirmPasswordValueError("");
  }
};
// Adjust onFocus and onBlur for the password input
export const onCurrentPasswordValueFocused = (
  currentPasswordValue,
  validatePasswordValue,
  setCurrentPasswordValueFocused,
  setPasswordValidationRules,
  setCurrentPasswordToolTipVisible
) => {
  setCurrentPasswordValueFocused("currentPasswordValue");
  setCurrentPasswordValueFocused(true);
  // Validate the current password value
  const currentPasswordRules = validatePasswordValue(currentPasswordValue).map(
    (rule) => ({ ...rule, isValid: rule.isValid })
  );
  setPasswordValidationRules(currentPasswordRules);
  // Show the tooltip if any rule is not valid
  setCurrentPasswordToolTipVisible(
    currentPasswordRules.some((rule) => !rule.isValid)
  );
};
export const onCurrentPasswordValueBlur = (
  setCurrentPasswordValueFocused,
  setCurrentPasswordToolTipVisible
) => {
  setCurrentPasswordValueFocused(false);
  setCurrentPasswordToolTipVisible(false);
};
export const onUserNameValueFocused = (
  userNameValue,
  validateUserNameValue,
  setUserNameValueFocused,
  setUserNameValidationRules,
  setUserNameToolTipVisible
) => {
  setUserNameValueFocused("userNameValue");
  setUserNameValueFocused(true);
  const userNameRules = validateUserNameValue(userNameValue).map((rule) => ({
    ...rule,
    isValid: rule.isValid,
  }));
  setUserNameValidationRules(userNameRules);
  setUserNameToolTipVisible(userNameRules.some((rule) => !rule.isValid));
};
export const onUserNameValueBlur = (
  setUserNameValueFocused,
  setUserNameToolTipVisible
) => {
  setUserNameValueFocused(false);
  setUserNameToolTipVisible(false);
};
export const handleOriginalPasswordChange = (
  value,
  originalPasswordValueError,
  currentContext,
  setOriginalPasswordValue,
  validatePasswordValue,
  setOriginalPasswordValidationRules,
  setOriginalPasswordToolTipVisible,
  setOriginalPasswordValueError
) => {
  setOriginalPasswordValue(value);
  const Key = getStorageKey("originalPasswordValue", currentContext);
  localStorage.setItem(Key, value);
  const validationResults = validatePasswordValue(value);
  setOriginalPasswordValidationRules(validationResults);
  setOriginalPasswordToolTipVisible(
    !validationResults.every((rule) => rule.isValid)
  );
  if (originalPasswordValueError) {
    setOriginalPasswordValueError("");
  }
};
export const onOriginalPasswordValueFocused = (
  originalPasswordValue,
  validatePasswordValue,
  setOriginalPasswordValueFocused,
  setUserNameValueFocused,
  setOriginalPasswordValidationRules,
  setOriginalPasswordToolTipVisible
) => {
  setUserNameValueFocused("originalPasswordValue");
  setOriginalPasswordValueFocused(true);
  const originalPasswordValidationResults = validatePasswordValue(
    originalPasswordValue
  ).map((rule) => ({
    ...rule,
    isValid: rule.isValid,
  }));
  setOriginalPasswordValidationRules(originalPasswordValidationResults);
  setOriginalPasswordToolTipVisible(
    originalPasswordValidationResults.some((rule) => !rule.isValid)
  );
};
export const onOriginalPasswordValueBlur = (
  setOriginalPasswordValueFocused,
  setOriginalPasswordToolTipVisible
) => {
  setOriginalPasswordValueFocused(false);
  setOriginalPasswordToolTipVisible(false);
};

export const clearSettingsNameSectionLocalStorage = (
  currentContext,
  setFirstNameValue,
  setLastNameValue,
  setUserNameValue
) => {
  SettingsNameSectionForm_LOCAL_STORAGE_BASE_KEYS.forEach((baseKey) => {
    const key = getStorageKey(baseKey, currentContext);
    localStorage.removeItem(key);
  });
  // Reset the states
  setFirstNameValue("");
  setLastNameValue("");
  setUserNameValue("");
};
export const clearSettingsContactInfoSectionLocalStorage = (
  currentContext,
  setEmailValue,
  setPhoneNumberValue
) => {
  SettingsContactInfoSectionForm_LOCAL_STORAGE_BASE_KEYS.forEach((baseKey) => {
    const key = getStorageKey(baseKey, currentContext);
    localStorage.removeItem(key);
  });
  setEmailValue("");
  setPhoneNumberValue("");
};
export const clearSettingsPasswordSectionSectionLocalStorage = (
  currentContext,
  setOriginalPasswordValue,
  setNewPasswordValue
) => {
  SettingsPasswordSectionForm_LOCAL_STORAGE_BASE_KEYS.forEach((baseKey) => {
    const key = getStorageKey(baseKey, currentContext);
    localStorage.removeItem(key);
  });
  setOriginalPasswordValue("");
  setNewPasswordValue("");
};
