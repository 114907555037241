import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";

export const eventTitleWatcher = (
  value,
  setEventTitleValue,
  currentContext
) => {
  setEventTitleValue(value);
  const EventTitleKey = getStorageKey("eventTitleValue", currentContext);
  localStorage.setItem(EventTitleKey, value);
};

export const descriptionValueWatcher = (
  value,
  setEventDescriptionValue,
  currentContext
) => {
  setEventDescriptionValue(value);
  const DescriptionKey = getStorageKey("eventDescriptionValue", currentContext);
  localStorage.setItem(DescriptionKey, value);
};

export const eventCityWatcher = (
    value, // Changed from e.target.value to value
    setEventCityValue,
    currentContext,// Moved currentContext down
    eventCityError, // Moved requiredFieldsError up
    setEventCityError,
) => {
    setEventCityValue(value);
    const EventCityKey = getStorageKey("eventCityValue", currentContext);
    localStorage.setItem(EventCityKey, value);
    if (eventCityError) {
      setEventCityError("");
    }
};

export const clearEventCity = (setEventCityValue, currentContext) => {
  setEventCityValue("");
  const eventCityKey = getStorageKey("eventCityValue", currentContext);
  localStorage.removeItem(eventCityKey);
};

export const clearEventTitle = (setEventTitleValue, currentContext) => {
  setEventTitleValue("");
  const EventTitleKey = getStorageKey("eventTitleValue", currentContext);
  localStorage.removeItem(EventTitleKey);
};

export const clearEventDescription = (
  setEventDescriptionValue,
  currentContext
) => {
  setEventDescriptionValue("");
  const DescriptionKey = getStorageKey("eventDescriptionValue", currentContext);
  localStorage.removeItem(DescriptionKey);
};

