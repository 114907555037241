import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";

export const groupTitleWatcher = (
  value,
  setGroupTitleValue,
  currentContext
) => {
  setGroupTitleValue(value);
  const GroupTitleKey = getStorageKey("groupTitleValue", currentContext);
  localStorage.setItem(GroupTitleKey, value);
};

export const descriptionValueWatcher = (
  value,
  setGroupDescriptionValue,
  currentContext
) => {
  setGroupDescriptionValue(value);
  const DescriptionKey = getStorageKey("groupDescriptionValue", currentContext);
  localStorage.setItem(DescriptionKey, value);
};

export const groupCityWatcher = (
    value, // Changed from e.target.value to value
    setGroupCityValue,
    currentContext,// Moved currentContext down
    groupCityError, // Moved requiredFieldsError up
    setGroupCityError,
) => {
    setGroupCityValue(value);
    const GroupCityKey = getStorageKey("groupCityValue", currentContext);
    localStorage.setItem(GroupCityKey, value);
    if (groupCityError) {
      setGroupCityError("");
    }
};

export const clearGroupCity = (setGroupCityValue, currentContext) => {
  setGroupCityValue("");
  const groupCityKey = getStorageKey("groupCityValue", currentContext);
  localStorage.removeItem(groupCityKey);
};

export const clearGroupTitle = (setGroupTitleValue, currentContext) => {
  setGroupTitleValue("");
  const GroupTitleKey = getStorageKey("groupTitleValue", currentContext);
  localStorage.removeItem(GroupTitleKey);
};

export const clearGroupDescription = (
  setGroupDescriptionValue,
  currentContext
) => {
  setGroupDescriptionValue("");
  const DescriptionKey = getStorageKey("groupDescriptionValue", currentContext);
  localStorage.removeItem(DescriptionKey);
};

