import thumbnail1 from "../../assets/thumbnails/thumbnail_1.jpg";
import thumbnail5 from "../../assets/thumbnails/thumbnail_5.jpg";
import thumbnail7 from "../../assets/thumbnails/thumbnail_7.jpg";
import thumbnail8 from "../../assets/thumbnails/thumbnail_8.jpg";
import thumbnail9 from "../../assets/thumbnails/thumbnail_9.jpg";
import thumbnail10 from "../../assets/thumbnails/thumbnail_10.jpg";
import thumbnail11 from "../../assets/thumbnails/thumbnail_11.jpg";
import thumbnail12 from "../../assets/thumbnails/thumbnail_12.jpg";
import thumbnail13 from "../../assets/thumbnails/thumbnail_13.jpg";
import thumbnail14 from "../../assets/thumbnails/thumbnail_14.jpg";


export const users = [
  {
    firstName: 'Laura',
    lastName: 'Phipps',
    thumbnail: thumbnail1,
    type: 'profile'
  },
  {
    firstName: 'Michiala',
    lastName: 'Johnson',
    thumbnail: thumbnail5,
    type: 'profile'
  },
  {
    firstName: 'Guy',
    lastName: 'Richie',
    thumbnail: thumbnail14,
    type: 'profile'
  },
  {
    firstName: 'New Chapter',
    lastName: 'Fun',
    thumbnail: thumbnail7,
    type: 'community'
  },
  {
    firstName: 'White Party',
    lastName: 'Models',
    thumbnail: thumbnail8,
    type: 'community'
  },
  {
    firstName: 'Beaching Time',
    lastName: 'Couple',
    thumbnail: thumbnail9,
    type: 'profile'
  },
  {
    firstName: 'Travel Partner',
    lastName: 'Team',
    thumbnail: thumbnail10,
    type: 'community'
  },
  {
    firstName: 'Horseback Journey',
    lastName: 'Group',
    thumbnail: thumbnail11,
    type: 'community'
  },
  {
    firstName: 'Nature Partner',
    lastName: 'Couple',
    thumbnail: thumbnail12,
    type: 'profile'
  },
  {
    firstName: 'Photo Booth Fun',
    lastName: 'Photo',
    thumbnail: thumbnail13,
    type: 'community'
  }
]