import { convertToSlug } from "../../../utils/stringUtils.js";
import CatIcons from "../../icons/categoryIcons.jsx";
import "./catVertical.scss";

// this component is used to display parent categories but can be easily used for the child (code extensiblity philosphy) for the future
export const CategoryNameFormatSearch = ({
  categoryName,
  categoryId,
  handleCategoryClick,
}) => {
  // This component takes itemName as a prop and uses it to
  //  display the corresponding icon and formatted name.
  // This function to get the width for every service name to always two lines
  function getWidth(categoryname) {
    let result = "";
    switch (
      categoryname // First, check for specific names
    ) {
      case "Graphic & Design":
        result = "97px";
        break;
      case "Tech & Programming":
        result = "108px";
        break;
      case "Book-ebook Publishing":
        result = "123px";
        break;
      case "Podcasting Services":
        result = "112px";
        break;
      case "Math & Computers":
        result = "100px";
        break;
      case "Food Services":
        result = "79px";
        break;
      case "Protective Services":
        result = "102px";
        break;
      case "Hospitality & Personal Care":
        result = "40%";
        break;
      case "Wellness Services":
        result = "94px";
        break;
      case "Legal Services":
        result = "74px";
        break;
      // case "Babies & Kids":
      //   result = "75px";
      //   break;
    }
    // If result is still empty, check based on length
    if (!result) {
      switch (categoryname.length) {
        case 13:
          result = "76px";
          break;
        case 14:
          result = "80px";
          break;
        case 16:
          result = "89px";
          break;
        case 18:
          result = "96px";
          break;
        case 19:
          result = "100px";
          break;
        case 15:
          result = "89px";
          break;
        case 12:
          result = "86px";
          break;
        case 25:
          result = "145px";
          break;
        case 21:
          result = "118px";
          break;
        case 20:
          result = "100px";
          break;
        case 26:
          result = "144px";
          break;
        case 24:
          result = "130px";
          break;
        case 28:
          result = "40%";
          break;
        case 17:
          result = "100px";
          break;
        case 30:
          result = "40%";
          break;
        case 22:
          result = "121px";
          break;
        // default:
        //   result = "40%";
      }
    }
    return result;
  }

  function formatName(categoryname) {
    if (categoryname === "Legal Services") return "Legal\nServices";
    return categoryname.replace(" & ", "\xa0&\n");
  }

  return (
    <div
      className="CategoryService"
      onClick={() => {
        console.log(
          "CategoryItem onClick Triggered for:",
          categoryName,
          "ID:",
          categoryId
        );
        handleCategoryClick(categoryName, categoryId);
      }}
    >
      <div className="center-menu-link">
        {CatIcons[convertToSlug(categoryName)]}
        <span style={{ width: getWidth(categoryName) }}>
          {formatName(categoryName)}
        </span>
      </div>
    </div>
  );
};
