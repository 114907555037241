export const getHighestPostPeriod = (posts) => {
    let totalDayPost = 0, totalWeekPost = 0, totalMonthPost = 0, totalYearPost = 0;
  
    // Sum up all the post counts per period across all posts
    posts.forEach(post => {
      totalDayPost += post.totalDayPost;
      totalWeekPost += post.totalWeekPost;
      totalMonthPost += post.totalMonthPost;
      totalYearPost += post.totalYearPost;
    });
  
    const periods = [
      { name: 'day', count: totalDayPost },
      { name: 'week', count: totalWeekPost },
      { name: 'month', count: totalMonthPost },
      { name: 'year', count: totalYearPost }
    ];
  
    // Sort by count descending, then by the period ascending for ties
    periods.sort((a, b) => b.count - a.count || a.name.length - b.name.length);
  
    // Return both name and count for use in your UI
    return periods[0].count > 0 ? periods[0] : null;
  };
  