import _endsWith from "lodash/endsWith.js";
// types
import AUTH from "../types/authTypes.js";
import SERVICE from "../types/serviceTypes.js";

import { INITIAL_PLAIN_DATA_REQUEST } from "../../utils/authTokenReduxUtils.js";
import { REDUX_PROMISE_MIDDLEWARE_SUFFIXES } from "../../constants/index.js";

const { LOADING, SUCCESS, ERROR } = REDUX_PROMISE_MIDDLEWARE_SUFFIXES;
const INIT_STATE = {
  authInfo: INITIAL_PLAIN_DATA_REQUEST
};

// const { PREFIXES } = TYPES;
const { AUTH_SERVICE } = SERVICE;

function authReducer(state = INIT_STATE, action = {}) {
  const { type } = action;
  if (type === AUTH.UN_AUTHORIZE) {
    return INIT_STATE;
  }
  if (type === `${AUTH_SERVICE.LOGIN}_${SUCCESS}`) {
    return {
      ...state,
      authInfo: {
        data: action.payload,
        loading: false
      }
    };
  }
  if (type === `${AUTH_SERVICE.LOGIN}_${LOADING}`) {
    return {
      ...state,
      authInfo: {
        loading: true
      }
    };
  }
  if (type === `${AUTH_SERVICE.LOGIN}_${ERROR}`) {
    const { code, message } = action.payload;
    return {
      ...state,
      authInfo: {
        error: {
          code,
          message
        },
        loading: false
      }
    };
  }
  return state;
}

export default authReducer;
