// SolidColorButton.jsx
import React, { useState, useEffect, forwardRef } from "react";
import "./solidColorBtn.scss";

const SolidColorButtonRef = forwardRef(
  (
    {
      className = "",
      onClick,
      disabled = false,
      isSubmit = false,
      solidColorBtnImage = null,
      solidColorBtnText = "",
      solidColorBtnTextFontSize,
      solidColorBtnWidth,
      solidColorBtnHeight,
      solidColorBtnFontFamily,
      solidColorBtnFontWeight,
      solidColorBtnFontColor,
      solidColorBtnBorderRadius,
      solidColorBtnBoxShadow,
      solidColorBtnBorderColor,
      marginLeft = "0px",
      marginRight = "0px",
      animationActive = false,
      extraStyles = {},
      animations = {},
    },
    ref
  ) => {
    const [localAnimations, setLocalAnimations] = useState({
      glow: true,
    });
    console.log("SolidColorButton is rendering.");

    useEffect(() => {
      console.log("animations prop changed:", animations);
      setLocalAnimations((prev) => ({ ...prev, ...animations }));
    }, []);

    const handleGlowEffect = () => {
      console.log("handleGlowEffect is called");
      setLocalAnimations({ glow: true });
      setTimeout(() => {
        console.log("Turning off glow after timeout");
        setLocalAnimations((prev) => ({ ...prev, glow: false }));
      }, 2000);
    };

    return (
      <button
        ref={ref}
        className={`solid-color-btn position-relative ${className} 
            ${
              localAnimations.glow
                ? "glow-on-rainbow-animation glow-on-press show-animation play-animation"
                : ""
            }`}
        onClick={(e) => {
          handleGlowEffect();
          if (onClick) onClick(e); // Pass the event object 'e' to the onClick handler
        }}
        disabled={disabled}
        type={isSubmit ? "submit" : "button"}
        style={{
          ...extraStyles,
          width: solidColorBtnWidth,
          height: solidColorBtnHeight,
          borderRadius: solidColorBtnBorderRadius,
          borderColor: solidColorBtnBorderColor,
          marginLeft: marginLeft,
          marginRight: marginRight,
          boxShadow: solidColorBtnBoxShadow,
          animation: animationActive
            ? "active 0.9s alternate infinite"
            : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {solidColorBtnImage}
          <span
            style={{
              marginLeft: solidColorBtnImage ? "5px" : "0",
              fontSize: solidColorBtnTextFontSize,
              fontFamily: solidColorBtnFontFamily,
              fontWeight: solidColorBtnFontWeight,
              color: solidColorBtnFontColor,
            }}
          >
            {solidColorBtnText}
          </span>
        </div>
      </button>
    );
  }
);

export default SolidColorButtonRef;
