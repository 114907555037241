import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CatIcons from "../../icons/categoryIcons";
import { convertToSlug } from "../../../utils/stringUtils";
import { WhiteBtn } from "../../buttons";
import GlobalThreeLineAnimation from "../../../globalViews/globalThreeLineAnimation";
import "./catmenu.scss";

const SubCategoryPopup = ({
  subCatMenuData,
  setSubCatMenuData,
  onSubcategorySelect,
  selectedSubcategory,
  onCancel,
}) => {
  console.log("Selected Subcategory: ", selectedSubcategory);
  const navigate = useNavigate();
  console.log("SubCategoryPopup Props:", subCatMenuData);
  const {
    subCatMenuOpen,
    data = { subcategories: [] },
    categoryName,
  } = subCatMenuData;
  const alphabet = Array.from(Array(26)).map((_, i) =>
    String.fromCharCode(i + 65)
  );
  // console.log(alphabet); // This will log: ['A', 'B', 'C', ..., 'Z']
  //   const anphabet = data.subcategories ? data.subcategories.filter((subcategory) => subcategory.char) : [];
  //   console.log("Alphabet array:", anphabet);
  const [alphabetActive, setAlphabetActive] = useState("");
  const [catMenuZIndex, setCatMenuZIndex] = useState(false);
  const iconKey = convertToSlug(categoryName);
  const CategoryIcon = CatIcons[iconKey];
  const subcategories = data.subcategories || []; // Use subcategories from data
  const ref = useRef({});
  const refCategorylist = useRef();
  // Initialize refSubcategories as an object with refs
  const refSubcategories = useRef({});
  // this will be used to pass the category to the input
  // instead of an input we will just show what is clicked
  //   user can remove subcategory and it will reset the button
  const handleSubcategoryClick = (subcategoryId) => {
    // Find the subcategory from the list using its ID
    const selectedSubcategory = subcategories.find(
      (subcat) => subcat.id === subcategoryId
    );
    console.log("Selected Subcategory:", selectedSubcategory);
    if (selectedSubcategory) {
      // Update the parent component's state with the selected subcategory
      if (typeof onSubcategorySelect === "function") {
        onSubcategorySelect(selectedSubcategory);
      } else {
        console.warn("onSubcategorySelect is not a function");
      }
    } else {
      console.warn("Selected subcategory not found");
    }
    // Close the popup
    setSubCatMenuData({ subCatMenuOpen: false, data: { subcategories: [] } });
  };

  useEffect(() => {
    const newRefs = alphabet.reduce((acc, char) => {
      acc[char] = React.createRef();
      return acc;
    }, {});

    Object.assign(refSubcategories.current, newRefs);
  }, [alphabet, subcategories]);

  // Scroll function for the index letter clicked it will scroll the alpha marker for the subcategory list section to the top under the header if it can scroll that high
  const handleAlphabetClick = (char) => {
    setAlphabetActive(char);
    const elementId = `subcat-${char}`;
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  // Handle Cancel button click

  const handleCancelClick = () => {
    // Close the popup
    setSubCatMenuData({ ...subCatMenuData, subCatMenuOpen: false });
    // Notify parent component about the cancel action
    if (onCancel) {
      onCancel();
    }
    // Navigate back to the previous page
    navigate(-1);
  };
  return (
    <div
      className={`categories-menu-wrapper ${subCatMenuOpen ? "show" : "hide"}`}
    >
      <div
        style={{ display: subCatMenuOpen ? "block" : "none" }}
        className="popup-wrapper popup--wrapper event-attached"
      >
        <div className="subcatmenu-header">
          <div className="subcategory-info">
            <div className="header-content">
              <div>Click to connect by interest</div>
              <div className="subcategory-count">
                {data.subcategories.length} Total subcategories
              </div>
            </div>
            <WhiteBtn
              whiteBtnImage={
                <GlobalThreeLineAnimation
                  fillColor="#964b00"
                  height="25px"
                  width="15px"
                  lineWidth="25px"
                  lineHeight="5px"
                  animationType="6"
                  marginLeft="10px" // Add marginLeft prop
                  marginBottom="40px" // Add marginBottom prop
                />
              }
              whiteBtnText="Cancel"
              whiteBtnFontColor="#964b00"
              whiteBtnfontSize=".75rem"
              whiteBtnBorderColor="#964b00"
              whiteBtnmarginLeft="70%"
              // whiteBtnmarginTop="70px"
              whiteBtnPaddingRight="20px"
              whiteBtnPosition="absolute"
              whiteBtnheight="50px"
              textStyle={{
                position: "absolute",
                top: "25px",
              }}
              top="4%"
              onClick={handleCancelClick} // Use the new handler
            />
            <div className="icon-container">
              <div>{CategoryIcon}</div>
              <h2 className="subcategory-title">{categoryName}</h2>
            </div>
          </div>
        </div>
        <div className="subcatmenu-popup-body ">
          {/* this will be used to pass the service to the input */}
          <div className="subcategories-container">
            <div className="subcategories-column">
              <ul className="subcategories-list" ref={refCategorylist}>
                {alphabet.map((char) => {
                  const filteredSubcategories = subcategories.filter(
                    (subcategory) =>
                      subcategory.name.toUpperCase().startsWith(char)
                  );
                  const isActive = alphabetActive === char;
                  return (
                    <li key={char} id={`subcat-${char}`}>
                      <h3
                        id={`subcat-${char}`}
                        className={
                          isActive ? "active-letter" : "inactive-letter"
                        }
                      >
                        {char}
                      </h3>
                      {filteredSubcategories.length > 0 ? (
                        filteredSubcategories.map((subcategory) => (
                          <div
                            key={subcategory.id}
                            className="subcategory-item"
                          >
                            <a
                              href="#"
                              onClick={() =>
                                handleSubcategoryClick(subcategory.id)
                              }
                            >
                              {subcategory.name}
                            </a>
                          </div>
                        ))
                      ) : (
                        <div className="subcategory-item">Unavailable</div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="alphabet-index-column">
              <nav className="subcategories-alphabet">
                <span
                  style={{ color: "black", fontWeight: "800" }}
                  className="subcategories-alphabet-prefix"
                >
                  INDEX
                </span>
                {alphabet.map((char) => {
                  const isActive = alphabetActive === char;
                  // console.log(
                  //   `Processing character: ${char}, Active: ${isActive}`
                  // ); // Debugging log
                  return (
                    <a
                      key={char}
                      className={`subcategories-alphabet-char subcategories-alphabet--char ${
                        alphabetActive === char ? "active" : ""
                      }`}
                      href="#"
                      data-char={char}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAlphabetClick(char);
                      }}
                    >
                      {char}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubCategoryPopup;
// Log whenever data changes or the component renders
// useEffect(() => {
//   console.log("Complete data object:", data);
//   const iconKey = convertToSlug(data?.categoryName);
//   console.log("Parent Category Name:", data?.CategoryName);
//   console.log("Converted Slug:", iconKey);
//   console.log("CatIcons Object:", CatIcons);
//   if (iconKey) {
//     console.log("Icon for the key:", iconKey, CatIcons[iconKey]);
//   } else {
//     console.log("Icon key is undefined");
//   }
// }, [data]);
// Assign a ref to each first subcategory item of each alphabet letter
// This useEffect is responsible for setting up refs for each alphabet letter
// Assigning refs for each subcategory
