import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import mockData from "../../seedMockData/seedMockData";
import Connections from "../../components/connections/connections";
import { getHighestPostPeriod } from "../../utils/postUtils";
import { capitalizeFirstLetter } from "../../utils/stringUtils.js";
import { handleFileChange } from "../../utils/changeFileHandler.js";
import {
  WhiteBtn,
  GlassBtn,
  SlideBtn,
  SolidColorButtonRef,
  SolidColorButton,
  ImgTopBtn,
  SwitchSlider,
  ToggleSlider,
  PlainBtn,
} from "../../components/buttons";
import GlobalThreeLineAnimation from "../../globalViews/globalThreeLineAnimation";
import {
  CommunityIcon,
  Xylophone,
  FindUsername,
  Back,
  XIcon,
  Filter,
  AvatarThumbNailIcon,
  Remote,
} from "../../components/icons";
import { CreateEventBtn } from "../../components/buttons/index.jsx";
import { EventPopup } from "../../components/forms"; // Import the EventPopup component
import StatusList from "../../components/statusList/statusList";
import GroupDetailMbPadNav from "./groupDetailMbPadNav.jsx";
import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";
import UserComment from "../../components/comment/comment.jsx";
import UserReply from "../../components/userReply/userReply.jsx";
import {
  onThreadReply,
  startThreadReply,
  onJoinThread,
} from "../../controller/errorHandlers/commentListenersErrorHanders.js";
import { clearReplyStateAndStorage } from "../../controller/watcherAndClearFunctions/commentClearsWatchersFunctions.js";
import EmotionEmoji from "../../components/emoji/emoji.jsx";
import { debounce } from "../../utils/searchDebounceUtils";

import "./groupDetailPage.scss";

const GroupDetailPage = ({
  userCommentWatcher,
  clearUserPostCommentLocalStorage,
  triggerFileUpload,
}) => {
  const navigate = useNavigate();
  const groupContainerRef = useRef(null);
  const memberAdminSectionRef = useRef(null);
  // used for active color so i can manipulate the scss for error handling
  // and filtering the active list
  const invitedRef = useRef(null);
  const interestedRef = useRef(null);
  const membersRef = useRef(null);
  const excludedRef = useRef(null);
  const declinedRef = useRef(null);
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const { groupId } = useParams();
  const currentContext = "groupDetails";
  const [userNameGroupSearchError, setUserNameGroupSearchError] =
    useState(false);
  const [isPrivateGroup, setIsPrivateGroup] = useState(false);
  const [groupComments, setGroupComments] = useState([]);
  const [activeReply, setActiveReply] = useState({ index: null, username: "" });
  const [replyValue, setReplyValue] = useState("");
  const [joinThread, setJoinThread] = useState(false);
  const [startThread, setStartThread] = useState(false);
  const [threadReply, setThreadReply] = useState(false);
  const [isInviteManager, setIsInviteManager] = useState(true);
  const [activeTab, setActiveTab] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [excludedUsers, setExcludedUsers] = useState([]);
  const [isPrivateEvent, setIsPrivateEvent] = useState(false); // Assuming you want to start with 'Public'
  const [isButtonPressed, setIsButtonPressed] = useState(false); // will reset the error handler = required messaage and red border
  const [groupSearchCriteriaValue, setGroupSearchCriteriaValue] = useState(""); // used to monitor when the input changes will set up a handler to see the value change
  const [groupPageDiscussionValueFocused, setGroupPageDiscussionValueFocused] =
    useState(false);
  const [isGroupSearchInputEmpty, setIsGroupSearchInputEmpty] = useState(false); // used to check if the input is empty for error handling and to reset state with is button pressed
  const [isSliderSlid, setIsSliderSlid] = useState(false); // an event listener will be used for actviiting error messages after the slider has slid if conditions are not met
  const [isSearchMode, setIsSearchMode] = useState(false); // default to not search mode
  const [invited, setInvitedList] = useState([]);
  const [groupPageDiscussionValue, setGroupPageDiscussionValue] = useState(""); // discussion input state management
  const [groupCharacterCommentError, setGroupCharacterCommentError] =
    useState(false);
  const [currentList, setCurrentList] = useState(null); // Can be 'interested', 'attending', etc.
  const [activityLogs, setActivityLogs] = useState(mockData.activityLogs);
  const [showEmojis, setShowEmojis] = useState(false);
  const [selectedEmojiId, setSelectedEmojiId] = useState(null);
  const [activeExpressionId, setActiveExpressionId] = useState(null);
  const [activateSpotlight, setActivateSpotlight] = useState(false);
  const [showAdminControls, setShowAdminControls] = useState(false);
  const [showMemberStatus, setShowMemberStatus] = useState(false);
  const resetFilters = () => {
    setGroupSearchCriteriaValue("");
    setIsGroupSearchInputEmpty(true);
    setCurrentList("");
    // ... reset other states related to filters as necessary
  };
  // Handlers for setting the active tab
  const handleDiscussionClick = () => {
    setActiveTab("discussion");
    setUserAction("discussion");
    // Reset the spotlight state to ensure it can be re-triggered
    setActivateSpotlight(false);

    // Then set it back to true with a slight delay
    setTimeout(() => {
      setActivateSpotlight(true);
    }, 10); // Small delay

    setTimeout(() => {
      const element = document.getElementById("commentsSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  };
  const toggleSpotlightOnElement = () => {
    const overlay = document.getElementById("overlay");
    const element = document.getElementById("commentsSection");

    if (!overlay || !element) return;

    if (activateSpotlight) {
      overlay.style.display = "block";
      // Force the browser to acknowledge the display change before transitioning opacity
      setTimeout(() => (overlay.style.opacity = 1), 0);
      element.classList.add("highlight-spotlight");

      // Set up to fade out after a delay
      setTimeout(() => {
        overlay.style.opacity = 0;
      }, 1000); // Adjust the delay for automatic fade-out as needed
    } else {
      overlay.style.opacity = 0; // Start fading out immediately
    }

    // Handler for end of fade out
    const onTransitionEndHandler = () => {
      if (overlay.style.opacity === "0") {
        overlay.style.display = "none";
        element.classList.remove("highlight-spotlight");
      }
    };

    overlay.addEventListener("transitionend", onTransitionEndHandler, {
      once: true,
    });
  };
  // Inside your component
  useEffect(() => {
    toggleSpotlightOnElement();
  }, [activateSpotlight]);

  const handleAboutClick = () => {
    setActiveTab("about");
    setUserAction("about");
    scrollToBottom();
  };
  const handleCancel = () => {
    setActiveTab(null); // Reset active tab to bring back 'Update Event' button
  };
  const handleShowMemberStatus = () => {
    setShowAdminControls(false);
    setShowMemberStatus(true);
    setActiveTab("memberStatus");
  };
  const handleShowAdminControls = () => {
    setShowAdminControls(true);
    setShowMemberStatus(false);
    setActiveTab("adminControls");
  };

  // Handler for 'Invite' action
  const handleInvite = () => {
    setActiveTab("invite");
    setUserAction("invite");
  };

  const handleJoin = (e) => {
    e.preventDefault();
    setUserAction("Join");
  };
  // // Show one list at a time, and for error handling, and message switching using condtional rendering
  const toggleList = (status) => {
    console.log("Toggling list to:", status); // Add this line
    setCurrentList(status);
  };

  const handleInvitedList = () => {
    handleButtonPress();
    toggleList("invited");
  };
  const handleInterestedList = () => {
    handleButtonPress();
    toggleList("interested");
  };

  const handleDeclinedList = () => {
    handleButtonPress();
    toggleList("declined");
  };

  const handleMembersList = () => {
    handleButtonPress();
    toggleList("members");
  };

  const handleExcludedList = () => {
    handleButtonPress();
    toggleList("excluded");
  };

  const toggleEventPrivacy = () => {
    setIsPrivateEvent((prev) => !prev);
  };
  const togglePopup = () => {
    setEventFormOpen(!eventFormOpen);
  };
  const handleSlideEnd = () => {
    // Show the popup when the slider ends invoke the code above
    togglePopup();
  };

  const toggleGuestRole = () => {
    setIsInviteManager((prev) => !prev);
  };
  useEffect(() => {
    if (activeTab === "adminControls" && memberAdminSectionRef.current) {
      memberAdminSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy(0, 850); // Adjust this value as needed
      }, 100);
    } else if (activeTab === "memberStatus" && memberAdminSectionRef.current) {
      memberAdminSectionRef.current.scrollIntoView({ behavior: "smooth" });
      // No additional scrolling adjustment needed for guestStatus
    }
  }, [activeTab, memberAdminSectionRef]);

  const hideButtons = activeTab === "members"; // when users clicks the members tab we will hide the buttons for more view port
  // toDo change message here..
  const getToggleLabels = (isPrivate) => {
    switch (isPrivate) {
      case true:
      case false:
        return {
          left: "",
          right: "",
        };
    }
  };
  const labels = getToggleLabels(isPrivateEvent);

  const loggedInUser = {
    id: "user-uuid-1",
    name: "AcctHolder",
  };
  const loggedInUserInfo = mockData.users.find(
    (user) => user.id === loggedInUser.id
  );

  const [users, setUsers] = useState({
    members: [],
    interested: [],
    blocked: [],
    declined: [],
    invited: [],
    excluded: [],
  });

  const isAnyButtonActive = () => {
    const isMembersActive =
      membersRef.current &&
      membersRef.current.classList.contains("membersNum-active");
    const isInterestedActive =
      membersRef.current &&
      membersRef.current.classList.contains("interstedNum-active");
    const isDeclinedActive =
      declinedRef.current &&
      declinedRef.current.classList.contains("declinedNum-active");
    const isInvitedActive =
      invitedRef.current &&
      invitedRef.current.classList.contains("invitedNum-active");
    const isExcludedActive =
      excludedRef.current &&
      excludedRef.current.classList.contains("excludedNum-active");
    // Check if currentList is not null
    const isCurrentListActive = currentList !== null;

    // console.log("Is 'Maybe' active:", isMaybeActive);
    // console.log("Is 'Declined' active:", isDeclinedActive);
    // console.log("Is 'Attending' active:", isAttendingActive);
    // console.log("Is 'Invited' active:", isInvitedActive);
    // console.log("Is 'Excluded' active:", isExcludedActive);

    return (
      isMembersActive ||
      isDeclinedActive ||
      isInvitedActive ||
      isExcludedActive ||
      isInterestedActive ||
      isCurrentListActive
    );
  };

  // monitors groupSearchCriteriaValue: This will tell us when the search criteria is reset.
  useEffect(() => {
    // console.log("useEffect - groupSearchCriteriaValue changed to:", groupSearchCriteriaValue); // working as expected
    if (groupSearchCriteriaValue === "") {
      setIsSearchMode(false);
    }
  }, [groupSearchCriteriaValue]);

  useEffect(() => {
    setUsers({
      interested: getUsersByStatus("interested"),
      members: getUsersByStatus("members"), // Fetching users based on 'member', 'administrating', 'moderating'
      blocked: getUsersByStatus("blocked"),
      declined: getUsersByStatus("declined"),
      invited: getUsersByStatus("invited"),
      excluded: getUsersByStatus("excluded"),
    });
  }, []);
  // In groupCard component
  const buttonRef = React.useRef(null);
  useEffect(() => {
    // Fetch comments associated with the group
    const comments = mockData.comments
      .filter((comment) => comment.groupId === groupId)
      .map((comment) => {
        // Find threads for each comment
        const threads = mockData.commentThreads
          .filter((thread) => thread.id === comment.threadId)
          .sort((a, b) => b.autoIndex - a.autoIndex);

        return { ...comment, threads };
      });
    // Fetch threads for each comment
    setGroupComments(comments);
  }, [groupId]);

  // useEffect to clear reply state and local storage when activeReply changes
  useEffect(() => {
    clearReplyStateAndStorage(setReplyValue, currentContext, activeReply);
  }, [setReplyValue, activeReply, currentContext]);
  useEffect(() => {
    console.log("startThread state updated:", startThread);
  }, [startThread]); // This useEffect will run whenever joinStartThread changes

  useEffect(() => {
    console.log("Thread Reply State: ", threadReply);
  }, [threadReply]);

  // When passing the button component, pass the ref as well
  <WhiteBtn ref={buttonRef} /* other props */ />;
  // this will monitor the stae of the popup so it can reset it back to z index intial state
  // setting count variables will be updating it with increment and decrement
  // this will save on joins and computational counts each time
  const scrollToBottom = () => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (groupPageDiscussionValue) {
      // If there is content in groupPageDiscussionValue, scroll to the bottom
      scrollToBottom();
    }
  }, [groupPageDiscussionValue]); // Dependency array with groupPageDiscussionValue

  useEffect(() => {
    if (activeTab === "about") {
      // If the 'About' tab is active, scroll to the bottom
      scrollToBottom();
    }
  }, [activeTab]); // Depend on the activeTab state

  useEffect(() => {
    if (eventFormOpen) {
      setElementsZIndexToZero();
    } else {
      resetElementsZIndex();
    }
  }, [eventFormOpen]);
  const groupMembersDetailSectionRef = useRef(null);

  useEffect(() => {
    if (currentList && groupMembersDetailSectionRef.current) {
      const element = groupMembersDetailSectionRef.current;
      const rect = element.getBoundingClientRect();
      const positionToScrollTo = window.scrollY + rect.top;
      window.scrollTo({
        top: positionToScrollTo,
        behavior: "smooth",
      });
    }
  }, [currentList]);

  const specificGroup = mockData.groups.find((g) => g.id === groupId);
  // Get connections related to the group
  const getFollowingIds = (userId, activityLogs) =>
    activityLogs
      .filter((log) => log.sourceId === userId && log.activity === "following")
      .map((log) => log.targetId);

  const getFollowerIds = (userId, activityLogs) =>
    activityLogs
      .filter((log) => log.targetId === userId && log.activity === "following")
      .map((log) => log.sourceId);

  const findConnections = (loggedInUserId, activityLogs) => {
    const followingIds = getFollowingIds(loggedInUserId, activityLogs);
    const followerIds = getFollowerIds(loggedInUserId, activityLogs);
    const mutualFollows = followingIds.filter((id) => followerIds.includes(id));
    return mutualFollows;
  };

  const connectionsIds = findConnections(
    loggedInUser.id,
    mockData.activityLogs
  );
  const connections = mockData.users.filter((user) =>
    connectionsIds.includes(user.id)
  );

  // Calculate total number of connections in the group
  const totalConnectionsInGroup = connections.filter(
    (connection) => connection.groupId === groupId
  ).length;
  const memberMessage =
    totalConnectionsInGroup > 1
      ? `${totalConnectionsInGroup} friend(s) are members.`
      : "No friends are members.";
  const groupPosts = mockData.posts.filter((post) => post.groupId === groupId);
  if (groupPosts.length === 0) {
    // If there are no posts for this group, do not render the component
    return null;
  }

  // Get the period with the highest post count for the first post in the group
  // Note: Adjust as per your use case if you need to aggregate posts data
  const highestPostPeriod = getHighestPostPeriod(groupPosts);

  const getUsersByStatus = (status) => {
    console.log("getUsersByStatus called with status:", status);

    let logActions;
    switch (status) {
      case "members":
        logActions = ["member"];
        break;
      case "administrator":
        logActions = ["administrator", "created group"];
        break;
      case "moderator":
        logActions = ["moderator"];
        break;
      case "co-moderator":
        logActions = ["co-moderator"];
        break;
      case "blocked":
        logActions = ["blocked"];
        break;
      case "declined":
        logActions = ["declined"];
        break;
      case "invited":
        logActions = ["invited"];
        break;
      case "excluded":
        logActions = ["excluded"];
        break;
      case "interested":
        logActions = ["interested", "join-request"];
        break;
      case "inactive":
        logActions = ["left", "removed"];
        break;
      default:
        logActions = [];
    }
    // Filter activity logs based on group ID and the determined activity statuses
    const specificGroupActivityLogs = mockData.activityLogs.filter(
      (log) =>
        log.relatedId === groupId &&
        log.objectType === "group" &&
        logActions.includes(log.activity)
    );
    // Map the logs to user objects
    const usersByStatus = specificGroupActivityLogs
      .map((log) => {
        let userId;
        // For 'interested', 'declined', and 'member', use sourceId
        if (
          status === "administrator" ||
          status === "members" ||
          status === "excluded" ||
          status === "moderator" ||
          status === "interested" || // request to join
          status === "declined"
        ) {
          userId = log.sourceId;
        } else if (status === "invited" || status === "inactive") {
          userId = log.targetId;
        }
        // Return the user object if found
        return mockData.users.find((user) => user.id === userId);
      })
      .filter(Boolean);
    // Add console log here to see the status and associated users
    console.log(`Status: ${status}, Users:`, usersByStatus);

    return usersByStatus;
  };

  // setting count variables will be updating it with increment and decrement
  // this will save on joins and computational counts each time
  const interestedCount = specificGroup.groupTotalInterested;
  const membersCount = specificGroup.groupTotalMembers;
  const declinedCount = specificGroup.groupTotalDeclined;
  const invitedCount = specificGroup.groupTotalInvited;

  const currentGroup = mockData.groups.find((g) => g.id === groupId);
  const excludedCount = currentGroup ? currentGroup.groupTotalExcluded : 0;
  const handleUnInvite = (username) => {
    setActivityLogs((prevLogs) =>
      prevLogs.filter(
        (log) =>
          !(
            log.targetId ===
              mockData.users.find((u) => u.username === username).id &&
            log.relatedId === groupId &&
            log.objectType === "group" &&
            log.activity === "invited"
          )
      )
    );
  };

  const toggleGroupPrivacy = () => {
    setIsPrivateGroup((prev) => !prev);
  };

  const toggleMemberStatus = () => {
    setIsInviteManager((prev) => !prev);
  };

  const adminUser = mockData.users.find(
    (user) => user.id === specificGroup.adminId
  );

  // Fetch group details, replaced `calendars` with `groups` as per the context
  const group = mockData.groups.find((group) => group.id === groupId);

  const setElementsZIndexToZero = () => {
    document.querySelectorAll(".events-btn").forEach((element) => {
      element.style.zIndex = "0";
    });
  };
  const resetElementsZIndex = () => {
    document.querySelectorAll(".events-btn").forEach((element) => {
      element.style.zIndex = "5";
    });
  };

  const handleUserNameChange = (value) => {
    setGroupSearchCriteriaValue(value);
    const userNameKey = getStorageKey("userNameValue", currentContext);
    localStorage.setItem(userNameKey, value);
    if (userNameGroupSearchError) {
      setUserNameGroupSearchError("");
    }
  };

  const filterUsersByStatusAndCriteria = (status, criteria) => {
    const allUsers = getUsersByStatus(status);
    if (!criteria) return allUsers;
    return allUsers.filter((user) =>
      user.username.toLowerCase().includes(criteria.toLowerCase())
    );
  };

  const handleSlideCompletion = () => {
    if (!groupSearchCriteriaValue && !isButtonPressed) {
      setIsSliderSlid(true);
      setIsGroupSearchInputEmpty(true);
      setUserNameGroupSearchError("Required"); // Set error message when input is empty
    } else {
      // API Call Placeholder: Fetch users based on groupSearchCriteriaValue
      // This is where you'll integrate your backend call.
      // Example:
      // const response = await fetchYourAPI(groupSearchCriteriaValue);
      // const data = await response.json();
      // setUsers(data);
      // Temporary logic to set users (to be replaced with API response)
      // Setting the users based on groupSearchCriteriaValue
      setUsers({
        members: filterUsersByStatusAndCriteria(
          "members",
          groupSearchCriteriaValue
        ),
        members: filterUsersByStatusAndCriteria(
          "member",
          groupSearchCriteriaValue
        ),
        declined: filterUsersByStatusAndCriteria(
          "declined",
          groupSearchCriteriaValue
        ),
        invited: filterUsersByStatusAndCriteria(
          "invited",
          groupSearchCriteriaValue
        ),
        excluded: filterUsersByStatusAndCriteria(
          "excluded",
          groupSearchCriteriaValue
        ),
      });

      setIsSliderSlid(true); // Show the buttons
      setIsGroupSearchInputEmpty(false); // Hide the "Required" message
      setGroupSearchCriteriaValue(""); // Reset the group search criteria value
      clearUserNameGroupSearchCriteriaLocalStorage("");
      setUserNameGroupSearchError("");
    }
    setIsButtonPressed(false);
  };

  const handleButtonPress = () => {
    setIsButtonPressed(true);
    setIsSliderSlid(false);
    setUserNameGroupSearchError(""); // Reset error message when the button is pressed
  };

  const clearUserNameGroupSearchCriteriaLocalStorage = () => {
    setGroupSearchCriteriaValue(""); // Reset the group search criteria value
    const userNameKey = getStorageKey("userNameValue", currentContext); // Assuming currentContext is defined in your component
    localStorage.removeItem(userNameKey); // Remove the item from local storage
  };
  const handleExclude = (username, status) => {
    // Remove user from current status list
    const updatedStatusList = users[status].filter(
      (user) => user.username !== username
    );

    // Add user to excluded list
    const updatedExcludedList = [
      ...users.excluded,
      users[status].find((user) => user.username === username),
    ];

    // Update state
    setUsers({
      ...users,
      [status]: updatedStatusList,
      excluded: updatedExcludedList,
    });
  };
  const handleInclude = (username) => {
    // Find the user by username
    const user = mockData.users.find((u) => u.username === username);
    // If the user is found, change the user's status from 'excluded' to 'invited' (or another status as per your needs)
    if (user) {
      const request = mockData.activityLogs.find(
        (log) =>
          log.userId === user.Id &&
          log.relatedId === groupId &&
          log.objectType === "group"
      );
      if (request && request.type === "group") {
        request.status = "invited"; // You can change this to any status
      }
      // Then, refresh your lists
      setExcludedUsers(getUsersByStatus("excluded"));
      setInvitedList(getUsersByStatus("invited"));
      // ...and so on for other lists if necessary
    }
  };
  const show_RedBorder_Required = !isAnyButtonActive() && isSliderSlid;
  const debouncedHandleSlideCompletion = debounce(handleSlideCompletion, 300);

  // Call adjustElementPositions whenever text changes or focus state changes
  // Function to handle the emoji button click
  const handleEmojiButton = (commentId, threadId = null) => {
    const expressionId = threadId ? `${commentId}-${threadId}` : commentId;
    console.log(
      `handleEmojiButton: expressionId = ${expressionId}, activeExpressionId = ${activeExpressionId}`
    );
    if (expressionId !== activeExpressionId || !showEmojis) {
      console.log(
        "Opening emoji selector for a new target or was previously closed"
      );
      setShowEmojis(true);
      setActiveExpressionId(expressionId);
    } else {
      console.log("Toggling emoji selector visibility for the same target");
      setShowEmojis(!showEmojis);
    }
  };
  // Function to handle emoji selection
  const chooseEmoji = (emojiId) => {
    if (emojiId) {
      console.log("Selected emoji:", emojiId);
      setSelectedEmojiId(emojiId); // Update the state with selected emoji ID
      setShowEmojis(false); // Close the emoji selector
      // Additional logic for emoji selection can be added here
    }
  };
  const handleRemove = async (status, userId) => {
    try {
      // Construct the request payload
      const payload = {
        userId: userId,
        groupId: groupId, // Assuming you have groupId in your component's state
        status: status,
      };

      // Placeholder for making an API call to the backend
      // Replace this with your actual API call
      // const response = await yourApi.removeUserFromStatus(payload);

      // Assuming the response contains the updated list of users
      // const updatedUsers = response.data;

      // Update your local state based on the response
      // This is a placeholder logic, adjust according to your state structure
      setUsers((prevUsers) => ({
        ...prevUsers,
        [status]: prevUsers[status].filter((user) => user.id !== userId),
      }));

      // Optionally, you can show a success message or handle the response further
    } catch (error) {
      // Handle any errors here, such as showing error messages
      console.error("Error removing user:", error);
    }
  };
  const handleApprove = (userId) => {
    // Example logic to update the state
    setUsers((prevUsers) => {
      const newInterested = prevUsers.interested.filter(
        (user) => user.id !== userId
      );
      const approvedUser = prevUsers.interested.find(
        (user) => user.id === userId
      );
      const newMembers = [...prevUsers.members, approvedUser];
      return {
        ...prevUsers,
        interested: newInterested,
        members: newMembers,
      };
    });

    // Placeholder for future backend integration
    // fetch('/api/approveUser', { method: 'POST', body: JSON.stringify({ userId }) })
    //   .then(response => response.json())
    //   .then(data => /* handle the response */);
  };

  const handleDecline = (userId) => {
    // Example logic to update the state
    setUsers((prevUsers) => {
      const newInterested = prevUsers.interested.filter(
        (user) => user.id !== userId
      );
      return {
        ...prevUsers,
        interested: newInterested,
      };
    });

    // Placeholder for future backend integration
    // fetch('/api/declineUser', { method: 'POST', body: JSON.stringify({ userId }) })
    //   .then(response => response.json())
    //   .then(data => /* handle the response */);
  };

  return (
    <div id="groups-page-container">
      <div id="overlay" className="overlay-style"></div>
      <div className="groups-cover-picture">
        {specificGroup.imagePath && (
          <img
            src={specificGroup.imagePath}
            alt={
              specificGroup.groupTitle
                ? capitalizeFirstLetter(specificGroup.groupTitle)
                : "Group Image"
            }
          />
        )}
        <div className="groupsEventDetails">
          <CreateEventBtn
            onHandleSlideEnd={handleSlideEnd}
            eventFormOpen={eventFormOpen}
            togglePopup={togglePopup}
            // Pass the EventPopup component as a prop
            eventPopup={
              <EventPopup
                onClose={() => setEventFormOpen(false)} // prop recieved state from the parent component above
                eventFormOpen={eventFormOpen}
              />
            }
          />
        </div>
      </div>
      {specificGroup && capitalizeFirstLetter(specificGroup.groupTitle)}
      <div className="image-button-wrapper" ref={groupMembersDetailSectionRef}>
        <div className="top-buttons">
          {!hideButtons && (
            <>
              <GlassBtn
                glassBtnImage={
                  <Xylophone
                    height="25px"
                    width="25px"
                    fillColor={activeTab === "discussion" ? "#fff" : "#fdfd96"} // Dynamic fillColor
                  />
                }
                onClick={handleDiscussionClick}
                glassBtnText="Discussion"
                glassBtnFontColor={
                  activeTab === "discussion" ? "#fff" : "#fdfd96"
                }
                glassBtnHeight="27px"
                glassBtnTextDecorationColor={
                  activeTab === "discussion" ? "#fff" : "#fdfd96"
                }
                glassBtnTextFontSize="1.3rem"
                glassBtnFontWeight="800"
              />
              <GlassBtn
                glassBtnImage={
                  <Xylophone
                    height="25px"
                    width="25px"
                    fillColor={activeTab === "about" ? "#fff" : "#fdfd96"} // Dynamic fillColor
                  />
                }
                onClick={handleAboutClick}
                glassBtnText="About"
                glassBtnFontColor={activeTab === "about" ? "#fff" : "#fdfd96"}
                glassBtnTextDecorationColor={
                  activeTab === "about" ? "#fff" : "#fdfd96"
                }
                glassBtnTextFontSize="1.3rem"
                glassBtnHeight="27px"
                glassBtnFontWeight="800"
              />
              <GlassBtn
                glassBtnImage={
                  <Xylophone
                    height="25px"
                    width="25px"
                    fillColor={userAction === "invite" ? "#fff" : "#fdfd96"} // Dynamic fillColor
                  />
                }
                onClick={handleInvite}
                glassBtnText="Invite"
                className="group-invite-btn"
                glassBtnFontColor={userAction === "invite" ? "#fff" : "#fdfd96"}
                glassBtnTextDecorationColor={
                  userAction === "invite" ? "#fff" : "#fdfd96"
                }
                glassBtnHeight="27px"
                glassBtnTextFontSize="1.3rem"
                glassBtnFontWeight="800"
              />
              {userAction === "Join" ? (
                <SolidColorButtonRef
                  solidColorBtnImage={
                    <GlobalThreeLineAnimation
                      fillColor="#964b00"
                      height="45px"
                      width="35px"
                      lineWidth="30px"
                      lineHeight="5px"
                      animationType="11"
                    />
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setUserAction(null); // reset userAction
                  }}
                  className="gold-btn"
                  solidColorBtnText="Cancel Request"
                  solidColorBtnFontWeight="900"
                  solidColorBtnFontFamily="Roboto-Slab"
                  solidColorBtnTextFontSize=".75rem"
                  solidColorBtnFontColor="#964b00"
                  solidColorBtnWidth="fit-content"
                  solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                />
              ) : (
                <SolidColorButtonRef
                  solidColorBtnImage={
                    <CommunityIcon
                      fillColor="#964b00"
                      height="25px"
                      width="25px"
                    />
                  }
                  onClick={handleJoin}
                  className="gold-btn"
                  solidColorBtnText="Join"
                  solidColorBtnFontWeight="800"
                  solidColorBtnFontFamily="helvetica"
                  solidColorBtnTextFontSize=".75rem"
                  solidColorBtnFontColor="#964b00"
                  solidColorBtnWidth="100px"
                  solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
                />
              )}
            </>
          )}
        </div>
        <div className="bottom-buttons">
          {!hideButtons && (
            <>
              <GlassBtn
                glassBtnImage={
                  <Xylophone
                    height="25px"
                    width="25px"
                    fillColor={
                      activeTab === "memberStatus" ? "#fff" : "#fdfd96"
                    } // Dynamic fillColor
                  />
                }
                onClick={handleShowMemberStatus}
                glassBtnText="Members"
                glassBtnFontColor={
                  activeTab === "memberStatus" ? "#fff" : "#fdfd96"
                }
                glassBtnTextDecorationColor={
                  activeTab === "memberStatus" ? "#fff" : "#fdfd96"
                }
                glassBtnTextFontSize="1.3rem"
                glassBtnHeight="27px"
                glassBtnFontWeight="800"
              />
              <GlassBtn
                glassBtnImage={
                  <Remote
                    height="25px"
                    width="25px"
                    fillColor={
                      activeTab === "adminControls" ? "#fff" : "#fdfd96"
                    }
                  />
                }
                onClick={handleShowAdminControls}
                glassBtnText="Admin"
                glassBtnFontColor={
                  activeTab === "adminControls" ? "#fff" : "#fdfd96"
                }
                glassBtnHeight="27px"
                glassBtnTextDecorationColor={
                  activeTab === "adminControls" ? "#fff" : "#fdfd96"
                }
                glassBtnTextFontSize="1.3rem"
                glassBtnFontWeight="800"
              />

              {activeTab !== "adminControls" &&
                activeTab !== "memberStatus" && (
                  <>
                    <SwitchSlider
                      leftLabel={labels.left}
                      rightLabel={labels.right}
                      handleChangeSlider={toggleEventPrivacy}
                      checked={isPrivateEvent}
                      className="mrgn-top"
                    />
                    <div className="flex-column">
                      <ToggleSlider
                        defaultPosition={isInviteManager ? "right" : "left"}
                        handleChangeSlider={toggleMemberStatus}
                        activeContent="Yes"
                        inactiveContent="No"
                        checked={isInviteManager}
                        className="events-details-slider-wrapper"
                        showGuestInviteText={true}
                        sliderWrapperStyle={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "33px",
                        }}
                        sliderTextStyle={{
                          textAlign: "center", // Center the text
                        }}
                      />
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </div>
      {/* Add the GroupDetailMbPadNav component */}
      <GroupDetailMbPadNav
        onDiscussionClick={handleDiscussionClick}
        onAboutClick={handleAboutClick}
        onMembersClick={handleShowMemberStatus}
        onShowAdminControls={handleShowAdminControls}
        onInvite={handleInvite}
        onInterested={handleInterestedList}
        onJoin={handleJoin}
        onInvitedList={handleInvitedList}
        onDeclinedList={handleDeclinedList}
        onMembersList={handleMembersList}
        onExcludedList={handleExcludedList}
        isAnyButtonActive={isAnyButtonActive}
        clearUserNameGroupSearchCriteriaLocalStorage={
          clearUserNameGroupSearchCriteriaLocalStorage
        }
        isSliderSlid={isSliderSlid}
        userAction={userAction} // Pass the userAction state here
        activeTab={activeTab}
        currentList={currentList}
        invitedRef={invitedRef}
        interestedRef={interestedRef}
        show_RedBorder_Required={show_RedBorder_Required}
        invitedCount={invitedCount}
        interestedCount={interestedCount}
        declinedRef={declinedRef}
        declinedCount={declinedCount}
        membersRef={membersRef}
        membersCount={membersCount}
        excludedRef={excludedRef}
        excludedCount={excludedCount}
        onInterestedList={handleInterestedList}
        groupSearchCriteriaValue={groupSearchCriteriaValue}
        handleUserNameChange={handleUserNameChange}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        onHandleCancel={handleCancel}
        onResetFilters={resetFilters}
        userNameGroupSearchError={userNameGroupSearchError}
        setIsGroupSearchInputEmpty={setIsGroupSearchInputEmpty}
        debouncedHandleSlideCompletion={debouncedHandleSlideCompletion}
        setGroupSearchCriteriaValue={setGroupSearchCriteriaValue}
        setUserAction={setUserAction}
        hideButtons={hideButtons}
        isInviteManager={isInviteManager}
        labels={labels}
        showMemberStatus={showMemberStatus}
        showAdminControls={showAdminControls}
        onToggleEventPrivacy={toggleEventPrivacy}
        isPrivateEvent={isPrivateEvent}
        onToggleGuestRole={toggleGuestRole}
        onHandleMembersClick={handleShowMemberStatus}
      />
      {activeTab === "about" && (
        <div className="about-section">
          <h1>
            <div className="group-activity">
              <div className="gpstats">
                <p>{group.groupTotalMembers} · members</p>
                {highestPostPeriod && (
                  <>
                    <span className="spacer"></span>
                    <p>
                      {highestPostPeriod.count} Post ·Per{" "}
                      {highestPostPeriod.name}
                    </p>
                  </>
                )}
              </div>
              <div className="gpMemberConnects">
                {connections.length > 0 ? (
                  <>
                    <div className="membersconnects-info">
                      <div className="gp-prof-thumbnails">
                        {connections
                          .filter((connection) => connection.imagePath !== null)
                          .slice(0, 3) // Get the first three users with non-null imagePath
                          .map((connection) => (
                            <div
                              key={connection.id}
                              className="gp-prof-thumbnail"
                            >
                              <img
                                src={connection.imagePath}
                                alt={connection.username}
                              />
                            </div>
                          ))}
                      </div>
                      {(() => {
                        const validConnections = connections.filter(
                          (connection) => connection.imagePath !== null
                        );
                        switch (validConnections.length) {
                          case 0:
                            return <p>No members found.</p>;
                          case 1:
                            return (
                              <p>{validConnections[0].username} is a member.</p>
                            );
                          case 2:
                            return (
                              <p>
                                {validConnections[0].username} and 1 friend are
                                .members
                              </p>
                            );
                          default:
                            return (
                              <p>
                                {validConnections[0].username} and{" "}
                                {validConnections.length - 1} friends are
                                members.
                              </p>
                            );
                        }
                      })()}
                    </div>
                  </>
                ) : (
                  <p>No connections found.</p>
                )}
              </div>
            </div>
          </h1>
          <h1>{adminUser && adminUser.name}</h1>
          <p>{specificGroup && specificGroup.groupDescription}</p>
          <h3 className="compact-Spacing hosts">
            {/* Displaying Admin */}
            <span>Admin: </span>
            {getUsersByStatus("administrator").map((admin, index) => (
              <Connections
                key={index}
                userId={admin.id}
                username={admin.username}
                firstName={admin.firstName}
                lastName={admin.lastName}
                profileThumbnail={admin.imagePath}
                showChatSlider={true}
                showUsernameOffSlider={false}
              />
            ))}
            {/* Displaying Moderators */}
            {getUsersByStatus("moderator").length > 0 && (
              <span>Moderator(s): </span>
            )}
            {getUsersByStatus("moderator").map((moderator, index) => (
              <Connections
                key={index}
                userId={moderator.id}
                username={moderator.username}
                firstName={moderator.firstName}
                lastName={moderator.lastName}
                profileThumbnail={moderator.imagePath}
                showChatSlider={true}
                showUsernameOffSlider={false}
              />
            ))}
          </h3>
        </div>
      )}
      {/* Excluded/members status" section based on activeTab */}
      {(activeTab === "adminControls" || activeTab === "memberStatus") && (
        <div className="members-section">
          <div className="backBtnGroupsPg">
            <SolidColorButton
              solidColorBtnImage={
                <Back
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              className="gold-btn"
              solidColorBtnText="Back"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize="1.3rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              onClick={() => navigate(-1)}
            />
            <SolidColorButton
              solidColorBtnImage={
                <XIcon
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              onClick={handleCancel}
              solidColorBtnText="Cancel/Close"
              className="gold-btn"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize="1.3rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
            <SolidColorButton
              className="gold-btn"
              solidColorBtnImage={
                <Filter height="25px" width="25px" fillColor="#964b00" />
              }
              onClick={resetFilters}
              solidColorBtnText="Reset Filters"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize="1.3rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
          </div>
          <div id="search-bar-wrapper">
            <div className="groups-connections-input-slider-container">
              <div className="connections-input-container blinker-wrapper">
                <input
                  value={groupSearchCriteriaValue}
                  placeholder={
                    userNameGroupSearchError
                      ? userNameGroupSearchError
                      : "Enter username"
                  }
                  className={userNameGroupSearchError ? "required-message" : ""}
                  type="text"
                  onChange={(e) =>
                    handleUserNameChange(e.target.value, "userNameValue")
                  }
                  onFocus={() => {
                    setIsFocused(true);
                    setGroupSearchCriteriaValue("");
                    setIsGroupSearchInputEmpty(false);
                  }}
                />
                {groupSearchCriteriaValue && (
                  <button
                    className="Clear-groupDetailsPg"
                    onClick={clearUserNameGroupSearchCriteriaLocalStorage}
                    style={{
                      color: "#fdfd96",
                      backgroundColor: "transparent",
                      borderColor: "#fdfd96",
                    }}
                  >
                    Clear
                  </button>
                )}
                {!isFocused && groupSearchCriteriaValue === "" && (
                  <span className="blinking-cursor blinking">|</span>
                )}
              </div>
              <SlideBtn
                sliderImage={() => <FindUsername fillColor="#964B00" />}
                slideBtnText="Slide to find username"
                slideBtnWidth="300px"
                onHandleSlideEnd={debouncedHandleSlideCompletion}
              />
            </div>
          </div>
          <div className="party-list">
            <div className="guest-list">
              <div className="group-admin-ctrls">
                <div
                  className={`btnsContain ${
                    show_RedBorder_Required &&
                    !isAnyButtonActive() &&
                    isSliderSlid
                      ? "red-border"
                      : ""
                  }`}
                >
                  {show_RedBorder_Required && (
                    <span className="error-message">
                      {userNameGroupSearchError}
                    </span>
                  )}
                  {activeTab === "memberStatus" && (
                    <>
                      <ImgTopBtn
                        onClick={handleMembersList}
                        image={
                          <span
                            ref={membersRef}
                            className={`memberNum ${
                              currentList === "members"
                                ? "memberNum-active"
                                : "memberNum-inactive"
                            }`}
                            style={{
                              color:
                                currentList === "members" ? "#000" : "#964b00",
                            }}
                          >
                            {membersCount}
                          </span>
                        }
                        imageOnTop={true}
                        imgTopBtnText={
                          currentList === "members"
                            ? "Member(s)"
                            : "Show Member(s) List"
                        }
                        imgTopBtnFontColor={
                          currentList === "members" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextDecorationColor={
                          currentList === "members" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextFontSize="1rem"
                        imgTopBtnFontWeight="800"
                        imgTopBtnHeight="fit-content"
                        imgTopBtnWidth="fit-content"
                        paddingLeft="10px"
                        paddingRight="10px"
                      />
                    </>
                  )}
                  {showAdminControls && !showMemberStatus && (
                    <>
                      <ImgTopBtn
                        onClick={handleInvitedList}
                        image={
                          <span
                            ref={invitedRef}
                            className={`invitedNum ${
                              currentList === "invited"
                                ? "invitedNum-active"
                                : "invitedNum-inactive"
                            }`}
                            style={{
                              color:
                                currentList === "invited" ? "#000" : "#964b00",
                            }}
                          >
                            {invitedCount}
                          </span>
                        }
                        imageOnTop={true}
                        imgTopBtnText={
                          currentList === "invited"
                            ? "Invited List"
                            : "Show Invited List"
                        }
                        imgTopBtnFontColor={
                          currentList === "invited" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextDecorationColor={
                          currentList === "invited" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextFontSize="1rem"
                        imgTopBtnFontWeight="800"
                        imgTopBtnHeight="fit-content"
                        imgTopBtnWidth="fit-content"
                        paddingLeft="10px"
                        paddingRight="10px"
                      />
                      <ImgTopBtn
                        onClick={handleInterestedList}
                        image={
                          <span
                            ref={interestedRef}
                            className={`interestedNum ${
                              currentList === " interested"
                                ? "interestedNum-active"
                                : "interestedNum-inactive"
                            }`}
                            style={{
                              fontSize: "1rem",
                              fontWeight: "800",
                              fontFamily: "Helvetica",
                              // taking the style attribute, assigning the variable and changing the style via inline
                              color:
                                currentList === "interested"
                                  ? "#000"
                                  : "#964b00",
                              WebkitTextStroke:
                                currentList === "interested"
                                  ? "1px #000"
                                  : "1px #964b00",
                            }}
                          >
                            {interestedCount}
                          </span>
                        }
                        imageOnTop={true}
                        imgTopBtnText={
                          currentList === "interested"
                            ? "Interested List"
                            : "Show Interested List"
                        }
                        imgTopBtnFontColor={
                          currentList === "interested" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextDecorationColor={
                          currentList === "interested" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextFontSize="1rem"
                        imgTopBtnFontWeight="800"
                        imgTopBtnHeight="fit-content"
                        imgTopBtnWidth="fit-content"
                        paddingLeft="10px"
                        paddingRight="10px"
                      />
                      <ImgTopBtn
                        onClick={handleDeclinedList}
                        image={
                          <span
                            ref={declinedRef}
                            className={`declinedNum ${
                              currentList === "declined"
                                ? "declinedNum-active"
                                : "declinedNum-inactive"
                            }`}
                            style={{
                              color:
                                currentList === "declined" ? "#000" : "#964b00",
                            }}
                          >
                            {declinedCount}
                          </span>
                        }
                        imageOnTop={true}
                        imgTopBtnText={
                          currentList === "declined"
                            ? "Declined List"
                            : "Show Declined List"
                        }
                        imgTopBtnFontColor={
                          currentList === "declined" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextDecorationColor={
                          currentList === "declined" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextFontSize="1rem"
                        imgTopBtnWidth="fit-content"
                        imgTopBtnFontWeight="800"
                        imgTopBtnHeight="fit-content"
                        paddingLeft="10px"
                        paddingRight="10px"
                      />
                      <ImgTopBtn
                        onClick={handleExcludedList}
                        image={
                          <span
                            ref={excludedRef}
                            className={`excludedNum ${
                              currentList === "excluded"
                                ? "excludedNum-active"
                                : "excludedNum-inactive"
                            }`}
                            style={{
                              color:
                                currentList === "excluded" ? "#000" : "#964b00",
                            }}
                          >
                            {excludedCount}
                          </span>
                        }
                        imageOnTop={true}
                        imgTopBtnText={
                          currentList === "excluded"
                            ? "Excluded List"
                            : "Show Excluded List"
                        }
                        imgTopBtnFontColor={
                          currentList === "excluded" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextDecorationColor={
                          currentList === "excluded" ? "#000" : "#964b00"
                        }
                        imgTopBtnTextFontSize="1rem"
                        imgTopBtnFontWeight="800"
                        imgTopBtnHeight="fit-content"
                        imgTopBtnWidth="fit-content"
                        paddingLeft="10px"
                        paddingRight="10px"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="scrollable-content">
            <div>
              {activeTab === "memberStatus" && (
                <>
                  {currentList === "members" && (
                    <StatusList
                      status="members"
                      type="group"
                      userList={users.members}
                      handleRemove={handleRemove}
                    />
                  )}
                </>
              )}
              {activeTab === "adminControls" && (
                <>
                  {currentList === "interested" && (
                    <StatusList
                      status="interested"
                      type="group"
                      userList={users.interested}
                      handleApprove={handleApprove}
                      handleDecline={handleDecline}
                    />
                  )}
                  {currentList === "invited" && (
                    <StatusList
                      status="invited"
                      type="group"
                      userList={users.invited}
                      handleExclude={handleExclude}
                      handleUnInvite={handleUnInvite}
                    />
                  )}

                  {currentList === "declined" && (
                    <StatusList
                      status="declined"
                      type="group"
                      userList={users.declined}
                      handleExclude={handleExclude}
                      handleUnInvite={handleUnInvite}
                    />
                  )}
                  {currentList === "excluded" && (
                    <StatusList
                      status="excluded"
                      type="group"
                      userList={users.excluded}
                      handleInclude={handleInclude}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {/* "Discussion" section based on activeTab */}
      {activeTab === "discussion" && (
        <div
          className="discussion-input-container groupComment-container"
          ref={groupContainerRef}
        >
          {/* Render each comment and its threads */}
          <div className="group-comments">
            {/* Render each comment and its threads */}
            {groupComments.map((comment) => (
              <div key={comment.id} className="comment-block">
                {/* Top-level Comment */}
                <div className="comment-top-level">
                  {mockData.users.find((user) => user.id === comment.sourceId)
                    ?.imagePath ? (
                    <img
                      src={
                        mockData.users.find(
                          (user) => user.id === comment.sourceId
                        ).imagePath
                      }
                      alt="User Thumbnail"
                      className="commentThumbnail"
                    />
                  ) : (
                    <AvatarThumbNailIcon className="default-avatar-icon" />
                  )}
                  <div className="userNameCommentWrapper">
                    <p className="comment-text">{comment.text}</p>
                    <p className="comment-username">
                      {mockData.users.find(
                        (user) => user.id === comment.sourceId
                      )?.username || "Unknown User"}
                    </p>
                  </div>
                </div>
                {/* Button to join an existing thread or start a new one */}
                <div className="Express-Respons-btns">
                  <PlainBtn
                    plainBtnText={
                      comment.threads && comment.threads.length > 0
                        ? "Join Thread"
                        : "Start Thread"
                    }
                    onClick={() =>
                      comment.threads && comment.threads.length > 0
                        ? onJoinThread(
                            comment,
                            setActiveReply, // State setter function from useState
                            setJoinThread // State setter function from useState
                          )
                        : startThreadReply(
                            comment,
                            startThread, // Assuming startThread is available in this scope
                            setActiveReply, // Assuming setActiveReply is available in this scope
                            setStartThread // Assuming setStartThread is available in this scope
                          )
                    }
                    plainBtnTextFontSize="1rem"
                    plainBtnWidth="fit-content"
                    plainBtnHeight="40px"
                    plainBtnFontFamily="Roboto"
                    plainBtnFontWeight="bold"
                    plainBtnFontColor="#964b00"
                    plainBtnBorderRadius="5px"
                    extraStyles={{
                      paddingRight: "10px",
                    }}
                  />
                  {/* <EmojiChoices 
                  {/* Emoji Button for each comment */}
                  <PlainBtn
                    plainBtnText="Reaction"
                    onClick={() => handleEmojiButton(comment.id)}
                    image={
                      <span
                        role="img"
                        aria-label="heart"
                        className="pulsing-heart"
                      >
                        ❤️
                      </span>
                    }
                    imageOnTop={false} // Set to true if you want the emoji above the text
                    extraStyles={{
                      paddingRight: "10px",
                      paddingLeft: "10px",
                    }}
                    plainBtnHeight="40px"
                    plainBtnFontFamily="Roboto"
                    plainBtnFontWeight="bold"
                    plainBtnFontColor="#964b00"
                    plainBtnBorderRadius="5px"
                  />
                  {/* Show Emojis for this comment if conditions are met */}
                  {showEmojis && activeExpressionId === comment.id && (
                    <EmotionEmoji onSelectEmoji={chooseEmoji} />
                  )}
                </div>
                {/* Display UserReply component if activeReply matches this comment */}
                {activeReply.commentId === comment.id && (
                  <UserReply
                    currentContext={"updateGroup"}
                    targetId={comment.id}
                    loggedInUserInfo={loggedInUserInfo}
                    activeReply={activeReply}
                    startThread={startThread}
                    joinThread={joinThread}
                    setActiveReply={setActiveReply}
                    setStartThread={setStartThread}
                    setJoinThread={setJoinThread}
                  />
                )}
                {/* Thread Replies */}
                {comment.threads.map((thread) => (
                  <div key={thread.autoIndex} className="thread-reply">
                    <div className="userThreadBubblePosition">
                      {mockData.users.find((user) => user.id === thread.userId)
                        ?.imagePath ? (
                        <img
                          src={
                            mockData.users.find(
                              (user) => user.id === thread.userId
                            ).imagePath
                          }
                          alt="User Thumbnail"
                          className="replyThumbnail"
                        />
                      ) : (
                        <AvatarThumbNailIcon className="default-avatar-icon" />
                      )}
                      <div className="userNameThreadWrapper">
                        <p className="thread-text">{thread.text}</p>
                        <p className="thread-username">
                          {mockData.users.find(
                            (user) => user.id === thread.userId
                          )?.username || "Unknown User"}
                        </p>
                      </div>
                    </div>
                    <div className="Express-Respons-btns">
                      <PlainBtn
                        plainBtnText="Reply"
                        onClick={() =>
                          onThreadReply(
                            thread.userId,
                            thread.id,
                            thread.autoIndex,
                            currentContext, // State from this component
                            activeReply, // State from this component
                            setReplyValue, // State setter from useState in this component
                            setActiveReply, // State setter from useState in this component
                            setThreadReply // State setter from useState in this component
                          )
                        }
                        plainBtnTextFontSize="1rem"
                        plainBtnWidth="fit-content"
                        plainBtnHeight="40px"
                        plainBtnFontFamily="Roboto"
                        plainBtnFontWeight="bold"
                        plainBtnFontColor="#964b00"
                        plainBtnBorderRadius="5px"
                        extraStyles={{
                          paddingRight: "10px",
                        }}
                      />
                      {/* Emoji Button for each thread */}
                      <PlainBtn
                        plainBtnText="Reaction"
                        onClick={() =>
                          handleEmojiButton(thread.id, thread.autoIndex)
                        }
                        image={
                          <span role="img" aria-label="heart">
                            ❤️
                          </span>
                        } // Pass the heart emoji as the image prop
                        imageOnTop={false} // Set to true if you want the emoji above the text
                        extraStyles={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                        plainBtnHeight="40px"
                        plainBtnFontFamily="Roboto"
                        plainBtnFontWeight="bold"
                        plainBtnFontColor="#964b00"
                        plainBtnBorderRadius="5px"
                      />
                      {/* Show Emojis for this thread if conditions are met */}
                      {/* Show Emojis for this specific thread */}
                      {showEmojis &&
                        activeExpressionId ===
                          `${thread.id}-${thread.autoIndex}` && (
                          <EmotionEmoji onSelectEmoji={chooseEmoji} />
                        )}
                    </div>
                    {activeReply.threadId === thread.id &&
                      activeReply.autoIndex === thread.autoIndex && (
                        <UserReply
                          currentContext={"updateGroup"}
                          targetId={thread.id} // Here you might need to pass the thread ID or the comment ID based on your backend logic
                          loggedInUserInfo={loggedInUserInfo}
                          activeReply={activeReply}
                          setActiveReply={setActiveReply}
                          threadReply={threadReply}
                          setThreadReply={setThreadReply}
                        />
                      )}
                  </div>
                ))}
              </div>
            ))}
            <div id="commentsSection">
              <UserComment
                loggedInUserInfo={loggedInUserInfo}
                currentContext={"updateGroup"} // Or any other context you're using
                onChange={userCommentWatcher}
                onFileChange={handleFileChange}
                onCommentClear={clearUserPostCommentLocalStorage}
                onUploadTrigger={triggerFileUpload}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default GroupDetailPage;
