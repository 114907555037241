const PREFIXES = {
    AUTH: "auth/",
    USER: "user/",
    UPLOADIMAGE: "uploadImage/",
  };
  
  const ACTION = {
    ADD: "add/",
    UPDATE: "update/",
    REMOVE: "remove/",
    SET: "set/"
  };
  
  export default { PREFIXES, ACTION };
  