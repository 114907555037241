import { useState, useRef } from 'react';

// custom hook to add three zeros at the end of every number and add comma separations to make it readable
export const useAddZerosWithCursor = () => {
  // Set the initial value of `originalValue` without ,
 const [originalValue, setOriginalValue] = useState('');
 // Set the initial value of `currentValue` to an empty string
 const [currentValue, setCurrentValue] = useState('');
 // create a reference for the input element
 const inputRef = useRef(null);
 // create another reference with a boolean value to track whether zeros have been appended to the input value or not
 const zerosAppended = useRef(false);
 // a function that will set the cursor position in the input field based on the length of `currentValue`
 const setCursor = async () => {
   await new Promise((resolve) => {
     const input = inputRef.current;
     const start = input.selectionStart;
     const end = input.selectionEnd;
     // If user is trying to delete a comma or period, move cursor to before it
     if (
       start === end &&
       start > 0 &&
       (input.value[start - 1] === ',' || input.value[start - 1] === '.')
     ) {
       // User is trying to delete a comma or period
       input.setSelectionRange(start - 1, start - 1);
       // If user is not trying to delete and input value is not empty, move cursor to end minus 3
     } else if (
       currentValue.length &&
       inputRef.current.selectionStart > currentValue.length - 3
     ) {
       // User is not trying to delete, set cursor to end minus 3
       inputRef.current.setSelectionRange(
         currentValue.length - 3,
         currentValue.length - 3
       );
     }
     resolve();
   });
 };


 // a function called that will be called every time the value of the input field changes
 const handleChange = async (event) => {
   let newValue = event.target.value;
   // Remove commas from the input value
   newValue = newValue.replace(/,/g, '');
   // If the input value is '0', clear currentValue
   if (newValue.length && Number(newValue) === 0) {
     setCurrentValue('');
     setOriginalValue(0);
     return;
   }
   // If the input value is not a number, do nothing
   if (!Number(newValue)) {
     return;
   }
   // Call `setCursor` function to set the cursor position in the input field
   await setCursor();
   // If zeros have not been appended, append them to newValue
   if (!zerosAppended.current) {
     if (newValue.length === 0) {
       // newValue = '000';
        setCurrentValue('');
        setOriginalValue(0);
     } else if (newValue.length > 0 && newValue !== '') {
       newValue += '000';
     }
     zerosAppended.current = true;
   }
   // Format the number with commas
   const formattedValue = Number(newValue).toLocaleString();
   // Update currentValue with the formatted value
   setCurrentValue(formattedValue);
   setOriginalValue(+newValue.replace(/,/g, ''));
   // If formattedValue is empty and currentValue is not, reset zerosAppended flag
   if (formattedValue === '' && currentValue !== '') {
     zerosAppended.current = false;
   }
 };
 // Reset zerosAppended flag if input is empty
  if (currentValue === '') {
   zerosAppended.current = false;
  }
 // Return an object with the originalValue, currentValue, inputRef, handleChange, setCursor, setOriginalValue and setCurrentValue variables/functions, so that they can be used in other components
  return { originalValue, currentValue, inputRef, handleChange, setCursor, setCurrentValue, setOriginalValue };
};
