import { useNavigate } from "react-router-dom";
import {
  GlassBtn,
  SolidColorButtonRef,
  SlideBtn,
  ImgTopBtn,
  SolidColorButton,
  SwitchSlider,
  ToggleSlider,
} from "../../components/buttons";
import {
  CommunityIcon,
  Xylophone,
  FindUsername,
  Filter,
  XIcon,
  Back,
  Remote,
} from "../../components/icons";
import GlobalThreeLineAnimation from "../../globalViews/globalThreeLineAnimation";
import "./groupDetailMbPadNav.scss";

const GroupDetailMbPadNav = ({
  onDiscussionClick,
  onAboutClick,
  onMembersClick,
  onInvite,
  onJoin,
  onInvitedList,
  onDeclinedList,
  onMembersList,
  onExcludedList,
  currentList,
  invitedRef,
  interestedRef,
  interestedCount,
  onInterestedList,
  invitedCount,
  declinedRef,
  declinedCount,
  membersRef,
  membersCount,
  excludedRef,
  excludedCount,
  userAction,
  groupSearchCriteriaValue,
  handleUserNameChange,
  isFocused,
  setIsFocused,
  setIsGroupSearchInputEmpty,
  debouncedHandleSlideCompletion,
  setGroupSearchCriteriaValue,
  activeTab,
  setUserAction,
  onResetFilters,
  onHandleCancel,
  hideButtons,
  isInviteManager,
  labels,
  onToggleEventPrivacy,
  isPrivateEvent,
  onToggleGuestRole,
  onHandleMembersClick,
  userNameGroupSearchError,
  show_RedBorder_Required,
  isAnyButtonActive,
  showMemberStatus,
  showAdminControls,
  isSliderSlid,
  onShowAdminControls,
  clearUserNameGroupSearchCriteriaLocalStorage,
}) => {
  const navigate = useNavigate();

  return (
    <div id="group-detail-mb-pad-nav">
      {!hideButtons && ( // if members tab is not active show the buttons else hide them
        <>
          <GlassBtn
            glassBtnImage={
              <Xylophone
                height="15px"
                width="15px"
                fillColor={activeTab === "discussion" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            onClick={onDiscussionClick}
            glassBtnText="Discussion"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnFontColor={activeTab === "discussion" ? "#fff" : "#fdfd96"}
            glassBtnTextDecorationColor={
              activeTab === "discussion" ? "#fff" : "#fdfd96"
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
          <GlassBtn
            glassBtnImage={
              <Xylophone
                height="15px"
                width="15px"
                fillColor={activeTab === "about" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            onClick={onAboutClick}
            glassBtnText="About"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnFontColor={activeTab === "about" ? "#fff" : "#fdfd96"}
            glassBtnTextDecorationColor={
              activeTab === "about" ? "#fff" : "#fdfd96"
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
          <GlassBtn
            glassBtnImage={
              <Xylophone
                height="15px"
                width="15px"
                fillColor={activeTab === "memberStatus" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            onClick={onMembersClick}
            glassBtnText="Members"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnFontColor={
              activeTab === "memberStatus" ? "#fff" : "#fdfd96"
            }
            glassBtnTextDecorationColor={
              activeTab === "memberStatus" ? "#fff" : "#fdfd96"
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
          <GlassBtn
            glassBtnImage={
              <Remote
                height="15px"
                width="15px"
                fillColor={activeTab === "adminControls" ? "#fff" : "#fdfd96"}
              />
            }
            onClick={onShowAdminControls}
            glassBtnText="Admin"
            glassBtnFontColor={
              activeTab === "adminControls" ? "#fff" : "#fdfd96"
            }
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnTextDecorationColor={
              activeTab === "adminControls" ? "#fff" : "#fdfd96"
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
          <GlassBtn
            glassBtnImage={
              <Xylophone
                height="15px"
                width="15px"
                fillColor={activeTab === "invite" ? "#fff" : "#fdfd96"} // Dynamic fillColor
              />
            }
            onClick={onInvite}
            glassBtnText="Invite"
            glassBtnHeight="20px"
            glassBtnWidth="100px"
            glassBtnTextFontSize=".75rem"
            glassBtnFontWeight="900"
            glassBtnFontColor={activeTab === "invite" ? "#fff" : "#fdfd96"}
            glassBtnTextDecorationColor={
              activeTab === "invite" ? "#fff" : "#fdfd96"
            }
            hi2Style={{
              transform: "rotate(90deg) translate(-80px, 50px)",
            }}
            hi3Style={{
              transform: "rotate(90deg) translate(-80px, -50px)",
            }}
          />
          {userAction === "Join" ? (
            <SolidColorButtonRef
              solidColorBtnImage={
                <GlobalThreeLineAnimation
                  fillColor="#964b00"
                  height="45px"
                  width="35px"
                  lineWidth="30px"
                  lineHeight="5px"
                  animationType="11"
                />
              }
              onClick={(e) => {
                e.preventDefault();
                setUserAction(null); // reset userAction
              }}
              className="gold-btn"
              solidColorBtnText="Cancel Request"
              solidColorBtnFontWeight="900"
              solidColorBtnFontFamily="Roboto-Slab"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="fit-content"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
          ) : (
            <SolidColorButtonRef
              solidColorBtnImage={
                <CommunityIcon fillColor="#964b00" height="25px" width="25px" />
              }
              onClick={onJoin}
              className="gold-btn"
              solidColorBtnText="Join"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="100px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
          )}
        </>
      )}
      {/* Separated div for sliders */}
      {((!hideButtons && userAction !== "Join") ||
        (hideButtons && userAction === "Join")) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px", // Add margin top to separate from the buttons above
          }}
        >
          <ToggleSlider
            defaultPosition={isInviteManager ? "right" : "left"}
            handleChangeSlider={onToggleGuestRole}
            activeContent="Yes"
            inactiveContent="No"
            checked={isInviteManager}
            className="events-details-slider-wrapper"
            showGuestInviteText={true}
            sliderWrapperStyle={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "33px",
            }}
            sliderTextStyle={{
              textAlign: "center", // Center the text
            }}
          />
          <SwitchSlider
            leftLabel={labels.left}
            rightLabel={labels.right}
            handleChangeSlider={onToggleEventPrivacy}
            checked={isPrivateEvent}
          />
        </div>
      )}
      {(activeTab === "adminControls" || activeTab === "memberStatus") && (
        <>
          <div className="mb-pad-groups-navtop">
            <SolidColorButton
              solidColorBtnImage={
                <Back
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              className="gold-btn"
              solidColorBtnText="Back"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="100px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              onClick={() => navigate(-1)}
            />
            <SolidColorButton
              solidColorBtnImage={
                <XIcon
                  height="15px"
                  width="15px"
                  fillColor="#964b00"
                  strokeWidth="4"
                  strokeColor="#964b00"
                />
              }
              onClick={onHandleCancel}
              solidColorBtnText="Cancel/Close"
              className="gold-btn"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="100px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
            <SolidColorButton
              className="gold-btn"
              solidColorBtnImage={
                <Filter height="25px" width="25px" fillColor="#964b00" />
              }
              onClick={onResetFilters}
              solidColorBtnText="Reset Filters"
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnWidth="100px"
              solidColorBtnFontColor="#964b00"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
            />
            <div className="bottom-buttons">
              {!hideButtons && (
                <>
                  <GlassBtn
                    glassBtnImage={
                      <Xylophone
                        fillColor="#fdfd96"
                        height="25px"
                        width="25px"
                      />
                    }
                    onClick={onHandleMembersClick}
                    glassBtnText="Members"
                    glassBtnFontColor="#fdfd96"
                    glassBtnTextDecorationColor="#fdfd96"
                    glassBtnTextFontSize="1.3rem"
                    glassBtnHeight="27px"
                    glassBtnFontWeight="800"
                  />
                </>
              )}
            </div>
          </div>
          <div className="group-mb-pad-connections-input-slider-container">
            <div className="connections-input-container blinker-wrapper">
              <input
                value={groupSearchCriteriaValue}
                placeholder={
                  userNameGroupSearchError
                    ? userNameGroupSearchError
                    : "Enter username"
                }
                className={userNameGroupSearchError ? "required-message" : ""}
                type="text"
                onChange={(e) =>
                  handleUserNameChange(e.target.value, "userNameValue")
                }
                onFocus={() => {
                  setIsFocused(true);
                  setGroupSearchCriteriaValue("");
                  setIsGroupSearchInputEmpty(false);
                }}
              />
              {groupSearchCriteriaValue && (
                <button
                  className="Clear-groupDetailsPg"
                  onClick={clearUserNameGroupSearchCriteriaLocalStorage}
                  style={{
                    color: "#fdfd96",
                    backgroundColor: "transparent",
                    borderColor: "#fdfd96",
                  }}
                >
                  Clear
                </button>
              )}
              {!isFocused && groupSearchCriteriaValue === "" && (
                <span className="blinking-cursor blinking">|</span>
              )}
            </div>
            <SlideBtn
              sliderImage={() => <FindUsername fillColor="#964B00" />}
              slideBtnText="Slide to find username"
              slideBtnWidth="300px"
              onHandleSlideEnd={debouncedHandleSlideCompletion}
            />
          </div>
          <div
            className={`mb-pad-party-list btnsContain ${
              show_RedBorder_Required && !isAnyButtonActive() && isSliderSlid
                ? "red-border"
                : ""
            }`}
          >
            {show_RedBorder_Required && (
              <span className="error-message">{userNameGroupSearchError}</span>
            )}
            {activeTab === "memberStatus" && (
              <>
                <ImgTopBtn
                  onClick={onMembersList}
                  image={
                    <span
                      ref={membersRef}
                      className={`memberNum ${
                        currentList === "members"
                          ? "memberNum-active"
                          : "memberNum-inactive"
                      }`}
                      style={{
                        color: currentList === "members" ? "#000" : "#964b00",
                      }}
                    >
                      {membersCount}
                    </span>
                  }
                  imageOnTop={true}
                  imgTopBtnText={
                    currentList === "members"
                      ? "Member(s)"
                      : "Show Member(s) List"
                  }
                  imgTopBtnFontColor={
                    currentList === "members" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextDecorationColor={
                    currentList === "members" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextFontSize=".75rem"
                  imgTopBtnFontWeight="800"
                  imgTopBtnHeight="fit-content"
                  imgTopBtnWidth="fit-content"
                  paddingLeft="10px"
                  paddingRight="10px"
                />
              </>
            )}
            {showAdminControls && !showMemberStatus && (
              <>
                <ImgTopBtn
                  onClick={onInvitedList}
                  image={
                    <span
                      ref={invitedRef}
                      className={`invitedNum ${
                        currentList === "invited"
                          ? "invitedNum-active"
                          : "invitedNum-inactive"
                      }`}
                      style={{
                        color: currentList === "invited" ? "#000" : "#964b00",
                      }}
                    >
                      {invitedCount}
                    </span>
                  }
                  imageOnTop={true}
                  imgTopBtnText={
                    currentList === "invited"
                      ? "Invited List"
                      : "Show Invited List"
                  }
                  imgTopBtnFontColor={
                    currentList === "invited" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextDecorationColor={
                    currentList === "invited" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextFontSize=".75rem"
                  imgTopBtnWidth="fit-content"
                  imgTopBtnFontWeight="800"
                  imgTopBtnHeight="fit-content"
                  paddingLeft="10px"
                  paddingRight="10px"
                />
                <ImgTopBtn
                  onClick={onInterestedList}
                  image={
                    <span
                      ref={interestedRef}
                      className={`interestedNum ${
                        currentList === "invited"
                          ? "interestedNum-active"
                          : "invitedNum-inactive"
                      }`}
                      style={{
                        fontSize: ".75rem",
                        fontWeight: "800",
                        fontFamily: "Helvetica",
                        color: // taking the style attribute, assigning the variable and changing the style via inline
                          currentList === "interested"
                            ? "#000"
                            : "#964b00",
                        WebkitTextStroke:
                          currentList === "interested"
                            ? "1px #000"
                            : "1px #964b00",
                      }}
                    >
                      {interestedCount}
                    </span>
                  }
                  imageOnTop={true}
                  imgTopBtnText={
                    currentList === "interested"
                      ? "interested List"
                      : "Show interested List"
                  }
                  imgTopBtnFontColor={
                    currentList === "interested" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextDecorationColor={
                    currentList === "interested" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextFontSize=".75rem"
                  imgTopBtnWidth="fit-content"
                  imgTopBtnFontWeight="800"
                  imgTopBtnHeight="fit-content"
                  paddingLeft="10px"
                  paddingRight="10px"
                />
                <ImgTopBtn
                  onClick={onDeclinedList}
                  image={
                    <span
                      ref={declinedRef}
                      className={`declinedNum ${
                        currentList === "declined"
                          ? "declinedNum-active"
                          : "declinedNum-inactive"
                      }`}
                      style={{
                        color: currentList === "declined" ? "#000" : "#964b00",
                      }}
                    >
                      {declinedCount}
                    </span>
                  }
                  imageOnTop={true}
                  imgTopBtnText={
                    currentList === "declined"
                      ? "Declined List"
                      : "Show Declined List"
                  }
                  imgTopBtnFontColor={
                    currentList === "declined" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextDecorationColor={
                    currentList === "declined" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextFontSize=".75rem"
                  imgTopBtnFontWeight="800"
                  imgTopBtnHeight="fit-content"
                  imgTopBtnWidth="fit-content"
                  paddingLeft="10px"
                  paddingRight="10px"
                />
                <ImgTopBtn
                  onClick={onExcludedList}
                  image={
                    <span
                      ref={excludedRef}
                      className={`excludedNum ${
                        currentList === "excluded"
                          ? "excludedNum-active"
                          : "excludedNum-inactive"
                      }`}
                      style={{
                        color: currentList === "excluded" ? "#000" : "#964b00",
                      }}
                    >
                      {excludedCount}
                    </span>
                  }
                  imageOnTop={true}
                  imgTopBtnText={
                    currentList === "excluded"
                      ? "Excluded List"
                      : "Show Excluded List"
                  }
                  imgTopBtnFontColor={
                    currentList === "excluded" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextDecorationColor={
                    currentList === "excluded" ? "#000" : "#964b00"
                  }
                  imgTopBtnTextFontSize=".75rem"
                  imgTopBtnFontWeight="800"
                  imgTopBtnHeight="fit-content"
                  imgTopBtnWidth="fit-content"
                  paddingLeft="10px"
                  paddingRight="10px"
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default GroupDetailMbPadNav;
