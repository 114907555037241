// This file is for managing the state of the uploadImage form,
//  we are storing all the values from the upload image form  in to this file.

// Reducers are pure functions that define HOW the app state changes. In other words,
// they are used to recalculate the new application state or, at least a part of it.
// Whenever we dispatch an action to our store, the action gets passed to the reducer.
// The reducer function takes two parameters: the previous app state, the action being dispatched and returns the new app state.
// (previousState, action) => newState

import _startsWith from "lodash/startsWith.js";

// types
import AUTH from "../types/authTypes.js";
import TYPES from "../types/globalTypes.js";

// reducer
import todoReducer from "./todoReducer.js";
import { INITIAL_PLANT_DATA_OBJECT } from "../../utils/authTokenReduxUtils.js";

// utils

const INIT_STATE = {
  uploadImageData: INITIAL_PLANT_DATA_OBJECT,
};

const { PREFIXES } = TYPES;

function uploadImageReducer(state = INIT_STATE, action = {}) {
  const { type } = action;

  if (type === AUTH.UN_AUTHORIZE) return INIT_STATE;

  if (_startsWith(type, PREFIXES.UPLOADIMAGE)) {
    return todoReducer(state, action);
  }

  return state;
}

export default uploadImageReducer;