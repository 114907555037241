import { validatePasswordValue } from "../../constants/validate.js";
export const handleSigninErrors = (
  signInPasswordValue,
  signInPhoneNumberValue,
  setToolTipVisible,
  setSignInPasswordError,
  setSignInPhoneInputError,
  currentContext
) => {
  let errors = {};
  // Check if password field is empty
  if (signInPasswordValue.length === 0 && currentContext === "signin") {
    errors.signInPasswordError = "Required";
  } else {
    const validationResults = validatePasswordValue(signInPasswordValue);
    const isPasswordValid = validationResults.every((rule) => rule.isValid);
    if (!isPasswordValid) {
      errors.signInPasswordError = "Password validation failed";
      setToolTipVisible(true); // Show tooltip
    } else {
      setToolTipVisible(false); // Hide tooltip when password is valid
    }
  }
  // Check if phone number field is empty
  if (currentContext === "signin" && signInPhoneNumberValue.length === 0) {
    errors.signInPhoneInputError = "Required";
  }
  // else if (currentContext === "signin" && (signInPhoneNumberValue.length < 10 || /^(.)\1+$/.test(signInPhoneNumberValue))) {
  //   // Validate if the phone number is at least 10 digits and not all the same digit
  //   errors.signInPhoneInputError = "Invalid phone number";
  // }
  setSignInPasswordError(errors.signInPasswordError || "");
  setSignInPhoneInputError(errors.signInPhoneInputError || "");

  return { errors };
};

export const onResetPassword = (
  resetPassword,
  setContextType,
  setShowAccountVerification,
  setCurrentForm
) => {
  if (resetPassword) {
    // Instead of returning a component, I set a piece of state that will trigger a re-render.
    setShowAccountVerification(true);
  } else {
    localStorage.clear();
    setCurrentForm("enterMobileNumber");
    setContextType("enterMobileNumber");
  }
};
