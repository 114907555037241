// functions that handle log in and logout
const AUTH_SERVICE = {
    LOGIN: "auth/LOGIN",
    LOGOUT: "auth/LOGOUT",
  };
  
  // 2 actions
   // get user profile
   // updating user profile
  const USER_SERVICE = {
    DATA_PROFILE: "user/PROFILE",
    UPDATE_PROFILE: "user/update/PROFILE"
  };
  
  const UPLOADIMAGE_SERVICE = {
    ADD_UPLOADIMAGE: "uploadImage/add/uploadImage",
    UPDATE_UPLOADIMAGE: "uploadImage/update/uploadImage"
  }
  export default { AUTH_SERVICE, USER_SERVICE, UPLOADIMAGE_SERVICE };