// GlobalThreeLineAnimation.jsx

import React, { useState, useEffect } from "react";
import "./cancelDropDownAcct.scss";

const CancelDropDownAcct = ({
  animationType = "1",
  fillColor = "#964b00",
  width="30px", height="30px",
  lineWidth = "50px", // Add lineWidth prop
  lineHeight = "5px", // Add lineHeight prop
  marginTop = 0, // Add marginTop prop
  marginBottom = 0, // Add marginBottom prop
  marginLeft = 0, // Add marginLeft prop
  marginRight = 0, // Add marginRight prop
  customStyles = {}, // Add customStyles prop

}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true); // Automatically apply the animation when the component mounts.
  }, []);

  return (
    <div
      className={`cancelDropDownAcct${isActive ? " is-active" : ""}`}
      id={`cancelDropDownAcct-${animationType}`}
      style={{
        height,
        width,
        position: 'relative', 
        marginTop,     
        marginBottom,   
        marginLeft,      
        marginRight, 
        ...customStyles, // Merge customStyles with component styles
     
      }} // Set height and width styles
    >
      <span className="line" style={{ backgroundColor: fillColor, width: lineWidth, height: lineHeight  }}></span>
      <span className="line" style={{ backgroundColor: fillColor, width: lineWidth, height: lineHeight  }}></span>
      <span className="line" style={{ backgroundColor: fillColor, width: lineWidth, height: lineHeight  }}></span>
      {/* using an ojbect or temperal literal for absolute targeting like you would in pure js this creates it's own object as well */}
      <style>{`
        .cancelDropDownAcct .line:before {
          background-color: ${fillColor} !important;
        }
        @media screen and (max-width: 1130px) {
          .cancelDropDownAcct .line:before {
            background-color: ${fillColor} !important;
          }
        }
      `}</style>
    </div>
  );
};

export default CancelDropDownAcct;
