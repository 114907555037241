import React from 'react';
import { CheckMark } from "../../../components/icons";
import "./customCheckBox.scss";

const CustomCheckbox = ({ label, name, checked, onChange, strokeColor = "#e2b645", checkmarkColor = "#e2b645" }) => {
    return (
        <div className="custom-checkbox">
            <input 
                type="checkbox" 
                id={name} 
                name={name} 
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={name}>
                <div className="checkmark-container">
                    {checked && <CheckMark fill={checkmarkColor} stroke={strokeColor}/>} 
                </div>
                <span className="checkbox-label">{label}</span>
            </label>
        </div>
    );
};

export default CustomCheckbox;
