import React, { useState } from "react";
import NotificationCard from "../../components/notificationCard/notificationCard";
import mockData from "../../seedMockData/seedMockData";
import "./notificationPopupListPage.scss";

const NotificationPopupListPage = () => {
  const [notificationMessages, setNotificationMessages] = useState(getNotificationMessages()); //  State for storing generated notification messages

  
  function generateNotificationMessage(activityLogs) {
    const sourceUser = mockData.users.find(user => user.id === activityLogs.sourceUserId);
    const targetUser = activityLogs.targetUserId ? 
                       mockData.users.find(user => user.id === activityLogs.targetUserId) : 
                       null;
    let message = "";
    switch (activityLogs.activity) {
      case "following":
        message = `${sourceUser.username} started following you`;
        break;
      case "liked":
        message = `${sourceUser.username} liked your post`;
        break;
      case "following":
        message = `${sourceUser.username} is following ${targetUser.username}`;
        break;
      case "invited":
        message = `${sourceUser.username} sent a chat request ${targetUser.username}`;
        break;
      case "birthday event":
        message = `${sourceUser.username} wishes ${targetUser.username} a happy birthday`;
        break;
      default:
      // ... other cases for different activity types ...
    }
    console.log("Generated notification message: ", message);
    return message;
  }
  // Given a notification, get the sourceUser and targetUser details
  // Function to get notification messages for all notifiable activity logs
  function getNotificationMessages(loggedInUserId) {
    return mockData.activityLogs
        .filter(log => 
            log.isNotifiable && 
            log.targetUserId && 
            log.targetUserId === loggedInUserId &&
            mockData.users.find(user => user.id === loggedInUserId).isNotifiable
        )
        .map(log => {
            const sourceUser = mockData.users.find(user => user.id === log.sourceUserId);
            const targetUser = mockData.users.find(user => user.id === log.targetUserId);
            return {
                id: log.id,
                message: generateNotificationMessage(log, sourceUser, targetUser),
                sourceUser
            };
        });
}
return (
  <div className="notifications-list">
      {notificationMessages.map(notification => (
          <NotificationCard
              key={notification.id}
              notification={notification.message}
              sourceUsername={notification.sourceUser.username}
              className="notification-card-spacing"
          />
      ))}
  </div>
);
};
export default NotificationPopupListPage;

// If you want users to receive notifications in real-time (i.e., instantly when an event happens without having to refresh the page), you'd need to use technologies like WebSockets or third-party services like Pusher or Firebase Realtime Database.
// Reading and Updating Notification Status:

// When a user reads a notification, an event can be triggered (e.g., clicking on the notification) that sends a request to the server to mark the notification as read.
// To summarize, yes, event listeners are crucial in this process, but they are just a part of a larger system. Depending on your application's architecture and requirements, you'd integrate event listeners, server-side logic, and possibly real-time technologies to create a seamless notification experience for your users.



