import { getStorageKey } from "../../utils/localStorageUniqueKeyGenerator.js";


export const handleTextarea = (
  value,
  keyContext,
  currentContext,
  textareaRef,
  setDescriptionValue,
  setDescriptionValueError,
) => {
  const formattedValue = formatTextWithBulletPoints(value);
  setDescriptionValue(formattedValue);
  const maxAllowedCharacters = 150; // Define the maximum allowed characters
  const currentLength = value.length;
  if (currentLength > maxAllowedCharacters) {
    // If length exceeds max characters, truncate and display error
    const truncatedValue = value.substring(0, maxAllowedCharacters);
    setDescriptionValue(truncatedValue); // Update state with truncated value
    setDescriptionValueError("Maximum 150 characters allowed"); // Display error message
    // Update localStorage with truncated value
    const bioDescriptionKey = getStorageKey(keyContext, currentContext);
    localStorage.setItem(bioDescriptionKey, truncatedValue);
  } else {
    // Update the value within the limit and clear any error message
    setDescriptionValue(value);
    setDescriptionValueError(""); // Clear error message

    // Update localStorage with the new value
    const bioDescriptionKey = getStorageKey(keyContext, currentContext);
    localStorage.setItem(bioDescriptionKey, value);
  }
  // Update the number of lines for textarea resizing
  const numOfLines = (value.match(/\n/g) || []).length + 1;
  textareaRef.current.rows = Math.max(2, numOfLines);
};
export const handleTextareaResize = (textareaRef) => {
  const textarea = textareaRef.current;
  if (textarea) {
    const textarea = textareaRef.current;
    const newHeight = textarea.scrollHeight; // comment out the bottom if i just want the scroll else keep it to help the text area grow
    // this will allow the text area to grow as the user is typing
    textarea.style.height = `${newHeight}px`; // Adjust the height of the textarea
  }
};
// Clear function
export const clearBioForm = (
  setDescriptionValue,
  setDescriptionValueError,
  clearDescriptionValueLocalStorage,
  textareaRef
) => {
  setDescriptionValue("");
  setDescriptionValueError("");
  clearDescriptionValueLocalStorage();
  if (textareaRef.current) {
    textareaRef.current.style.height = "100px"; // reset the text area height
  }
};
export const handleTextAreaBulletPoint = (
  event,
  descriptionValue,
  setDescriptionValue
) => {
  const bullet = "\u2022 ";
  let newText = descriptionValue;
  const cursorPosition = event.target.selectionStart;
  if (event.key === "Enter") {
    // User presses Enter, add bullet point in new line
    event.preventDefault();
    newText =
      newText.substring(0, cursorPosition) +
      "\n" +
      bullet +
      newText.substring(cursorPosition);
    setDescriptionValue(newText);
  } else if (newText.trim() === "" && event.key !== "Enter") {
    // First character in an empty textarea, add bullet point
    setDescriptionValue(bullet + newText);
  }
};
export const formatTextWithBulletPoints = (text, bulletPointsVisible) => {
  if (!bulletPointsVisible) {
    // Remove bullet points if they are not visible
    return text.replace(/^\u2022\s/gm, ""); // Assumes bullet points are • character
  }
  return text; // Return text as is if bullet points are visible
};

export const clearDescriptionValueLocalStorage = (
    currentContext,
    setDescriptionValue
  ) => {
    // Reset the description value
    setDescriptionValue("");
    // Construct the key for localStorage
    const bioDescriptionKey = getStorageKey("bioDescriptioValue", currentContext);
    // Remove the item from localStorage
    localStorage.removeItem(bioDescriptionKey);
  };