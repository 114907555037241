import { SlideBtn } from "../index.jsx";
import CurrentDayTime from "../../currentDayTime/currentDayTime.jsx";
import { CommunityIcon } from "../../icons";
import { EventPopup } from "../../forms/index.jsx";

// Similar to CreateEventBtn but for updating events
const UpdateEventSlider = ({
  currentEventData,
  onHandleSlideEnd,
  eventFormOpen, // state is recieved from the event details page
  togglePopup,
  eventPopup,
}) => {
  // Rest of the logic remains almost the same
  const handleSlideEnd = () => {
    togglePopup();
    onHandleSlideEnd();
  };
  return (
    <div className="update-event-slider">
      {" "}
      {/* Added class name here */}
      <div className="events-btn">
        <CurrentDayTime />
        <SlideBtn
          slideBtnText="+ Update Event"
          slideBtnWidth="fit-content"
          slidBtnFontSize=".75rem"
          style={{
            width: '200px',
            border: '1px solid rgba(0, 0, 0, 0.6)',
            paddingLeft:"20px",
            paddingRight:"20px",
          }}
          sliderImage={() => (
            <CommunityIcon fillColor="#964B00" width="20px" height="20px" />
          )}
          onClick={togglePopup}
          onHandleSlideEnd={handleSlideEnd}
        />
      </div>
      {eventFormOpen && (
        <div>
          <div
            className={`overlay ${eventFormOpen ? "active" : ""}`}
            onClick={togglePopup}
          ></div>
          <div className="popup">
            {eventFormOpen && eventPopup}
            <div className="settings-container">
              <EventPopup isUpdate={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UpdateEventSlider;
