
export const handleBioFormErrors = (
  descriptionValue,
  currentContext,
  setDescriptionValueError
) => {
  let errors = {};
  // console.log("Starting validation process");
  // returning true or false to see if the current context is reset password and if the imput is empty at
  // the time of the event to fix the required not returning
  // console.log(" descriptionValue empty:", descriptionValue.length === 0);
  // console.log("currentContext is bio:", currentContext === "bio");
  if (currentContext === "bio" && descriptionValue.length === 0) {
    errors.descriptionValueError = "Required";
    setDescriptionValueError(errors.descriptionValueError);
  } else {
    setDescriptionValueError(""); // Clears any existing error
  }
  // console.log("Errors:", errors); // Logging errors outside the if-else block
  return { errors };
};

