import React, { useState, useEffect, forwardRef } from "react";
import "./glassBtn.scss";

const GlassBtnComponent = (
  {
    reverseOrder = false,
    imageOnTop = false,
    glassBtnText,
    onClick,
    glassBtnWidth = "200px",
    fullwidthGlassHi = false,
    sparkle = false,
    glassConfirm = false,
    className = "glass-btn",
    disabled = false,
    isSubmit = false,
    glassBtnImage = "",
    glassBtnTextDecorationColor = "#964B00",
    glassBtnTextFontSize = ".75rem",
    glassBtnFontFamily = "roboto-slab",
    glassBtnFontWeight = "900",
    glassBtnFontColor = "#964B00",
    glassBtnHeight = "37px",
    glassBtnPosition = "relative",
    glassBtnTextDecoration = "underline",
    glassBtnTextAlign = "center",
    glassBtnTextShadow = "0 0 black",
    glassBtnBorderRadius = "8px",
    glassBtnTransition = "all 0.1s ease-in-out",
    glassBtnOutline = "0",
    glassBtnLineHeight = "20px",
    glassBtnMarginLeft = "auto",
    glassBtnMarginRight = "auto",
    glassBtnMarginTop,
    glassBtnBoxShadow,
    hi2Style = {}, // Default to empty object if not provided
    hi3Style = {}, // Default to empty object if not provided
    localHi2Class = "", // style is set on the parent here and passing it in
    localHi3Class = "", // style is set on the parent here and passing it in
    glassHoverStyle = {}, // Custom style for the glasshover element
    top,
    extraStyles = {},
    animations = {}, // Pass an object to toggle animations
    textLocalStyle = {},
  },
  ref
) => {
  // Activating animations globally
  const [localAnimations, setLocalAnimations] = useState({
    glow: false,
  });

  useEffect(() => {
    setLocalAnimations((prev) => ({ ...prev, ...animations }));
  }, []);

  const handleGlowEffect = () => {
    setLocalAnimations({ glow: true });
    setTimeout(() => setLocalAnimations({ glow: false }), 2000);
  };

  const handleButtonClick = (e) => {
    if (onClick) onClick(e);
    handleGlowEffect();
  };

  return (
    <div
      ref={ref}
      className={`button glass ${fullwidthGlassHi ? "fullwidth-glasshi" : ""} ${
        sparkle ? "sparkle" : ""
      } ${glassConfirm ? "glassConfirm" : ""} ${
        localAnimations.glow
          ? "glow-on-glass-animation glow-on-press show-animation"
          : ""
      } ${className}`}
      onClick={handleButtonClick}
      style={{
        width: glassBtnWidth,
        height: glassBtnHeight,
        boxShadow: `${glassBtnBoxShadow} !important`,
        position: glassBtnPosition,
        textDecoration: glassBtnTextDecoration,
        textDecorationColor: glassBtnTextDecorationColor,
        textAlign: glassBtnTextAlign,
        textShadow: glassBtnTextShadow,
        borderRadius: glassBtnBorderRadius,
        transition: glassBtnTransition,
        outline: glassBtnOutline,
        lineHeight: glassBtnLineHeight,
        marginLeft: glassBtnMarginLeft,
        marginRight: glassBtnMarginRight,
        marginTop: glassBtnMarginTop,
        top: top,
        ...extraStyles,
      }}
    >
      {/* Button content here */}
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: imageOnTop ? "column" : "row",
          alignItems: "center",
        }}
      >
        {/* ... Rest of your content */}
        {imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
        {!reverseOrder && !imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
        <span
          style={{
            color: glassBtnFontColor,
            fontSize: glassBtnTextFontSize,
            fontFamily: glassBtnFontFamily,
            fontWeight: glassBtnFontWeight,
            ...textLocalStyle, // New prop for custom styles
          }}
        >
          {glassBtnText}
        </span>
        {reverseOrder && !imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
      </div>
      {/* Additional divs like glasshover, glassref, hi2, hi3 */}
      <div
        className="glasshover"
        style={{
          ...{ width: `calc(${glassBtnWidth} - 2px)` },
          ...glassHoverStyle,
        }}
      ></div>
      <div
        className={`glassref ${className}`}
        style={{ width: glassBtnWidth }}
      />
      <div
        id="hi2"
        className={`glasshi ${localHi2Class}`}
        style={hi2Style} // Apply local class
      />
      <div
        id="hi3"
        className={`glasshi ${localHi3Class}`}
        style={hi3Style} // Apply local class
      />
    </div>
  );
};


const GlassBtnRef = React.forwardRef(GlassBtnComponent);
export default GlassBtnRef;
