import { useState } from "react";
import { users } from "./users";
import Book from "../../components/book/book";
import AuthorizationForm from "../../components/forms/authorizationForm.jsx";
import "./landingPage.scss";
import "../../index.scss";

const LandingPage = () => {
  const [currentForm, setCurrentForm] = useState("signIn");
  const [contextType, setContextType] = useState("signIn");
  // useEffect(() => {
  //   const timer = setTimeout(() => { // animation to controll the scroll so user can see the signup form on mount for mobile view
  //     const formSection = document.querySelector('.form-section'); // passing in the section class on where I want the scroll to stop
  //     if (formSection) {
  //       const formSectionTop = formSection.getBoundingClientRect().top + window.scrollY;
  //       window.scrollTo({
  //         top: formSectionTop,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }, 3000); // 3 second delay so user can experience the book animation

  //   return () => clearTimeout(timer); // Clear the timeout if the component unmounts before the timeout
  // }, []);

  return (
    <div id="landing-page">
      <div className="landing-page-content-wrapper">
        <div className="book-section">
          <Book users={users} />
        </div>

        <div className="form-section">
          <AuthorizationForm
             currentForm={currentForm}
             setContextType={setContextType}
             setCurrentForm={setCurrentForm}
             contextType={contextType} // Pass contextType as a prop
          />
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
