import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useAuth } from "../../../context/authContext";
import { apiSignUp } from "../../../api/auth";
import { UnlockSlideBtn, CustomButton, WhiteBtn } from "../../buttons";
import { getStorageKey } from "../../../utils/localStorageUniqueKeyGenerator.js";
import {
  handleUserNameValueChange,
  handlefirstNameValueChange,
  handleLastNameChange,
  handleEmailChange,
  handlePhoneInputChange,
  handleCurrentPasswordValueChange,
  handleNewPasswordChange,
  onNewPasswordValueFocused,
  onNewPasswordValueBlur,
  handleConfirmPasswordChange,
  onCurrentPasswordValueFocused,
  onCurrentPasswordValueBlur,
  onUserNameValueFocused,
  onUserNameValueBlur,
  handleOriginalPasswordChange,
  onOriginalPasswordValueFocused,
  onOriginalPasswordValueBlur,
  clearSettingsNameSectionLocalStorage,
  clearSettingsContactInfoSectionLocalStorage,
  clearSettingsPasswordSectionSectionLocalStorage,
} from "../../../controller/watcherAndClearFunctions/signupSettingsClearsWatchersFunctions.js";
import { signupErrorHandler } from "../../../controller/errorHandlers/signupSettingsErrorHandler.js";
import {
  validatePasswordValue,
  validateUserNameValue,
} from "../../../constants/validate";
import ToolTip from "../../tooltip/tooltip";
import { EyeSlashIcon, EyeIcon } from "../../icons";
import Twisty from "./twisty.jsx";
// import { required } from "../../../utils/validate.js";
import "../formsCommon.scss";
import "./signUp.scss";
// Custom hook for managing password input fields which will be passed to the component below
// so each password input can call the same datavalidation rules and still be independent with state
// the tool tip is not showing when i type on this form...it prob was not included for settings but it needs to be
const SignUp = ({
  setCurrentForm,
  setContextType,
  contextType,
  isSettings = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [errors, setErrors] = useState({});
  const [signupAttempted, setSignupAttempted] = useState(false);
  const currentContext = isSettings ? "settings" : "signup";
  const [userNameValueFocused, setUserNameValueFocused] = useState(false);
  const [firstNameValueFocused, setFirstNameValueFocused] = useState(false);
  const [currentPasswordValueFocused, setCurrentPasswordValueFocused] =
    useState(false);
  const [newPasswordValueFocused, setNewPasswordValueFocused] = useState(false);
  const [originalPasswordValueFocused, setOriginalPasswordValueFocused] =
    useState(false);
  const [showPhoneVerif, setShowPhoneVerif] = useState(false);
  const [userNameValueError, setUserNameValueError] = useState("");
  const [firstNameValueError, setFirstNameValueError] = useState("");
  const [lastNameValueError, setLastNameValueError] = useState("");
  const [emailValueError, setEmailValueError] = useState("");
  const [phoneNumberValueError, setPhoneNumberValueError] = useState("");
  const [confirmPasswordValueError, setConfirmPasswordValueError] =
    useState("");
  const [newPasswordValueError, setNewPasswordValueError] = useState(false);
  const [currentPasswordValueError, setCurrentPasswordValueError] =
    useState("");
  const [originalPasswordValueError, setOriginalPasswordValueError] =
    useState("");
  const [passwordValidationRules, setPasswordValidationRules] = useState([
    false,
  ]);
  const [originalPasswordValidationRules, setOriginalPasswordValidationRules] =
    useState([false]);
  const [newPasswordValidationRules, setNewPasswordValidationRules] = useState([
    false,
  ]);
  const [userNameValidationRules, setUserNameValidationRules] = useState([
    false,
  ]);
  const [userNameToolTipVisible, setUserNameToolTipVisible] = useState(false);
  const [currentPasswordToolTipVisible, setCurrentPasswordToolTipVisible] =
    useState(false);
  const [newPasswordToolTipVisible, setNewPasswordToolTipVisible] =
    useState(false);
  const [originalPasswordToolTipVisible, setOriginalPasswordToolTipVisible] =
    useState(false);
  const [confirmPasswordValueFocused, setConfirmPasswordValueFocused] =
    useState(false);
  const [confirmPasswordValueValue, setConfirmPasswordValueValue] =
    useState("");
  const [showconfirmPasswordValue, setShowconfirmPasswordValue] =
    useState(false);
  const [updateNameVisible, setUpdateNameVisible] = useState(false);
  const [updateContactInfoVisible, setUpdateContactInfoVisible] =
    useState(false);
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);
  const [showNewPasswordValue, setShowNewPasswordValue] = useState("");
  const [showCurrentPasswordValue, setShowCurrentPasswordValue] =
    useState(false);
  const [showOriginalPasswordValue, setShowOriginalPasswordValue] =
    useState(false);
  const [firstNameValue, setFirstNameValue] = useState(
    localStorage.getItem(getStorageKey("firstNameValue", currentContext)) || ""
  );
  const [lastNameValue, setLastNameValue] = useState(
    localStorage.getItem(getStorageKey("lastNameValue", currentContext)) || ""
  );
  const [userNameValue, setUserNameValue] = useState(
    localStorage.getItem(getStorageKey("userNameValue", currentContext)) || ""
  );
  const [emailValue, setEmailValue] = useState(
    localStorage.getItem(getStorageKey("emailValue", currentContext)) || ""
  );
  const [phoneNumberValue, setPhoneNumberValue] = useState(
    localStorage.getItem(getStorageKey("phoneNumberValue", currentContext)) ||
      ""
  );
  const [originalPasswordValue, setOriginalPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("originalPasswordValue", currentContext)
    ) || ""
  );
  const [newPasswordValue, setNewPasswordValue] = useState(
    localStorage.getItem(getStorageKey("newPasswordValue", currentContext)) ||
      ""
  );
  const [confirmPasswordValue, setConfirmPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("confirmPasswordValue", currentContext)
    ) || ""
  );
  const [currentPasswordValue, setCurrentPasswordValue] = useState(
    localStorage.getItem(
      getStorageKey("currentPasswordValue", currentContext)
    ) || ""
  );
  const onSignup = async () => {
    setSignupAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  // UseEffect to trigger validation on specific events (e.g., form submission)
  useEffect(() => {
    if (signupAttempted) {
      const { errors } = signupErrorHandler(
        firstNameValue, // values first order matters
        lastNameValue,
        emailValue,
        userNameValue,
        phoneNumberValue,
        confirmPasswordValue,
        currentPasswordValue,
        originalPasswordValue,
        newPasswordValue,
        currentContext, // this always needs to be after the values
        setFirstNameValueError, // setters next order matters
        setLastNameValueError,
        setEmailValueError,
        setUserNameValueError,
        setUserNameToolTipVisible,
        setPhoneNumberValueError,
        setConfirmPasswordValueError,
        setCurrentPasswordValueError,
        setCurrentPasswordToolTipVisible,
        setOriginalPasswordValueError,
        setOriginalPasswordToolTipVisible,
        setNewPasswordValueError,
        setNewPasswordToolTipVisible
      );
      if (!errors) {
        // Handle validation errors here
      }
      setSignupAttempted(false); // Reset for next submission attempt
    }
  }, [signupAttempted, currentPasswordValue, userNameValue]);
  const onUpdateSettings = async () => {
    // setSignupAttempted(true); // Trigger validation
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };

  return (
    // style={updateFormSettingStyle} add this to the wrapper class div if I want to change the style for the settings page
    <div className="form-wrapper">
      <form
        id="sign-up-form"
        style={{
          display:
            updateNameVisible ||
            updatePasswordVisible ||
            updateContactInfoVisible
              ? "flex"
              : "block",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="button-container"
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {isSettings && (
            <WhiteBtn
              whiteBtnImage={
                updateNameVisible ? (
                  <Twisty
                    fillColor="#964b00"
                    height="45px"
                    width="35px"
                    lineWidth="20px"
                    lineHeight="5px"
                    animationType="6"
                  />
                ) : (
                  <Twisty
                    fillColor="#964b00"
                    height="25px"
                    width="25px"
                    lineWidth="15px"
                    lineHeight="5px"
                    animationType="5"
                  />
                )
              }
              onClick={() => {
                if (updateNameVisible) {
                  clearSettingsNameSectionLocalStorage(
                    currentContext,
                    setFirstNameValue,
                    setLastNameValue,
                    setUserNameValue
                  );
                  setUpdateNameVisible(false);
                } else {
                  setUpdateNameVisible(true);
                }
              }}
              whiteBtnText={
                updateNameVisible
                  ? "Cancel Update Name(s)"
                  : "Full/user name fields"
              }
              textStyle={updateNameVisible ? {} : { marginRight: "12px" }}
              whiteBtnFontColor="#964b00"
              whiteBtnwidth="290px"
              whiteBtnfontSize="1rem"
              whiteBtnHeight="10px"
              whiteBtnBorderRadius="35px"
              whiteBtnFontFamily="Roboto-Slab"
              whiteBtnFontWeight="900"
              whiteBtnAnimationActive="true"
              whiteBtnBorderColor="#964b00"
              extraStyles={{
                marginBottom: "10px",
                marginTop: "10px",
                boxShadow:
                  "0 4px 3px 1px rgba(0, 0, 0, 0.6), 0 6px 8px rgba(0, 0, 0, 0.6), 0 -4px 4px rgba(0, 0, 0, 0.6), 0 -6px 4px rgba(0, 0, 0, 0.6), inset 0 0 3px 0 rgba(0, 0, 0, 0.6)",
              }}
            />
          )}
        </div>
        {(!isSettings || updateNameVisible) && (
          <section className="full-name-section">
            <div className="input-group blinker-wrapper">
              <label htmlFor="first_name">
                {isSettings ? "Update Your First Name:" : "First Name:"}
              </label>
              <input
                type="text"
                id="first_name"
                autoComplete="given-name"
                placeholder={
                  firstNameValueError
                    ? firstNameValueError
                    : "Enter your legal first name here"
                }
                className={firstNameValueError ? "red-placeholder" : ""}
                value={firstNameValue}
                onChange={(e) =>
                  handlefirstNameValueChange(
                    e.target.value,
                    currentContext,
                    setFirstNameValue,
                    firstNameValueError,
                    setFirstNameValueError
                  )
                }
                onFocus={() => setFirstNameValueFocused(true)}
                onBlur={() => setFirstNameValueFocused(false)}
              />
              <span className="blinking-cursor signup-cursor">|</span>
            </div>
            <div className="input-group blinker-wrapper">
              <label htmlFor="last_name">
                {isSettings ? "Update Your Last Name:" : "Last Name:"}
              </label>
              <input
                type="text"
                id="last_name"
                autoComplete="family-name"
                placeholder={
                  lastNameValueError
                    ? lastNameValueError
                    : "Enter your legal last name here"
                }
                className={lastNameValueError ? "red-placeholder" : ""}
                value={lastNameValue}
                onChange={(e) =>
                  handleLastNameChange(
                    e.target.value,
                    lastNameValueError,
                    currentContext,
                    setLastNameValue,
                    getStorageKey,
                    setLastNameValueError
                  )
                }
              />
              <span className="blinking-cursor signup-cursor">|</span>
            </div>
          </section>
        )}
        {(!isSettings || updateNameVisible) && (
          <div className="input-group-with-tooltip">
            <div className="input-group blinker-wrapper">
              <label htmlFor="userNameValue">
                {!isSettings
                  ? "Create a userNameValue:"
                  : "Update Your userNameValue:"}
              </label>
              <input
                id="userNameValue"
                autoComplete="off"
                type="text"
                placeholder={
                  userNameValueError
                    ? userNameValueError
                    : "Users will find you by userNameValue"
                }
                className={userNameValueError ? "red-placeholder" : ""}
                value={userNameValue}
                onChange={(e) =>
                  handleUserNameValueChange(
                    e.target.value,
                    setUserNameValue,
                    currentContext,
                    validateUserNameValue,
                    setUserNameValidationRules,
                    setUserNameToolTipVisible,
                    userNameValueError,
                    setUserNameValueError
                  )
                }
                onFocus={() =>
                  onUserNameValueFocused(
                    userNameValue,
                    validateUserNameValue,
                    setUserNameValueFocused,
                    setUserNameValidationRules,
                    setUserNameToolTipVisible
                  )
                }
                onBlur={() =>
                  onUserNameValueBlur(
                    setUserNameValueFocused,
                    setUserNameToolTipVisible
                  )
                }
              />
              <span className="blinking-cursor signup-cursor">|</span>
            </div>
            {(userNameValueFocused || userNameValueError) && (
              <ToolTip
                rules={userNameValidationRules}
                toolTipVisible={userNameToolTipVisible}
                setToolTipVisible={setUserNameToolTipVisible}
              />
            )}
          </div>
        )}
        <div className="input-group blinker-wrapper">
          <div
            className="button-container"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isSettings && (
              <WhiteBtn
                whiteBtnImage={
                  updateContactInfoVisible ? (
                    <Twisty
                      fillColor="#964b00"
                      height="45px"
                      width="35px"
                      lineWidth="20px"
                      lineHeight="5px"
                      animationType="1"
                      right="5px"
                    />
                  ) : (
                    <Twisty
                      fillColor="#964b00"
                      height="25px"
                      width="25px"
                      lineWidth="15px"
                      lineHeight="5px"
                      animationType="5"
                    />
                  )
                }
                onClick={() => {
                  if (updateContactInfoVisible) {
                    clearSettingsContactInfoSectionLocalStorage(
                      currentContext,
                      setEmailValue,
                      setPhoneNumberValue
                    );
                    setUpdateContactInfoVisible(false);
                  } else {
                    setUpdateContactInfoVisible(true);
                  }
                }}
                whiteBtnText={
                  updateContactInfoVisible
                    ? "Cancel Update Contact Info"
                    : "Contact Info"
                }
                textStyle={
                  updateContactInfoVisible ? {} : { marginRight: "80px" }
                }
                whiteBtnFontColor="#964b00"
                whiteBtnwidth="290px"
                whiteBtnfontSize="1rem"
                whiteBtnHeight="10px"
                whiteBtnBorderRadius="35px"
                whiteBtnFontFamily="Roboto-Slab"
                whiteBtnFontWeight="900"
                whiteBtnAnimationActive="true"
                whiteBtnBorderColor="#964b00"
                extraStyles={{
                  boxShadow:
                    "0 4px 3px 1px rgba(0, 0, 0, 0.6), 0 6px 8px rgba(0, 0, 0, 0.6), 0 -4px 4px rgba(0, 0, 0, 0.6), 0 -6px 4px rgba(0, 0, 0, 0.6), inset 0 0 3px 0 rgba(0, 0, 0, 0.6)",
                }}
              />
            )}
          </div>
          {(!isSettings || updateContactInfoVisible) && (
            <>
              <label htmlFor="emailValue">
                {isSettings ? "Update Your email:" : "email:"}
              </label>
              <input
                type="emailValue"
                id="emailValue"
                autoComplete="off"
                placeholder={
                  emailValueError ? emailValueError : "Enter emailValue here"
                }
                className={emailValueError ? "red-placeholder" : ""}
                value={emailValue}
                onChange={(e) =>
                  handleEmailChange(
                    e.target.value,
                    setEmailValue,
                    currentContext,
                    getStorageKey,
                    emailValueError,
                    setEmailValueError
                  )
                }
              />
              {!isFocused && (
                <span
                  className={`blinking-cursor ${
                    isSettings
                      ? "settings-emailValue-cursor"
                      : "signup-emailValue--cursor"
                  }`}
                >
                  |
                </span>
              )}
              <label htmlFor="phone_number">
                {isSettings
                  ? "Update Your Mobile Number:"
                  : "Enter Your Mobile Number:"}
              </label>
              <PhoneInput
                type="text"
                country={"us"}
                autoComplete="phone_number"
                placeholder={phoneNumberValueError}
                className={phoneNumberValueError ? "red-placeholder" : ""}
                inputClass="custom-placeholder-color"
                value={phoneNumberValue}
                onChange={(value) =>
                  handlePhoneInputChange(
                    value,
                    setPhoneNumberValue,
                    currentContext,
                    getStorageKey,
                    phoneNumberValueError,
                    setPhoneNumberValueError
                  )
                }
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                error={!!phoneNumberValueError.phone_number}
                inputProps={{
                  id: "phone_number",
                  required: true,
                }}
                helperText={
                  phoneNumberValueError.phone_number && "Invalid phone number"
                }
              />
              {!isFocused && phoneNumberValue === "" && (
                <span
                  className={`blinking-cursor ${
                    isSettings
                      ? "settings-mobile-cursor"
                      : "signup-mobile-cursor"
                  }`}
                >
                  |
                </span>
              )}
            </>
          )}
        </div>
        <div className="input-group-with-tooltip">
          <div
            className="button-container"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isSettings && (
              <WhiteBtn
                whiteBtnImage={
                  updatePasswordVisible ? (
                    <Twisty
                      fillColor="#964b00"
                      height="45px"
                      width="35px"
                      lineWidth="20px"
                      lineHeight="5px"
                      animationType="11"
                    />
                  ) : (
                    <Twisty
                      fillColor="#964b00"
                      height="25px"
                      width="25px"
                      lineWidth="15px"
                      lineHeight="5px"
                      animationType="5"
                    />
                  )
                }
                onClick={() => {
                  if (updatePasswordVisible) {
                    clearSettingsPasswordSectionSectionLocalStorage(
                      currentContext,
                      setOriginalPasswordValue,
                      setNewPasswordValue
                    );
                    setUpdatePasswordVisible(false);
                  } else {
                    setUpdatePasswordVisible(true);
                  }
                }}
                whiteBtnText={
                  updatePasswordVisible
                    ? "Cancel Update Password"
                    : "Password fields"
                }
                textStyle={updatePasswordVisible ? {} : { marginRight: "55px" }}
                whiteBtnFontColor="#964b00"
                whiteBtnwidth="280px"
                whiteBtnfontSize="1rem"
                whiteBtnHeight="10px"
                whiteBtnBorderRadius="35px"
                whiteBtnFontFamily="Roboto-Slab"
                whiteBtnFontWeight="900"
                whiteBtnAnimationActive="true"
                whiteBtnBorderColor="#964b00"
                extraStyles={{
                  boxShadow:
                    "0 4px 3px 1px rgba(0, 0, 0, 0.6), 0 6px 8px rgba(0, 0, 0, 0.6), 0 -4px 4px rgba(0, 0, 0, 0.6), 0 -6px 4px rgba(0, 0, 0, 0.6), inset 0 0 3px 0 rgba(0, 0, 0, 0.6)",
                }}
              />
            )}
          </div>
        </div>
        {!isSettings && (
          <>
            <div className="input-group-with-tooltip">
              <div className="input-group blinker-wrapper">
                <label htmlFor="currentPasswordValueValue">
                  Create a Password:
                </label>
                <input
                  type={showCurrentPasswordValue ? "text" : "password"}
                  id="currentPasswordValueValue"
                  placeholder={
                    currentPasswordValueError
                      ? currentPasswordValueError
                      : "Enter Password Here"
                  }
                  className={currentPasswordValueError ? "red-placeholder" : ""}
                  value={currentPasswordValue}
                  onChange={(e) =>
                    handleCurrentPasswordValueChange(
                      e.target.value,
                      setCurrentPasswordValue,
                      currentContext,
                      getStorageKey,
                      validatePasswordValue,
                      setPasswordValidationRules,
                      setCurrentPasswordToolTipVisible,
                      currentPasswordValueError,
                      setCurrentPasswordValueError
                    )
                  }
                  onFocus={() =>
                    onCurrentPasswordValueFocused(
                      currentPasswordValue,
                      validatePasswordValue,
                      setCurrentPasswordValueFocused,
                      setPasswordValidationRules,
                      setCurrentPasswordToolTipVisible
                    )
                  }
                  onBlur={() =>
                    onCurrentPasswordValueBlur(
                      setCurrentPasswordValueFocused,
                      setCurrentPasswordToolTipVisible
                    )
                  }
                />
                {!currentPasswordValueFocused && (
                  <span className="blinking-cursor signup-password-cursor">
                    |
                  </span>
                )}
                {/* it is actually intialized as undefined since it is paired with confirm password see notes below */}
                {currentPasswordValue && currentPasswordValue !== "" && (
                  <div
                    className="show-hide-password-toggle"
                    onClick={(e) => {
                      setShowCurrentPasswordValue((prev) => !prev);
                    }}
                  >
                    {showCurrentPasswordValue ? (
                      <EyeSlashIcon width="20" height="15" />
                    ) : (
                      <EyeIcon width="20" height="15" />
                    )}
                    <span className="show-hide-label">
                      {showCurrentPasswordValue ? "Hide" : "Show"}
                    </span>
                  </div>
                )}
                {(currentPasswordValueFocused || currentPasswordValueError) && (
                  <ToolTip
                    rules={passwordValidationRules}
                    toolTipVisible={currentPasswordToolTipVisible}
                    setToolTipVisible={setCurrentPasswordToolTipVisible}
                  />
                )}
              </div>
            </div>
            {/* This is the closing tag for 'input-group-with-tooltip' */}
            <div className="input-group blinker-wrapper">
              <label htmlFor="confirm_password">Confirm Password:&nbsp;</label>
              <input
                type={showconfirmPasswordValue ? "text" : "password"}
                id="confirm_password"
                placeholder={
                  confirmPasswordValueError
                    ? confirmPasswordValueError
                    : "Confirm your password"
                }
                className={confirmPasswordValueError ? "red-placeholder" : ""}
                value={confirmPasswordValue}
                onChange={(e) =>
                  handleConfirmPasswordChange(
                    e.target.value,
                    confirmPasswordValueError,
                    currentContext,
                    setConfirmPasswordValue,
                    setConfirmPasswordValueError
                  )
                }
                errors={errors}
                onFocus={() => setConfirmPasswordValueFocused(true)}
                onBlur={() => setConfirmPasswordValueFocused(false)}
              />
              {confirmPasswordValueError &&
                confirmPasswordValueError !== "Required" && (
                  <div className="error-message">
                    {confirmPasswordValueError}
                  </div>
                )}
              {!confirmPasswordValueFocused &&
                confirmPasswordValueValue === "" && (
                  <span className="blinking-cursor signup-cursor">|</span>
                )}
              {/* confirmed password is undefined so need to write it twice to say undefined is also false since it is not intialized as a string */}
              {confirmPasswordValue && confirmPasswordValue !== "" && (
                <div
                  className="show-hide-password-toggle"
                  onClick={() => setShowconfirmPasswordValue((prev) => !prev)}
                  style={{
                    bottom: confirmPasswordValueError ? "28px" : "5px", // Adjust based on error message height
                  }}
                >
                  {showconfirmPasswordValue ? (
                    <EyeSlashIcon width="20" height="15" />
                  ) : (
                    <EyeIcon width="20" height="15" />
                  )}
                  {showconfirmPasswordValue ? "Hide" : "Show"}
                </div>
              )}
            </div>
          </>
        )}
        {updatePasswordVisible && (
          <>
            {isSettings && (
              <>
                {/* Original Password Field */}
                <div className="input-group blinker-wrapper">
                  <label htmlFor="original_password">
                    Enter Original Password:
                  </label>
                  <input
                    type={showOriginalPasswordValue ? "text" : "password"}
                    id="original_password"
                    placeholder={
                      originalPasswordValueError
                        ? originalPasswordValueError
                        : "Enter Original Password Here"
                    }
                    className={
                      originalPasswordValueError ? "red-placeholder" : ""
                    }
                    value={originalPasswordValue}
                    onChange={(e) =>
                      handleOriginalPasswordChange(
                        e.target.value,
                        originalPasswordValueError,
                        currentContext,
                        setOriginalPasswordValue,
                        validatePasswordValue,
                        setOriginalPasswordValidationRules,
                        setOriginalPasswordToolTipVisible,
                        setOriginalPasswordValueError
                      )
                    }
                    errors={errors}
                    onFocus={() =>
                      onOriginalPasswordValueFocused(
                        originalPasswordValue,
                        validatePasswordValue,
                        setOriginalPasswordValueFocused,
                        setUserNameValueFocused,
                        setOriginalPasswordValidationRules,
                        setOriginalPasswordToolTipVisible
                      )
                    }
                    onBlur={() =>
                      onOriginalPasswordValueBlur(
                        setOriginalPasswordValueFocused,
                        setOriginalPasswordToolTipVisible
                      )
                    }
                  />
                  {!originalPasswordValueFocused &&
                    originalPasswordValue === "" && (
                      <span className="blinking-cursor signup-cursor">|</span>
                    )}
                  {(originalPasswordValueFocused ||
                    originalPasswordValueError) && (
                    <ToolTip
                      rules={originalPasswordValidationRules}
                      toolTipVisible={originalPasswordToolTipVisible}
                      setToolTipVisible={setOriginalPasswordToolTipVisible}
                    />
                  )}
                  {/* undefined and empty string is false since you have to specify undefind as false it can mean many things */}
                  {originalPasswordValue && originalPasswordValue !== "" && (
                    <div
                      className="show-hide-password-toggle"
                      onClick={() =>
                        setShowOriginalPasswordValue((prev) => !prev)
                      }
                    >
                      {showOriginalPasswordValue ? (
                        <EyeSlashIcon width="20" height="15" />
                      ) : (
                        <EyeIcon width="20" height="15" />
                      )}
                      {showOriginalPasswordValue ? "Hide" : "Show"}
                    </div>
                  )}
                </div>
                {/* New Password Field */}
                <div className="input-group blinker-wrapper">
                  <label htmlFor="new_password">Enter New Password:</label>
                  <input
                    type={showNewPasswordValue ? "text" : "password"}
                    id="new_password"
                    placeholder={
                      newPasswordValueError
                        ? newPasswordValueError
                        : "Enter new password here"
                    }
                    className={newPasswordValueError ? "red-placeholder" : ""}
                    value={newPasswordValue}
                    onChange={(e) =>
                      handleNewPasswordChange(
                        e.target.value,
                        newPasswordValueError,
                        currentContext,
                        setNewPasswordValue,
                        validatePasswordValue,
                        setNewPasswordValidationRules,
                        setNewPasswordToolTipVisible,
                        setNewPasswordValueError
                      )
                    }
                    onFocus={() =>
                      onNewPasswordValueFocused(
                        newPasswordValue,
                        validatePasswordValue,
                        setNewPasswordValueFocused,
                        setNewPasswordValidationRules,
                        setNewPasswordToolTipVisible
                      )
                    }
                    onBlur={() =>
                      onNewPasswordValueBlur(
                        setNewPasswordValueFocused,
                        setNewPasswordToolTipVisible
                      )
                    }
                  />
                  {!newPasswordValueFocused && newPasswordValue === "" && (
                    <span className="blinking-cursor signup-cursor">|</span>
                  )}
                  {(newPasswordValueFocused || newPasswordValueError) && (
                    <ToolTip
                      rules={newPasswordValidationRules}
                      toolTipVisible={newPasswordToolTipVisible}
                      setToolTipVisible={setNewPasswordToolTipVisible}
                    />
                  )}
                  {/* undefined and empty string is false since you have to specify undefind as false it can mean many things */}
                  {newPasswordValue && newPasswordValue !== "" && (
                    <div
                      className="show-hide-password-toggle"
                      onClick={() => setShowNewPasswordValue((prev) => !prev)}
                    >
                      {showNewPasswordValue ? (
                        <EyeSlashIcon width="20" height="15" />
                      ) : (
                        <EyeIcon width="20" height="15" />
                      )}
                      {showNewPasswordValue ? "Hide" : "Show"}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        <UnlockSlideBtn
          onHandleSlideEnd={(ev) => {
            if (!isSettings) {
              // This will be executed when isSettings is false, meaning it's for signup
              onSignup(ev, contextType);
            } else {
              // This will be executed when isSettings is true, meaning it's for updating account settings
              onUpdateSettings(ev, contextType); // Assuming you have an onUpdateSettings function
            }
          }}
          slideBtnText={
            !isSettings ? "Slide right to sign up" : "Slide to update account"
          }
          slidBtnFontWeight="800"
          marginTop="10px"
          slideBtnheight="35px"
          slideBtnLeft={!isSettings ? "13%" : "0%"} // Conditional style
          slideBtnWidth={!isSettings ? "220px" : "100%"} // Conditional style
          slideBtTop="5px"
        />
        <CustomButton
          customBtnText="X Cancel"
          customBtnWidth="100%"
          customBtnTextFontSize="1.5rem"
          customBtnFontFamily="Roboto-Slab"
          customBtnFontWeight="900"
          animationActive={true} // enable the animation
          onClick={() => {
            localStorage.clear();
            if (!isSettings) {
              setCurrentForm("signIn");
              setContextType("signIn"); // Redirect only if isSettings is false
            } else {
              clearSettingsNameSectionLocalStorage(
                currentContext,
                setFirstNameValue,
                setLastNameValue,
                setUserNameValue
              );
              clearSettingsContactInfoSectionLocalStorage(
                currentContext,
                setEmailValue,
                setPhoneNumberValue
              );
              clearSettingsPasswordSectionSectionLocalStorage(
                currentContext,
                setOriginalPasswordValue,
                setNewPasswordValue
              );
              setUpdateNameVisible(false); // close the filters
              setUpdateContactInfoVisible(false);
              setUpdatePasswordVisible(false);
            }
          }}
        />
        <div id="stopScroll"></div>
      </form>
    </div>
  );
};
export default SignUp;

// passed in currentContext to the component to determine which one it is in
// this approach is good if the components share state
// const currentContext = isSettings ? "settings" : "signup";
// If isSettings is true, currentContext is set to "settings".
// If isSettings is false, currentContext is set to "signup".
// slideBtnText={
//   !isSettings
//   ? "Slide right to sign up"
//   : "Slide to update account"
// }
// If isSettings is false, the button will display "Slide right to sign up", indicating that the user is in a signup process.
// If isSettings is true, the button will display "Slide to update account", indicating that the user is in an account settings or update process.
