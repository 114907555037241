import React, { useState, useEffect, useRef } from "react";
import { ImgTopBtn, SolidColorButton } from "../../buttons/index";
import { EyeSlashIcon, EyeIcon } from "../../icons/index.jsx";
import {
  clearDescriptionValueLocalStorage,
  handleTextarea,
  handleTextareaResize,
  clearBioForm,
  handleTextAreaBulletPoint,
  formatTextWithBulletPoints,
} from "../../../controller/watcherAndClearFunctions/bioWatchersClearsFn.js";
import {
  handleBioFormErrors,
} from "../../../controller/errorHandlers/bioFormErrorHandlers.js";
import "./bioForm.scss";
function BioForm({ onClose }) {
  const currentContext = "bio";
  const bioContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [descriptionValueError, setDescriptionValueError] = useState("");
  const [bioSubmissionAttempted, setBioSubmissionAttempted] = useState(false);
  const [descriptionFocused, setDescriptionValueFocused] = useState(false);
  const [bulletPointsVisible, setBulletPointsVisible] = useState(true);

  const submitBio = async () => {
    setBioSubmissionAttempted(true); // Trigger validation
    // onClose();
    // Don't include form submission logic here
    // It will be handled in the useEffect
  };
  const toggleBulletPointsVisibility = () => {
    setBulletPointsVisible((prev) => !prev);
  };

  useEffect(() => {
    if (bioSubmissionAttempted) {
      const { isRequired } = handleBioFormErrors(
        descriptionValue,
        currentContext,
        setDescriptionValueError
      );
      if (!isRequired) {
        // Proceed with form submission logic
        // API call should be triggered here
        // Example (commented out):
        // try {
        //   const response = await yourApiCallFunction(descriptionValue);
        //   if (response.success) {
        //     onClose(); // Close the form on successful submission
        //   } else {
        //     // Handle unsuccessful submission
        //   }
        // } catch (error) {
        //   // Handle API call failure
        // }
        localStorage.clear(); // Ensure this is the desired behavior
      }
      setBioSubmissionAttempted(false); // Reset for next submission attempt
    }
  }, [bioSubmissionAttempted, descriptionValue]);

  return (
    <div className="bio-form-overlay">
      <div id="bio-form">
        <ImgTopBtn
          id="cancel-btn"
          imgTopBtnBorderRadius="25px"
          imgTopBtnText=" X Cancel"
          imgTopBtnWidth="fit-content"
          imgTopBtnTextFontSize="1.3rem"
          imgTopBtnnFontFamily="Roboto-Slab"
          imgTopBtnFontWeight="900"
          imgTopBtnFontColor="#964b00"
          paddingRight="5px"
          paddingLeft="5px"
          extraImgTopBtnStyles={{
            left: "70%",
          }}
          onClick={() => {
            clearDescriptionValueLocalStorage(
              currentContext,
              setDescriptionValue
            );
            onClose();
          }}
        />
        <div className="header">
          <h2 className="headerFont">Share your qualities/values/turn offs</h2>
        </div>

        <form>
          <div
            className="textarea-container blinker-wrapper description"
            ref={bioContainerRef}
          >
            <textarea
              ref={textareaRef}
              value={formatTextWithBulletPoints(descriptionValue, bulletPointsVisible)}
              onInput={() => handleTextareaResize(textareaRef)}
              placeholder="Description"
              className={`
                          ${
                            descriptionFocused && descriptionValue
                              ? "textarea-focused-value"
                              : ""
                          }
                          ${descriptionFocused ? "focused-textarea" : ""}
                          ${descriptionValue ? "textarea-with-value" : ""}`}
              onChange={(e) =>
                handleTextarea(
                  e.target.value,
                  "bioDescriptioValue",
                  currentContext,
                  textareaRef,
                  setDescriptionValue,
                  setDescriptionValueError
                )
              }
              onKeyDown={(e) =>
                handleTextAreaBulletPoint(
                  e,
                  descriptionValue,
                  setDescriptionValue
                )
              }
              onFocus={() => setDescriptionValueFocused(true)}
              onBlur={() => setDescriptionValueFocused(false)}
              rows="3"
            />
            {descriptionValueError && (
              <div className="error-message">{descriptionValueError}</div>
            )}
            {descriptionValue && (
              <button
                className="reset-bio"
                onClick={() =>
                  clearBioForm(
                    setDescriptionValue,
                    setDescriptionValueError,
                    () =>
                      clearDescriptionValueLocalStorage(
                        currentContext,
                        setDescriptionValue
                      ),
                    textareaRef
                  )
                }
              >
                Clear
              </button>
            )}
            {!descriptionFocused && descriptionValue === "" && (
              <span className="blinking-cursor blinking margtop5percent">
                |
              </span>
            )}
            <SolidColorButton
              solidColorBtnImage={
                bulletPointsVisible ? (
                  <EyeSlashIcon width="20" height="15" />
                ) : (
                  <EyeIcon width="20" height="15" />
                )
              }
              className="gold-btn"
              solidColorBtnText={
                bulletPointsVisible
                  ? "Hide Bullet Points"
                  : "Show Bullet Points"
              }
              solidColorBtnFontWeight="800"
              solidColorBtnFontFamily="helvetica"
              solidColorBtnTextFontSize=".75rem"
              solidColorBtnFontColor="#964b00"
              solidColorBtnWidth="200px"
              solidColorBtnBoxShadow="0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb"
              onClick={toggleBulletPointsVisibility}
            />
          </div>
          {/* Add other form elements here if needed */}
          <ImgTopBtn
            image={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="22.795"
                viewBox="0 0 40 32.795"
              >
                <g id="Book" transform="translate(-5.734 -9.052)">
                  <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M42.163,37.617V9.052l-1.09.091a41.911,41.911,0,0,0-12.706,3.1l-1.918.8V41.488l1.4-.581a43.585,43.585,0,0,1,13.144-3.2Z"
                    fill="#964b00"
                  />
                  <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M45.734,13.275H43.591v25a.713.713,0,0,1-.656.714l-1.829.15c-.527.044-1.055.1-1.579.162-.17.021-.339.048-.508.072-.354.047-.707.1-1.059.15-.205.033-.409.071-.613.107-.315.055-.631.11-.945.172-.214.043-.429.091-.647.137-.3.063-.6.128-.9.2-.223.053-.445.109-.666.166-.289.072-.577.149-.864.229q-.336.093-.67.192c-.286.084-.567.17-.85.26l-.662.214c-.282.1-.563.194-.844.295-.214.078-.429.156-.647.238-.1.037-.195.077-.293.115H45.734V13.275Z"
                    fill="#964b00"
                  />
                  <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M10.4,9.144l-1.09-.091V37.62l1.269.108A43.32,43.32,0,0,1,23.7,40.942l1.317.548V13.037l-1.917-.8A41.9,41.9,0,0,0,10.4,9.144Z"
                    fill="#964b00"
                  />
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M5.734,13.275V41.846H22.117c-.088-.034-.176-.071-.264-.1-.2-.078-.411-.152-.616-.227-.291-.1-.58-.208-.873-.306-.211-.071-.423-.141-.636-.208q-.433-.143-.872-.269c-.214-.063-.429-.125-.643-.186-.294-.082-.588-.159-.883-.234-.214-.055-.429-.11-.647-.161-.3-.072-.6-.137-.9-.2-.214-.046-.429-.094-.643-.137-.312-.061-.624-.116-.938-.171l-.615-.108c-.346-.055-.691-.1-1.038-.147-.175-.023-.349-.052-.523-.071-.523-.063-1.048-.118-1.571-.162L8.531,38.99a.715.715,0,0,1-.654-.714v-25H5.734Z"
                    fill="#964b00"
                  />
                </g>
              </svg>
            }
            imgTopBtnFontFamily="helvetica"
            imageReverse={true}
            imgTopBtnFontWeight="800"
            className="gold-btn"
            imgTopBtnText="Add Bio"
            onClick={submitBio}
            imgTopBtnBorderRadius="25px"
            imgTopBtnTextFontSize="1.8rem"
            imgTopBtnFontColor="#964b00"
            extraImgTopBtnStyles={{
              left: "35%",
            }}
            paddingRight="5px"
            paddingLeft="5px"
          />
        </form>
      </div>
    </div>
  );
}
export default BioForm;
