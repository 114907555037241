import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mockData from "../../seedMockData/seedMockData";

// create post will go where user name is will be a glass
// button, and change up picture, maybe change it to the
// follow button
import { ConnectionBtn, GlassBtn } from "../buttons/index";
import {
  TrashCan,
  Pencil,
  ChatIcon,
  HeartIcon,
  Xylophone,
  FindUsername,
  FollowIcon,
  UnfollowIcon,
  NotInterestedIcon,
} from "../../components/icons/index";
import { CreatePostCircle } from "../buttons/index";

import "./userPost.scss";

const UserPost = ({
  username,
  postId,
  firstName,
  lastName,
  media,
  profileThumbnail,
  className,
  showChatSlider = true,
  isProfilePage = false, // New prop to determine if it's on profile page setting it to false to avoid a refractor if true we will not show username for each post on the profile page
  isProfileOwner,
}) => {
  // console.log("Props received by UserPost:", {
  //   lastName,
  //   firstName,
  //   username,
  //   media,
  //   profileThumbnail,
  //   chatLink,
  //   showChatSlider,
  // });
  const navigate = useNavigate();
  const currentContext = "updatePost";
  const [heartLiked, setHeartLiked] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [heartLikeCount, setHeartLikeCount] = useState(0); // Initializing heart like count
  const [commentCount, setCommentCount] = useState(0); // Initializing comment count
  const [isFollowing, setIsFollowing] = useState(false);

  const toggleHeartLike = (e) => {
    e.stopPropagation(); // Prevents the click event from bubbling up to parent elements
    setHeartLiked(!heartLiked);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 700); // Reset animation state after it completes
    // Toggling like count
    if (!heartLiked) {
      // If not already liked, increment the count
      setHeartLikeCount(heartLikeCount + 1);
    } else {
      // If already liked, decrement the count
      setHeartLikeCount(heartLikeCount - 1);
    }
  };

  if (!media) return null; // If no media, don't display the post
  function goToUserProfile(additionalOffset = 0) {
    // change to true when you have a way to get the username, replace 'username' with the actual username variable
    navigate(`/profile/${username}#member`, { replace: false });

    // The logic to scroll to the element
    setTimeout(() => {
      // Timeout is used to wait for the navigation to complete
      const el = document.getElementById("member");
      if (el) {
        const offset = 100; // Height of your fixed header/navbar
        const elementPosition =
          el.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset - additionalOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  }

  // handle toggle for the un/follow glass btn
  function handleFollowToggle(e) {
    e.stopPropagation(); // Prevents the click event from bubbling up to parent elements

    setIsFollowing((prevState) => !prevState);
  }
  const handlePostClick = () => {
    // Find the post using the postId
    const userPost = mockData.posts.find((post) => post.id === postId);

    // No need to find media separately as imagePath is included in the post object
    const imagePath = userPost?.imagePath;

    // Filter comments related to the post
    const userPostComments = mockData.comments.filter(
      (comment) => comment.postId === postId
    );

    // Navigate to the post detail page with the necessary data
    navigate(`/post/${postId}`, {
      state: { media: imagePath, comments: userPostComments },
    });
  };

  return (
    <div
      className={`user-post-container ${className}`}
      onClick={handlePostClick}
      style={{ cursor: "pointer" }} // Inline style for cursor
    >
      {isProfilePage && isProfileOwner && (
        <>
          {/* Buttons for updating and removing posts, shown only for profile owners on their profile page */}
          <div className="update-container">
            <GlassBtn
              glassBtnImage={
                <Pencil height="25px" width="25px" fillColor="#fdfd96" />
              }
              className="updateCtrl"
              glassBtnText="Update"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="900"
              glassBtnFontColor="#fdfd96"
              glassBtnTextDecorationColor="#fdfd96"
            />
          </div>
          <div className="remove-container">
            <GlassBtn
              glassBtnImage={
                <TrashCan height="25px" width="25px" fillColor="#e2b645" />
              }
              className="rmvPostBtn"
              glassBtnText="Remove"
              glassBtnTextFontSize="1.3rem"
              glassBtnFontWeight="900"
              glassBtnFontColor="#e2b645"
              glassBtnTextDecorationColor="#e2b645"
            />
          </div>
        </>
      )}
      {/* Conditionally displaying the this section, will not appear on profile page*/}
      <div className="user-info-wrapper">
        {!isProfilePage && (
          <h4 className="user-name-heading" onClick={() => goToUserProfile(50)}>
            <span className="user-name-wrapper">
              <FindUsername height="10px" fillColor="#e2b645" />
              <span className="userName">{username}</span>
            </span>
            <span className="user-heading-bottom">
              {`${firstName} ${lastName}`}
            </span>
          </h4>
        )}
      </div>
      {!(isProfilePage && isProfileOwner) && ( // display the elements when it is not the case that both isProfilePage and isProfileOwner are true.
        <>
          <div
            className="report-icon-wrapper"
            // onClick={handleReportClick}  // for future programing use
          >
            <Xylophone
              // onClick={handleReport} // for future programing use we will see if the top only works first
              height="20px" // Example size
            />
            <span className="icon-text">Report</span>
          </div>
        </>
      )}
      {!isProfilePage && (
        <div className="createPostCircle">
          <CreatePostCircle // Example font size
            // onClick={handleCreatePostAction} needed for the future
            text="Create Post"
            color="#fdfd96"
            fontFamily="Roboto Slab"
            fontSize="1.2rem"
            fontWeight="900"
            borderWidth="2px"
            borderColor="#fdfd96"
            borderRadius="50px"
          />
        </div>
      )}
      {/* Displaying the media (image) */}
      <img className="media" src={media} alt={`${username} media`} />{" "}
      {/* Chat slider button, shown based on the showChatSlider prop */}
      {showChatSlider && (
        <div className="slide-button-wrapper">
          <ConnectionBtn
            sliderImage={profileThumbnail || null}
            slidBtnFontSize=".75rem"
            slideBtnWidth="134px"
            slideBtnText="View Profile"
            top="18px"
            onHandleSlideEnd={() => goToUserProfile(50)} // Passes an additional 50 pixels offset to the call back
          />
        </div>
      )}
      {!(isProfilePage && isProfileOwner) && ( // display the elements when it is not the case that both isProfilePage and isProfileOwner are true.
        <>
          <div className="sidebar-icons">
            {/* Profile Thumbnail with Follow Icon */}
            <div className="followProfile-btn">
              <div className="icon-wrapper" onClick={handleFollowToggle}>
                {isFollowing ? (
                  <UnfollowIcon height="20px" fillColor="#e2b645" />
                ) : (
                  <FollowIcon height="20px" fillColor="#e2b645" />
                )}
                <span className="icon-text">
                  {isFollowing ? "Unfollow" : "Follow"}
                </span>
              </div>
            </div>
            {/* Heart Icon */}
            <HeartIcon
              fillColor={heartLiked ? "#bf3b2b" : "#fff"}
              onClick={(e) => toggleHeartLike(e)}
              className={animate ? "animate" : ""}
              height="20px" // Example size
              // onClick={onLike} need to program this still
            />
            {/* displaying the users incremented or decremented hear-like-count 
         this happens on the frontend and will only be sent to the backend when the user
         logs out because user can change user's mind. We will need redux for this since
         we have to persist it to a store beyond useState, so we don't lose the data.
         We can also do batch processing will discuss with Rick how we can do that*/}
            <div className="heart-like-count">{heartLikeCount}</div>
            {/* Not Interested Icon */}
            {/* Chat Icon */}
            {/* see notes after component for idea on set up */}
            <ChatIcon
              height="20px" // Example size
              // onClick={onComment} need to program this still
            />
            {/* passing count when it is defined with backend  */}
            <div className="comment-count">{commentCount}</div>
            <div className="not-interested-icon-wrapper">
              <NotInterestedIcon
                // onClick={handleNotInterested} // for future programing use
                height="20px" // Example size
                // Add any additional props if needed
              />
              <span className="icon-text">Hide</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UserPost;
// 1. Database Structure
// Assuming you have a backend database to store comments:

// Threads/Posts Table: Each thread or post should have a unique ID (e.g., threadId or postId).
// Comments Table: Store comments in a table where each comment has:
// A unique ID (e.g., commentId).
// A reference to the ID of the thread/post it belongs to (threadId or postId).
// The ID of the user who made the comment.
// The content of the comment.
// 2. Backend Logic
// API Endpoint for Comments: Create an API endpoint to fetch comments based on the threadId or postId. This endpoint can also return the count of comments.
// Posting Comments: Implement an API endpoint to post new comments. This endpoint should associate the new comment with the correct threadId and the user who posted it.
// 3. Frontend Implementation
// Fetching Comments: When a thread or post is opened, use the comments API to fetch the associated comments and display them.
// Display Comment Count: Display the comment count in a suitable place in your UI, typically near the thread or post title or near the comment icon.
// Comment Submission Interface: Provide a text input and a submit button to allow users to write and post comments.
// 4. Handling Comment Count Updates
// Real-Time Updates: If real-time updates are needed (for example, when other users add comments), consider using WebSockets or long polling to update the comment count dynamically.
// Increment/Decrement Logic: When a user adds or deletes a comment, update the comment count accordingly. This can be done on the frontend by incrementing/decrementing the count or by fetching the latest count from the backend.
// Example Data Models
// Threads/Posts Table

// threadId (or postId)
// title
// content
// userId (author)
// Other relevant fields...
// Comments Table

// commentId
// threadId (or postId)
// userId (comment author)
// commentText
// Timestamps (created at, updated at)
// Other relevant fields...
// 5. Security and Validation
// Ensure that users can only post comments if they are authenticated.
// Implement input validation both on the frontend and backend to prevent malicious inputs.
// This setup should provide a foundational structure for handling comments associated with threads or posts, including counting and displaying the number of comments. You can extend and modify this basic structure to fit the specific needs and features of your application.
