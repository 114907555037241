// Action types
export const SET_RECEIPT = "SET_RECEIPT";
export const CLEAR_RECEIPT = "CLEAR_RECEIPT";

// Action creators
export const setReceipt = (data) => ({
  type: SET_RECEIPT,
  payload: data,
});
export const clearReceipt = () => ({
  type: CLEAR_RECEIPT
});
