
export const SettingsNameSectionForm_LOCAL_STORAGE_BASE_KEYS = [
    "firstNameValue",
    "lastNameValue",
    "userNameValue",
  ];
  export const SettingsContactInfoSectionForm_LOCAL_STORAGE_BASE_KEYS = [
    "emailValue",
    "phoneNumberValue",
  ];
  export const SettingsPasswordSectionForm_LOCAL_STORAGE_BASE_KEYS = [
    "originalPasswordValue",
    "newPasswordValue",
  ];






