import React from "react";
import { UserProfileProvider } from "./context/userProfileContext.jsx";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import App from "./main.jsx";
import App from "./main.jsx"
import { Provider } from "react-redux";
import { AuthProvider } from "./context/authContext.jsx";
import { MessageProvider } from "./context/messageContext.jsx"; 
import initStore from "./redux/store/store.js";

const { store } = initStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <Provider store={store}>
      <BrowserRouter>
        <UserProfileProvider>
          <MessageProvider>
            <App />
          </MessageProvider>
        </UserProfileProvider>
      </BrowserRouter>
    </Provider>
  </AuthProvider>
);

// wrap your app with the ConnectionsProvider,
// which will provide your connection-related states
// and functions to the rest of your components. To do
// that, you'd have to import your ConnectionsProvider
// and wrap it around the main content of your app or
// just the parts that need access to the connections context.
// Any component within <App /> will have access to the ConnectionsContext
// and can make use of the context's values and functions via the useContext hook.
// The context will be available throughout the app, ensuring
// that any component inside your routing system can use connection-related
// data and functions.
// // This segregation can potentially lead to a more tree-shakable
// // build by only including the client-side utilities in your bundle,
// //  which might result in a smaller bundle size. Here, the /client
// //  pathway is explicitly focusing on client-side rendering
// //  functionalities.
// // Typically, developers would simply import from "react-dom".
// // It seems like React documentation has included this to
// // suggest a more fine-grained import for tree-shaking purposes see below reason.
// // https://react.dev/reference/react-dom/client
// import ReactDOM from "react-dom/client";// React's DOM rendering utilities.

// // We import the serviceWorkerRegistration module, which handles registering our service worker.
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// // reportWebVitals is a utility that lets you measure the performance of your app.
// import reportWebVitals from './reportWebVitals';

// // ReactDOM.createRoot() is a part of the new concurrent mode in React,
// // // which enables better time-slicing and can lead to performance improvements.
// // // It's different from ReactDOM.render() in that it allows React to interrupt rendering to work on multiple tasks simultaneously.
// // ReactDOM.createRoot(document.getElementById("root")).render(<App />);

// // // Rendering our main App component inside a StrictMode wrapper. StrictMode is a tool for highlighting potential problems in an app.
// // // It checks for issues like deprecated APIs, unexpected side effects, etc.
// // root.render(
// //   <React.StrictMode>
// //     <App />
// //   </React.StrictMode>
// // );

// // The service worker is registered here. Service workers enable features like push notifications and background sync.
// // They can also help with offline caching for PWAs (Progressive Web Apps).
// // Changing from `unregister` to `register` means you're opting into making your app a PWA.
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// // This function helps to monitor the performance of your app and can send metrics to an analytics endpoint.
// // It's part of the Create React App setup to help you understand how well your app is performing.
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Routing Libraries: If you notice usage of libraries like
//react-router-dom (for React) or vue-router (for Vue.js),
// it's a clear indicator of an SPA.

// Example Scenarios:
// AuthProvider: It’s common to place this at a high level
// since many components might need to know if a user is authenticated
// or to access user information.

// AnimationProvider: If animations are used across multiple routes
// and components, it might also be placed at a high level. If it's
// used only in specific routes, wrapping just those routes or component trees might be beneficial to limit the scope and potential re-renders.
