import React, { useRef, useEffect } from "react";
import { AvatarIcon } from "../../icons";
import { useNavigate } from "react-router-dom";
import "./connectionPageNav.scss";

function ConnectionPgNavSlider({
  className,
  username,
  slideBtnText,
  onHandleSlideEnd,
  sliderImage,
  style,
  slidBtnFontSize,
  slideBtnWidth,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}) {
  const navigate = useNavigate();
  const slideRef = useRef(null);
  const slideBtnRef = useRef(null);

  let slideBounds;
  let btnBounds;

  function onSlide(ev) {
    ev.stopPropagation(); // Stop the event from propagating up
    const pointerX = ev.clientX;
    const pos = pointerX - btnBounds.x - btnBounds.width / 2;
    const minPos = 0;
    const maxPos =
      slideBounds.width - btnBounds.width - slideBounds.width * 0.04;

    slideBtnRef.current.style.left = clamp(minPos, pos, maxPos) + "px";

    const tolerance = 0.1;
    if (pos >= maxPos - tolerance) {
      onHandleSlideEnd();
      navigate(`/connections/${username}`);
    }
  }

  function onSlideBtnPointerDown(ev) {
    ev.preventDefault();
    slideBounds = slideRef.current.getBoundingClientRect();
    btnBounds = slideBtnRef.current.getBoundingClientRect();
    slideBtnRef.current.setPointerCapture(ev.pointerId);
    slideBtnRef.current.addEventListener("pointermove", onSlide);
  }

  function onSlideBtnPointerUp(ev) {
    ev.preventDefault();
    slideBtnRef.current.releasePointerCapture(ev.pointerId);
    slideBtnRef.current.removeEventListener("pointermove", onSlide);
    const animation = slideBtnRef.current.animate(
      { left: "0" },
      { duration: 200 }
    );
    animation.finished.then(() => {
      slideBtnRef.current.style.left = "0";
    });
  }

  function clamp(min, val, max) {
    return Math.max(min, Math.min(val, max));
  }
  // The logic for starting the slide gesture
  const onSlideStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Add mousemove and touchmove event listeners
    document.addEventListener("mousemove", onSlide);
    document.addEventListener("touchmove", onSlide);
  };
  // The logic for ending the slide gesture
  const onSlideEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Remove mousemove and touchmove event listeners
    document.removeEventListener("mousemove", onSlide);
    document.removeEventListener("touchmove", onSlide);
  };
  // Attach mouse and touch event handlers to the slider button
  useEffect(() => {
    const slideButton = slideBtnRef.current;

    const options = { passive: false }; // Options object

    slideButton.addEventListener("pointerdown", onSlideBtnPointerDown, options);
    slideButton.addEventListener("pointerup", onSlideBtnPointerUp, options);
    slideButton.addEventListener("touchstart", onSlideStart, options);
    slideButton.addEventListener("touchend", onSlideEnd, options);

    return () => {
      slideButton.removeEventListener(
        "pointerdown",
        onSlideBtnPointerDown,
        options
      );
      slideButton.removeEventListener(
        "pointerup",
        onSlideBtnPointerUp,
        options
      );
      slideButton.removeEventListener("touchstart", onSlideStart, options);
      slideButton.removeEventListener("touchend", onSlideEnd, options);
    };
  }, []);

  return (
    <div
      ref={slideRef}
      className={`slide ${className}`}
      style={{
        ...style,
        width: slideBtnWidth,
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
      }}
    >
      <div
        ref={slideBtnRef}
        className="slidebtn"
        onPointerDown={onSlideBtnPointerDown}
        onPointerUp={onSlideBtnPointerUp}
      >
        {typeof sliderImage === "function" ? (
          React.createElement(sliderImage)
        ) : sliderImage ? (
          <img src={sliderImage} alt="chat profile" className="profileThumbnail"  />
        ) : (
          <AvatarIcon className="thumbnailAvatar" />
        )}
      </div>
      <div className="label" style={{ fontSize: slidBtnFontSize }}>
        {slideBtnText}
      </div>
    </div>
  );
}

export default ConnectionPgNavSlider;
