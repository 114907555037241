import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import profile1 from "./assets/profile-1.jpg";
import profile2 from "./assets/profile-2.jpg";
import profile3 from "./assets/profile-3.jpg";

import "./chatAnimation.scss";

// LazyImage Component for lazy loading images
function LazyImage({ src, alt, className }) {
  const [imageSrc, setImageSrc] = useState(null);
  const imgRef = useRef();

  useEffect(() => {
    const imgLoader = new Image();
    imgLoader.src = src;
    imgLoader.onload = () => {
      setImageSrc(src); // Set loaded image source
    };
  }, [src]);

  return <img ref={imgRef} src={imageSrc} alt={alt} className={className} />;
}

// Array of chat messages with their respective details
const chatMessages = [
  {
    name: "intro",
    msg: "Welcome to our group!",
    delay: 0, // No delay for the introductory message
    align: "left",
    showTime: true,
    time: "Now",
    imagePath: profile1,
  },
  {
    name: "ms1",
    msg: "Excited to be here!",
    delay: 3000,
    align: "right",
    showTime: true,
    time: "Now",
    imagePath: profile3,
  },
  {
    name: "ms2",
    msg: "Can't wait to meet up",
    delay: 3000,
    align: "left",
    showTime: true,
    time: "Now",
    imagePath: profile2,
  },
  //... more messages can be added in this format
];

function ChatAnimation() {
  // useRef for direct DOM manipulation
  // chatListRef.current.innerHTML = ""; // Clear all previous messages
  const chatListRef = useRef(null);
  useEffect(() => {
    let timeoutIds = []; // To keep track of timeout IDs for cleanup
    // Function to handle and display the chat messages
    const displayChat = () => {
      // Ensure the chat list container is present before manipulating its innerHTML.
      if (!chatListRef.current) return;
      // Clearing previous messages
      chatListRef.current.innerHTML = "";

      let chatDelay = 0;
      // Iterating through each message object in chatMessages.
      chatMessages.forEach((obj) => {
        chatDelay += 3000;
        const chatDelay2 = chatDelay + obj.delay;

        const msgTimeoutId = setTimeout(() => {
          // create the message element
          const messageElement = document.createElement("li");
          messageElement.classList.add("message-" + obj.align, obj.name);

          // Create the typing indicator
          const typingIndicatorDiv = document.createElement("div");
          typingIndicatorDiv.classList.add("sp-" + obj.name);
          const typingIndicatorInnerDiv = document.createElement("div");
          typingIndicatorInnerDiv.classList.add("typingIndicator");
          for (let i = 1; i <= 3; i++) {
            const bounce = document.createElement("div");
            bounce.classList.add("bounce" + i);
            typingIndicatorInnerDiv.appendChild(bounce);
          }
          typingIndicatorDiv.appendChild(typingIndicatorInnerDiv);
          messageElement.appendChild(typingIndicatorDiv);
          // Append the messageElement immediately
          if (chatListRef.current) {
            // Check if element is available
            // Append the messageElement immediately
            chatListRef.current.appendChild(messageElement);
          }
          const msgInnerTimeoutId = setTimeout(() => {
            // Hide typing indicator.
            typingIndicatorDiv.style.display = "none";
            // Show thumbnail.
            // Create a React element and render it to the DOM node dynamically.
            if (obj.imagePath) {
              const reactElement = React.createElement(LazyImage, {
                src: obj.imagePath,
                alt: obj.name,
                className: `chat-thumbnail thumbnail-${obj.align}`,
              });
              const container = document.createElement("div"); // Creating a container for your component
              messageElement.appendChild(container); // Appending new container to messageElement
              const root = ReactDOM.createRoot(container);
              root.render(reactElement);
            }
            // Ensure the message list scrolls to show the latest message.
            const messageContainer = chatListRef.current;
            messageContainer.scrollTop = messageContainer.scrollHeight;

            // Create and display the message content.
            const messageInnerDiv = document.createElement("div");
            messageInnerDiv.classList.add("messageinner-" + obj.name);
            const span = document.createElement("span");
            span.classList.add("message-text");
            span.innerText = obj.msg;
            messageInnerDiv.appendChild(span);

            // Display the time if showTime is true.
            if (obj.showTime) {
              const timeSpan = document.createElement("span");
              timeSpan.classList.add("message-time");
              timeSpan.innerText = obj.time;
              messageInnerDiv.appendChild(timeSpan);
            }
            messageElement.appendChild(messageInnerDiv);
          }, obj.delay);

          // Tracking inner timeout ID for cleanup.
          timeoutIds.push(msgInnerTimeoutId);
        }, chatDelay);

        // Tracking outer timeout ID for cleanup.
        timeoutIds.push(msgTimeoutId);
      });
    };
    // This calls the `displayChat` function immediately when the component mounts.
    // It starts the chat animation right away without waiting.
    displayChat();
    // Calculate the total time for the entire chat to finish:
    // For each message, there's an initial delay (`obj.delay`) for the typing indicator,
    // and an additional constant delay of 4000ms (4 seconds) that we've been adding
    // between messages. The `reduce` method computes the total delay from all messages,
    // and then we add the extra 4 seconds multiplied by the number of messages.
    const totalTimeForChat =
      chatMessages.reduce((acc, obj) => acc + obj.delay, 0) +
      3000 * chatMessages.length;
    const repeatTimeoutId = setTimeout(displayChat, totalTimeForChat);
    // Here, we're setting up a timeout to call the `displayChat` function again
    // after the entire chat animation has finished. This will make the chat animation
    // loop/repeat after completing the entire sequence once.
    // Tracking repeating timeout ID for cleanup.
    timeoutIds.push(repeatTimeoutId);
    // `totalTimeForChat` determines when the chat will restart.
    // `timeoutId` is a unique ID for this timeout, which can be used to clear the timeout if needed.
    // Cleanup function to clear all timeouts when component is unmounted to prevent memory leaks.
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, []);

  return (
    <div id="chat-wrapper">
      <ul className="chat-message-list" ref={chatListRef}></ul>
    </div>
  );
}

export default ChatAnimation;
