export const codeValidationErrorHandler = (
  codeValue,
  setCodeErrors,
  currentContext,
  ) => {
    let errors = {};
    if ( currentContext === "resetPasswordSignin" &&  codeValue.length !== 6) {
      errors.codeError = "Code must be 6 digits";
    }
    setCodeErrors(errors.codeError || "");
    return { errors };
  };


  