import React, { createContext, useContext, useState, useEffect } from 'react';

export  const MessageContext = createContext();

export const useMessages = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  
  // Hardcoded current user details
  // Simulate getting the current user from a login process or from the backend
  // For now, we're hardcoding it, but you'll replace this with actual auth logic later
   // Simulate fetching user data and setting it
   useEffect(() => {
    // Simulate an API call with setTimeout
    setTimeout(() => {
      setCurrentUser({
        id: "user-uuid-5",
        username: "AcctHolder",
        ipAddress: "123.45.67.89" // Hardcoded IP address
      });
    }, 1000);
  }, []);

  console.log('MessageProvider messages:', messages);
   // Simulate threadId which would normally come from the backend
  const [currentThreadId, setCurrentThreadId] = useState("thread-uuid-1");

  const sendMessage = (text, senderId ,threadId,  receiverId, replyToId) => {
    const newMessage = {
      id: Date.now(), // This should eventually be replaced with IDs from the backend
      text,
      receiverId, // The ID of the user who is supposed to receive the message
      senderId: currentUser.id, // Use currentUser's id here
      threadId,
      replyToId,
      timestamp: new Date().toISOString(),
    };
    // Add the new message to your messages state
    setMessages(prevMessages => [...prevMessages, newMessage]);
  };

  const deleteMessage = (messageId) => {
    setMessages(prev => prev.filter(message => message.id !== messageId));
  };

  const deleteThreadMessages = (threadId) => {
    setMessages(prev => prev.filter(message => message.threadId !== threadId));
  };

  return (
    <MessageContext.Provider 
    value={{ 
      messages, 
      sendMessage, 
      deleteMessage, 
      deleteThreadMessages, 
      currentUser,  // Make the current user available in the context
      setCurrentUser,  // Allow components to set the current user
      currentThreadId, // Make the current thread ID available in the context
      setCurrentThreadId, // Allow components to set the current thread ID 
      }}>
      {children}
    </MessageContext.Provider>
  );
};
