import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxPromiseMiddleware, {
  createPromise,
} from "redux-promise-middleware";
import buildPersistStoreConfigs from "./buildPersistStoreConfigs.js";
import { DEV_MODE, REDUX_PROMISE_MIDDLEWARE_SUFFIXES } from "../../constants/index.js";
import { persistStore, persistReducer } from 'redux-persist';

// import your root reducer here
import rootReducer from '../reducers/reducersGlobal.js'; 

const { LOADING, SUCCESS, ERROR } = REDUX_PROMISE_MIDDLEWARE_SUFFIXES;

const persistConfig = buildPersistStoreConfigs();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = DEV_MODE ? composeWithDevTools({}) : compose;

export default function initStore() {
  const store = createStore(
    persistedReducer,
    composeEnhancer(
      applyMiddleware(
        thunkMiddleware,
        createPromise({ promiseTypeSuffixes: [LOADING, SUCCESS, ERROR] }),
        reduxPromiseMiddleware
      )
    )
  );

  const persist = persistStore(store);

  return {
    store,
    persist,
  };
}
