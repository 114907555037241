import React from 'react';
// import {FAQS} from './dashBoardWrapper.jsx'
import {FAQS} from "../../constants/rewardPoints.js"

const Faq = () => {
  return (
    <>
      <section className="faq">
        {FAQS.map((faq, index) => (
          <div key={index}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </section>
      <div className="conditions">
        <a href="#"> See terms and conditions at Meet on up terms and conditions</a>
      </div>
    </>
  );
};
export default Faq;
