import _endsWith from "lodash/endsWith.js";
import _includes from "lodash/includes.js";

// constants
import { REDUX_PROMISE_MIDDLEWARE_SUFFIXES } from "../../constants/index.js";

import TYPES from "../types/globalTypes.js";

// get config
const { LOADING, SUCCESS, ERROR } = REDUX_PROMISE_MIDDLEWARE_SUFFIXES;
const { ACTION } = TYPES;

// handle the reducer
const todoReducer = (state, action) => {
  const { type } = action;

  if (!action.payload) return state;

  if (_endsWith(type, LOADING)) {
    const {
      payload: { dataKey },
    } = action;
    const { [dataKey]: bundle } = state;
    return {
      ...state,
      [dataKey]: {
        ...bundle,
        errors: undefined,
        loading: true,
      },
    };
  }

  if (_endsWith(type, ERROR)) {
    const {
      payload: {
        dataKey,
        errors: { errors },
      },
    } = action;
    const { [dataKey]: bundle } = state;
    return {
      ...state,
      [dataKey]: {
        ...bundle,
        errors,
        data: undefined,
        loading: false,
      },
    };
  }

  if (_endsWith(type, SUCCESS)) {
    const {
      payload: { dataKey, data },
    } = action;
    const { [dataKey]: bundle } = state;
    // handle action ADD dispatch
    if (_includes(type, ACTION.ADD)) {
      const { data: previousData } = bundle;
      let newData;
      if (typeof previousData === "object") {
        if (Array.isArray(previousData)) {
          newData = previousData.concat(data);
        } else {
          newData = { ...previousData, data };
        }
      } else if (typeof previousData === "number") {
        newData = previousData + data;
      } else if (typeof previousData === "string") {
        newData = previousData.concat(data);
      } else {
        newData = undefined;
      }
      return {
        ...state,
        [dataKey]: {
          ...bundle,
          data: newData,
          errors: undefined,
          loading: false,
        },
      };
    }
    if (_includes(type, ACTION.REMOVE)) {
      return {
        ...state,
        [dataKey]: {
          ...bundle,
          data: bundle.data.filter((item) => item.id !== data.id),
          errors: undefined,
          loading: false,
        },
      };
    }
    return {
      ...state,
      [dataKey]: {
        ...bundle,
        data,
        errors: undefined,
        loading: false,
      },
    };
  }

  return state;
};

export default todoReducer;
